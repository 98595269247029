import React from 'react';
import done from 'images/done.svg';

import styles from '../styles.module.css';
import OnboardingButton from './OnboardingButton';

const Success = ({ skipTrackable, nextStep }) => {
  return (
    <div data-testid="success-container">
      <div className={styles.welcomeBody}>
        <div className={styles.welcomeSteps}>
          <h1>You're almost ready to go!</h1>
          <p>
            Next, set up the Trackable prefix to access our advanced analytics and features, like
            SmartLinks for podcast marketing and to be included on our charts.
          </p>

          <p>
            Trackable works by transparently redirecting your download requests through our service,
            instantly and reliably. It’ll have no impact on the listener experience. We collect
            information via the redirect and provide detailed analytics. More about Trackable...
          </p>
        </div>
        <div className={styles.welcomeImage}>
          <img src={done} alt="Done" />
        </div>
      </div>
      <OnboardingButton link nextStep={nextStep}>
        Set Up Trackable
      </OnboardingButton>
      <a className={`${styles.skipTrackable} db tc link pointer`} href={skipTrackable}>
        Skip and set up in dashboard later &raquo;
      </a>
    </div>
  );
};

export default Success;
