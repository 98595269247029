import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import pluralize from 'pluralize';

import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import Header from 'shared/Header';
import FormDivisionSelector from 'shared/FormDivisionSelector';
import FormPodcastSearchInput from 'shared/FormPodcastSearchInput';
import FormPodcast from 'shared/FormPodcast';
import FormInputCheckbox from 'shared/FormInputCheckbox';
import FormTitle from 'shared/FormTitle';
import FormError from 'shared/FormError';
import FormSelect from 'shared/FormSelect';
import FormCloseBox from 'shared/FormCloseBox';
import BannerNotice from 'shared/BannerNotice';

const BLANK_FORM = {
  email: '',
  isRestrictedPodcastUser: false,
  allowedTeamPodcasts: [],
  loading: false,
  errors: {},
  modal: false,
  role: null,
  isAllDivisionsUser: true,
  allowedDivisions: [],
  selectedDivision: null,
};

const ROLES = [
  { value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'viewer', label: 'Viewer' },
];

export default class InviteForm extends Component {
  constructor(props) {
    super(props);

    this.handleInvite = this.handleInvite.bind(this);
    this.handleAddDivision = this.handleAddDivision.bind(this);
    this.handleRemoveDivision = this.handleRemoveDivision.bind(this);
    this.handleAddTeamPodcast = this.handleAddTeamPodcast.bind(this);
    this.handleRemoveTeamPodcast = this.handleRemoveTeamPodcast.bind(this);
    this.resetForm = this.resetForm.bind(this);

    this.state = {
      formRoles: ROLES.filter(r => this.props.allowedRoles.includes(r.value)),
      ...BLANK_FORM,
    };

    if (this.props.allowedRoles.length == 1) {
      this.state.role = this.props.allowedRoles[0];
    }
  }

  handleInvite() {
    const {
      email,
      isRestrictedPodcastUser,
      allowedTeamPodcasts,
      loading,
      role,
      modal,
      allowedDivisions,
    } = this.state;

    if (loading) return;

    if ((this.props.hasRestrictedUsers || this.props.hasDivisions) && !modal) {
      this.setState({ modal: true });
      return;
    }

    this.setState({ loading: true });
    this.props
      .onInvite({
        email,
        isRestrictedPodcastUser,
        teamPodcastIds: allowedTeamPodcasts.map(a => a.teamPodcastId),
        divisions: allowedDivisions.map(d => ({ id: d.id, role: d.role })),
        role,
      })
      .then(res => {
        this.setState({ loading: false });
        if (res.data && res.data.errors) {
          this.setState({ errors: res.data.errors });
        } else {
          this.resetForm();
        }
      });
  }

  resetForm() {
    this.setState(BLANK_FORM);
  }

  handleAddDivision(division, role) {
    const { allowedDivisions } = this.state;

    if (allowedDivisions.filter(d => d.id === division.id).length === 0) {
      allowedDivisions.push({ id: division.id, name: division.name, role: role });
    }

    this.setState({ allowedDivisions, selectedDivision: null });
  }

  handleRemoveDivision(divisionId) {
    let { allowedDivisions } = this.state;

    allowedDivisions = allowedDivisions.filter(d => d.id !== divisionId);

    this.setState({ allowedDivisions });
  }

  handleAddTeamPodcast(teamPodcast) {
    const { allowedTeamPodcasts } = this.state;

    if (
      allowedTeamPodcasts.filter(tp => tp.teamPodcastId === teamPodcast.teamPodcastId).length === 0
    ) {
      allowedTeamPodcasts.push(teamPodcast);
    }

    this.setState({ allowedTeamPodcasts });
  }

  handleRemoveTeamPodcast(teamPodcast) {
    let { allowedTeamPodcasts } = newTeamUser;

    allowedTeamPodcasts = allowedTeamPodcasts.filter(
      tp => tp.teamPodcastId !== teamPodcast.teamPodcastId,
    );

    this.setState({ allowedTeamPodcasts });
  }

  render() {
    const {
      loading,
      invites,
      email,
      isRestrictedPodcastUser,
      allowedTeamPodcasts,
      errors,
      modal,
      isAllDivisionsUser,
      selectedDivision,
      allowedDivisions,
      formRoles,
    } = this.state;

    const {
      hasRestrictedUsers,
      hasDivisions,
      teamId,
      teamHashedId,
      canInviteUsers,
      showUserRoles,
    } = this.props;

    if (!modal) {
      return (
        <div>
          {this.renderBasicForm()}
          <Button
            onClick={this.handleInvite}
            className="w-100 mt2"
            type="primary"
            loading={loading}
            disabled={!canInviteUsers}
          >
            Send invite
          </Button>
        </div>
      );
    }

    return (
      <BasicModal isOpen onRequestClose={() => this.setState({ modal: false })}>
        {this.renderBasicForm()}
        {hasRestrictedUsers && (
          <div className="mt4">
            <FormInputCheckbox
              value={!isRestrictedPodcastUser}
              onChange={() =>
                this.setState({
                  isRestrictedPodcastUser: !isRestrictedPodcastUser,
                  allowedTeamPodcasts: [],
                })
              }
              title="Access"
              name="All Podcasts"
            />
            {isRestrictedPodcastUser && (
              <div>
                <FormTitle title="Allowed Podcasts" />
                {allowedTeamPodcasts.map(teamPodcast => (
                  <FormPodcast
                    podcast={teamPodcast}
                    onUnselectPodcast={this.handleRemoveTeamPodcast}
                    key={teamPodcast.id}
                  />
                ))}
                <FormPodcastSearchInput
                  title="Add an allowed podcast"
                  teamId={teamId}
                  onSelectPodcast={this.handleAddTeamPodcast}
                />
              </div>
            )}
          </div>
        )}
        {hasDivisions && (
          <div className="mt4">
            <FormInputCheckbox
              value={isAllDivisionsUser}
              onChange={() =>
                this.setState({ isAllDivisionsUser: !isAllDivisionsUser, allowedDivisions: [] })
              }
              title="Access"
              name="All Divisions"
            />
            {!isAllDivisionsUser && (
              <div>
                <FormTitle title="Allowed Divisions" />
                {allowedDivisions.map(allowedDivision => (
                  <div className="mb4">
                    <FormCloseBox onClose={() => this.handleRemoveDivision(allowedDivision.id)}>
                      <div className="pa3 f4 fw4 dt w-100">
                        <div className="w-50-ns w-100 dtc">{allowedDivision.name}</div>
                        <div className="w-50-ns w-100 dtc">
                          Role:{' '}
                          {allowedDivision.role.charAt(0).toUpperCase() +
                            allowedDivision.role.slice(1).toLowerCase()}
                        </div>
                      </div>
                    </FormCloseBox>
                  </div>
                ))}
                {selectedDivision ? (
                  <div>
                    <FormCloseBox onClose={() => this.setState({ selectedDivision: null })}>
                      <div className="tc f4 fw4 pa3">{selectedDivision.name}</div>
                    </FormCloseBox>
                    <FormSelect
                      options={formRoles}
                      onChange={value => this.handleAddDivision(selectedDivision, value)}
                      tooltip="Admins cannot manage billing, Members cannot manage other users."
                      placeholder="Select a role to add the division..."
                      className="mb0"
                    />
                  </div>
                ) : (
                  <FormDivisionSelector
                    title="Add a division"
                    teamId={teamHashedId}
                    teamSlug={teamId}
                    onSelectDivision={division => this.setState({ selectedDivision: division })}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <Button
          onClick={this.handleInvite}
          className="w-100 mt2"
          type="primary"
          loading={loading}
          disabled={!canInviteUsers}
        >
          Send invite
        </Button>
      </BasicModal>
    );
  }

  renderBasicForm() {
    const { allowedRoles, canInviteUsers, teamId, showUserRoles, usersAllowed } = this.props;

    const { email, errors, role, formRoles, isAllDivisionsUser } = this.state;

    return (
      <div>
        <Header>Invite a Teammate</Header>
        {!canInviteUsers && (
          <BannerNotice
            error
            message={
              "You have reached the user limit for your plan. <span class='underline'>Upgrade</span> to add more users."
            }
          />
        )}
        {canInviteUsers && usersAllowed && (
          <BannerNotice
            message={`You can add ${pluralize(
              'more user',
              usersAllowed,
              true,
            )} to your team.  <span class='underline'>Upgrade your plan</span> to add more users and set different permission levels for each user.`}
          />
        )}
        <div className="dt w-100">
          <div className="dtc pr2 w-50">
            <input
              className="pa2 input-reset ba w-100 bg-white br2 b--silver"
              type="email"
              name="email"
              placeholder="friend@example.com"
              value={email}
              disabled={!canInviteUsers}
              onChange={e => this.setState({ email: e.target.value })}
            />
            <FormError errorMessage={errors.email} />
          </div>
          {isAllDivisionsUser && (
            <div className="dtc pa2 w-50">
              <FormSelect
                value={role}
                options={formRoles}
                onChange={value =>
                  showUserRoles ? this.setState({ role: value }) : this.setState({ role: 'admin' })
                }
                tooltip="Admins cannot manage billing, Members cannot manage other users."
                placeholder={`Select a role... ${+showUserRoles ? '' : ''}`}
                className="mb0"
                disabled={true}
              />
              <FormError errorMessage={errors.role} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

InviteForm.propTypes = {
  teamId: PropTypes.string.isRequired,
  hasRestrictedUsers: PropTypes.bool.isRequired,
  onInvite: PropTypes.func.isRequired,
  allowedRoles: PropTypes.array.isRequired,
  hasDivisions: PropTypes.bool.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  canInviteUsers: PropTypes.bool.isRequired,
  showUserRoles: PropTypes.bool,
  usersAllowed: PropTypes.number,
};

InviteForm.defaultProps = {
  showUserRoles: false,
  usersAllowed: null,
};
