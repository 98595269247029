import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import ErrorBoundary from 'shared/ErrorBoundary';
import { redirectToPricingPage } from 'shared/helpers';
import useUserInfo from 'shared/hooks/useUserInfo';

import DemographicsBarChart from './DemographicsBarChart';
import DemographicsChartHeader from './DemographicsChartHeader';
import {
  demographicsSegments,
  formatSpotifyDemographicDataForBarChart,
} from './DemographicsChartHelpers';
import Locked from './Locked';

const SpotifyDemographics = ({ data, error }) => {
  const { teamId } = useParams();
  const { hasSpotifyCredentials } = useUserInfo();

  const errorComponent = <Banner error>Could not display Spotify data</Banner>;

  // If they do not have spotify creds or are not on a paid plan, block access
  if (!hasSpotifyCredentials) {
    return (
      <Locked
        reqs={[
          {
            name: 'Integrate Spotify',
            checked: hasSpotifyCredentials,
            onClick: () => (window.location = `/teams/${teamId}/dashboard/integrations/spotify`),
          },
        ]}
      />
    );
  }

  if (error) {
    return (
      <Banner className="mt3" error>
        Failed to load Spotify demographics data
      </Banner>
    );
  }

  const age = data && data.ageDemographics ? data.ageDemographics : null;
  const gender = data && data.genderDemographics ? data.genderDemographics : null;

  // If they are missing demographics data
  if (!gender || !age || gender.length === 0 || age.length === 0) {
    return (
      <Banner className="mt3">
        We don't currently have Spotify demographics available for your selection yet. Check your
        Spotify integration to make sure this show is integrated, and get in touch with any
        questions.
      </Banner>
    );
  }

  return (
    <ErrorBoundary errorComponent={errorComponent}>
      <div className="flex flex-wrap gap">
        <div style={{ minWidth: '49%', maxWidth: '100%' }}>
          <DemographicsChartHeader segment={demographicsSegments.age} />
          <DemographicsBarChart
            isLoading={false}
            data={formatSpotifyDemographicDataForBarChart(age)}
            inlineStyle={{ height: '300px', width: '100%' }}
          />
        </div>
        <div style={{ minWidth: '49%', maxWidth: '100%' }}>
          <DemographicsChartHeader segment={demographicsSegments.gender} />
          <DemographicsBarChart
            isLoading={false}
            data={formatSpotifyDemographicDataForBarChart(gender)}
            inlineStyle={{ height: '300px', width: '100%' }}
          />
        </div>
      </div>
    </ErrorBoundary>
  );
};

SpotifyDemographics.propTypes = {
  error: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

SpotifyDemographics.defaultProps = {
  data: null,
};

export default SpotifyDemographics;
