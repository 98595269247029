/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import RailsUJS from 'rails-ujs';

import '../application.sass';

RailsUJS.start();

// Support component names relative to this directory:
const componentRequireContext = require.context('pages', true);
const ReactRailsUJS = require('react_ujs');
ReactRailsUJS.useContext(componentRequireContext);
ReactRailsUJS.mountComponents();

// Lazy loader, stolen from https://github.com/kaizau/Lazy-Load-Images-without-jQuery
// TODO: there must be a better place to put this. But this will do for now.
(function () {
  const addEventListener =
    window.addEventListener ||
    function (n, f) {
      window.attachEvent(`on${n}`, f);
    };
  const removeEventListener =
    window.removeEventListener ||
    function (n, f, b) {
      window.detachEvent(`on${n}`, f);
    };

  var lazyLoader = {
    cache: [],
    mobileScreenSize: 500,
    // tinyGif: 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',

    addObservers: function () {
      addEventListener('scroll', lazyLoader.throttledLoad);
      addEventListener('resize', lazyLoader.throttledLoad);
    },

    removeObservers: function () {
      removeEventListener('scroll', lazyLoader.throttledLoad, false);
      removeEventListener('resize', lazyLoader.throttledLoad, false);
    },

    throttleTimer: new Date().getTime(),

    throttledLoad: function () {
      const now = new Date().getTime();
      if (now - lazyLoader.throttleTimer >= 200) {
        lazyLoader.throttleTimer = now;
        lazyLoader.loadVisibleImages();
      }
    },

    loadVisibleImages: function () {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      const pageHeight = window.innerHeight || document.documentElement.clientHeight;
      const range = {
        min: scrollY - 200,
        max: scrollY + pageHeight + 200,
      };

      let i = 0;
      while (i < lazyLoader.cache.length) {
        const image = lazyLoader.cache[i];
        const imagePosition = getOffsetTop(image);
        const imageHeight = image.height || 0;

        if (imagePosition >= range.min - imageHeight && imagePosition <= range.max) {
          const mobileSrc = image.getAttribute('data-src-mobile');

          image.onload = function () {
            this.className = this.className.replace(/(^|\s+)lazy-load(\s+|$)/, '$1lazy-loaded$2');
          };

          image.onerror = function () {
            this.src = 'http://via.placeholder.com/300/8CC1D7/4B9EC1/?text=+';
          };

          if (mobileSrc && screen.width <= lazyLoader.mobileScreenSize) {
            image.src = mobileSrc;
          } else {
            image.src = image.getAttribute('data-src');
          }

          image.removeAttribute('data-src');
          image.removeAttribute('data-src-mobile');

          lazyLoader.cache.splice(i, 1);
          continue;
        }

        i++;
      }

      if (lazyLoader.cache.length === 0) {
        lazyLoader.removeObservers();
      }
    },

    init: function () {
      // Patch IE7- (querySelectorAll)
      if (!document.querySelectorAll) {
        document.querySelectorAll = function (selector) {
          const doc = document;
          const head = doc.documentElement.firstChild;
          const styleTag = doc.createElement('STYLE');
          head.appendChild(styleTag);
          doc.__qsaels = [];
          styleTag.styleSheet.cssText = `${selector}{x:expression(document.__qsaels.push(this))}`;
          window.scrollBy(0, 0);
          return doc.__qsaels;
        };
      }

      addEventListener('load', function _lazyLoaderInit() {
        const imageNodes = document.querySelectorAll('img[data-src]');

        for (let i = 0; i < imageNodes.length; i++) {
          const imageNode = imageNodes[i];

          // Add a placeholder if one doesn't exist
          // imageNode.src = imageNode.src || lazyLoader.tinyGif;

          lazyLoader.cache.push(imageNode);
        }

        lazyLoader.addObservers();
        lazyLoader.loadVisibleImages();

        removeEventListener('load', _lazyLoaderInit, false);
      });
    },
  };

  // For IE7 compatibility
  // Adapted from http://www.quirksmode.org/js/findpos.html
  function getOffsetTop(el) {
    let val = 0;
    if (el.offsetParent) {
      do {
        val += el.offsetTop;
      } while ((el = el.offsetParent));
      return val;
    }
  }

  lazyLoader.init();
})();

import Chartkick from 'chartkick';
window.Chartkick = Chartkick;

// for Chart.js
import Chart from 'chart.js';
Chartkick.addAdapter(Chart);

import 'flag-icon-css/css/flag-icon.css';
