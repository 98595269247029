import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import {
  adminGetPodcastOwnersAndAgencies,
  adminGetPromo,
  adminUpdatePromo,
  teamSearch,
} from 'shared/api';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';
import Loading from 'shared/Loading';

const TeamSuggestionBox = ({ podcast, onSuggestionSelected }) => {
  const [suggestions, setSuggestions] = useState(null);

  useEffect(() => {
    adminGetPodcastOwnersAndAgencies({ podcastId: podcast.slug }).then(res =>
      setSuggestions(res.data),
    );
  }, []);

  if (!suggestions) {
    return <Loading />;
  }

  return (
    <React.Fragment>
      <div className="dark-gray f6 force-inter">Podcast Owners and Partners</div>
      <div className="mid-gray f7 mb1 force-inter">
        Teams that have trackable installed on or who have been designated agents for the podcast.
        Click on one and then hit save to designate it as the Seller Team.
      </div>
      <div className="w-100">
        {suggestions.map((suggestion, index) => (
          <div
            key={index}
            className={`f6 flex flex-row gap-small pa2 w-100 bg-${
              suggestion.type === 'owner' ? 'blue' : 'green'
            } pointer white dim items-end`}
            onClick={() =>
              onSuggestionSelected(
                suggestion.type === 'owner' ? suggestion.team : suggestion.partner,
              )
            }
          >
            <div className="b ttu">{suggestion.type}</div>
            {suggestion.type === 'owner' ? (
              <div>{suggestion.team.name}</div>
            ) : (
              <React.Fragment>
                <div>{suggestion.partner.name}</div>
                <div className="f7 ttc b">represents ➜</div>
                <div>{suggestion.team.name}</div>
              </React.Fragment>
            )}
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

class PromoDetailView extends React.Component {
  constructor(props) {
    super(props);

    this.handleAutosuggest = this.handleAutosuggest.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.save = this.save.bind(this);

    this.state = {
      promo: null,
      notice: null,
      selectedSellerTeam: null,
      sellerTeamSuggestions: [],
      sellerTeamSearchValue: '',
      suggestedTeams: null,
    };
  }

  componentDidMount() {
    adminGetPromo({
      promoId: this.props.match.params.promoId,
      isRequest: this.props.match.params.promoRequest === 'request',
    }).then(res => {
      this.setState({
        promo: res.data,
        selectedSellerTeam: res.data.sellerTeam ? res.data.sellerTeam : null,
      });
    });
  }

  handleAutosuggest({ value }) {
    this.setState({ search: value });
    teamSearch({
      term: value,
    }).then(res => {
      if (this.state.search == value) {
        this.setState({ sellerTeamSuggestions: res.data });
      }
    });
  }

  renderSuggestion(suggestion) {
    return <div key={suggestion.id}>{suggestion.label}</div>;
  }

  handleSuggestionSelected(selectedSellerTeam) {
    this.setState({ selectedSellerTeam });
  }

  save() {
    adminUpdatePromo({
      promoId: this.props.match.params.promoId,
      sellerTeamId: this.state.selectedSellerTeam.id,
      sellerTeamUsers: this.state.promo.sellerTeam ? this.state.promo.sellerTeamUsers : [],
      isRequest: this.props.match.params.promoRequest === 'request',
    })
      .then(res => {
        this.setState({
          promo: res.data.promoRequest,
          requestInProgress: false,
          notice: (
            <BannerNotice
              success
              message="Success"
              onClose={() => this.setState({ notice: null })}
            />
          ),
        });
      })
      .catch(() => {
        this.setState({
          requestInProgress: false,
          notice: (
            <BannerNotice
              error
              message="Error updating promo"
              onClose={() => this.setState({ notice: null })}
            />
          ),
        });
      });
  }

  render() {
    const { promo, selectedSellerTeam, sellerTeamSuggestions, sellerTeamSearchValue } = this.state;

    if (!promo) {
      return <Loading fullScreen />;
    }

    return (
      <div>
        {this.state.notice}
        <div className="pa3 mw7">
          <div
            className="f5 mb4 hover-blue pointer"
            onClick={() => this.props.history.push('/custom_admin/react/promos')}
          >
            ← Back
          </div>
          <div className="mb4">
            <div className="f4 gray mb2">User Entered Seller Team</div>
            <div className="mb4 f4">{promo.userInputPublisherName}</div>
            <div className="f4 gray mb2">Assigned Seller Team</div>
            {selectedSellerTeam ? (
              <div className="mb3 f4">
                {selectedSellerTeam && (
                  <div>
                    {selectedSellerTeam.id} - {selectedSellerTeam.name}
                  </div>
                )}
                <div
                  className="f6 blue underline pointer"
                  onClick={() => {
                    const { promo } = this.state;
                    promo.sellerTeam = null;
                    promo.sellerTeamUsers = null;

                    this.setState({
                      selectedSellerTeam: null,
                      sellerTeamSearchValue: '',
                      promo,
                    });
                  }}
                >
                  clear seller team
                </div>
              </div>
            ) : (
              <div className="bg-white">
                <div className="header-font mb2">SELECT A TEAM</div>
                <Autosuggest
                  suggestions={sellerTeamSuggestions}
                  onSuggestionsFetchRequested={this.handleAutosuggest}
                  onSuggestionsClearRequested={() => this.setState({ sellerTeamSuggestions: [] })}
                  onSuggestionSelected={(event, { suggestion }) =>
                    this.handleSuggestionSelected({ ...suggestion, id: suggestion.value })
                  }
                  getSuggestionValue={suggestion => sellerTeamSuggestions.trackName}
                  shouldRenderSuggestions={value => (value ? value.length > 2 : false)}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={{
                    className: 'pa2 b--moon-gray ba br2 w-100 w5 f5',
                    placeholder: 'Search team by name',
                    value: sellerTeamSearchValue,
                    onChange: e => this.setState({ sellerTeamSearchValue: e.target.value }),
                  }}
                />
              </div>
            )}
          </div>
          {promo.sellerPodcast && (
            <div className="mv4">
              <TeamSuggestionBox
                podcast={promo.sellerPodcast}
                onSuggestionSelected={selection => this.handleSuggestionSelected(selection)}
              />
            </div>
          )}
          {promo.sellerTeam && promo.sellerTeamUsers && (
            <div className="flex flex-column mb4">
              <div className="f5 mb1 header-font">Team Member's SmartPromo Email Preferences</div>
              <div className="f5 mb3">
                Change user's promo request email preferences, if unchecked they won't receive an
                email
              </div>
              {promo.sellerTeamUsers.map((user, index) => (
                <div key={user.id} className="flex flex-row items-baseline f5">
                  <input
                    className="mr2 mb2"
                    type="checkbox"
                    onChange={() => {
                      promo.sellerTeamUsers.map(u => {
                        if (u.id === user.id) {
                          u.shouldReceiveSmartpromoApprovalRequests =
                            !user.shouldReceiveSmartpromoApprovalRequests;
                          return u;
                        }
                        return u;
                      });
                      this.setState({ promo });
                    }}
                    checked={user.shouldReceiveSmartpromoApprovalRequests}
                  />
                  <div className="mr2">{user.email}</div>
                </div>
              ))}
            </div>
          )}
          <Button size="large" type="primary" onClick={this.save}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default PromoDetailView;
