import React from 'react';
import Header from 'shared/Header';
import { redirectToPricingPage } from 'shared/helpers';

import styles from '../styles/styles.module.css';

const ConnectUpsell = ({ teamId }) => {
  const handleUpgrade = () => {
    redirectToPricingPage('Clicked Upgrade: Connect Pro Features', teamId);
  };

  return (
    <div>
      <Header className="dark-blue mt3 lh-copy-l">
        Upgrade to Pro to unlock the full power of Connect
      </Header>
      <ul>
        <li className={styles.upsellLi}>Request Introductions</li>
        <li className={styles.upsellLi}>Advanced Filters</li>
        <li className={styles.upsellLi}>View Demographics and Reach for Verified Podcasts</li>
      </ul>
      <button
        onClick={() => handleUpgrade()}
        className="pv2 bn white bg-blue pointer w4 f5 br2 header-font tc relative"
      >
        Upgrade Now
      </button>
    </div>
  );
};

export default ConnectUpsell;
