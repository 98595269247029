/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import MinusSVG from 'images/icons/minus-red.svg';
import PlusSVG from 'images/icons/plus-blue.svg';
import WarningSVG from 'images/icons/warning-yellow.svg';
import PropTypes from 'prop-types';
import { playerInfo } from 'shared/helpers';

const redirectDeviceInfo = {
  ios: { label: 'iOS device', value: 'ios' },
  mac: { label: 'Mac device', value: 'mac' },
  android: { label: 'Android device', value: 'android' },
};

const destinationOptions = {
  spotify: { value: 'spotify', label: 'Spotify', icon: playerInfo.spotify.icon },
  itunes: { value: 'itunes', label: 'Apple Podcasts', icon: playerInfo.itunes.icon },
  google_podcasts: {
    value: 'google_podcasts',
    label: 'Google Podcasts',
    icon: playerInfo.google_podcasts.icon,
  },
};

// ***
// SmartLinks Note:
// The backend refers to the devices as platforms. The tracking
// link will return these redirect rules in a dictionary called `platforms`
// ex. { ios: 'spotify', mac: 'itunes', android: 'spotify' }
// ***

const { Option } = components;
const CustomOption = props => (
  <Option {...props}>
    <div className="input-select__single-value flex flex-row items-center">
      <img src={props.data.icon} className="input-select__icon mr2" style={{ height: '25px' }} />
      <div>{props.data.label}</div>
    </div>
  </Option>
);

const SingleValue = ({ data }) => (
  <div className="input-select">
    <div className="input-select__single-value flex flex-row items-center">
      {data.icon && (
        <img src={data.icon} className="input-select__icon mr2" style={{ height: '25px' }} />
      )}
      <div>{data.label}</div>
    </div>
  </div>
);

const RedirectCondition = ({
  deviceOptions,
  device,
  destination,
  needsLink,
  goToDeeplinkManager,
  showAdd,
  addCondition,
  removeCondition,
  updateCondition,
  id,
}) => (
  <div className="flex flex-row items-center gap mt3">
    <div className="self-start" style={{ flex: '30%' }}>
      <Select
        value={redirectDeviceInfo[device]}
        onChange={({ value }) => updateCondition({ device: value, destination, id })}
        options={deviceOptions}
      />
    </div>
    <div style={{ flex: '60%' }}>
      <Select
        value={destinationOptions[destination]}
        onChange={({ value }) => updateCondition({ device, destination: value, id })}
        components={{ SingleValue, Option: CustomOption }}
        options={Object.keys(destinationOptions).map(k => destinationOptions[k])}
      />
      {needsLink && (
        <div
          className="f7 mt1 mb2 pa1 br2 flex flex-row items-center"
          style={{ color: '#6d5518', backgroundColor: '#fbeecd' }}
        >
          <img src={WarningSVG} style={{ height: '18px' }} className="mr1" />
          <div>
            Assign{' '}
            <span onClick={() => goToDeeplinkManager(destination)} className="link blue pointer">
              Deeplinks
            </span>{' '}
            to active this condition.
          </div>
        </div>
      )}
    </div>
    <img
      src={showAdd ? PlusSVG : MinusSVG}
      style={{ margin: '10px 0' }}
      className="h1 w1 dim pointer self-start"
      onClick={showAdd ? addCondition : removeCondition}
    />
  </div>
);

function LinkRedirectSetupView({ redirects, saveRedirects, validPlayers, goToDeeplinkManager }) {
  const redirectKeys = Object.keys(redirects);

  const [currentItemId, setCurrentItemId] = useState(4);
  const [, setCreatingNewRedirect] = useState(false);
  const [redirectConditions, setRedirectConditions] = useState(() => {
    const conditions = redirectKeys.map((k, index) => ({
      device: k,
      destination: redirects[k],
      id: index,
    }));
    if (conditions.length < 3) {
      conditions.push({ device: null, destination: null, id: 3 });
    }

    return conditions;
  });

  const availableDeviceOptions = useMemo(() => {
    const usedKeys = redirectConditions.map(c => c.device).filter(c => c);
    return Object.keys(redirectDeviceInfo)
      .map(key => (usedKeys.includes(key) ? null : redirectDeviceInfo[key]))
      .filter(k => k);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(redirectConditions)]);

  function handleSaveRedirect(device, destination) {
    redirects[device] = destination;
    saveRedirects(redirects);
    setCreatingNewRedirect(false);
  }

  function handleDeleteRedirect(device) {
    delete redirects[device];
    saveRedirects(redirects);
  }

  return (
    <div className="mt3">
      <div className="items-center flex flex-row">
        <div style={{ flex: '30%' }} className="f5 dark-gray">
          Device
        </div>
        <div className="f5 dark-gray mr2" style={{ flex: '60%' }}>
          Destination
        </div>
      </div>
      {redirectConditions.map((condition, index) => (
        <RedirectCondition
          key={condition.id}
          id={condition.id}
          device={condition.device}
          destination={condition.destination}
          goToDeeplinkManager={goToDeeplinkManager}
          needsLink={condition.destination ? !validPlayers.includes(condition.destination) : false}
          deviceOptions={availableDeviceOptions}
          showAdd={index === redirectConditions.length - 1 && index !== 2}
          updateCondition={condition => {
            redirectConditions[index] = condition;
            if (redirectConditions.length < 3 && index === redirectConditions.length - 1) {
              setRedirectConditions([
                ...redirectConditions,
                { destination: null, device: null, id: currentItemId + 1 },
              ]);
              setCurrentItemId(currentItemId + 1);
            } else {
              setRedirectConditions([...redirectConditions]);
            }

            if (!!condition.device && !!condition.destination) {
              handleSaveRedirect(condition.device, condition.destination);
            }
          }}
          addCondition={() => {
            setRedirectConditions([
              ...redirectConditions,
              { destination: null, device: null, id: currentItemId + 1 },
            ]);
            setCurrentItemId(currentItemId + 1);
          }}
          removeCondition={() => {
            const condition = redirectConditions[index];
            if (!!condition.device && condition.destination) {
              handleDeleteRedirect(condition.device);
            }

            redirectConditions.splice(index, 1);

            // If the final condition view has a condition or device, we need to make sure there is an extra view
            // so the previous one can be deleted.
            const finalCondition = redirectConditions[redirectConditions.length - 1];
            if (!!finalCondition.device || !!finalCondition.destination) {
              redirectConditions.push({ destination: null, device: null, id: currentItemId + 1 });
            }

            setRedirectConditions([...redirectConditions]);
            setCurrentItemId(currentItemId + 1);
          }}
        />
      ))}
    </div>
  );
}

LinkRedirectSetupView.propTypes = {
  redirects: PropTypes.object.isRequired,
  saveRedirects: PropTypes.func.isRequired,
  validPlayers: PropTypes.array.isRequired,
  goToDeeplinkManager: PropTypes.func.isRequired,
};

export default LinkRedirectSetupView;
