import React from 'react';
import CloseIcon from 'images/icons/x.svg';
import header from 'images/mpbundleheader.png';
import PropTypes from 'prop-types';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';

const BundleConfirmationModal = ({ isOpen, onClose, onConfirm, upgradedTier }) => {
  const styles = {
    title: {
      marginTop: '32px',
    },
    info: {
      marginTop: '24px',
      fontSize: '14px',
    },
    continueButton: {
      borderRadius: '40px',
      width: '174px',
    },
    buttonContainer: {
      marginTop: '32px',
      marginBottom: '32px',
    },
    moreButton: {
      marginTop: '16px',
    },
    moreLink: {
      color: '#6447ff',
      cursor: 'pointer',
      textDecoration: 'none',
    },
    modal: {
      content: {
        maxWidth: '480px',
        outline: 'none',
      },
    },
    header: {
      backgroundImage: `url(${header})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      height: '197px',
    },
    body: {
      padding: '0 40px',
    },
  };

  return (
    <BasicModal
      style={styles.modal}
      className="absolute overflow-auto z-9999 left-2 right-2 bg-white center top-2 ba b--light-gray br2"
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
    >
      <div className="tr h5" style={styles.header}>
        <img src={CloseIcon} alt="Close" className="pointer dim mr4 mt4" onClick={onClose} />
      </div>
      <div style={styles.body} className="tc">
        <h2 style={styles.title} className="fw-700 force-inter">
          Bundle and upgrade your plan
        </h2>
        <p style={styles.info} className="force-inter">
          You’re eligible for a Megaphone and Chartable {upgradedTier} bundle. When you bundle your
          plans, you’ll get Chartable {upgradedTier} for free as part of your Megaphone
          subscription.{' '}
        </p>
      </div>
      <div style={styles.buttonContainer}>
        <Button
          className="white pv3 mt-auto f5 b center bg-spotify-purple"
          style={styles.continueButton}
          onClick={onConfirm}
        >
          Continue
        </Button>
        <div className="tc" style={styles.moreButton}>
          <a
            href="https://megaphone.spotify.com/pricing"
            target="_blank"
            style={styles.moreLink}
            rel="noreferrer"
          >
            Learn more
          </a>
        </div>
      </div>
    </BasicModal>
  );
};

BundleConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  upgradedTier: PropTypes.string.isRequired,
};

export default BundleConfirmationModal;
