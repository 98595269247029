import React, { useEffect, useState } from 'react';
import { DataSearch } from '@appbaseio/reactivesearch';

const ConnectSearch = ({ onChange, searchValue }) => {
  const [val, setVal] = useState(searchValue);
  useEffect(() => {
    if (searchValue !== val) {
      setVal(searchValue);
    }
  }, [searchValue]);
  const handleChange = (value, triggerQuery, event) => {
    if (!event) return;
    const val = event.target.value;
    setVal(val);
    onChange(val, triggerQuery);
  };
  return (
    <div className="flex justify-between mb3">
      <div className="mt3" style={{ width: '100%' }}>
        <DataSearch
          innerClass={{ input: 'br3 connect-search bn-important bg-white-important card' }}
          style={{ border: 'none' }}
          className="pb2 w-100 br4"
          componentId="title"
          dataField={['title', 'description', 'publisher', 'genres']}
          placeholder="Search Podcasts"
          fieldWeights={[10, 2, 10, 5]}
          autosuggest={false}
          fuzziness={0}
          queryFormat="and"
          value={val}
          onChange={handleChange}
          autoFocus
        />
      </div>
    </div>
  );
};

export default ConnectSearch;
