import React from 'react';
import PropTypes from 'prop-types';
import ChartHistoryChart from 'shared/charts/ChartHistoryChart';
import Header from 'shared/Header';
import TableErrorView from 'shared/TableErrorView';

const Devices = ({ downloadHistory, startDate, endDate, aggregation }) => {
  return (
    <div className="mt4">
      <Header className="dark-blue" useNew>
        Converted Devices
      </Header>
      {downloadHistory === null || downloadHistory.length > 2 ? (
        <ChartHistoryChart
          noTitle
          legendPosition="top"
          history={downloadHistory}
          hideSelectors
          hideAggregationOptions
          loading={!downloadHistory}
          height="480px"
          startDate={startDate}
          endDate={endDate}
          aggregation={aggregation}
        />
      ) : (
        <div className="mt3">
          <TableErrorView header="No data to show!" />
        </div>
      )}
    </div>
  );
};

Devices.propTypes = {
  downloadHistory: PropTypes.array,
  playerColorMap: PropTypes.any,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  aggregation: PropTypes.any,
};

export default Devices;
