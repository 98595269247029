import React from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import { constructReactSelectOptionsFromStringArray } from 'shared/helpers.js';
import ReportLink from 'shared/ReportLink';

const DetailHeader = ({
  canChangeSmartLinksAttributionWindow,
  setAttributionWindowSettingsIsOpen,
  lookbackWindow,
  startDate,
  endDate,
  canEditSmartLinks,
  handleUpdateDateRange,
  trackingLink,
  aggregation,
  aggregations,
  setAggregation,
}) => {
  return (
    <div>
      <div className="flex justify-end flex-row items-center mb3 gap-small">
        {canChangeSmartLinksAttributionWindow && (
          <React.Fragment>
            <ReactTooltip id="lookbackwindow" />
            <div
              data-for="lookbackwindow"
              data-tip="How many days after each impression we look to find conversions. Click to change."
              className="mr1 dim f7 tc br4 ph2 pv1 white bg-blue pointer"
              onClick={() => setAttributionWindowSettingsIsOpen(true)}
            >
              {lookbackWindow % 24 > 0 ? `${lookbackWindow} hour` : `${lookbackWindow / 24} days`}{' '}
              attribution window
            </div>
          </React.Fragment>
        )}
        <ChartableDatePicker
          startDate={startDate}
          endDate={endDate}
          handleUpdateDateRange={handleUpdateDateRange}
        />
      </div>
      <div className="flex flex-wrap items-center justify-between mb3">
        <div>
          <div className="mid-gray f6">
            Downloads last updated:{' '}
            {trackingLink.downloadsLastUpdated
              ? moment(trackingLink.downloadsLastUpdated).format('MM/DD/YYYY')
              : 'Never'}
          </div>
          <div className="mid-gray f6">Clicks updated in real-time</div>
        </div>
        <div className="flex flex-row items-center gap-small">
          <Select
            value={{
              value: aggregation,
              label: `Aggregate ${aggregation}`,
            }}
            onChange={selection => setAggregation(selection.value)}
            options={constructReactSelectOptionsFromStringArray(Object.keys(aggregations))}
            className="w5"
          />
          {canEditSmartLinks && (
            <ReportLink
              params={{
                product: 'SmartLinks',
                reportType: 'SMARTLINKS_REPORT',
                trackingLinkId: trackingLink.id,
              }}
            >
              <Button className="f6 w4" type="create">
                Export CSV
              </Button>
            </ReportLink>
          )}
        </div>
      </div>
    </div>
  );
};

DetailHeader.propTypes = {
  canChangeSmartLinksAttributionWindow: PropTypes.bool,
  canEditSmartLinks: PropTypes.bool,
  lookbackWindow: PropTypes.any,
  startDate: PropTypes.any,
  setAttributionWindowSettingsIsOpen: PropTypes.func,
  handleUpdateDateRange: PropTypes.func,
  endDate: PropTypes.any,
  aggregation: PropTypes.any,
  trackingLink: PropTypes.object,
  aggregations: PropTypes.any,
  setAggregation: PropTypes.func,
};

export default DetailHeader;
