import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Autosuggest from 'react-autosuggest';

import Loading from 'shared/Loading';

import './autosuggest.css';

import { toggleTrackPodcast, createByItunesUrl } from 'shared/api';

export default class PodcastTracker extends Component {
  constructor(props) {
    super(props);

    this.handleAutosuggest = this.handleAutosuggest.bind(this);
    this.handleClearSuggestions = this.handleClearSuggestions.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.showUrlSearchBar = this.showUrlSearchBar.bind(this);
    this.showAutosuggest = this.showAutosuggest.bind(this);
    this.onUrlChange = this.onUrlChange.bind(this);
    this.handleCreateByItunesUrl = this.handleCreateByItunesUrl.bind(this);

    this.state = {
      autoSuggest: !props.urlSearch,
      urlSearch: !!props.urlSearch,
      loading: false,
      suggestions: [],
      name: '',
      url: '',
    };
  }

  handleAutosuggest({ value }) {
    const params = { q: value.trim() };

    axios.get('/search', { params }).then(res => {
      if (this.state.name && this.state.name.trim() == params.q) {
        this.setState({ suggestions: res.data.results });
      }
    });
  }

  handleClearSuggestions() {
    this.setState({ suggestions: [] });
  }

  handleSuggestionSelected(event, { suggestion }) {
    this.setState({ loading: true });
    toggleTrackPodcast({ id: suggestion.slug, teamId: this.props.teamId }).then(
      res => {
        if (this.props.callback) {
          this.props.callback({ success: true });
          return;
        }
        if (this.props.nextUrl) {
          window.location = this.props.nextUrl;
        } else {
          this.setState({ loading: false, name: '' });
        }
      },
      error => {
        this.props.callback({ error: true });
      },
    );
  }

  showUrlSearchBar() {
    this.setState({
      autoSuggest: false,
      urlSearch: true,
    });
  }

  showAutosuggest() {
    this.setState({
      autoSuggest: true,
      urlSearch: false,
      error: null,
    });
  }

  handleCreateByItunesUrl() {
    createByItunesUrl({ url: this.state.url, teamId: this.props.teamId }).then(res => {
      if (res.data.error) {
        this.setState({ loading: false, error: res.data.error });
        if (this.props.callback) {
          this.props.callback({ error: true });
        }
        this.props.callback({ success: true });
        return;
      }
      if (this.props.callback) {
        this.props.callback({ success: true });
        return;
      }
      location.reload();
    });
  }

  onUrlChange(e) {
    this.setState({
      url: e.target.value,
      error: null,
    });
  }

  renderSuggestion(suggestion) {
    const displayTitle =
      suggestion.title.length > 50 ? `${suggestion.title.slice(0, 50)}...` : suggestion.title;
    let { imageClass, titleClass } = this.props;

    imageClass = imageClass || 'w-20 pr2';
    titleClass = titleClass || 'w-80';

    return (
      <div className="pointer link pa2 flex flex-wrap items-center">
        <div className={imageClass}>
          {suggestion.displayImageUrl && (
            <img className="tc center" title={suggestion.title} src={suggestion.displayImageUrl} />
          )}
        </div>
        <div className={titleClass}>
          <div className="f6 tl" title={suggestion.title}>
            {suggestion.tracked ? '✅' : ''}
            {displayTitle}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { suggestions, name, loading, url, error } = this.state;

    const { placeholder, autofocus, wrapperClass } = this.props;

    if (loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div className={wrapperClass}>
        {this.state.autoSuggest && (
          <div>
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={this.handleAutosuggest}
              onSuggestionsClearRequested={this.handleClearSuggestions}
              onSuggestionSelected={this.handleSuggestionSelected}
              getSuggestionValue={suggestion => suggestion.trackName}
              shouldRenderSuggestions={value => value.trim().length > 1}
              renderSuggestion={this.renderSuggestion}
              inputProps={{
                className: 'pa1 b--moon-gray ba br2 silver w-100 w5',
                placeholder: placeholder ? placeholder : 'Search...',
                value: name,
                onChange: e => this.setState({ name: e.target.value }),
                autoFocus: autofocus,
              }}
            />
            <div
              onClick={e => this.showUrlSearchBar()}
              className="f7 fl mt1 dark-blue header-font mb2 b"
            >
              Don't see your podcast?
              <span className="link underline ml1 pointer">Search by iTunes url.</span>
            </div>
          </div>
        )}
        {this.state.urlSearch && (
          <div>
            <div className="cf">
              <input
                type="text"
                placeholder="Apple Podcasts URL"
                name="url"
                onChange={this.onUrlChange}
                className="pa1 b--moon-gray ba br2 silver fl w5 w-60"
              />
              <button
                className="fl w-30 pa1 bg-blue pointer f5 dim white dib br2 ml1"
                onClick={this.handleCreateByItunesUrl}
              >
                Add podcast
              </button>
            </div>
            {error && <div className="red">{error}</div>}
            <div
              className="f7 fl mt1 dark-blue header-font underline pointer"
              onClick={e => this.showAutosuggest()}
            >
              {' '}
              Search by name
            </div>
          </div>
        )}
      </div>
    );
  }
}
