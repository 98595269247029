import React, { useState, useEffect } from 'react';
import SidebarTab from './SidebarTab';
import openIcon from 'images/icons/sidebar/open.svg';
import collapsedIcon from 'images/icons/sidebar/collapsed.svg';
import { localStorageHelper as ls } from 'shared/helpers';

const SidebarCategory = ({ category, name, collapsed, index, context }) => {
  const [isCollapsed, setCollapsed] = useState(collapsed);

  useEffect(() => {
    const store = new ls(context);
    let setter = store.get('collapsedState');
    setter[index] = isCollapsed ? 1 : 0;
    store.set('collapsedState', setter);
  }, [isCollapsed]);

  const renderSelected = () => {
    if (!isCollapsed) {
      return null;
    }
    const tab = category.find(tab => tab.active);
    if (!tab) {
      return null;
    }
    return (
      <a key={tab.title} style={{ textDecoration: 'none' }} href={tab.url}>
        <SidebarTab tab={tab} />
      </a>
    );
  };

  return (
    <div>
      {name !== '' && (
        <div onClick={() => setCollapsed(!isCollapsed)} style={{ padding: '12px 0' }}>
          <img
            style={{ position: 'relative', cursor: 'pointer', top: '7px', marginRight: '8px' }}
            src={isCollapsed ? collapsedIcon : openIcon}
          />
          <span
            className={`header-font`}
            style={{
              fontSize: '13px',
              color: 'white',
              cursor: 'pointer',
              fontWeight: 700,
              opacity: 0.8,
              textTransform: 'uppercase',
            }}
          >
            {name}
          </span>
        </div>
      )}
      {name === '' && <div style={{ padding: '6px 0' }}></div>}
      <div style={{ overflow: 'hidden', height: isCollapsed ? '0px' : 'initial' }}>
        {category.map(tab => {
          return (
            <a key={tab.title} style={{ textDecoration: 'none' }} href={tab.url}>
              <SidebarTab tab={tab} />
            </a>
          );
        })}
      </div>
      <div>{renderSelected()}</div>
    </div>
  );
};

export default SidebarCategory;
