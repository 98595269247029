import React, { Component } from 'react';
import Checkbox from 'shared/Checkbox';

const ConnectForm = ({
  handleChange,
  buyerProfile,
  formState,
  handleSend,
  podcast: { display_image_url, title, slug, publisher },
}) => {
  const interestOptions = [
    {
      title: 'Cross-Promotion',
      value: 'interestedInCrossPromo',
    },
    {
      title: 'Advertisement',
      value: 'interestedInAds',
    },
    {
      title: 'Guest Spot',
      value: 'interestedInGuestSpot',
    },
  ];

  const getDefaultSrc = e => {
    e.target.src = `https://via.placeholder.com/90x90.png?text=${publisher}`;
  };

  const isBuyerProfile = () => {
    return !!Object.keys(buyerProfile).length;
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!Object.keys(formState).length) {
      return;
    }
    handleSend();
  };

  return (
    <div>
      <div className="bb b--light-gray pt2 mb2">
        <div className="dib">
          <h2
            style={{ maxWidth: '577px', overflow: 'hidden', fontSize: '23px' }}
            className="dib ml2"
          >
            Why do you want to request an intro to <span className="dark-blue">{title}</span>?
          </h2>
        </div>
      </div>
      <form>
        <div className="mb3">
          {interestOptions.map(interest => {
            return (
              <div className="flex justify-start pt2" style={{ whiteSpace: 'nowrap' }}>
                <Checkbox onClick={() => handleChange(interest.value)} className="di flex-none" />
                <span style={{ position: 'relative', top: '-2px' }} className="f4 pb3 flex">
                  {interest.title}
                </span>
              </div>
            );
          })}
        </div>
        <button
          onClick={handleSubmit}
          style={{ margin: '0 auto 0 0' }}
          disabled={!Object.keys(formState).length}
          className={`pv2 bn white ${
            !Object.keys(formState).length ? 'bg-gray' : 'bg-blue'
          } pointer w4 f5 br2 header-font mt3 tc relative`}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default ConnectForm;
