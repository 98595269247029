import React from 'react';
import PropTypes from 'prop-types';
import Header from 'shared/Header';
import StatCard, { StatCardContent } from 'shared/StatCard';

const StatCardHeader = ({ stats, className, reach, showIncrementalReach }) => (
  <div className={className}>
    <div className="mb3">
      <div className="b mb2 mid-gray">Top of Funnel Metrics</div>
      <div className="inline-flex gap flex-wrap w-100">
        <StatCard
          title="Impressions"
          stat={stats.impressions.total}
          tooltip="Number of downloads with ads inserted"
          inlineStyle={{ flex: 1 }}
        />
        <StatCard
          title="Reach"
          stat={reach && reach.total}
          tooltip="Number of unique devices reached"
          inlineStyle={{ flex: 1 }}
          byline={reach && showIncrementalReach && `${reach.incremental} Incremental`}
        />
        <StatCard
          title="Frequency"
          stat={reach && (parseFloat((stats.impressions.total / reach.total).toFixed(1)) || 0)}
          tooltip="Average number of ad exposures per device"
          inlineStyle={{ flex: 1 }}
        />
      </div>
    </div>
    <div className="w-100">
      <div className="b mb2 mid-gray">Conversion Metrics</div>
      <div className="inline-flex gap flex-wrap w-100">
        <StatCard
          title="Converted Devices"
          stat={stats.uniqueDownloads.total}
          inlineStyle={{ flex: 1 }}
          tooltip="Number of unique devices attributed to promotion.  Number of downloads performed by these devices shown below."
          byline={`${stats.uniqueDownloads.downloads} downloads`}
        />
        <StatCard
          title="Device Conversion Rate"
          stat={
            stats.uniqueDownloads.responseRate
              ? `${(stats.uniqueDownloads.responseRate * 100).toFixed(3)}`
              : null
          }
          tooltip="Number of unique devices divided by the number of impressions"
          inlineStyle={{ flex: 1 }}
          showLoadingIfNull={false}
          percentage
        />
        <StatCard
          title="New Converted Devices"
          stat={stats.newDownloads.total}
          tooltip="Devices that haven't downloaded an episode of the show in the past 60 days"
          inlineStyle={{ flex: 1 }}
          byline={`${stats.newDownloads.downloads} downloads`}
        />
        <StatCard
          title="New Device Conversion Rate"
          stat={
            stats.newDownloads.responseRate
              ? `${(stats.newDownloads.responseRate * 100).toFixed(3)}`
              : null
          }
          tooltip="Number of unique devices divided by the number of impressions"
          inlineStyle={{ flex: 1 }}
          showLoadingIfNull={false}
          percentage
        />
      </div>
      {stats.totalCost && stats.totalCost > 0 ? (
        <div className="inline-flex gap flex-wrap w-100 mt2">
          <StatCard
            inlineStyle={{ flex: 1 }}
            title="Total Cost"
            text
            stat={`$${stats.totalCost.toFixed(2)}`}
            tooltip="Total cost of running your promotion"
          />
          <StatCard
            inlineStyle={{ flex: 1 }}
            title="Cost Per Conversion"
            text
            stat={
              stats.uniqueDownloads.total > 0
                ? `$${(stats.totalCost / stats.uniqueDownloads.total).toFixed(2)}`
                : '-'
            }
            tooltip="Total cost divided by the number of conversions attributed to your promotion"
          />
          <StatCard
            inlineStyle={{ flex: 1 }}
            title="Cost Per New Conversion"
            text
            stat={
              stats.newDownloads.total > 0
                ? `$${(stats.totalCost / stats.newDownloads.total).toFixed(2)}`
                : '-'
            }
            tooltip="Total cost divided by the number of conversion from new unique devices attributed to your promotion"
          />
        </div>
      ) : null}
    </div>
  </div>
);

StatCardHeader.propTypes = {
  stats: PropTypes.object.isRequired,
  className: PropTypes.string,
  reach: PropTypes.object,
  showIncrementalReach: PropTypes.bool,
};

StatCardHeader.defaultProps = {
  className: '',
  reach: null,
  showIncrementalReach: true,
};

export default StatCardHeader;
