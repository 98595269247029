import React from 'react';
import PropTypes from 'prop-types';

function Checkbox({
  radioButton,
  onClick,
  isChecked,
  label,
  disabled,
  className,
  description,
  flavorText,
  small,
}) {
  return (
    <div onClick={onClick} className={`flex flex-row pointer dim ${className}`}>
      <input
        style={{ marginTop: `${small ? 2 : 3}px` }}
        type={radioButton ? 'radio' : 'checkbox'}
        readOnly
        disabled={disabled}
        checked={isChecked}
        className={`${small ? 'mr1' : 'mr2'}`}
      />
      <div>
        <div className="flex flex-row">
          <div
            className={`${small ? 'f6' : 'f5'} ${disabled ? 'moon-gray' : 'dark-gray'} ${
              description ? 'mb1' : ''
            }`}
          >
            {label}
          </div>
          <div className="i dark-gray ml1">{flavorText}</div>
        </div>
        {description && (
          <div
            style={{ lineHeight: '14px' }}
            className={`f6 ${disabled ? 'moon-gray' : 'mid-gray'}`}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  );
}

Checkbox.propTypes = {
  onClick: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  radioButton: PropTypes.bool,
  small: PropTypes.bool,
  flavorText: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  className: '',
  description: null,
  radioButton: false,
  flavorText: null,
  small: false,
};

export default Checkbox;
