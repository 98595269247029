import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Banner from 'shared/Banner';
import BoxOptionSelect from 'shared/BoxOptionSelect';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import Checkbox from 'shared/Checkbox';
import EpisodeSearchInput from 'shared/EpisodeSearchInput';
import { providerOptions } from 'shared/helpers';

const NOT_FOUND = 'NOT_FOUND';

const DetailForm = ({
  teamId,
  campaignType,
  showErrors,
  promotingPodcast,
  isInternal,
  provider,
  setProvider,
  airType,
  setAirType,
  airDate,
  setAirDate,
  episode,
  setEpisode,
  publisherName,
  setPublisherName,
  setManualIsInternal,
  name,
  setName,
  renderTeamSelector,
  selectedSellerTeam,
}) => {
  const [publisherNotFound, setPublisherNotFound] = useState(false);

  useEffect(() => {
    const setter = !!(selectedSellerTeam && selectedSellerTeam.value === NOT_FOUND);
    setPublisherNotFound(setter);
  }, [selectedSellerTeam]);

  const renderPublisherSelectFlow = () => {
    const mappedElements = [];
    if (isInternal || !campaignType) return null;
    const separator = <div className="w3 bb b--light-gray mb2 mt3" />;
    const textInput = (
      <React.Fragment>
        {separator}
        <div className="mb2 lh-copy dark-gray f4-5">
          What's the name of the publisher you're working with?
        </div>
        <input
          placeholder="Enter Publisher name (ex. iHeartMedia, NPR, Wondery)"
          value={publisherName}
          onChange={e => setPublisherName(e.target.value)}
          className={`pa2 b--moon-gray ba br2 w-100 w5 f5 ${
            showErrors && publisherName === '' && 'bw1 b--red'
          }`}
          type="text"
        />
      </React.Fragment>
    );

    const selector = (
      <React.Fragment>
        {separator}
        {renderTeamSelector()}
      </React.Fragment>
    );
    if (campaignType.value === 'dai' && renderTeamSelector()) mappedElements.push(selector);
    if (publisherNotFound || !renderTeamSelector() || campaignType.value === 'baked_in')
      mappedElements.push(textInput);
    return mappedElements.map(element => <>{element}</>);
  };

  return (
    <React.Fragment>
      {!promotingPodcast && campaignType.value === 'dai' && (
        <React.Fragment>
          <div className="mb2 lh-copy dark-gray f4-5">
            Would you like to send this SmartPromo to an external team for approval?
          </div>
          <Checkbox
            className="mb3"
            small
            radioButton
            isChecked={isInternal}
            onClick={() => setManualIsInternal(true)}
            label="No, I want to create the tracking pixel internally"
          />
          <Checkbox
            className="mb3"
            radioButton
            small
            isChecked={!isInternal}
            onClick={() => setManualIsInternal(false)}
            label="Yes, I want to send this SmartPromo to an external team for approval"
          />
          <Banner noImage>
            <div className="f6 b lh-copy">
              {isInternal
                ? "You'll be responsible for implementing the tracking pixel, or providing it to an external publisher."
                : 'The external publisher will be able to approve the SmartPromo, create a tracking pixel, and view the results of the campaign in their Chartable dashboard.'}
            </div>
          </Banner>
          <div className="w3 bb b--light-gray mv4" />
        </React.Fragment>
      )}
      <div className="mb2 lh-copy dark-gray f4-5">
        Promo Name <span className="f6">(For your records only)</span>
      </div>
      <input
        placeholder="My New Promo"
        value={name}
        onChange={e => setName(e.target.value)}
        className={`pa2 b--moon-gray ba br2 w-100 w5 f5 ${
          showErrors && name === '' && 'bw1 b--red'
        }`}
        type="text"
      />
      {isInternal && campaignType.value === 'dai' && (
        <React.Fragment>
          <div className="w3 bb b--light-gray mv4" />
          <div className="mb2 lh-copy dark-gray f4-5">
            What DAI provider will{' '}
            {promotingPodcast ? <i>{promotingPodcast.title}</i> : 'the promoting show'} use?
          </div>
          <Select
            value={provider}
            onChange={setProvider}
            options={providerOptions}
            className="f5"
          />
        </React.Fragment>
      )}
      {campaignType.value === 'baked_in' && (
        <React.Fragment>
          {isInternal ? (
            <>
              <div className="w3 bb b--light-gray mv4" />
              <div className="mb2 lh-copy dark-gray f4-5">
                Is the spot run on an Aired or Upcoming episode?
              </div>
              <BoxOptionSelect
                options={[
                  { value: 'aired', label: 'Aired' },
                  { value: 'upcoming', label: 'Upcoming' },
                ]}
                onSelectOption={newAirType => {
                  if (newAirType.value === 'aired') {
                    setAirDate(null);
                  } else if (newAirType.value === 'upcoming') {
                    setEpisode(null);
                  }
                  setAirType(newAirType);
                }}
                selectedOption={airType}
              />
            </>
          ) : (
            <React.Fragment>
              <div className="w3 bb b--light-gray mv4" />
              <div className="mb2 lh-copy dark-gray f4-5">
                Select the publish date of the episode containing the promotion
              </div>
              <ChartableDatePicker
                singleDaySelect
                handleUpdateDay={setAirDate}
                day={airDate}
                inlineStyle={{ minWidth: 'max-content', width: '16rem' }}
              />
            </React.Fragment>
          )}
          {airType &&
            (airType.value === 'upcoming' ? (
              <React.Fragment>
                <div className="w3 bb b--light-gray mv4" />
                <div className="mb2 lh-copy dark-gray f4-5">What date will the spot air?</div>
                <ChartableDatePicker
                  singleDaySelect
                  handleUpdateDay={setAirDate}
                  day={airDate}
                  disabledDays={{ before: new Date() }}
                  inlineStyle={{ minWidth: 'max-content', width: '16rem' }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="w3 bb b--light-gray mv4" />
                <div className="mb2 lh-copy dark-gray f4-5">
                  What episode of <i>{promotingPodcast.title}</i> did the spot air on?
                </div>
                <EpisodeSearchInput
                  podcastId={promotingPodcast.id}
                  teamId={teamId}
                  onSelect={setEpisode}
                  selectedEpisode={episode}
                />
              </React.Fragment>
            ))}
        </React.Fragment>
      )}
      {renderPublisherSelectFlow()}
    </React.Fragment>
  );
};

export default DetailForm;
