import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import OnboardingStep from './components/OnboardingStep';
import PodcastAddConfirmation from './components/PodcastAddConfirmation';
import Success from './components/Success';
import Welcome from './components/Welcome';

const MegaphoneOnboarding = ({ teamId, teamName, megaphoneOrganizationId }) => {
  const routes = {
    index: `/megaphone_onboarding/${teamId}/`,
    welcome: `/megaphone_onboarding/${teamId}/welcome`,
    podcastAdd: `/megaphone_onboarding/${teamId}/add_podcasts`,
    success: `/megaphone_onboarding/${teamId}/success`,
    dashboardTrackable: `/teams/${teamId}/dashboard/integrations/trackable`,
    skipTrackable: `/teams/${teamId}/dashboard?megaphone_onboarding`,
  };

  return (
    <Switch>
      <Route exact path={routes.welcome}>
        <OnboardingStep>
          <Welcome teamId={teamId} teamName={teamName} nextStep={routes.podcastAdd} />
        </OnboardingStep>
      </Route>
      <Route exact path={routes.podcastAdd}>
        <OnboardingStep>
          <PodcastAddConfirmation
            teamId={teamId}
            teamName={teamName}
            nextStep={routes.success}
            megaphoneOrganizationId={megaphoneOrganizationId}
          />
        </OnboardingStep>
      </Route>
      <Route exact path={routes.success}>
        <OnboardingStep>
          <Success
            teamId={teamId}
            teamName={teamName}
            skipTrackable={routes.skipTrackable}
            nextStep={routes.dashboardTrackable}
          />
        </OnboardingStep>
      </Route>
      <Route exact path={routes.index}>
        <Redirect to={routes.welcome} />
      </Route>
    </Switch>
  );
};

const MegaphoneOnboardingRouter = props => (
  <Router>
    <Route
      path="/megaphone_onboarding/:teamId"
      render={renderProps => <MegaphoneOnboarding {...props} {...renderProps} />}
    />
  </Router>
);

export default MegaphoneOnboardingRouter;
