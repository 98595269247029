import React from 'react';
import ThirdImage from 'images/growth.svg';
import Button from 'shared/Button';

export default function LandingPage({ teamId }) {
  return (
    <div className="center pv3">
      <section className="pa4">
        <div className="flex flex-row gap-large">
          <div>
            <div className="f2 lh-title dark-blue mb3">
              Chartable API: Work directly with Chartable data and tools
            </div>
            <div className="f4 lh-copy dark-gray force-inter fw-300 mb4">
              The Chartable API enables direct Chartable integrations for reporting and automation.
            </div>
            <Button
              onClick={() => (window.location = 'mailto:api@chartable.com')}
              size="large"
              className="w-80"
              type="primary"
            >
              Contact Sales To Get Started →
            </Button>
          </div>
        </div>
      </section>
      <section className="pa4">
        <div className="f2 tc lh-title dark-blue mb4">How it works</div>
        <div className="lh-copy">
          <p>
            The Chartable API replicates and extends the functionality of the Chartable dashboard.
            You'll be able to create and edit campaign items like SmartLinks and SmartPromos, and
            pull detailed reporting beyond the pre-configured views seen in the dash.
          </p>
          <p>To get started, follow these quick steps:</p>
        </div>
        <div className="flex flex-row gap-large">
          <div>
            <div className="f4 force-inter dark-gray mb2">
              1. <a onClick={() => (window.location = 'mailto:api@chartable.com')}>Contact sales</a>
            </div>
            <div className="lh-copy mid-gray f5 mb4 ">
              Let the sales team know that you're interested in the Chartable API. They will provide
              you with pricing information and enable it on your account.
            </div>
            <div className="f4 force-inter dark-gray mb2">2. Create an API Key</div>
            <div className="lh-copy mid-gray f5 mb4 ">
              When you see a green checkbox next to the Chartable API in the Integrations tab,
              you're ready to go! Click on the integration to create an API key.
            </div>
            <div className="f4 force-inter dark-gray mb2">3. Start Developing</div>
            <div className="lh-copy mid-gray f5">
              Check out the{' '}
              <a href="https://api.chartable.com?utm_source=dashboard_landing" target="_blank">
                Chartable API Reference
              </a>{' '}
              and start integrating Chartable data into your app!
            </div>
          </div>
          <img className="mw6 dib dn-m" src={ThirdImage} />
        </div>
      </section>

      <section className="pa4 bg-washed-blue">
        <div className="f2 tc lh-title dark-blue mb4">What You Get</div>
        <div className="flex flex-row gap-large items-center">
          <div className="lh-copy">
            We are constantly adding new features and endpoints to the Chartable API. Our current
            offerings include:
            <ul>
              <li>Podcasts</li>
              <li>Episodes</li>
              <li>SmartLinks</li>
              <li>Custom SmartLinks Domains</li>
              <li>Remarketing Pixels</li>
              <li>
                See the{' '}
                <a href="https://api.chartable.com?utm_source=dashboard_landing" target="_blank">
                  Chartable API Reference
                </a>{' '}
                for more information
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="pv5 ph4 flex justify-center items-center">
        <Button
          onClick={() => (window.location = 'mailto:api@chartable.com')}
          size="huge"
          className="mw6 w-100"
          type="primary"
        >
          Supercharge your Podcast data →
        </Button>
      </section>
    </div>
  );
}
