"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.assertNever = assertNever;
exports.isColorToken = isColorToken;
exports.pxToInt = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
// eslint-disable-next-line
function assertNever(_) {
  throw new Error('Unreachable value');
}

/**
 * Export Foundation tokens
 *
 * TODO: If Foundation merges this PR (https://ghe.spotify.net/encore/foundation/pull/239),
 * remove and re-export from Foundation.
 */

// Helper for validating whether a color string is a foundation token
function isColorToken(color) {
  return color !== undefined && _encoreFoundation.color.hasOwnProperty(color);
}

/**
 * Helper for converting a token (in string px format) to an integer.
 * @example
 * pxToInt(spacer16) // returns 16
 *  */
var pxToInt = function pxToInt(px) {
  return parseInt(px, 10);
};

/**
 * Construct a type with the properties of T where T's optional properties (K)
 * are required in the new type.
 */
exports.pxToInt = pxToInt;