import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
// import useUserInfo from 'shared/hooks/useUserInfo';
import { SingleValueWithIcon, PlaceholderWithEmptyIcon } from 'shared/ReactSelectHelpers';

import { searchTeamPodcasts, buyerPodcastSearch, getPodcast } from 'shared/api';

const { Option } = components;
const OptionWithIcon = props => (
  <Option {...props}>
    <div className="input-select__single-value flex flex-row items-center">
      <img
        src={props.data.imageUrl}
        className="input-select__icon br2 mr2"
        style={{ height: '28px' }}
      />
      <div>{props.data.title}</div>
    </div>
  </Option>
);

const PodcastSearchInput = ({
  onSelect,
  isDisabled,
  scopeToTeamPodcasts,
  defaultOption,
  maxPodcastsToFetch,
  className,
  initialPodcast,
  initialPodcastId,
  showClearInput,
  overrideTeamId,
  excludeStatistics,
}) => {
  const params = useParams();
  const teamId = params.teamId ? params.teamId : overrideTeamId;

  const [, setInputValue] = useState('');
  const [podcast, setPodcast] = useState(initialPodcast ? initialPodcast : null);
  const [loadingInitialPodcast, setLoadingInitialPodcast] = useState(false);

  useEffect(() => {
    if (!podcast && initialPodcastId) {
      setLoadingInitialPodcast(true);
      getPodcast({ podcastId: initialPodcastId, teamId })
        .then(res => setPodcast(res.data))
        .finally(() => setLoadingInitialPodcast(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchTeamPodcasts = (q, callback) => {
    // TODO: debounce this to reduce number of calls

    if (scopeToTeamPodcasts) {
      searchTeamPodcasts({ q: q, teamId, excludeStatistics }).then(res =>
        callback(res.data.results),
      );
    } else {
      buyerPodcastSearch({
        teamId,
        q: q,
        showIsInternal: false,
        filterByTrackable: false,
        includeClaimedStatus: true,
        limit: maxPodcastsToFetch,
      }).then(res => callback(res.data.results));
    }
  };

  const handleSelect = selectedPodcast => {
    setPodcast(selectedPodcast);
    onSelect(selectedPodcast);
  };

  return (
    <div className={`${className} mw-100`}>
      <AsyncSelect
        isDisabled={isDisabled || loadingInitialPodcast}
        isLoading={loadingInitialPodcast}
        defaultOptions={defaultOption ? [defaultOption] : null}
        value={
          podcast
            ? {
                title: podcast.title,
                label: podcast.title,
                value: podcast.id,
                icon: podcast.imageUrl ? podcast.imageUrl : null,
              }
            : defaultOption
            ? defaultOption
            : null
        }
        cacheOptions
        loadOptions={handleSearchTeamPodcasts}
        onChange={handleSelect}
        onInputChange={setInputValue}
        placeholder="Search by podcast title"
        components={{
          Placeholder: PlaceholderWithEmptyIcon,
          SingleValue: SingleValueWithIcon,
          Option: OptionWithIcon,
        }}
        noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Type to search' : 'No results')}
      />
      {showClearInput && (
        <div className="link blue pointer f6 mt2" onClick={() => handleSelect(null)}>
          Clear Podcast
        </div>
      )}
    </div>
  );
};

OptionWithIcon.propTypes = {
  data: PropTypes.obj,
};

PodcastSearchInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  scopeToTeamPodcasts: PropTypes.bool,
  defaultOption: PropTypes.object,
  maxPodcastsToFetch: PropTypes.number,
  className: PropTypes.string,
  initialPodcast: PropTypes.object,
  initialPodcastId: PropTypes.string,
  showClearInput: PropTypes.bool,
  excludeStatistics: PropTypes.bool,
  overrideTeamId: PropTypes.string,
};

PodcastSearchInput.defaultProps = {
  isDisabled: false,
  scopeToTeamPodcasts: true,
  defaultOption: null,
  maxPodcastsToFetch: 10,
  showClearInput: false,
  overrideTeamId: null,
  excludeStatistics: false,
};

export default PodcastSearchInput;
