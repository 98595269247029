import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import LoadingGif from 'images/loading.gif';

export default function Loading({ className, fullScreen }) {
  return (
    <div className={classNames(fullScreen && 'vh-100 dt w-100', className)}>
      <div className={fullScreen ? 'dtc v-mid h-100' : ''}>
        <img
          src={LoadingGif}
          alt="Loading"
          title="Loading"
          className={fullScreen ? 'dtc center' : ''}
        />
      </div>
    </div>
  );
}

Loading.propTypes = {
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
};

Loading.defaultProps = {
  fullScreen: false,
  className: '',
};
