import React from 'react';
import Button from 'shared/Button';

const MegaphonSignIn = () => {
  const renderMegaphoneButton = () => (
    <Button
      style={{ backgroundColor: '#7474ec' }}
      size="large"
      className="ph3 mt2 pv2 input-reset bn white pointer f5 dib dim w-100 br2 header-font"
      onClick={() => (window.location = '/oauth/authorize_megaphone')}
    >
      Log in with Megaphone
    </Button>
  );

  return (
    <div className={`mw8 center pv3 ph2 ph0-ns`}>
      <div className={`mt3 center w-75 mw6`}>
        <div className={`f2 gray mb3`}>Log in to your account</div>
        <div className={`ba br2 pa2 center w-100 b--primary bg-white`}>
          {renderMegaphoneButton()}
        </div>
      </div>
    </div>
  );
};

export default MegaphonSignIn;
