import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { deleteMegaphoneOrganization } from 'shared/api/v1';
import Banner from 'shared/Banner';
import useUserInfo from 'shared/hooks/useUserInfo';

import DisconnectConfirmationModal from './DisconnectConfirmationModal';

const ConnectionDetail = ({ onUnlink, organization }) => {
  const { teamName, canManageMegaphoneConnection, hasMegaphoneBundle } = useUserInfo();
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [error, setError] = useState(null);

  const handleUnlinkMegaphoneOrganization = async () => {
    if (requestInProgress) return;
    setRequestInProgress(true);
    setError(null);

    try {
      const response = await deleteMegaphoneOrganization({
        id: organization.id,
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      onUnlink();
    } catch (e) {
      setError(e.message ? e.message : 'Failed to disconnect megaphone organization');
      setRequestInProgress(false);
      setConfirmationIsOpen(false);
    }
    setConfirmationIsOpen(false);
  };

  return (
    <>
      {!canManageMegaphoneConnection && (
        <Banner className="mb2 b">
          Only team Admins and Owners can disconnect a Megaphone Organization
        </Banner>
      )}
      {error && (
        <Banner error className="mb2 b" onClick={() => setError(null)}>
          {error}
        </Banner>
      )}
      <div className="pa3 card">
        <div className="flex flex-wrap justify-between mb4">
          <div>
            <div className="force-inter fw-500 dark-gray f4 mb1">{teamName}</div>
            <div className="gray f6 fw-500">Chartable team</div>
          </div>
          {canManageMegaphoneConnection && (
            <div
              className={requestInProgress ? 'gray' : 'red pointer dim'}
              onClick={() => setConfirmationIsOpen(true)}
            >
              Disconnect
            </div>
          )}
        </div>
        <div className="gray f6 fw-500 mb1">Linked Organization</div>
        <div className="flex flex-wrap justify-between items-baseline">
          <div className="force-inter b dark-gray f5">{organization.name}</div>
          <div className="force-inter gray f6">
            {`Connected ${moment(new Date(organization.created_at * 1000)).format('MMM Do, YYYY')}`}
          </div>
        </div>
      </div>
      <DisconnectConfirmationModal
        isOpen={confirmationIsOpen}
        onClose={() => setConfirmationIsOpen(false)}
        onConfirm={handleUnlinkMegaphoneOrganization}
        hasMegaphoneBundle={hasMegaphoneBundle}
      />
    </>
  );
};

ConnectionDetail.propTypes = {
  onUnlink: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
};

export default ConnectionDetail;
