/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { getPodcastDemographics, getTeamDemographics } from 'shared/api/v1';
import GeoView from 'shared/GeoView';
import { capitalizeFirstLetter } from 'shared/helpers';
import useUserInfo from 'shared/hooks/useUserInfo';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import Sidebar from 'shared/Sidebar';
import UserContext from 'shared/UserContext';

import DemographicsHeader from './components/DemographicsHeader';
import DemographicsView from './components/DemographicsView';
import PlayersView from './components/PlayersView';

const sourceType = {
  trackable: 'trackable',
  spotify: 'spotify',
};

function Audience(props) {
  const { location, history, match, defaultPodcast } = props;

  const { teamId } = match.params;
  const { allowedOnAllPodcasts } = useUserInfo();

  // Until we switch over Geo endpoints to the public api, we still need the initial podcast data
  const [selectedPodcast, setSelectedPodcast] = useState(defaultPodcast ? defaultPodcast : null);
  const [selectedView, setView] = useState('demo');

  const [demographicsData, setDemographicsData] = useState({
    trackable: null,
    spotify: null,
  });
  const [loadingDemographicsData, setLoadingDemographics] = useState({
    trackable: false,
    spotify: false,
  });
  const [demographicsDataLoadErrors, setDemographicsDataLoadErrors] = useState({
    trackable: false,
    spotify: false,
  });

  const hidden = 'dn';
  const visible = 'db';

  const searchParams = new URLSearchParams(location.search);
  const podcastId = searchParams.get('podcast_id');

  useEffect(() => {
    if (defaultPodcast) {
      history.replace({
        pathname: match.url,
        search: `?podcast_id=${defaultPodcast.id}`,
      });
    }
  }, []);

  useEffect(() => {
    handleGetDemographics(sourceType.spotify);
    handleGetDemographics(sourceType.trackable);
  }, [podcastId]);

  const getIsVisible = view => {
    return selectedView === view;
  };

  const handleGetDemographics = async source => {
    loadingDemographicsData[source] = true;
    demographicsDataLoadErrors[source] = false;
    setLoadingDemographics({ ...loadingDemographicsData });
    setDemographicsDataLoadErrors({ ...demographicsDataLoadErrors });

    try {
      let response;
      if (podcastId) {
        response = await getPodcastDemographics({
          source: capitalizeFirstLetter(source),
          podcastId,
        });
      } else {
        response = await getTeamDemographics({ source: capitalizeFirstLetter(source) });
      }
      demographicsData[source] = response.data;
      setDemographicsData({ ...demographicsData });
    } catch (_) {
      demographicsDataLoadErrors[source] = true;
      setDemographicsDataLoadErrors({ ...demographicsDataLoadErrors });
    } finally {
      loadingDemographicsData[source] = false;
      setLoadingDemographics({ ...loadingDemographicsData });
    }
  };

  const handleSelectPodcast = selection => {
    if (selection.type === 'all') {
      setSelectedPodcast(null);
    } else {
      setSelectedPodcast(selection.data);
    }

    history.replace({
      pathname: match.url,
      search: selection.type === 'all' ? '' : `?podcast_id=${selection.data.id}`,
    });
  };

  // If the user is not allowed to view all podcasts, but tries to anyway (selectedPodcast === null)
  // throw them to this error page
  if (!selectedPodcast && !allowedOnAllPodcasts) {
    return (
      <div className="pa3 tc w-100">
        <h2 className="black-90">You don't have permission view this data</h2>
        <h5 className="mt2 black-70">Talk to your team admin to gain access</h5>
      </div>
    );
  }

  return (
    <div className="pa3">
      <h3 className="mt0 mb2">Podcast</h3>
      <PodcastSwitcher
        teamId={teamId}
        className="mb2"
        defaultToAll={allowedOnAllPodcasts}
        includeAll={allowedOnAllPodcasts}
        includeNone={false}
        selectedPodcast={selectedPodcast || null}
        onSelect={handleSelectPodcast}
        loading={false}
        excludeStatistics
      />
      <DemographicsHeader selectedView={selectedView} setView={setView} />
      {/* using css display instead of conditional rendering to prevent re-renders.  */}
      {/* This is because the GeoView takes a long time to load data and we want to prevent it from rerendering */}
      <div className={getIsVisible('geo') ? visible : hidden}>
        <GeoView
          pivotType="podcastOverview"
          teamId={teamId}
          selectedPodcast={selectedPodcast}
          shouldIgnoreUpdates={!getIsVisible('geo')}
        />
      </div>
      <div className={getIsVisible('demo') ? visible : hidden}>
        <DemographicsView
          podcastId={podcastId}
          spotifyDemographicsData={demographicsData.spotify}
          spotifyDemographicsFailedToLoad={demographicsDataLoadErrors.spotify}
          loadingSpotifyDemographics={loadingDemographicsData.spotify}
        />
      </div>
      <div className={getIsVisible('player') ? visible : hidden}>
        <PlayersView
          data={
            demographicsData.trackable && demographicsData.trackable.playerDemographics
              ? demographicsData.trackable.playerDemographics
              : null
          }
          loading={loadingDemographicsData.trackable}
          error={demographicsDataLoadErrors.trackable}
        />
      </div>
    </div>
  );
}

const DashboardDemographics = props => {
  return (
    <UserContext.Provider value={props.userInfo}>
      <Sidebar sidebar={props.sidebar}>
        <Router>
          <Route
            path="/teams/:teamId/dashboard/audience"
            render={renderProps => <Audience {...props} {...renderProps} />}
          />
        </Router>
      </Sidebar>
    </UserContext.Provider>
  );
};

export default DashboardDemographics;
