import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';

import FormTitle from 'shared/FormTitle';

export default function FormSelect({
  options,
  value,
  onChange,
  title,
  tooltip,
  className,
  placeholder,
}) {
  const optionValue = options.filter(option => option.value === value)[0];
  const derivedClassName = className ? className : 'mb4';

  return (
    <div className={derivedClassName}>
      {title && <FormTitle title={title} tooltip={tooltip} />}
      <Select
        value={optionValue}
        onChange={e => onChange(e.value)}
        options={options}
        placeholder={placeholder}
        className="f5"
      />
    </div>
  );
}

FormSelect.defaultProps = {
  options: PropTypes.array.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

FormSelect.defaultProps = {
  tooltip: null,
  className: '',
  placeholder: 'Select...',
};
