import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const SmallTopBar = ({ backText, text, podcast, backUrl, locationState, hasRouter }) => (
  <div className="bb b--light-gray w-100 bg-white pv2 ph4 items-center f5">
    <div className="flex items-center justify-between" style={{ height: '1.5rem' }}>
      {hasRouter ? (
        <Link
          to={{
            pathname: backUrl,
            state: locationState,
          }}
          className="link blue pointer"
        >
          « {backText}
        </Link>
      ) : (
        <a className="link blue" href={backUrl}>
          « {backText}
        </a>
      )}
      {podcast ? (
        <div className="bg-white flex flex-row items-center pr1 mw6 br1 w4-5">
          <img style={{ height: '1.5rem' }} src={podcast.displayImageUrl} alt="" />
          <div className="f6 dark-gray ml1 truncate">{podcast.shortTitle}</div>
        </div>
      ) : (
        <div className="dark-gray ml1 truncate">{text}</div>
      )}
    </div>
  </div>
);

SmallTopBar.propTypes = {
  backUrl: PropTypes.string.isRequired,
  podcast: PropTypes.object,
  locationState: PropTypes.object,
  text: PropTypes.string,
  backText: PropTypes.string,
};

SmallTopBar.defaultProps = {
  podcast: null,
  text: '',
  backText: '',
  locationState: null,
  hasRouter: true,
};

export default SmallTopBar;
