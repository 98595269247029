import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// Turn dict into urlencoded search parameters, updated to exclude null keys
// https://stackoverflow.com/questions/7045065/how-do-i-turn-a-javascript-dictionary-into-an-encoded-url-string
function serialize(obj) {
  var str = [];
  for (var p in obj) {
    if (!obj[p]) {
      continue;
    }
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
  }
  return str.join('&');
}

const ReportLink = ({ params, children }) => {
  const { teamId } = useParams();
  const url = `/teams/${teamId}/dashboard/reports/create?${serialize(params)}`;

  return (
    <a className="link no-underline inherit" target="_blank" href={url} rel="noreferrer">
      {children}
    </a>
  );
};

ReportLink.propTypes = {
  params: PropTypes.object,
  children: PropTypes.node,
};

export default ReportLink;
