import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';

function getEmailString(promo) {
  const subject = `${promo.buyerTeam} needs you to install Trackable for an upcoming promo`;
  const body = encodeURI(`Hello,

${promo.buyerTeam} is using Chartable to measure an upcoming podcast promo on your show, ${promo.sellerPodcast.title}.

Chartable indicates that you don't have a Trackable Prefix installed on your show. The prefix allows Chartable to measure downloads and conversions for this promotion.

In order to install Trackable, sign up for a free Chartable account on www.chartable.com, then follow the instructions to get it installed with your provider.

Please feel free to email adops@chartable.com for assistance on setting up your account or installing Trackable.

Best Regards,
${promo.buyerTeam}`);
  return `mailto:?subject=${subject}&body=${body}`;
}

function LargeStatusWarnings({ promo, teamId, isSeller }) {
  if (promo.isArchived) return null;

  switch (promo.status.code) {
    case 'TRACKABLE_NOT_ENABLED':
      return (
        <div className="lh-copy f4">
          <div className="mb3 pa3 b--red ba br2">
            {!promo.trackableErrors.buyerTeamPodcastHasTrackable && (
              <div>
                <div className="red b mb1">
                  {promo.buyerPodcast.title} does not have trackable installed
                </div>
                <div className="f5 mb3">
                  Without the trackable prefix we cannot attribute download data. If your promo is
                  currently running, this will result in a loss of data until the prefix is
                  reinstalled.
                </div>
                {!isSeller ? (
                  <a
                    href={`/teams/${teamId}/dashboard/integrations/trackable`}
                    target="_blank"
                    className="mt2 f5 link blue pointer b"
                  >
                    Click here for instructions on how to install trackable.
                  </a>
                ) : (
                  <div className="f5 lh-copy">
                    Reach out to the seller team to confirm they have trackable installed.
                  </div>
                )}
              </div>
            )}
            {promo.campaignType !== 'dai' &&
              !promo.trackableErrors.sellerTeamPodcastHasTrackable && (
                <div>
                  <div className="red b mb1">
                    {promo.sellerPodcast.title} does not have trackable installed
                  </div>
                  <div className="f5 mb3">
                    Without the trackable prefix we cannot attribute download data. If your promo is
                    currently running, this will result in a loss of data until the prefix is
                    reinstalled.
                  </div>
                  {!promo.isInternal &&
                    (isSeller ? (
                      <a
                        href={`/teams/${teamId}/dashboard/integrations/trackable`}
                        target="_blank"
                        className="mt2 f5 link blue pointer b"
                      >
                        Click here for instructions on how to install trackable.
                      </a>
                    ) : (
                      <a
                        href={getEmailString(promo)}
                        target="_blank"
                        className="pointer blue highlight dim no-underline f4"
                      >
                        Please reach out to the publisher to ask them to install a trackable prefix
                        on this podcast
                      </a>
                    ))}
                </div>
              )}
          </div>
          <div className="mb4 f5">
            If you have any questions{' '}
            <a href="mailto:adops@chartable.com" target="_blank">
              please contact support.
            </a>
          </div>
        </div>
      );
    case 'NO_EPISODE_ON_DROP_DATE':
      if (!isSeller) return null;
      return (
        <div className="lh-copy f4 b--gold ba br2 pa3 mb3">
          <div className="mb3">
            <div className="mb2">
              This promo was scheduled to air on {moment(promo.startedAt).format('MM/DD/YYYY')}{' '}
              however no episode was published on the scheduled date.
            </div>
            <div>Please note it can take up to 24 hours from publish time to detect the spot.</div>
          </div>
          <div className="mb2 f5">
            If you have any questions{' '}
            <a href="mailto:adops@chartable.com" target="_blank">
              please contact support.
            </a>
          </div>
        </div>
      );
    case 'NO_SELLER_TEAM_PODCAST':
      return (
        <Banner className="f5 mb3" error>
          <b>This promo has no promoting podcast assigned to it.</b>
          <div className="mt1">
            The podcast that will promote <i>{promo.buyerPodcast.title}</i> is not assigned to this
            promo. Please get in touch with support to resolve this issue.
          </div>
        </Banner>
      );
    case 'MEGAPHONE_LOGS_UNAVAILABLE':
      return (
        <Banner className="f5 mb3" error>
          <b>This promo cannot be measured with Megaphone</b>
          <div className="mt1">
            Please install the Trackable prefix by following instructions on the{' '}
            <a
              href={`/teams/${teamId}/dashboard/integrations/trackable`}
              target="_blank"
              className="mt2 f5 link blue pointer b"
            >
              integrations page
            </a>
            .
          </div>
        </Banner>
      );
    default:
      return null;
  }
}

LargeStatusWarnings.propTypes = {
  promo: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired,
  isSeller: PropTypes.bool,
};

LargeStatusWarnings.defaultProps = {
  isSeller: false,
};

export default LargeStatusWarnings;
