"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.visuallyHidden = void 0;
var _styledComponents = require("styled-components");
var visuallyHidden = function visuallyHidden() {
  return (0, _styledComponents.css)(["border:0;clip:rect(0,0,0,0);height:1px;margin:-1px;overflow:hidden;padding:0;position:absolute;width:1px;"]);
};
exports.visuallyHidden = visuallyHidden;