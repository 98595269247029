import React from 'react';
import PropTypes from 'prop-types';

import FormTitle from 'shared/FormTitle';

export default function FormInputCheckbox({ name, value, onChange, title }) {
  return (
    <div className="mb4">
      <div className="f5">
        <FormTitle title={title} />
        <input type="checkbox" checked={value} name={name} onChange={onChange} />
        <label className="ml2">{name}</label>
      </div>
    </div>
  );
}

FormInputCheckbox.defaultProps = {
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
