import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Loading from 'shared/Loading';
import { getTrackingLink } from 'shared/api/v1';
import Sidebar from 'shared/Sidebar';

import LinkTaggerView from './components/LinkTaggerView';

export default class LinkTagger extends Component {
  constructor(props) {
    super(props);

    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);

    this.state = {
      loading: true,
      trackingLink: null,
      parameters: {},
      url: null,
      copied: false,
    };
  }

  componentDidMount() {
    this.handleLoadTrackingLink();
  }

  handleLoadTrackingLink() {
    const { teamId, trackingLinkId } = this.props;

    this.setState({ loading: true });

    getTrackingLink({
      trackingLinkId,
      teamId,
    }).then(res => {
      this.setState({
        trackingLink: res.data,
        url: res.data.slugUrl,
        loading: false,
        parameters: {},
      });
    });
  }

  handleChangeInput(event) {
    let { parameters, url, trackingLink } = this.state;

    parameters[event.target.name] = event.target.value;

    let params = Object.entries(parameters).map(([key, val]) =>
      val ? `${key}=${encodeURIComponent(val)}` : null,
    );

    let str = params.filter(Boolean).join('&');

    url = trackingLink.slugUrl;
    if (str && str.length > 1) {
      url = url + '?' + str;
    }

    this.setState({
      parameters: parameters,
      url: url,
    });
  }

  handleClickCopy(event) {
    // Hack alert!
    let dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', this.state.url);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 1000);
  }

  render() {
    const { loading, trackingLink, url, copied } = this.state;

    const { teamId, sidebar, allLinksPath } = this.props;

    if (loading) {
      return <Loading fullScreen />;
    }

    return (
      <Sidebar sidebar={sidebar}>
        <LinkTaggerView
          trackingLink={trackingLink}
          url={url}
          onChangeInput={this.handleChangeInput}
          onClickCopy={this.handleClickCopy}
          copied={copied}
          teamId={teamId}
          allLinksPath={allLinksPath}
        />
      </Sidebar>
    );
  }
}

LinkTagger.propTypes = {
  teamId: PropTypes.string.isRequired,
  trackingLinkId: PropTypes.string.isRequired,
};
