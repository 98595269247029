import React, { Component } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import { compose } from 'recompose';
import {
  getAudienceOverlap,
  getDemographics,
  getEpisodes,
  getPlayerData,
  getTeamPodcasts,
} from 'shared/api';
import { getConsumption, getDownloads, getPlays } from 'shared/api/v1';
import {
  formatMultiplePodcastDataToGoogleCharts,
  formatPlatformDataToGoogleCharts,
  getFlooredUtcDateTime,
} from 'shared/helpers';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';
import UserContext, { withUser } from 'shared/UserContext';

import DashboardCompareView from './components/DashboardCompareView';

class DashboardCompare extends Component {
  constructor(props) {
    super(props);

    this.handleChangePodcast = this.handleChangePodcast.bind(this);
    this.handleChangeEpisode = this.handleChangeEpisode.bind(this);
    this.handleGetDownloadsHistory = this.handleGetDownloadsHistory.bind(this);
    this.handleGetConsumptionSeries = this.handleGetConsumptionSeries.bind(this);
    this.handleGetPlaysHistory = this.handleGetPlaysHistory.bind(this);
    this.handleGetDemographics = this.handleGetDemographics.bind(this);
    this.handleGetPlayerDemographics = this.handleGetPlayerDemographics.bind(this);
    this.handleGenerateAudienceOverlap = this.handleGenerateAudienceOverlap.bind(this);
    this.podcastIds = this.podcastIds.bind(this);
    this.episodeIds = this.episodeIds.bind(this);
    this.pushHistory = this.pushHistory.bind(this);
    this.handleChangeDownloadsEndDate = this.handleChangeDownloadsEndDate.bind(this);
    this.handleChangeDownloadsStartDate = this.handleChangeDownloadsStartDate.bind(this);
    this.handleChangePlaysEndDate = this.handleChangePlaysEndDate.bind(this);
    this.handleChangePlaysStartDate = this.handleChangePlaysStartDate.bind(this);

    const initialStartDate = moment().subtract(3, 'months').toDate();
    const initialEndDate = new Date();

    this.state = {
      podcastOne: null,
      podcastOneEpisodes: null,
      episodeOne: null,
      podcastTwo: null,
      podcastTwoEpisodes: null,
      podcasts: [],
      episodeTwo: null,
      loading: false,
      loadingDownloadsHistory: false,
      downloadsHistory: null,
      downloadsHistoryAggregation: null,
      consumptionSeries: null,
      loadingConsumptionSeries: false,
      loadingPlaysHistory: false,
      playsHistory: null,
      playsHistoryAggregation: null,
      loadingPlayerDemographics: null,
      playerDemographics: null,
      loadingDemographics: null,
      demographics: null,
      loadingData: null,
      loadingPodcastOne: false,
      loadingPodcastTwo: false,
      loadingAudienceOverlap: false,
      generatedAudienceOverlap: false,
      downloadsStartDate: initialStartDate,
      downloadsEndDate: initialEndDate,
      playsStartDate: initialStartDate,
      playsEndDate: initialEndDate,
      loadingPodcasts: true,
    };
  }

  componentDidMount() {
    const { location, podcastsCount } = this.props;
    const { podcast_ids, episode_ids } = qs.parse(location.search.slice(1));

    getTeamPodcasts({
      limit: this.props.numberOfDefaultOptionsToFetch,
      teamId: this.props.teamId,
    }).then(({ data }) => {
      const podcasts = data.results;
      if (podcast_ids) {
        const podcastOne = podcast_ids[0] && podcasts.find(p => p.id === podcast_ids[0]);
        const podcastTwo = podcast_ids[1] && podcasts.find(p => p.id === podcast_ids[1]);
        if (episode_ids) {
          this.handleChangePodcast(
            podcastOne,
            podcastTwo,
            Number.parseInt(episode_ids[0]),
            Number.parseInt(episode_ids[1]),
          );
        } else {
          this.handleChangePodcast(podcastOne, podcastTwo);
        }
      }

      this.setState(
        {
          podcasts,
          loadingPodcasts: false,
        },
        () => {
          if (podcastsCount === 1) {
            const { podcasts } = this.state;
            this.handleChangePodcast(podcasts[0], podcasts[0]);
          }
        },
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      podcastOne,
      podcastTwo,
      episodeOne,
      episodeTwo,
      loadingDemographics,
      loadingPlayerDemographics,
      loadingPlaysHistory,
      loadingDownloadsHistory,
      loadingConsumptionSeries,
      loadingPodcastOne,
      loadingPodcastTwo,
    } = this.state;

    if (
      !loadingPodcastOne &&
      !loadingPodcastTwo &&
      (prevState.loadingPodcastOne !== loadingPodcastOne ||
        prevState.loadingPodcastTwo !== loadingPodcastTwo ||
        prevState.episodeOne !== episodeOne ||
        prevState.episodeTwo !== episodeTwo)
    ) {
      this.pushHistory();
      if (podcastOne || podcastTwo) {
        const numDays = this.episodeIds().length > 0 ? 30 : 180;
        const aggregation = this.episodeIds().length > 0 ? 'daily' : 'weekly';
        this.handleGetConsumptionSeries();
        this.handleGetDownloadsHistory({ numDays, aggregation });
        this.handleGetPlaysHistory({ numDays, aggregation });
        this.handleGetDemographics();
        this.handleGetPlayerDemographics();
      }
    }

    const loadingData =
      loadingDownloadsHistory ||
      loadingDemographics ||
      loadingPlaysHistory ||
      loadingConsumptionSeries ||
      loadingPlayerDemographics;
    if (prevState.loadingData != loadingData) {
      this.setState({ loadingData: loadingData });
    }
  }

  handleChangeDownloadsStartDate(date) {
    this.handleGetDownloadsHistory({ startDate: date });
  }

  handleChangeDownloadsEndDate(date) {
    this.handleGetDownloadsHistory({ endDate: date });
  }

  handleChangePlaysEndDate(date) {
    this.handleGetPlaysHistory({ endDate: date });
  }

  handleChangePlaysStartDate(date) {
    this.handleGetPlaysHistory({ startDate: date });
  }

  pushHistory() {
    const queryString = qs.stringify({
      podcast_ids: this.podcastIds() || undefined,
      episode_ids: this.episodeIds() || undefined,
    });
    this.props.history.push(`${this.props.match.url}?${queryString}`);
  }

  podcastIds() {
    const { podcastOne, podcastTwo } = this.state;
    const ret = [];
    if (podcastOne) ret.push(podcastOne.id);
    if (podcastTwo) ret.push(podcastTwo.id);
    return ret;
  }

  episodeIds(usingCid = false) {
    const { episodeOne, episodeTwo } = this.state;
    const ret = [];
    if (episodeOne) ret.push(usingCid ? episodeOne.cid : episodeOne.id);
    if (episodeTwo) ret.push(usingCid ? episodeTwo.cid : episodeTwo.id);
    return ret;
  }

  handleChangePodcast(podcastOne, podcastTwo, episodeOneId, episodeTwoId) {
    if (this.state.loadingData || this.state.loadingAudienceOverlap) return;
    if (podcastOne) {
      this.setState({
        podcastOne,
        podcastOneEpisodes: null,
        episodeOne: null,
        loadingPodcastOne: true,
        generatedAudienceOverlap: false,
        loadingData: true,
      });
      getEpisodes({ teamId: this.props.teamId, podcastId: podcastOne.id }).then(res => {
        const updatedState = { podcastOneEpisodes: res.data, loadingPodcastOne: false };
        if (episodeOneId) {
          const episode = res.data.filter(e => e.id === episodeOneId)[0];
          updatedState.episodeOne = episode;
        }
        this.setState(updatedState);
      });
    }

    if (podcastTwo) {
      this.setState({
        podcastTwo,
        podcastTwoEpisodes: null,
        episodeTwo: null,
        loadingPodcastTwo: true,
        generatedAudienceOverlap: false,
        loadingData: true,
      });
      getEpisodes({ teamId: this.props.teamId, podcastId: podcastTwo.id }).then(res => {
        const updatedState = { podcastTwoEpisodes: res.data, loadingPodcastTwo: false };
        if (episodeTwoId) {
          const episode = res.data.filter(e => e.id === episodeTwoId)[0];
          updatedState.episodeTwo = episode;
        }
        this.setState(updatedState);
      });
    }
  }

  handleChangeEpisode(episodeOne, episodeTwo) {
    if (this.state.loadingData || this.state.loadingAudienceOverlap) return;

    if (episodeOne) {
      this.setState({
        episodeOne,
        generatedAudienceOverlap: false,
        loadingData: true,
      });
    }

    if (episodeTwo) {
      this.setState({
        episodeTwo,
        generatedAudienceOverlap: false,
        loadingData: true,
      });
    }
  }

  async handleGetDownloadsHistory({ startDate, endDate, aggregation }) {
    const selectedStartDate = startDate === undefined ? this.state.downloadsStartDate : startDate;
    const selectedEndDate = endDate === undefined ? this.state.downloadsEndDate : endDate;
    const { loadingDownloadsHistory, downloadsHistoryAggregation } = this.state;

    if (loadingDownloadsHistory) return;

    let formattedData = null;
    let ids = this.podcastIds();
    let scope = 'podcasts';

    const episodeIds = this.episodeIds(true);

    if (episodeIds.length > 0) {
      ids = episodeIds;
      scope = 'episodes';
    }

    const res = await getDownloads({
      scope,
      ids,
      aggregation,
      startDate: selectedStartDate ? getFlooredUtcDateTime(selectedStartDate) : null,
      endDate: selectedEndDate ? getFlooredUtcDateTime(selectedEndDate) : null,
    });
    try {
      formattedData = formatMultiplePodcastDataToGoogleCharts(res.data.data, res.data.metadata);
    } catch (_) {}
    this.setState({
      downloadsHistory: formattedData,
      loadingDownloadsHistory: false,
      downloadsStartDate: selectedStartDate,
      downloadsEndDate: selectedEndDate,
      downloadsHistoryAggregation: aggregation || downloadsHistoryAggregation,
    });
  }

  async handleGetPlaysHistory({ startDate, endDate, aggregation }) {
    const { userInfo } = this.props;
    if (!userInfo.showSpotify) return;

    const selectedStartDate = startDate === undefined ? this.state.playsStartDate : startDate;
    const selectedEndDate = endDate === undefined ? this.state.playsEndDate : endDate;
    const { loadingPlaysHistory, playsHistoryAggregation } = this.state;
    if (loadingPlaysHistory) return;

    let ids = this.podcastIds();
    let scope = 'podcasts';

    const episodeIds = this.episodeIds(true);

    if (episodeIds.length > 0) {
      ids = episodeIds;
      scope = 'episodes';
    }

    const res = await getPlays({
      ids,
      scope,
      aggregation,
      startDate: selectedStartDate ? getFlooredUtcDateTime(selectedStartDate) : null,
      endDate: selectedEndDate ? getFlooredUtcDateTime(selectedEndDate) : null,
    });

    let formattedData = null;

    try {
      if (res.data.data) {
        formattedData = formatPlatformDataToGoogleCharts(
          res.data.data,
          res.data.metadata,
          'Date',
          'date',
          'count',
        );
      }
    } catch (_) {}
    this.setState({
      playsHistory: formattedData,
      loadingPlaysHistory: false,
      playsStartDate: selectedStartDate,
      playsEndDate: selectedEndDate,
      playsHistoryAggregation: aggregation || playsHistoryAggregation,
    });
  }

  async handleGetConsumptionSeries() {
    const { userInfo } = this.props;
    if (!userInfo.showSpotify) return;
    if (this.state.loadingConsumptionSeries) return;
    this.setState({ loadingConsumptionSeries: true });

    let ids = this.podcastIds();
    let scope = 'podcasts';

    const episodeIds = this.episodeIds(true);

    if (episodeIds.length > 0) {
      ids = episodeIds;
      scope = 'episodes';
    }

    const res = await getConsumption({ ids, scope });
    const formattedData = null;
    try {
      const formattedData = formatPlatformDataToGoogleCharts(
        res.data.data,
        res.data.metadata,
        'Percentage',
        'percentage',
        'consumption',
      );
    } catch (_) {}

    this.setState({
      loadingConsumptionSeries: false,
      consumptionSeries: formattedData,
    });
  }

  async handleGetDemographics() {
    const { loadingDemographics } = this.state;
    if (loadingDemographics) return;

    this.setState({ loadingDemographics: true, demographics: null });
    getDemographics({
      teamId: this.props.teamId,
      podcastIds: this.podcastIds(),
      episodeIds: this.episodeIds(),
    }).then(res => {
      this.setState({
        loadingDemographics: false,
        demographics: res.data,
      });
    });
  }

  async handleGetPlayerDemographics() {
    const { loadingPlayerDemographics } = this.state;
    if (loadingPlayerDemographics) return;

    this.setState({ loadingPlayerDemographics: true, playerDemographics: null });

    getPlayerData({
      teamId: this.props.teamId,
      podcastIds: this.podcastIds(),
      episodeIds: this.episodeIds(),
    }).then(res => {
      this.setState({
        loadingPlayerDemographics: false,
        playerDemographics: res.data.playerDemographics,
      });
    });
  }

  handleGenerateAudienceOverlap() {
    const {
      podcastOne,
      podcastTwo,
      episodeOne,
      episodeTwo,
      loadingAudienceOverlap,
      generatedAudienceOverlap,
    } = this.state;

    if (loadingAudienceOverlap || generatedAudienceOverlap) {
      return;
    }

    if (
      (this.podcastIds().length !== 2 || podcastOne === podcastTwo) &&
      (this.episodeIds().length !== 2 || episodeOne === episodeTwo)
    ) {
      return;
    }

    this.setState({ loadingAudienceOverlap: true });

    getAudienceOverlap({
      teamId: this.props.teamId,
      podcastIds: this.podcastIds(),
      episodeIds: this.episodeIds(),
    }).then(res => {
      this.setState({ loadingAudienceOverlap: false, generatedAudienceOverlap: true });
    });
  }

  render() {
    const {
      loading,
      podcastOne,
      podcastOneEpisodes,
      podcastTwo,
      podcastTwoEpisodes,
      podcasts,
      episodeOne,
      episodeTwo,
      downloadsHistory,
      downloadsHistoryAggregation,
      consumptionSeries,
      playsHistory,
      playsHistoryAggregation,
      demographics,
      playerDemographics,
      selectedCountry,
      downloadsStartDate,
      downloadsEndDate,
      playsEndDate,
      playsStartDate,
      loadingData,
      loadingPodcasts,
      loadingAudienceOverlap,
      generatedAudienceOverlap,
    } = this.state;

    const { teamId, podcastsCount, userInfo } = this.props;

    if (loading) {
      return <Loading fullScreen />;
    }

    return (
      <DashboardCompareView
        teamId={teamId}
        podcastOne={podcastOne}
        podcastOneEpisodes={podcastOneEpisodes}
        podcastTwo={podcastTwo}
        podcastTwoEpisodes={podcastTwoEpisodes}
        podcastsCount={podcastsCount}
        podcasts={podcasts}
        loadingPodcasts={loadingPodcasts}
        episodeOne={episodeOne}
        episodeTwo={episodeTwo}
        downloadsHistory={downloadsHistory}
        downloadsHistoryAggregation={downloadsHistoryAggregation}
        onGetDownloadsHistory={this.handleGetDownloadsHistory}
        playsHistory={playsHistory}
        playsHistoryAggregation={playsHistoryAggregation}
        onGetPlaysHistory={this.handleGetPlaysHistory}
        consumptionSeries={consumptionSeries}
        demographics={demographics}
        playerDemographics={playerDemographics}
        selectedCountry={selectedCountry}
        onChangePodcast={this.handleChangePodcast}
        onChangeEpisode={this.handleChangeEpisode}
        loadingData={loadingData}
        onChangeDownloadsEndDate={this.handleChangeDownloadsEndDate}
        onChangeDownloadsStartDate={this.handleChangeDownloadsStartDate}
        onChangePlaysEndDate={this.handleChangePlaysEndDate}
        onChangePlaysStartDate={this.handleChangePlaysStartDate}
        downloadsStartDate={downloadsStartDate}
        downloadsEndDate={downloadsEndDate}
        playsStartDate={playsStartDate}
        playsEndDate={playsEndDate}
        onGenerateAudienceOverlap={this.handleGenerateAudienceOverlap}
        loadingAudienceOverlap={loadingAudienceOverlap}
        generatedAudienceOverlap={generatedAudienceOverlap}
        userInfo={userInfo}
      />
    );
  }
}

DashboardCompare.propTypes = {
  teamId: PropTypes.string.isRequired,
  podcastsCount: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const WrappedDashboardCompare = compose(withRouter, withUser)(DashboardCompare);

const DashboardCompareRouter = props => (
  <Sidebar sidebar={props.sidebar}>
    <UserContext.Provider value={props.userInfo}>
      <Router>
        <Route path="/teams/:teamId/dashboard/compare">
          <WrappedDashboardCompare {...props} />
        </Route>
      </Router>
    </UserContext.Provider>
  </Sidebar>
);

export default DashboardCompareRouter;

DashboardCompareRouter.propTypes = {
  teamId: PropTypes.string.isRequired,
};
