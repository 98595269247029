import React from 'react';
import styled from 'styled-components';

import { IconMoreAndroid } from '../../../../../spotify-internal/encore-web/cjs/components/Icon/icons/IconMoreAndroid';
import { IconNotifications } from '../../../../../spotify-internal/encore-web/cjs/components/Icon/icons/IconNotifications';
import ProfilePlaceholder from './ProfilePlaceholder';

const ProfileIcon = styled.div`
  background: #4b9ec1;
  color: white;
  border-radius: 50%;
  display: flex;
  width: 32px;
  height: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-size: 0.625rem;
`;
const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 5rem;
  padding: 1.5rem 4rem;
`;

const HeaderLink = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

const Header = ({ userName }) => {
  const nameInitials = userName
    ?.split(' ')
    .map(name => name[0])
    .join('');
  return (
    <Wrapper>
      <div className="inline-flex ml-16">
        <HeaderLink href="/users/dashboard">
          <IconNotifications semanticColor="textSubdued" iconSize="16" />
        </HeaderLink>
      </div>
      <div className="inline-flex ml-16">
        <IconMoreAndroid semanticColor="textSubdued" />
      </div>
      <div className="inline-flex ml-16">
        <HeaderLink href="/settings">
          <ProfileIcon>
            {nameInitials.length ? nameInitials.slice(0, 2) : <ProfilePlaceholder />}
          </ProfileIcon>
        </HeaderLink>
      </div>
    </Wrapper>
  );
};

export default Header;
