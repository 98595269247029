import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import { getSmartFeeds } from 'shared/api';
import Sidebar from 'shared/Sidebar';

import 'react-table/react-table.css';
import SmartFeedsCreateView from './components/SmartFeedsCreateView';
import SmartFeedsEditView from './components/SmartFeedsEditView';
import SmartFeedsListView from './components/SmartFeedsListView';
const toastOptions = {
  duration: 10000,
  id: 'SMART_FEEDS_IDX',
};

const SmartFeeds = ({ sidebar, teamId, podcasts }) => {
  const [feeds, setFeeds] = useState(null);

  const fetchFeeds = async () => {
    try {
      const data = await getSmartFeeds({ teamId });
      setFeeds(data.data);
    } catch (e) {
      toast.error(
        'Failed to fetch feeds. Please refresh the page. If this persists, contact your account manager.',
        toastOptions,
      );
    }
  };

  useEffect(() => {
    fetchFeeds();
  }, []);

  if (!feeds) {
    return null;
  }

  return (
    <Sidebar sidebar={sidebar}>
      <Toaster />
      <Router>
        <Route exact path="/smart_feeds/:teamId">
          <SmartFeedsListView toast={toast} teamId={teamId} feeds={feeds} />
        </Route>
        <Route exact path="/smart_feeds/:teamId/new">
          <SmartFeedsCreateView toast={toast} teamId={teamId} feeds={feeds} podcasts={podcasts} />
        </Route>
        <Route
          path="/smart_feeds/:teamId/edit/:feedId"
          render={renderProps => (
            <SmartFeedsEditView
              {...renderProps}
              updateFeed={() => fetchFeeds()}
              toast={toast}
              feeds={feeds}
              teamId={teamId}
              podcasts={podcasts}
            />
          )}
        />
      </Router>
    </Sidebar>
  );
};

export default SmartFeeds;
