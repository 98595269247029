import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'shared/Button';

import styles from '../styles.module.css';

const OnboardingButton = ({ nextStep, children, onClick, loading, link }) => {
  const history = useHistory();

  return (
    <div className="tc">
      <div className="dib">
        <Button
          type="spotify"
          loading={loading}
          disabled={loading}
          size="huge"
          className={`${styles.onboardingButton} ph5`}
          onClick={() => {
            if (link) {
              window.location = nextStep;
              return;
            }
            if (nextStep) {
              history.push(nextStep);
            } else if (onClick) {
              onClick();
            }
            history.push(nextStep);
          }}
        >
          {children}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingButton;
