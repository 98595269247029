import React, { useState, useRef } from 'react';
import Select from 'react-select';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import Button from 'shared/Button';
import moment from 'moment';
import DateRangePicker from 'shared/DateRangePicker';

const FILTERS = {
  REPORT_TYPE: 'report_type',
  DATE_RANGE: 'date_range',
  STATUS: 'status',
};
const publisherReportTypes = [
  {
    value: 'SMARTPROMOS_SNAPSHOT_REPORT',
    label: 'SmartPromos Snapshot Report',
  },
  {
    value: 'SMARTPROMOS_TIMESERIES_REPORT',
    label: 'SmartPromos Timeseries Report',
  },
  {
    value: 'SMARTLINKS_REPORT',
    label: 'SmartLinks Report',
  },
  {
    value: 'SMARTLINKS_OVERVIEW_REPORT',
    label: 'SmartLinks Overview Report',
  },
  {
    value: 'EPISODES_OVERVIEW_REPORT',
    label: 'Episodes Overview Report',
  },
  {
    value: 'PODCAST_OVERVIEW_REPORT',
    label: 'Podcast Overview Report',
  },
];

const statuses = [
  {
    value: 'PROCESSING',
    label: 'Processing',
  },
  {
    value: 'COMPLETE',
    label: 'Complete',
  },
  {
    value: 'ARCHIVED',
    label: 'Archived',
  },
  {
    value: 'FAILED',
    label: 'Failed',
  },
];

const filterStyle = {
  flexGrow: 1,
};

const ALL = { value: 'ALL', label: 'All' };

const ReportsFilter = ({ requestFetch }) => {
  const [typeFilter, setTypeFilter] = useState(false);
  const [statusFilter, setStatusFilter] = useState(ALL);
  const [dateRange, setDateRange] = useState({
    endDate: moment(),
    startDate: moment().subtract(6, 'months'),
  });
  const firstUpdate = useRef(true);

  const doFetch = debounce(() => {
    requestFetch({
      reportTypes: typeFilter && typeFilter.map(f => (f && f.value) || null),
      status: statusFilter.value === 'ALL' ? statusFilter : statusFilter.value,
      dateRange: dateRange,
    });
  }, 800);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    doFetch();
  }, [typeFilter, statusFilter, dateRange]);

  const handleFilterChange = filterType => (data1, data2) => {
    switch (filterType) {
      case FILTERS.REPORT_TYPE:
        setTypeFilter(data1);
        break;
      case FILTERS.STATUS:
        setStatusFilter(data1);
        break;
      case FILTERS.DATE_RANGE:
        setDateRange({
          startDate: moment(data1),
          endDate: moment(data2),
        });
        break;
    }
  };

  const reportTypes = publisherReportTypes;

  const ReportTypeFilter = () => {
    return (
      <div>
        <label>Report Type</label>
        <Select
          value={typeFilter}
          isMulti
          onChange={handleFilterChange(FILTERS.REPORT_TYPE)}
          options={[...reportTypes]}
          className="mr-auto w-100 mb3"
        />
      </div>
    );
  };

  const StatusFilter = () => {
    return (
      <div style={filterStyle}>
        <label>Status</label>
        <Select
          value={statusFilter}
          onChange={handleFilterChange(FILTERS.STATUS)}
          options={[ALL, ...statuses]}
          className="mr-auto w-80 mb4"
        />
      </div>
    );
  };

  const DateFilter = () => {
    return (
      <div className={`w-50 mr4`} style={filterStyle}>
        <label>Created Date</label>
        <DateRangePicker
          startDate={dateRange.startDate.toDate()}
          endDate={dateRange.endDate.toDate()}
          onChange={handleFilterChange(FILTERS.DATE_RANGE)}
          isClearable={false}
          className={`pa2 hover-black ba b--black-20 br2`}
        />
      </div>
    );
  };
  const resetFilters = () => {
    setTypeFilter(null);
    setStatusFilter(ALL);
    setDateRange({ endDate: moment(), startDate: moment().subtract(6, 'months') });
  };

  const Reset = () => {
    const style = {
      top: '19px',
    };
    return (
      <div className={`relative`} style={style}>
        <Button onClick={resetFilters} className="w4" type="primary">
          Reset Filters
        </Button>
      </div>
    );
  };

  return (
    <div>
      <ReportTypeFilter />
      <div className={`flex`}>
        <StatusFilter />
        <DateFilter />
        <Reset />
      </div>
    </div>
  );
};

export default ReportsFilter;
