import React from 'react';
import header from 'images/chartable-logo.png';
import styled from 'styled-components';

import { Navigation } from '../../../../../spotify-internal/encore-web/es/components/Navigation';
import { NavigationList } from '../../../../../spotify-internal/encore-web/es/components/NavigationList';
import { NavigationListItem } from '../../../../../spotify-internal/encore-web/es/components/NavigationListItem';
import {
  gray90,
  gray95,
  spacer48,
  spacer56,
} from '../../../../../spotify-internal/encore-web/es/tokens';

const StyledNavigation = styled(Navigation)`
  width: 14.5rem;
  background-color: ${gray95};
  padding: ${spacer48};
`;

const LogoImg = styled.img`
  width: 100%;
  height: 30px;
  margin-bottom: ${spacer56};
`;

const StyledListItem = styled(NavigationListItem)`
  color: #191414;
  :hover {
    color: #3e829e;
    background: ${gray90};
  }
  ${'::before'} {
    border-inline-start: 4px solid #3e829e;
  }
`;
const SideNavigation = () => {
  return (
    <StyledNavigation>
      <NavigationList>
        <LogoImg src={header} />
        <StyledListItem active href="/admin/dashboard">
          Tool1
        </StyledListItem>
        <StyledListItem href="/admin/dashboard">Tool2</StyledListItem>
        <StyledListItem href="/admin/dashboard">Tool3</StyledListItem>
      </NavigationList>
    </StyledNavigation>
  );
};

export default SideNavigation;
