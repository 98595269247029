import React from 'react';
import Toggle from 'react-toggle';
import Check from 'images/check.svg';
import PropTypes from 'prop-types';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';

import { subscriptionNeedsAttention } from '../subscriptionUtils';

function Plan({
  plan,
  onChooseNewPlan,
  onChangePlan,
  subscription,
  showContactForm,
  showAnnualPlans,
  manageBilling,
}) {
  const buttonProps = {
    active: {
      className: 'bg-silver tc w-80 bn',
      text: 'Your Plan',
      type: 'cancel',
    },
    select: { text: 'Select' },
    downgrade: { text: 'Change plan' },
    upgrade: { text: 'Upgrade' },
    contact_us: {
      text: 'Contact Us',
      type: 'contact_us',
    },
    sign_up: {
      text: 'Sign Up',
    },
    incomplete: {
      text: 'Complete Subscription',
    },
    payInvoice: {
      text: 'Pay Invoice',
    },
  };

  const isCurrentPlan = () => {
    switch (subscription.planId) {
      case 'bundle_pro':
        return plan.id === 'spotify_pro_monthly';
      case 'bundle_enterprise':
        return plan.id === 'enterprise_monthly';
      default:
        return plan.id === subscription.planId;
    }
  };

  const isNotSetRatePlan = plan.id === 'enterprise_monthly';

  const getButtonType = () => {
    // canceled sub
    if (
      subscription &&
      (subscription.status === 'cancelled' ||
        subscription.cancelAtPeriodEnd ||
        subscription === 'incomplete_expired')
    ) {
      if (isNotSetRatePlan) {
        return 'contact_us';
      }
      return 'sign_up';
    }

    // active sub
    if (subscription && subscription.status === 'active') {
      if (isCurrentPlan()) {
        return 'active';
      }
      if (isNotSetRatePlan) {
        return 'contact_us';
      }
      if (subscription.sortValue > plan.sortValue) {
        return 'downgrade';
      }
      if (subscription.sortValue < plan.sortValue) {
        return 'upgrade';
      }
    }

    // sub needs attention
    if (subscriptionNeedsAttention(subscription)) {
      if (isCurrentPlan()) {
        switch (subscription.status) {
          case 'past_due':
          case 'unpaid':
          case 'incomplete':
            return 'payInvoice';
          default:
            return 'incomplete';
        }
      }
      return 'contact_us';
    }

    // no sub
    if (isNotSetRatePlan) {
      return 'contact_us';
    }
    return 'select';
  };

  let pricingDiv = (
    <div>
      <div className="f3 pa1">{`$${plan.amount / 100.0}/${showAnnualPlans ? 'yr' : 'mo'}`}</div>
    </div>
  );

  if (plan.amount === 0 && !isNotSetRatePlan) {
    pricingDiv = <div className="f3 pa1">Free</div>;
  } else if (isNotSetRatePlan) {
    pricingDiv = <div className="f3 pa1">Contact us</div>;
  } else if (plan.nickname === 'Indie') {
    pricingDiv = <div className="f3 pa1">Free starting 5/1/23</div>;
  }

  const onClick = () => {
    if (buttonProps[getButtonType()].type === 'contact_us') {
      return showContactForm('enterprise');
    } else if (subscription === null) {
      return onChooseNewPlan(plan.id);
    }
    return onChangePlan();
  };

  return (
    <div className="w-25-l w-100 pa2" style={{ flex: 1 }}>
      <div className="ba b--moon-gray  bg-white br1">
        <div className="f7 ttu tracked pa1 br2 white center w-50 tc mb2">&nbsp;</div>
        <div className="f3 b dark-blue tc ph2">{plan.nickname}</div>
        <div className="tc gray f4 h2 mt3 h3 ph2">{pricingDiv}</div>
        <div className="bt bb bg-washed-blue b--light-blue ph2 pv3 lh-copy h4-5">
          <div className="mb1">
            <span className="b black pr1">{plan.extras.smartLinks}</span>
            SmartLinks
            {plan.extras.smartLinks === '5' && <span> / mo</span>}
          </div>
          <div className="mb1">
            <span className="b black pr1">{plan.extras['chart & review history']}</span>
            chart & review history
          </div>
          <div className="mb1">
            <span className="b black pr1">{plan.extras.users}</span>
            users
          </div>
          <div className="mb1">
            <span className="b black pr1">{plan.extras.smartPromos}</span>
            SmartPromos / mo.
          </div>
        </div>
        <div className="tc mv3">
          <Button
            onClick={onClick}
            className="w-80 center mt2"
            type="primary"
            disabled={getButtonType() === 'active' || !manageBilling}
          >
            {buttonProps[getButtonType()].text}
          </Button>
        </div>
        <div className="lh-copy ph2 f6 h5-6 mb3">
          <div className="b mb2 ttu tracked f7 tc gray">
            {plan.id.includes('hobby') ? 'Includes' : 'Also includes'}
          </div>
          {plan.extraFeatures.map(feature => (
            <div className="mb1" key={feature}>
              {/* eslint-disable-next-line jsx-a11y/alt-text */}
              <img className="w1 pr1" src={Check} />
              {feature}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

Plan.propTypes = {
  plan: PropTypes.object.isRequired,
  onChooseNewPlan: PropTypes.func.isRequired,
  onChangePlan: PropTypes.func.isRequired,
  subscription: PropTypes.object,
  showAnnualPlans: PropTypes.bool.isRequired,
};

export default function PlanChooser({
  plans,
  subscription,
  showContactForm,
  showAnnualPlans,
  onToggleAnnualPlans,
  manageBilling,
  onChooseNewPlan,
  onChangePlan,
}) {
  const planDivs = [];
  plans.forEach(value => {
    const thisPlan = (
      <Plan
        key={value[0]}
        plan={value[1]}
        onChooseNewPlan={onChooseNewPlan}
        onChangePlan={onChangePlan}
        subscription={subscription}
        showContactForm={showContactForm}
        showAnnualPlans={showAnnualPlans}
        manageBilling={manageBilling}
      />
    );

    planDivs.push(thisPlan);
  });

  return (
    <div className="center w-100">
      {!manageBilling && (
        <BannerNotice message="Contact your team's owner to make changes in billing or plans." />
      )}
      <div className="mv2 tc center">
        <div className="f5">
          <span className="v-mid">Pay&nbsp;</span>
          <span className={`mr1 black v-mid${showAnnualPlans ? '' : ' fw9'}`}>Monthly</span>
          <div className="v-mid di">
            <Toggle
              defaultChecked={false}
              icons={false}
              className="mt2"
              onChange={onToggleAnnualPlans}
            />
          </div>
          <span className={`v-mid mr1 black${showAnnualPlans ? ' fw9' : ''}`}> Yearly</span>
          <span className="v-mid b br2 f7 pa1 bg-light-green ml1 ttu tracked"> Save 20% </span>
        </div>
      </div>
      <div className="cf" />

      <div className="flex flex-wrap center mb3">{planDivs}</div>
    </div>
  );
}

PlanChooser.propTypes = {
  subscription: PropTypes.object,
  onChooseNewPlan: PropTypes.func.isRequired,
  onChangePlan: PropTypes.func.isRequired,
  showContactForm: PropTypes.func.isRequired,
  plans: PropTypes.array.isRequired,
  features: PropTypes.object.isRequired,
  showAnnualPlans: PropTypes.bool,
  onToggleAnnualPlans: PropTypes.func.isRequired,
  manageBilling: PropTypes.bool,
};
