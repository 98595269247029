import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import { popupCenter } from 'shared/helpers';
import styles from './styles.module.css';
import growth from 'images/growth.svg';

const MegaphoneOnboardingSignup = () => {
  const renderMegaphoneButton = () => (
    <Button
      size="large"
      type="spotify"
      className={`${styles.mpButton} ph3 mt2 pv3 input-reset bn white pointer f5 dib dim w-100 br2 header-font`}
      onClick={() => {
        popupCenter(
          '/oauth/authorize_megaphone?redirect_path=oauth/megaphone_oauth_callback&signup=true',
          'megaphoneOauthWindow',
          735,
          675,
        );
      }}
    >
      Sign up with Megaphone
    </Button>
  );

  
  return (
    <div data-testid="megaphone-signup-container">
      <h1 className={styles.title}>Welcome to Chartable!</h1>
      <div className={styles.bodyContainer}>
        <div className={styles.body}>
          <p>Welcome!</p>
          <p>Chartable allows you to see all of your podcast and promo stats in one place, easily track trends, and optimize podcast performance. As part of Spotify, we’re trusted by top publishers to measure more than a billion downloads per month.</p> 
          <p>Get started by signing up with your Megaphone account below.</p>
          <p>We will create your Chartable Dashboard using your Megaphone name and integrate these accounts so you can use Chartable to see how your podcasts are doing.</p>
        </div>
        <div className={styles.imageContainer}>
          <img src={growth} />
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {renderMegaphoneButton()}
      </div>
      <a className={`${styles.backTrack} link`} href="/sign_in">I already have an account, log in &raquo;</a>
    </div>
  );
};

MegaphoneOnboardingSignup.propTypes = {
  renderMegaphoneButton: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
};

export default MegaphoneOnboardingSignup;
