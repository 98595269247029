import React from 'react';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';

import 'react-table/react-table.css';

function formatNumber(number) {
  return number !== null && number !== undefined ? number.toLocaleString() : 'N/A';
}

function EpisodeTable({
  episodes,
  baseUrl,
  onSort,
  hasDownloads,
  hasListens,
  reportingWindow,
  selectedColumns,
}) {
  const columns = [
    {
      Header: '',
      width: 50,
      sortable: false,
      Cell: row => (
        <div className="w-50 center">
          <img src={row.original.podcast.displayImageUrl} className="center fl br2" alt="" />
        </div>
      ),
    },
    {
      Header: 'Episode',
      accessor: 'shortTitle',
      minWidth: 250,
    },
    {
      Header: 'Published',
      accessor: 'publishedAt',
      id: 'publishedAt',
      Cell: row => (
        <div className="tc">{moment(row.original.publishedAt).format('MM/DD/YYYY')}</div>
      ),
    },
  ];

  let launchWindows = [7, 30, 42, 45, 60];
  launchWindows = launchWindows.filter(e => e !== reportingWindow);

  if (hasDownloads) {
    columns.push({
      Header: 'Downloads',
      accessor: 'totalDownloads',
      Cell: row => <div className="tc">{formatNumber(row.original.totalDownloads)}</div>,
    });
    columns.push({
      Header: `${reportingWindow}d Launch`,
      accessor: `downloads[${reportingWindow}]`,
      Cell: row => (
        <div className="tc">
          {row.original.downloads ? formatNumber(row.original.downloads[reportingWindow]) : 'N/A'}
        </div>
      ),
    });

    launchWindows.forEach(window => {
      if (selectedColumns.includes(`${window}_day_launch_downloads`)) {
        columns.push({
          Header: `${window}d Launch`,
          accessor: `downloads[${window}]`,
          Cell: row => (
            <div className="tc">
              {row.original.downloads ? formatNumber(row.original.downloads[`${window}`]) : 'N/A'}
            </div>
          ),
        });
      }
    });
  }

  if (hasListens && selectedColumns.includes('consumption')) {
    columns.push({
      Header: () => (
        <div>
          <ReactTooltip id="consumption" />
          Consumption
          <a
            data-for="consumption"
            data-tip="This is the amount of the episode that at least 50% of Spotify listeners made it through."
            className="ph1 ba b--gray br-100 tc pointer"
          >
            &#63;
          </a>
        </div>
      ),
      accessor: 'consumption.average',
      Cell: row => (
        <div className="tc">
          {row.original.consumption && row.original.consumption.average
            ? `${row.original.consumption.average}%`
            : null}
        </div>
      ),
    });
  }
  if (hasListens && selectedColumns.includes('plays')) {
    columns.push({
      Header: 'Plays',
      accessor: 'plays.total',
      Cell: row => (
        <div className="tc">
          {formatNumber(row.original.plays ? row.original.plays.total : null)}
        </div>
      ),
    });
  }

  return (
    <div>
      <ReactTable
        columns={columns}
        data={episodes}
        pageSize={episodes.length}
        showPagination={false}
        collapseOnDataChange
        className="-highlight"
        defaultSorted={[
          {
            id: 'publishedAt',
            desc: true,
          },
        ]}
        onSortedChange={newSorted => onSort(newSorted)}
        getTrProps={(state, rowInfo) => ({
          style: { display: 'flex', alignItems: 'center', cursor: 'pointer' },
          onClick: () =>
            (window.location = `${baseUrl}${rowInfo.original.podcast.slug}&episode_id=${rowInfo.original.cid}`),
        })}
      />
    </div>
  );
}

EpisodeTable.propTypes = {
  episodes: PropTypes.array.isRequired,
  baseUrl: PropTypes.string.isRequired,
  onSort: PropTypes.func.isRequired,
  hasDownloads: PropTypes.bool.isRequired,
  hasListens: PropTypes.bool.isRequired,
  selectedColumns: PropTypes.array.isRequired,
};

export default EpisodeTable;
