import { screenXsMax } from '@spotify-internal/encore-foundation';
import styled, { css } from 'styled-components';
import { List as ListComponent } from "../List";
export var List = styled(ListComponent).attrs({
  forwardedAs: 'dl'
}).withConfig({
  displayName: "List",
  componentId: "sc-bff34i-0"
})(["", ";@media (max-width:", "){display:flex;inline-size:100%;}"], function (props) {
  return props.flex && css(["@media (max-width:", "){justify-content:space-between;}"], screenXsMax);
}, screenXsMax);
export default List;