import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import PaginatedTable from 'shared/PaginatedTable';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';
import BasicModal from 'shared/BasicModal';
import NewStatusIndicator from 'shared/NewStatusIndicator';
import ReportsFilter from './ReportsFilter.jsx';
import Button from 'shared/Button';
import { addUtcOffset, getFlooredUtcDateTime } from 'shared/helpers';
import 'react-table/react-table.css';

const REPORT_TYPES = {
  SMARTPROMOS_SNAPSHOT_REPORT: {
    value: 'SMARTPROMOS_SNAPSHOT_REPORT',
    displayText: 'SmartPromo Snapshot Report',
  },
  SMARTLINKS_REPORT: { value: 'SMARTLINKS_REPORT', displayText: 'SmartLinks Report' },
  SMARTLINKS_OVERVIEW_REPORT: {
    value: 'SMARTLINKS_OVERVIEW_REPORT',
    displayText: 'SmartLinks Overview Report',
  },
  SMARTPROMOS_TIMESERIES_REPORT: {
    value: 'SMARTPROMOS_TIMESERIES_REPORT',
    displayText: 'SmartPromo Timeseries Report',
  },
  EPISODES_OVERVIEW_REPORT: {
    value: 'EPISODES_OVERVIEW_REPORT',
    displayText: 'Episodes Overview Report',
  },
  PODCAST_OVERVIEW_REPORT: {
    value: 'PODCAST_OVERVIEW_REPORT',
    displayText: 'Podcast Overview Report',
  },
};

const publisherReportTypes = [
  'SMARTPROMOS_SNAPSHOT_REPORT',
  'SMARTPROMOS_TIMESERIES_REPORT',
  'SMARTLINKS_REPORT',
  'SMARTLINKS_OVERVIEW_REPORT',
  'EPISODES_OVERVIEW_REPORT',
  'PODCAST_OVERVIEW_REPORT',
];

function ReportsTable({
  reports,
  email,
  showDetails,
  handleDownloadReport,
  downloadingReports,
  match,
  setPagination,
  sortBy,
  sortDesc,
  page,
  loading,
  pageSize,
  reportsTotal,
  handleUpdateTypes,
  requestFetch,
  requestInProgress,
}) {
  const readOnlyMode = useReadOnlyMode();

  const reportTypes = publisherReportTypes;

  const [shownReportTypes, setShownReportTypes] = useState(reportTypes);

  const handleUpdateFilter = reportType => {
    const _shownReportTypes = shownReportTypes.filter(srt => srt !== reportType);
    if (_shownReportTypes.length === shownReportTypes.length) {
      _shownReportTypes.push(reportType);
    }
    handleUpdateTypes(_shownReportTypes);
    setShownReportTypes(_shownReportTypes);
  };

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
      Cell: row => {
        return (
          <div className="tl flex flex-row items-center">
            <div className="tl">{REPORT_TYPES[row.original.type].displayText}</div>
          </div>
        );
      },
    },
    {
      Header: 'Created by',
      accessor: 'creator',
      sortable: false,
      Cell: row => (
        <div
          className={`${
            row.original.creator === email ? 'bg-blue pa1 white br1 dib' : ''
          } overflow-hidden`}
        >
          {row.original.creator}
        </div>
      ),
    },
    {
      Header: 'Created at',
      accessor: 'createdAt',
      Cell: row => <div>{moment(row.original.createdAt).format('MMMM D, YYYY HH:mm')}</div>,
    },
    {
      Header: 'Report Dates (UTC)',
      accessor: 'startDate',
      maxWidth: 200,
      Cell: row => {
        const startDate = moment(addUtcOffset(new Date(row.original.startDate))).format(
          'MMMM D, YYYY',
        );
        const endDate = row.original.endDate
          ? moment(addUtcOffset(new Date(row.original.endDate))).format('MMMM D, YYYY')
          : null;
        return (
          <div>
            {startDate}
            {endDate && startDate !== endDate ? ` - ${endDate}` : null}
          </div>
        );
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: row => {
        return <NewStatusIndicator status={row.original.status} />;
      },
    },
    {
      Header: '',
      className: 'h-100 flex justify-center',
      sortable: false,
      Cell: row => (
        <Button
          onClick={() => handleDownloadReport(row.original)}
          disabled={row.original.status !== 'COMPLETE'}
          loading={downloadingReports.has(row.original.id)}
          className="w4"
          type="primary"
        >
          Download
        </Button>
      ),
    },
  ];

  if (!readOnlyMode) {
    columns.push({
      Header: '',
      maxWidth: 90,
      sortable: false,
      Cell: row => (
        <div onClick={() => showDetails(row.original)} className="dim blue pointer f6">
          Archive
        </div>
      ),
    });
  }

  return (
    <div className="mw8">
      <ReportsFilter isPublisher requestFetch={requestFetch} />
      {reports.length > 0 || requestInProgress ? (
        <PaginatedTable
          defaultSorted={[
            {
              id: sortBy,
              desc: sortDesc,
            },
          ]}
          columns={columns}
          data={reports}
          handlePaging={event => setPagination(p => ({ ...p, page: event.selected + 1 }))}
          handleSort={sort => {
            setPagination(p => ({
              ...p,
              page: 1,
              sortDesc: sort[0]['desc'],
              sortBy: sort[0]['id'],
            }));
          }}
          loading={loading}
          total={reportsTotal}
          page={page}
          pageSize={pageSize}
          rowStyle={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '0.5rem 0',
          }}
          getTrProps={(state, rowInfo) => ({
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyItems: 'center',
            },
          })}
          tableClassName="w-100 f5 tc"
        />
      ) : (
        <div className="justify-center items-center flex flex-column mv5">
          <h2 className="mt0 mb2">No Reports available for download</h2>
          <h4 className="mt0 mb2">Try removing some filters, or:</h4>
          <Link to={`${match.url}/create`}>
            <h4 className="mv0">Create a new report for your team →</h4>
          </Link>
        </div>
      )}
    </div>
  );
}

ReportsTable.propTypes = {
  showDetails: PropTypes.func.isRequired,
  reports: PropTypes.array.isRequired,
  email: PropTypes.string,
  downloadingReports: PropTypes.any,
  handleDownloadReport: PropTypes.func,
};

ReportsTable.defaultProps = {
  reports: [],
  email: '',
};

export default compose(withRouter)(ReportsTable);
