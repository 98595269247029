import React from 'react';

const DemoCard = ({ children }) => {
  return (
    <div style={{ marginRight: '1.3%', width: '24%', height: '63px' }} className="dib v-top">
      <div className="card ba b--light-gray ph2 pv1" style={{ height: '100%' }}>
        {children}
      </div>
    </div>
  );
};

export default DemoCard;
