import React, { Component } from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: error };
  }

  render() {
    if (this.state.hasError) {
      if (this.props.errorComponent) {
        return this.props.errorComponent;
      } else {
        return <h1>Something went wrong.{JSON.stringify(hasError)}</h1>;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
