"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ie11Edge = void 0;
var _styledComponents = require("styled-components");
var ie11Edge = function ie11Edge(rules) {
  return (0, _styledComponents.css)(["@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){", ";}@supports (-ms-ime-align:auto){", ";}"], rules, rules);
};
exports.ie11Edge = ie11Edge;