import React, { useState } from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import Table from 'shared/Table';
import TeamSearchInput from 'shared/TeamSearchInput';

class PodcastSwitcherRegistry extends React.Component {
  state = {
    example1: '',
  };

  render() {
    return (
      <div className="pa3 mb5 mt2">
        <div className="f3 header-font mb2">PodcastSwitcher</div>
        <div className="f5 lh-copy mb3 mid-gray">
          The Podcast Switcher allows a user to search and select a Podcast owned by their team,
          none, or all.
        </div>
        <div className="f4 lh-copy mb3 pt3 bt b--red red b">Important Note</div>
        <div className="f5 lh-copy mb3 mid-gray">
          Before getting into the props, it's important you understand the three types of results
          the PodcastSwitcher returns between "All", "None", and "Some." The PodcastSwitcher will
          never return null, it will always return one of these three types.
        </div>
        <div className="f5 lh-copy mb3 mid-gray">
          When None is selected, this result will be returned{' '}
          <code>{`{type: 'none', data: null}, title: 'None'`}</code>
        </div>
        <div className="f5 lh-copy mb3 mid-gray">
          When All is selected, this result will be returned{' '}
          <code>{`{type: 'all', data: null}, title: 'All'`}</code>
        </div>
        <div className="f5 lh-copy mb3 mid-gray">
          When a podcast is selected, this result will be returned{' '}
          <code>{`{type: 'none', data: <SELECTED_PODCAST_DATA>, title: <SELECTED_PODCAST_TITLE>}`}</code>
        </div>
        <div className="f5 lh-copy mb3 pb3 bb b--red mid-gray red">
          When using the PodcastSwitcher, be sure to check the 'type' so that you know if your
          component should expect podcast data.
        </div>
        <div className="mb3 pb3 bb b--moon-gray">
          <div className="f4 mb2">teamId (required)</div>
          <div className="f5 header-font mb2">The slug of the user's team.</div>
          <div className="f4 mb2">onSelect (required)</div>
          <div className="f5 header-font mb2">
            Function to be called whenever a user selects an option.
          </div>
          <div className="f5 header-font mb2">ex:</div>
          <code className="mb3 mid-gray f5">
            {
              '<PodcastSwitcher teamId="chartable-radio" onSelect={selection => this.setState({ example1: selection})} />'
            }
          </code>
          <PodcastSwitcher
            className="mt3"
            teamId="chartable-radio"
            onSelect={selection => this.setState({ example1: selection.title })}
          />
          <div>You've selected: {this.state.example1}</div>
        </div>
        <div className="mb3 pb3 bb b--moon-gray">
          <div className="f4 mb2">includeNone (default=false)</div>
          <div className="f5 header-font mb2">
            If none should be included as an option in the switcher
          </div>
          <div className="f5 header-font mb2">ex:</div>
          <code className="mb3 mid-gray f5">
            {
              '<PodcastSwitcher includeNone={true} teamId="chartable-radio" onSelect={selection => this.setState({ example1: selection})} />'
            }
          </code>
          <PodcastSwitcher
            includeNone
            className="mt3"
            teamId="chartable-radio"
            onSelect={selection => console.log(selection)}
          />
        </div>
        <div className="mb3 pb3 bb b--moon-gray">
          <div className="f4 mb2">defaultToNone (default=false)</div>
          <div className="f5 header-font mb2">If the default selection should be set to none</div>
          <div className="f5 header-font mb2">ex:</div>
          <code className="mb3 mid-gray f5">
            {
              '<PodcastSwitcher includeNone={true} teamId="chartable-radio" onSelect={selection => this.setState({ example1: selection})} />'
            }
          </code>
          <PodcastSwitcher
            defaultToNone
            className="mt3"
            teamId="chartable-radio"
            onSelect={selection => console.log(selection)}
          />
        </div>
        <div className="mb3 pb3 bb b--moon-gray">
          <div className="f4 mb2">includeAll (default=true)</div>
          <div className="f5 header-font mb2">
            If true should be included as an option in the switcher
          </div>
          <div className="f4 mb2">defaultToAll (default=true)</div>
          <div className="f5 header-font mb2">If the default selection should be set to all</div>
        </div>
        <div className="mb3 pb3 bb b--moon-gray">
          <div className="f4 mb2">selectedPodcast (default=null)</div>
          <div className="f5 header-font mb2">
            The default selected podcast. Note that you can exclude both All and None as options
            when you provide a default Podcast.
          </div>
          <div className="f5 header-font mb2">ex:</div>
          <div className="mb2">
            <code className="f6">
              const podcast ={' '}
              {`{emailTitle: "Armchair Expert with Dax Shepard",
              id: "armchair-expert-with-dax-shepard",
              imageUrl: "https://cdn.simplecast.com/images/0f24bed7-a97a-44f9-acad-ae53dc40c90a/6ee076d3-c7b2-4425-906e-65814b120642/3000x3000/1517966385artwork.jpg?aid=rss_feed",
              shortTitle: "Armchair Expert with Dax Shepard",
              teamName: "Chartable",
              teamPodcastId: "PqRTNNyz",
              title: "Armchair Expert with Dax Shepard",
              trackableEnabled: false}`}
            </code>
          </div>
          <code className="mb3 mid-gray f5">
            {
              '<PodcastSwitcher selectedPodcast={podcast} teamId="chartable-radio" onSelect={selection => this.setState({ example1: selection})} />'
            }
          </code>
          <PodcastSwitcher
            includeAll={false}
            defaultToAll={false}
            selectedPodcast={{
              emailTitle: 'Armchair Expert with Dax Shepard',
              id: 'armchair-expert-with-dax-shepard',
              imageUrl:
                'https://cdn.simplecast.com/images/0f24bed7-a97a-44f9-acad-ae53dc40c90a/6ee076d3-c7b2-4425-906e-65814b120642/3000x3000/1517966385artwork.jpg?aid=rss_feed',
              shortTitle: 'Armchair Expert with Dax Shepard',
              teamName: 'Chartable',
              teamPodcastId: 'PqRTNNyz',
              title: 'Armchair Expert with Dax Shepard',
              trackableEnabled: false,
            }}
            className="mt3"
            teamId="chartable-radio"
            onSelect={selection => console.log(selection)}
          />
        </div>
        <div className="mb3">
          <div className="f4 mb2">fetchDefaultOptions (default=true)</div>
          <div className="f5 header-font mb2">
            If this is true, the component will automatically fetch a limited number of podcasts on
            load.
          </div>
          <div className="f4 mb2">numberOfDefaultOptionsToFetch (default=5)</div>
          <div className="f5 header-font mb2">
            The number of podcasts we should pull from. Note that if the total number of
            TeamPodcasts returned is less than this number, searching will be disabled (since all
            the options are already presented).
          </div>
          <div className="f5 header-font mb2">ex:</div>
          <div className="f5 mb2">
            Notice I'm using iheartmedia as the teamId here because they have over 5 podcasts
          </div>
          <code className="mb3 mid-gray f5">
            {
              '<PodcastSwitcher teamId="iheartmedia" onSelect={selection => this.setState({ example1: selection})} />'
            }
          </code>
          <PodcastSwitcher
            defaultToNone
            className="mt3"
            teamId="iheartmedia"
            onSelect={selection => console.log(selection)}
          />
        </div>
      </div>
    );
  }
}

const ButtonRegistry = props => (
  <div>
    <div
      onClick={() => props.history.push(`/custom_admin/react/product/component_registry`)}
      className="ma2 f4 near-black pointer hover-blue"
    >
      Back
    </div>
    <div className="pa3 mt2">
      <div className="f3 header-font mb2">Button</div>
      <div className="f5 lh-copy mb3 mid-gray">
        Button to be used for all things clicking. Prestyled for font size, padding, and colors.
      </div>
      <div className="mb3">
        <div className="f4 mb2">Size</div>
        <div className="f5 header-font mb2">ex:</div>
        <code className="mb3 mid-gray f5">{'<Button size="large">'}</code>
        <div>
          <Button className="dib w5 mv2" type="primary" size="huge">
            huge
          </Button>
          <Button className="dib w5 mv2" type="primary" size="large">
            large
          </Button>
          <Button className="dib w5 mv2" type="primary" size="medium">
            medium (default)
          </Button>
          <Button className="dib w5 mv2" type="primary" size="small">
            small
          </Button>
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">Type</div>
        <div className="f5 header-font mb2">ex:</div>
        <code className="mb3 mid-gray f5">{'<Button type="primary">'}</code>
        <div>
          <Button type="primary" className="dib w5 mv2">
            primary
          </Button>
          <Button type="default" className="dib w5 mv2">
            default (default)
          </Button>
          <Button type="create" className="dib w5 mv2">
            create
          </Button>
          <Button type="destructive" className="dib w5 mv2">
            destructive
          </Button>
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">Type (Inverted)</div>
        <div className="f5 header-font mb2">ex:</div>
        <code className="mb3 mid-gray f5">{'<Button inverted={true}>'}</code>
        <div>
          <Button inverted type="primary" className="dib w5 mv2">
            primary
          </Button>
          <Button inverted type="default" className="dib w5 mv2">
            default
          </Button>
          <Button inverted type="create" className="dib w5 mv2">
            create
          </Button>
          <Button inverted type="destructive" className="dib w5 mv2">
            destructive
          </Button>
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">Loading / Disabled</div>
        <div className="f5 header-font mb2">ex:</div>
        <code className="mb3 mid-gray f5">{'<Button disabled={true} loading={true}>'}</code>
        <div>
          <Button type="primary" loading className="dib w5 mv2">
            loading
          </Button>
          <Button type="primary" disabled className="dib w5 mv2">
            disabled
          </Button>
          <Button type="primary" loading disabled className="dib w5 mv2">
            loading / disabled
          </Button>
        </div>
      </div>
    </div>
  </div>
);

const BannerNoticeRegistry = props => (
  <div>
    <div
      onClick={() => props.history.push(`/custom_admin/react/product/component_registry`)}
      className="ma2 f4 near-black pointer hover-blue"
    >
      Back
    </div>
    <div className="pa3 mt2">
      <div className="f3 header-font mb2">BannerNotice</div>
      <div className="f5 lh-copy mb3 mid-gray">Notice to display things above the ui.</div>
      <div className="mb4">
        <div className="f4 mb2">message</div>
        <div className="f5 header-font mb2">ex:</div>
        <div className="mb2">
          <code className="mb3 mid-gray f5 mb2">{'<BannerNotice message="my message" />'}</code>
        </div>
        <div>
          <BannerNotice message="my message" />
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">type</div>
        <div className="f5 header-font mb2">ex:</div>
        <div className="mb2">
          <code className="mb3 mid-gray f5 mb2">{'<BannerNotice type="error" />'}</code>
        </div>
        <div>
          <BannerNotice error message="error" />
          <BannerNotice warning message="warning (default)" />
          <BannerNotice success message="success" />
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">action / actionText</div>
        <div className="f5 header-font mb2">ex:</div>
        <div className="mb2">
          <code className="mb3 mid-gray f5 mb2">
            {'<BannerNotice actionText="Click here" action={() => console.log("flag")} />'}
          </code>
        </div>
        <div>
          <BannerNotice
            message="notification with action"
            actionText="Click here"
            action={() => console.log('flag')}
          />
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">onClose</div>
        <div className="f5 header-font mb2">ex:</div>
        <div className="mb2">
          <code className="mb3 mid-gray f5 mb2">
            {
              '<BannerNotice onClose={() => console.log("close me")} onClick={() => console.log("flag")} />'
            }
          </code>
        </div>
        <div>
          <BannerNotice
            message="When onClose is provided, close button is in top left"
            onClose={() => console.log('close me')}
          />
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2">onClick</div>
        <div className="f5 header-font mb2">ex:</div>
        <div className="mb2">
          <code className="mb3 mid-gray f5 mb2">
            {'<BannerNotice onClick={() => console.log("flag")} />'}
          </code>
        </div>
        <div>
          <BannerNotice
            message="When onClick is provided, hover effects are added"
            onClick={() => console.log('flag')}
          />
        </div>
        <div className="mb4">
          <div className="f4 mb2">rounded</div>
          <div className="f5 header-font mb2">ex:</div>
          <div className="mb2">
            <code className="mb3 mid-gray f5 mb2">{'<BannerNotice rounded />'}</code>
          </div>
          <div className="w-50">
            <BannerNotice
              rounded
              message="Use rounded when notification doesn't take up the full width of the page"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

class TableRegistry extends React.Component {
  render() {
    const data = [
      {
        text: 'This is the first row',
        number: '1',
      },
      {
        text: 'This is the second row',
        number: '2',
      },
      {
        text: 'This is the third row',
        number: '3',
      },
    ];

    const columns = [
      {
        Header: 'Column 1',
        accessor: 'text',
        className: 'tc',
      },
      {
        Header: 'Column 2',
        accessor: 'number',
        className: 'tc',
      },
    ];

    return (
      <div>
        <div
          onClick={() => this.props.history.push(`/custom_admin/react/product/component_registry`)}
          className="ma2 f4 near-black pointer hover-blue"
        >
          Back
        </div>
        <div className="pa3 mt2">
          <div className="f3 header-font mb2">Table</div>
          <div className="f5 mb4 lh-copy mb3 mid-gray">Prestyled table</div>
          <div className="mb4">
            <div className="f4 mb2">ReactTable Props (Default Table)</div>
            <div className="f5 lh-copy mb3 mid-gray">
              All ReactTable props should be available for Table. Please add any that aren't already
              working properly.
              <br />
              Table can be used as though it is a normal ReactTable.
              <br />
              <br />
              Below, you can see pageSize, filterable, showPagination are all inputted
              automatically. All rows are vertically aligned to center as well.
            </div>
            <div className="f5 header-font mb2">ex:</div>
            <div className="mb2">
              <code className="mb3 mid-gray f5 mb2">
                {'<Table columns={columns} data={data} />'}
              </code>
            </div>
            <div>
              <Table columns={columns} data={data} />
            </div>
          </div>
          <div className="mb4">
            <div className="f4 mb2">onRowClick</div>
            <div className="f5 header-font mb2">ex:</div>
            <div className="mb2">
              <code className="mb3 mid-gray f5 mb2">
                {'<Table columns={columns} data={data} onRowClick={() => console.log("click")} />'}
              </code>
            </div>
            <div>
              <Table columns={columns} data={data} onRowClick={() => console.log('flag')} />
            </div>
          </div>
          <div className="mb4">
            <div className="f4 mb2">rowHeight</div>
            <div className="f5 header-font mb2">ex:</div>
            <div className="mb2">
              <code className="mb3 mid-gray f5 mb2">
                {'<Table columns={columns} data={data} rowHeight={"1.0rem"} />'}
              </code>
            </div>
            <div>
              <Table columns={columns} data={data} rowHeight="1.0rem" />
            </div>
          </div>
          <div className="mb4">
            <div className="f4 mb2">showDetailButton / onDetailClick</div>
            <div className="f5 header-font mb2">ex:</div>
            <div className="mb2">
              <code className="mb3 mid-gray f5 mb2">
                {
                  '<Table columns={columns} data={data} detailClick={() => console.log("flag")} onDetailClick={(row) => console.log(row)} showDetailButton />'
                }
              </code>
            </div>
            <div>
              <Table
                columns={columns}
                data={data}
                showDetailButton
                onDetailClick={row => console.log(row)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const TeamSearchInputRegistry = () => {
  const [selectedTeam, setSelectedTeam] = useState(null);

  return (
    <div className="pa3 mt2">
      <div className="f3 header-font mb2">TeamSearchInput</div>
      <TeamSearchInput onSelect={selection => setSelectedTeam(selection)} />
      <div className="mv3">{`<TeamSearchInput onSelect={(selection) => setSelectedTeam(selection)} />`}</div>
      <div className="f5 mb2 near-black">Team Selected:</div>
      <pre>{JSON.stringify(selectedTeam, null, 2)}</pre>
    </div>
  );
};

function ComponentRegistry(props) {
  const baseUrl = '/custom_admin/react/product/component_registry';

  return (
    <Switch>
      <Route path={`${baseUrl}/Button`} component={ButtonRegistry} />
      <Route path={`${baseUrl}/BannerNotice`} component={BannerNoticeRegistry} />
      <Route path={`${baseUrl}/Table`} component={TableRegistry} />
      <Route path={`${baseUrl}/PodcastSwitcher`} component={PodcastSwitcherRegistry} />
      <Route path={`${baseUrl}/TeamSearchInput`} component={TeamSearchInputRegistry} />
      <Route
        path="/custom_admin/react/product/component_registry"
        render={renderProps => (
          <div className="pa3 mt2 flex flex-column">
            <div className="f3 header-font mb3">Shared Components</div>
            <Link
              className="mb2 f4 hover-blue pointer no-underline color near-black"
              to={`${baseUrl}/Button`}
            >
              {'<Button>'}
            </Link>
            <Link
              className="mb2 f4 hover-blue pointer no-underline color near-black"
              to={`${baseUrl}/BannerNotice`}
            >
              {'<BannerNotice>'}
            </Link>
            <Link
              className="mb2 f4 hover-blue pointer no-underline color near-black"
              to={`${baseUrl}/Table`}
            >
              {'<Table>'}
            </Link>
            <Link
              className="mb2 f4 hover-blue pointer no-underline color near-black"
              to={`${baseUrl}/PodcastSwitcher`}
            >
              {'<PodcastSwitcher>'}
            </Link>
            <Link
              className="mb2 f4 hover-blue pointer no-underline color near-black"
              to={`${baseUrl}/TeamSearchInput`}
            >
              {'<TeamSearchInput>'}
            </Link>
          </div>
        )}
      />
    </Switch>
  );
}

export default ComponentRegistry;
