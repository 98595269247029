import React, { useState } from 'react';
import close from 'images/icons/sidebar/close_blue.svg';
import { debounce, isEmpty } from 'lodash';
import Checkbox from 'shared/Checkbox';

import BuyerForm from './components/BuyerForm';
import SellerForm from './components/SellerForm';

const UserSettings = ({
  userType,
  buyerProfile,
  sellerProfile,
  isOpen,
  setIsOpen,
  handleSubmit,
  teamId,
}) => {
  const [formState, setFormState] = useState({ teamId });
  const [savedState, setSavedState] = useState(null);
  const handleChange = e => {
    setSavedState(false);
    const formData = new FormData(e.currentTarget);
    const entries = formData.entries();
    const current = formState;
    for (const entry of entries) {
      current[entry[0]] = entry[1];
      setFormState(current);
    }
  };

  const handleSave = formState => {
    setSavedState(true);
    handleSubmit(formState);
  };

  const handleClose = () => {
    setSavedState(null);
    setIsOpen(false);
  };

  const runSellerOnboarding = () => {
    window.location = `/seller_profile_onboarding?team_id=${teamId}`;
  };

  return (
    <div
      className="shadow-3"
      style={{
        position: 'absolute',
        background: 'white',
        zIndex: 10,
        right: isOpen ? 0 : '-400px',
        height: '100vh',
        width: '400px',
        display: isOpen ? 'initial' : 'none',
      }}
    >
      <div className="pa4 pt4">
        <div className="flex justify-between v-base">
          <span className="f3 dark-blue">Profile Settings</span>
          {savedState !== null &&
            (savedState === false ? (
              <span style={{ color: 'darkred', fontStyle: 'italics', fontSize: '10px' }}>
                Unsaved Changes!
              </span>
            ) : (
              <span style={{ color: 'darkgreen', fontStyle: 'italics', fontSize: '10px' }}>
                Changes Saved.
              </span>
            ))}
          <img style={{ cursor: 'pointer' }} src={close} onClick={handleClose} />
        </div>
        <div className="pt4 relative">
          {(userType === 'seller' || userType === 'both') && (
            <div
              onClick={runSellerOnboarding}
              style={{ margin: '0 auto 0 0' }}
              className="pv2 bn white bg-blue pointer w5 f5 br2 header-font mt3 tc relative"
            >
              Manage Full Profile
            </div>
          )}
        </div>
        <div className="pt4 relative">
          <form className="relative" onChange={handleChange}>
            {(userType === 'buyer' || userType === 'both') && <BuyerForm profile={buyerProfile} />}
          </form>
          <div
            onClick={() => handleSave(formState)}
            style={{ margin: '0 auto 0 0' }}
            className="pv2 bn white bg-blue pointer w4 f5 br2 header-font mt3 tc relative"
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
