import React from 'react';
import ReactPaginate from 'react-paginate';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import Loading from 'shared/Loading';

function PaginatedTable({
  tableClassName,
  page,
  handlePaging,
  handleSort,
  columns,
  pageSize,
  data,
  total,
  loading,
  defaultSorted,
  showPagination,
  showLoadingWhileDataIsNull,
  failedToLoad,
  failedToLoadMessage,
  noDataComponent,
  sorted,
  getTrProps,
  manual,
  testId,
}) {
  if (failedToLoad) {
    return <div>{failedToLoadMessage}</div>;
  }

  if (showLoadingWhileDataIsNull && !data) {
    return <Loading fullScreen />;
  }

  const pageCount = Math.ceil(total / pageSize);

  return (
    <div className="relative" data-testid={testId}>
      <ReactTable
        defaultSorted={defaultSorted}
        getTrProps={getTrProps}
        data={data}
        showPagination={showPagination}
        pages={pageCount}
        pageSize={Math.min(data.length, pageSize)}
        columns={columns}
        className={tableClassName}
        NoDataComponent={noDataComponent}
        onSortedChange={handleSort}
        sorted={sorted}
        manual={!!manual}
      />
      <ReactPaginate
        className={pageCount === 1 && 'dn'}
        previousLabel="&laquo;"
        nextLabel="&raquo;"
        breakClassName="dib mr2"
        pageClassName="dib mr2 b--gray br2 ba bg-white"
        previousClassName={page > 1 ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
        nextClassName={page < pageCount ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
        pageLinkClassName="dib pv2 ph3 pointer"
        previousLinkClassName="dib pv2 ph3 pointer"
        nextLinkClassName="dib pv2 ph3 pointer"
        activeClassName="bg-blue white"
        disabledClassName="dn"
        containerClassName={`${pageCount === 1 && 'dn'} pa2 list pl0 w-100 center tc`}
        onPageChange={handlePaging}
        initialPage={page - 1}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        disableInitialCallback
        forcePage={page - 1}
      />
      {loading && (
        <div className="absolute top-0 w-100 h-100 flex items-center justify-center">
          <div className="absolute top-0 w-100 h-100 bg-light-gray o-40" />
          <Loading />
        </div>
      )}
    </div>
  );
}

PaginatedTable.propTypes = {
  handlePaging: PropTypes.func.isRequired,
  defaultSorted: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  rowStyle: PropTypes.object,
  handleSort: PropTypes.func,
  onRowClick: PropTypes.func,
  data: PropTypes.array,
  pageSize: PropTypes.number,
  loading: PropTypes.bool,
  showPagination: PropTypes.bool,
  showLoadingWhileDataIsNull: PropTypes.bool,
  failedToLoad: PropTypes.bool,
  failedToLoadMessage: PropTypes.node,
  tableClassName: PropTypes.string,
};

PaginatedTable.defaultProps = {
  pageSize: 20,
  loading: false,
  rowStyle: null,
  showPagination: false,
  onRowClick: null,
  handleSort: null,
  data: null,
  showLoadingWhileDataIsNull: false,
  failedToLoad: false,
  failedToLoadMessage: <div>test</div>,
  tableClassName: 'w-100 f5',
};

export default PaginatedTable;
