import React, { Component } from 'react';

import ChartRanking from './ChartRanking';

const ProviderContainer = ({ history }) => {
  return (
    <div className="bg-white pa3 br2 mb4">
      <div className="f4 mb2 gray">{history.simpleName}</div>
      <div>
        <table className="w-100 f5 mb1">
          <tr>
            <th className="th tl f7 ttu silver">Rank</th>
            <th className="th tl f7 ttu silver">Change</th>
            <th className="th tl f7 ttu silver">View Chart History</th>
            <th />
          </tr>
          {history.charts.map(ch => (
            <ChartRanking data={ch} />
          ))}
        </table>
      </div>
      {history.morePath && (
        <div className="mb1">
          <a className="link blue" href={history.morePath}>
            More {history.simpleName} chart appearances &raquo;
          </a>
        </div>
      )}
    </div>
  );
};

export default ProviderContainer;
