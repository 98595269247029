import React from 'react';

export const ReadOnlyModeBanner = () => {
  const style = { color: '#6d5518', backgroundColor: '#fbeecd' };

  return (
    <div className="w-100 b f5 pa3 tc" style={style}>
      Chartable is currently undergoing maintenance and is in read only mode. Actions on the
      interface may be disabled.
    </div>
  );
};

export default ReadOnlyModeBanner;
