import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

function DashboardChartsSelectorView({
  episodes,
  podcasts,
  charts,
  selectedChart,
  selectedPodcast,
  selectedEpisode,
  onChangeChart,
  onChangeEpisode,
  onChangePodcast,
}) {
  const viewingOptions = [];

  if (!selectedChart) return null;

  if (episodes) {
    const episodeOptions = episodes.map(episode => ({
      value: episode.id,
      label: episode.shortTitle,
    }));
    viewingOptions.push({
      label: 'Episode Charts',
      options: [{ value: 0, label: 'All' }, ...episodeOptions],
    });
  }

  return (
    <div className="mw8 pa3 bg-white br2 mb3">
      {episodes && (
        <div className="w-100 tl mb3 pa2">
          <div className="f6 gray ttu tracked">Select an Episode</div>
          <Select
            value={
              selectedEpisode
                ? { value: selectedEpisode.id, label: selectedEpisode.shortTitle }
                : { value: 0, label: 'All' }
            }
            onChange={e => onChangeEpisode(e.value)}
            options={viewingOptions}
          />
        </div>
      )}
      {Object.keys(charts).length === 0 ? (
        <div className="pa3">
          <div className="f3 dark-blue mb3">Charts</div>
          <div className="mw8 ph3 pv4 bg-white br2 mb3 tc f4">No chart appearances yet</div>
        </div>
      ) : (
        <div className="w-100 tl flex flex-wrap">
          <div className="w-third-l w-100 pa2">
            <div className="f6 gray ttu tracked">Platform</div>
            <Select
              value={{ value: selectedChart.provider, label: selectedChart.provider }}
              onChange={e => onChangeChart(e.value)}
              options={Object.keys(charts)
                .filter(key => !key.includes('~'))
                .map(platform => ({ value: platform, label: platform }))}
            />
          </div>
          {Object.keys(selectedChart.path)
            .filter(key => selectedChart.path[key].parent)
            .map(entry => (
              <div key={entry} className="w-third-l w-100 pa2">
                <div className="f6 gray ttu tracked">{entry}</div>
                <Select
                  value={{
                    value: selectedChart.path[entry].fullPath,
                    label: selectedChart.path[entry].value,
                  }}
                  isSearchable
                  onChange={e => onChangeChart(e.value)}
                  options={[...new Set(charts[selectedChart.path[entry].parent].children)]
                    .sort()
                    .map(elem => ({ value: elem, label: elem.split('~').pop() }))}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

DashboardChartsSelectorView.propTypes = {
  charts: PropTypes.object.isRequired,
  podcasts: PropTypes.array.isRequired,
  episodes: PropTypes.array,
  selectedPodcast: PropTypes.object.isRequired,
  selectedEpisode: PropTypes.object,
  selectedChart: PropTypes.object,
  onChangeChart: PropTypes.func.isRequired,
  onChangeEpisode: PropTypes.func.isRequired,
  onChangePodcast: PropTypes.func.isRequired,
};

DashboardChartsSelectorView.defaultProps = {
  selectedChart: null,
  selectedEpisode: null,
  episodes: null,
};

export default DashboardChartsSelectorView;
