import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Header from 'shared/Header';
import FormError from 'shared/FormError';
import FormSelect from 'shared/FormSelect';
import 'react-table/react-table.css';

export default class TrackingLinkTeamPodcastPlatformUrlForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teamPodcastId: null,
      platformId: null,
      url: '',
    };

    const { trackingLinkTeamPodcastPlatformUrl, teamPodcasts, platforms } = this.props;

    if (trackingLinkTeamPodcastPlatformUrl) {
      const teamPodcastId = teamPodcasts.filter(
        teamPodcast => teamPodcast.name == trackingLinkTeamPodcastPlatformUrl.name,
      )[0].id;
      const platformId = platforms.filter(
        platform => platform.name == trackingLinkTeamPodcastPlatformUrl.platform,
      )[0].id;
      this.state = {
        teamPodcastId: teamPodcastId,
        platformId: platformId,
        url: trackingLinkTeamPodcastPlatformUrl.url,
      };
    }

    this.handleOnSubmitTrackingLinkTeamPodcastPlatformUrl =
      this.handleOnSubmitTrackingLinkTeamPodcastPlatformUrl.bind(this);
  }

  handleOnSubmitTrackingLinkTeamPodcastPlatformUrl() {
    const { teamPodcastId, platformId, url } = this.state;

    this.props.onSubmit(teamPodcastId, platformId, url);
  }

  render() {
    const { teamPodcastId, platformId, url } = this.state;

    const { teamPodcasts, platforms, errors } = this.props;

    const teamPodcastValues = [];
    const platformValues = [];

    teamPodcasts.forEach(element => {
      teamPodcastValues.push({ value: element.id, label: element.name });
    });

    platforms.forEach(element => {
      platformValues.push({ value: element.id, label: element.name });
    });

    return (
      <div className="h30-l">
        <Header className="tc mb2">Platform URL</Header>

        <div className="dt w-100">
          <div className="pa2 w-80 center">
            <label>Podcast</label>
            <FormSelect
              value={teamPodcastId}
              options={teamPodcastValues}
              onChange={value => this.setState({ teamPodcastId: value })}
              placeholder={`Select a podcast`}
              className="mb1 mt1"
              disabled={true}
            />
            <FormError errorMessage={errors.teamPodcastId} />
          </div>
          <div className="pa2 w-80 center">
            <label>Platform</label>
            <FormSelect
              value={platformId}
              options={platformValues}
              onChange={value => this.setState({ platformId: value })}
              placeholder={`Select a platform`}
              className="mb1 mt1"
              disabled={true}
            />
            <FormError errorMessage={errors.platformId} />
          </div>
          <div className="pa2 w-80 center">
            <label>Custom URL</label>
            <input
              className="pa2 input-reset ba w-100 bg-white br2 b--silver mt1"
              type="url"
              name="url"
              placeholder="spotify.open/show/12345"
              value={url}
              onChange={e => this.setState({ url: e.target.value })}
            />
            <FormError errorMessage={errors.url} />
          </div>
        </div>
        <Button
          onClick={this.handleOnSubmitTrackingLinkTeamPodcastPlatformUrl}
          className="w-80 mt4 center"
          type="primary"
        >
          Add Platform Url
        </Button>
      </div>
    );
  }
}

TrackingLinkTeamPodcastPlatformUrlForm.propTypes = {
  teamPodcasts: PropTypes.array.isRequired,
  trackingLinkTeamPodcastPlatformUrl: PropTypes.object,
  platforms: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  errors: PropTypes.object,
};

TrackingLinkTeamPodcastPlatformUrlForm.defaultProps = {
  errors: {},
};
