import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import { providerDisplayNames } from 'shared/helpers';

const InstallationInstructions = {
  megaphone: (
    <div>
      Follow the{' '}
      <a
        target="_blank"
        href="http://support.megaphone.fm/ad-ops/third-party-tracking"
        rel="noopener noreferrer"
      >
        Megaphone third party tracking instructions
      </a>{' '}
      using the tracking pixel code below.
    </div>
  ),
  mtm: (
    <div>
      Follow the{' '}
      <a
        target="_blank"
        href="http://support.megaphone.fm/ad-ops/third-party-tracking"
        rel="noopener noreferrer"
      >
        Spotify Audience Network third party tracking instructions
      </a>{' '}
      using the tracking pixel code below.
    </div>
  ),
  art19: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Art19
      account.
    </div>
  ),
  adswizz: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your
      AdsWizz account.
    </div>
  ),
  triton: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Omny
      or Triton account.
    </div>
  ),
  audacy: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your
      Audacy account.
    </div>
  ),
  acast: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Acast
      account.
    </div>
  ),
  knit: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Knit
      account.
    </div>
  ),
  art19_marketplace: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Art19
      Marketplace account.
    </div>
  ),
  pandora: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your
      Pandora account.
    </div>
  ),
};

function PixelManager({ pixel, canInstallPixelCode, promo, onClose }) {
  return (
    <div>
      <div>
        <h2 className="mt0 mb1 dark-gray">Provider</h2>
        <div>
          <div className="pv1 f4 w-100 ttc">{providerDisplayNames[pixel.provider]}</div>
        </div>
      </div>
      <div className="bg-near-white w-90 center mv3" style={{ height: '2px' }} />
      <div className="mb4 mt0">
        <h2 className="mb2 dark-gray">Installation</h2>
        <div className="bg-near-white pa3">
          {canInstallPixelCode ? (
            <div className="lh-copy f5 body-font dark-gray">
              {InstallationInstructions[pixel.provider]}
            </div>
          ) : (
            <div className="lh-copy f5 body-font dark-gray mb3">
              The <b className="red bold">publisher team running the promo ({promo.sellerTeam})</b>{' '}
              must install this pixel code on their podcast before the promo starts.
            </div>
          )}
          <div className="red f6 mt2 lh-copy">
            Note: this tag contains macros, or dynamic values, which must be filled in by the ad
            serving platform. <strong>It will not load in a web browser</strong>.
          </div>
          <div className="bg-light-gray w-95 center mv3" style={{ height: '2px' }} />
          <div className="flex flex-column">
            <div
              className="ba bg-white br2 b--light-silver pa3 mb2"
              style={{ wordBreak: 'break-all' }}
            >
              <code className="f6" data-testid="pixel-url">
                {pixel.url}
              </code>
            </div>
          </div>
        </div>
      </div>
      <Button size="large" className="w-100" onClick={onClose}>
        Close
      </Button>
    </div>
  );
}

PixelManager.propTypes = {
  promo: PropTypes.object.isRequired,
  pixel: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  canInstallPixelCode: PropTypes.bool.isRequired,
};

PixelManager.defaultProps = {
  pixel: null,
  canInstallPixelCode: true,
};

export default PixelManager;
