import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { getTrackingLinkRemarketingPixels } from 'shared/api/v1';
import Banner from 'shared/Banner';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import Header from 'shared/Header';
import { remarketingPixelPlatforms } from 'shared/helpers';
import Loading from 'shared/Loading';
import NewStatusIndicator from 'shared/NewStatusIndicator';
import TableErrorView from 'shared/TableErrorView';

import RemarketingPixelForm from '../../RemarketingPixelForm';

const SettingsRemarketingPixelManager = () => {
  const { teamId } = useParams();

  const [remarketingPixels, setRemarketingPixels] = useState(null);
  const [failedToFetchRemarketingPixels, setFailedToFetchRemarketingPixels] = useState(false);
  const [selectedRemarketingPixel, setSelectedRemarketingPixel] = useState(null);
  const [addRemarketingPixelIsOpen, setAddRemarketingPixelIsOpen] = useState(false);

  const handleGetTrackingLinkRemarketingPixels = async () => {
    setRemarketingPixels(null);
    setFailedToFetchRemarketingPixels(false);

    try {
      const res = await getTrackingLinkRemarketingPixels();
      setRemarketingPixels(res.data);
    } catch (_) {
      setFailedToFetchRemarketingPixels(true);
    }
  };

  useEffect(() => {
    handleGetTrackingLinkRemarketingPixels();
  }, []);

  const columns = [
    {
      Header: 'Platform',
      accessor: 'platform',
      Cell: row => (
        <div className="flex items-center flex-row gap-small">
          <img
            alt="icon"
            src={remarketingPixelPlatforms[row.original.platform].icon}
            className="w1 h1"
          />
          {remarketingPixelPlatforms[row.original.platform].label}
          {row.original.defaultForPlatform && <NewStatusIndicator status="ok" text="default" />}
        </div>
      ),
    },
    {
      Header: 'Pixel',
      minWidth: 200,
      className: 'f7',
      accessor: 'pixel',
    },
    {
      Header: '',
      Cell: row => (
        <Button
          onClick={() => {
            setSelectedRemarketingPixel(row.original);
            setAddRemarketingPixelIsOpen(true);
          }}
          inverted
          type="primary"
          className="f7 w3 dib mr2"
          size="small"
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div>
      <div className="ph4 mt3">
        <div className="f5 mb3 lh-copy mt3 dark-gray">
          Add retargeting pixels that will be inserted into your SmartLinks{' '}
          <a
            target="_blank"
            className="blue link pointer"
            href="https://link.chtbl.com/demo"
            rel="noreferrer"
          >
            player selection page
          </a>
          .
        </div>
        {remarketingPixels ? (
          <div>
            <Button
              type="primary"
              className="mw5 mb2"
              onClick={() => setAddRemarketingPixelIsOpen(true)}
            >
              Add Pixel
            </Button>
            <ReactTable
              getTrProps={() => ({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
              data={remarketingPixels}
              showPagination={false}
              pageSize={remarketingPixels.length}
              columns={columns}
              className="-highlight w-100 f5"
              NoDataComponent={() => <TableErrorView header="You haven't added any pixels yet!" />}
            />
          </div>
        ) : failedToFetchRemarketingPixels ? (
          <Banner error>Failed to load Remarketing Pixels</Banner>
        ) : (
          <div className="flex justify-center mt3">
            <Loading />
          </div>
        )}
      </div>
      {addRemarketingPixelIsOpen && (
        <BasicModal
          isOpen
          onRequestClose={() => {
            setSelectedRemarketingPixel(null);
            setAddRemarketingPixelIsOpen(false);
          }}
          ariaHideApp={false}
        >
          <div style={{ minHeight: '300px' }}>
            <Header className="mb3" useNew size="small">
              Add new Marketing Pixel
            </Header>
            <RemarketingPixelForm
              teamId={teamId}
              onSave={() => {
                setSelectedRemarketingPixel(null);
                setAddRemarketingPixelIsOpen(false);
                handleGetTrackingLinkRemarketingPixels();
              }}
              remarketingPixel={selectedRemarketingPixel}
            />
            <Button
              className="mt3"
              onClick={() => {
                setSelectedRemarketingPixel(null);
                setAddRemarketingPixelIsOpen(false);
              }}
            >
              Close
            </Button>
          </div>
        </BasicModal>
      )}
    </div>
  );
};

export default SettingsRemarketingPixelManager;
