import React from 'react';
import PropTypes from 'prop-types';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';

const DefaultDetailView = () => (
  <div className="pb4 bb b--moon-gray f5">
    <div className="mb2 header-font">Confirm Archiving of Report</div>
    <div className="mt2 f4 b">Are you sure you'd like to archive this report?</div>
  </div>
);

function ReportDetailModal({ report, onClose, handleDeleteReport, handleArchiveReport }) {

  var reportContent;

  reportContent = <DefaultDetailView/>

  return (
    <BasicModal isOpen={true} onRequestClose={onClose} ariaHideApp={false}>
      <div>
        {reportContent}
        <div className="mt4"></div>
        {report.status === 'COMPLETE' || report.status === 'PROCESSING' ? (
          <Button
            onClick={() => handleArchiveReport(report, true)}
            type="primary"
            className="mt2 w-100 mb3"
          >
            Archive
          </Button>
        ) : (
          <Button
            onClick={() => handleDeleteReport(report)}
            type="destructive"
            className="w-100 mb3"
          >
            Delete
          </Button>
        )}
        <Button onClick={onClose} inverted className="w-100">
          Cancel
        </Button>
      </div>
    </BasicModal>
  );
}

ReportDetailModal.propTypes = {
  report: PropTypes.object.isRequired,
  handleArchiveReport: PropTypes.func.isRequired,
  handleDeleteReport: PropTypes.func.isRequired,
};

export default ReportDetailModal;
