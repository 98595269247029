import React from 'react';
import Joyride from 'react-joyride';
import PropTypes from 'prop-types';

const TOUR_STEPS = [
  {
    target: '.tour-step-zero',
    content:
      'The AdOps page organizes all of your SmartPromos into one convenient dashboard so you can approve, monitor, and manage all of your ads in one place.\nClick next to learn more about how it works!',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-one',
    content:
      "The Request tab shows all your pending requests from other publishers you work with. You can approve or reject them from this page. Once you approve them, they'll appear in the manage tab along with other approved ads.",
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-two',
    content:
      'The Manage tab shows all of your SmartPromos. It shows both internal and external promos.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-three',
    content:
      'Both the Request and Manage tab have filters so you can easily find for the SmartPromo or SmartAd you are searching for!',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
];

const AdOpsTour = ({ showTour }) => (
  <Joyride
    run={showTour}
    disableScrollParentFix
    continuous
    steps={TOUR_STEPS}
    showProgress
    showSkipButton
    disableScrolling
    styles={{
      options: {
        primaryColor: '#4B9EC1',
      },
    }}
  />
);

AdOpsTour.propTypes = {
  showTour: PropTypes.bool,
};

AdOpsTour.defaultProp = {
  showTour: false,
};

export default AdOpsTour;
