import React from 'react';
import ReactAudioPlayer from 'react-audio-player';
import moment from 'moment';
import PropTypes from 'prop-types';
import BarChart from 'shared/charts/BarChart';
import ChartHistoryChart from 'shared/charts/ChartHistoryChart';
import ConsumptionChart from 'shared/charts/ConsumptionChart';
import CountryDemographicsChart from 'shared/charts/CountryDemographicsChart';
import DashboardHeaderStat from 'shared/charts/DashboardHeaderStat';
import PieChart from 'shared/charts/PieChart';

export default function DashboardEpisodeOverviewView({
  teamId,
  podcast,
  episode,
  downloadsHistory,
  downloadsHistoryAggregation,
  loadingDownloadsHistory,
  onGetDownloadsHistory,
  loadingConsumptionSeries,
  consumptionSeries,
  playsHistory,
  playsHistoryAggregation,
  onGetPlaysHistory,
  loadingPlaysHistory,
  downloadsStartDate,
  downloadsEndDate,
  playsStartDate,
  playsEndDate,
  onChangePlaysEndDate,
  onChangePlaysStartDate,
  onChangeDownloadsEndDate,
  onChangeDownloadsStartDate,
  reportingWindow,
  episodeDemographics,
  userInfo,
}) {
  const urlBase = `/teams/${teamId}/dashboard`;
  const downloadsUrl = `${urlBase}/integrations/trackable`;
  const chartsUrl = `${urlBase}/charts/?podcast_id=${podcast.id}`;
  const headerColumns = 4;

  const countryDemographics =
    episodeDemographics && episodeDemographics.countryDemographics
      ? episodeDemographics.countryDemographics
      : null;
  const genderDemographics =
    episodeDemographics && episodeDemographics.genderDemographics
      ? episodeDemographics.genderDemographics
      : null;
  const ageDemographics =
    episodeDemographics && episodeDemographics.ageDemographics
      ? episodeDemographics.ageDemographics
      : null;
  const playerDemographics =
    episodeDemographics && episodeDemographics.playerDemographics
      ? episodeDemographics.playerDemographics
      : null;

  const platformDemographics = episode.demographics
    ? episode.demographics.platformDemographics
    : null;

  return (
    <div>
      <div className="bb b--light-gray w-100 bg-white pv2 ph4 items-center f5">
        <div className="flex items-center justify-between" style={{ height: '1.5rem' }}>
          <a href={`/teams/${teamId}/dashboard/episodes`} className="link blue pointer">
            « All Episodes
          </a>
          {podcast && (
            <div className="bg-white flex flex-row items-center pr1 mw6 br1 w4-5">
              <img style={{ height: '1.5rem' }} src={podcast.imageUrl} alt="" />
              <div className="f6 dark-gray ml1 truncate">{podcast.title}</div>
            </div>
          )}
        </div>
      </div>
      <div className="mw8 pa3">
        <div className="cf mb2">
          <div className="gray f5 mb1 header-font">Episode</div>
          <div className="w-80-ns fl f3">{episode.shortTitle}</div>
          {userInfo.isAdmin && <div className="w-80-ns fl f3">ID: {episode.id}</div>}
          <div className="w-20-ns fr tr-ns f4 silver">
            {moment(episode.publishedAt).utc().format('MM/DD/YYYY')}
          </div>
        </div>
        <div className="flex-ns flex-wrap-ns mt2 mb4 bg-almost-white br2 pa3">
          <DashboardHeaderStat
            label="downloads"
            url={episode.totalDownloads ? null : downloadsUrl}
            labelUrl="/glossary#downloads"
            stat={episode.totalDownloads}
            numColumns={headerColumns}
            tooltip="Downloads, tracked by your hosting provider"
            first
          />
          <DashboardHeaderStat
            label="confirmed plays"
            url={null}
            labelUrl="/glossary#total-confirmed-plays"
            stat={episode.plays ? episode.plays.total : null}
            numColumns={headerColumns}
            tooltip="Listens longer than 60 seconds on Spotify"
          />
          <DashboardHeaderStat
            label="launch performance"
            url={episode.downloads && episode.downloads[reportingWindow] ? null : downloadsUrl}
            labelUrl="/glossary#launch-performance"
            stat={episode.downloads[reportingWindow]}
            numColumns={headerColumns}
            tooltip={`Downloads, first ${reportingWindow} days after launch`}
            last
          />
        </div>
        {(downloadsHistory || loadingDownloadsHistory) && (
          <ChartHistoryChart
            history={downloadsHistory}
            aggregation={downloadsHistoryAggregation}
            startDate={downloadsStartDate}
            endDate={downloadsEndDate}
            handleChangeEndDate={onChangeDownloadsEndDate}
            handleChangeStartDate={onChangeDownloadsStartDate}
            onGetHistory={onGetDownloadsHistory}
            loading={loadingDownloadsHistory}
            height="480px"
            teamId={teamId}
          />
        )}
        {(consumptionSeries || loadingConsumptionSeries) && (
          <div>
            <ConsumptionChart
              loading={loadingConsumptionSeries}
              consumptionSeries={consumptionSeries}
              consumptionStatistics={episode.consumption}
            />
            {episode.url && (
              <ReactAudioPlayer src={episode.url} controls preload={false} className="w-100 mv2" />
            )}
          </div>
        )}
        {episode && episode.chartPositions && Object.keys(episode.chartPositions).length > 0 && (
          <div className="w-100 mb3 mt4">
            <div className="f3 dark-blue mb2 w-100">
              <a href={chartsUrl} className="link dark-blue">
                Charts at a glance
              </a>
            </div>
            <table className="w-100 f5">
              <tr>
                <th className="tl f7 ttu silver w3">Rank</th>
                <th className="tl f7 ttu silver w3">Change</th>
                <th />
              </tr>
            </table>
            <div className="b4 br2 pa3 bg-white h-100">
              {Object.entries(episode.chartPositions).map(([provider, positions]) => (
                <table className="w-100 f5" key={provider}>
                  <tbody>
                    {positions.map(position => (
                      <tr className="mb2 w-100" key={position.chart.id + position.chart.chartType}>
                        <td className="pr2 pb2 w3">
                          {position.position ? (
                            <span>#{position.position}</span>
                          ) : (
                            <span>&mdash;</span>
                          )}
                        </td>
                        <td className="pr2 pb2 w3">
                          {position.delta ? (
                            <span className={position.delta <= 0 ? 'green' : 'red'}>
                              {position.delta > 0 ? <span>&#9660;</span> : <span>&#9650;</span>}
                              {Math.abs(position.delta)}
                            </span>
                          ) : (
                            <span>&mdash;</span>
                          )}
                        </td>
                        <td className="pr2 pb2">
                          <a
                            href={`${chartsUrl}&chart_id=${position.chart.id}&chart_type=${position.chart.chartType}&episode_id=${episode.id}`}
                            className="link blue"
                          >
                            {position.chart.displayName}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        )}
        {(playsHistory || loadingPlaysHistory) && (
          <ChartHistoryChart
            title="Confirmed Plays"
            subtitle="Listens longer than 60 seconds on Spotify"
            legendPosition="top"
            history={playsHistory}
            aggregation={playsHistoryAggregation}
            startDate={playsStartDate}
            endDate={playsEndDate}
            onGetHistory={onGetPlaysHistory}
            handleChangeEndDate={onChangePlaysEndDate}
            handleChangeStartDate={onChangePlaysStartDate}
            loading={loadingPlaysHistory}
            teamId={teamId}
          />
        )}
        {countryDemographics && (
          <CountryDemographicsChart title="Locations" countryDemographics={countryDemographics} />
        )}
        {genderDemographics && (
          <div className="w-100 flex flex-wrap">
            <div className="w-50-ns w-100 pr2-ns">
              <PieChart data={genderDemographics} title="Gender" />
            </div>
            <div className="w-50-ns w-100">
              <BarChart data={ageDemographics} title="Age" />
            </div>
          </div>
        )}
        {(playerDemographics || platformDemographics) && (
          <div className="w-100 flex flex-wrap">
            <div className="w-100-ns w-100 ph2">
              <PieChart data={playerDemographics} title="Players" />
            </div>
          </div>
        )}
        <div className="tc f7">All data in UTC</div>
      </div>
    </div>
  );
}

DashboardEpisodeOverviewView.propTypes = {
  teamId: PropTypes.string.isRequired,
  podcast: PropTypes.object.isRequired,
  episode: PropTypes.object.isRequired,
  downloadsHistory: PropTypes.array,
  downloadsHistoryAggregation: PropTypes.string,
  downloadsHistoryNumDays: PropTypes.number,
  onGetDownloadsHistory: PropTypes.func.isRequired,
  loadingDownloadsHistory: PropTypes.bool.isRequired,
  playsHistory: PropTypes.array,
  playsHistoryAggregation: PropTypes.string,
  playsHistoryNumDays: PropTypes.number,
  onGetPlaysHistory: PropTypes.func.isRequired,
  loadingPlaysHistory: PropTypes.bool.isRequired,
  loadingConsumptionSeries: PropTypes.bool.isRequired,
  consumptionSeries: PropTypes.array,
  loadingDemographics: PropTypes.bool,
  onChangeDownloadsEndDate: PropTypes.func.isRequired,
  onChangeDownloadsStartDate: PropTypes.func.isRequired,
  onChangePlaysEndDate: PropTypes.func.isRequired,
  onChangePlaysStartDate: PropTypes.func.isRequired,
  downloadsStartDate: PropTypes.instanceOf(Date).isRequired,
  downloadsEndDate: PropTypes.instanceOf(Date).isRequired,
  playsStartDate: PropTypes.instanceOf(Date).isRequired,
  playsEndDate: PropTypes.instanceOf(Date).isRequired,
  reportingWindow: PropTypes.string.isRequired,
  episodeDemographics: PropTypes.object,
};

DashboardEpisodeOverviewView.defaultProps = {
  downloadsHistory: null,
  downloadsHistoryAggregation: null,
  consumptionSeries: null,
  playsHistory: null,
  playsHistoryAggregation: null,
  loadingDemographics: null,
  episodeDemographics: null,
};
