import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import {
  createTrackingLinkRemarketingPixels,
  deleteTrackingLinkRemarketingPixels,
  updateTrackingLinkRemarketingPixel,
} from 'shared/api/v1';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import Checkbox from 'shared/Checkbox';
import { remarketingPixelPlatformKeys, remarketingPixelPlatforms } from 'shared/helpers';
import { OptionWithIcon, SingleValueWithIcon } from 'shared/ReactSelectHelpers';

const RemarketingPixelForm = ({ teamId, onSave, remarketingPixel, linkableReadOnly }) => {
  const isEditing = !!remarketingPixel;

  const [selectedPlatformKey, setSelectedPlatformKey] = useState(
    isEditing ? remarketingPixel.platform : '',
  );
  const [pixelString, setPixelString] = useState(isEditing ? remarketingPixel.pixel : '');
  const [makeDefault, setMakeDefault] = useState(
    isEditing ? remarketingPixel.defaultForPlatform : false,
  );

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [bannerMessage, setBannerMessage] = useState(null);

  const handleDeleteRemarketingPixel = async () => {
    if (requestInProgress) return;
    setRequestInProgress(true);

    const params = { id: remarketingPixel.id };
    try {
      await deleteTrackingLinkRemarketingPixels(params);
      if (onSave) {
        onSave();
      }
    } catch (e) {
      setBannerMessage(
        linkableReadOnly ? e.response.data.errors : 'Failed to create marketing pixel',
      );
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleSaveRemarketingPixel = async () => {
    if (requestInProgress) return;
    setRequestInProgress(true);

    let params = {
      platform: selectedPlatformKey,
      pixel: pixelString,
      defaultForPlatform: makeDefault,
      teamId,
    };

    let response;

    try {
      if (isEditing) {
        params = Object.assign({}, params, { id: remarketingPixel.id });
        response = await updateTrackingLinkRemarketingPixel(params);
      } else {
        response = await createTrackingLinkRemarketingPixels(params);
      }

      if (onSave) {
        onSave(response.data);
      }
    } catch (err) {
      let message = linkableReadOnly
        ? err.response.data.errors
        : `Failed to ${isEditing ? 'update' : 'create'} marketing pixel`;
      if (err.response && err.response.data && err.response.data.message) {
        message = err.response.data.message;
      }

      setBannerMessage(message);
    }

    setRequestInProgress(false);
  };

  return (
    <div>
      {bannerMessage && (
        <Banner error className="mv3 f6">
          {bannerMessage}
        </Banner>
      )}
      <div className="f5 mb2 dark-gray">Platform</div>
      <Select
        isDisabled={isEditing}
        value={remarketingPixelPlatforms[selectedPlatformKey]}
        options={remarketingPixelPlatformKeys.map(k => remarketingPixelPlatforms[k])}
        components={{ SingleValue: SingleValueWithIcon, Option: OptionWithIcon }}
        onChange={selection => setSelectedPlatformKey(selection.value)}
      />
      <div className="f5 mt3 mb2 mt3 dark-gray">Pixel</div>
      <input
        type="text"
        className="pa2 input-reset ba w-100 bg-white br2 b--silver"
        value={pixelString}
        onChange={e => setPixelString(e.target.value)}
        placeholder="Platform pixel..."
      />
      <Checkbox
        className="mv3"
        isChecked={makeDefault}
        onClick={() => setMakeDefault(!makeDefault)}
        label={`Make default pixel${
          selectedPlatformKey ? ` for ${remarketingPixelPlatforms[selectedPlatformKey].label}` : ''
        }`}
        description="Automatically add this pixel to all future SmartLinks"
      />
      <Button
        className="mt4"
        type="primary"
        disabled={!selectedPlatformKey || !pixelString || requestInProgress}
        loading={requestInProgress}
        onClick={() => handleSaveRemarketingPixel()}
      >
        Save
      </Button>
      {isEditing && (
        <Button
          className="mt3"
          type="destructive"
          inverted
          disabled={requestInProgress}
          loading={requestInProgress}
          onClick={handleDeleteRemarketingPixel}
        >
          Delete Pixel
        </Button>
      )}
    </div>
  );
};

RemarketingPixelForm.propTypes = {
  teamId: PropTypes.string.isRequired,
  remarketingPixel: PropTypes.object,
  onSave: PropTypes.func,
};

RemarketingPixelForm.defaultProps = {
  onSave: null,
  remarketingPixel: null,
};

export default RemarketingPixelForm;
