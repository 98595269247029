import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { debounce } from 'lodash';
import moment from 'moment';
import { getAdOpsCampaignsAndPromos, getAdOpsFilterOptions } from 'shared/api';
import Button from 'shared/Button';
import { constructReactSelectOptionsFromStringArray } from 'shared/helpers';
import Loading from 'shared/Loading';
import NewStatusIndicator from 'shared/NewStatusIndicator';
import PaginatedTable from 'shared/PaginatedTable';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import Tag from './Tag';

const Table = ({
  sortBy,
  sortDesc,
  setPage,
  columns,
  placements,
  setSortDesc,
  setSortBy,
  metadata,
  loading,
  page,
}) => (
  <PaginatedTable
    defaultSorted={[
      {
        id: sortBy,
        desc: sortDesc,
      },
    ]}
    columns={columns}
    data={placements}
    handlePaging={event => setPage(event.selected + 1)}
    handleSort={sort => {
      setSortDesc(sort[0].desc);
      setSortBy(sort[0].id);
    }}
    loading={loading}
    total={metadata ? metadata.total : 0}
    pageSize={20}
    page={page}
    rowStyle={{
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      minHeight: '2rem',
    }}
    tableClassName="w-100 f5 -highlight"
  />
);

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.sortDesc === nextProps.sortDesc &&
    prevProps.sortBy === nextProps.sortBy &&
    JSON.stringify(prevProps.placements) === JSON.stringify(nextProps.placements) &&
    JSON.stringify(prevProps.metadata) === JSON.stringify(nextProps.metadata) &&
    prevProps.loading === nextProps.loading &&
    prevProps.page === nextProps.page
  );
};

const MemoizedTable = React.memo(Table, areEqual);

const Manage = ({ match, tagTypes, location }) => {
  const readOnlyMode = useReadOnlyMode();

  const { teamId } = match.params;
  const prevFilters = location.state;

  const [placements, setPlacements] = useState(null);
  const [buyerTeamNames, setBuyerTeamNames] = useState([]);
  const [showAllFilters, setShowAllFilters] = useState(!!prevFilters);

  const [tags, setTags] = useState(
    new Set(
      Object.keys(tagTypes)
        .map(k => tagTypes[k].value)
        .filter(t => (prevFilters ? prevFilters.tags.includes(t) : true)),
    ),
  );
  const [buyerTeamNameOptions, setBuyerTeamNameOptions] = useState(
    prevFilters ? prevFilters.buyerTeamNames : [],
  );
  const [nameQueryString, setNameQueryString] = useState(
    prevFilters ? prevFilters.nameQueryString : '',
  );
  const [nameQueryStringInputValue, setNameQueryStringInputValue] = useState(
    prevFilters ? prevFilters.nameQueryString : '',
  );
  const [sortDesc, setSortDesc] = useState(prevFilters ? prevFilters.sortDesc : true);
  const [sortBy, setSortBy] = useState(prevFilters ? prevFilters.sortBy : 'startedAt');
  const [page, setPage] = useState(prevFilters ? prevFilters.page : 1);

  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(false);

  const createTags = placement => {
    return [
      tagTypes.smartPromo,
      tagTypes[placement.status.value.toLowerCase()],
      placement.isInternal ? tagTypes.internal : tagTypes.external,
      placement.campaignType === 'dai' ? tagTypes.dynamic : tagTypes.bakedIn,
    ];
  };

  const CellWrapper = ({ children, placementId }) => {
    if (readOnlyMode) {
      return (
        <div style={{ color: 'inherit' }} className="no-underline">
          {children}
        </div>
      );
    }

    return (
      <Link
        style={{ color: 'inherit' }}
        className="no-underline"
        to={{
          pathname: readOnlyMode ? '#' : `/teams/${teamId}/dashboard/adops/manage/${placementId}`,
          state: { page, sortBy, sortDesc, buyerTeamNames, nameQueryString, tags: [...tags] },
        }}
      >
        {children}
      </Link>
    );
  };

  useEffect(() => {
    getAdOpsFilterOptions({ teamId }).then(res =>
      setBuyerTeamNameOptions(
        constructReactSelectOptionsFromStringArray(res.data.buyerTeamNames, false),
      ),
    );
  }, []);

  useEffect(() => {
    setLoading(true);

    getAdOpsCampaignsAndPromos({
      teamId,
      page,
      sortBy,
      sortDesc,
      nameQueryString,
      buyerTeamNames: buyerTeamNames.map(btn => btn.value),
      tags: [...tags],
    }).then(res => {
      const { data, metadata } = res.data;
      setPlacements(data);
      setMetadata(metadata);

      if (metadata.total < page * 20) {
        setPage(Math.ceil(metadata.total / 20));
      }

      setLoading(false);
    });
  }, [page, sortBy, sortDesc, nameQueryString, tags, buyerTeamNames]);

  const columns = useMemo(() => [
    {
      Header: 'Tags',
      sortable: false,
      width: 170,
      Cell: row => {
        return (
          <div className="flex flex-wrap gap-small">
            {createTags(row.original).map((tag, index) => {
              return <Tag key={tag.value} label={tag.label} color={tag.color} />;
            })}
          </div>
        );
      },
    },
    {
      Header: 'Status',
      sortable: false,
      Cell: row => (
        <CellWrapper placementId={row.original.placementId}>
          <div className="w-100 h-100 pointer flex items-center justify-center pl2">
            <NewStatusIndicator
              status={row.original.status.value}
              tooltip={row.original.status.description}
            />
          </div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Name',
      accessor: 'name',
      id: 'name',
      Cell: row => (
        <CellWrapper placementId={row.original.placementId}>
          <div className="w-100 h-100 pointer flex items-center pl2">{row.original.name}</div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Buyer',
      id: 'buyerTeamName',
      accessor: 'buyerTeamName',
      Cell: row => (
        <CellWrapper placementId={row.original.placementId}>
          <div className="w-100 h-100 pointer flex items-center pl2">
            {row.original.buyerTeamName}
          </div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Sold Show',
      id: 'sellerTeamPodcast',
      accessor: row => (row.sellerTeamPodcast ? row.sellerTeamPodcast.title : null),
      Cell: row => (
        <CellWrapper placementId={row.original.placementId}>
          <div className="pointer flex items-center h-100 w-100">
            {row.original.sellerTeamPodcast ? (
              <div className="flex items-center">
                {row.original.sellerTeamPodcast.displayImageUrl && (
                  <div className="mr2 h2 w2">
                    <img
                      src={row.original.sellerTeamPodcast.displayImageUrl}
                      className="center fl br2"
                      alt=""
                    />
                  </div>
                )}
                <div className="w-70">{row.original.sellerTeamPodcast.shortTitle}</div>
              </div>
            ) : (
              <div className="i tc">Run of Network or Multiple</div>
            )}
          </div>
        </CellWrapper>
      ),
    },
    {
      Header: 'Promoting',
      id: 'promoting',
      accessor: row =>
        row.type === 'ad_campaign' ? row.buyerTeamName : row.buyerTeamPodcast.shortTitle,
      Cell: row => {
        if (row.original.type === 'ad_campaign') {
          return (
            <CellWrapper placementId={row.original.placementId}>
              <div className="w-100 h-100 pointer flex items-center">
                {row.original.buyerTeamName}
              </div>
            </CellWrapper>
          );
        } else if (!row.original.buyerTeamPodcast) {
          return (
            <CellWrapper placementId={row.original.placementId}>
              <div className="w-100 h-100 pointer flex items-center tc justify-center">-</div>
            </CellWrapper>
          );
        }

        return (
          <CellWrapper placementId={row.original.placementId}>
            <div className="flex items-center pointer h-100 w-100">
              <div className="mr2 h2 w2">
                <img
                  src={row.original.buyerTeamPodcast.displayImageUrl}
                  className="center fl br2"
                  alt=""
                />
              </div>
              <div className="w-70">{row.original.buyerTeamPodcast.shortTitle}</div>
            </div>
          </CellWrapper>
        );
      },
    },
    {
      Header: 'Impressions',
      id: 'impressions',
      acccessor: 'impressions',
      Cell: row => (
        <CellWrapper placementId={row.original.placementId}>
          {row.original.impressions ? (
            <div className="w-100 tr">{row.original.impressions.toLocaleString()}</div>
          ) : (
            <div className="w-100 flex items-center justify-center">-</div>
          )}
        </CellWrapper>
      ),
    },
    {
      Header: 'Air Date(s)',
      id: 'startedAt',
      accessor: 'startedAt',
      Cell: row => (
        <CellWrapper placementId={row.original.placementId}>
          {row.original.startedAt ? (
            <div className="flex flex-row items-center">
              <div>{moment(row.original.startedAt).format('MM/DD/YYYY')}</div>
              {row.original.endedAt && (
                <React.Fragment>
                  <div className="mh2">-</div>
                  <div>{moment(row.original.endedAt).format('MM/DD/YYYY')}</div>
                </React.Fragment>
              )}
            </div>
          ) : (
            <div className="w-100 flex items-center justify-center">-</div>
          )}
        </CellWrapper>
      ),
    },
  ]);

  const handleTextSearch = useCallback(
    debounce(text => {
      setNameQueryString(text);
    }, 300),
    [],
  );

  if (!placements) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <div className="w-100 ph4 tour-step-three">
        <div className="f6 dark-gray b mb1">Filter by campaign name</div>
        <input
          className="input-reset ba b--black-20 pa2 db w-100"
          placeholder="Search by name"
          value={nameQueryStringInputValue}
          onChange={e => {
            setNameQueryStringInputValue(e.target.value);
            handleTextSearch(e.target.value);
          }}
        />
        {showAllFilters && (
          <React.Fragment>
            <div className="mt3 f5 dark-gray b mb2">Tag Filters (click to turn on/off)</div>
            <div className="flex flex-wrap gap">
              <div className="card pa2">
                <div className="f6 mid-gray b mb2">Placement Type</div>
                <div className="flex flex-wrap gap">
                  <Tag
                    label={tagTypes.dynamic.label}
                    color={tags.has(tagTypes.dynamic.value) ? tagTypes.dynamic.color : '#999'}
                    onClick={() => {
                      tags.has(tagTypes.dynamic.value)
                        ? tags.delete(tagTypes.dynamic.value)
                        : tags.add(tagTypes.dynamic.value);
                      setTags(new Set(tags));
                    }}
                  />
                  <Tag
                    label={tagTypes.bakedIn.label}
                    color={tags.has(tagTypes.bakedIn.value) ? tagTypes.bakedIn.color : '#999'}
                    onClick={() => {
                      tags.has(tagTypes.bakedIn.value)
                        ? tags.delete(tagTypes.bakedIn.value)
                        : tags.add(tagTypes.bakedIn.value);
                      setTags(new Set(tags));
                    }}
                  />
                </div>
              </div>
              <div className="card pa2">
                <div className="f6 mid-gray b mb2">Status</div>
                <div className="flex flex-wrap gap">
                  <Tag
                    label={tagTypes.active.label}
                    color={tags.has(tagTypes.active.value) ? tagTypes.active.color : '#999'}
                    onClick={() => {
                      tags.has(tagTypes.active.value)
                        ? tags.delete(tagTypes.active.value)
                        : tags.add(tagTypes.active.value);
                      setTags(new Set(tags));
                    }}
                  />
                  <Tag
                    label={tagTypes.ready.label}
                    color={tags.has(tagTypes.ready.value) ? tagTypes.ready.color : '#999'}
                    onClick={() => {
                      tags.has(tagTypes.ready.value)
                        ? tags.delete(tagTypes.ready.value)
                        : tags.add(tagTypes.ready.value);
                      setTags(new Set(tags));
                    }}
                  />
                  <Tag
                    label={tagTypes.error.label}
                    color={tags.has(tagTypes.error.value) ? tagTypes.error.color : '#999'}
                    onClick={() => {
                      tags.has(tagTypes.error.value)
                        ? tags.delete(tagTypes.error.value)
                        : tags.add(tagTypes.error.value);
                      setTags(new Set(tags));
                    }}
                  />
                  <Tag
                    label={tagTypes.warning.label}
                    color={tags.has(tagTypes.warning.value) ? tagTypes.warning.color : '#999'}
                    onClick={() => {
                      tags.has(tagTypes.warning.value)
                        ? tags.delete(tagTypes.warning.value)
                        : tags.add(tagTypes.warning.value);
                      setTags(new Set(tags));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mt3 f5 dark-gray b mb2">Buyer Team Name</div>
            <Select
              onChange={selection => setBuyerTeamNames(selection ? selection : [])}
              value={buyerTeamNames}
              options={buyerTeamNameOptions}
              isMulti
            />
          </React.Fragment>
        )}
        <div
          onClick={() => setShowAllFilters(!showAllFilters)}
          className="blue link f6 pointer dib b mt3"
        >
          {showAllFilters ? 'Hide' : 'Show'} All Filters
        </div>
      </div>
      <div className="pa4">
        <MemoizedTable
          sortBy={sortBy}
          sortDesc={sortDesc}
          setPage={setPage}
          columns={columns}
          placements={placements}
          setSortDesc={setSortDesc}
          setSortBy={setSortBy}
          metadata={metadata}
          loading={loading}
          page={page}
        />
      </div>
    </div>
  );
};

export default Manage;
