import React from 'react';
import Button from 'shared/Button';
import PropTypes from 'prop-types';

const Connections = ({
  handleLogin,
  credentials,
  removeConnection,
  hasActiveConnection,
  isAdmin,
  scrapeConnection,
}) => {
  // eslint-disable-next-line react/prop-types
  const ConnectButton = ({ title, disabled }) => {
    return (
      <div>
        <Button
          type="primary"
          style={{
            cursor: disabled ? '' : 'pointer',
          }}
          onClick={disabled ? () => null : handleLogin}
        >
          {title}
        </Button>
      </div>
    );
  };

  const ConnectCopy = () => (
    <div className="f4 tl mt2 mb2">
      Add Connection
      <div className="f5 body-font lh-copy gray mb2">We&apos;ll keep your data private.</div>
    </div>
  );

  const Connect = () => {
    return (
      <div>
        <ConnectCopy />
        <ConnectButton title="Connect a Spotify account" />
      </div>
    );
  };

  // eslint-disable-next-line react/prop-types
  const DisplayImage = ({ src }) => {
    const NoUrl = () => <div className="dib h2 w2 mr2 bg-light-silver" />;
    const Avatar = () => <img src={src} className="h2 w2 mr2" />;
    return src ? <Avatar /> : <NoUrl />;
  };

  const CredentialsTable = () => {
    return (
      <div className="mb4">
        <div className="f4 tl mb1 mt4">Your connections</div>
        <table className="w-100 collapse ba b--moon-gray br2">
          {credentials.map(credential => {
            return (
              <tr key={credential.id} className="bb br2 bg-white">
                <td className="pa2">
                  <div className="flex items-center">
                    <DisplayImage src={credential.imageUrl} />
                    <span>{credential.creator}</span>
                  </div>
                </td>
                <td className="pa2">
                  Status: <span className="ttc">{credential.status || 'Inactive'}</span>
                </td>
                <td className="pa2">
                  <div
                    onClick={removeConnection(credential.id)}
                    className="link red"
                    style={{ cursor: 'pointer' }}
                  >
                    Delete
                  </div>
                </td>
                {isAdmin && (
                  <td className="pa2">
                    <div
                      onClick={scrapeConnection(credential.id)}
                      className="link blue"
                      style={{ cursor: 'pointer' }}
                    >
                      Scrape
                    </div>
                  </td>
                )}
              </tr>
            );
          })}
        </table>
      </div>
    );
  };

  const LoggedIn = () => (
    <div>
      <div className="mt3 lh-copy mb2">
        {hasActiveConnection
          ? 'You are currently logged in and receiving stats from Spotify!'
          : "There's an issue with your integration. Try reconnecting."}
      </div>
      <CredentialsTable />
      <ConnectCopy />
      <ConnectButton title="Connect another Spotify account" />
    </div>
  );

  return hasActiveConnection ? <LoggedIn /> : <Connect />;
};

Connections.propTypes = {
  handleLogin: PropTypes.func,
  credentials: PropTypes.object,
  removeConnection: PropTypes.func,
  hasActiveConnection: PropTypes.bool,
  isAdmin: PropTypes.bool,
  scrapeConnection: PropTypes.func,
};

export default Connections;
