import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import Loading from 'shared/Loading';

const getOptions = (data, customOption) => {
  let teamOptions = data.map(team => ({
    label: team.name,
    value: team.cid,
  }));
  if (customOption.value) {
    teamOptions.unshift(customOption);
  }
  return teamOptions;
};

const TeamSelector = ({
  data,
  onSelect,
  selectorClassName,
  labelClassName,
  label,
  isDisabled,
  loading,
  selected,
  customOption,
}) =>
  loading ? (
    <Loading />
  ) : (
    <div>
      <div className={labelClassName}>{label}</div>
      <div>
        <Select
          value={selected}
          onChange={onSelect}
          isDisabled={isDisabled}
          options={getOptions(data, customOption)}
          className={selectorClassName}
        />
      </div>
    </div>
  );

TeamSelector.propTypes = {
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectorClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  selected: PropTypes.object,
  customOption: PropTypes.object,
};

TeamSelector.defaultProps = {
  selectorClassName: '',
  labelClassName: '',
  label: 'Select a team',
  isDisabled: false,
  loading: false,
  selected: null,
  customOption: {},
};

export default TeamSelector;
