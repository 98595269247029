import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import Header from 'shared/Header';
import Sidebar from 'shared/Sidebar';
import TopBar from 'shared/SmallTopBar';
import UserContext from 'shared/UserContext';

import ConnectionDetail from './ConnectionDetail';
import OAuth from './OAuth';
import TeamConnector from './TeamConnector';

const MegaphoneIntegration = ({ sidebar, userInfo, teamId }) => {
  // FIXME -
  // The side bar should be on the top level Integrations component.
  // Because most of Integrations dash is still haml there is no top level
  // component so we need to place it here for now.
  // In addition, userInfo will be moved up to the top level and we will
  // access userInfo (teamId, hasMegaphoneOAuth) from context instead of passing it directly.

  const { hasMegaphoneOAuth, connectedMegaphoneOrganization, teamName } = userInfo;

  const [successBannerText, setSuccessBannerText] = useState(null);
  const [megaphoneOrganization, setMegaphoneOrganization] = useState(
    connectedMegaphoneOrganization,
  );

  let content;

  if (megaphoneOrganization) {
    content = (
      <>
        <div className="mid-gray f5 mb2 lh-copy">
          Your Chartable and Megaphone accounts are linked!
        </div>
        <ConnectionDetail
          organization={megaphoneOrganization}
          onUnlink={() => {
            setSuccessBannerText(
              'Success. Your Megaphone and Chartable teams are now disconnected.',
            );
            setMegaphoneOrganization(null);
          }}
        />
      </>
    );
  } else if (hasMegaphoneOAuth) {
    content = (
      <TeamConnector
        teamName={teamName}
        onSuccess={organization => {
          setSuccessBannerText('Success. Your Megaphone and Chartable teams are now connected.');
          setMegaphoneOrganization(organization);
        }}
      />
    );
  } else if (userInfo.forceBundle) {
    content = (
      <div className="pa3 card">
        <div className="mb3">This Chartable account is integrated with Megaphone.</div>
        <div>If you have any questions please get in touch with support at help@chartable.com.</div>
      </div>
    );
  } else {
    content = <OAuth userInfo={userInfo} />;
  }

  return (
    <UserContext.Provider value={userInfo}>
      <Sidebar sidebar={sidebar}>
        <TopBar
          text="Connect to Megaphone"
          backText="Integrations"
          backUrl={`/teams/${teamId}/dashboard/integrations`}
          hasRouter={false}
        />
        <div className="pa4 mw7">
          <Header className="dark-blue mb2" useNew>
            Connect to Megaphone
          </Header>
          <div className="lh-copy mb4 f5 mid-gray">
            Link your Megaphone organization and Chartable team to integrate your analytics. By
            linking your teams you’ll be able to track your podcast stats, trends, and performance
            all in one place.
          </div>
          {successBannerText && (
            <Banner onClick={() => setSuccessBannerText(null)} noImage success className="mb2">
              {successBannerText}
            </Banner>
          )}
          {content}
        </div>
      </Sidebar>
    </UserContext.Provider>
  );
};

MegaphoneIntegration.propTypes = {
  userInfo: PropTypes.object.isRequired,
  sidebar: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default MegaphoneIntegration;
