import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getEpisodicRetention } from 'shared/api';
import { DateSelectorContainer } from 'shared/ChartableDatePicker';
import Checkbox from 'shared/Checkbox';
import Header from 'shared/Header';
import { redirectToPricingPage } from 'shared/helpers';
import Loading from 'shared/Loading';
import TableErrorView from 'shared/TableErrorView';

const orderList = ['Next', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th'];

const windowOptions = [
  {
    label: '3 eps',
    value: 3,
  },
  {
    label: '5 eps',
    value: 5,
  },
  {
    label: '7 eps',
    value: 7,
  },
];

const MONTHLY = 'monthly';
const EPISODIC = 'episodic';

function createCell(rowIndex, columnIndex, data) {
  if (!data) {
    return <Cell key={columnIndex} value={null} />;
  }

  return (
    <Cell key={columnIndex} value={data.retentionPercentage}>
      {data.subsequentRawEpisode && data.subsequentRawEpisode.shortTitle && (
        <ReactTooltip id={`${rowIndex}+${columnIndex}`} />
      )}
      <div
        data-for={`${rowIndex}+${columnIndex}`}
        data-tip={`For Ep.: ${data.subsequentRawEpisode && data.subsequentRawEpisode.shortTitle}`}
        className={columnIndex === 0 && columnIndex === 1 ? 'tour-step-four' : ''}
      >
        {data.retentionPercentage.toFixed(2)}%
      </div>
    </Cell>
  );
}

const Cell = ({ value, children }) => (
  <div
    style={{ minWidth: '8rem' }}
    className="h2 flex-basis-0 flex-grow relative flex items-center justify-center"
  >
    <div
      style={{
        opacity: value !== null ? `${value + 10}%` : '0',
        filter: `saturate(${200 - value}%)`,
      }}
      className="absolute top-0 br2 right-0 left-0 bottom-0 bg-light-blue"
    />
    <div className="relative tr ph2 w-100 f5 dark-gray b">{children}</div>
  </div>
);

const Table = ({ data, numberOfColumns }) => {
  const columnAverages = [];
  const rows = data.map((cohort, rowIndex) => (
    <div
      key={rowIndex}
      className={`${
        rowIndex === 0 ? 'tour-step-two' : ''
      } br1 ph1 bg-animate hover-bg-washed-blue bg-white w-100 flex items-center gap-small flex-row`}
    >
      <ReactTooltip id={`${rowIndex}`} />
      <div
        data-for={`${rowIndex}`}
        data-tip={cohort.cohortRawEpisode.shortTitle}
        style={{ minWidth: '8rem' }}
        className={`flex-grow truncate dark-gray flex-basis-0 f6 mw4 ${
          rowIndex === 0 ? 'tour-step-three' : ''
        }`}
      >
        {cohort.cohortRawEpisode.shortTitle}
      </div>
      {[...Array(numberOfColumns).keys()].map(columnIndex => {
        // While we assemble the rows, we also  calculate the averages
        if (!columnAverages[columnIndex] && !!cohort.data[columnIndex]) {
          columnAverages.push({
            retentionPercentage: cohort.data[columnIndex].retentionPercentage,
            downloadsPerDevice: cohort.data[columnIndex].downloadsPerDevice,
            entries: 1,
          });
        } else if (cohort.data[columnIndex]) {
          columnAverages[columnIndex].retentionPercentage +=
            cohort.data[columnIndex].retentionPercentage;
          columnAverages[columnIndex].downloadsPerDevice +=
            cohort.data[columnIndex].downloadsPerDevice;
          columnAverages[columnIndex].entries += 1;
        }

        return createCell(rowIndex, columnIndex, cohort.data[columnIndex]);
      })}
    </div>
  ));

  const averageRow = (
    <div
      key={-1}
      className="br1 ph1 bg-animate hover-bg-washed-blue bg-white w-100 flex items-center gap-small flex-row"
    >
      <div style={{ minWidth: '8rem' }} className="flex-grow dark-gray flex-basis-0 f6 mw4">
        Average
      </div>
      {[...Array(numberOfColumns).keys()].map(columnIndex => {
        const averagedData = columnAverages[columnIndex]
          ? {
              retentionPercentage:
                columnAverages[columnIndex].retentionPercentage /
                columnAverages[columnIndex].entries,
              downloadsPerDevice:
                columnAverages[columnIndex].downloadsPerDevice /
                columnAverages[columnIndex].entries,
            }
          : null;

        return createCell(-1, columnIndex, averagedData);
      })}
    </div>
  );

  return (
    <div className="w-100 overflow-auto overflow-x-scroll">
      <div className="w-100 inline-flex ph1 flex-row gap-small">
        <div style={{ minWidth: '8rem' }} className="h2 mw4 flex-grow flex-basis-0 bg-white" />
        {data.map((d, i) => {
          return (
            <React.Fragment>
              <div
                style={{ minWidth: '8rem' }}
                className={`ph1 truncate h2 tr bg-white flex-grow flex-basis-0 dark-gray ${
                  numberOfColumns > 6 ? 'f6' : 'f5'
                }`}
                key={i}
              >
                After {orderList[i]} Episode
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div style={{ minWidth: '100%' }} className="inline-flex flex-column gap-small">
        {averageRow}
        {rows}
      </div>
    </div>
  );
};

function EpisodicRetentionChart({
  teamPodcastId,
  teamId,
  className,
  forceLoading,
  showUpsell,
  switcher,
}) {
  const [data, setData] = useState(null);

  const [customDateSelectOpen, setCustomDateSelectOpen] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [loading, setLoading] = useState(true);

  const [trailingEpisodeCount, setTrailingEpisodeCount] = useState(5);

  function handleGetPodcastRetention() {
    getEpisodicRetention({
      teamId,
      teamPodcastId,
      trailingEpisodeCount,
    })
      .then(res => {
        setData(res.data);
        setRequestFailed(false);
      })
      .catch(err => setRequestFailed(true))
      .finally(() => setLoading(false));
  }

  function handleSetCustomDateRange(startDate, endDate) {
    // Force at least 3 months of data to be loaded.
    const minStartDate = moment(endDate).subtract(2, 'months').toDate();
    const _startDate = minStartDate.getTime() < startDate.getTime() ? minStartDate : startDate;

    setStartDate(moment(_startDate).startOf('month').toDate());
    setEndDate(moment(endDate).startOf('month').toDate());
  }

  useEffect(() => {
    if (forceLoading) return;
    setLoading(true);
    handleGetPodcastRetention();
  }, [trailingEpisodeCount, forceLoading]);

  const numberOfWindows = data && data.length;
  let content;

  if (requestFailed) {
    content = <TableErrorView header="Failed to load data" />;
  } else if (loading) {
    content = (
      <div className="h5 w-100 bg-white flex items-center justify-center">
        <Loading />
      </div>
    );
  } else {
    // Only show viz if we have at least 3 months
    if (data.length > 0 && data[0].data.length > 2) {
      content = (
        <React.Fragment>
          <Table data={data} numberOfColumns={numberOfWindows} />
        </React.Fragment>
      );
    } else {
      content = (
        <TableErrorView
          header="Not enough data"
          subheader="We need at least 2 episodes to display retention"
        />
      );
    }
  }

  return (
    <div className={`card pa3 ${className}`}>
      {showUpsell && (
        <div style={{ color: '#6d5518', backgroundColor: '#fbeecd' }} className="br3 pa3 mb3">
          <div className="mb1 lh-copy">This is a preview of the Episodic Retention Chart</div>
          <a
            onClick={() =>
              redirectToPricingPage('Clicked Upgrade: Episodic Retention Chart', teamId)
            }
            className="pointer b lh-copy link blue"
          >
            Upgrade your plan to Pro or Enterprise to permanently unlock this chart →
          </a>
        </div>
      )}
      <div className="flex flex-wrap justify-between relative items-center">
        <Header useNew className="mb2 mb0-l dark-blue tour-step-one nowrap">
          New Device Retention {switcher ? '' : ' (By Episode)'}
          <div style={{ top: '-3px', left: '-6px' }} className="dib relative">
            {switcher}
          </div>
        </Header>
        <div className="ml2-l ml0 flex flex-row items-center">
          {/* <div
            className={`f6 pv1 ph2 ba dark-gray pointer ${![3, 6, 12].includes(numberOfWindows) ? 'bg-blue b--blue white' : 'b--moon-gray bg-animate hover-bg-washed-blue'}`}
            onClick={() => {
              setCustomDateSelectOpen(true);
            }}
          >
            Custom
          </div> */}
          {/* {customDateSelectOpen && (
            <div className="relative bg-red">
              <DateSelectorContainer
                includeQuickSelect={false}
                startDate={startDate}
                endDate={endDate}
                handleUpdateDateRange={handleSetCustomDateRange}
                close={() => setCustomDateSelectOpen(false)}
                disabledDays={{after: new Date()}}
              />
            </div>
          )} */}
          {windowOptions.map(windowOption => (
            <div
              key={windowOption.label}
              className={`f6 pv1 ph2 ba dark-gray pointer ${
                numberOfWindows === windowOption.value
                  ? 'bg-blue b--blue white'
                  : 'b--moon-gray bg-animate hover-bg-washed-blue'
              }`}
              onClick={() => {
                setTrailingEpisodeCount(windowOption.value);
              }}
            >
              {windowOption.label}
            </div>
          ))}
        </div>
      </div>
      <div className="mv2 bb bt-none bl-none br-none b--near-white" />
      {content}
    </div>
  );
}

EpisodicRetentionChart.propTypes = {
  teamPodcastId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  className: PropTypes.string,
  showUpsell: PropTypes.bool,
};

EpisodicRetentionChart.defaultProps = {
  className: '',
  showUpsell: false,
};

export default EpisodicRetentionChart;
