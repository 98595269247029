import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'shared/charts/PieChart';
import Header from 'shared/Header';

const Player = ({ playerDetails, playerColorMap }) => {
  return (
    <div className="mt3">
      <Header className="mb2 dark-blue" useNew>
        By Player
      </Header>
      <div className="flex flex-wrap">
        {playerDetails.uniqueDownloads && (
          <div className="w-50 pr2">
            <Header useNew small>
              Converted Devices
            </Header>
            <PieChart data={playerDetails.uniqueDownloads} colorMap={playerColorMap} />
          </div>
        )}
        {playerDetails.newDownloads && (
          <div className="w-50">
            <Header useNew small>
              New Converted Devices
            </Header>
            <PieChart data={playerDetails.newDownloads} colorMap={playerColorMap} />
          </div>
        )}
      </div>
    </div>
  );
};

Player.propTypes = {
  playerDetails: PropTypes.object,
  playerColorMap: PropTypes.any,
};

export default Player;
