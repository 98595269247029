"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.controlSizes = exports.controlPaddingBlock = exports.controlMinBlockSize = exports.controlIconSize = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
/*
 * Mixins and values meant for control components:
 * Buttons, Chip, Dropdown, Select, FormInput, etc
 */

var controlSizes = ['sm', 'md', 'lg'];
exports.controlSizes = controlSizes;
var controlMinBlockSize = {
  sm: _encoreFoundation.spacer32,
  md: _encoreFoundation.spacer48,
  lg: _encoreFoundation.spacer56
};
exports.controlMinBlockSize = controlMinBlockSize;
var controlPaddingBlock = {
  sm: _encoreFoundation.spacer4,
  md: _encoreFoundation.spacer8,
  lg: _encoreFoundation.spacer12
};
exports.controlPaddingBlock = controlPaddingBlock;
var controlIconSize = {
  sm: 16,
  md: 24,
  lg: 24
};
exports.controlIconSize = controlIconSize;