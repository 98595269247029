import React from 'react';
import PropTypes from 'prop-types';

function getTrackableEmailString(promo) {
  const buyerTeamName = promo.buyerTeam.name || promo.buyerTeam;
  const subject = `${buyerTeamName} needs you to install Trackable for an upcoming promo`;
  const body = encodeURI(`Hello,

${buyerTeamName} is using Chartable to measure an upcoming podcast promo on your show, ${promo.sellerPodcast.title}.

Chartable indicates that you don't have a Trackable Prefix installed on your show. The prefix allows Chartable to measure downloads and conversions for this promotion.

In order to install Trackable, sign up for a free Chartable account on www.chartable.com, then follow the instructions to get it installed with your provider.

Please feel free to email adops@chartable.com for assistance on setting up your account or installing Trackable.

Best Regards,
${buyerTeamName}`);
  return `mailto:?subject=${subject}&body=${body}`;
}

const MegaphoneLogsUnavailable = ({ promo }) => (
  <div className="mb4">
    <div className="f4 mb2 b header-font black">Cannot Track with Megaphone</div>
    <div className="f5 lh-copy dark-gray mb2">
      We're unable to measure this promo with Megaphone.
    </div>
    <div className="f5 lh-copy dark-gray mb2">
      Please install the Trackable prefix by following instructions on the{' '}
      <a
        target="_blank"
        href={`/teams/${
          promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
        }/dashboard/integrations/trackable`}
      >
        integrations page
      </a>
      .
    </div>
    <div className="f5 lh-copy dark-gray">
      Please reach out to support if you have any questions.
    </div>
  </div>
);

const UnknownErrorExplainer = () => (
  <div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">Promo Error</div>
      <div className="f5 lh-copy dark-gray mb2">There has been an error with your promo.</div>
      <div className="f5 lh-copy dark-gray mb2">
        The Chartable engineering team has been alerted to this error and is working to fix it.
      </div>
      <div className="f5 lh-copy dark-gray">
        Please reach out to support if you have any questions.
      </div>
    </div>
  </div>
);

const NeedArt19DecryptionKeyExplainer = ({ promo }) => (
  <div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">Need Art19 Decryption Key</div>
      <div className="f5 lh-copy dark-gray">
        Art19 encrypts data needed to measure downloads. We need your decryption key to measure
        campaigns.
      </div>
    </div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">How to resolve</div>
      <div className="f5 lh-copy dark-gray mb2">
        To get your key contact your Art19 rep and ask for your tracking pixel decryption key.
      </div>
      <div className="f5 lh-copy dark-gray">
        Once you have your key, go to{' '}
        <a
          target="_blank"
          href={`/teams/${
            promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
          }/dashboard/promos/settings`}
        >
          your attribution settings page
        </a>{' '}
        and follow the instructions on the page to enter it.
      </div>
    </div>
  </div>
);

const TrackableNotEnabledExplainer = ({ promo }) => {
  const { buyerTeamPodcastHasTrackable, sellerTeamPodcastHasTrackable } = promo.trackableErrors;

  return (
    <div>
      <div className="mb4">
        <div className="f4 mb2 b header-font black">Podcast Missing Trackable Prefix</div>
        <div className="f5 lh-copy dark-gray">
          {!buyerTeamPodcastHasTrackable && !sellerTeamPodcastHasTrackable
            ? 'Both Podcasts must have a prefix installed on their feeds in order to perform attribution.'
            : `The publisher of ${
                buyerTeamPodcastHasTrackable ? promo.sellerPodcast.title : promo.buyerPodcast.title
              } must install their Trackable prefix on the show's RSS feed so that Chartable can perform attribution for this SmartPromo.`}
        </div>
      </div>
      <div className="mb4">
        <div className="f4 mb2 b header-font black">How to resolve</div>
        {!buyerTeamPodcastHasTrackable && (
          <div>
            <div className="b f5 black mb2">Fix {promo.buyerPodcast.title} trackable status</div>
            <ol>
              <li className="lh-copy dark-gray mb2">
                Make sure the Trackable Prefix is installed on {promo.buyerPodcast.title}'s feed (
                <a
                  target="_blank"
                  href={`/teams/${
                    promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                  }/dashboard/integrations/trackable`}
                >
                  Instructions
                </a>
                ).
              </li>
              <li className="lh-copy dark-gray mb2">
                Once you're sure it's installed, click the "Refresh Trackable Status" button on your{' '}
                <a
                  target="_blank"
                  href={`/teams/${
                    promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                  }/dashboard/integrations/trackable`}
                >
                  integrations page
                </a>{' '}
                under settings.
              </li>
              <li className="lh-copy dark-gray">
                Once you no longer see {promo.buyerPodcast.title} under "These podcasts do not have
                the Trackable prefix," your promo's error status will return to normal (status
                updates every 10 minutes).
              </li>
            </ol>
          </div>
        )}
        {!sellerTeamPodcastHasTrackable && !!promo.sellerPodcast && (
          <div>
            <div className="b f5 black mb2">Fix {promo.sellerPodcast.title} trackable status</div>
            {promo.isInternal ? (
              <ol>
                <li className="dark-gray mb2">
                  Make sure the Trackable Prefix is installed on {promo.sellerPodcast.title}'s feed
                  (
                  <a
                    target="_blank"
                    href={`/teams/${
                      promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                    }/dashboard/integrations/trackable`}
                  >
                    Instructions
                  </a>
                  ).
                </li>
                <li className="dark-gray mb2">
                  Once you're sure it's installed, click the "Refresh Trackable Status" button on
                  your{' '}
                  <a
                    target="_blank"
                    href={`/teams/${
                      promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                    }/dashboard/integrations/trackable`}
                  >
                    integrations page
                  </a>{' '}
                  under settings.
                </li>
                <li className="dark-gray">
                  Once you no longer see {promo.sellerPodcast.title} under "These podcasts do not
                  have the Trackable prefix," your promo's error status will return to normal
                  (status updates every 10 minutes).
                </li>
              </ol>
            ) : (
              <div>
                <div className="dark-gray mb2">
                  The publisher of {promo.sellerPodcast.title} must install their team's Trackable
                  Prefix on their rss.
                </div>
                <a
                  href={getTrackableEmailString(promo)}
                  target="_blank"
                  className="blue pointer dim"
                >
                  Contact the seller.
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const NoPixelExplainer = () => (
  <div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">
        No pixel has been created for this dynamic promo
      </div>
      <div className="f5 dark-gray">
        Once you select a hosting provider, Chartable will generate a tracking pixel for this promo
        for you to install.
      </div>
    </div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">How to resolve</div>
      <ol>
        <li className="mb2 dark-gray">
          In the left-most column of this table click the "Install Pixel" button for the promo you
          have selected
        </li>
        <li className="mb2 dark-gray">
          Follow the instructions to select a hosting provider and a pixel will automatically be
          generated for you
        </li>
      </ol>
    </div>
  </div>
);

const NoEpisodeOnDropDateExplainer = ({ promo }) => (
  <div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">Select Episode for Attribution</div>
    </div>
    <div className="mb4">
      <div className="f4 mb2 b header-font black">How to resolve</div>
      <ol>
        <li className="mb2 dark-gray">
          Check{' '}
          <a target="_blank" href={`/podcasts/${promo.sellerPodcast.slug}/episodes`}>
            {promo.sellerPodcast.title}'s feed
          </a>{' '}
          to see if the episode dropped.
        </li>
        <li className="mb2 dark-gray">
          {promo.isInternal ? (
            <>
              Click the "Select Episode" button located on this table row to choose the episode this
              promo aired on.
            </>
          ) : (
            <>
              Ask the publisher, <i>{promo.sellerTeam}</i>, to select the episode the podcast aired
              on from their Chartable AdOps dashboard.
            </>
          )}
        </li>
      </ol>
    </div>
  </div>
);

const ErrorResolutionExplainer = ({ promo }) => {
  switch (promo.status.code) {
    case 'NO_EPISODE_ON_DROP_DATE':
      return <NoEpisodeOnDropDateExplainer promo={promo} />;
    case 'NO_PIXEL':
    case 'NO_PROVIDER_FOR_PIXEL':
      return <NoPixelExplainer />;
    case 'TRACKABLE_NOT_ENABLED':
      return <TrackableNotEnabledExplainer promo={promo} />;
    case 'NEED_ART19_DECRYPTION_KEY':
      return <NeedArt19DecryptionKeyExplainer promo={promo} />;
    case 'MEGAPHONE_LOGS_UNAVAILABLE':
      return <MegaphoneLogsUnavailable promo={promo} />;
    default:
      return <UnknownErrorExplainer />;
  }
};

ErrorResolutionExplainer.propTypes = {
  promo: PropTypes.object.isRequired,
};

export default ErrorResolutionExplainer;
