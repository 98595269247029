import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default function FormTitle({ title, tooltip }) {
  return (
    <div className="f4 mb2 header-font">
      <ReactTooltip />
      {title}
      {tooltip && (
        <a data-tip={tooltip} className="f6 ml2 ph1 ba b--gray br-100 tc pointer">
          &#63;
        </a>
      )}
    </div>
  );
}

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

FormTitle.defaultProps = {
  tooltip: null,
};
