import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Art19DecryptionKeyInput,
  EmailPreferences,
  LookbackWindowSettings,
} from 'shared/AttributionSettings';
import BannerNotice from 'shared/BannerNotice';

import TopBar from '../components/TopBar';

function Settings(props) {
  const {
    teamHashedId,
    lookbackWindow,
    availableLookbackWindows,
    onUpdate,
    attributionStatusNotifications,
    smartpromoApprovalRequests,
    location,
    art19DecryptionKeys,
  } = props;

  let backUrl;

  if (location.state && location.state.backUrl) {
    backUrl = location.state.backUrl;
  }

  const { teamId } = props.match.params;

  const [bannerNotice, setBannerNotice] = useState(null);

  function createBannerNotice(message, type) {
    setBannerNotice(
      <BannerNotice rounded message={message} type={type} onClose={() => setBannerNotice(null)} />,
    );
  }

  return (
    <div>
      <TopBar
        text={backUrl ? 'Back' : 'Analytics'}
        backUrl={backUrl ? backUrl : `/teams/${teamId}/dashboard/promos`}
      />
      <div className="ph4 mt3 pb5">
        {bannerNotice && <div className="mb3">{bannerNotice}</div>}
        <div className="mw7">
          <div className="f3 near-black mt0 header-font">SmartPromo Settings</div>
          <div className="pa4 mt3 card ba b--moon-gray">
            <EmailPreferences
              teamId={teamId}
              attributionStatusNotifications={attributionStatusNotifications}
              smartpromoApprovalRequests={smartpromoApprovalRequests}
              onSuccess={preferences => {
                onUpdate({ ...preferences, lookbackWindow, art19DecryptionKeys });
                createBannerNotice('Updated email alert settings!', 'success');
              }}
              onFailure={() => {
                createBannerNotice(
                  'Failed to update email alert settings. Please reach out to support if this problem continues.',
                  'error',
                );
              }}
            />
          </div>
          <div className="pa4 mt3 card ba b--moon-gray">
            <LookbackWindowSettings
              product="smartpromos"
              teamId={teamId}
              teamHashedId={teamHashedId}
              lookbackWindow={lookbackWindow}
              availableLookbackWindows={availableLookbackWindows}
              onSuccess={team => {
                onUpdate({
                  lookbackWindow: team.promoLookbackWindowDays,
                  attributionStatusNotifications,
                  smartpromoApprovalRequests,
                  art19DecryptionKeys,
                });
                createBannerNotice('Updated lookback window settings!', 'success');
              }}
              onFailure={() => {
                createBannerNotice(
                  'Failed to update lookback window settings. Please reach out to support if this problem continues.',
                  'error',
                );
              }}
            />
          </div>
          <div className="pa4 mt3 card ba b--moon-gray">
            <Art19DecryptionKeyInput
              teamId={teamId}
              art19DecryptionKeys={art19DecryptionKeys}
              teamHashedId={teamHashedId}
              decryptionKeys={art19DecryptionKeys}
              onSuccess={keys => {
                onUpdate({
                  art19DecryptionKeys: keys,
                  lookbackWindow,
                  attributionStatusNotifications,
                  smartpromoApprovalRequests,
                });
                createBannerNotice('Updated Art19 Decryption Keys!', 'success');
              }}
              onFailure={() => {
                createBannerNotice(
                  'Failed to add key. Please reach out to support if this problem continues.',
                  'error',
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  match: PropTypes.object.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  availableLookbackWindows: PropTypes.array.isRequired,
  attributionStatusNotifications: PropTypes.bool.isRequired,
  smartpromoApprovalRequests: PropTypes.bool.isRequired,
  art19DecryptionKeys: PropTypes.array.isRequired,
  selectedAudioAdCampaign: PropTypes.object,
};

Settings.defaultProps = {
  selectedAudioAdCampaign: null,
};

export default Settings;
