import React, { useEffect, useState } from 'react';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import PropTypes from 'prop-types';

const GeographicalDemographics = ({
  countries,
  regions,
  countryCode,
  setCountryCode,
  setTooltipContent,
  countriesGeoJson,
  metric,
  jumpData,
  dateRange,
  showSource,
  geoSource,
}) => {
  const [position, setPosition] = useState([0, 0]);
  const [activeCountry, setActiveCountry] = useState({ key: null, name: null, position: null });
  const [zoom, setZoom] = useState(1);
  const [colorScale, setScale] = useState(null);
  const [markers, setMarkers] = useState(null);
  const [regionalMax, setRegionalMax] = useState(null);

  useEffect(() => {
    const demos = countries;
    const max = Math.max(...demos.map(d => d[metric]));
    setScale(max);
  }, [countries]);

  useEffect(() => {
    if (countryCode !== (activeCountry && activeCountry.code)) {
      const jump = jumpData[countryCode];
      if (!jump) {
        return;
      }
      setZoom(jump.zoom);
      setPosition(jump.position);
      setActiveCountry({
        code: countryCode,
        name: jump.name,
        position: jump.position,
        zoom: jump.zoom,
      });
    }
    if (regions && countryCode) {
      const hash = {};
      regions.forEach(r => {
        if (!r.cityGeonameId) {
          return;
        }
        let lat = Math.round(r.lat);
        let lng = Math.round(r.lng);
        let key = [lat, lng];
        let extant = hash[key] || {
          value: 0,
          cities: [],
        };
        extant.cities.push(r.cityGeonameDisplayname);
        let value = extant.value + r[metric];
        hash[key] = {
          value,
          cities: extant.cities,
        };
      });
      Object.keys(hash).forEach(key => {
        hash[key].tooltip = generateTooltip(hash[key]);
      });
      setMarkers(hash);
      setRegionalMax(Math.max(...Object.values(hash).map(v => v.value)));
    }
  }, [regions, countryCode]);

  const handleCountryClick = code => {
    setCountryCode(code);
  };

  const handleMove = e => {
    setPosition(e.coordinates);
    setZoom(e.zoom);
  };

  const getColor = geo => {
    let rbLevel = 0;
    const twoLet = geo.properties.iso_a2;
    const item = countries.find(d => d.countryCode === twoLet);
    const metricValue = item && item[metric];
    rbLevel = metricValue ? metricValue / colorScale : rbLevel;
    rbLevel = 1 - Math.pow(rbLevel, 0.2);
    rbLevel = 255 * rbLevel;
    return {
      default: `rgb(${rbLevel}, 255, ${rbLevel})`,
      hover: `rgb(${rbLevel}, 255, ${rbLevel})`,
    };
  };

  const jumpToFeature = (feature, targetScope = null) => {
    switch (targetScope) {
      case 'world':
        setMarkers(null);
        setZoom(1);
        setPosition([0, 0]);
        setActiveCountry(null);
        setCountryCode(null);
        break;
      case 'country':
        setZoom(feature.zoom);
        setPosition(feature.position);
        break;
    }
  };

  const generateTooltip = data => {
    const length = data.cities.length <= 5 ? data.cities.length : 5;
    let text = length > 1 ? `<div class="underline pb1">Top ${length} Cities</div>` : '';
    for (let i = 0; i < length; i++) {
      if (data.cities[i]) {
        text += data.cities[i];
        text += '<br />';
      }
    }
    text += `<div class="pt1">Total: ${data.value.toLocaleString()}</div>`;
    return text;
  };

  return (
    <div>
      <div
        style={{ borderTop: 'none', borderLeft: 'none', borderRight: 'none', cursor: 'pointer' }}
        className={`ba b--moon-gray`}
      >
        <div
          className={`pr2 pl2 pt1 pb1 bg-white dark-gray ma2 dib br-pill ba b--moon-gray dim`}
          onClick={() => jumpToFeature(null, 'world')}
        >
          World
        </div>
        {activeCountry && activeCountry.name && (
          <span>
            <span className={`fs`}>→</span>
            <div
              onClick={() => jumpToFeature(null, 'world')}
              className={`ba b--blue pr2 pl2 pt1 pb1 bg-blue ma2 dib br-pill white dim`}
            >
              {activeCountry.name}
              <span className={`ph1`}>&times;</span>
            </div>
          </span>
        )}
        {showSource && (
          <span className={`fr relative i pr2 f7`} style={{ paddingTop: '13px' }}>
            Source: {geoSource}
          </span>
        )}
      </div>
      <div style={{ maxWidth: '100%', background: '#78B9FA' }}>
        <ComposableMap
          data-tip=""
          data-for="mapViewTip"
          width={800}
          height={600}
          projection={'geoMercator'}
        >
          <ZoomableGroup zoom={zoom} center={position} onMoveEnd={handleMove} fill={'#78B9FA'}>
            <Geographies geography={countriesGeoJson}>
              {({ geographies }) => (
                <React.Fragment>
                  {geographies.map(geo => (
                    <Geography
                      onClick={e => handleCountryClick(geo.properties.iso_a2, geo)}
                      key={geo.rsmKey}
                      geography={geo}
                      stroke={
                        activeCountry && activeCountry.code === geo.properties.iso_a2
                          ? 'rgb(166, 166, 166)'
                          : '#33333366'
                      }
                      strokeWidth={'0.3'}
                      style={{
                        default: {
                          fill:
                            activeCountry && activeCountry.code === geo.properties.iso_a2
                              ? 'white'
                              : getColor(geo).default,
                          outline: 'none',
                          cursor:
                            activeCountry && activeCountry.code === geo.properties.iso_a2
                              ? 'crosshair'
                              : 'pointer',
                        },
                        hover: {
                          fill:
                            activeCountry && activeCountry.code === geo.properties.iso_a2
                              ? 'white'
                              : getColor(geo).hover,
                          outline: 'none',
                          cursor:
                            activeCountry && activeCountry.code === geo.properties.iso_a2
                              ? 'crosshair'
                              : 'pointer',
                        },
                        pressed: {
                          fill: 'lightgray',
                          outline: 'none',
                        },
                      }}
                    />
                  ))}
                </React.Fragment>
              )}
            </Geographies>
            {markers &&
              Object.keys(markers).map(m => (
                <Marker
                  key={m}
                  coordinates={[m.split(',')[1], m.split(',')[0]]}
                  onMouseEnter={() => {
                    setTooltipContent(markers[m].tooltip);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent('');
                  }}
                >
                  <circle
                    r={4 * Math.pow(markers[m].value / regionalMax, 0.5)}
                    fill={`rgba(136, 90, 154, ${Math.pow(markers[m].value / regionalMax, 0.5)})`}
                  />
                </Marker>
              ))}
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </div>
  );
};

GeographicalDemographics.propTypes = {
  showSource: PropTypes.bool,
};

GeographicalDemographics.defaultProps = {
  showSource: true,
};

export default GeographicalDemographics;
