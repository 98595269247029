import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import { approvePromoRequest, getPromoRequest } from 'shared/api';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import { capitalizeFirstLetter, providerOptions } from 'shared/helpers';
import Loading from 'shared/Loading';
import NewStatusIndicator from 'shared/NewStatusIndicator';
import TopBar from 'shared/SmallTopBar';

import EpisodeSelector from '../EpisodeSelector';

const AudioAdPlacementRequestDetailView = () => {
  const [audioAdPlacementRequest, setAudioAdPlacementRequest] = useState(null);
  const [error, setError] = useState(false);
  const [approvalError, setApprovalError] = useState(null);
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [provider, setProvider] = useState();
  const [requestApprovalInProgress, setRequestApprovalInProgress] = useState(false);

  const { audioAdPlacementRequestId, teamId } = useParams();
  const isScheduledAdCampaign = Number.isInteger(parseInt(audioAdPlacementRequestId, 10));

  const handleGetPromoRequest = async () => {
    if (requestInProgress) return;
    setRequestInProgress(true);

    try {
      const response = await getPromoRequest({
        teamId,
        audioAdPlacementRequestId,
      });
      setAudioAdPlacementRequest(response.data);
    } catch (e) {
      setError('Failed to approve episode');
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleApprovePromoRequest = async episode => {
    if (requestApprovalInProgress) return;
    setRequestApprovalInProgress(true);

    try {
      const response = await approvePromoRequest({
        teamId,
        audioAdPlacementRequestId,
        episodeId: episode ? episode.cid : null,
        pixelProvider: provider ? provider.value : null,
      });
      window.location.assign(
        audioAdPlacementRequest.ownerTeamName
          ? `/teams/${teamId}/dashboard/adops/requests`
          : `/teams/${teamId}/dashboard/adops/manage/${response.data.promo.placementMetadataId}?new=true`,
      );
    } catch (e) {
      setApprovalError('Could not approve promo');
      setRequestApprovalInProgress(false);
    }
  };

  useEffect(() => {
    if (!isScheduledAdCampaign) {
      handleGetPromoRequest();
    }
  }, []);

  const topBar = (
    <TopBar backText="All Requests" backUrl={`/teams/${teamId}/dashboard/adops/requests`} />
  );

  if (isScheduledAdCampaign) {
    return (
      <>
        {topBar}
        <Banner className="pv3 ph4">
          SmartAds scheduled ad campaigns can only be approved on the Requests page table
        </Banner>
      </>
    );
  }

  if (error) {
    return (
      <>
        {topBar}
        <Banner className="pv3 ph4" error>
          Failed to load Promo Request
        </Banner>
      </>
    );
  }

  if (!audioAdPlacementRequest) {
    return (
      <>
        {topBar}
        <Loading fullScreen />
      </>
    );
  }

  let content;

  if (audioAdPlacementRequest.statusCode === 'APPROVED') {
    content = (
      <Banner success>
        <div className="mb2 b lh-copy">This promo request has already been approved.</div>
        {audioAdPlacementRequest.sellerTeamSlug === teamId ? (
          <div className="blh-copy">
            It can be found by going to the manage tab or by{' '}
            <a
              href={`/teams/${teamId}/dashboard/adops/manage/${audioAdPlacementRequest.placementMetadataId}`}
              className="link blue pointer"
            >
              clicking here.
            </a>
          </div>
        ) : (
          <div className="lh-copy">
            Its data is populating on the {audioAdPlacementRequest.sellerTeam} dashboard
          </div>
        )}
      </Banner>
    );
  } else if (requestApprovalInProgress) {
    content = (
      <div className="tc mt4">
        <Loading />
        <div className="f4 force-inter dark-gray">
          {audioAdPlacementRequest.campaignType === 'dai' ? 'Creating Pixel' : 'Filling Request'}
        </div>
      </div>
    );
  } else if (audioAdPlacementRequest.campaignType === 'dai') {
    content = (
      <div className="mw6">
        <div className="force-inter mb1 dark-gray f4">Select Provider</div>
        <div className="mid-gray lh-copy mb2">
          Select the pixel provider you use for measurement.
        </div>
        <Select
          value={provider}
          onChange={e => setProvider(e)}
          options={providerOptions}
          className="f5 near-black mb3"
        />
        <Button onClick={() => handleApprovePromoRequest(null)} type="create" disabled={!provider}>
          Confirm
        </Button>
      </div>
    );
  } else if (moment(audioAdPlacementRequest.scheduledAirDate).isAfter(new Date())) {
    content = (
      <Banner className="f5 mb3" noImage>
        You will be able to approve this SmartPromo and select the episode the promo aired on after
        the scheduled air date,{' '}
        {moment(audioAdPlacementRequest.scheduledAirDate).format('MM/DD/YYYY')}
      </Banner>
    );
  } else if (audioAdPlacementRequest.sellerPodcast) {
    content = (
      <>
        {!!audioAdPlacementRequest.ownerTeamName && (
          <Banner className="f5 mb3" noImage>
            Your team has permissions to approve SmartPromos on behalf of of{' '}
            <b>{audioAdPlacementRequest.ownerTeamName}</b>. Once approved, this promo & its data
            will populate on the <b>{audioAdPlacementRequest.ownerTeamName}</b> publisher dashboard.
          </Banner>
        )}
        <EpisodeSelector
          teamId={teamId}
          podcastId={audioAdPlacementRequest.sellerPodcast.slug}
          scheduledAirDate={audioAdPlacementRequest.scheduledAirDate}
          onSelect={episode => handleApprovePromoRequest(episode)}
          error={approvalError}
        />
      </>
    );
  } else {
    content = (
      <Banner className="f5 mb3" error>
        <b>This promo has no promoting podcast assigned to it.</b>
        <div className="mt1">
          The podcast that will promote <i>{audioAdPlacementRequest.buyerPodcast.title}</i> is not
          assigned to this promo. Please get in touch with support to resolve this issue.
        </div>
      </Banner>
    );
  }

  return (
    <div>
      {topBar}
      <div className="pv3 ph4">
        <div className="mb3">
          <NewStatusIndicator
            className="f5 ttc b br4 ph3 pv1 help"
            status={audioAdPlacementRequest.statusCode}
            text={
              audioAdPlacementRequest.statusCode === 'NEEDS_APPROVAL'
                ? 'Needs Approval'
                : capitalizeFirstLetter(audioAdPlacementRequest.statusCode.toLowerCase())
            }
          />
        </div>
        <div className="f4 mb3">{audioAdPlacementRequest.name}</div>
        <div className="card pa3 mb3">
          <div className="flex flex-wrap" style={{ gap: '20px 0' }}>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoting</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {audioAdPlacementRequest.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={audioAdPlacementRequest.buyerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {audioAdPlacementRequest.buyerPodcast
                    ? audioAdPlacementRequest.buyerPodcast.shortTitle
                    : `${audioAdPlacementRequest.buyerTeam} network`}
                </div>
              </div>
            </div>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoted on</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {audioAdPlacementRequest.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={audioAdPlacementRequest.sellerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {audioAdPlacementRequest.sellerPodcast
                    ? audioAdPlacementRequest.sellerPodcast.shortTitle
                    : `${audioAdPlacementRequest.sellerTeam} network`}
                </div>
              </div>
            </div>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Buyer</div>
              <div className="f5 dark-gray truncate">{audioAdPlacementRequest.buyerTeam}</div>
            </div>
            {audioAdPlacementRequest.adType && (
              <div style={{ flex: '50%' }}>
                <div className="f6 gray mb1 b">Ad Type</div>
                <div className="f5 dark-gray truncate">{audioAdPlacementRequest.adType}</div>
              </div>
            )}
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Placement Type</div>
              <div className="f5 dark-gray truncate">
                {audioAdPlacementRequest.campaignType === 'dai' ? 'Dynamic' : 'Baked-in'}
              </div>
            </div>
          </div>
        </div>
        {content}
      </div>
    </div>
  );
};

export default AudioAdPlacementRequestDetailView;
