import axios from 'axios';
// handle csrf
const csrfElement =
  process.env.NODE_ENV === 'test' ? {} : document.head.querySelector('meta[name="csrf-token"]');
if (!csrfElement) {
  throw new Error('could not find CSRF token in <head>');
}

axios.defaults.xsrfHeaderName = 'X-CSRF-TOKEN'; // eslint-disable-line no-param-reassign
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': csrfElement.content,
  'X-Key-Inflection': 'camel',
  'Cache-Control': 'no-cache, max-age=0',
};

// This hack is the unfortunate result of needing to access team id from
// this file but having no better parent scope in which to store this.
// TODO: find a dynamic way of injecting the team id
if (window.teamId) {
  axios.defaults.headers.common['X-Team-Id'] = window.teamId;
}

// handle error codes and redirects
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data && error.response.data.location) {
      window.location = error.response.data.location;
    } else {
      return Promise.reject(error);
    }
  },
);

// Audience endpoints
export function getPodcastGeographicData(params) {
  return axios.get('/api/downloads/demographics/geography', { params });
}

export function getSmartPromosGeographicData(params) {
  return axios.get('/api/audio_rollups/geography', { params });
}

export function getSmartPromosPlayerData(params) {
  return axios.get('/api/audio_rollups/players', { params });
}

// team management
export function getTeam(params) {
  return axios.get(`/api/teams/${params.id}`, { params });
}

export function createTeam(params) {
  return axios.post('/api/teams', params);
}

export function updateTeam(params) {
  return axios.patch(`/api/teams/${params.id}`, params);
}

export function deleteTeam(params) {
  return axios.delete(`/api/teams/${params.id}`, { params });
}

export function getTeamPodcastDefaults(params) {
  return axios.get(`/api/teams/${params.id}/settings`, { params });
}

export const adminGetTeamsForUser = async params => {
  return axios.get(`/custom_admin/team_users`, {
    params,
  });
};

export function adminGetTeamEmailPreferences(params) {
  return axios.get(`/custom_admin/teams/${params.teamId}/email_preferences`, {
    params,
  });
}

export function adminUpdateTeamEmailPreferences(params) {
  return axios.patch(`/custom_admin/teams/${params.teamId}/bulk_update_email_preferences`, params);
}

export function adminConfirmInput(params) {
  return axios.post(`/custom_admin/teams/${params.slug}/confirm_team_user_creation`, params);
}

export function adminAddUserToTeam(params) {
  return axios.post(`/custom_admin/teams/${params.slug}/force_create_team_user`, params);
}

export function upsertBuyerProfile(params) {
  return axios.put(`/api/buyer_profiles/${params.teamId}`, params);
}

export function upsertSellerProfile(params) {
  return axios.put(`/api/seller_profiles/${params.teamId}`, params);
}

export function setViewedSellerPrompt(params) {
  return axios.post(`/api/teams/set_viewed_seller_prompt`, params);
}

// user management
export function getTeamUser(params) {
  return axios.get(`/api/team_users/${params.id}`, { params });
}

export function updateTeamUser(params) {
  return axios.patch(`/api/team_users/${params.id}`, params);
}

export function destroyTeamUser(params) {
  return axios.delete(`/api/team_users/${params.id}`, { params });
}

// team invites
// invites
export function getTeamInvites(params) {
  return axios.get('/api/team_invites', { params });
}

export function resendTeamInvite(params) {
  return axios.post(`/api/team_invites/${params.id}/resend`, params);
}

export function createTeamInvite(params) {
  return axios.post('/api/team_invites', params);
}

export function deleteTeamInvite(params) {
  return axios.delete(`/api/team_invites/${params.id}`, { params });
}

// team podcasts
export function getTeamPodcasts(params) {
  return axios.get('/api/team_podcasts', { params });
}

export function searchTeamPodcasts(params) {
  return axios.get('/api/team_podcasts/search', { params });
}

export function deleteTeamPodcast(params) {
  return axios.delete(`/api/team_podcasts/${params.teamPodcastId}`, { params });
}

// podcasts
export function getPodcast(params) {
  return axios.get(`/api/podcasts/${params.podcastId}`, { params });
}

export function getPodcasts(params) {
  return axios.get('/api/podcasts', { params });
}

export function getFilteredPodcasts(params) {
  return axios.get('/api/podcasts/filter', { params });
}

export function searchPodcasts(params) {
  return axios.get('/api/podcasts/search', { params });
}

export function buyerPodcastSearch(params) {
  return axios.get('/api/podcasts/buyer_search', { params });
}

export function getPodcastByItunesUrl(params) {
  return axios.get('/api/podcasts/search_by_itunes_url', { params });
}

export function toggleTrackPodcast(params) {
  return axios.post(`/api/podcasts/track/${params.id}`, params);
}

export function getPodcastsNormalizations(params) {
  return axios.get(`/custom_admin/podcasts/normalizations`, { params });
}

export function normalizePodcast(params) {
  return axios.patch(`/custom_admin/podcasts/${params.podcastId}/normalize`, params);
}

export function denormalizePodcast(params) {
  return axios.patch(`/custom_admin/podcasts/${params.podcastId}/denormalize`, params);
}

export function getPodcastRetention(params) {
  return axios.get(`/api/team_podcasts/${params.teamPodcastId}/retention`, {
    params,
  });
}

export function addMegaphoneMatches(params) {
  return axios.post('/api/podcasts/add_megaphone_matches', params);
}

export function getMegaphoneMatches(params) {
  return axios.get(`/api/podcasts/megaphone_matches`, { params });
}

// episodes
export function getEpisode(params) {
  return axios.get(`/api/episodes/${params.episodeId}`, { params });
}

export function getEpisodes(params) {
  return axios.get('/api/episodes', { params });
}

export function searchEpisodes(params) {
  return axios.get('/api/episodes/search', { params });
}

export function paginatedSearchEpisodes(params) {
  return axios.get('/api/episodes/paginated_search', { params });
}

export function buyerSearchEpisodes(params) {
  return axios.get('/api/episodes/buyer_search', { params });
}

export function getEpisodesSummary(params) {
  return axios.get('/api/episodes/summary', { params });
}

export function getEpisodesSync(params) {
  return axios.get('/api/episodes/sync', { params });
}

export function getEpisodicRetention(params) {
  return axios.get(`/api/team_podcasts/${params.teamPodcastId}/retention_by_episode`, { params });
}

// charts
export function getCharts(params) {
  return axios.get('/api/charts', { params });
}

export function getRankHistory(params) {
  return axios.get('/api/charts/rank_history', { params });
}

// integration stats
export function getDownloadsHistory(params) {
  return axios.get('/api/downloads/history', { params });
}

export function getPacingHistory(params) {
  return axios.get('/api/downloads/pacing', { params });
}

export function getDemographics(params) {
  return axios.get('/api/downloads/demographics', { params });
}

export function getPlayerData(params) {
  return axios.get('/api/downloads/player_data', { params });
}

export function getConsumptionSeries(params) {
  return axios.get('/api/downloads/consumption_series', { params });
}

export function getPlaysHistory(params) {
  return axios.get('/api/downloads/play_history', { params });
}

export function getAudienceOverlap(params) {
  return axios.get('/api/downloads/audience_overlap', { params });
}

// reviews
export function getReviews(params) {
  return axios.get('/api/reviews', { params });
}

export function getReviewsHistory(params) {
  return axios.get('/api/reviews/history', { params });
}

export function getReviewsSummary(params) {
  return axios.get('/api/reviews/summary', { params });
}

// credentials
export function createCredential(params) {
  return axios.post('/api/credentials', params);
}

export function updateCredential(params) {
  return axios.patch('/api/credentials', params);
}

export function destroyCredential(params) {
  return axios.delete(`/api/credentials/${params.id}`, { params });
}

export function getSpotifyOauthUrl(params) {
  return axios.post('/api/credentials/spotify_oauth', params);
}

export function scrapeCredential(params) {
  return axios.post('/api/credentials/scrape', params);
}

// invites
export function getInvites(params) {
  return axios.get('/api/invites', { params });
}

export function createInvite(params) {
  return axios.post('/api/invites', params);
}

export function getAudioAdCampaign(params) {
  return axios.get(`/api/audio_ad_campaigns/${params.audioAdCampaignId}`, {
    params,
  });
}

export function getAudioAdCampaigns(params) {
  return axios.get(`/api/audio_ad_campaigns`, { params });
}

export function getAudioRollups(params) {
  return axios.get('/api/audio_rollups', { params });
}

export function getAudioRollupReach(params) {
  return axios.get('/api/audio_rollups/reach', { params });
}

export function getAudioAdAggregateBenchmarks(params) {
  return axios.get('/api/audio_ad_benchmarks', { params });
}

export function getPublicAudioAdAggregateBenchmarks(params) {
  return axios.get('/api/audio_ad_benchmarks/public_index', { params });
}

export function getSellerPromos(params) {
  return axios.get('/api/audio_ad_placements/seller', { params });
}

export function getSellerPromoRequests(params) {
  return axios.get('/api/audio_ad_placement_requests/seller', { params });
}

export function getBuyerPromos(params) {
  return axios.get('/api/audio_ad_placements/buyer', { params });
}

export function getBuyerPromoRequests(params) {
  return axios.get('/api/audio_ad_placement_requests/buyer', { params });
}

export function getPromo(params) {
  return axios.get(`/api/promos/${params.promoId}`, { params });
}

export function postPromo(params) {
  return axios.post('/api/promos', params);
}

export function getSellerTeams(params) {
  return axios.get('/api/promos/seller_teams', { params });
}

export function archivePromo(params) {
  return axios.patch(`/api/audio_ad_placements/${params.audioAdPlacementId}/archive`, params);
}

export function putPromo(params) {
  return axios.put(`/api/audio_ad_placements/${params.audioAdPlacementId}`, params);
}

export function fillPromoEpisode(params) {
  return axios.post(`/api/audio_ad_placements/${params.audioAdPlacementId}/fill_episode`, params);
}

export function unarchivePromo(params) {
  return axios.patch(`/api/audio_ad_placements/${params.audioAdPlacementId}/unarchive`, params);
}

export function getPromoRequest(params) {
  return axios.get(`/api/audio_ad_placement_requests/${params.audioAdPlacementRequestId}`, {
    params,
  });
}

export function postPromoRequest(params) {
  return axios.post('/api/audio_ad_placement_requests', params);
}

export function deletePromoRequest(params) {
  return axios.delete(`/api/audio_ad_placement_requests/${params.audioAdPlacementRequestId}`, {
    params,
  });
}

export function approvePromoRequest(params) {
  return axios.patch(
    `/api/audio_ad_placement_requests/${params.audioAdPlacementRequestId}/approve`,
    params,
  );
}

export function denyPromoRequest(params) {
  return axios.patch(
    `/api/audio_ad_placement_requests/${params.audioAdPlacementRequestId}/deny`,
    params,
  );
}

export function getArchivedPromos(params) {
  params.archived = true;
  return axios.get('/api/audio_ad_placements/buyer', { params });
}

export function adminGetPromosWithStatus(params) {
  return new Set(['NEEDS_APPROVAL', 'DENIED', 'APPROVED']).has(params.status)
    ? axios.get('/custom_admin/audio_ad_placement_requests', { params })
    : axios.get('/custom_admin/audio_ad_placements', { params });
}

export function adminGetPromo(params) {
  return params.isRequest
    ? axios.get(`/custom_admin/audio_ad_placement_requests/${params.promoId}`, {
        params,
      })
    : axios.get(`/custom_admin/audio_ad_placements/${params.promoId}`, {
        params,
      });
}

export function adminUpdatePromo(params) {
  return params.isRequest
    ? axios.patch(`/custom_admin/audio_ad_placement_requests/${params.promoId}`, params)
    : axios.patch(`/custom_admin/audio_ad_placements/${params.promoId}`, params);
}

export function adminGetPromoApprovalBreakdown(params) {
  return axios.get('/custom_admin/audio_ad_placement_requests/approval_breakdown', { params });
}

export function adminSendPromoApprovalEmail(params) {
  return axios.post('/custom_admin/audio_ad_placement_requests/send_approval_emails', params);
}

export function adminFillPromoEpisode(params) {
  return axios.post(`/custom_admin/audio_ad_placements/${params.promoId}/fill_episode`, params);
}

export function adminGetPromosToFill(params) {
  return axios.get('/custom_admin/audio_ad_placements/fill_episode', { params });
}

// tracking links
export function generateLinkCSV(params) {
  return axios.get(`/api/tracking_links/${params.trackingLinkId}/generate_csv`, { params });
}

export function generateLinksCSV(params) {
  return axios.get(`/api/tracking_links/generate_links_csv`, { params });
}

export function getLinksSummary(params) {
  return axios.get('/api/tracking_links/summary', { params });
}

// subscription settings
export function getSubscriptionSettings(params) {
  return axios.get(`/api/subscription_settings/${params.teamId}`, { params });
}

export function getPlanDetails() {
  return axios.get(`/api/subscription_details`);
}

export function getStripeCheckoutRedirectUrl(params) {
  return axios.get('/api/checkout_redirect_url', { params });
}

export function getStripeCustomerPortalRedirectUrl(params) {
  return axios.get('/api/customer_portal_redirect_url', { params });
}

// Email preferences
export function getEmailPreferences() {
  return axios.get('/api/email_preferences');
}

export function updateEmailPreferences(params) {
  return axios.patch(`/api/email_preferences`, params);
}

export const createByItunesUrl = ({ url, teamId }) => {
  const params = { url: url, teamId: teamId };
  return axios.post('/api/podcasts/create_from_itunes_url', params);
};

export const createByFeedUrl = ({ url, teamId }) => {
  const params = { url: url, teamId: teamId };
  return axios.post('/api/podcasts/create_from_feed_url', params);
};

export function createTrackingPixel(params) {
  return axios.post(`/api/team_provider_tracking_pixels`, params);
}

export function teamSearch(params) {
  return axios.get(`/custom_admin/team_autocomplete`, { params });
}

// tracking links
export function getCompareClicksHistory(params) {
  return axios.get(`/api/tracking_links/compare_click_history`, { params });
}

export function getCompareDownloadsHistory(params) {
  return axios.get(`/api/tracking_links/compare_download_history`, { params });
}

export const updateLink = params => {
  return axios.patch(`/api/tracking_links/${params.trackingLink.slug}`, params);
};

export const fetchSignedImageUrl = params => {
  return axios.get('/tracking_links/signed_image_url', { params });
};

export const deleteSocialMediaUrl = params => {
  return axios.get(`/tracking_links/delete_social_media_image`, { params });
};

export function fetchConfigurablePlatforms(params) {
  return axios.get(`/api/tracking_links/configurable_platforms`, { params });
}

// elasticsearch
export const getElasticsearchKey = params => {
  return axios.get('/api/elasticsearch/api_key', { params });
};

export const refreshEpisodes = params => {
  return axios.get('/api/podcast/refresh', { params });
};

export function fetchEpisodeOptions(params) {
  return axios.get(`/api/episodes/search`, { params });
}

// reports
export function getReports(params) {
  return axios.get(`/api/teams/${params.teamId}/reports`, { params });
}

export function createReport(params) {
  return axios.post(`/api/teams/${params.teamId}/reports`, params);
}

export function downloadReport(params) {
  return axios.get(`/api/teams/${params.teamId}/reports/${params.reportId}/download`, { params });
}

export function deleteReport(params) {
  return axios.delete(`/api/teams/${params.teamId}/reports/${params.reportId}`, { params });
}

export function updateReport(params) {
  return axios.patch(`/api/teams/${params.teamId}/reports/${params.reportId}`, params);
}

// tracking link team podcast player url

export function createTrackingLinkTeamPodcastPlatformUrl(params) {
  return axios.post(`/api/tracking_link_team_podcast_platform_urls`, params);
}

export function deleteTrackingLinkTeamPodcastPlatformUrl(params) {
  return axios.delete(`/api/tracking_link_team_podcast_platform_urls/${params.id}`, { params });
}

export function updateTrackingLinkTeamPodcastPlatformUrl(params) {
  return axios.patch(`/api/tracking_link_team_podcast_platform_urls/${params.id}`, params);
}

export function getTrackingLinkTeamPodcastPlatformUrls(params) {
  return axios.get(`/api/tracking_link_team_podcast_platform_urls`, { params });
}

// users

export function dismissFlag(params) {
  return axios.get('/api/users/dismiss_flag', { params });
}

export function setViewedTour(params) {
  return axios.post('/api/users/set_viewed_tour', params);
}

// tracking link team podcast episode platform url

export function createTrackingLinkTeamPodcastEpisodePlatformUrl(params) {
  return axios.post(`/api/tracking_link_team_podcast_episode_platform_urls`, params);
}

export function deleteTrackingLinkTeamPodcastEpisodePlatformUrl(params) {
  return axios.delete(`/api/tracking_link_team_podcast_episode_platform_urls/${params.id}`, {
    params,
  });
}

export function updateTrackingLinkTeamPodcastEpisodePlatformUrl(params) {
  return axios.patch(`/api/tracking_link_team_podcast_episode_platform_urls/${params.id}`, params);
}

export function getTrackingLinkTeamPodcastEpisodePlatformUrls(params) {
  return axios.get(`/api/tracking_link_team_podcast_episode_platform_urls`, {
    params,
  });
}

export function fetchAllPlatforms() {
  return axios.get('/api/plaforms/');
}

// team user preferences

export function updateTeamUserPreferences(params) {
  return axios.patch(`/api/team_user_preference`, params);
}

export function getTeamUserPreferences(params) {
  return axios.get(`/api/team_user_preference`, { params });
}

// sessions

export function signIn(params) {
  return axios.post(`api/session`, params);
}

export function peekUser(params) {
  return axios.post(`api/session/peek`, params);
}

// multi factor auth

export function enableMFA(params) {
  return axios.post(`api/mfa`, params);
}

// chartable connect

export function getAdvertiserProfile(params) {
  return axios.get(`/api/buyer_profiles`, params);
}

export function sendConnectMessage(params) {
  return axios.post(`/api/connect_threads`, params);
}

export function setViewedConnectTour(params) {
  return axios.post(`/api/connect/visited/${params.tour}`, params);
}

// household demos

export function getHouseHoldDemographics(params) {
  return axios.get(`/api/downloads/demographics/household_demographics`, {
    params,
  });
}

// Proxy for podcast cover art
export function getPodcastCoverArt(params) {
  return axios.get(`/api/podcasts/${params.podcastId}/podcast_cover_art`, {
    params,
  });
}

// SmartFeeds
export const getSmartFeeds = params => {
  return axios.get('/api/smart_feeds', { params });
};

export const updateSmartFeed = params => {
  return axios.put(`/api/smart_feeds/${params.feedId}`, params);
};

export const createSmartFeed = params => {
  return axios.post(`/api/smart_feeds`, params);
};

// Sales sheet
export const createSalesSheet = params => {
  return axios.post('/api/sales_sheets', params);
};

export const getSalesSheets = params => {
  return axios.get('/api/sales_sheets', { params });
};

export const deleteSalesSheet = params => {
  return axios.delete(`/api/sales_sheets/${params.id}`, { params });
};

export const publicGetSalesSheet = params => {
  return axios.get(`/api/public_sales_sheets/${params.id}`, { params });
};

// Partners

export const adminGetPartners = params => {
  return axios.get('/custom_admin/partners', { params });
};

export const adminCreatePartner = params => {
  return axios.post('/custom_admin/partners', params);
};

export const adminDeletePartner = params => {
  return axios.delete(`/custom_admin/partners/${params.id}`, { params });
};

export const adminGetPodcastOwnersAndAgencies = params => {
  return axios.get('/custom_admin/podcasts/owners_and_partners', { params });
};

// Adops
export const getAdOpsRequests = params => {
  return axios.get('/api/adops_tasks', { params });
};

export const getAdOpsCampaignsAndPromos = params => {
  return axios.get('/api/adops_tasks/approved', { params });
};

export const getAdOpsPlacement = params => {
  return axios.get(`/api/adops_tasks/${params.id}`, { params });
};

export const getAdOpsFilterOptions = params => {
  return axios.get('/api/adops_tasks/filter_options', { params });
};
