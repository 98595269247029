import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function StarRatings({ blue, rating, className }) {
  return (
    <div className={className}>
      {[1, 2, 3, 4, 5].map(index => (
        <div
          key={index}
          className={classNames(
            'icon-star dib',
            blue ? 'blue' : null,
            rating >= index ? 'full' : rating >= index - 0.5 ? 'half' : '',
          )}
        >
          ☆
        </div>
      ))}
    </div>
  );
}

StarRatings.propTypes = {
  rating: PropTypes.number.isRequired,
  className: PropTypes.string,
};

StarRatings.defaultProps = {
  className: '',
};
