import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const TopBar = ({ text, podcast, backUrl }) => (
  <div className="bb b--light-gray w-100 bg-white pv2 ph4 items-center f5">
    <div className="flex items-center justify-between" style={{ height: '1.5rem' }}>
      <Link to={backUrl} className="link blue pointer">
        « {text}
      </Link>
      {podcast && (
        <div className="bg-white flex flex-row items-center pr1 mw6 br1 w4-5">
          <img style={{ height: '1.5rem' }} src={podcast.displayImageUrl} alt="" />
          <div className="f6 dark-gray ml1 truncate">{podcast.shortTitle}</div>
        </div>
      )}
    </div>
  </div>
);

PropTypes.propTypes = {
  backUrl: PropTypes.string.isRequired,
  podcast: PropTypes.object,
  text: PropTypes.string,
};

PropTypes.defaultProps = {
  podcast: null,
  text: '',
};

export default TopBar;
