import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Spotify from 'images/spotify.png';
import GooglePodcasts from 'images/google_podcasts_logo.png';
import ItunesConnect from 'images/logo-apple-podcasts.png';
import Breaker from 'images/logo-breaker.png';
import PocketCasts from 'images/logo-pocket-casts.png';
import PodcastAddict from 'images/logo-podcast-addict.png';
import Rss from 'images/rss.png';
import RadioPublic from 'images/radio_public.svg';
import OverCast from 'images/overcast.svg';
import CastBox from 'images/castbox.svg';
import Pandora from 'images/pandora.png';
import iHeartRadio from 'images/i_heart_radio.png';
import YouTube from 'images/youtube.png';
import AmazonMusic from 'images/logo-amazon-music.png';
import RadioDotCom from 'images/logo-radio_com.png';
import CustomLinkDestination from 'images/custom-link-destination.svg';
import Uforia from 'images/logo-uforia.png';

var placeholder = document.createElement('div');
placeholder.className = 'placeholder';
class TrackingLinkPlayers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      configurablePlayers: this.props.configurablePlayers,
      players: this.props.configurablePlayers,
      removedPlayers: [],
    };
  }

  componentDidMount() {
    if (this.props.trackingLink && this.props.trackingLink.id) {
      const configurablePlayers = this.props.configurablePlayers;
      const currentPlayers = this.props.trackingLink.players
        ? this.props.trackingLink.players['all']
        : this.props.configurablePlayers;
      const removedPlayers = configurablePlayers.filter(x => !currentPlayers.includes(x));

      this.setState({
        players: currentPlayers,
        removedPlayers: removedPlayers,
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.configurablePlayers !== this.props.configurablePlayers) {
      const configurablePlayers = this.props.configurablePlayers;
      const currentPlayers =
        this.props.trackingLink && this.props.trackingLink.players
          ? this.props.trackingLink.players['all']
          : configurablePlayers;
      const removedPlayers = configurablePlayers.filter(x => !currentPlayers.includes(x));
      this.setState({
        players: currentPlayers,
        removedPlayers: removedPlayers,
        configurablePlayers: configurablePlayers,
      });
    }
  }

  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
    document.body.style.cursor = 'grabbing';
  }
  dragEnd(e) {
    this.dragged.style.display = null;
    this.dragged.parentNode.removeChild(placeholder);
    // update state
    var data = this.state.players;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over.dataset.id);
    if (from < to) to--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({ players: data });
    this.onHandleUpdatePlayers(e);
  }
  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = 'none';
    if (e.target.className === 'placeholder') return;
    var target = e.target.nodeName == 'SPAN' ? e.target.parentNode : e.target;
    this.over = target;
    e.currentTarget.insertBefore(placeholder, target);
  }

  removePlayer(e) {
    let players = this.state.players;
    let playerId = e.target.dataset.id;
    var removedPlayer = players.splice(playerId, 1);
    let removedPlayers = this.state.removedPlayers;
    removedPlayers.push(removedPlayer[0]);

    this.setState({
      players: players,
      removedPlayers: removedPlayers,
    });
    this.onHandleUpdatePlayers(e);
  }

  addPlayer(e) {
    let playerId = e.target.dataset.id;
    var addedPlayer = this.state.removedPlayers.splice(playerId, 1);
    let players = this.state.players;
    players.push(addedPlayer[0]);

    this.setState({
      players: players,
    });
    this.onHandleUpdatePlayers(e);
  }

  onHandleUpdatePlayers(e) {
    e.preventDefault();
    this.props.handleUpdatePlayerPositions(this.state.players);
  }

  render() {
    const playersInfo = {
      itunes: {
        name: 'Apple Podcasts',
        image: ItunesConnect,
      },
      spotify: {
        name: 'Spotify',
        image: Spotify,
      },
      google_podcasts: {
        name: 'Google Podcasts',
        image: GooglePodcasts,
      },
      overcast: {
        name: 'Overcast',
        image: OverCast,
      },
      castbox: {
        name: 'CastBox',
        image: CastBox,
      },
      radio_public: {
        name: 'Radio Public',
        image: RadioPublic,
      },
      breaker: {
        name: 'Breaker',
        image: Breaker,
      },
      pocket_casts: {
        name: 'Pocket Casts',
        image: PocketCasts,
      },
      podcast_addict: {
        name: 'Podcast Addict',
        image: PodcastAddict,
      },
      rss: {
        name: 'RSS',
        image: Rss,
      },
      pandora: {
        name: 'Pandora',
        image: Pandora,
      },
      i_heart_radio: {
        name: 'iHeartRadio',
        image: iHeartRadio,
      },
      youtube: {
        name: 'YouTube',
        image: YouTube,
      },
      amazon_music: {
        name: 'Amazon Music',
        image: AmazonMusic,
      },
      radio_com: {
        name: 'Audacy',
        image: RadioDotCom,
      },
      custom: {
        name: 'Podcast Homepage',
        image: CustomLinkDestination,
      },
      uforia: {
        name: 'Uforia',
        image: Uforia,
      },
    };

    var listItems = this.state.players.map((item, i) => {
      return (
        <div
          key={i}
          className={
            'glow w-60-l f4 mb1 ba br3 b--black pa2 center bg-washed-blue flex items-center'
          }
          style={{ cursor: 'pointer' }}
          data-id={i}
          draggable="true"
          onDragEnd={this.dragEnd.bind(this)}
          onDragStart={this.dragStart.bind(this)}
        >
          <img
            src={playersInfo[item]['image']}
            alt={item}
            style={{ width: '27px', height: '27px' }}
            className="fl v-mid"
          />
          <span className="ml3 v-mid w-60-ns">{playersInfo[item]['name']}</span>
          <div
            className="w-20-ns pa2 f6 bg-light-red dim fr tc br-pill pointer h2 v-mid"
            data-id={i}
            onClick={this.removePlayer.bind(this)}
          >
            Hide
          </div>
        </div>
      );
    });
    var listRemovedPlayers = this.state.removedPlayers.map((item, i) => {
      return (
        <div
          key={i}
          className={
            'glow mb1 f4 w-60-l ba br3 b--black mt2 pa2 center bg-washed-blue flex items-center'
          }
          data-id={i}
        >
          <img
            src={playersInfo[item]['image']}
            alt={item}
            style={{ width: '27px', height: '27px' }}
            className="fl v-mid"
          />
          <span className="ml3 v-mid w-60-ns"> {playersInfo[item]['name']}</span>
          <div
            className="w-20-ns pa2 f6 bg-light-green dim fr tc br-pill pointer h2 v-mid"
            data-id={i}
            onClick={this.addPlayer.bind(this)}
          >
            Add
          </div>
        </div>
      );
    });
    return (
      <div className="w-100">
        <div onDragOver={this.dragOver.bind(this)} className=" bg-almost-white pv2 pb2" id="list">
          <label className="db lh-copy f4 header-font tc mb1">Visible Players</label>
          {listItems}
        </div>

        <div className="w-100">
          <div className="bg-almost-white pv2 pb2">
            <label className="db lh-copy f4 header-font tc mb1">Hidden players</label>
            {listRemovedPlayers}
          </div>
        </div>
      </div>
    );
  }
}

TrackingLinkPlayers.propTypes = {
  handleUpdatePlayerPositions: PropTypes.func,
  trackingLink: PropTypes.object,
  configurablePlayers: PropTypes.array.isRequired,
};

TrackingLinkPlayers.defaultProps = {
  trackingLink: null,
};

export default TrackingLinkPlayers;
