import React from 'react';
import { Link, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Header from 'shared/Header';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';
import TabBarItem from 'shared/TabBarItem';

import CustomDomainSetupView from './components/CustomDomainSetupView';
import SettingsDeeplinkManager from './components/SettingsDeeplinkManager';
import SettingsRemarketingPixelManager from './components/SettingsRemarketingPixelManager';

function Settings({ match, location, linkableReadOnly }) {
  const { teamId } = match.params;
  const readOnlyMode = useReadOnlyMode();

  if (readOnlyMode) {
    // This sucks but the way we do routing right now it's best I can do
    return null;
  }

  return (
    <div>
      <div className="bg-white pt2 ph4">
        <Link to={`/teams/${teamId}/dashboard/links`} className="link blue pointer">
          « Overview
        </Link>
        <div className="flex items-center flex-row justify-between mb4 mt3">
          <Header useNew>SmartLinks Settings</Header>
        </div>
        <div className="flex flex-row gap-large">
          <TabBarItem
            title="Manage Deeplinks"
            route={`/teams/${teamId}/dashboard/links/settings/deeplinks`}
            width="9rem"
            selected={location.pathname === `/teams/${teamId}/dashboard/links/settings/deeplinks`}
          />
          <TabBarItem
            title="Custom Domain"
            route={`/teams/${teamId}/dashboard/links/settings/custom_domains`}
            width="9rem"
            selected={
              location.pathname === `/teams/${teamId}/dashboard/links/settings/custom_domains`
            }
          />
          <TabBarItem
            title="Remarketing Pixels"
            route={`/teams/${teamId}/dashboard/links/settings/remarketing_pixel`}
            width="10rem"
            selected={
              location.pathname === `/teams/${teamId}/dashboard/links/settings/remarketing_pixel`
            }
          />
        </div>
      </div>
      <div className="ph4 pv3">
        <Switch>
          <Route
            path="/teams/:teamId/dashboard/links/settings/deeplinks"
            render={() => <SettingsDeeplinkManager linkableReadOnly={linkableReadOnly} />}
          />
          <Route
            path="/teams/:teamId/dashboard/links/settings/custom_domains"
            render={() => <CustomDomainSetupView linkableReadOnly={linkableReadOnly} />}
          />
          <Route
            path="/teams/:teamId/dashboard/links/settings/remarketing_pixel"
            render={() => <SettingsRemarketingPixelManager linkableReadOnly={linkableReadOnly} />}
          />
        </Switch>
      </div>
    </div>
  );
}

Settings.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default Settings;
