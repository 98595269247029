import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import BasicModal from 'shared/BasicModal';
import FormError from 'shared/FormError';
import { createTeam } from 'shared/api';

export default class NewDivisionForm extends Component {
  constructor(props) {
    super(props);

    this.handleCreateTeam = this.handleCreateTeam.bind(this);

    this.state = {
      name: '',
      errors: {},
      loading: false,
    };
  }

  handleCreateTeam() {
    const { teamSlug, onCreateNotification, onCloseForm } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });

    createTeam({
      teamId: teamSlug,
      name,
    }).then(res => {
      if (res.data.errors) {
        this.setState({
          loading: false,
          errors: res.data.errors,
        });
      } else {
        onCreateNotification(`New division ${res.data.name} created.`);
        onCloseForm();
      }
    });
  }

  render() {
    const { name, errors, loading } = this.state;

    const { onCloseForm } = this.props;

    const disabled = name.length < 3;

    return (
      <div>
        <BasicModal isOpen onRequestClose={() => onCloseForm()}>
          <div className="f4 mb4 tc b">Create a New Division</div>
          <div className="mb4">
            <div className="f5 header-font mb2">Name</div>
            <input
              type="text"
              className="pa2 input-reset ba w-100 bg-white br2 b--silver string optional"
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <FormError error={errors.name} />
          </div>
          <Button
            className="w-100"
            type="primary"
            disabled={disabled}
            onClick={() => this.handleCreateTeam()}
            loading={loading}
          >
            Create Division
          </Button>
        </BasicModal>
      </div>
    );
  }
}

NewDivisionForm.propTypes = {
  teamSlug: PropTypes.string.isRequired,
  onCloseForm: PropTypes.func.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
};
