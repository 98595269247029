import React, { useEffect, useState } from 'react';
import Loading from 'shared/Loading';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import Sidebar from 'shared/Sidebar';

import ProviderContainer from './components/ProviderContainer';

const DashboardChartsOverview = ({
  chartHistories,
  pods,
  teamId,
  podcastsCount,
  maxPodcasts,
  singlePodcast,
  sidebar,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedPodcast, setSelected] = useState(singlePodcast);

  const renderCharts = history => {
    if (history.filter(h => !!h).length === 0) {
      const urlBase = `/teams/${teamId}/dashboard/charts`;
      const url = !singlePodcast ? urlBase : `${urlBase}?podcast_id=${singlePodcast.id}`;
      return (
        <div>
          <div className="f4 gray mb3">Your show doesn't rank on any charts right now :)</div>
          <a className="link blue" href={url}>
            View chart history
          </a>
        </div>
      );
    }
    return (
      <div>
        {history.map(h => {
          if (!h) {
            return null;
          }
          return <ProviderContainer history={h} />;
        })}
      </div>
    );
  };

  const renderMoreNotification = () => {
    if (podcastsCount <= maxPodcasts) {
      return null;
    }
    return (
      <div className="mb3 pa3 br2 bg-washed-yellow">
        Showing {maxPodcasts} of {podcastsCount} podcasts. Select a podcast below to see all charts
        for a single show.
      </div>
    );
  };

  const handleSelect = podcast => {
    setLoading(true);
    setSelected(podcast.data);
    const urlBase = `/teams/${teamId}/dashboard/charts/overview`;
    window.location.href =
      podcast.type === 'all' ? urlBase : `${urlBase}?podcast_id=${podcast.data.id}`;
  };

  const renderPodcastSwitcher = () => {
    if (pods.length === 1) {
      return null;
    }
    return (
      <PodcastSwitcher
        teamId={teamId}
        onSelect={handleSelect}
        loading={false}
        selectedPodcast={selectedPodcast}
        defaultToNone={false}
        defaultToAll
        includeNone={false}
        includeAll
        fetchDefaultOptions={false}
        defaultOptions={pods}
        className="mb3"
      />
    );
  };

  const renderContent = () => {
    if (loading) {
      return <Loading fullScreen />;
    }

    return (
      <div>
        {chartHistories.map(ch => {
          return (
            <div>
              <div className="f3 dark-blue mb2">{singlePodcast ? 'Charts' : ch.name}</div>
              {renderCharts(ch.history)}
            </div>
          );
        })}
        <div>
          <p className="pa3 bg-washed-green gray lh-copy br2 mw8">
            <strong>Note: </strong>
            If you see charts abroad but not in your home country&mdash;e.g. if you're based in the
            US but only see ranks from a country like Spain&mdash;it's because you're not currently
            appearing on charts in your home country.
          </p>
        </div>
      </div>
    );
  };

  return (
    <Sidebar sidebar={sidebar}>
      <div className="pa3 mw8">
        {renderMoreNotification()}
        {renderPodcastSwitcher()}
        {renderContent()}
      </div>
    </Sidebar>
  );
};

export default DashboardChartsOverview;
