import React from 'react';
import PropTypes from 'prop-types';
import { updateEmailPreferences } from 'shared/api';
import Button from 'shared/Button';

class EmailPreferences extends React.Component {
  constructor(props) {
    super(props);

    this.onSave = this.onSave.bind(this);

    this.state = {
      attributionStatusNotifications: props.attributionStatusNotifications,
      smartpromoApprovalRequests: props.smartpromoApprovalRequests,
      requestInProgress: false,
    };
  }

  onSave() {
    if (this.state.requestInProgress) return;
    this.setState({ requestInProgress: true });

    const params = { teamId: this.props.teamId };

    if (this.state.smartpromoApprovalRequests !== null) {
      params.smartpromoApprovalRequests = this.state.smartpromoApprovalRequests;
    }

    if (this.state.attributionStatusNotifications !== null) {
      params.attributionStatusNotifications = this.state.attributionStatusNotifications;
    }

    updateEmailPreferences(params)
      .then(res => this.props.onSuccess(res.data))
      .catch(() => {
        this.props.onFailure();
      })
      .finally(() => this.setState({ requestInProgress: false }));
  }

  render() {
    const { smartpromoApprovalRequests, requestInProgress } = this.state;

    return (
      <div>
        <div className="f3 dark-blue mb3 header-font mr2">Email Preferences</div>
        {smartpromoApprovalRequests !== null && (
          <div>
            <div className="mb2 f4 header-font">SmartPromos</div>
            <div className="f5 flex items-center mb4">
              <input
                className="mr2"
                checked={smartpromoApprovalRequests}
                type="checkbox"
                data-testid="smart-promo-approval-request-checkbox"
                onChange={() =>
                  this.setState({
                    smartpromoApprovalRequests: !smartpromoApprovalRequests,
                  })
                }
              />
              <div className="b lh-copy">External SmartPromos approval requests</div>
            </div>
          </div>
        )}
        <Button
          className="mt2 w4"
          loading={requestInProgress}
          onClick={this.onSave}
          type="primary"
          testId="email-preferences-save-btn"
        >
          Save
        </Button>
      </div>
    );
  }
}

EmailPreferences.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  attributionStatusNotifications: PropTypes.bool,
  smartpromoApprovalRequests: PropTypes.bool,
  teamId: PropTypes.string,
};

EmailPreferences.defaultProps = {
  attributionStatusNotifications: null,
  smartpromoApprovalRequests: null,
};
export default EmailPreferences;
