import axios from 'axios';
import { useEffect, useState } from 'react';
import { refreshJWT } from 'shared/api/v1';

function useJWTManager(userId, teamId) {
  const [token, setToken] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [error, setError] = useState(null);

  const init = async () => {
    const payload = await refreshToken();
    const exp = payload.exp * 1000; // Convert seconds to milliseconds
    const interval = exp - Date.now() - 600000; // Request a new JWT 10 minutes before it expires
    setInterval(refreshToken, interval);
  };

  const refreshToken = async () => {
    try {
      const res = await refreshJWT();
      setToken(res.data.token);
      return res.data;
    } catch (e) {
      setError(e);
      return null;
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    axios.defaults.headers.common['X-JWT-Auth'] = token;

    if (token) {
      setInitialLoad(false);
    }
  }, [token]);

  return {
    initialLoad,
    error,
  };
}

export default useJWTManager;
