import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getAudioRollups } from 'shared/api';
import { getFlooredUtcDateTime } from 'shared/helpers.js';
import Loading from 'shared/Loading';

import RollupTable from '../../RollupTable';

const RollupByPodcastView = ({ match, history, teamId, campaign, startDate, endDate }) => {
  const [podcastRollups, setPodcastRollups] = useState(null);
  const [loading, setLoading] = useState();

  const firstUpdate = useRef(true);

  const getPodcastRollups = async () => {
    try {
      const rollups = await getAudioRollups({
        teamId,
        audioAdCampaignId: campaign.audioAdCampaignId,
        startDate: getFlooredUtcDateTime(startDate),
        endDate: getFlooredUtcDateTime(endDate),
        pivotColumn: 'podcast_id',
      });
      setPodcastRollups(rollups.data);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (firstUpdate.current) {
      window.scrollTo(0, 0);
      firstUpdate.current = false;
    }
    getPodcastRollups();
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  if (loading) {
    return <Loading fullScreen />;
  }

  if (!podcastRollups) {
    return <Loading fullScreen />;
  }

  return (
    <RollupTable
      rollups={podcastRollups}
      dimension="Podcast"
      onRowClick={row => {
        if (row.original.podcastId) {
          history.push(`${match.url}/${row.original.podcastId}`);
        }
      }}
    />
  );
};

RollupByPodcastView.propTypes = {
  campaign: PropTypes.object.isRequired,
};

export default RollupByPodcastView;
