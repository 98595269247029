/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import Vibrant from 'node-vibrant/lib/bundle';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import SmallLoading from 'shared/SmallLoading';

// eslint-disable-next-line react/prop-types
function Swatch({ color, onClick }) {
  return (
    <div
      onClick={onClick}
      className="br-100 ba b--dark-gray pointer dim"
      style={{ height: '1.25rem', width: '1.25rem', background: color }}
    />
  );
}

Swatch.defaultProps = {
  color: '#FFFFFF',
};

function ColorPicker({
  color,
  onSelectColor,
  imageUrl,
  podcastId,
  teamId,
  cachedPalette,
  cachePalette,
}) {
  const [customColor, setCustomColor] = useState('#FFFFFF');
  const [loadingColors, setLoadingColors] = useState(true);
  const [palette, setPalettte] = useState(['#4B9EC1', '#800000', '#008000', '#008080', '#000000']);

  const fetchPodcastCoverArt = async () => {
    try {
      const fetchedPalette = await Vibrant.from(
        `/api/podcasts/${podcastId}/podcast_cover_art?team_id=${teamId}`,
      ).getPalette();
      const newPalette = Object.keys(fetchedPalette)
        .map(c => palette[c].getHex())
        .slice(0, 5);
      cachePalette(newPalette);
      setPalettte(newPalette);
    } finally {
      setLoadingColors(false);
    }
  };

  useEffect(() => {
    if (cachedPalette) {
      setPalettte(cachedPalette);
      setLoadingColors(false);
    } else {
      fetchPodcastCoverArt();
    }
  }, []);

  function onChangeCustomColor(e) {
    if (e.target.value.length > 7) return;
    setCustomColor(e.target.value);
  }

  const customColorIsValid = useMemo(() => /^#[a-zA-Z0-9]{6}$/.test(customColor), [customColor]);

  const buttonTextColor = useMemo(() => {
    if (!customColorIsValid) return 'dark-gray';
    const colorString = customColor.substring(1, 7);
    const r = parseInt(colorString.substring(0, 2), 16); // hexToR
    const g = parseInt(colorString.substring(2, 4), 16); // hexToG
    const b = parseInt(colorString.substring(4, 6), 16); // hexToB
    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? 'dark-gray' : 'white';
  }, [customColor]);

  return (
    <div>
      <div className="flex flex-row gap-large">
        <div
          className="flex justify-between flex-column"
          style={{ height: '140px', width: '1.25rem' }}
        >
          {loadingColors ? (
            <SmallLoading />
          ) : (
            palette.map((hex, index) => (
              <Swatch key={index} onClick={() => onSelectColor(hex)} color={hex} />
            ))
          )}
        </div>
        <div
          className="h-auto flex items-center justify-center b--moon-gray ba br2 w5"
          style={{ background: color }}
        >
          <img
            alt="color-image"
            src={imageUrl}
            className="h-auto w-40 br4"
            style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}
          />
        </div>
      </div>
      <div className="flex flex-row gap-large" style={{ marginTop: '10px' }}>
        <Swatch color="#FFFFFF" onClick={() => onSelectColor('#FFFFFF')} />
        <div className="flex flex-row w5">
          <input
            style={{ flexGrow: 1, outline: 'none' }}
            className="pa1 input-reset bb bt bl br-0 br--left bg-white f6 w4 br2 w-100 b--moon-gray"
            type="text"
            value={`#${customColor.substring(1)}`}
            onChange={onChangeCustomColor}
          />
          <Button
            style={{ background: customColorIsValid ? customColor : '#CCCCCC' }}
            className={`${buttonTextColor} br--right f6 w3`}
            size="small"
            disabled={!customColorIsValid}
            onClick={() => onSelectColor(customColor)}
          >
            Set
          </Button>
        </div>
      </div>
    </div>
  );
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  onSelectColor: PropTypes.func.isRequired,
  imageUrl: PropTypes.string,
  podcastId: PropTypes.string,
  teamId: PropTypes.string,
  cachedPalette: PropTypes.array,
  cachePalette: PropTypes.func,
};

ColorPicker.defaultProps = {
  imageUrl: null,
  podcastId: null,
  teamId: null,
  cachedPalette: null,
};

export default ColorPicker;
