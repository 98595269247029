import React from 'react';
import styled from 'styled-components';

import Header from './components/Header';
import SideNavigation from './components/SideNavigation';

const Wrapper = styled.div`
  font-family: 'CircularSp';
  display: flex;
`;
const AdminDashboard = ({ userName }) => {
  return (
    <Wrapper>
      <SideNavigation />
      <Header userName={userName} />
      <footer />
    </Wrapper>
  );
};

export default AdminDashboard;
