import React from 'react';
import TabBar, { TabBarItem } from 'shared/TabBar';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

function ReportsHeader({ match, history, className }) {
  const readOnlyMode = useReadOnlyMode();

  const { teamId } = match.params;
  const { path } = match;

  const baseUrl = `/teams/${teamId}/dashboard/reports`;

  const selectedClassName = 'no-underline f5 dim pointer blue pb2 bb bw2 w4 tc b--blue';
  const unselectedClassName =
    'no-underline f5 dim pointer near-black pb2 bb bw2 w4 tc b--moon-gray';

  return (
    <div className={className}>
      <TabBar>
        <TabBarItem
          title="Recently Run"
          route={baseUrl}
          selected={path === '/teams/:teamId/dashboard/reports'}
        />
        {!readOnlyMode && (
          <TabBarItem
            title="Create"
            route={`${baseUrl}/create`}
            selected={path === '/teams/:teamId/dashboard/reports/create'}
          />
        )}
      </TabBar>
    </div>
  );
}

export default ReportsHeader;
