import React, { Component } from 'react';
import styles from './styles.module.css';
import arrowUp from 'images/icons/stat_up.svg';
import arrowDown from 'images/icons/stat_down.svg';
import StarRatings from 'shared/StarRatings';
import ReactTooltip from 'react-tooltip';

const Stat = ({ cardCount, percentGrowth, stat, label, byline, tooltip, stars }) => {
  const isIncrease = percentGrowth >= 0;

  const getWidth = () => {
    const percent = Math.round(1000 / cardCount) / 10;
    const margin = Math.round((((cardCount - 1) * 8) / cardCount) * 10) / 10;
    const cardSize = `calc(${percent}% - ${margin}px)`;
    return cardSize;
  };

  const Change = () => {
    return (
      <div className={`flex justify-start`}>
        <div className={styles.icon}>
          <img src={isIncrease ? arrowUp : arrowDown} />
        </div>
        <div className={`${styles.percent} ${isIncrease ? null : styles.percentDecline}`}>
          {Math.abs(Math.round(percentGrowth))}%
        </div>
      </div>
    );
  };

  const Top = () => {
    return (
      <div>
        <ReactTooltip id={label} />
        <div className={styles.topText}>{label}</div>
        {tooltip && (
          <div className="f7 b gray ttu tracked dib tc ml2" style={{ marginTop: '3px' }}>
            <a
              data-tip={tooltip}
              data-for={label}
              className="ph1 ba b--gray br-100 tc"
              style={{ cursor: 'help' }}
            >
              ?
            </a>
          </div>
        )}
      </div>
    );
  };

  const Bottom = () => {
    let content;
    if (isNaN(stat)) {
      content = stat.toUpperCase();
    } else {
      content = (Math.round(stat * 10) / 10).toLocaleString();
    }
    return (
      <div className={`flex justify-start`}>
        <div className={styles.bottomStat}>
          {content}
          {byline && <span className={styles.byline}>({byline})</span>}
        </div>
        {!stars && !!percentGrowth && (
          <div>
            <Change />
          </div>
        )}
        {stars && (
          <div className={styles.stars}>
            <StarRatings blue rating={stat} className="tc" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: getWidth() }} className={`bg-white ${styles.statContainer}`}>
      <Top />
      <Bottom />
    </div>
  );
};

export default Stat;
