import axios from 'axios';

if (window.teamId) {
  axios.defaults.headers.common['X-Team-Id'] = window.teamId;
}

// Shared
export const getConsumption = params => {
  return axios.get('/api/v1/consumption', { params });
};

export const getDownloads = params => {
  return axios.get('/api/v1/downloads', { params });
};

export const getDemographics = params => {
  return axios.get('/api/v1/demographics', { params });
};

export const getPlayers = params => {
  return axios.get('/api/v1/players', { params });
};

export const getPlays = params => {
  return axios.get('/api/v1/plays', { params });
};

// Remarketing pixels
export const getTrackingLinkRemarketingPixels = params => {
  return axios.get('/api/v1/remarketing_pixels', { params });
};

export const createTrackingLinkRemarketingPixels = params => {
  return axios.post('/api/v1/remarketing_pixels', params);
};

export const deleteTrackingLinkRemarketingPixels = params => {
  return axios.delete(`/api/v1/remarketing_pixels/${params.id}`, { params });
};

export const updateTrackingLinkRemarketingPixel = params => {
  return axios.patch(`/api/v1/remarketing_pixels/${params.id}`, params);
};

// SmartFeeds
export const deleteSmartFeed = (feedId, teamId) => {
  axios.defaults.headers.common['X-Team-Id'] = teamId;
  return axios.delete(`/api/v1/smart_feeds/${feedId}`);
};

// SmartLinks
export function createTrackingLink(params) {
  return axios.post('/api/v1/smart_links', params);
}

export function updateTrackingLink(params) {
  return axios.patch(`/api/v1/smart_links/${params.id}`, params);
}

export function fetchTrackingLinkTimeseriesData(params) {
  return axios.get(`/api/v1/smart_links/${params.trackingLinkId}/timeseries`, {
    params,
  });
}

export function fetchTrackingLinkDetails(params) {
  return axios.get(`/api/v1/smart_links/${params.trackingLinkId}/details`, {
    params,
  });
}

export function getTrackingLinks(params) {
  return axios.get('/api/v1/smart_links', { params });
}

export function searchTrackingLinks(params) {
  return axios.get('/api/v1/smart_links/search', { params });
}

export function getTrackingLink(params) {
  return axios.get(`/api/v1/smart_links/${params.trackingLinkId}`, { params });
}

// SmartLinks custom domains
export function getTrackingLinkTeamDomains(params) {
  return axios.get('/api/v1/smart_link_domains', { params });
}

export function createTrackingLinkTeamDomain(params) {
  return axios.post('/api/v1/smart_link_domains', params);
}

export function destroyTrackingLinkTeamDomain(params) {
  return axios.delete(`/api/v1/smart_link_domains/${params.id}`);
}

export function checkTrackingLinkTeamDomainStatus(params) {
  return axios.patch(`/api/v1/smart_link_domains/${params.id}/verify`);
}

export function createTrackingLinkTeamDomainPodcast(params) {
  return axios.post(
    `/api/v1/smart_link_domains/${params.trackingLinkTeamDomainId}/add_podcast`,
    params,
  );
}

export function deleteTrackingLinkTeamDomainPodcasts(params) {
  return axios.delete(
    `/api/v1/smart_link_domains/${params.trackingLinkTeamDomainId}/remove_podcast`,
    { params },
  );
}

// SmartLinks Deeplinks
export function getTrackingLinkPodcastDeeplinks(params) {
  return axios.get(`/api/v1/smart_link_podcast_deeplinks`, { params });
}

export function createTrackingLinkPodcastDeeplinks(params) {
  return axios.post(`/api/v1/smart_link_podcast_deeplinks`, params);
}

export function deleteTrackingLinkPodcastDeeplinks(params) {
  return axios.delete(`/api/v1/smart_link_podcast_deeplinks/${params.id}`, params);
}

export function updateTrackingLinkPodcastDeeplinks(params) {
  return axios.patch(`/api/v1/smart_link_podcast_deeplinks/${params.id}`, params);
}

export function getTrackingLinkEpisodeDeeplinks(params) {
  return axios.get(`/api/v1/smart_link_episode_deeplinks`, { params });
}

export function createTrackingLinkEpisodeDeeplinks(params) {
  return axios.post(`/api/v1/smart_link_episode_deeplinks`, params);
}

export function deleteTrackingLinkEpisodeDeeplinks(params) {
  return axios.delete(`/api/v1/smart_link_episode_deeplinks/${params.id}`, params);
}

export function updateTrackingLinkEpisodeDeeplinks(params) {
  return axios.patch(`/api/v1/smart_link_episode_deeplinks/${params.id}`, params);
}

// Team API Keys
export const getInactiveTeamApiKeys = (teamId, params) => {
  axios.defaults.headers.common['X-Team-Id'] = teamId;
  return axios.get('/api/v1/team_api_keys/inactive', { params });
};

export const getActiveTeamApiKey = teamId => {
  axios.defaults.headers.common['X-Team-Id'] = teamId;
  return axios.get('/api/v1/team_api_keys');
};

export const createTeamApiKey = teamId => {
  axios.defaults.headers.common['X-Team-Id'] = teamId;
  return axios.post('/api/v1/team_api_keys');
};

// Podcasts
export function getPodcasts(params) {
  return axios.get('/api/v1/podcasts', { params });
}

export function getPodcast(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}`, { params });
}

export function getPodcastCharts(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/charts`, params);
}

export function getPodcastDemographics(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/demographics`, {
    params,
  });
}

export function getPodcastEpisodes(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/episodes`, params);
}

export function getPodcastEpisodePacing(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/episode_pacing`, params);
}

export function getPodcastGeography(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/geography`, { params });
}

export function getPodcastHouseholdDemographics(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/household_demographics`, { params });
}

export function getPodcastRetention(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/retention`, params);
}

export function getPodcastReviews(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/reviews`, params);
}

export function getPodcastReviewsHistory(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/reviews_history`, params);
}

export function getPodcastReviewsSummary(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/reviews_summary`, params);
}

export function refreshEpisodes(params) {
  return axios.get(`/api/v1/podcasts/${params.podcastId}/refresh_episodes`, { params });
}

export function refreshTrackableStatus(teamId, params) {
  return axios.put(`/api/v1/podcasts/${params.podcastId}/refresh_trackable_status`);
}

export function searchPodcasts(params) {
  return axios.get(`/api/v1/podcasts/search`, { params });
}

// Episodes
export function getEpisodes(params) {
  return axios.get(`/api/v1/episodes`, { params });
}

export function getEpisode(params) {
  return axios.get(`/api/v1/episodes/${params.episodeId}`, { params });
}

export function getEpisodeDemographics(params) {
  return axios.get(`/api/v1/episodes/${params.episodeId}/demographics`, { params });
}

export function getEpisodeOptions(params) {
  return axios.get(`/api/v1/episodes/search`, { params });
}

// Teams
export function getTeamCharts(params) {
  return axios.get(`/api/v1/team/charts`, params);
}

export function getTeamDemographics(params) {
  return axios.get(`/api/v1/team/demographics`, { params });
}

export function getTeamDownloadHistory(params) {
  return axios.get(`/api/v1/team/download_history`, { params });
}

export function getTeamEpisodes(params) {
  return axios.get(`/api/v1/team/episodes`, params);
}

export function getTeamGeography(params) {
  return axios.get(`/api/v1/team/geography`, { params });
}

export function getTeamHouseholdDemographics(params) {
  return axios.get(`/api/v1/team/household_demographics`, { params });
}

export function getTeamPlayHistory(params) {
  return axios.get(`/api/v1/team/play_history`, { params });
}

export function getTeamReviews(params) {
  return axios.get(`/api/v1/team/reviews`, params);
}

export function getTeamReviewsHistory(params) {
  return axios.get(`/api/v1/team/reviews_history`, params);
}

export function getTeamReviewsSummary(params) {
  return axios.get(`/api/v1/team/reviews_summary`, params);
}

export function getTeamStatistics(params) {
  return axios.get(`/api/v1/team/statistics`, { params });
}

// Team Users
export function getTeamUsers(params) {
  return axios.get(`/api/v1/team_users`, { params });
}

export function searchTeamUsers(params) {
  return axios.get(`/api/v1/team_users/search`, { params });
}

// JWT
export function refreshJWT() {
  return axios.post(`/api/v1/jwt`);
}

// Megaphone Connection
export const getMegaphoneOrganization = params => {
  return axios.get('/api/v1/megaphone_organization', params);
};

export const getMegaphoneOrganizations = params => {
  return axios.get('/api/v1/megaphone_organizations', params);
};

export const createMegaphoneOrganization = params => {
  return axios.post('/api/v1/megaphone_organizations', params);
};

export const deleteMegaphoneOrganization = params => {
  return axios.delete(`/api/v1/megaphone_organizations`, { params });
};
