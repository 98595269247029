import React, { Component } from 'react';

import DemoCard from './DemoCard';

const DemoViz = ({ podcast, demoType, locked }) => {
  const genderScale = ['Male', 'Female'];
  const fill = new Array(10).fill(null);
  const ageScale = ['< 18', '18-22', '23-27', '28-34', '35-44', '45-59', '> 59'];
  const scale = demoType === 'gender' ? genderScale : ageScale;

  const renderChart = () => {
    return demoType === 'gender' ? <GenderChart /> : <AgeChart />;
  };

  const GenderChart = () => {
    const { percentage_male } = podcast;
    const percentage = percentage_male > 4 ? percentage_male : 5;
    const numberMale = Math.round(percentage / 10);
    let levels = fill.map((e, idx) => {
      return 10 - idx !== numberMale ? 0.1 : 1;
    });
    if (locked) {
      levels = fill.map((e, idx) => {
        return 0.1;
      });
    }
    return (
      <div className="flex justify-between mb1">
        {levels.map(level => {
          return (
            <div
              style={{
                borderRadius: '1px',
                width: '8.5%',
                height: '10px',
                background: `rgba(48, 101, 123, ${level})`,
              }}
            />
          );
        })}
      </div>
    );
  };

  const AgeChart = () => {
    const {
      percentage_0_17,
      percentage_18_22,
      percentage_23_27,
      percentage_28_34,
      percentage_35_44,
      percentage_45_59,
      percentage_60_150,
    } = podcast;
    const percentageArray = [
      percentage_0_17,
      percentage_18_22,
      percentage_23_27,
      percentage_28_34,
      percentage_35_44,
      percentage_45_59,
      percentage_60_150,
    ];
    let levels = percentageArray.map(p => Math.pow((p / 100).toFixed(2), 0.5));
    if (locked) {
      levels = fill.map((e, idx) => {
        return 0.1;
      });
    }
    return (
      <div className="flex justify-between mb1">
        {levels.map(level => {
          return (
            <div
              style={{
                borderRadius: '1px',
                width: '13%',
                height: '10px',
                background: `rgba(48, 101, 123, ${level})`,
              }}
            />
          );
        })}
      </div>
    );
  };

  const getMajority = () => {
    let descriptor;
    let leader;
    let max;
    switch (demoType) {
      case 'age':
        const {
          percentage_0_17,
          percentage_18_22,
          percentage_23_27,
          percentage_28_34,
          percentage_35_44,
          percentage_45_59,
          percentage_60_150,
        } = podcast;
        const percentageArray = [
          percentage_0_17,
          percentage_18_22,
          percentage_23_27,
          percentage_28_34,
          percentage_35_44,
          percentage_45_59,
          percentage_60_150,
        ];
        max = Math.max(...percentageArray);
        const idx = percentageArray.indexOf(max);
        leader = ageScale[idx];
        switch (true) {
          case max > 30:
            descriptor = 'Skews ';
            break;
          default:
            descriptor = 'Leans ';
        }
        break;
      case 'gender':
        const { percentage_male, percentage_female } = podcast;
        if (percentage_male > percentage_female) {
          leader = 'Male';
          max = percentage_male;
        } else {
          leader = 'Female';
          max = percentage_female;
        }
        switch (true) {
          case max > 65:
            descriptor = 'Skews ';
            break;
          default:
            descriptor = 'Leans ';
        }
        break;
    }
    if (locked) {
      descriptor = 'Leans ';
      leader = '???';
    }
    return (
      <span>
        {descriptor} <span className="dark-blue">{leader}</span>
      </span>
    );
  };

  return (
    <DemoCard>
      <div className="tc">{getMajority()}</div>
      <div>{renderChart()}</div>
      <div className="flex justify-between">
        {scale.map(p => {
          return <div style={{ fontSize: '10px', textAlign: 'center' }}>{p}</div>;
        })}
      </div>
    </DemoCard>
  );
};

export default DemoViz;
