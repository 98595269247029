import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const DEFAULT_COLORS = {
  ok: { color: '#03543f', backgroundColor: '#def7ec' },
  warning: { color: '#6d5518', backgroundColor: '#fbeecd' },
  error: { color: '#9b1c1c', backgroundColor: '#fde8e8' },
  inactive: { color: '#fff', backgroundColor: '#252f3fb3' },
};

const REPORT_COLORS = {
  COMPLETE: { color: '#03543f', backgroundColor: '#def7ec' },
  PROCESSING: { color: '#6d5518', backgroundColor: '#fbeecd' },
  FAILED: { color: '#9b1c1c', backgroundColor: '#fde8e8' },
  ARCHIVED: { color: '#fff', backgroundColor: '#252f3fb3' },
};

const SMARTPROMO_COLORS = {
  ACTIVE: { color: '#03543f', backgroundColor: '#def7ec' },
  PROCESSING: { color: '#5145cd', backgroundColor: '#5145cd' },
  PENDING: { color: '#6d5518', backgroundColor: '#fbeecd' },
  WARNING: { color: '#6d5518', backgroundColor: '#fbeecd' },
  DENIED: { color: '#9b1c1c', backgroundColor: '#fde8e8' },
  ERROR: { color: '#9b1c1c', backgroundColor: '#fde8e8' },
  ARCHIVED: { color: '#fff', backgroundColor: '#252f3fb3' },
};

const SMARTADS_COLORS = {
  active: { color: '#03543f', backgroundColor: '#def7ec' },
  pending: { color: '#6d5518', backgroundColor: '#fbeecd' },
  warning: { color: '#6d5518', backgroundColor: '#fbeecd' },
  error: { color: '#9b1c1c', backgroundColor: '#fde8e8' },
};

const SMARTPROMO_EXTERNAL_REQUEST_COLORS = {
  NEEDS_APPROVAL: DEFAULT_COLORS.warning,
  DENIED: DEFAULT_COLORS.error,
  APPROVED: DEFAULT_COLORS.ok,
};

const SMARTLINKS_PLATFORM_URL_COLORS = {
  pending: { color: '#6d5518', backgroundColor: '#fbeecd' },
  active: { color: '#03543f', backgroundColor: '#def7ec' },
  error: { color: '#9b1c1c', backgroundColor: '#fde8e8' },
  auto_generated: { color: '#03543f', backgroundColor: '#def7ec' },
};

function StatusIndicator(props) {
  var colors;

  if (props.forPromo) {
    if (!SMARTPROMO_COLORS[props.status]) throw new Error('Invalid status for StatusIndicator');
    colors = SMARTPROMO_COLORS[props.status];
  } else if (props.forReport) {
    if (!REPORT_COLORS[props.status]) throw new Error('Invalid status for StatusIndicator');
    colors = REPORT_COLORS[props.status];
  } else if (props.forSmartLinksPlatformUrl) {
    if (!SMARTLINKS_PLATFORM_URL_COLORS[props.status])
      throw new Error('Invalid status for StatusIndicator');
    colors = SMARTLINKS_PLATFORM_URL_COLORS[props.status];
  } else if (props.forPromoRequest) {
    colors = SMARTPROMO_EXTERNAL_REQUEST_COLORS[props.status];
  } else {
    if (!DEFAULT_COLORS[props.status]) throw new Error('Invalid status for StatusIndicator');
    colors = DEFAULT_COLORS[props.status];
  }

  return (
    <div>
      <ReactTooltip />
      <span
        className={props.className || 'b br4 f6 ttc pointer'}
        data-tip={props.tooltip}
        style={{
          ...colors,
          padding: props.className ? '' : '0.075rem 0.75rem',
        }}
      >
        {props.text || props.status}
      </span>
    </div>
  );
}

StatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  forPromo: PropTypes.bool,
  forReport: PropTypes.bool,
  forSmartLinksPlatformUrl: PropTypes.bool,
  forPromoRequest: PropTypes.bool,
};

StatusIndicator.defaultProps = {
  tooltip: null,
  forPromo: false,
  forReport: false,
  forPromoRequest: false,
  forSmartLinksPlatformUrl: false,
  status: null,
  text: null,
};

export default StatusIndicator;
