"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.overlayDirections = exports.overlayBoxShadow = exports.overlayArrow = exports.overlay = exports.dialog = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
var _styledComponents = require("styled-components");
var _styles = require("../../styles");
var _typeHelpers = require("../../typeHelpers");
var _semanticTheme = require("../semantic-theme");
var _triangle = require("./triangle");
//
// Overlays: Dialogs, Tooltips, Popovers, etc
// --------------------------------------------------

var overlayBoxShadow = "0 ".concat(_encoreFoundation.spacer4, " ").concat(_encoreFoundation.spacer12, " 0 rgba(0, 0, 0, 0.2)");
exports.overlayBoxShadow = overlayBoxShadow;
var overlayDirections = {
  top: 'top',
  topEnd: 'topEnd',
  topStart: 'topStart',
  start: 'start',
  startTop: 'startTop',
  startBottom: 'startBottom',
  bottom: 'bottom',
  bottomStart: 'bottomStart',
  bottomEnd: 'bottomEnd',
  end: 'end',
  endTop: 'endTop',
  endBottom: 'endBottom'
};
exports.overlayDirections = overlayDirections;
var overlay = function overlay(_ref) {
  var _ref$maxWidth = _ref.maxWidth,
    maxWidth = _ref$maxWidth === void 0 ? '296px' : _ref$maxWidth,
    _ref$colorSet = _ref.colorSet,
    colorSet = _ref$colorSet === void 0 ? 'base' : _ref$colorSet;
  return (0, _styledComponents.css)(["background:", ";color:", ";border-radius:", ";box-shadow:", ";text-align:start;cursor:default;display:inline-block;max-inline-size:", ";position:relative;"], (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.backgroundElevatedBase, _semanticTheme.defaultTheme[colorSet].background.elevated.base), (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.textBase, _semanticTheme.defaultTheme[colorSet].text.base), _encoreFoundation.spacer8, overlayBoxShadow, maxWidth);
};
exports.overlay = overlay;
var dialog = function dialog(_ref2) {
  var colorSet = _ref2.colorSet;
  return (0, _styledComponents.css)(["", ";display:flex;flex-direction:column;max-block-size:90vh;z-index:", ";@media (min-width:", "){max-block-size:80vh;}"], overlay({
    maxWidth: "calc(100% - ".concat(_encoreFoundation.spacer48, ")"),
    colorSet: colorSet
  }), _styles.zIndexDialog, _encoreFoundation.screenSmMin);
};
exports.dialog = dialog;
var overlayArrow = function overlayArrow(direction) {
  switch (direction) {
    case 'top':
      return (0, _triangle.triangle)({
        offset: 'center',
        position: 'top'
      });
    case 'topStart':
      return (0, _triangle.triangle)({
        offset: _encoreFoundation.spacer8,
        position: 'top'
      });
    case 'topEnd':
      return (0, _triangle.triangle)({
        offset: "calc(100% - ".concat(_encoreFoundation.spacer24, ")"),
        position: 'top'
      });
    case 'bottom':
      return (0, _triangle.triangle)({
        offset: 'center',
        position: 'bottom'
      });
    case 'bottomStart':
      return (0, _triangle.triangle)({
        offset: _encoreFoundation.spacer8,
        position: 'bottom'
      });
    case 'bottomEnd':
      return (0, _triangle.triangle)({
        offset: "calc(100% - ".concat(_encoreFoundation.spacer24, ")"),
        position: 'bottom'
      });
    case 'start':
      return (0, _triangle.triangle)({
        offset: 'center',
        position: 'start'
      });
    case 'startTop':
      return (0, _triangle.triangle)({
        offset: _encoreFoundation.spacer8,
        position: 'start'
      });
    case 'startBottom':
      return (0, _triangle.triangle)({
        offset: "calc(100% - ".concat(_encoreFoundation.spacer24, ")"),
        position: 'start'
      });
    case 'end':
      return (0, _triangle.triangle)({
        offset: 'center',
        position: 'end'
      });
    case 'endTop':
      return (0, _triangle.triangle)({
        offset: _encoreFoundation.spacer8,
        position: 'end'
      });
    case 'endBottom':
      return (0, _triangle.triangle)({
        offset: "calc(100% - ".concat(_encoreFoundation.spacer24, ")"),
        position: 'end'
      });
    default:
      return (0, _typeHelpers.assertNever)(direction);
  }
};
exports.overlayArrow = overlayArrow;