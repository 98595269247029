import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Loading from 'shared/Loading';
import { getAudioAdCampaign, getAudioAdCampaigns } from 'shared/api';

function AudioAdCampaignSearchInput({
  teamId,
  initialAudioAdCampaignId,
  onSelectAudioAdCampaign,
  className,
}) {
  const [audioAdCampaign, setAudioAdCampaign] = useState(null);
  const [audioAdCampaigns, setAudioAdCampaigns] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!initialAudioAdCampaignId) return;
    setLoading(true);
    const handleGetAudioAdCampaign = async () => {
      try {
        const res = await getAudioAdCampaign({
          teamId,
          audioAdCampaignId: initialAudioAdCampaignId,
          includeDetails: false,
        });
        setAudioAdCampaign(res.data);
        onSelectAudioAdCampaign(res.data.audioAdCampaignId);
      } catch (_) {} // We don't care about this error

      setLoading(false);
    };

    handleGetAudioAdCampaign();
  }, []);

  useEffect(() => {
    getAudioAdCampaigns({ teamId })
      .then(res => setAudioAdCampaigns(res.data))
      .catch(err => setError('Failed to load campaigns'));
  }, []);

  const audioAdCampaignOptions = useMemo(() => {
    if (!audioAdCampaigns) return [];
    return audioAdCampaigns.map(c => ({
      ...c,
      value: c.audioAdCampaignId,
      label: c.buyerPodcast.title,
    }));
  }, [audioAdCampaigns]);

  if (error) {
    return <div className="f5 red">Failed to load audio ad campaigns. Please try again later.</div>;
  }

  let imageContent;
  if (loading) {
    imageContent = <Loading />;
  } else if (audioAdCampaign) {
    imageContent = (
      <img className="br2 w-100 h-100" src={audioAdCampaign.buyerPodcast.imageUrl} alt="" />
    );
  } else {
    imageContent = <div className="bg-light-gray w-100 h-100 br2"></div>;
  }

  return (
    <div className={`dt flex items-center ${className} mw-100`}>
      <div className="mr2 b--moon-gray ba br2" style={{ height: '38px', width: '38px' }}>
        {imageContent}
      </div>
      <Select
        isDisabled={loading}
        isLoading={loading}
        value={
          audioAdCampaign
            ? {
                value: audioAdCampaign.audioAdCampaignId,
                label: audioAdCampaign.buyerPodcast.title,
              }
            : null
        }
        options={audioAdCampaignOptions}
        teamId={teamId}
        onChange={selection => {
          setAudioAdCampaign(selection);
          onSelectAudioAdCampaign(selection.audioAdCampaignId);
        }}
        className="flex-grow-1"
      />
    </div>
  );
}

export default AudioAdCampaignSearchInput;
