import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  checkTrackingLinkTeamDomainStatus,
  createTrackingLinkTeamDomain,
  createTrackingLinkTeamDomainPodcast,
  deleteTrackingLinkTeamDomainPodcasts,
  destroyTrackingLinkTeamDomain,
  getTrackingLinkTeamDomains,
} from 'shared/api/v1';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import Header from 'shared/Header';
import Loading from 'shared/Loading';
import NewStatusIndicator from 'shared/NewStatusIndicator';
import PodcastSwitcher from 'shared/PodcastSwitcher';

import 'react-table/react-table.css';

const CustomDomainSetupView = ({ linkableReadOnly }) => {
  const { teamId } = useParams();

  const [customDomains, setCustomDomains] = useState(null);
  const [newCustomDomainName, setNewCustomDomainName] = useState('');
  const [tableRequestInProgress, setTableRequestInProgress] = useState(false);
  const [createRequestInProgress, setCreateRequestInProgress] = useState(false);
  const [createBannerText, setCreateBannerText] = useState(null);
  const [tableBannerInfo, setTableBannerInfo] = useState(null);
  const [doFetch, setDoFetch] = useState(true);
  const [domainPodcastModalState, setDomainPodcastModalState] = useState(null);
  const [domainPodcastError, setDomainPodcastError] = useState(null);
  const [removingId, setRemovingId] = useState(null);
  const [showCnameReminder, setShowCnameReminder] = useState(null);

  const fetchDomains = async () => {
    try {
      const res = await getTrackingLinkTeamDomains();
      setCustomDomains(res.data);
    } catch (_) {}
  };

  useEffect(() => {
    if (doFetch) {
      setDoFetch(false);
      fetchDomains();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doFetch]);

  useEffect(() => {
    if (customDomains) {
      const unverifiedDomains = customDomains.filter(domain => domain.status === 'Pending');
      setShowCnameReminder(unverifiedDomains.length > 0);
    }
  }, [customDomains]);

  const handleCreateCustomDomain = async () => {
    if (createRequestInProgress) return;
    setCreateRequestInProgress(true);

    try {
      const params = { name: newCustomDomainName };
      const res = await createTrackingLinkTeamDomain(params);
      customDomains.push(res.data);
      setCustomDomains(customDomains);
      setNewCustomDomainName('');
    } catch (e) {
      let error;

      if (linkableReadOnly) {
        error = e.response.data.errors;
      } else {
        error =
          (e.response && e.response.data && e.response.data.message) ||
          'Failed to create custom domain';
      }

      setCreateBannerText(error);
    } finally {
      setCreateRequestInProgress(false);
    }
  };

  const handleDeleteCustomDomain = async trackingLinkTeamDomainId => {
    if (tableRequestInProgress) return;
    setTableRequestInProgress(true);
    setRemovingId(trackingLinkTeamDomainId);
    try {
      const params = { id: trackingLinkTeamDomainId };
      await destroyTrackingLinkTeamDomain(params);
      const _customDomains = customDomains.filter(cd => cd.id !== trackingLinkTeamDomainId);
      setCustomDomains(_customDomains);
    } catch (e) {
      if (linkableReadOnly) {
        setTableBannerInfo({
          type: 'error',
          message: e.response.data.errors,
        });
      } else {
        setTableBannerInfo({
          type: 'error',
          message: 'Failed to remove Custom Domain',
        });
      }
    } finally {
      setTableRequestInProgress(false);
      setRemovingId(null);
    }
  };

  const handleCheckCustomDomainStatus = async trackingLinkTeamDomainId => {
    if (tableRequestInProgress) return;
    setTableRequestInProgress(true);

    try {
      const params = { id: trackingLinkTeamDomainId };
      const res = await checkTrackingLinkTeamDomainStatus(params);
      const _customDomains = customDomains.map(cd =>
        cd.id === trackingLinkTeamDomainId ? res.data : cd,
      );
      setCustomDomains(_customDomains);
      setTableBannerInfo({
        type: 'success',
        message: 'Refreshed domain status',
      });
    } catch (e) {
      if (linkableReadOnly) {
        setTableBannerInfo({
          type: 'error',
          message: e.response.data.errors,
        });
      } else {
        setTableBannerInfo({
          type: 'error',
          message: 'Failed to refresh domain status',
        });
      }
    } finally {
      setTableRequestInProgress(false);
    }
  };

  const handleSaveDomainPodcast = async podcast => {
    setDomainPodcastError(null);
    const domainId = domainPodcastModalState.id;
    const podcastId = podcast.id;
    const params = { teamId, domainId, podcastId };

    try {
      await createTrackingLinkTeamDomainPodcast(params);
      const newState = { ...domainPodcastModalState };
      newState.podcasts.push(podcast);
      setDomainPodcastModalState(newState);
      setDoFetch(true);
    } catch (e) {
      if (linkableReadOnly) {
        setTableBannerInfo({ type: 'error', message: e.response.data.errors });
      } else {
        setTableBannerInfo({ type: 'error', message: 'Failed to save podcast' });
      }
    }
  };

  const handleDeleteDomainPodcast = async podcast => {
    setDomainPodcastError(null);
    const domainId = domainPodcastModalState.id;
    const podcastId = podcast.id;
    const params = { teamId, domainId, podcastId };
    try {
      await deleteTrackingLinkTeamDomainPodcasts(params);
      const newState = { ...domainPodcastModalState };
      newState.podcasts = [...domainPodcastModalState.podcasts].filter(
        pod => pod.id !== podcast.id,
      );
      setDomainPodcastModalState(newState);
      setDoFetch(true);
    } catch (_) {
      setDomainPodcastError({
        type: 'error',
        message: 'Failed to delete podcast',
      });
    }
  };

  const domainPodcastModalContents = () => {
    return (
      <div>
        <div style={{ borderBottom: '1px solid #aaa' }}>
          <div style={{ color: '#073c52' }} className="f3 tc pt3">
            {domainPodcastModalState.name}
          </div>
          <div className="bg-near-white mv2">
            <div className="pa3 f5">
              Adding a podcast below will set{' '}
              <span style={{ color: '#073c52' }} className="b code f5">
                {domainPodcastModalState.name}
              </span>
              as the default domain for new links created. This will not affect previously created
              links.
            </div>
          </div>
          <div className="ph3 pt3">
            <div className="pb2">Add a Podcast:</div>
            <PodcastSwitcher
              teamId={teamId}
              onSelect={({ data }) => handleSaveDomainPodcast(data)}
              loading={false}
              selectedPodcast={null}
              defaultToNone={false}
              defaultToAll={false}
              includeNone={false}
              includeAll={false}
              includeSelect
              defaultToSelect
              numberOfDefaultOptionsToFetch={100}
              className="w6 mb3"
            />
            {domainPodcastError && (
              <div style={{ color: 'red' }} className="f6 tc pb2">
                {domainPodcastError}
              </div>
            )}
          </div>
        </div>
        <div>
          {domainPodcastModalState.podcasts.map(podcast => {
            return (
              <div
                key={podcast.title}
                style={{ borderBottom: '1px solid #CCC' }}
                className="flex items-center pa2"
              >
                <img
                  src={podcast.imageUrl}
                  style={{ maxWidth: '30px' }}
                  className="center fl br2"
                  alt=""
                />
                <div className="pl3" style={{ flexGrow: 4 }}>
                  {podcast.title}
                </div>
                <span
                  onClick={() => {
                    handleDeleteDomainPodcast(podcast);
                  }}
                  className="pointer fr f3"
                >
                  &times;
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const columns = [
    {
      Header: 'Domain',
      accessor: 'name',
    },
    {
      Header: 'Status',
      accessor: 'status',
      minWidth: 300,
      Cell: row => (
        <>
          <NewStatusIndicator status={row.original.status} />
          {row.original.status === 'Pending' && (
            <div className="bg-near-white br2 pa2">
              <div className="mb1 f6">To activate set the CNAME of your domain to:</div>
              <div className="dib f7 code pa1 bg-light-gray mb2 br1">{row.original.cname}</div>
              <div className="mb1 f6">
                Then{' '}
                <span
                  onClick={() => handleCheckCustomDomainStatus(row.original.id)}
                  className="f6 link blue pointer"
                >
                  click here to refresh the domain status.
                </span>
              </div>
              <div className="mb1 f6">
                This domain will be deleted from Chartable on{' '}
                {moment(row.original.deleteOn).format('MM/DD/YYYY')} if the correct CNAME is not
                set.
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      Header: 'Podcasts',
      accessor: 'podcasts',
      Cell: row => {
        if (row.original.status !== 'Active') {
          return <div className="br4">None Set</div>;
        } else if (row.original.podcasts.length == 0) {
          return (
            <div
              onClick={() => setDomainPodcastModalState(row.original)}
              className="br4 pointer link blue"
            >
              None Set
            </div>
          );
        }

        return (
          <div>
            {row.original.podcasts.map((pod, idx) => (
              <div key={pod.title}>
                <span
                  onClick={() => setDomainPodcastModalState(row.original)}
                  className="br4 pointer link blue"
                >
                  {pod.title}
                  {row.original.podcasts.length > 1 &&
                    idx + 1 < row.original.podcasts.length &&
                    ', '}
                </span>
              </div>
            ))}
          </div>
        );
      },
    },
    {
      Header: 'Actions',
      className: 'tc',
      Cell: row => {
        if (removingId == row.original.id) {
          return (
            <div style={{ maxWidth: '30px' }}>
              <Loading />
            </div>
          );
        }
        return (
          <Button
            onClick={() => handleDeleteCustomDomain(row.original.id)}
            size="small"
            type="destructive"
            className="center w4 f6"
          >
            Remove
          </Button>
        );
      },
    },
  ];

  return (
    <div className="lh-copy mb4 pb5">
      {domainPodcastModalState !== null && (
        <Modal
          shouldCloseOnOverlayClick
          isOpen
          onRequestClose={() => {
            setDomainPodcastModalState(null);
            setDomainPodcastError(null);
          }}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 1 },
            content: {
              outline: 'none',
              maxWidth: '410px',
              height: '600px',
              padding: '0',
              margin: 'auto',
            },
          }}
        >
          {domainPodcastModalContents()}
        </Modal>
      )}

      {showCnameReminder && (
        <Banner>
          One or more of your domains does not have the correct CNAME record, and will be deleted
          from your Chartable account if you do not set the correct CNAME.
        </Banner>
      )}

      <p>
        Add a custom domain so your links can live at &quot;listen.mydomain.com&quot; rather than
        &quot;link.chtbl.com.&quot; You&apos;ll need access to the domain name records for your
        custom domain to add a CNAME or ALIAS record.
      </p>
      <p>
        &quot;Bare&quot; or root domains require special domain records. If your domain registrar
        doesn&apos;t support ALIAS records, you must use a subdomain (e.g. listen.mypodcast.com
        rather than mypodcast.com).
      </p>
      <p>
        To finish set up, you will need to have access to your company&apos;s DNS. DNS. You can also
        check out our{' '}
        <a
          className="link blue pointer"
          href="https://help.chartable.com/article/145-how-to-set-up-a-custom-domain-for-smartlinks"
          target="_blank"
          rel="noreferrer"
        >
          documentation
        </a>
        for more information.
      </p>
      <div className="mt4 w-100">
        {customDomains ? (
          customDomains.length > 0 ? (
            <>
              <Header useNew size="small" className="dark-blue mb2">
                Your Custom Domains
              </Header>
              {tableBannerInfo && (
                <Banner
                  type={tableBannerInfo.type}
                  onClick={() => setTableBannerInfo(null)}
                  className="f6 mb2"
                >
                  {tableBannerInfo.message}
                </Banner>
              )}
              <ReactTable
                columns={columns}
                data={customDomains}
                className="w-100"
                pageSize={customDomains.length}
                showPagination={false}
              />
            </>
          ) : null
        ) : (
          <div className="flex w-100 justify-center items-center">
            <Loading />
          </div>
        )}
      </div>
      <Header useNew size="small" className="mt4 dark-blue mb2">
        Add a custom domain
      </Header>
      <div className="mb1 dark-gray">Domain Name</div>
      <div className="mw6">
        {createBannerText && (
          <Banner onClick={() => setCreateBannerText(null)} className="f6 mb2" error>
            {createBannerText}
          </Banner>
        )}
        <input
          value={newCustomDomainName}
          className="pa2 input-reset ba bg-white br2 b--silver w-100"
          type="text"
          placeholder="www.mydomain.com"
          onChange={e => setNewCustomDomainName(e.target.value)}
        />
        <Button
          onClick={() => handleCreateCustomDomain()}
          type="primary"
          className="mt2 w-100"
          size="small"
          disabled={createRequestInProgress}
          loading={createRequestInProgress}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default CustomDomainSetupView;
