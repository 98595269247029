import React from 'react';
import PropTypes from 'prop-types';

import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';

function ConfirmationModal({
  title,
  message,
  confirmTitle,
  cancelTitle,
  onCancel,
  onConfirm,
  onClose,
  isOpen,
  showCancel,
}) {
  if (!onCancel) {
    onCancel = onClose;
  }

  return (
    <BasicModal isOpen={isOpen} onRequestClose={onClose} ariaHideApp={false}>
      <div>
        <div className="header-font f4 near-black">{title}</div>
        {message && <div className="f4 mid-gray mv3 lh-copy">{message}</div>}
        <div className="flex flex-row mt3">
          <Button type="primary" className="mr3 w4" onClick={onConfirm}>
            {confirmTitle}
          </Button>
          {showCancel && (
            <Button className="w4" onClick={onCancel ? onCancel : onClose}>
              {cancelTitle}
            </Button>
          )}
        </div>
      </div>
    </BasicModal>
  );
}

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.any,
  confirmTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  showCancel: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  onCancel: null,
  title: 'Are you sure?',
  message: null,
  confirmTitle: 'Confirm',
  cancelTitle: 'Cancel',
  showCancel: true,
};

export default ConfirmationModal;
