import React from 'react';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import { popupCenter } from 'shared/helpers';

const OAuthErrors = {
  1001: {
    title: 'Megaphone User already connected to Chartable account',
    message:
      'The Megaphone user you logged in with is already connected to another Chartable account. Please login with the connected account in order to complete the authorization flow.',
  },
};

const OAuth = props => {
  const queryParams = new URLSearchParams(window.location.search);
  const errorCode = queryParams.get('error_code');

  return (
    <>
      {errorCode && Object.keys(OAuthErrors).includes(errorCode) && (
        <Banner error className="mb3 f6">
          <b className="mb2">{OAuthErrors[errorCode].title}</b>
          <div>{OAuthErrors[errorCode].message}</div>
        </Banner>
      )}
      <div className="card pa3 mt2">
        <div className="dark-gray mb3 lh-copy f5">
          You need to connect your Megaphone account to Chartable. Click the button below to get
          started.
        </div>
        <Button
          large
          className="mw6 bg-megaphone-color white"
          onClick={() => {
            popupCenter(
              `/oauth/authorize_megaphone?redirect_path=/oauth/megaphone_integration_callback&team_id=${props.userInfo.teamId}`,
              'megaphoneOauthWindow',
              735,
              675,
            );
          }}
        >
          Connect to Megaphone
        </Button>
      </div>
    </>
  );
};

OAuth.propTypes = {
  userInfo: PropTypes.object.isRequired,
};

export default OAuth;
