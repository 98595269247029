/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import WarningSVG from 'images/icons/warning-yellow.svg';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import { allPlayerKeys, playerInfo } from 'shared/helpers';
import Loading from 'shared/Loading';

const reorder = (list, startIndex, endIndex) => {
  const result = list;
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// There's a limitation with react-beauitful-dnd where transforms on parent components
// cause the dragged components to be offset from the cursor.
// This magical hook accounts for that in a react portal.
// https://github.com/atlassian/react-beautiful-dnd/issues/128
const useDraggableInPortal = () => {
  const self = useRef({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    self.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [self]);

  return render =>
    (provided, ...args) => {
      const element = render(provided, ...args);
      if (provided.draggableProps.style.position === 'fixed') {
        return createPortal(element, self.elt);
      }
      return element;
    };
};

function PlayerItem({
  platformKey,
  index,
  onHidePlatform,
  renderDraggable,
  needsLink,
  goToDeeplinkManager,
}) {
  if (platformKey === 'stitcher') {
    return <></>;
  }

  return (
    <Draggable draggableId={platformKey} index={index}>
      {renderDraggable(provided => (
        <div
          className={`pv2 mt2 ba br3 ph2 bg-near-white ${
            needsLink ? 'b--yellow' : 'b--light-gray'
          }`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {needsLink && (
            <div
              className="f6 mb2 pa2 br2 flex flex-row items-center"
              style={{ color: '#6d5518', backgroundColor: '#fbeecd' }}
            >
              <img src={WarningSVG} style={{ height: '18px' }} className="mr1" />
              <div>
                You must assign a{' '}
                <span
                  onClick={() => goToDeeplinkManager(platformKey)}
                  className="link blue pointer"
                >
                  Deeplinks
                </span>{' '}
                for this player to appear on your link page.
              </div>
            </div>
          )}
          <div className="flex items-center flex-row justify-between">
            <div className="flex flex-row items-center">
              <img
                src={playerInfo[platformKey].icon}
                style={{ height: '28px' }}
                className="w-auto mr2"
              />
              {playerInfo[platformKey].label}
            </div>
            <Button
              type="primary"
              inverted
              className="w4"
              size="small"
              onClick={() => onHidePlatform(platformKey)}
            >
              Hide
            </Button>
          </div>
        </div>
      ))}
    </Draggable>
  );
}

function PlayerList({
  goToDeeplinkManager,
  platformKeys,
  onHidePlatform,
  renderDraggable,
  validPlayers,
}) {
  return platformKeys.map((platformKey, index) => (
    <PlayerItem
      goToDeeplinkManager={goToDeeplinkManager}
      needsLink={!validPlayers.includes(platformKey)}
      renderDraggable={renderDraggable}
      onHidePlatform={onHidePlatform}
      platformKey={platformKey}
      index={index}
      key={platformKey}
    />
  ));
}

function PlayerOrderSelect({
  storePlatformOrder,
  initialPlayerOrder,
  validPlayers, // Player keys that have a deeplink set up
  goToDeeplinkManager,
}) {
  const renderDraggable = useDraggableInPortal();

  const [state, setState] = useState({
    platforms: initialPlayerOrder,
    hiddenPlatforms: allPlayerKeys.filter(k => !initialPlayerOrder.includes(k)),
  });

  function onHidePlatform(platformKey) {
    const platforms = state.platforms.filter(p => p !== platformKey);
    const hiddenPlatforms = state.hiddenPlatforms;
    hiddenPlatforms.push(platformKey);
    setState({ platforms, hiddenPlatforms });
  }

  function onUnhidePlatform(platformKey) {
    const hiddenPlatforms = state.hiddenPlatforms.filter(p => p !== platformKey);
    const platforms = state.platforms;
    platforms.push(platformKey);
    setState({ platforms, hiddenPlatforms });
  }

  function onDragEnd(result) {
    if (!result.destination) return;
    if (result.destination.index === result.source.index) return;

    const platforms = reorder(state.platforms, result.source.index, result.destination.index);

    setState({ platforms, hiddenPlatforms: state.hiddenPlatforms });
  }

  useEffect(() => {
    storePlatformOrder(state.platforms);
  }, [state]);

  if (!state.platforms) {
    return <Loading />;
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <PlayerList
                validPlayers={validPlayers}
                goToDeeplinkManager={goToDeeplinkManager}
                renderDraggable={renderDraggable}
                onHidePlatform={onHidePlatform}
                platformKeys={state.platforms}
              />
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="mt3 mid-gray f5 mb1">Hidden Players</div>
      <div className="mid-gray f6 mb3">These will not show up on the player selection page</div>
      {state.hiddenPlatforms.map((platformKey, index) => (
        <div
          key={index}
          className="pv2 bg-light-gray mt2 b--light-gray ba br3 ph2 flex items-center flex-row justify-between"
        >
          <div className="flex flex-row items-center">
            <img
              src={playerInfo[platformKey].icon}
              style={{ height: '25px' }}
              className="w-auto mr2"
            />
            {playerInfo[platformKey].label}
          </div>
          <Button className="w4" size="small" onClick={() => onUnhidePlatform(platformKey)}>
            Show
          </Button>
        </div>
      ))}
    </div>
  );
}

PlayerOrderSelect.propTypes = {
  selectedPodcastId: PropTypes.string.isRequired,
  storePlatformOrder: PropTypes.func.isRequired,
  initialPlayerOrder: PropTypes.array.isRequired,
  goToDeeplinkManager: PropTypes.func.isRequired, // Sry for how far down I'm pushing this -JS
  validPlayers: PropTypes.array,
};

PlayerOrderSelect.defaultProps = {
  validPlayers: [],
};

export default PlayerOrderSelect;
