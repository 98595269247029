import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { onClickHandler } from 'shared/a11y/lib';
import { putPromo } from 'shared/api';
import Button from 'shared/Button';
import { withUser } from 'shared/UserContext';

const AD_TYPES = [
  { value: 'drop_in', label: 'Episode Drop' },
  { value: 'preroll', label: 'Preroll' },
  { value: 'midroll', label: 'Midroll' },
  { value: 'postroll', label: 'Postroll' },
  { value: 'guest_spot', label: 'Guest Spot' },
  { value: 'trailer', label: 'Trailer' },
];

function PromoEditor({ audioAdPlacement, onCancel, onSuccess, teamId, includeDetailsAfterUpdate }) {
  const [name, setName] = useState(audioAdPlacement.name || false);
  const [adType, setAdType] = useState(audioAdPlacement.adType || null);
  const [totalCost, setTotalCost] = useState(audioAdPlacement.totalCost || null);
  const [targetImpressions, setTargetImpressions] = useState(
    audioAdPlacement.targetImpressions || null,
  );
  const [isInternal] = useState(audioAdPlacement.isInternal || '');

  const [requestInProgress, setRequestInProgress] = useState(false);

  function handleUpdatePromo() {
    if (requestInProgress) return;
    setRequestInProgress(true);

    putPromo({
      audioAdPlacementId: audioAdPlacement.audioAdPlacementId,
      includeDetails: includeDetailsAfterUpdate,
      teamId,
      adType,
      targetImpressions,
      totalCost,
      name,
      isInternal,
    })
      .then(res => onSuccess(res.data))
      .catch(() => setRequestInProgress(false));
  }

  return (
    <div>
      <h2 className="mt0">Edit Promo</h2>
      <div className="mv4">
        <div className="f5 mb2 header-font">Name</div>
        <input
          data-testid="promo-name-input"
          type="text"
          className="pa2 input-reset ba w-100 bg-white br2 b--silver string optional"
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div className="mv4">
        <div className="f5 mb2 header-font">Ad Type</div>
        <div className="flex flex-wrap mt2">
          {AD_TYPES.map(item => (
            <div
              data-testid="ad-type"
              key={item.value}
              className={`ttc dim pointer w-30 bw1 ba b--blue ph2 pv2 f5 tc br4 mr2 mb2 dib ${
                adType === item.value ? 'white bg-blue' : 'blue bg-none'
              }`}
              {...onClickHandler(() => setAdType(item.value))}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
      <div className="mv4">
        <div className="f5 mb2 header-font">Total Cost</div>
        <input
          type="number"
          min="0.00"
          className="pa2 input-reset ba w-100 bg-white br2 b--silver string optional"
          value={totalCost || ''}
          onChange={e => setTotalCost(e.target.value)}
        />
      </div>
      <div className="mv4">
        <div className="f5 mb2 header-font">Target Impressions</div>
        <input
          type="number"
          className="pa2 input-reset ba w-100 bg-white br2 b--silver string optional"
          value={targetImpressions || ''}
          onChange={e => setTargetImpressions(e.target.value)}
        />
      </div>
      <div className="w-auto flex flex-row justify-between">
        <Button
          className="w-auto flex-grow-1 mr3"
          size="large"
          type="primary"
          disabled={requestInProgress}
          onClick={handleUpdatePromo}
        >
          Save
        </Button>
        <Button className="w-auto flex-grow-1" size="large" onClick={onCancel}>
          Close
        </Button>
      </div>
    </div>
  );
}

PromoEditor.propTypes = {
  teamId: PropTypes.string.isRequired,
  audioAdPlacement: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  includeDetailsAfterUpdate: PropTypes.bool,
};

PromoEditor.defaultProps = {
  requestInProgress: false,
  includeDetailsAfterUpdate: false,
};

export default withUser(PromoEditor);
