import React from 'react';
import Sidebar from 'shared/Sidebar';

import ApiManagerView from './components/ApiManagerView';
import LandingPage from './components/LandingPage';

const ApiManager = props => {
  const { sidebar, teamId, hasApiAccess, isApiCandidate } = props;

  const delayEvent = async (delay, callback, argument) => {
    setTimeout(() => {
      callback(argument);
    }, delay);
  };

  const renderComponent = () => {
    if (props.hasApiAccess) {
      return <ApiManagerView {...props} delayEvent={delayEvent} />;
    } else if (props.isApiCandidate) {
      return <LandingPage {...props} />;
    }
  };

  return <Sidebar sidebar={sidebar}>{renderComponent()}</Sidebar>;
};

export default ApiManager;
