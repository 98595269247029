import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import Loading from './Loading';

import { searchTeamPodcasts, getTeamPodcasts } from 'shared/api';

const SelectionValue = (type, selection) => {
  switch (type) {
    case 'all':
      return { type: 'all', title: 'All', value: 'all', data: null };
    case 'select':
      return {
        type: 'all',
        title: 'Select a Podcast',
        value: 'all',
        data: null,
      };
    case 'none':
      return { type: 'none', title: 'None', value: 'none', data: null };
    default:
      return {
        type: 'some',
        title: selection.title,
        value: selection.id || selection.slug,
        data: selection,
      };
  }
};

class PodcastSwitcher extends React.Component {
  constructor(props) {
    super(props);

    this.searchTeamPodcasts = this.searchTeamPodcasts.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    if (!props.includeNone && !props.includeAll && !props.selectedPodcast && !props.includeSelect) {
      throw new Error(
        'You must include one default option: All, Select a Podcast, None, SelectedPodcast',
      );
    }

    var defaultSelection;

    if (props.selectedPodcast) {
      defaultSelection = SelectionValue('some', props.selectedPodcast);
    } else if (props.defaultToNone) {
      defaultSelection = SelectionValue('none');
    } else if (props.defaultToSelect) {
      defaultSelection = SelectionValue('select');
    } else {
      defaultSelection = SelectionValue('all');
    }

    var defaultOptions = [];

    if (props.defaultToNone || props.includeNone) {
      defaultOptions.push(SelectionValue('none'));
    }

    if (props.defaultToAll || props.includeAll) {
      defaultOptions.push(SelectionValue('all'));
    }

    if (props.defaultToSelect || props.includeSelect) {
      defaultOptions.push(SelectionValue('select'));
    }

    if (props.defaultOptions) {
      defaultOptions = [
        ...defaultOptions,
        ...this.formatResultsAsSelectionValues(props.defaultOptions),
      ];
    }

    this.state = {
      results: [],
      defaultOptions: defaultOptions,
      selection: defaultSelection,
      hasAllPodcasts: props.defaultOptions && props.defaultOptions.length,
    };
  }

  componentDidMount() {
    if (this.props.fetchDefaultOptions) {
      const params = {
        limit: this.props.numberOfDefaultOptionsToFetch,
        teamId: this.props.teamId,
      };
      getTeamPodcasts(params).then(res => {
        this.setState({
          hasAllPodcasts: res.data.total <= this.props.numberOfDefaultOptionsToFetch,
          defaultOptions: [
            ...this.state.defaultOptions,
            ...this.formatResultsAsSelectionValues(res.data.results),
          ],
        });
      });
    }
  }

  componentDidUpdate(prevProps) {
    let updatedState = {};
    const podcastExists = !!this.props.selectedPodcast;
    const podcastHasChanged =
      podcastExists &&
      this.props.selectedPodcast.id !== (this.state.selection.data && this.state.selection.data.id);
    const shouldUpdateSelected = podcastExists && podcastHasChanged;
    if (this.props.defaultOptions.length && !prevProps.defaultOptions.length) {
      let defaultOptions = [
        ...this.state.defaultOptions,
        ...this.formatResultsAsSelectionValues(this.props.defaultOptions),
      ];
      updatedState.defaultOptions = defaultOptions;
      updatedState.hasAllPodcasts = true;
    }
    if (shouldUpdateSelected) {
      updatedState.selection = SelectionValue('some', this.props.selectedPodcast);
    }
    if (Object.keys(updatedState).length) {
      this.setState(updatedState);
    }
  }

  formatResultsAsSelectionValues(results) {
    return results.map(r => SelectionValue('some', r));
  }

  searchTeamPodcasts(inputValue, callback) {
    if (this.state.hasAllPodcasts) {
      callback(
        this.state.defaultOptions.filter(pod =>
          pod.title.toLowerCase().includes(inputValue.toLowerCase()),
        ),
      );
      return;
    }

    const { teamId, excludeStatistics } = this.props;
    // TODO: debounce this to reduce number of calls
    searchTeamPodcasts({ q: inputValue, teamId, excludeStatistics }).then(res =>
      callback([...this.formatResultsAsSelectionValues(res.data.results)]),
    );
  }

  handleInputChange(inputValue) {
    this.setState({ inputValue });
    return inputValue;
  }

  handleSelect(selection) {
    this.setState({ selection: selection });
    this.props.onSelect({
      type: selection.type,
      data: selection.data,
      title: selection.title,
    });
  }

  filterDefaultOptions(options) {
    if (this.props.excludeOptions.length === 0) return options;
    return options.filter(
      option => !this.props.excludeOptions.includes(option.data && option.data.slug),
    );
  }

  render() {
    const { className, loading, isDisabled } = this.props;

    const { selection, defaultOptions } = this.state;

    const selectedPodcast = selection && selection.type === 'some' ? selection.data : null;

    return (
      <div className={`dt flex items-center ${className} mw-100`}>
        <div className="mr2 b--moon-gray ba br2" style={{ height: '38px', width: '38px' }}>
          {loading ? (
            <Loading className="tc" />
          ) : selectedPodcast ? (
            <img
              className="br2 w-100 h-100"
              src={selectedPodcast.imageUrl || selectedPodcast.image_url}
              alt=""
            />
          ) : (
            <div className="bg-light-gray w-100 h-100 br2" />
          )}
        </div>
        <AsyncSelect
          getOptionLabel={option => option.title}
          className="flex-grow-1"
          cacheOptions
          getOptionValue={({ value }) => value}
          loadOptions={this.searchTeamPodcasts}
          onChange={this.handleSelect}
          onInputChange={this.handleInputChange}
          defaultOptions={this.filterDefaultOptions(defaultOptions)}
          defaultValue={selection}
          isDisabled={isDisabled}
          value={{
            title: loading ? 'Loading...' : selection.title,
            value: loading ? null : selection.value,
          }}
        />
      </div>
    );
  }
}

PodcastSwitcher.propTypes = {
  teamId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedPodcast: PropTypes.object,
  fetchDefaultOptions: PropTypes.bool,
  defaultOptions: PropTypes.array,
  defaultToNone: PropTypes.bool,
  defaultToAll: PropTypes.bool,
  defaultToSelect: PropTypes.bool,
  includeNone: PropTypes.bool,
  includeAll: PropTypes.bool,
  numberOfDefaultOptionsToFetch: PropTypes.number,
  loading: PropTypes.bool,
  excludeOptions: PropTypes.array,
  excludeStatistics: PropTypes.bool,
  includeSelect: PropTypes.bool,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

PodcastSwitcher.defaultProps = {
  selectedPodcast: null,
  fetchDefaultOptions: true,
  defaultOptions: [],
  defaultToNone: false,
  defaultToAll: true,
  defatulToSelect: false,
  includeNone: false,
  includeAll: true,
  includeSelect: false,
  numberOfDefaultOptionsToFetch: 5,
  loading: true,
  excludeOptions: [],
  excludeStatistics: false,
};

export default PodcastSwitcher;
