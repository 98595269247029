// Default keys that will be added if not overwritten by `keys`
// NOTE:
// This is a bit hacky, I think handling multi key inputs generically would be
// an interesting project to take on someday, but not yet! -JS
export const MultiValueInputDefaultKeys = {
  dateRange: ['startDate', 'endDate'],
};

// A report is an array of "inputs"
// Input is an interface structured the following way:
// {
//   header: string
//   type: InputType
//   defaultValue?: any (usually a string, although sometimes this needs to be specific to the type)
//   required?: bool
//   errorMessage?: string = "You must select an input"
//   key?: string (defaults to type)
//   keys?: string[]
// }

// All valid input types
export const InputTypes = {
  audioAdCampaignId: 'audioAdCampaignId',
  dateRange: 'dateRange',
  aggregationWindow: 'aggregationWindow',
  podcastId: 'podcastId',
  episodeId: 'episodeId',
  trackingLinkId: 'trackingLinkId',
  adCampaignId: 'adCampaignId',
};
