import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// This is the new TabBarItem based on Friendly's designs. Not to be confused with
// the old tabbar item which is found in `TabBar.jsx`

function TabBarItem({
  title,
  selected,
  route,
  disableWhenSelected,
  notification,
  width,
  className,
}) {
  const selectedClassName = 'no-underline f5 b pointer dark-gray pb3 bb bw2 tc b--blue';
  const unselectedClassName =
    'no-underline f5 hover-blue pointer mid-gray pb2 bb-none bw2 tc b--moon-gray';

  const content = (
    <React.Fragment>
      {title}
      {notification && !selected && (
        <div
          style={{
            fontSize: '0.5rem',
            top: '-4px',
            padding: '5px',
            right: '-2px',
          }}
          className="absolute bg-red white flex items-center br-100 b"
        />
      )}
    </React.Fragment>
  );

  if (selected && disableWhenSelected) {
    return (
      <div
        style={{ width: width }}
        className={`${notification ? 'relative' : ''} ${selectedClassName} ${className}`}
      >
        {content}
      </div>
    );
  }

  return (
    <Link
      to={route}
      style={{ width: width }}
      className={`${notification ? 'relative' : ''} ${
        selected ? selectedClassName : unselectedClassName
      } ${className}`}
    >
      {content}
    </Link>
  );
}

TabBarItem.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disableWhenSelected: PropTypes.bool,
  notification: PropTypes.bool,
  width: PropTypes.string,
  className: PropTypes.string,
};

TabBarItem.defaultProps = {
  disableWhenSelected: true,
  notification: false,
  width: '7rem',
  className: '',
};

export default TabBarItem;
