import React from 'react';
import checked from 'images/icons/checked.png';
import lockedIcon from 'images/icons/locked.png';
import unchecked from 'images/icons/unchecked.png';
import Header from 'shared/Header';
import { redirectToPricingPage } from 'shared/helpers';

import styles from './LockedRetention.module.css';

const LockedRetention = ({ teamId, trackableEnabled, subscribed }) => {
  const aboutUrl = 'https://help.chartable.com/article/128-what-is-the-retention-chart';
  const handleUpgrade = () => {
    redirectToPricingPage('Clicked Upgrade: Household Demographics', teamId);
  };

  return (
    <div className="mb3">
      <Header useNew className="mb2 mb0-l dark-blue tour-step-one">
        New Device Retention{' '}
        <a className={styles.about} target="_blank" href={aboutUrl}>
          What is this?
        </a>
      </Header>
      <div className={styles.lockedBox}>
        <div style={{ textAlign: 'center' }}>
          <img alt="padlock" src={lockedIcon} className={styles.padlock} />
        </div>
        <div>
          <div className={styles.req}>
            <img
              alt="lock"
              className={styles.lockIcon}
              src={trackableEnabled ? checked : unchecked}
            />
            <a
              className={`${styles.lockBullet} ${styles.upgradeLink}`}
              href={`/teams/${teamId}/dashboard/integrations/trackable`}
            >
              Enable Trackable
            </a>
          </div>
          <div>
            <img alt="locked" className={styles.lockIcon} src={subscribed ? checked : unchecked} />
            <span className={`${styles.lockBullet} ${styles.upgradeLink}`} onClick={handleUpgrade}>
              Upgrade to the Pro or Enterprise Plan
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockedRetention;
