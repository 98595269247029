import React from 'react';
import PropTypes from 'prop-types';

export default function Header(props) {
  const { children, className, useNew } = props;

  const NewHeader = ({ children, className, size, large, small, inlineStyle }) => {
    const defaultClassName = 'dark-gray mt0 mb0';

    let _size = size;

    if (large) {
      _size = 'large';
    } else if (small) {
      _size = 'small';
    }

    switch (_size) {
      case 'large':
        return (
          <div style={inlineStyle} className={`f2 ${className} ${defaultClassName}`}>
            {children}
          </div>
        );
      case 'small':
        return (
          <div style={inlineStyle} className={`f4 ${className} ${defaultClassName}`}>
            {children}
          </div>
        );
      default:
        return (
          <div style={inlineStyle} className={`f3 ${className} ${defaultClassName}`}>
            {children}
          </div>
        );
    }
  };

  if (useNew) {
    return <NewHeader {...props}>{children}</NewHeader>;
  }

  return <div className={'w-100 f3 mb3 dark-blue ' + className}>{children}</div>;
}

Header.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  useNew: PropTypes.bool,
  inlineStyle: PropTypes.object,
  size: PropTypes.string,
  large: PropTypes.any,
  small: PropTypes.any, // Temporary until we make a switchover (if we ever do) -JS
};

Header.defaultProps = {
  className: null,
  useNew: false,
};
