import React from 'react';

const ProfilePlaceholder = () => {
  return (
    <svg
      style={{ width: '15px', fill: 'white' }}
      viewBox="0 0 636 1024"
      aria-labelledby="gtsi-ant-user-title"
      id="si-ant-user"
    >
      <title id="gtsi-ant-user-title">icon user</title>
      <path d="M611 647q-24-57-68-101t-101-68l-2-1q30-17 55-42 75-74 75-180 0-105-74.5-180T315 0 134.5 74.5 60 255t75 180q26 26 58 44-57 24-100 67-44 44-68 101Q0 706 0 771v221q0 13 9.5 22.5T32 1024t22.5-9.5T64 992V771q0-105 74.5-179.5T318 517t179.5 74.5T572 771v221q0 13 9.5 22.5t22.5 9.5 22.5-9.5T636 992V771q0-64-25-124zM124 255q0-79 56-135t135-56 135 56 56 135-56 135-135 56-135-56-56-135z" />
    </svg>
  );
};

export default ProfilePlaceholder;
