import React, { useEffect, useState } from 'react';
import { createTeamApiKey, getActiveTeamApiKey, getInactiveTeamApiKeys } from 'shared/api/v1';
import BannerNotice from 'shared/BannerNotice';
import Loading from 'shared/Loading';

import 'react-table/react-table.css';
import KeyGen from './KeyGen';
import KeyList from './KeyList';

const ApiManagerView = ({ teamId, delayEvent }) => {
  const [banner, setBanner] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(null);
  const [inactiveKeys, setInactiveKeys] = useState(null);
  const [activeKey, setActiveKey] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [page, setPage] = useState(1);
  const [keyCount, setKeyCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [activeKeyLoading, setActiveKeyLoading] = useState(true);
  const [inactiveKeysLoading, setInactiveKeysLoading] = useState(true);
  const [keyCreated, setKeyCreated] = useState(false);

  const handleCreateKey = async () => {
    setActiveKeyLoading(true);
    setInactiveKeysLoading(true);
    try {
      const res = await createTeamApiKey(teamId);
      setActiveKey(res.data.api_key);
      setBanner({
        message: 'API key created. Click "Show" to reveal the key.',
        type: 'success',
      });
      setKeyCreated(true);
    } catch (error) {
      setBanner({
        message: 'Error creating an API key. Please reload the page or contact support.',
        type: 'error',
      });
    }
    delayEvent(3000, setBanner, null);
    delayEvent(3000, setKeyCreated, false);
    setActiveKeyLoading(false);
  };

  const fetchInactiveKeys = async () => {
    let results;
    let total;
    let pages;
    try {
      const res = await getInactiveTeamApiKeys(teamId, { page, pageSize });
      results = res.data.keys;
      total = res.data.total;
      pages = Math.ceil(total / pageSize);
      setInactiveKeys(results);
      setPageCount(pages);
      setKeyCount(total);
    } catch (error) {
      setBanner({
        message: 'Error fetching API keys. Please reload the page or contact support.',
        type: 'error',
      });
      delayEvent(3000, setBanner, null);
    }
    setInactiveKeysLoading(false);
  };

  const fetchActiveKey = async () => {
    try {
      const res = await getActiveTeamApiKey(teamId);
      setActiveKey(res.data.api_key);
    } catch (error) {
      setBanner({
        message: 'Error fetching API keys. Please reload the page or contact support.',
        type: 'error',
      });
      delayEvent(3000, setBanner, null);
    }
    setActiveKeyLoading(false);
  };

  useEffect(() => {
    if (!activeKeyLoading) return;
    fetchActiveKey();
  }, [activeKeyLoading]);

  useEffect(() => {
    if (!inactiveKeysLoading) return;
    fetchInactiveKeys();
  }, [inactiveKeysLoading, page]);

  const renderHeader = () => {
    return (
      <div className="mb3 flex items-center justify-between">
        <div className="f3 dark-blue">Chartable API</div>
      </div>
    );
  };

  const renderCopy = () => {
    return (
      <div>
        <p>
          API keys can be used to create, read, and update your Chartable data. Generate a key below
          and refer to our{' '}
          <a href="https://api.chartable.com" target="_blank">
            API documentation
          </a>{' '}
          to get started.
        </p>
        <p>
          Only one API key can be active at a time. Creating a new key will render an active key
          unusable. If you find that your key no longer works, refer to the list below to ensure it
          has not been deactivated.
        </p>
      </div>
    );
  };

  return (
    <div>
      {banner && (
        <div style={{ top: 'calc(100vh - 50px)', zIndex: 100 }} className="fixed w-100">
          <BannerNotice type={banner.type} message={banner.message} />
        </div>
      )}
      <div style={{ margin: '0 auto' }} className="mw9 pv3 ph4">
        <div>
          {renderHeader()}
          {renderCopy()}
          {activeKeyLoading && !activeKey ? (
            <Loading fullScreen />
          ) : (
            <KeyGen
              activeKey={activeKey}
              createKey={handleCreateKey}
              keyCreated={keyCreated}
              delayEvent={delayEvent}
            />
          )}
          {inactiveKeysLoading && !inactiveKeys ? (
            <Loading fullScreen />
          ) : (
            <KeyList
              keys={inactiveKeys}
              delayEvent={delayEvent}
              page={page}
              pageCount={pageCount}
              pageSize={pageSize}
              setPage={page => {
                setInactiveKeysLoading(true);
                setPage(page);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ApiManagerView;
