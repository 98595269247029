import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter as Router, Link, matchPath, Route, Switch } from 'react-router-dom';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getAudioAdCampaigns } from 'shared/api';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';
import Header from 'shared/Header';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';
import Loading from 'shared/Loading';
import { OptionWithIcon, SingleValueWithIcon } from 'shared/ReactSelectHelpers';
import { ReadOnlyModeContext } from 'shared/ReadOnlyModeContext';
import Sidebar from 'shared/Sidebar';
import TabBarItem from 'shared/TabBarItem';
import UserContext, { withUser } from 'shared/UserContext';

import Analytics from './components/Analytics';
import ArchiveView from './components/ArchiveView';
import Audience from './components/Audience';
import AudioAdPlacementDetailView from './components/AudioAdPlacementDetailView';
import Benchmarks from './components/Benchmarks';
import LandingPage from './components/LandingPage';
import Manage from './components/Manage';
import PodcastRollupDetailView from './components/PodcastRollupDetailView';
import PromoForm from './components/PromoForm';
import Settings from './components/Settings';

// Order matters! getCurrentPath chooses the FIRST path that matches.
const routes = {
  new: '/teams/:teamId/dashboard/promos/new',
  settings: '/teams/:teamId/dashboard/promos/settings',
  benchmarks: '/teams/:teamId/dashboard/promos/benchmarks',
  landing: '/teams/:teamId/dashboard/promos/landing',
  podcastRollupFromPlacement:
    '/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics/:audioAdPlacementId/byPodcast/:podcastId',
  podcastRollup:
    '/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics/byPodcast/:podcastId',
  analyticsByPodcast: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics/byPodcast',
  audioAdPlacementDetailView:
    '/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics/:audioAdPlacementId',
  analytics: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics',
  archive: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/manage/archive',
  manageRequests: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/manage/requests',
  manageAudioAdPlacementDetailView:
    '/teams/:teamId/dashboard/promos/:audioAdCampaignId/manage/:audioAdPlacementId',
  manage: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/manage',
  audienceDemographics: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/audience/demographics',
  audiencePlayers: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/audience/players',
  audience: '/teams/:teamId/dashboard/promos/:audioAdCampaignId/audience',
  naked: '/teams/:teamId/dashboard/promos/:audioAdPlacementDetailView?',
};

// I need the current path of the sub routes at this level to manage tab bars.
// If I were using react router better this may not be necessary, but alas...
function getCurrentPath(pathname) {
  return routes[
    Object.keys(routes).find(key => {
      return matchPath(pathname, { path: routes[key] });
    })
  ];
}

export function SmartPromos(props) {
  const {
    match,
    history,
    location,
    allColumns,
    selectedColumns,
    teamHashedId,
    availableLookbackWindows,
    canEditPromos,
    smartPromosCreatedThisMonth,
    monthlySmartPromosLimit,
    canCreateSmartPromos,
    canCreateUnlimitedSmartPromos,
    canIgnoreTrackableErrorOnSmartPromoCreation,
  } = props;

  const readOnlyMode = useReadOnlyMode();

  const { teamId, audioAdCampaignId } = match.params;

  const [manageTabState, setManageTabState] = useState(null);
  const [analyticsTabState, setAnalyticsTabState] = useState(undefined);

  const [failedToLoadAudioAdCampaigns, setFailedToLoadAudioAdCampaigns] = useState(false);
  const [audioAdCampaigns, setAudioAdCampaigns] = useState(null);

  const [lookbackWindow, setLookbackWindow] = useState(props.lookbackWindow);

  const [attributionStatusNotifications, setAttributionStatusNotifications] = useState(
    props.attributionStatusNotifications,
  );
  const [smartpromoApprovalRequests, setSmartpromoApprovalRequests] = useState(
    props.smartpromoApprovalRequests,
  );
  const [art19DecryptionKeys, setArt19DecryptionKeys] = useState(props.art19DecryptionKeys);

  const [newPromo, setNewPromo] = useState(null);

  const [hasNoCampaigns, setHasNoCampaigns] = useState(false);

  const [selectedAudioAdCampaign, setSelectedAudioAdCampaign] = useState(null);

  const audioAdCampaignOptions = useMemo(() => {
    if (!audioAdCampaigns || failedToLoadAudioAdCampaigns) return null;
    return audioAdCampaigns.map(c => ({
      value: c,
      label: c.name,
      icon: c.buyerPodcast ? c.buyerPodcast.displayImageUrl : null,
    }));
  }, [audioAdCampaigns, failedToLoadAudioAdCampaigns]);

  const currentPath = useMemo(() => getCurrentPath(location.pathname), [location.pathname]);

  const routeNeedsAudioAdCampaign = [
    routes.podcastRollupFromPlacement,
    routes.podcastRollup,
    routes.analyticsByPodcast,
    routes.audioAdPlacementDetailView,
    routes.manageAudioAdPlacementDetailView,
    routes.analytics,
    routes.manageRequests,
    routes.manage,
    routes.audienceDemographics,
    routes.audiencePlayers,
    routes.audience,
    routes.naked,
    routes.archive,
  ].includes(currentPath);

  useEffect(() => {
    if (!audioAdCampaigns || failedToLoadAudioAdCampaigns || !routeNeedsAudioAdCampaign) return;
    // It's a bit weird to set the audio ad campaign state based on the url param instead of just setting the url.
    // I do this so that this state gets saved when tabbing and users can still save urls to quickly go to their podcast.
    const newSelectedAudioAdCampaign = audioAdCampaigns.find(
      c => c.audioAdCampaignId === audioAdCampaignId,
    );
    if (!newSelectedAudioAdCampaign && !hasNoCampaigns) {
      history.push(
        `/teams/${teamId}/dashboard/promos/${audioAdCampaigns[0].audioAdCampaignId}/analytics`,
      );
    } else {
      setSelectedAudioAdCampaign(newSelectedAudioAdCampaign);
    }
  }, [
    audioAdCampaignId,
    audioAdCampaigns,
    failedToLoadAudioAdCampaigns,
    hasNoCampaigns,
    history,
    routeNeedsAudioAdCampaign,
    teamId,
  ]);

  function handleSwitchAudioAdCampaign(newAudioAdCampaignId) {
    // This is kind of hacky, I'm swapping out a url parameter in the middle of a url. -JS
    history.push(
      `/teams/${teamId}/dashboard/promos/${newAudioAdCampaignId}${location.pathname.slice(
        match.url.length,
      )}`,
    );
  }

  const clearManageTabState = () => setManageTabState(undefined);
  const clearAnalyticsTabState = () => setAnalyticsTabState(undefined);

  const handleUpdateSettings = newSettings => {
    setAttributionStatusNotifications(newSettings.attributionStatusNotifications);
    setSmartpromoApprovalRequests(newSettings.smartpromoApprovalRequests);
    if (
      newSettings.lookbackWindow !== lookbackWindow ||
      newSettings.art19DecryptionKeys !== art19DecryptionKeys
    ) {
      clearAnalyticsTabState();
      clearManageTabState();
      setLookbackWindow(newSettings.lookbackWindow);
      setArt19DecryptionKeys(newSettings.art19DecryptionKeys);
    }
  };

  const handleGetAudioAdCampaigns = useCallback(() => {
    getAudioAdCampaigns({ teamId })
      .then(res => {
        if (res.data.length === 0) {
          if (routeNeedsAudioAdCampaign) {
            history.push(`/teams/${teamId}/dashboard/promos/landing`);
          }
          setHasNoCampaigns(true);
        }

        setAudioAdCampaigns(res.data);
      })
      .catch(() => setFailedToLoadAudioAdCampaigns(true));
  }, [history, routeNeedsAudioAdCampaign, teamId]);

  useEffect(() => handleGetAudioAdCampaigns(), [handleGetAudioAdCampaigns]);

  const onCreatePromo = promo => {
    clearAnalyticsTabState();
    clearManageTabState();
    setNewPromo(promo);

    const isNewCampaign = !audioAdCampaigns.find(
      c => c.audioAdCampaignId === promo.audioAdCampaignId,
    );

    if (isNewCampaign) {
      setAudioAdCampaigns(null);
      handleGetAudioAdCampaigns();
    }
  };

  // Get banner for # of smartpromos left to create this month.
  // Return null if no banner is needed (enterprise+ plan).
  function getLimitBanner() {
    if (canCreateUnlimitedSmartPromos) return null;

    if (canCreateSmartPromos) {
      const promosLeftThisMonth = monthlySmartPromosLimit - smartPromosCreatedThisMonth;
      return (
        <Banner>
          <div className="mb2 f6">
            You have <b>{promosLeftThisMonth > 0 ? promosLeftThisMonth : 'no more'}</b> promos
            remaining this month.
          </div>
        </Banner>
      );
    } else if (audioAdCampaigns.length > 0) {
      return (
        <Banner>
          <div className="mb2 f6">You cannot create more promos this month.</div>
        </Banner>
      );
    }

    return null;
  }

  if (failedToLoadAudioAdCampaigns) {
    return (
      <BannerNotice
        error
        message="Failed to load SmartPromos. Please reload the page to try again."
      />
    );
  }

  if (!audioAdCampaigns) {
    return <Loading fullScreen />;
  }

  if (currentPath === routes.naked) {
    history.push(`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics`);
    return <Loading fullScreen />;
  }

  // Skip auto AudioAdCampaign checks/sets if route doesn't need it
  if (routeNeedsAudioAdCampaign) {
    if (!audioAdCampaignId) {
      history.push(
        `/teams/${teamId}/dashboard/promos/${audioAdCampaigns[0].audioAdCampaignId}/analytics`,
      );
      return <Loading fullScreen />;
    }

    if (!selectedAudioAdCampaign) {
      return <Loading fullScreen />;
    }
  }

  const hideLargeHeader = [
    routes.settings,
    routes.audioAdPlacementDetailView,
    routes.manageAudioAdPlacementDetailView,
    routes.podcastRollupFromPlacement,
    routes.podcastRollup,
    routes.new,
    routes.archive,
  ].includes(currentPath);

  const disableAudioAdCampaignSelector = !routeNeedsAudioAdCampaign;

  // Check if there is a default audio ad campaign to fall back to to determine if the user will see the marketing page
  const baseAudioAdCampaignUrl =
    audioAdCampaigns.length > 0
      ? `/teams/${teamId}/dashboard/promos/${
          selectedAudioAdCampaign
            ? selectedAudioAdCampaign.audioAdCampaignId
            : audioAdCampaigns[0].audioAdCampaignId
        }`
      : `/teams/${teamId}/dashboard/promos/landing`;

  const limitBanner = getLimitBanner();

  return (
    <div>
      {!hideLargeHeader && (
        <div className="bg-white pt4 ph4" data-testid="promos-large-banner">
          {limitBanner &&
            (smartPromosCreatedThisMonth >= monthlySmartPromosLimit || !canCreateSmartPromos) && (
              <div className="mb3">{limitBanner}</div>
            )}
          <div className="flex items-center flex-row justify-between mb4">
            <Header useNew>SmartPromos</Header>
            <div className="justify-end flex flex-wrap gap-large items-baseline">
              <>
                {readOnlyMode ? (
                  <Button disabled inverted type="primary" className="w4">
                    Settings
                  </Button>
                ) : (
                  <Link
                    to={{
                      pathname: `/teams/${teamId}/dashboard/promos/settings`,
                      state: { backUrl: location.pathname },
                    }}
                    className="no-underline"
                  >
                    <Button inverted type="primary" className="w4">
                      Settings
                    </Button>
                  </Link>
                )}
              </>
              {canCreateSmartPromos && (
                <>
                  {readOnlyMode ? (
                    <Button disabled type="create" className="w4">
                      Create Promo
                    </Button>
                  ) : (
                    <Link
                      to={{
                        pathname: `/teams/${teamId}/dashboard/promos/new`,
                        state: { backUrl: location.pathname },
                      }}
                      className="no-underline white"
                    >
                      <Button disabled={readOnlyMode} type="create" className="w4">
                        Create Promo
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-large">
            {hasNoCampaigns ? (
              <TabBarItem
                title="Manage"
                selected={routes.landing === currentPath}
                route={`/teams/${teamId}/dashboard/promos/landing`}
              />
            ) : (
              <React.Fragment>
                <TabBarItem
                  title="Analytics"
                  selected={[routes.analytics, routes.analyticsByPodcast].includes(currentPath)}
                  route={`${baseAudioAdCampaignUrl}/analytics`}
                />
                <TabBarItem
                  title="Audience"
                  selected={currentPath === routes.audience}
                  route={`${baseAudioAdCampaignUrl}/audience`}
                />
                <TabBarItem
                  title="Manage"
                  selected={[routes.manage, routes.manageRequests].includes(currentPath)}
                  route={`${baseAudioAdCampaignUrl}/manage`}
                />
              </React.Fragment>
            )}
            <TabBarItem
              title="Benchmarks"
              selected={currentPath === routes.benchmarks}
              route={`/teams/${teamId}/dashboard/promos/benchmarks`}
            />
          </div>
          <div
            className={`bt b--light-gray ${
              disableAudioAdCampaignSelector ? 'o-0 h0 hidden pv0' : 'o-100 pv3'
            }`}
            style={{
              transition:
                'padding-top 100ms, padding-bottom 50ms, opacity 50ms, visibility 1000ms, height: 200ms',
            }}
          >
            <Select
              isDisabled={disableAudioAdCampaignSelector}
              value={
                disableAudioAdCampaignSelector
                  ? null
                  : {
                      value: selectedAudioAdCampaign,
                      label: selectedAudioAdCampaign.name,
                      icon: selectedAudioAdCampaign.buyerPodcast
                        ? selectedAudioAdCampaign.buyerPodcast.displayImageUrl
                        : null,
                    }
              }
              options={audioAdCampaignOptions}
              components={{ SingleValue: SingleValueWithIcon, Option: OptionWithIcon }}
              onChange={selection => handleSwitchAudioAdCampaign(selection.value.audioAdCampaignId)}
            />
          </div>
        </div>
      )}
      <Switch>
        <Route exact path={routes.benchmarks} component={Benchmarks} />
        {!readOnlyMode && (
          <Route
            exact
            path={routes.settings}
            render={renderProps => (
              <Settings
                {...renderProps}
                selectedAudioAdCampaign={selectedAudioAdCampaign}
                attributionStatusNotifications={attributionStatusNotifications}
                smartpromoApprovalRequests={smartpromoApprovalRequests}
                teamHashedId={teamHashedId}
                lookbackWindow={lookbackWindow}
                availableLookbackWindows={availableLookbackWindows}
                art19DecryptionKeys={art19DecryptionKeys}
                onUpdate={handleUpdateSettings}
              />
            )}
          />
        )}
        <Route
          path={routes.landing}
          render={renderProps => (
            <LandingPage canCreateSmartPromos={canCreateSmartPromos} {...renderProps} />
          )}
        />
        {!readOnlyMode && (
          <Route
            exact
            path={routes.new}
            render={renderProps => (
              <PromoForm
                {...renderProps}
                onCreatePromo={onCreatePromo}
                limitBanner={limitBanner}
                reachedPromoLimit={
                  monthlySmartPromosLimit - smartPromosCreatedThisMonth < 0 &&
                  !canCreateUnlimitedSmartPromos
                }
                canIgnoreTrackableErrorOnSmartPromoCreation={
                  canIgnoreTrackableErrorOnSmartPromoCreation
                }
              />
            )}
          />
        )}
        <Route
          path={[routes.podcastRollup, routes.podcastRollupFromPlacement]}
          render={renderProps => (
            <PodcastRollupDetailView
              {...renderProps}
              clearAnalyticsTabState={clearAnalyticsTabState}
              storeState={state => setAnalyticsTabState({ ...state, ...analyticsTabState })}
              initialState={analyticsTabState}
              selectedPodcast={selectedAudioAdCampaign.buyerPodcast}
              lookbackWindow={lookbackWindow}
            />
          )}
        />
        <Route
          path={routes.audience}
          render={renderProps => (
            <Audience
              {...renderProps}
              initialState={analyticsTabState}
              clearAnalyticsTabState={clearAnalyticsTabState}
              clearManageTabState={clearManageTabState}
              storeState={state => setAnalyticsTabState({ ...state, ...analyticsTabState })}
            />
          )}
        />
        <Route
          path={routes.archive}
          render={renderProps => (
            <ArchiveView
              {...renderProps}
              selectedPodcast={selectedAudioAdCampaign.buyerPodcast}
              clearManageTabState={clearManageTabState}
            />
          )}
        />
        <Route
          path={routes.manageRequests}
          render={renderProps => (
            <Manage
              {...renderProps}
              initialState={manageTabState}
              storeState={state => setManageTabState(state)}
              clearAnalyticsTabState={clearAnalyticsTabState}
              viewingPromoTable={currentPath === routes.manage}
              newPromo={newPromo}
              nullifyNewPromo={() => setNewPromo(null)}
            />
          )}
        />
        <Route
          path={routes.manageAudioAdPlacementDetailView}
          render={renderProps => (
            <AudioAdPlacementDetailView
              {...renderProps}
              clearManageTabState={clearManageTabState}
              clearAnalyticsTabState={clearAnalyticsTabState}
              storeState={state => setManageTabState({ ...state, ...manageTabState })}
              showData={false}
              initialState={manageTabState}
              lookbackWindow={lookbackWindow}
              selectedPodcast={selectedAudioAdCampaign.buyerPodcast}
              backUrl={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/manage`}
              canEditPromos={!readOnlyMode && canEditPromos}
            />
          )}
        />
        <Route
          path={routes.manage}
          render={renderProps => (
            <Manage
              {...renderProps}
              initialState={manageTabState}
              storeState={state => setManageTabState(state)}
              clearAnalyticsTabState={clearAnalyticsTabState}
              viewingPromoTable={currentPath === routes.manage}
              newPromo={newPromo}
              nullifyNewPromo={() => setNewPromo(null)}
            />
          )}
        />
        <Route
          path={routes.analyticsByPodcast}
          render={renderProps => (
            <Analytics
              {...renderProps}
              selectedColumns={selectedColumns}
              allColumns={allColumns}
              clearManageTabState={clearManageTabState}
              storeState={state => setAnalyticsTabState(state)}
              initialState={analyticsTabState}
              lookbackWindow={lookbackWindow}
              byPodcast
            />
          )}
        />
        <Route
          path={routes.audioAdPlacementDetailView}
          render={renderProps => (
            <AudioAdPlacementDetailView
              {...renderProps}
              clearManageTabState={clearManageTabState}
              clearAnalyticsTabState={clearAnalyticsTabState}
              storeState={state => setAnalyticsTabState({ ...state, ...analyticsTabState })}
              initialState={analyticsTabState}
              lookbackWindow={lookbackWindow}
              selectedPodcast={selectedAudioAdCampaign.buyerPodcast}
              backUrl={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics`}
              canEditPromos={!readOnlyMode && canEditPromos}
            />
          )}
        />
        <Route
          path={routes.analytics}
          render={renderProps => (
            <Analytics
              {...renderProps}
              selectedColumns={selectedColumns}
              allColumns={allColumns}
              clearManageTabState={clearManageTabState}
              lookbackWindow={lookbackWindow}
              storeState={state => setAnalyticsTabState(state)}
              initialState={analyticsTabState}
            />
          )}
        />
      </Switch>
    </div>
  );
}

SmartPromos.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  allColumns: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  art19DecryptionKeys: PropTypes.array.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  attributionStatusNotifications: PropTypes.bool.isRequired,
  canEditPromos: PropTypes.bool.isRequired,
  smartpromoApprovalRequests: PropTypes.bool.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  availableLookbackWindows: PropTypes.array.isRequired,
};

const WrappedSmartPromos = withUser(SmartPromos);

const SmartPromosRouter = props => (
  <UserContext.Provider value={props.userInfo}>
    <ReadOnlyModeContext.Provider value={props.readOnlyMode}>
      <Sidebar readOnlyMode={props.readOnlyMode} sidebar={props.sidebar}>
        <Router>
          <Route
            path="/teams/:teamId/dashboard/promos/:audioAdCampaignId?"
            render={renderProps => <WrappedSmartPromos {...props} {...renderProps} />}
          />
        </Router>
      </Sidebar>
    </ReadOnlyModeContext.Provider>
  </UserContext.Provider>
);

export default SmartPromosRouter;
