import React from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import PropTypes from 'prop-types';

const PodcastDisplayRow = ({ row }) => (
  <div>
    {row.original.sellerPodcast ? (
      <div className="flex items-center">
        <div className="mr2" style={{ width: '15%' }}>
          <img src={row.original.sellerPodcast.displayImageUrl} className="center fl br2" alt="" />
        </div>
        <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
      </div>
    ) : (
      <div className="tc">Unknown</div>
    )}
  </div>
);

const EpisodeDisplayRow = ({ row }) => (
  <div>
    {row.original.episode ? (
      <div className="flex items-center">
        <div className="mr2" style={{ width: '15%' }}>
          <img src={row.original.sellerPodcast.displayImageUrl} className="center fl br2" alt="" />
        </div>
        <div className="w-70">{row.original.episode.title}</div>
      </div>
    ) : (
      <div className="tc">Unknown</div>
    )}
  </div>
);

function formatNumber(number) {
  return number !== null ? number.toLocaleString() : 'N/A';
}

export default function RollupTable({ rollups, dimension, onRowClick }) {
  const columns = [
    {
      Header: dimension,
      minWidth: 125,
      sortable: false,
      Cell: row => {
        if (dimension == 'Podcast') {
          return <PodcastDisplayRow row={row} />;
        } else if (dimension == 'Episode') {
          return <EpisodeDisplayRow row={row} />;
        }
        return <div>{row.original.name}</div>;
      },
    },
  ];

  if (dimension === 'Episode') {
    columns.push({
      Header: 'Published At',
      Cell: row => (
        <div className="tc">
          {row.original.episode && row.original.episode.publishedAt
            ? moment(row.original.episode.publishedAt).format('MM/DD/YYYY')
            : null}
        </div>
      ),
    });
  }

  columns.push(
    {
      Header: 'Started At',
      accessor: 'startedAt',
      id: 'startedAt',
      Cell: row => (
        <div className="tc">
          {row.original.startedAt ? moment(row.original.startedAt).format('MM/DD/YYYY') : null}
        </div>
      ),
    },
    {
      Header: 'Impressions',
      accessor: 'impressions.total',
      Cell: row => (
        <div className="tc">
          {row.original.impressions ? formatNumber(row.original.impressions.total) : null}
        </div>
      ),
    },
    {
      Header: 'Total Conversions',
      accessor: 'uniqueDownloads.total',
      Cell: row => (
        <div className="tc">
          {!row.original.isRequest && formatNumber(row.original.uniqueDownloads.total)}
        </div>
      ),
    },
    {
      Header: 'Total New Conversions',
      accessor: 'newDownloads.total',
      Cell: row => (
        <div className="tc">
          {!row.original.isRequest && formatNumber(row.original.newDownloads.total)}
        </div>
      ),
    },
    {
      Header: 'Conversions Rate',
      accessor: 'uniqueDownloads.responseRate',
      Cell: row => (
        <div className="tc">
          {!row.original.isRequest &&
            (row.original.uniqueDownloads.responseRate
              ? `${(row.original.uniqueDownloads.responseRate * 100).toFixed(3)}%`
              : null)}
        </div>
      ),
    },
    {
      Header: 'New Conversions Rate',
      accessor: 'newDownloads.responseRate',
      Cell: row => (
        <div className="tc">
          {!row.original.isRequest &&
            (row.original.newDownloads.responseRate
              ? `${(row.original.newDownloads.responseRate * 100).toFixed(3)}%`
              : null)}
        </div>
      ),
    },
  );

  return (
    <ReactTable
      defaultSorted={[
        {
          id: 'startedAt',
          desc: true,
        },
      ]}
      getTrProps={(state, rowInfo) => ({
        style: {
          cursor: dimension === 'Podcast' ? 'pointer' : '',
          opacity: rowInfo.original.isArchived ? '0.5' : '1',
          display: 'flex',
          alignItems: 'center',
        },
        onClick: () => {
          onRowClick(rowInfo);
        },
      })}
      data={rollups}
      showPagination={false}
      pageSize={rollups.length}
      columns={columns}
      className="-highlight w-100 f5"
      NoDataComponent={() => null}
    />
  );
}
