import React from 'react';
import PropTypes from 'prop-types';

import { demographicsSegments } from './DemographicsChartHelpers';

const DemographicsChartHeader = ({ segment, showLegend }) => {
  let title;

  switch (segment) {
    case demographicsSegments.householdIncome:
      title = 'Household Income';
      break;
    case demographicsSegments.education:
      title = 'Education';
      break;
    case demographicsSegments.ethnicity:
      title = 'Ethnicity';
      break;
    case demographicsSegments.gender:
      title = 'Gender';
      break;
    case demographicsSegments.age:
      title = 'Age';
  }

  return (
    <div className="flex items-baseline justify-between">
      <div className="b f4 force-inter dark-blue mt4 mb2">{title}</div>
      {showLegend && (
        <div className="card pa2 f6 flex items-center gap-large flex-row">
          <div className="gap-small flex items-center flex-row">
            <div className="w1 h1 br2" style={{ backgroundColor: '#CDECFF' }} />
            <div> benchmarks</div>
          </div>
          <div className="gap-small flex items-center flex-row">
            <div className="w1 h1 br2" style={{ backgroundColor: '#4b9ec1' }} />
            <div>you</div>
          </div>
        </div>
      )}
    </div>
  );
};

DemographicsChartHeader.propTypes = {
  segment: PropTypes.string.isRequired,
  showLegend: PropTypes.bool,
};

DemographicsChartHeader.defaultProps = {
  showLegend: false,
};

export default DemographicsChartHeader;
