import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { destroyTeamUser } from 'shared/api';
import Loading from 'shared/Loading';
import withNotifications from 'shared/NotificationManager';

import EditTeamUserForm from './components/EditTeamUserForm';
import TeamUserManagerView from './components/TeamUserManagerView';

class TeamUserManager extends Component {
  constructor(props) {
    super(props);

    this.handleDestroyTeamUser = this.handleDestroyTeamUser.bind(this);

    this.state = {
      loading: false,
    };
  }

  handleDestroyTeamUser(userId) {
    const { teamId, onCreateNotification } = this.props;

    this.setState({ loading: true });
    destroyTeamUser({
      id: userId,
      teamId,
    }).then(res => {
      onCreateNotification(res.data.message);
      this.setState({
        loading: false,
      });
    });
  }

  render() {
    const {
      teamSlug,
      teamId,
      baseRoute,
      onCreateNotification,
      hasRestrictedUsers,
      allowedRoles,
      hasDivisions,
      teamHashedId,
    } = this.props;

    const { loading } = this.state;
    if (loading) {
      return <Loading />;
    }

    return (
      <div>
        <Router>
          <Switch>
            <Route
              exact
              path={baseRoute}
              render={props => (
                <div>
                  <TeamUserManagerView
                    teamId={teamId}
                    teamSlug={teamSlug}
                    hasRestrictedUsers={hasRestrictedUsers}
                    onShowEditTeamUser={teamUserId =>
                      props.history.push(`${baseRoute}/${teamUserId}/edit`)
                    }
                    onCreateNotification={onCreateNotification}
                    onDestroyTeamUser={this.handleDestroyTeamUser}
                    hasDivisions={hasDivisions}
                  />
                </div>
              )}
            />
            <Route
              exact
              path={`${baseRoute}/:userId/edit`}
              render={props => (
                <div>
                  <EditTeamUserForm
                    teamId={teamId}
                    teamHashedId={teamHashedId}
                    userId={props.match.params.userId}
                    hasRestrictedUsers={hasRestrictedUsers}
                    onCloseForm={() => props.history.push(`${baseRoute}`)}
                    onCreateNotification={onCreateNotification}
                    allowedRoles={allowedRoles}
                    hasDivisions={hasDivisions}
                  />
                </div>
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

TeamUserManager.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  baseRoute: PropTypes.string.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
  hasRestrictedUsers: PropTypes.bool,
  allowedRoles: PropTypes.array.isRequired,
  hasDivisions: PropTypes.bool.isRequired,
};

TeamUserManager.defaultProps = {
  hasRestrictedUsers: false,
};

const TeamUserManagerWithNotifications = withNotifications(TeamUserManager);
export default TeamUserManagerWithNotifications;
