import React from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Loading from 'shared/Loading';

import { getTeamPodcastDefaults } from 'shared/api';

class TeamPodcastDefaults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      requestInProgress: true,
      podcasts: null,
      showPodcasts: false,
    };
  }

  componentDidMount() {
    getTeamPodcastDefaults({
      id: this.props.teamHashedId,
      teamId: this.props.teamId,
    })
      .then(res => {
        this.setState({ podcasts: res.data });
      })
      .finally(() => this.setState({ requestInProgress: false }));
  }

  renderPodcastTable() {
    const { podcasts, requestInProgress } = this.state;

    if (requestInProgress) {
      return (
        <div className="flex justify-center items-center w-100 h4 flex-column">
          <Loading />
          <div className="mt2 f6 tracked b ttu">Loading</div>
        </div>
      );
    }

    if (!requestInProgress && !podcasts) {
      return (
        <div className="tc f4 mid-gray pa3 b--mid-gray br3">
          <div className="mb2">Something went wrong loading your host settings.</div>
          <div>If this problem persists, please reach out to support.</div>
        </div>
      );
    }

    return (
      <div>
        <input type="text" placeholder="Filter by title" />
        <div className="flex flex-column ba br1 b--moon-gray">
          {podcasts.length > 0 ? (
            podcasts.map((podcast, index) => (
              <div
                key={podcast.shortTitle}
                className={`pa1 h3 flex items-center flex-row ${
                  index === podcasts.length - 1 ? '' : 'bb'
                } b--moon-gray`}
              >
                <img className="h-100 w-auto br1" src={podcast.displayImageUrl} alt="" />
                <div className="ml2">{podcast.shortTitle}</div>
                <div>
                  {podcast.provider ? podcast.provider : <b className="i">No default selected</b>}
                </div>
                <Button type="primary" className="w4">
                  Set default
                </Button>
              </div>
            ))
          ) : (
            <div className="tc f4 mid-gray pa3">No podcasts</div>
          )}
        </div>
      </div>
    );
  }

  render() {
    const { showPodcasts } = this.state;

    return (
      <div>
        <div className="f3 dark-blue mb3 header-font mr2">Podcast Defaults</div>
        <div style={{ fontSize: '18px' }} className="mid-gray lh-copy mb2">
          Setting a default hosting provider makes setting up dynamic campaigns quicker and easier.
        </div>
        <div style={{ fontSize: '18px' }} className="mid-gray lh-copy mb3">
          You can set a default provider for each podcast.
        </div>
        <div>
          <div
            className="link blue pointer mb2"
            style={{ fontSize: '19px' }}
            onClick={() => this.setState({ showPodcasts: !showPodcasts })}
          >
            {`${showPodcasts ? 'Hide' : 'Show'} Podcast Defaults`}
          </div>
          {showPodcasts && this.renderPodcastTable()}
        </div>
      </div>
    );
  }
}

TeamPodcastDefaults.propTypes = {
  teamHashedId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default TeamPodcastDefaults;
