import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { searchTeamUsers } from 'shared/api/v1';
import Checkbox from 'shared/Checkbox';
import EpisodeSearchInput from 'shared/EpisodeSearchInput';
import PodcastSearchInput from 'shared/PodcastSearchInput';

function LinkPodcastEpisodeForm({
  podcast,
  episode,
  dispatch,
  isEditing,
  teamId,
  errors,
  isAutomated,
  automatedLinkUserId,
  hasSmartLinksAutomation,
}) {
  const [isDeeplink, setIsDeeplink] = useState(!!episode);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleSetUser = selection => {
    dispatch({ type: 'setAutomatedLinkUserId', payload: selection.id });
    setSelectedUser(selection);
  };

  const queryTeamUsers = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      searchTeamUsers({ query: inputValue }).then(res => callback(res.data.data));
    }
  };

  return (
    <div>
      <div className="f5 mb2 mt3 dark-gray">Podcast</div>
      <PodcastSearchInput
        isDisabled={isEditing}
        teamId={teamId}
        onSelect={selectedPodcast => {
          setIsDeeplink(false);
          dispatch({ type: 'setPodcast', payload: selectedPodcast });
          dispatch({ type: 'setCustomShortLinkDomainId', payload: null });
        }}
        initialPodcast={podcast}
        className="f5"
        excludeStatistics
      />
      {podcast && (
        <div className="mv4">
          <Checkbox
            className="mb3"
            radioButton
            isChecked={!isDeeplink}
            onClick={() => {
              dispatch({ type: 'setEpisode', payload: null });
              dispatch({ type: 'setAutomatedLinkUserId', payload: null });
              dispatch({ type: 'setIsAutomated', payload: false });
              setIsDeeplink(false);
            }}
            label="Link to Podcast"
            flavorText="(default)"
            description="Select this option if you want the listener to land on the Podcast Page (sometimes referred to as the Podcast Overview) in their podcast player."
          />
          <Checkbox
            radioButton
            isChecked={isDeeplink}
            onClick={() => setIsDeeplink(true)}
            label="Link to Episode"
            description="Select this option if you want the listener to land on the Episode Page of a specific episode in their podcast player. Addiitionally, select this if you'd like a link to be created automatically for future episodes."
          />
        </div>
      )}
      {isDeeplink && (
        <React.Fragment>
          <div className="f5 mb2 mt3 dark-gray">Episode</div>
          {errors && errors.episode && <div className="red f6 mb1 b">{errors.episode}</div>}
          <EpisodeSearchInput
            podcastId={podcast.id}
            teamId={teamId}
            onSelect={selectedEpisode => {
              dispatch({ type: 'setEpisode', payload: selectedEpisode });
            }}
            initialEpisode={episode}
            className={`f5 w-100 ${errors && errors.episode ? 'ba b--red br2' : ''}`}
            allowRefresh
            numberOfEpisodesToPrefetch={10}
          />
        </React.Fragment>
      )}
      {hasSmartLinksAutomation && (
        <div>
          {episode && (
            <div>
              <Checkbox
                isChecked={isAutomated}
                onClick={() => dispatch({ type: 'setIsAutomated', payload: !isAutomated })}
                label={`Automatically generate new links for future episodes${
                  isAutomated && automatedLinkUserId ? ' (sent to the email below)' : ''
                }`}
                className="mt2 mb2"
              />
              {isAutomated && (
                <div>
                  <div className="mid-gray f6 mb1">
                    Type to search for a user who should receive the SmartLinks
                  </div>
                  <AsyncSelect
                    getOptionLabel={option => option.email}
                    className="flex-grow-1"
                    cacheOptions
                    getOptionValue={({ value }) => value}
                    loadOptions={queryTeamUsers}
                    placeholder="Search by email..."
                    onChange={handleSetUser}
                    value={selectedUser}
                  />
                </div>
              )}
            </div>
          )}
          {errors &&
            errors.automatedTrackingLinkUserId &&
            errors.automatedTrackingLinkUserId[0] && (
              <div className="f6 red b mt1">{`${errors.automatedTrackingLinkUserId[0]}`}</div>
            )}
        </div>
      )}
    </div>
  );
}

LinkPodcastEpisodeForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  podcast: PropTypes.object,
  episode: PropTypes.object,
  errors: PropTypes.object,
  isEditing: PropTypes.bool,
  isAutomated: PropTypes.bool,
  automatedLinkUserId: PropTypes.string,
  hasSmartLinksAutomation: PropTypes.bool,
};

LinkPodcastEpisodeForm.defaultProps = {
  isEditing: false,
  errors: null,
};

export default LinkPodcastEpisodeForm;
