import React, { useState } from 'react';
import Button from 'shared/Button';
import { adminGetTeamsForUser } from 'shared/api';

const useGetTeamsForUsers = () => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [error, setError] = useState('');
  const [teams, setTeams] = useState(null);

  const reset = () => {
    setError('');
    setTeams(null);
    setRequestInProgress(false);
  };

  const makeRequest = async params => {
    setRequestInProgress(true);
    try {
      const response = await adminGetTeamsForUser(params);
      console.log(response);
      setTeams(response.data);
    } catch (e) {
      console.log(e);
      setError('something went wrong');
    }

    setRequestInProgress(false);
  };

  return {
    requestInProgress,
    teams,
    error,
    makeRequest,
    reset,
  };
};

const TeamUserFinder = () => {
  const [email, setEmail] = useState('');

  const { requestInProgress, teams, error, makeRequest, reset } = useGetTeamsForUsers();

  return (
    <>
      <div className="mw6 pa4">
        <div className="f4 blue">How this works</div>
        <div className="f5 lh-copy mb4">
          <ol className="mb0 mt2">
            <li>Enter a user's email</li>
            <li>All teams associated with user will be output</li>
            <li>
              It will indicate if the team <i>should</i> be granting the user access
            </li>
          </ol>
        </div>
        <div className="f5 mb2 blue">Enter a user email</div>
        <input
          disabled={requestInProgress}
          value={email}
          className="pa2 input-reset ba bg-white br2 b--silver w-100 mb3"
          type="email"
          placeholder="chartable@gmail.com"
          onChange={e => setEmail(e.target.value)}
        />
        {error && <div className="red f6 mb3">{error}</div>}
        <Button
          disabled={!email}
          loading={requestInProgress}
          type="create"
          onClick={() => {
            reset();
            makeRequest({ email });
          }}
        >
          Confirm
        </Button>
      </div>
      {teams && (
        <div className="pa4 w-80 flex flex-column">
          <div className="pa1 flex white flex-row b justify-between bg-blue">
            <div>id</div>
            <div>name</div>
            <div>slug</div>
            <div>stripe bundle</div>
            <div>force_bundle_team</div>
            <div>can_use_adops</div>
          </div>
          {teams.map((team, index) => (
            <div
              className={`pa1 flex flex-row justify-between ${
                index % 2 === 0 ? 'bg-white' : 'bg-near-white'
              }`}
            >
              <div>{team.id}</div>
              <div>{team.name}</div>
              <div>{team.slug}</div>
              <div>{team.stripeBundle ? '✅' : '❌'}</div>
              <div>{team.forceBundleTeam ? '✅' : '❌'}</div>
              <div>{team.canUseAdops ? '✅' : '❌'}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default TeamUserFinder;
