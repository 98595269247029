import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
  useLocation,
  Link,
  Redirect,
} from 'react-router-dom';
import moment from 'moment';

import ConfirmationModal from 'shared/ConfirmationModal';
import Banner from 'shared/Banner';
import SmallTopBar from 'shared/SmallTopBar';
import TableErrorView from 'shared/TableErrorView';
import PaginatedTable from 'shared/PaginatedTable';
import UserContext from 'shared/UserContext';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';
import Header from 'shared/Header';
import TabBarItem from 'shared/TabBarItem';
import { getPodcast, getSalesSheets, deleteSalesSheet } from 'shared/api';
import useUserInfo from 'shared/hooks/useUserInfo';

import NewSalesSheetForm from './components/NewSalesSheetForm';
import SalesSheet from './components/SalesSheet';

const useCopy = (text, callback) => {
  // Hack alert! (I think DZ wrote this -JS)
  let dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', text);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  if (callback) {
    callback();
  }
};

const TableRowWrapper = ({ teamId, rowId, children }) => (
  <Link
    className="no-underline dark-gray"
    to={`/teams/${teamId}/dashboard/sales_sheet/manage/${rowId}`}
  >
    {children}
  </Link>
);

TableRowWrapper.propTypes = {
  teamId: PropTypes.string,
  rowId: PropTypes.string,
  children: PropTypes.node,
};

const DashboardSalesSheet = ({ onlyAllowedPodcastId, history }) => {
  const { teamId } = useParams();
  const location = useLocation();
  const { canManageSalesSheets } = useUserInfo();

  const [loadingInitialState, setLoadingInitialState] = useState(true);
  const [initialPodcast, setInitialPodcast] = useState(null);
  const [salesSheets, setSalesSheets] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);

  const columns = useMemo(
    () => [
      {
        Header: 'Podcast',
        minWidth: 125,
        Cell: row => (
          <TableRowWrapper teamId={teamId} rowId={row.original.id}>
            <div className="flex items-center tl">
              <div className="h2 w2 mr2">
                <img src={row.original.podcast.imageUrl} className="br2" alt="" />
              </div>
              <div className="w-70">{row.original.podcast.title}</div>
            </div>
          </TableRowWrapper>
        ),
        sortable: false,
      },
      {
        Header: 'Date',
        accessor: 'createdAt',
        id: 'createdAt',
        sortable: false,
        Cell: row => (
          <TableRowWrapper teamId={teamId} rowId={row.original.id}>
            {moment(row.original.createdAt).format('MM/DD/YYYY')}
          </TableRowWrapper>
        ),
      },
    ],
    [],
  );

  const handlePaging = ({ selected }) => {
    setPage(selected + 1); // Pagination component starts at 0, but pagination starts at 1
  };

  const handleGetSalesSheets = () => {
    getSalesSheets({ teamId, page })
      .then(res => {
        setTotal(res.data.metadata.total);
        setSalesSheets(res.data.data);
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    const podcastId = onlyAllowedPodcastId
      ? onlyAllowedPodcastId
      : new URLSearchParams(window.location.search).get('podcast_id');
    if (!podcastId) {
      setLoadingInitialState(false);
    } else {
      getPodcast({ podcastId, teamId })
        .then(res => setInitialPodcast(res.data))
        .finally(() => setLoadingInitialState(false));
    }
  }, []);

  useEffect(() => {
    handleGetSalesSheets();
  }, [page]);

  const header = (
    <div className="bg-white pt4 ph4">
      <Header useNew className="mb4">
        Sales Sheet
      </Header>
      <div className="flex flex-row gap-large">
        {canManageSalesSheets && (
          <TabBarItem
            title="Create"
            route={`/teams/${teamId}/dashboard/sales_sheet/create`}
            selected={location.pathname === `/teams/${teamId}/dashboard/sales_sheet/create`}
          />
        )}
        <TabBarItem
          title="Manage"
          route={`/teams/${teamId}/dashboard/sales_sheet/manage`}
          selected={location.pathname === `/teams/${teamId}/dashboard/sales_sheet/manage`}
        />
      </div>
    </div>
  );

  if (
    location.pathname === `/teams/${teamId}/dashboard/sales_sheet/` ||
    location.pathname === `/teams/${teamId}/dashboard/sales_sheet`
  ) {
    history.push(
      canManageSalesSheets
        ? `/teams/${teamId}/dashboard/sales_sheet/create`
        : `/teams/${teamId}/dashboard/sales_sheet/manage`,
    );
  }

  if (loadingInitialState) {
    return (
      <React.Fragment>
        {header}
        <Loading fullScreen />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {header}
      <div className="ph4 mv4">
        <Route
          path="/teams/:teamId/dashboard/sales_sheet/create"
          render={props =>
            canManageSalesSheets ? (
              <div className="mw6">
                <NewSalesSheetForm {...props} initialPodcast={initialPodcast} teamId={teamId} />
              </div>
            ) : (
              <Redirect to={`/teams/${teamId}/dashboard/sales_sheet/manage`} />
            )
          }
        />

        <Route path="/teams/:teamId/dashboard/sales_sheet/manage">
          <div>
            <Header useNew className="force-inter mb2" size="small">
              All Sales Sheets
            </Header>
            {salesSheets || error ? (
              <PaginatedTable
                data={salesSheets}
                columns={columns}
                total={total}
                pageSize={10}
                page={page}
                defaultSorted={[
                  {
                    id: 'createdAt',
                    desc: true,
                  },
                ]}
                rowStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                handlePaging={handlePaging}
                noDataComponent={() => (
                  <div>
                    <TableErrorView header="You don't have any sales sheets yet!" />
                  </div>
                )}
                failedToLoad={error}
                failedToLoadMessage={
                  <TableErrorView
                    header="Failed to load Sales Sheets"
                    subheader="Please reach out to support if this problem persists."
                  />
                }
              />
            ) : (
              <Loading fullScreen />
            )}
          </div>
        </Route>
      </div>
    </React.Fragment>
  );
};

DashboardSalesSheet.propTypes = {
  onlyAllowedPodcastId: PropTypes.string,
  history: PropTypes.object,
};

const SalesSheetContainer = ({ history }) => {
  const { id, teamId } = useParams();
  const displayUrl = `https://chartable.com/sheet/${id}`;
  const { canManageSalesSheets } = useUserInfo();

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [error, setError] = useState(false);

  const handleDeleteSalesSheet = () => {
    if (deleteInProgress) return;
    setDeleteInProgress(true);

    deleteSalesSheet({ id, teamId })
      .then(() => {
        history.push(`/teams/${teamId}/dashboard/sales_sheet/manage`);
      })
      .catch(() => {
        setDeleteInProgress(false);
        setConfirmDeleteOpen(false);
        setError(true);
      });
  };

  const disclaimerText =
    'Anyone who has the link associated with this sales sheet can view this data.';
  const deletionDisclaimerText =
    ' Deleting this sales sheet will remove public access. You can delete the sales sheet at any time.';

  return (
    <div>
      <SmallTopBar
        backText="Manage Sales Sheets"
        backUrl={`/teams/${teamId}/dashboard/sales_sheet/manage`}
      />
      <div className="pa3">
        <Banner>
          <div className="b f6 mb1">This sales sheet data is public</div>
          <div className="f6">
            {`${disclaimerText} ${canManageSalesSheets ? deletionDisclaimerText : ''}`}
          </div>
        </Banner>
        {error && (
          <Banner type="error" className="mv3" onClick={() => setError(false)}>
            <div className="b f6 mb1">This sales sheet data is public</div>
            <div className="f6">
              {`${disclaimerText} ${canManageSalesSheets ? deletionDisclaimerText : ''}`}
            </div>
          </Banner>
        )}
        <div className="mb2 mt3 pa3 br3 bg-white flex items-end flex-wrap justify-between">
          <div className="mw6-l w-100" style={{ flex: '50%' }}>
            <div className="mb2">
              <a href={displayUrl} target="_blank" className="b blue link f5" rel="noreferrer">
                Public link
              </a>
            </div>
            <div className="flex br2 flex-row bg-near-white gap-small ba b--near-white w-100">
              <div className="truncate pv2 ph1 dark-gray f5">{displayUrl}</div>
              {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
              <div
                onClick={() => useCopy(displayUrl, null)}
                className="ml-auto pv2 f6 ph3 bg-blue white b pointer dim"
              >
                Copy
              </div>
            </div>
          </div>
          {canManageSalesSheets && (
            <div
              className="ml-auto w-100 w-auto-l tc mt0-l mt2 pv2 f6 ph3 ba b--red red b pointer dim"
              onClick={() => setConfirmDeleteOpen(true)}
            >
              Delete
            </div>
          )}
        </div>
        <div className="w-100 bb mt4 mb3 b--light-gray" />
        <SalesSheet id={id} />
      </div>
      <ConfirmationModal
        title="Confirm Sales Sheet Deletion"
        message="The sales sheet will no longer be accessible to anyone"
        onClose={() => setConfirmDeleteOpen(false)}
        isOpen={confirmDeleteOpen}
        onConfirm={handleDeleteSalesSheet}
      />
    </div>
  );
};

SalesSheetContainer.propTypes = {
  history: PropTypes.object,
};

const DashboardSalesSheetRouter = ({ userInfo, sidebar }) => {
  const showSalesSheetUpsell = userInfo && userInfo.showSalesSheetUpsell;

  return (
    <Router>
      <Switch>
        <Route
          path="/sheet/:id"
          render={renderProps => <SalesSheet id={renderProps.match.params.id} {...renderProps} />}
        />
        <UserContext.Provider value={userInfo}>
          <Sidebar sidebar={sidebar}>
            <Switch>
              <Route
                path="/teams/:teamId/dashboard/sales_sheet/manage/:id"
                component={SalesSheetContainer}
              />
              <Route path="/teams/:teamId/dashboard/sales_sheet" component={DashboardSalesSheet} />
            </Switch>
          </Sidebar>
        </UserContext.Provider>
      </Switch>
    </Router>
  );
};

DashboardSalesSheetRouter.propTypes = {
  userInfo: PropTypes.object,
  sidebar: PropTypes.object,
};

export default DashboardSalesSheetRouter;
