import React from 'react';
import classNames from 'classnames';
import LoadingGif from 'images/icons/loading.svg';
import SmallLoadingGif from 'images/smallLoading.gif';
import PropTypes from 'prop-types';

export default function Loading({ className, fullScreen, isChild, small, title = 'Loading' }) {
  return (
    <div
      className={classNames(fullScreen && `${isChild ? 'h-100' : 'vh-100'} dt w-100`, className)}
    >
      <div className={fullScreen ? 'dtc v-mid h-100' : ''}>
        <img
          src={small ? SmallLoadingGif : LoadingGif}
          alt="Loading"
          title={title}
          className={fullScreen ? 'dtc center' : ''}
        />
      </div>
    </div>
  );
}

Loading.propTypes = {
  fullScreen: PropTypes.bool,
  className: PropTypes.string,
  isChild: PropTypes.bool,
  small: PropTypes.bool,
};

Loading.defaultProps = {
  fullScreen: false,
  className: '',
};
