import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';
import { debounce, isEmpty } from 'lodash';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';

import ConnectView from './ConnectView';
import { EIGHTEEN_MONTHS_AGO, SORT_OPTIONS, TOUR_STEPS } from './constants';

const ALL = 'all';
const DESC = 'desc';
const ASC = 'asc';
const querystring = require('querystring');
const actor = 'publisher';

import {
  createBuyerProfile,
  getElasticsearchKey,
  getTeam,
  sendConnectMessage,
  setViewedConnectTour,
} from 'shared/api';

const defaultTourState = {
  processed: false,
  tourOne: false,
  tourTwo: false,
};

const Connect = props => {
  const { teamId, teamHashedId, history, completedTours, hasConnectProFeatures, hasSellerProfile } =
    props;
  const [queryState, setQueryState] = useState(defaultState);
  const [query, setQuery] = useState(null);
  const [buyerProfile, setBuyerProfile] = useState(null);
  const [sellerProfile, setSellerProfile] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [fetchingNewKey, setFetchingNewKey] = useState(false);
  const [resultListHasLoaded, setResultListHasLoaded] = useState(false);
  const [shouldAutoFetchKey, setShouldAutoFetchKey] = useState(true);
  const [sentPodcastIds, setSentPodcastIds] = useState([]);
  const [ownedPodcastIds, setOwnedPodcastIds] = useState([]);
  const [relatedPodcast, setRelatedPodcast] = useState(null);

  const fetchNewKey = async teamId => {
    setFetchingNewKey(true);
    try {
      const res = await getElasticsearchKey({ teamId });
      setApiKey(res.data.apiKey);
      setFetchingNewKey(false);
      setShouldAutoFetchKey(true);
    } catch (error) {
      setFetchingNewKey(false);
      setShouldAutoFetchKey(false);
    }
  };

  const fetchTeam = async () => {
    try {
      const res = await getTeam({ id: teamHashedId, teamId });
      const buyerProf = res.data.buyerProfile || {};
      setBuyerProfile(buyerProf);
      setSentPodcastIds(buyerProf.sentPodcastIds || []);
      setOwnedPodcastIds(buyerProf.ownedPodcastIds || []);
      const sellerProf = res.data.sellerProfile || {};
      setSellerProfile(sellerProf);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setViewedConnectTour({ teamId, id: teamHashedId, tour: 1 });
    setViewedConnectTour({ teamId, id: teamHashedId, tour: 2 });
    fetchTeam();
    fetchNewKey(teamId);
    setQuery(queryGenerator(queryState));
  }, []);

  const queryGenerator = obj => {
    const query = {
      track_total_hits: true,
      query: {
        bool: {
          must: [],
          must_not: [],
        },
      },
    };
    // setup
    const sort = [];
    const verified = {
      term: {
        has_seller_profile: true,
      },
    };
    const quality = {
      range: {
        last_published_ts: {
          gte: EIGHTEEN_MONTHS_AGO,
        },
      },
    };

    const textSearch = ['cross_fields', 'phrase', 'phrase_prefix'].map(qType => {
      return {
        multi_match: {
          query: obj.textSearch,
          fields: ['title^10', 'description^2', 'publisher^10', 'genres^5'],
          type: qType,
          operator: 'and',
        },
      };
    });

    const firstRelated = {
      terms: {
        first_degree_related_podcasts: [`${obj.relatedPodcast && obj.relatedPodcast.id}`],
      },
    };
    const secondRelated = {
      terms: {
        second_degree_related_podcasts: [`${obj.relatedPodcast && obj.relatedPodcast.id}`],
      },
    };
    const myPods = { ids: { values: ownedPodcastIds } };

    const genres = {
      term: {
        'genres.keyword': obj.genres,
      },
    };

    const rangeFilter = (key, value, def) => {
      const filt = {
        range: {
          [key]: {
            gte: value.start,
            lte: value.end,
            boost: 2,
          },
        },
      };

      if (value.end === def.end) {
        delete filt.range[key].lte;
      }
      return filt;
    };

    const genresAgg = {
      'genres.keyword': {
        terms: {
          field: 'genres.keyword',
          size: 200,
          order: {
            _term: ASC,
          },
        },
      },
    };

    // sort
    if (obj.sortOption && obj.sortOption.value) {
      const opt = obj.sortOption.value;
      let whichEstimate;
      let sortScript;
      switch (opt) {
        case 'downloads':
          whichEstimate =
            obj.sortDirection === ASC
              ? 'estimated_min_thirty_day_downloads'
              : 'estimated_max_thirty_day_downloads';
          sortScript = {
            _script: {
              type: 'number',
              script: {
                lang: 'painless',
                inline: `doc['average_thirty_day_downloads'].size() != 0 ? doc['average_thirty_day_downloads'].value : (doc['${whichEstimate}'].size() != 0 ? doc['${whichEstimate}'].value : 0)`,
              },
              order: obj.sortDirection,
            },
          };
          sort.push(sortScript);
          break;
        case 'reach':
          whichEstimate =
            obj.sortDirection === ASC
              ? 'estimated_min_thirty_day_reach'
              : 'estimated_max_thirty_day_reach';
          sortScript = {
            _script: {
              type: 'number',
              script: {
                lang: 'painless',
                inline: `doc['average_thirty_day_reach'].size() != 0 ? doc['average_thirty_day_reach'].value : (doc['${whichEstimate}'].size() != 0 ? doc['${whichEstimate}'].value : 0)`,
              },
              order: obj.sortDirection,
            },
          };
          sort.push(sortScript);
          break;
        default:
          sort.push({ [obj.sortOption.value]: obj.sortDirection });
          break;
      }
    } else if (!obj.relatedPodcast) {
      sort.push({ _score: DESC });
    }
    sort.push({ review_count: obj.sortDirection });

    query.sort = sort;

    // relatedPod
    if (obj.relatedPodcast) {
      query.query.bool.minimum_should_match = 1;
      query.query.bool.should = [firstRelated, secondRelated];
    }

    // textSearch
    if (obj.textSearch) {
      query.query.bool.must.push(textSearch);
    }

    // quality
    if (obj.quality) {
      query.query.bool.must.push(quality);
    }

    // myPodcasts
    if (obj.myPodcasts) {
      query.query.bool.must.push(myPods);
    } else if (ownedPodcastIds.length) {
      query.query.bool.must_not.push(myPods);
    }

    // verified
    if (obj.verified) {
      query.query.bool.must.push(verified);
    }

    // genres
    if (obj.genres) {
      query.query.bool.must.push(genres);
      query.aggs = genresAgg;
    }

    // review count
    if (obj.reviewCount && !!obj.reviewCount.end) {
      query.query.bool.must.push(
        rangeFilter('review_count', obj.reviewCount, defaultState.reviewCount),
      );
    }

    // rating count
    if (obj.ratingCount && !!obj.ratingCount.end) {
      query.query.bool.must.push(
        rangeFilter('rating_count', obj.ratingCount, defaultState.ratingCount),
      );
    }

    // rating average
    if (
      obj.ratingAverage &&
      !!obj.ratingAverage.end &&
      !(obj.ratingAverage.start === 0 && obj.ratingAverage.end === 5)
    ) {
      query.query.bool.must.push(
        rangeFilter('rating_average', obj.ratingAverage, defaultState.ratingAverage),
      );
    }

    // reach
    const reachActive =
      obj.reach &&
      (obj.reach.end !== defaultState.reach.end || obj.reach.start !== defaultState.reach.start);

    if (reachActive) {
      if (!query.query.bool.should) {
        query.query.bool.should = [];
      }
      query.query.bool.should.push({
        bool: {
          must: [rangeFilter('average_thirty_day_reach', obj.reach, defaultState.reach)],
        },
      });
      query.query.bool.should.push({
        bool: {
          should: [
            rangeFilter('estimated_min_thirty_day_reach', obj.reach, defaultState.reach),
            rangeFilter('estimated_max_thirty_day_reach', obj.reach, defaultState.reach),
          ],
          minimum_should_match: 1,
        },
      });
      if (query.query.bool.minimum_should_match) {
        query.query.bool.minimum_should_match = 2;
      } else {
        query.query.bool.minimum_should_match = 1;
      }
    }

    // downloads
    const downloadsActive =
      obj.downloads &&
      (obj.downloads.end !== defaultState.downloads.end ||
        obj.downloads.start !== defaultState.downloads.start);

    if (downloadsActive) {
      if (!query.query.bool.should) {
        query.query.bool.should = [];
      }
      query.query.bool.should.push({
        bool: {
          must: [
            rangeFilter('average_thirty_day_downloads', obj.downloads, defaultState.downloads),
          ],
        },
      });
      query.query.bool.should.push({
        bool: {
          should: [
            rangeFilter(
              'estimated_min_thirty_day_downloads',
              obj.downloads,
              defaultState.downloads,
            ),
            rangeFilter(
              'estimated_max_thirty_day_downloads',
              obj.downloads,
              defaultState.downloads,
            ),
          ],
          minimum_should_match: 1,
        },
      });
      if (query.query.bool.minimum_should_match) {
        query.query.bool.minimum_should_match = 2;
      } else {
        query.query.bool.minimum_should_match = 1;
      }
    }
    return query;
  };

  const dispatchStateChange = debounce(payload => {
    const newState = stateReducer(queryState, payload);
    const newQuery = queryGenerator(newState);
    const dashboard = 'dashboard';
    setQuery(newQuery);
    setQueryState(newState);
    // history.push({
    //   pathname: `/teams/${teamId}/${dashboard}/connect`,
    //   search: `?${querystring.encode(newState)}`
    // })
  }, 300);

  if (buyerProfile === null || completedTours === null || sellerProfile === null) {
    return <Loading fullScreen />;
  }

  return (
    <ConnectView
      {...props}
      buyerProfile={buyerProfile}
      sellerProfile={sellerProfile}
      filterOptions={queryState}
      fetchTeam={fetchTeam}
      query={query}
      updateState={dispatchStateChange}
      apiKey={apiKey}
      defaultTourState={completedTours}
      setResultListHasLoaded={setResultListHasLoaded}
      fetchNewKey={fetchNewKey}
      resultListHasLoaded={resultListHasLoaded}
      fetchingNewKey={fetchingNewKey}
      shouldAutoFetchKey={shouldAutoFetchKey}
      relatedPodcast={relatedPodcast}
      setRelatedPodcast={setRelatedPodcast}
      sentPodcastIds={sentPodcastIds}
      setSentPodcastIds={setSentPodcastIds}
      hasBuyerProfile={!isEmpty(buyerProfile)}
      has={!isEmpty(buyerProfile)}
      setBuyerProfile={setBuyerProfile}
    />
  );
};

const WrappedConnect = withRouter(Connect);

const ConnectRouter = props => {
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setCollapsed(true);
    });
  });

  const redirectToWizard = () => {
    window.location = props.wizardUrl;
  };

  return (
    <Sidebar sidebar={props.sidebar} collapsed={collapsed}>
      <Router>
        <Route exact path={`/teams/${props.teamId}/dashboard/connect`}>
          <WrappedConnect {...props} />
        </Route>
        <Route exact path={`/teams/${props.teamId}/dashboard/connect/profile`}>
          <WrappedConnect {...props} profileConfigIsOpen />
        </Route>
      </Router>
    </Sidebar>
  );
};

export default ConnectRouter;

const rangeOption = (start, end) => {
  return {
    start,
    end,
  };
};

const defaultState = {
  textSearch: '',
  sortOption: {
    value: 'trending_rank',
    label: 'Trending',
    disabled: false,
  },
  sortDirection: ASC,
  reviewCount: rangeOption(0, 1000),
  ratingCount: rangeOption(0, 5000),
  ratingAverage: rangeOption(0, 5),
  downloads: rangeOption(0, 200000),
  reach: rangeOption(0, 50000),
  verified: null,
  myPodcasts: false,
  SearchResult: 0,
  quality: true,
  genres: '',
  relatedPodcast: null,
  fields: [],
};

const duplicate = obj => {
  return JSON.parse(JSON.stringify(obj));
};

const stateReducer = (currentState, payload) => {
  let newState = duplicate(currentState);
  switch (payload.action) {
    case 'CLEAR':
      newState = duplicate(defaultState);
      break;
    case 'SET':
      switch (payload.key) {
        case 'textSearch':
          newState.textSearch = payload.value;
          if (newState.sortOption !== null && payload.value !== '') {
            newState = stateReducer(newState, {
              action: 'SET',
              key: 'sortOption',
              value: null,
            });
            newState = stateReducer(newState, {
              action: 'SET',
              key: 'verified',
              value: false,
            });
          }
          break;
        case 'sortOption':
          let value = payload.value;
          if (value === null) {
            value = {
              value: null,
              label: 'Sort By:',
            };
          }
          newState.sortOption = value;
          if (['trending_rank', 'top200_rank'].includes(value.value)) {
            newState = stateReducer(newState, {
              action: 'SET',
              key: 'sortDirection',
              value: ASC,
            });
          } else {
            newState = stateReducer(newState, {
              action: 'SET',
              key: 'sortDirection',
              value: DESC,
            });
          }
          break;
        case 'relatedPodcast':
          newState.relatedPodcast = payload.value;
          newState = stateReducer(newState, {
            action: 'SET',
            key: 'sortOption',
            value: null,
          });
          newState = stateReducer(newState, {
            action: 'SET',
            key: 'textSearch',
            value: '',
          });
          newState = stateReducer(newState, {
            action: 'SET',
            key: 'verified',
            value: false,
          });
          break;
        case 'myPodcasts':
          newState.myPodcasts = payload.value;
          newState = stateReducer(newState, {
            action: 'SET',
            key: 'verified',
            value: false,
          });
          break;
        case 'verified':
        case 'quality':
        case 'genres':
        case 'SearchResult':
        case 'sortDirection':
        case 'reviewCount':
        case 'ratingCount':
        case 'ratingAverage':
        case 'reach':
        case 'downloads':
        default:
          newState[payload.key] = payload.value;
          break;
      }
  }
  return newState;
};
