import React from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

function Table({
  columns,
  data,
  onRowClick,
  className,
  filterable,
  showPagination,
  pageSize,
  showDetailButton,
  rowHeight,
  defaultSorted,
  onDetailClick,
}) {
  const tableStyle = !!className ? className : `w-100 f5 ${!!onRowClick && '-highlight pointer'}`;

  if (showDetailButton) {
    columns.push({
      Header: '',
      sortable: false,
      maxWidth: 75,
      Cell: row => (
        <div
          onClick={!!onDetailClick ? () => onDetailClick(row.original) : null}
          className="tr dim blue pointer f6 mr1 ml2"
        >
          Details →
        </div>
      ),
    });
  }

  return (
    <ReactTable
      getTrProps={(state, row) => ({
        onClick: !!onRowClick ? () => onRowClick(row) : null,
        style: {
          display: 'flex',
          alignItems: 'center',
          minHeight: rowHeight,
          background: row.viewIndex % 2 ? '#F5FAFC' : '#FFFFFF',
        },
      })}
      data={data}
      pageSize={pageSize ? pageSize : data.length}
      columns={columns}
      showPagination={showPagination}
      filterable={filterable}
      className={tableStyle}
      defaultSorted={defaultSorted}
    />
  );
}

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  className: PropTypes.string,
  rowHeight: PropTypes.string,
  showPagination: PropTypes.bool,
  filterable: PropTypes.bool,
  showDetailButton: PropTypes.bool,
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  defaultSorted: PropTypes.array,
  onDetailClick: PropTypes.func,
};

Table.defaultProps = {
  showPagination: false,
  filterable: false,
  showDetailButton: false,
  pageSize: null,
  onDetailClick: null,
  defaultSorted: [],
  rowHeight: '3.0rem',
};

export default Table;
