import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';

import { constructReactSelectOptionsFromStringArray } from 'shared/helpers';
import DataChartBase from 'shared/DataChartBase';
import PieChart from 'shared/charts/PieChart';
import Loading from 'shared/Loading';

function formatData(data, selectedMetricValue) {
  const formattedData = data.map(row => {
    return [row['playerName'], row[selectedMetricValue]];
  });

  return [['Player', 'Trackable']].concat(formattedData);
}

function PlayerChart({
  data,
  inlineStyle,
  metadata,
  selectedMetric,
  handleSelectMetric,
  isLoading,
}) {
  const [displayOption, setDisplayOption] = useState(0);
  if (!data || !metadata) {
    if (isLoading) {
      return (
        <DataChartBase inlineStyle={inlineStyle} title="Players">
          <div style={{ height: '340px' }} className="flex justify-center items-center">
            <Loading title="Loading player data" />
          </div>
        </DataChartBase>
      );
    } else {
      return (
        <div className="ma3" data-testid="players-fetch-error">
          Failed to load Player Data. Please reload the page to try again.
        </div>
      );
    }
  }

  let formattedData;
  if (data) {
    formattedData = formatData(data, selectedMetric.value);
  }

  return (
    <DataChartBase
      inlineStyle={inlineStyle}
      title="Players"
      selectedMetric={selectedMetric}
      metricOptions={constructReactSelectOptionsFromStringArray(metadata.availableMetrics)}
      handleSelectMetric={handleSelectMetric}
      isLoading={isLoading}
    >
      {formattedData.length > 1 ? (
        <React.Fragment>
          <div
            className="mb2 dib bg-light-gray br3"
            style={{ padding: '5px', width: 'max-content' }}
          >
            <h4
              onClick={() => setDisplayOption(0)}
              className={`pointer dim b fw1000 dib black pv2 ph3 mv0 ${
                displayOption === 0 ? 'bg-white br3' : ''
              }`}
            >
              Pie Chart
            </h4>
            <h4
              onClick={() => setDisplayOption(1)}
              className={`pointer dim b fw1000 dib black pv2 ph3 mv0 ${
                displayOption === 1 ? 'bg-white br3' : ''
              }`}
            >
              Table
            </h4>
          </div>
          <div className={displayOption === 1 ? 'dn' : ''}>
            <PieChart
              // The PieChart needs a "category" (e.g. trackable/spotify/apple) :/
              data={{ trackable: formattedData }}
              showOptions={false}
            />
          </div>
          <div className={displayOption === 0 ? 'dn' : ''} style={{ height: '340px' }}>
            <ReactTable
              showPageSizeOptions={false}
              showPageJump={false}
              pageSize={8}
              data={data}
              columns={[
                {
                  Header: 'Player',
                  accessor: 'playerName',
                  className: 'ph1',
                },
                {
                  Header: selectedMetric.label,
                  accessor: selectedMetric.value,
                  className: 'tr ph1',
                },
                {
                  Header: 'Percentage',
                  accessor: 'total',
                  className: 'tr ph1',
                  Cell: row => (
                    <div>
                      {((row.original[selectedMetric.value] / metadata.total) * 100).toFixed(2)}%
                    </div>
                  ),
                },
              ]}
              className="-highlight"
            />
          </div>
        </React.Fragment>
      ) : (
        <div className="justify-center items-center flex flex-column" style={{ height: '340px' }}>
          <h2 className="mt0 mb2">No data for selection!</h2>
          <h4 className="mv0">Maybe try another date range with the date selector?</h4>
        </div>
      )}
    </DataChartBase>
  );
}

PlayerChart.propTypes = {
  data: PropTypes.array,
  inlineStyle: PropTypes.object,
};

PlayerChart.defaultProps = {
  data: null,
};

export default PlayerChart;
