import React from 'react';

const AGGREGATION_WINDOW = {
  day: 'day',
  week: 'week',
};

// TODO (12/10/2021)
// The box selectors should be it's own generic
// component that exists in the shared folder.

const AggregationWindowSelector = ({ aggregationWindow, onChange }) => {
  return (
    <div className="flex flex-row">
      <div
        className={
          'pointer bw1 ba w4 ph2 pv2 f5 tc bg-white dib' +
          (aggregationWindow === AGGREGATION_WINDOW.day
            ? ' white bg-blue b--blue'
            : ' mid-gray b--moon-gray bg-none')
        }
        onClick={() => onChange(AGGREGATION_WINDOW.day)}
      >
        Day
      </div>
      <div
        className={
          'pointer bw1 bb bt br w4 bg-white ph2 pv2 f5 tc dib' +
          (aggregationWindow === AGGREGATION_WINDOW.week
            ? ' white bg-blue b--blue'
            : ' mid-gray b--moon-gray bg-none')
        }
        onClick={() => onChange(AGGREGATION_WINDOW.week)}
      >
        Week
      </div>
    </div>
  );
};

export default AggregationWindowSelector;
