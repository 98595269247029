import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import WarningSVG from 'images/icons/error.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { onClickHandler } from 'shared/a11y/lib';
import Button from 'shared/Button';
import PaginatedTable from 'shared/PaginatedTable';
import { withUser } from 'shared/UserContext';

import 'react-table/react-table.css';

function formatNumber(number) {
  return number !== null ? number.toLocaleString() : 'N/A';
}

const AD_TYPES = {
  drop_in: 'Episode Drop',
  preroll: 'Preroll',
  midroll: 'Midroll',
  guest_spot: 'Guest Spot',
  trailer: 'Trailer',
};

const PromoTable = ({
  campaignId,
  teamId,
  promos,
  selectedColumns,
  openErrorExplainer,
  editPromo,
  canEditPromos,
  archivePromo,
  page,
  setPage,
  sortBy,
  setSortBy,
  setSortDesc,
  total,
  pageSize,
  loading,
}) => {
  const rowLink = `/teams/${teamId}/dashboard/promos/${campaignId}/analytics`;

  const [displayColumns, setDisplayColumns] = useState([]);
  const [sorted, setSorted] = useState([{ id: 'startedAt', desc: true }]);

  const columns = {
    promo_name: {
      Header: 'Promo Name',
      accessor: 'name',
      id: 'promo_name',
      sortable: false,
      Cell: row =>
        row.original.status.value === 'ERROR' ? (
          <div className="flex flex-row items-center db pv2 ph1 mid-gray">
            <img
              data-tip="This promo has an error. Click for more detail."
              src={WarningSVG}
              className="h1 w-auto mr2 dim"
              {...onClickHandler(() => openErrorExplainer(row.original))}
              alt="promo-error"
            />
            <div>{row.original.name}</div>
          </div>
        ) : (
          <Link
            className="flex flex-row items-center db pv2 ph1 no-underline mid-gray"
            to={`${rowLink}/${row.original.audioAdPlacementId}`}
          >
            {row.original.name}
          </Link>
        ),
    },
    publisher_name: {
      Header: 'Publisher',
      accessor: 'sellerTeam',
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.sellerTeam ? row.original.sellerTeam : row.original.publisherName}
        </Link>
      ),
    },
    promoted_on_podcast: {
      Header: 'Promoted On',
      accessor: 'sellerPodcast',
      minWidth: 150,
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.sellerPodcast ? (
            <div className="flex items-center">
              <div className="mr2" style={{ width: '15%' }}>
                <img
                  src={row.original.sellerPodcast.displayImageUrl}
                  className="center fl br2"
                  alt=""
                />
              </div>
              <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
            </div>
          ) : (
            <div className="tc">-</div>
          )}
        </Link>
      ),
    },
    episode: {
      Header: 'Episode',
      accessor: 'sellerTeamEpisode',
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.episode ? row.original.episode.shortTitle : <div className="tc">-</div>}
        </Link>
      ),
    },
    type: {
      Header: 'Type',
      accessor: 'isInternal',
      maxWidth: 75,
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.isInternal ? 'Internal' : 'External'}
        </Link>
      ),
    },
    ad_type: {
      Header: 'Ad Type',
      accessor: 'adType',
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.adType ? AD_TYPES[row.original.adType] : ''}
        </Link>
      ),
    },
    air_date: {
      Header: 'Air Date',
      accessor: 'startedAt',
      id: 'startedAt',
      className: 'tc',
      sortable: true,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.startedAt ? moment(row.original.startedAt).format('MM/DD/YYYY') : null}
        </Link>
      ),
    },
    impressions: {
      Header: 'Impressions',
      id: 'impressions',
      accessor: 'impressions.total',
      className: 'tc',
      sortable: true,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.impressions ? formatNumber(row.original.impressions.total) : null}
        </Link>
      ),
    },
    total_conversions: {
      Header: 'Converted Devices',
      accessor: 'uniqueDownloads.total',
      className: 'tc',
      sortable: true,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {formatNumber(row.original.uniqueDownloads.total)}
        </Link>
      ),
    },
    total_new_conversions: {
      Header: 'New Converted Devices',
      accessor: 'newDownloads.total',
      className: 'tc',
      sortable: true,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {formatNumber(row.original.newDownloads.total)}
        </Link>
      ),
    },
    conversion_rate: {
      Header: 'Conversions Rate',
      accessor: 'uniqueDownloads.responseRate',
      className: 'tc',
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.uniqueDownloads.responseRate
            ? `${(row.original.uniqueDownloads.responseRate * 100).toFixed(3)}%`
            : null}
        </Link>
      ),
    },
    new_conversion_rate: {
      Header: 'New Conversions Rate',
      accessor: 'newDownloads.responseRate',
      className: 'tc',
      sortable: false,
      Cell: row => (
        <Link
          className="db pv2 ph1 no-underline mid-gray"
          to={`${rowLink}/${row.original.audioAdPlacementId}`}
        >
          {row.original.newDownloads.responseRate
            ? `${(row.original.newDownloads.responseRate * 100).toFixed(3)}%`
            : null}
        </Link>
      ),
    },
    cost_per_download: {
      Header: 'Cost Per Download',
      className: 'tc',
      sortable: false,
      Cell: row =>
        row.original.totalCost ? (
          <Link
            className="db pv2 ph1 no-underline mid-gray"
            to={`${rowLink}/${row.original.audioAdPlacementId}`}
          >
            {row.original.uniqueDownloads && row.original.uniqueDownloads.total > 0
              ? `$${(row.original.totalCost / row.original.uniqueDownloads.total).toFixed(2)}`
              : '-'}
          </Link>
        ) : (
          <Button className="f6 mw4 center" size="small" onClick={() => editPromo(row.original)}>
            Add cost
          </Button>
        ),
    },
    cost_per_new_download: {
      Header: 'Cost Per New Download',
      className: 'tc',
      sortable: false,
      Cell: row =>
        row.original.totalCost ? (
          <Link
            className="db pv2 ph1 no-underline mid-gray"
            to={`${rowLink}/${row.original.audioAdPlacementId}`}
          >
            {row.original.newDownloads && row.original.newDownloads.total > 0
              ? `$${(row.original.totalCost / row.original.newDownloads.total).toFixed(2)}`
              : '-'}
          </Link>
        ) : (
          <Button className="f6 mw4 center" size="small" onClick={() => editPromo(row.original)}>
            Add cost
          </Button>
        ),
    },
  };

  if (canEditPromos) {
    columns.actions = {
      Header: 'Actions',
      id: 'actions',
      sortable: false,
      minWidth: 150,
      className: 'tc',
      Cell: row => (
        <React.Fragment>
          <Button
            inverted
            type="primary"
            className="f7 w3 dib mr2"
            size="small"
            onClick={() => editPromo(row.original)}
          >
            Edit
          </Button>
          <Button
            inverted
            type="destructive"
            className="f7 w3 dib"
            size="small"
            onClick={() => archivePromo(row.original.audioAdPlacementId)}
          >
            Archive
          </Button>
        </React.Fragment>
      ),
    };
  }

  useEffect(() => {
    const newDisplayColumns = Object.keys(columns)
      .filter(key => selectedColumns.includes(key))
      .map(key => columns[key]);
    setDisplayColumns(newDisplayColumns);
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedColumns]);

  return (
    <PaginatedTable
      defaultSorted={[
        {
          id: 'startedAt',
          desc: true,
        },
      ]}
      columns={displayColumns}
      handlePaging={event => setPage(event.selected + 1)}
      handleSort={sort => {
        let thisSortDesc; // Don't confuse with the sortDesc prop

        // Default to 'desc' when sorting by a different column
        if (sortBy !== sort[0].id) {
          thisSortDesc = true;
        } else {
          thisSortDesc = sort[0].desc;
        }

        setSortDesc(thisSortDesc);
        setSortBy(sort[0].id);
        setSorted([{ id: sort[0].id, desc: thisSortDesc }]); // Send to ReactTable's sorted state to keep in sync
      }}
      loading={loading}
      data={promos}
      page={page}
      total={total}
      pageSize={pageSize}
      sorted={sorted}
      rowStyle={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      }}
      tableClassName="w-100 f5 -highlight"
      testId="promo-table"
    />
  );
};

function areEqual(prevProps, nextProps) {
  return (
    JSON.stringify(prevProps.promos) === JSON.stringify(nextProps.promos) &&
    JSON.stringify(prevProps.selectedColumns) === JSON.stringify(nextProps.selectedColumns) &&
    prevProps.loading === nextProps.loading
  );
}

PromoTable.propTypes = {
  campaignId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  promos: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  openErrorExplainer: PropTypes.func.isRequired,
  editPromo: PropTypes.func.isRequired,
  canEditPromos: PropTypes.bool,
};

PromoTable.defaultProps = {
  canEditPromos: false,
};

export default compose(withRouter, withUser)(React.memo(PromoTable, areEqual));
