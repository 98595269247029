import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements, StripeProvider, injectStripe } from 'react-stripe-elements';

import { getSubscriptionSettings, getPlanDetails } from 'shared/api';
import Loading from 'shared/Loading';

import SubscriptionSettings from './components/SubscriptionSettings';
import Sidebar from 'shared/Sidebar';

// Stripe has very specific requirements for what the component hierarchy
// needs to look like in order for Elements to work. The component that
// holds StripeProvider/Elements can't also hold the form itself, so we're
// forced to add another wrapper here.
const StripeSubscriptionSettingsContainer = injectStripe(SubscriptionSettings);
export default class WrappedSubscriptionSettingsContainer extends Component {
  constructor(props) {
    super(props);

    this.loadSubscriptionSettings = this.loadSubscriptionSettings.bind(this);

    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    this.loadSubscriptionSettings();
  }

  loadSubscriptionSettings() {
    if (this.props.teamId) {
      getSubscriptionSettings({ teamId: this.props.teamId }).then(res => {
        // TODO: some of this is not really state.
        const setState = {
          loading: false,
          ...res.data,
        };
        this.setState(setState);
      });
    } else {
      getPlanDetails().then(res => {
        const setState = {
          loading: false,
          ...res.data,
        };
        setState.showPlanChooserOnStartup = true;
        this.setState(setState);
      });
    }
  }

  render() {
    if (this.state.loading) {
      return <Loading fullScreen />;
    }

    const {
      showPlans,
      showCardForm,
      manageBilling,
      canCancel,
      canDowngrade,
      futurePlan,
      sidebar,
      subSidebar,
    } = this.props;

    const {
      apiKey,
      card,
      subscription,
      plans,
      features,
      showPlanChooserOnStartup,
      skipPlanConfirmation,
      canChangePlan,
      hasCardOption,
      invoices,
      percentOff,
      freeTrialLength,
    } = this.state;

    return (
      <Sidebar sidebar={sidebar} subSidebar={subSidebar}>
        <StripeProvider apiKey={apiKey}>
          <Elements>
            <StripeSubscriptionSettingsContainer
              apiKey={apiKey}
              teamId={this.props.teamId}
              userId={this.props.userId}
              card={card}
              subscription={subscription}
              plans={plans}
              features={features}
              showPlanChooserOnStartup={showPlanChooserOnStartup}
              skipPlanConfirmation={skipPlanConfirmation}
              showCardForm={showCardForm}
              canChangePlan={canChangePlan}
              hasCardOption={hasCardOption}
              invoices={invoices}
              percentOff={percentOff}
              freeTrialLength={freeTrialLength}
              canCancel={canCancel}
              showPlans={showPlans}
              manageBilling={manageBilling}
              canDowngrade={canDowngrade}
              futurePlan={futurePlan}
            />
          </Elements>
        </StripeProvider>
      </Sidebar>
    );
  }
}

WrappedSubscriptionSettingsContainer.propTypes = {
  teamId: PropTypes.number,
  userId: PropTypes.number,
  showPlanChooserOnStartup: PropTypes.bool,
  showPlans: PropTypes.bool,
  manageBilling: PropTypes.bool,
  canCancel: PropTypes.bool,
  canDowngrade: PropTypes.bool,
  futurePlan: PropTypes.string,
};

WrappedSubscriptionSettingsContainer.defaultProps = {
  teamId: null,
  userId: null,
  manageBilling: false,
  canCancel: false,
  canDowngrade: true,
  futurePlan: null,
};
