import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'shared/Loading';

export default function FormCloseBox({ children, onClose }) {
  return (
    <div className="ba br2 mt2 b--dotted">
      <div className="relative flex flex-wrap items-center bg-white">
        {children}
        <div className="pointer pr2 pt1 top-0 right-0 absolute f7 lh-copy b">
          <div className="underline-hover f5 dib black pointer tr" onClick={() => onClose()}>
            &times;
          </div>
        </div>
      </div>
    </div>
  );
}

FormCloseBox.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
};
