import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

export default function UpsellDatePicker({ ...props }) {
  const CalendarContainer = ({ className, children, showPopperArrow, arrowProps = {} }) => {
    return (
      <div>
        <div className={className}>
          <div className="react-datepicker__triangle" {...arrowProps} />
          {children}
        </div>
      </div>
    );
  };

  return <DatePicker calendarContainer={CalendarContainer} {...props} />;
}

UpsellDatePicker.propTypes = {
  minDate: PropTypes.instanceOf(Date),
  teamId: PropTypes.string.isRequired,
};

UpsellDatePicker.defaultProps = {
  minDate: null,
};
