import React, { useState, useEffect } from 'react';
import PodcastSwitcher from 'shared/PodcastSwitcher';

const SmartFeedsForm = ({
  teamId,
  podcasts,
  isEditing,
  selectedPodcast: selectedPod,
  feed,
  onSubmit,
  feeds,
}) => {
  const [selectedPodcast, setSelectedPodcast] = useState(selectedPod || null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [data, setData] = useState({
    addTrackablePrefix: feed ? feed.addTrackablePrefix : true,
    sourceUrl: feed && feed.sourceUrl,
    slug: feed && feed.slug,
  });

  const errorStyle = {
    top: '-15px',
  };

  const noPodsLeft =
    feeds && feeds.length && podcasts && podcasts.length && podcasts.length === feeds.length;

  useEffect(() => {
    if (noPodsLeft && !isEditing) {
      setErrorMessage(
        <span className={`f3 fw7`}>
          You've created a SmartFeed for each of your shows. You can't create more than one
          SmartFeed per show.
        </span>,
      );
    }
  }, [noPodsLeft]);

  const handlePodcastSelect = selection => {
    setSelectedPodcast(selection.data);
    const slug = (selection.data && selection.data.slug) || '';
    const sourceUrl = (selection.data && selection.data.feedUrl) || '';
    setChange('sourceUrl', sourceUrl);
    setChange('slug', slug);
  };

  const handleChange = event => {
    const name = event.currentTarget.name;
    const val = event.currentTarget.value;
    setChange(name, val);
  };

  const handleChecked = event => {
    const name = event.currentTarget.name;
    const val = !!event.currentTarget.checked;
    setChange(name, val);
  };

  const setChange = (name, val) => {
    setData(old => ({
      ...old,
      [name]: val,
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!selectedPodcast) {
      setErrorMessage('You must select a podcast');
      return;
    }
    const current = { ...data };
    current.podcastId = podcasts.find(pod => pod[1] === selectedPodcast.slug)[2];
    const requiredFields = ['slug', 'sourceUrl', 'podcastId'];
    const validValue = key => current[key] !== '' && current[key] !== false;
    const incomplete = !requiredFields.every(
      key => Object.keys(current).includes(key) && validValue(key),
    );
    if (incomplete) {
      setErrorMessage('Please fill out the form completely');
      return;
    }
    onSubmit(current);
  };

  const getExcludedOptions = displayAll => {
    if (displayAll) return [];
    return feeds.map(f => f.podcast.id);
  };

  return (
    <div className={`mw6 bg-white pa2 br2`}>
      <form
        className={noPodsLeft && !isEditing ? 'dn' : 'db'}
        onChange={() => setErrorMessage(null)}
        onSubmit={handleSubmit}
      >
        <div>
          <label className={`db lh-copy f5 header-font string optional`}>
            {isEditing ? 'Podcast' : 'Select a Podcast'}
          </label>
          <PodcastSwitcher
            teamId={teamId}
            className="mb2"
            defaultToNone={true}
            includeNone={true}
            includeAll={false}
            defaultToAll={false}
            fetchDefaultOptions={true}
            selectedPodcast={selectedPodcast || null}
            onSelect={handlePodcastSelect}
            loading={false}
            isDisabled={!!isEditing || noPodsLeft}
            excludeOptions={getExcludedOptions(isEditing)}
          />
          <div className={`mb2`}>
            <div className={`form-group string optional smart_feed_slug`}>
              <label
                htmlFor={`smart_feed_slug`}
                className={`db lh-copy f5 header-font string optional`}
              >
                Shortcut{' '}
                {!isEditing && (
                  <span className={`fr f7`} style={{ bottom: '-6px', position: 'relative' }}>
                    3-32 characters
                  </span>
                )}
              </label>
              <input
                disabled={isEditing || noPodsLeft}
                value={data.slug}
                readOnly={isEditing}
                onChange={handleChange}
                name={`slug`}
                type={`text`}
                placeholder={`Appears at the end of the URL, e.g. feeds.chrt.fm/yourpod`}
                className={`pa2 input-reset ba w-100 br2 string optional`}
              />
            </div>
          </div>
          <div className={`mb2`}>
            <div className={`form-group string optional w-100 smart_feed_source_url`}>
              <label
                htmlFor={`smart_feed_source_url`}
                className={`db lh-copy f5 header-font string optional`}
              >
                Original feed URL
              </label>
              <input
                disabled={noPodsLeft && !isEditing}
                onChange={handleChange}
                name={`sourceUrl`}
                type={`text`}
                placeholder={`https://your.feed`}
                value={data.sourceUrl}
                className={`pa2 input-reset ba w-100 br2 string optional`}
              />
            </div>
          </div>
          <div className={`pa0 ma0 lh-copy f5 pointer`}>
            <label
              className={`boolean optional pa0 ma0 lh-copy`}
              htmlFor={`smart_feed_add_trackable_prefix`}
            >
              <input
                disabled={noPodsLeft && !isEditing}
                onChange={handleChecked}
                className={`mr1 boolean optional`}
                type={`checkbox`}
                defaultChecked={data.addTrackablePrefix ? 'checked' : ''}
                name={`addTrackablePrefix`}
              />
              Add{' '}
              <a
                className={`link blue`}
                target={`_blank`}
                href={`/teams/${teamId}/dashboard/integrations`}
              >
                Trackable
              </a>{' '}
              prefix to your episode URLs
            </label>
          </div>
          <div className={`dib mt3`}>
            <input
              type={`submit`}
              name={`commit`}
              className={`pa2 dim bg-blue input-reset white br2 bn`}
            />
          </div>
        </div>
      </form>
      {errorMessage ? (
        <span
          style={errorStyle}
          className={`f7 ${noPodsLeft && !isEditing ? 'db pa3' : 'fr relative'} red `}
        >
          {errorMessage}
        </span>
      ) : null}
    </div>
  );
};

export default SmartFeedsForm;
