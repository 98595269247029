import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-google-charts';

import Loading from 'shared/Loading';
import DataChartBase from 'shared/DataChartBase';

function BarChart({ data, subtitle, isLoading, inlineStyle }) {
  if (!data) {
    return (
      <DataChartBase inlineStyle={inlineStyle} title={title} subtitle={subtitle}>
        <div style={{ height: '500px' }} className="flex justify-center items-center">
          <Loading />
        </div>
      </DataChartBase>
    );
  }

  return (
    <DataChartBase inlineStyle={inlineStyle} subtitle={subtitle} isLoading={isLoading}>
      <div className="relative flex justify-between flex-row mt2">
        <div className="b f7 mid-gray"></div>
      </div>
      <Chart
        height="100%"
        chartType="BarChart"
        loader={<div>One moment please...</div>}
        data={data}
        options={{
          legend: { position: 'none' },
          vAxis: {
            textPosition: 'in',
            textStyle: { color: 'black', fontWeight: 1000, auraColor: 'none' },
          },
          hAxis: {
            format: "#'%'",
            minorGridlines: { count: 0 },
            viewWindowMode: 'maximized',
          },
          chartArea: { width: '100%', height: '90%', top: '-5', bottom: 20, right: 10 },
          focusTarget: 'category',
        }}
      />
    </DataChartBase>
  );
}

BarChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
};

BarChart.defaultProps = {
  title: null,
  data: null,
  subtitle: null,
  isLoading: false,
};

export default BarChart;
