import React from 'react';
import PropTypes from 'prop-types';

const SoldPromos = ({ viewingPromoTable, match }) => {
  const destinationURL = `/teams/${match.params.teamId}/dashboard/adops${
    viewingPromoTable ? '/manage' : '/requests'
  }`;
  window.location.assign(destinationURL);

  return (
    <div className="tc ph4 pv5">
      <div className="f3 force-inter mb3">This page has moved!</div>
      <div className="f5 force-inter mb1">You are being redirected to the adops page.</div>
      <a href={destinationURL} className="blue link pointer">
        Click here if you are not automatically redirected.
      </a>
    </div>
  );
};

SoldPromos.propTypes = {
  viewingPromoTable: PropTypes.bool.isRequired,
};

export default SoldPromos;
