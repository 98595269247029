import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import StarRatings from 'shared/StarRatings';
import SmallLoading from 'shared/SmallLoading';

export default function DashboardHeaderStat({
  label,
  first,
  last,
  url,
  stat,
  percentGrowth,
  numColumns,
  useStarRating,
  tooltip,
  useLoadingIndicator,
  small,
  defaultValue,
  labelUrl,
}) {
  let widthClass = null;
  switch (numColumns) {
    case 6:
      widthClass = 'w-auto-ns mr3';
      break;
    case 5:
      widthClass = 'w-20-ns';
      break;
    case 4:
      widthClass = 'w-25-ns';
      break;
    case 3:
      widthClass = 'w-third-ns';
      break;
    case 2:
      widthClass = 'w-50-ns';
      break;
    default:
      widthClass = 'w-25-ns';
  }
  let displayStat = useLoadingIndicator ? <SmallLoading /> : defaultValue;
  if (useStarRating && stat) {
    displayStat = <StarRatings rating={stat} className="tc" />;
  } else if (stat || stat === 0) {
    displayStat = stat.toLocaleString();
  }

  return (
    <div className={`${widthClass} tr-ns tc mb3 mb0-ns flex flex-column`}>
      <ReactTooltip />
      <div
        className={`f3-ns f4 ${small ? 'f6-ns f6' : ''} b dark-blue ${first ? 'tl-ns' : ''} ${
          last ? 'tr-ns' : ''
        } tc`}
      >
        <span className="dib v-mid">
          {url ? (
            <a href={url} className="link dark-blue no-underline">
              {displayStat}
            </a>
          ) : (
            displayStat
          )}
        </span>
        {percentGrowth && (
          <span className={percentGrowth > 0 ? 'green f6 ml2 dib v-mid' : 'red f6 ml2 dib v-mid'}>
            {percentGrowth > 0 ? (
              <span>&#9650;</span>
            ) : percentGrowth == 0 ? (
              <span>&#9654;</span>
            ) : (
              <span>&#9660;</span>
            )}
            {Math.abs(Math.round(percentGrowth))}%
          </span>
        )}
      </div>
      <div className={`f6 gray ttu tracked ${first ? 'tl-ns' : ''} ${last ? 'tr-ns' : ''} tc mt1`}>
        {labelUrl ? (
          <a href={labelUrl} className="link dark-blue gray">
            {label}
          </a>
        ) : (
          label
        )}
      </div>
      {tooltip && (
        <div
          className={`f7 b gray ttu tracked ${first ? 'tl-ns' : ''} ${last ? 'tr-ns' : ''} tc mt2`}
        >
          <a data-tip={tooltip} className="ph1 ba b--gray br-100 tc pointer">
            &#63;
          </a>
        </div>
      )}
    </div>
  );
}

DashboardHeaderStat.propTypes = {
  label: PropTypes.string.isRequired,
  numColumns: PropTypes.number.isRequired,
  first: PropTypes.bool,
  last: PropTypes.bool,
  useStarRating: PropTypes.bool,
  url: PropTypes.string,
  stat: PropTypes.any,
  percentGrowth: PropTypes.number,
  tooltip: PropTypes.string,
  useLoadingIndicator: PropTypes.bool,
  small: PropTypes.bool,
  defaultValue: PropTypes.any,
  labelUrl: PropTypes.string,
};

DashboardHeaderStat.defaultProps = {
  first: false,
  last: false,
  url: null,
  stat: null,
  percentGrowth: null,
  useStarRating: false,
  tooltip: null,
  useLoadingIndicator: false,
  small: false,
  defaultValue: 'Unknown',
  labelUrl: null,
};
