import React from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import { onClickHandler } from 'shared/a11y/lib';
import Button from 'shared/Button';
import NewStatusIndicator from 'shared/NewStatusIndicator';

function CellWrapper({ className, canEditPromos, to, children }) {
  return canEditPromos ? (
    <Link className={`no-underline color-inherit ${className}`} to={to}>
      {children}
    </Link>
  ) : (
    <div className={className}>{children}</div>
  );
}

const getPromoTableColumns = (
  canEditPromos,
  handleInstallPixelClick,
  handleEditorClick,
  handleArchiveClick,
  handleResolveClick,
  handleFillEpisodeClick,
  audioAdCampaignId,
  teamId,
) => {
  const baseUrl = `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/manage`;

  const columns = [
    {
      sortable: false,
      Header: 'Status',
      id: 'status',
      accessor: 'status.value',
      className: 'tc ttc',
      width: 75,
      Cell: row => (
        <div>
          <NewStatusIndicator
            status={row.original.status.value}
            text={row.original.status.value.toLowerCase()}
            tooltip={row.original.status.description}
          />
          {row.original.status.value === 'ERROR' && (
            <div
              {...onClickHandler(() => handleResolveClick(row.original))}
              className="mt1 blue pointer underline dim"
            >
              Resolve
            </div>
          )}
        </div>
      ),
    },
    {
      Header: 'Promo Name',
      accessor: 'name',
      Cell: row => (
        <CellWrapper
          canEditPromos={canEditPromos}
          className="inherit no-underline"
          to={`${baseUrl}/${row.original.audioAdPlacementId}`}
        >
          {row.original.name}
        </CellWrapper>
      ),
    },
    {
      Header: 'Publisher',
      accessor: 'sellerTeam',
      Cell: row => (
        <CellWrapper
          canEditPromos={canEditPromos}
          className="inherit no-underline"
          to={`${baseUrl}/${row.original.audioAdPlacementId}`}
        >
          {row.original.sellerTeam ? row.original.sellerTeam : '-'}
        </CellWrapper>
      ),
    },
    {
      Header: 'Promoted On',
      id: 'sellerPodcast',
      accessor: 'sellerPodcast.shortTitle',
      minWidth: 150,
      Cell: row => (
        <CellWrapper
          canEditPromos={canEditPromos}
          className="inherit no-underline"
          to={`${baseUrl}/${row.original.audioAdPlacementId}`}
        >
          {row.original.sellerPodcast ? (
            <div className="flex items-center">
              <div className="mr2" style={{ width: '15%' }}>
                <img
                  src={row.original.sellerPodcast.displayImageUrl}
                  className="center fl br2"
                  alt=""
                />
              </div>
              <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
            </div>
          ) : (
            <div className="tc">-</div>
          )}
        </CellWrapper>
      ),
    },
    {
      sortable: false,
      Header: 'Pixel',
      accessor: 'campaignType',
      Cell: row =>
        row.original.campaignType === 'dai' ? (
          <div
            data-tip={
              row.original.isRequest
                ? 'Pixel will be generated once the publisher approves promo request'
                : null
            }
          >
            <Button
              disabled={row.original.isRequest}
              type="primary"
              size="small"
              className="ph2 f7"
              onClick={() => handleInstallPixelClick(row.original)}
            >
              View Pixel
            </Button>
          </div>
        ) : (
          <CellWrapper
            canEditPromos={canEditPromos}
            className="inherit no-underline"
            to={`${baseUrl}/${row.original.audioAdPlacementId}`}
          >
            <div className="tc f6 i">Baked-in</div>
          </CellWrapper>
        ),
    },
    {
      Header: 'Episode',
      accessor: 'episodeTitle',
      minWidth: 120,
      Cell: row => {
        if (row.original.episodeTitle || row.original.campaignType === 'dai') {
          return (
            <CellWrapper
              canEditPromos={canEditPromos}
              className="inherit no-underline"
              to={`${baseUrl}/${row.original.audioAdPlacementId}`}
            >
              {row.original.episodeTitle ? (
                <div>{row.original.episodeTitle}</div>
              ) : (
                <div className="tc">-</div>
              )}
            </CellWrapper>
          );
        }

        if (!canEditPromos) {
          return (
            <CellWrapper
              canEditPromos={canEditPromos}
              className="inherit no-underline"
              to={`${baseUrl}/${row.original.audioAdPlacementId}`}
            >
              <div className="tc">-</div>
            </CellWrapper>
          );
        }

        const scheduledAirDate = moment(row.original.startedAt);
        const isFillable = row.original.isInternal && scheduledAirDate.isBefore(new Date());

        return (
          <>
            <ReactTooltip id={row.original.audioAdPlacementId} />
            <div
              data-for={row.original.audioAdPlacementId}
              data-tip={
                isFillable
                  ? null
                  : `${
                      row.original.isInternal ? 'You' : 'The publisher'
                    } can fill this episode the day after the scheduled air date, ${scheduledAirDate.format(
                      'MM/DD/YYYY',
                    )}`
              }
            >
              <Button
                data-for={row.original.audioAdPlacementId}
                disabled={!isFillable}
                type="primary"
                size="small"
                className="ph2 f7"
                onClick={() => handleFillEpisodeClick(row.original)}
              >
                Select Episode
              </Button>
            </div>
          </>
        );
      },
    },
    {
      Header: 'Air Date',
      accessor: 'startedAt',
      id: 'startedAt',
      Cell: row => (
        <CellWrapper
          canEditPromos={canEditPromos}
          className="inherit no-underline tc"
          to={`${baseUrl}/${row.original.audioAdPlacementId}`}
        >
          {row.original.startedAt ? (
            moment(row.original.startedAt).format('MM/DD/YYYY')
          ) : (
            <div className="tc">-</div>
          )}
        </CellWrapper>
      ),
    },
    {
      Header: 'External / Internal',
      accessor: 'isInternal',
      maxWidth: 75,
      Cell: row => (
        <CellWrapper
          canEditPromos={canEditPromos}
          className="inherit no-underline tc f5"
          to={`${baseUrl}/${row.original.audioAdPlacementId}`}
        >
          {row.original.isInternal ? 'Internal' : 'External'}
        </CellWrapper>
      ),
    },
  ];

  if (canEditPromos) {
    columns.push({
      Header: 'Actions',
      id: 'actions',
      sortable: false,
      minWidth: 150,
      className: 'tc',
      Cell: row => (
        <React.Fragment>
          <Button
            inverted
            type="primary"
            className="f7 w3 dib mr2"
            size="small"
            onClick={() => handleEditorClick(row.original)}
          >
            Edit
          </Button>
          <Button
            inverted
            type="destructive"
            className="f7 w3 dib"
            size="small"
            onClick={() => handleArchiveClick(row.original.audioAdPlacementId)}
          >
            Archive
          </Button>
        </React.Fragment>
      ),
    });
  }

  return columns;
};

const getPromoRequestTableColumns = (canEditPromos, handleEditorClick) => {
  const promoRequestStatusValues = {
    NEEDS_APPROVAL: {
      value: 'awaiting approval',
      tooltip: 'Waiting on publisher to approve this promo',
    },
    DENIED: { value: 'Denied', tooltip: 'The publisher has denied this promo' },
  };

  const columns = [
    {
      sortable: false,
      Header: 'Status',
      id: 'status',
      accessor: 'status.statusCode',
      className: 'tc ttc',
      minWidth: 100,
      Cell: row => (
        <NewStatusIndicator
          status={row.original.statusCode}
          text={promoRequestStatusValues[row.original.statusCode].value}
          tooltip={promoRequestStatusValues[row.original.statusCode].tooltip}
        />
      ),
    },
    {
      Header: 'Promo Name',
      accessor: 'name',
    },
    {
      Header: 'Publisher',
      accessor: 'sellerTeam',
      Cell: row => <div>{row.original.sellerTeam ? row.original.sellerTeam : '-'}</div>,
    },
    {
      Header: 'Promoted On',
      id: 'sellerPodcast',
      accessor: 'sellerPodcast.shortTitle',
      minWidth: 150,
      Cell: row => (
        <div>
          {row.original.sellerPodcast ? (
            <div className="flex items-center">
              <div className="mr2" style={{ width: '15%' }}>
                <img
                  src={row.original.sellerPodcast.displayImageUrl}
                  className="center fl br2"
                  alt=""
                />
              </div>
              <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
            </div>
          ) : (
            <div className="tc">-</div>
          )}
        </div>
      ),
    },
    {
      Header: 'Episode',
      accessor: 'episodeTitle',
      Cell: row =>
        row.original.episodeTitle ? (
          <div>{row.original.episodeTitle}</div>
        ) : (
          <div className="tc">-</div>
        ),
    },
    {
      Header: 'Air Date',
      accessor: 'startedAt',
      id: 'startedAt',
      Cell: row => (
        <div className="tc">
          {row.original.startedAt ? moment(row.original.startedAt).format('MM/DD/YYYY') : null}
        </div>
      ),
    },
  ];

  if (canEditPromos) {
    columns.push({
      Header: '',
      sortable: false,
      className: 'tc',
      Cell: row => (
        <Button
          inverted
          type="primary"
          className="f7 w3 dib"
          size="small"
          onClick={() => handleEditorClick(row.original)}
        >
          Edit
        </Button>
      ),
    });
  }

  return columns;
};

export { getPromoTableColumns, getPromoRequestTableColumns };
