"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.textOverflow = void 0;
var _styledComponents = require("styled-components");
//
// Text overflow
// --------------------------------------------------

// Requires inline-block or block for proper styling
var textOverflow = function textOverflow() {
  return (0, _styledComponents.css)(["overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"]);
};
exports.textOverflow = textOverflow;