import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { onClickHandler } from 'shared/a11y/lib';
import { addMegaphoneMatches, getMegaphoneMatches } from 'shared/api';
import { createMegaphoneOrganization, getMegaphoneOrganizations } from 'shared/api/v1';
import Banner from 'shared/Banner';
import Loading from 'shared/Loading';

import styles from '../styles.module.css';
import OnboardingButton from './OnboardingButton';

const PodcastAddConfirmation = ({ teamName, teamId, nextStep, megaphoneOrganizationId }) => {
  const [podcastsCount, setPodcastsCount] = useState(null);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const megaphonePodcastsLink = `https://cms.megaphone.fm/organizations/${megaphoneOrganizationId}/podcasts`;

  useEffect(() => {
    (async () => {
      try {
        const res = await getMegaphoneMatches({
          teamId,
          megaphoneOrganizationId,
        });
        setPodcastsCount(res.data.total);
        setError(null);
      } catch (e) {
        setError(e.message);
      }
    })();
  }, []);

  const handleSubmit = async skip => {
    setSubmissionInProgress(true);
    try {
      const response = await getMegaphoneOrganizations();
      if (response.data.error) {
        setError(response.data.error);
        throw new Error(response.data.error);
      }

      const organization = response.data.find(org => org.id === megaphoneOrganizationId);
      await createMegaphoneOrganization({
        id: organization.id,
        name: organization.name,
      });
      if (!skip) {
        await addMegaphoneMatches({
          teamId,
          megaphoneOrganizationId,
        });
      }
      history.push(nextStep);
      setError(null);
    } catch (e) {
      setError(e);
    } finally {
      setSubmissionInProgress(false);
    }
  };

  return (
    <div className={styles.podcastAddContainer} data-testid="podcast-add-container">
      {error && <Banner error>{error}</Banner>}
      <h1 className={`${styles.onboardingTitle} tc`}>
        Add {teamName} Podcasts to Chartable Dashboard
      </h1>
      <div className={`${styles.addBody} tc`}>
        {podcastsCount === null ? (
          <Loading title="Loading podcast count" />
        ) : (
          <>
            <h1 className={styles.onboardingTitle}>
              {podcastsCount} podcast{podcastsCount === 1 ? '' : 's'} detected
            </h1>
            <a href={megaphonePodcastsLink} className="pointer link underline">
              See full list
            </a>
          </>
        )}
        <p>
          Speeds may vary and it can take several hours to finish adding podcasts. We will email you
          once all of your podcasts are added to your dashboard. You can always reach out to{' '}
          <a href="mailto:help@chartable.com">Customer Support</a> for more information.
        </p>
      </div>
      <OnboardingButton loading={submissionInProgress} onClick={handleSubmit}>
        Add podcasts
      </OnboardingButton>
      <span {...onClickHandler(() => history.push(nextStep))} className="tc pointer link db">
        Skip &amp; add manually later &raquo;
      </span>
    </div>
  );
};

export default PodcastAddConfirmation;
