import React from 'react';
import ChartableIcon from 'images/icons/chartable.svg';
import MegaphoneIcon from 'images/icons/megaphone-logo.svg';
import CloseIcon from 'images/icons/x.svg';
import PropTypes from 'prop-types';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';

const ConnectionConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLoading,
  selectedOrganization,
}) => (
  <BasicModal
    className="absolute overflow-auto z-9999 left-2 right-2 bg-white center top-2 ba b--light-gray br2 mw6-7"
    isOpen={isOpen}
    onRequestClose={onClose}
    ariaHideApp={false}
  >
    <>
      <div className="tr">
        <img src={CloseIcon} alt="Close" className="pointer dim mr4 mt4" onClick={onClose} />
      </div>
      <div className="pb5 ph5 pt3">
        <div className="tc f2 dark-gray b">Link your Teams</div>
        <div className="flex mv5 flex-row justify-between items-center">
          <div className="tc" style={{ flexBasis: '45%' }}>
            <img src={MegaphoneIcon} style={{ width: '30px' }} className="mb2" />
            <div className="f6 mb2 b gray">Megaphone Org</div>
            <div className="f5 dark-gray">{selectedOrganization && selectedOrganization.label}</div>
          </div>
          <div className="dark-gray b f3">+</div>
          <div className="tc" style={{ flexBasis: '45%' }}>
            <img src={ChartableIcon} style={{ height: '36px' }} className="mb2" />
            <div className="f6 mb2 b gray">Chartable Team</div>
            <div className="f5 dark-gray">Chartable</div>
          </div>
        </div>
        <div className="tc mb4 dark-gray force-inter lh-copy">
          By linking these teams, you agree to Megaphone sharing your name, email address, and
          organization membership data with Chartable.com. To see more details visit{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://megaphone.spotify.com/legal"
            className="link pointer no-underline megaphone-color"
          >
            Megaphone’s Privacy Policy and Terms of Service.
          </a>
        </div>
        <Button
          className={`white pv3 w5 mt-auto f5 b center ${
            isLoading ? 'bg-moon-gray' : 'bg-spotify-purple'
          }`}
          style={{ borderRadius: '40px' }}
          loading={isLoading}
          onClick={onConfirm}
        >
          Link Teams
        </Button>
      </div>
    </>
  </BasicModal>
);

ConnectionConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  selectedOrganization: PropTypes.object,
};

ConnectionConfirmationModal.defaultProps = {
  selectedOrganization: null,
};

export default ConnectionConfirmationModal;
