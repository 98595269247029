import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

// Prop label key is required because the response from demographics does not have a generic
// key for label (e.g. the key to get the label "male" or "female" for gender is "gender")
// -JS

const IndexedValueChart = ({ indexedData, color, showGenreBreakout, labelKey, colorMap }) => {
  const colorForLabel = label => {
    if (!colorMap) return color;
    return colorMap[label] ? colorMap[label] : color;
  };

  const postiveData = useMemo(
    () =>
      indexedData.filter(
        data =>
          !!data &&
          !!data.reachPercentageAgainstBenchmark &&
          data.reachPercentageAgainstBenchmark >= 0.0,
      ),
    [indexedData],
  );

  const renderPrimaryItem = data => (
    <div>
      <span className="f3 near-black">
        <b className="force-inter b">{data.reachPercentageAgainstBenchmark}%</b>{' '}
        <span className="f4">more</span>{' '}
        <b className={`bg-${colorForLabel(data[labelKey])} white ph1 mr2 br2 force-inter`}>
          {data[labelKey].replace('years', 'year').replace('old', 'olds')}
        </b>
      </span>
      <span className="f4 force-inter dark-gray">than the average podcast audience</span>
    </div>
  );

  const renderGenreItem = (label, genreData) => {
    if (!genreData) return null;
    if (genreData.reachPercentageAgainstBenchmark <= 0.0) return null;

    return (
      <li key={`${label}-${genreData.name}`}>
        <div className="mt3">
          <span className="f5 near-black">
            <b className="force-inter b">{genreData.reachPercentageAgainstBenchmark}%</b>{' '}
            <span className="f5">more</span>{' '}
            <b className={`${colorForLabel(label)} mr1 br2 force-inter`}>
              {label.replace('years', 'year').replace('old', 'olds')}
            </b>
          </span>
          <span className="f5 force-inter dark-gray">
            than the average <b>{genreData.name}</b> audience
          </span>
        </div>
      </li>
    );
  };

  return (
    <React.Fragment>
      {postiveData.map((data, index) => (
        <div className={`bw2 bl b--${colorForLabel(data[labelKey])} pa3`} key={index}>
          {renderPrimaryItem(data)}
          {!!data.genres && data.genres.length > 0 && (
            <ul className="ma0 ph4">
              {data.genres.map(genreData => renderGenreItem(data[labelKey], genreData))}
            </ul>
          )}
        </div>
      ))}
    </React.Fragment>
  );
};

IndexedValueChart.propTypes = {
  labelKey: PropTypes.string.isRequired,
  indexedData: PropTypes.array.isRequired,
  color: PropTypes.string,
  showGenreBreakout: PropTypes.bool,
  colorMap: PropTypes.object,
};

IndexedValueChart.defaultProps = {
  color: 'white',
  showGenreBreakout: true,
  colorMap: null,
};

export default IndexedValueChart;
