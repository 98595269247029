import React from 'react';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';

const Success = ({ setIsSuccess }) => {
  const removeSuccess = () => {
    setIsSuccess(false);
  };

  return (
    <div className="mb4">
      <Banner
        type="success"
        onClick={removeSuccess}
        style={{ cursor: 'pointer' }}
        hoverText="click to dismiss"
      >
        You&apos;ve successfully integrated Spotify for Podcasters stats with your account.
      </Banner>
    </div>
  );
};

Success.propTypes = {
  setIsSuccess: PropTypes.func,
  migrationResult: PropTypes.array,
};

export default Success;
