import React from 'react';

const DemographicsHeader = ({ selectedView, setView }) => {
  const selectedClassName = 'no-underline f5 b dim pointer blue pb2 bb bw2 w4 tc b--blue';
  const unselectedClassName =
    'no-underline f5 b dim pointer near-black pb2 bb bw2 w4 tc b--moon-gray';

  return (
    <div className="flex flex-wrap justify-between mv3">
      <div className="flex flex-row items-center">
        <span
          onClick={() => setView('demo')}
          className={selectedView === 'demo' ? selectedClassName : unselectedClassName}
        >
          Demographics
        </span>
        <span
          onClick={() => setView('geo')}
          className={selectedView === 'geo' ? selectedClassName : unselectedClassName}
        >
          Geographics
        </span>
        <span
          onClick={() => setView('player')}
          className={selectedView === 'player' ? selectedClassName : unselectedClassName}
        >
          Podcast Players
        </span>
      </div>
    </div>
  );
};

export default DemographicsHeader;
