export const publisherReports = {
  SmartPromos: [
    {
      label: 'Snapshot',
      type: 'SMARTPROMOS_SNAPSHOT_REPORT',
      description: 'Snapshot of all promo data for active promos in your SmartPromos Dashboard.',
    },
    {
      label: 'Timeseries',
      type: 'SMARTPROMOS_TIMESERIES_REPORT',
      description:
        'Promo performance for a specific podcast between two dates, aggregated by day or week.',
    },
  ],
  SmartLinks: [
    {
      label: 'Overview',
      type: 'SMARTLINKS_OVERVIEW_REPORT',
      description: 'Overview of SmartLinks data for one or all of your podcasts.',
    },
    {
      label: 'Link Report',
      type: 'SMARTLINKS_REPORT',
      description: 'Detailed data for one of your SmartLinks.',
    },
  ],
};
