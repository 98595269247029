import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import { redirectToPricingPage } from 'shared/helpers';
import useUserInfo from 'shared/hooks/useUserInfo';

import DemographicsBarChart from './DemographicsBarChart';
import DemographicsChartHeader from './DemographicsChartHeader';
import { demographicsSegments, formatDemographicDataForBarChart } from './DemographicsChartHelpers';
import IndexedValueChart from './IndexedValueChart';
import Locked from './Locked';

const HouseholdDemographicsCharts = ({ gender, education, householdIncome, age, error }) => {
  const {
    canViewHouseholdDemographics,
    trackableEnabled,
    hasHouseholdDemographics,
    hasHouseholdDemographicsData,
    shouldUpsellHouseholdDemographics,
  } = useUserInfo();

  const { teamId } = useParams();

  const genderColor = useMemo(() => {
    if (!gender) return 'white';
    const postiveGender = gender.find(item => item.reachPercentageAgainstBenchmark >= 0.0);
    return postiveGender.gender === 'Female' ? 'red' : 'dark-blue';
  }, [gender]);

  // Show Locked UI if any of the following are false
  if (!hasHouseholdDemographics || !trackableEnabled) {
    const requirements = [
      {
        name: trackableEnabled
          ? 'Demographics Data Unavailable (Not Enough Data)'
          : 'Demographics Data Unavailable (Trackable not installed for selection. Learn more here.)',
        checked: false,
        onClick: trackableEnabled
          ? null
          : () => (window.location = `/teams/${teamId}/dashboard/integrations/trackable`),
      },
    ];

    return <Locked reqs={requirements} />;
  }

  // If the user does not have permission to view demographics, based on role, hide charts
  if (!canViewHouseholdDemographics) {
    return (
      <Banner className="mt3" error>
        You don't have permission to view this
      </Banner>
    );
  }

  // Error check from request
  if (error) {
    return (
      <Banner className="mt3" error>
        Failed to load demographics data
      </Banner>
    );
  }

  // If one or more segments is null, show "no data"
  if (!(gender || education || householdIncome || age)) {
    return (
      <Banner noImage className="mt3">
        <div>Household Audience data is unavailable. Try selecting another show.</div>
      </Banner>
    );
  }

  return (
    <div>
      <div className="flex flex-wrap gap">
        <div style={{ minWidth: '49%', maxWidth: '100%' }}>
          <DemographicsChartHeader segment={demographicsSegments.householdIncome} showLegend />
          <DemographicsBarChart
            isLoading={false}
            data={formatDemographicDataForBarChart(
              demographicsSegments.householdIncome,
              householdIncome,
              true,
            )}
            inlineStyle={{ height: '400px', width: '100%' }}
          />
        </div>
        <div style={{ minWidth: '49%', maxWidth: '100%' }}>
          <DemographicsChartHeader segment={demographicsSegments.education} showLegend />
          <DemographicsBarChart
            isLoading={false}
            data={formatDemographicDataForBarChart(demographicsSegments.education, education, true)}
            inlineStyle={{ height: '400px', width: '100%' }}
          />
        </div>
      </div>
      <div className="w-100 mt4 mw8">
        <div className={`b mb2 f4 force-inter ${genderColor}`}>Gender</div>
        <div className="card pa2 w-100">
          <IndexedValueChart indexedData={gender} labelKey="gender" color={genderColor} />
        </div>
      </div>
      <div className="w-100 mt4 mw8">
        <div className="b mb2 f4 force-inter purple">Age</div>
        <div className="card pa2 w-100">
          <IndexedValueChart indexedData={age} labelKey="age" color="purple" />
        </div>
      </div>
    </div>
  );
};

HouseholdDemographicsCharts.propTypes = {
  error: PropTypes.bool.isRequired,
  gender: PropTypes.array,
  education: PropTypes.array,
  householdIncome: PropTypes.array,
  age: PropTypes.array,
};

HouseholdDemographicsCharts.defaultProps = {
  gender: null,
  education: null,
  householdIncome: null,
  age: null,
};

export default HouseholdDemographicsCharts;
