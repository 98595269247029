/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// ***
// SMARTLINKS WARNING: Throughout this page "platform" refers to the player.
// ***

import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import WarningRedSVG from 'images/icons/warning-red.svg';
import PropTypes from 'prop-types';
import {
  createTrackingLinkEpisodeDeeplinks,
  createTrackingLinkPodcastDeeplinks,
  deleteTrackingLinkEpisodeDeeplinks,
  deleteTrackingLinkPodcastDeeplinks,
  updateTrackingLinkEpisodeDeeplinks,
  updateTrackingLinkPodcastDeeplinks,
} from 'shared/api/v1';
import Button from 'shared/Button';
import { playerInfo } from 'shared/helpers';
import Loading from 'shared/Loading';

const { Option } = components;
const CustomOption = props => (
  <Option {...props}>
    <div className="input-select__single-value flex flex-row items-center">
      <img
        alt="select-icon"
        src={props.data.icon}
        className="input-select__icon mr2"
        style={{ height: '25px' }}
      />
      <div>{props.data.label}</div>
    </div>
  </Option>
);

const SingleValue = ({ data }) => (
  <div className="input-select">
    <div className="input-select__single-value flex flex-row items-center">
      {data.icon && (
        <img
          alt="select-icon"
          src={data.icon}
          className="input-select__icon mr2"
          style={{ height: '25px' }}
        />
      )}
      <div>{data.label}</div>
    </div>
  </div>
);

function DeeplinkItem({
  id,
  url,
  platformKey,
  className,
  allowsEditing,
  allowsDelete,
  handleDeleteDeeplink,
  handleEditDeeplink,
  error,
  removeError,
  pageValidated,
}) {
  const [urlCopy, setUrlCopy] = useState(url);
  const [editing, setEditing] = useState(false);

  if (platformKey === 'stitcher') {
    return <></>;
  }

  return (
    <div className={className}>
      {editing ? (
        <div className="b--light-gray br3 ba pa2">
          <div className="mb2 flex flex-row items-center">
            <img
              alt="player-icon"
              style={{ width: '25px' }}
              className="h-auto mr2"
              src={playerInfo[platformKey].icon}
            />
            <div className="drak-gray">{playerInfo[platformKey].label}</div>
            {allowsDelete && (
              <div onClick={() => handleDeleteDeeplink(id)} className="ml-auto f6 red pointer dim">
                Remove
              </div>
            )}
          </div>
          <input
            value={urlCopy}
            className="pa2 input-reset ba bg-white br2 b--silver w-90 mr3"
            onChange={e => setUrlCopy(e.target.value)}
          />
          {!!error && error.platformKey === platformKey && (
            <div className="red mt2 f6">{error.message}</div>
          )}
          <div className="mt2 flex flex-row gap-small">
            <Button
              onClick={() => {
                handleEditDeeplink(id, urlCopy, platformKey, () => {
                  setEditing(false);
                  removeError(null);
                });
              }}
              type="primary"
              size="small"
              className="f6 w3"
            >
              Save
            </Button>
            <Button
              onClick={() => {
                setUrlCopy(url);
                removeError(null);
                setEditing(false);
              }}
              size="small"
              className="f6 w3"
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <div className={`b--${pageValidated === 'error' ? 'washed-red' : 'light-gray'} br3 ba pa2`}>
          {pageValidated === 'error' && (
            <div
              className="f6 mb2 pa2 br2 flex flex-row items-center"
              style={{ color: '#9b1c1c', backgroundColor: '#fde8e8' }}
            >
              <img alt="warning" src={WarningRedSVG} style={{ height: '18px' }} className="mr1" />
              <div>We were unable to validate the url you have provided.</div>
            </div>
          )}
          <div className="flex items-center flex-row">
            <img
              alt="player-icon"
              style={{ width: '25px' }}
              className="h-auto mr2"
              src={playerInfo[platformKey].icon}
            />
            <div className="overflow-hidden">
              <div className="f5 mb1" target="_blank" href={urlCopy}>
                {playerInfo[platformKey].label}
              </div>
              <div className="f7 mid-gray truncate w-100">{urlCopy || 'Default'}</div>
            </div>
            {allowsEditing && (
              <div onClick={() => setEditing(true)} className="blue ml-auto pointer f6 dim">
                Edit
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function DeeplinkManager({
  className,
  teamId,
  podcastId,
  rawEpisodeId,
  deeplinks, // This makes it harder to reuse, but managing the state at the top level makes it much easier for the create form
  setDeeplinks,
  preSelectedNewDeeplinkPlatformKey,
  nullifyPreSelectedKey,
  linkableReadOnly,
}) {
  const [requestInProgress, setRequestInProgress] = useState(false);

  const [editError, setEditError] = useState(null);
  const [createDeeplinkError, setCreateDeeplinkError] = useState(null);
  const [creatingDeeplink, setCreatingDeeplink] = useState(!!preSelectedNewDeeplinkPlatformKey);
  const [newDeeplinkUrl, setNewDeeplinkUrl] = useState('');
  const [newDeeplinkPlatformKey, setNewDeeplinkPlatformKey] = useState(
    preSelectedNewDeeplinkPlatformKey,
  );

  async function handleDeleteDeeplink(id) {
    if (requestInProgress) return;
    setRequestInProgress(true);

    const params = { id, teamId };

    try {
      if (rawEpisodeId) {
        await deleteTrackingLinkEpisodeDeeplinks(params);
      } else {
        await deleteTrackingLinkPodcastDeeplinks(params);
      }

      const _deeplinks = deeplinks.filter(deeplink => deeplink.id !== id);
      setEditError(null);
      setDeeplinks(_deeplinks);
    } catch (err) {
      if (linkableReadOnly) {
        setEditError({ platformKey, message: err.response.data.errors });
      } else {
        // eslint-disable-next-line no-undef
        setEditError({ platformKey, message: 'Failed to delete link' });
      }
    }

    setRequestInProgress(false);
  }

  // Remove platform key argument after auto generating issues are fixed
  async function handleEditDeeplink(id, url, platformKey, onSuccess) {
    if (requestInProgress) return;
    setRequestInProgress(true);

    if (!id) {
      handleCreateDeeplink(platformKey, url, true);
      return;
    }

    const params = {
      teamId,
      id,
      url
    };

    let response;

    try {
      if (rawEpisodeId) {
        response = await updateTrackingLinkEpisodeDeeplinks(params);
      } else {
        response = await updateTrackingLinkPodcastDeeplinks(params);
      }

      // Manually setting can edit from here because we know that if they updated it, they can destroy/edit it.
      response.data.canEdit = true;

      const _deeplinks = deeplinks.map(deeplink => (deeplink.id === id ? response.data : deeplink));
      setDeeplinks(_deeplinks);
      onSuccess();
    } catch (error) {
      if (linkableReadOnly) {
        setEditError({ platformKey, message: error.response.data.errors });
      } else {
        setEditError({
          platformKey,
          message: 'Failed to update deeplink. Make sure the link is valid for the player.',
        });
      }
    }

    setRequestInProgress(false);
  }

  async function handleCreateDeeplink(
    newDeeplinkPlatformKey,
    newDeeplinkUrl,
    updatingDefaultDeeplink,
  ) {
    if (requestInProgress) return;
    setRequestInProgress(true);

    const params = {
      teamId,
      podcastId,
      rawEpisodeId,
      platformId: newDeeplinkPlatformKey,
      url: newDeeplinkUrl,
      useNew: true,
    };

    let response;

    try {
      if (rawEpisodeId) {
        response = await createTrackingLinkEpisodeDeeplinks(params);
      } else {
        response = await createTrackingLinkPodcastDeeplinks(params);
      }

      // Manually setting can edit from here because we know that if they created it, they can destroy/edit it.
      response.data.canEdit = true;

      deeplinks.push(response.data);
      setDeeplinks(deeplinks);
      cancelCreatingDeeplink();
    } catch (err) {
      if (linkableReadOnly) {
        setCreateDeeplinkError(err.response.data.errors);
      } else if (updatingDefaultDeeplink) {
        setEditError({
          platformKey: newDeeplinkPlatformKey,
          message: 'Failed to update deeplink. Make sure the link is valid for the player.',
        });
      } else {
        setCreateDeeplinkError('Invalid URL for this player');
      }
    }

    setRequestInProgress(false);
  }

  function cancelCreatingDeeplink() {
    setEditError(null);
    setCreatingDeeplink(false);
    setNewDeeplinkUrl('');
    setNewDeeplinkPlatformKey(null);
    setCreateDeeplinkError(null);
  }

  useEffect(() => {
    if (nullifyPreSelectedKey) {
      nullifyPreSelectedKey();
    }
  }, []);

  const platformOptions = useMemo(() => {
    if (!deeplinks) return null;
    const usedPlatforms = deeplinks.map(d => d.platformKey);
    return Object.keys(playerInfo)
      .map(key => (!usedPlatforms.includes(playerInfo[key].value) ? playerInfo[key] : null))
      .filter(i => i && i.value !== 'rss'); // Hiding RSS until users can edit/add it
  }, [JSON.stringify(deeplinks)]);

  return (
    <div className={className}>
      <div className="relative">
        {deeplinks ? (
          <React.Fragment>
            {deeplinks.map(deeplink => (
              <DeeplinkItem
                {...deeplink}
                key={deeplink.id || deeplink.platformKey}
                allowsEditing={deeplink.canEdit}
                allowsDelete={deeplink.canDestroy}
                className="mt2 overflow-hidden"
                handleDeleteDeeplink={handleDeleteDeeplink}
                handleEditDeeplink={handleEditDeeplink}
                pageValidated={deeplink.pageValidated}
                error={editError}
                removeError={() => setEditError(null)}
              />
            ))}
            {creatingDeeplink ? (
              <div className="b--light-blue mt2 br3 ba pa2">
                <div className="f5 dark-gray mb2">Add New Link</div>
                <div className="f6 mb2 dark-gray">Platform</div>
                <Select
                  value={playerInfo[newDeeplinkPlatformKey]}
                  options={platformOptions}
                  placeholder="Choose a platform..."
                  components={{ SingleValue, Option: CustomOption }}
                  className="mb3"
                  onChange={selection => setNewDeeplinkPlatformKey(selection.value)}
                />
                <div className="f6 mb1 dark-gray">URL</div>
                <div className="f7 mb2 mid-gray">
                  This should be the direct URL to the {rawEpisodeId ? 'episode' : 'podcast'} for
                  the chosen platform
                </div>
                <input
                  value={newDeeplinkUrl}
                  placeholder="Ex. open.spotify.com/ ..."
                  className="pa2 input-reset ba bg-white br2 b--silver"
                  style={{ width: '97%' }}
                  onChange={e => setNewDeeplinkUrl(e.target.value)}
                />
                {createDeeplinkError && <div className="mt2 red f6">{createDeeplinkError}</div>}
                <div className="flex flex-row mt3">
                  <Button
                    type="primary"
                    size="small"
                    className="w4 mr3"
                    disabled={!newDeeplinkPlatformKey || !newDeeplinkUrl}
                    onClick={() => {
                      if (!newDeeplinkPlatformKey || !newDeeplinkUrl) return;
                      handleCreateDeeplink(newDeeplinkPlatformKey, newDeeplinkUrl, false);
                    }}
                  >
                    Save
                  </Button>
                  <Button size="small" className="w4" onClick={() => cancelCreatingDeeplink()}>
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <div
                onClick={() => setCreatingDeeplink(true)}
                className="bg-blue white ml2 ph3 br3 dib pv1 pointer dim mt3 f6"
              >
                + Add Player Link
              </div>
            )}
          </React.Fragment>
        ) : (
          <div className="w-100 tc">
            <Loading />
          </div>
        )}
        {requestInProgress && (
          <div className="absolute top-0 w-100 h-100 flex items-center justify-center">
            <div className="absolute top-0 w-100 h-100 bg-light-gray o-40" />
          </div>
        )}
      </div>
    </div>
  );
}

DeeplinkManager.propTypes = {
  teamId: PropTypes.string.isRequired,
  rawEpisodeId: PropTypes.string,
  podcastId: PropTypes.string,
  className: PropTypes.string,
  allowsEditing: PropTypes.bool,
  preSelectedNewDeeplinkPlatformKey: PropTypes.string,
  nullifyPreSelectedKey: PropTypes.func,
};

DeeplinkManager.defaultProps = {
  allowsEditing: true,
  className: '',
  preSelectedNewDeeplinkPlatformKey: null,
};

export default DeeplinkManager;
