import React, { Component } from 'react';
import PropTypes from 'prop-types';

import BannerNotice from 'shared/BannerNotice';

export default function withNotifications(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);

      this.createNotification = this.createNotification.bind(this);
      this.destroyNotification = this.destroyNotification.bind(this);

      this.state = {
        notices: {},
      };
    }

    createNotification(message, type) {
      const { notices } = this.state;
      const notificationId = '_' + Math.random().toString(36).substr(2, 9);
      notices[notificationId] = (
        <BannerNotice
          message={message}
          type={type}
          onClick={() => this.destroyNotification(notificationId)}
          onClose={() => this.destroyNotification(notificationId)}
        />
      );
      this.setState({
        notices,
      });
    }

    destroyNotification(notificationId) {
      const { notices } = this.state;
      delete notices[notificationId];

      this.setState({
        notices,
      });
    }

    render() {
      return (
        <div>
          {Object.values(this.state.notices).map(notice => (
            <div>{notice}</div>
          ))}
          <WrappedComponent onCreateNotification={this.createNotification} {...this.props} />
        </div>
      );
    }
  };
}
