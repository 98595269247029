import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { getPodcastHouseholdDemographics, getTeamHouseholdDemographics } from 'shared/api/v1';
import { checkForCID } from 'shared/helpers';
import useUserInfo from 'shared/hooks/useUserInfo';
import Loading from 'shared/Loading';

import { demographicsSegments, formatDemographicDataForBarChart } from './DemographicsChartHelpers';
import HouseholdDemographicsCharts from './HouseholdDemographicsCharts';
import SpotifyDemographics from './SpotifyDemographics';

const DemographicsView = ({
  podcastId,
  spotifyDemographicsData,
  spotifyDemographicsFailedToLoad,
  loadingSpotifyDemographics,
}) => {
  const { hasHouseholdDemographics } = useUserInfo();
  const [loadingHouseholdDemographics, setLoadingHouseholdDemographics] = useState(false);
  const [householdDemographicsFailedToLoad, setHouseholdDemographicsFailedToLoad] = useState(false);
  const [householdDemographics, setHouseholdDemographics] = useState({
    age: null,
    ethnicity: null,
    gender: null,
    householdIncome: null,
    education: null,
  });

  const handleGetTeamHouseholdDemographics = async podcastId => {
    setLoadingHouseholdDemographics(true);
    setHouseholdDemographicsFailedToLoad(false);

    try {
      let requests;

      if (podcastId) {
        // Some users still go direct to the old version of the page where we used podcast slug in the url.
        // In this case, we auto switch the url to the CID but we need to make sure that this call doesn't
        // execute for the slug version so we escape early.
        if (checkForCID(podcastId)) {
          requests = [
            getPodcastHouseholdDemographics({ segment: 'age', podcastId }),
            getPodcastHouseholdDemographics({ segment: 'ethnicity', podcastId }),
            getPodcastHouseholdDemographics({ segment: 'gender', podcastId }),
            getPodcastHouseholdDemographics({ segment: 'household_income', podcastId }),
            getPodcastHouseholdDemographics({ segment: 'education', podcastId }),
          ];
        } else {
          return;
        }
      } else {
        requests = [
          getTeamHouseholdDemographics({ segment: 'age' }),
          getTeamHouseholdDemographics({ segment: 'ethnicity' }),
          getTeamHouseholdDemographics({ segment: 'gender' }),
          getTeamHouseholdDemographics({ segment: 'household_income' }),
          getTeamHouseholdDemographics({ segment: 'education' }),
        ];
      }

      const [age, ethnicity, gender, householdIncome, education] = await Promise.all(requests);

      setHouseholdDemographics({
        age: age && age.data ? age.data.data : null,
        ethnicity: ethnicity && ethnicity.data ? ethnicity.data.data : null,
        gender: gender && gender.data ? gender.data.data : null,
        householdIncome: householdIncome && householdIncome.data ? householdIncome.data.data : null,
        education: education && education.data ? education.data.data : null,
      });
    } catch (e) {
      setHouseholdDemographicsFailedToLoad(true);
    } finally {
      setLoadingHouseholdDemographics(false);
    }
  };

  useEffect(() => {
    if (hasHouseholdDemographics) {
      handleGetTeamHouseholdDemographics(podcastId);
    }
  }, [podcastId]);

  return (
    <div className="pb5">
      {hasHouseholdDemographics && (
        <>
          <div className="flex items-baseline gap flex-row">
            <div className="f3 force-inter mt4 b">US Household Demographics</div>
            <a
              href="https://help.chartable.com/article/146-household-demographics"
              target="_blank"
              className="link blue pointer"
              referrer="noreferrer"
            >
              Learn more
            </a>
          </div>
          {loadingHouseholdDemographics ? (
            <Loading />
          ) : (
            <HouseholdDemographicsCharts
              {...householdDemographics}
              error={householdDemographicsFailedToLoad}
            />
          )}
        </>
      )}
      <div className="flex items-baseline gap flex-row mt5">
        <div className="f3 force-inter mt4 b">Spotify Demographics</div>
        <a
          href="https://help.chartable.com/article/242-setting-up-the-spotify-integration"
          target="_blank"
          className="link blue pointer"
          referrer="noreferrer"
        >
          Learn more
        </a>
      </div>
      {loadingSpotifyDemographics ? (
        <Loading />
      ) : (
        <SpotifyDemographics
          data={spotifyDemographicsData}
          error={spotifyDemographicsFailedToLoad}
        />
      )}
    </div>
  );
};

DemographicsView.propTypes = {
  podcastId: PropTypes.string,
  spotifyDemographicsData: PropTypes.object,
  spotifyDemographicsFailedToLoad: PropTypes.bool,
  loadingSpotifyDemographics: PropTypes.bool,
};

DemographicsView.defaultProps = {
  podcastId: null,
  spotifyDemographicsData: null,
  spotifyDemographicsFailedToLoad: null,
  loadingSpotifyDemographics: null,
};

export default DemographicsView;
