import { opacity30, opacity70, spacer24, spacer64 } from '@spotify-internal/encore-foundation';
import { cssLayoutMargin } from "./layout-theme";

// Typography
export var fontWeightBook = 400;
export var fontWeightBold = 700;
export var fontWeightBlack = 900;

// z-index master list
export var zIndexPreviewBackdrop = 0;
export var zIndexBannerDecorator = 1;
export var zIndexFixed = 1030;
export var zIndexDialogBackdrop = 1040;
export var zIndexDialog = 1050;
export var zIndexPopover = 1060;
export var zIndexSkipLink = 9999;

// Sidebar
export var sidebarBaseWidth = '200px';
export var sidebarSlimBaseWidth = spacer64;
export var sidebarPadding = cssLayoutMargin('compact', spacer24);

// Transitions + Animations
export var transitionFade = '0.1s';

// Opacities
export var opacityDisabled = opacity30;
export var opacityActive = opacity70;

// Misc
export var cursorDisabled = 'not-allowed'; // Disabled cursor for form controls and buttons.