/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from 'prop-types';
import TitleBar from 'shared/charts/TitleBar';
import Loading from 'shared/Loading';

export default function ConsumptionChart({ loading, consumptionSeries, showTitle }) {
  const title = 'Consumption';
  const subtitle = 'Percentage of listeners still listening during an episode';
  if (loading) {
    return (
      <div>
        {showTitle && <TitleBar title={title || 'Downloads'} subtitle={subtitle} />}
        <div className="mt2 w-100 tc center h-2">
          <Loading />
        </div>
      </div>
    );
  }

  const hAxisDataType = consumptionSeries[0][0]; // 'Percentage' or 'Time'

  const rows = consumptionSeries.slice(1);

  const chartOptions = {
    displayZoomButtons: false,
    displayRangeSelector: false,
    hAxis:
      hAxisDataType === 'Percentage'
        ? {
            title: '% Episode Listened',
            format: 'percent',
            viewWindow: {
              min: 0,
              max: 1,
            },
          }
        : {},
    vAxis: {
      format: 'percent',
      viewWindow: {
        min: 0,
        max: 1,
      },
      title: '% Listeners Engaged',
    },
    legend: { position: 'top' },
    chartArea: {
      left: 75,
      top: 40,
      width: '90%',
      height: '80%',
    },
    interpolateNulls: true,
    backgroundColor: {
      strokeWidth: 0,
    },
    thickness: 3,
    series: {
      0: { color: '#885A9A' },
      1: { color: '#7FA232' },
    },
  };

  const chartColumns = consumptionSeries[0].map(col => {
    if (col === 'Percentage') {
      return {
        type: 'number',
        label: 'Show Completion',
      };
    }

    if (col === 'Time') {
      return {
        type: 'string',
        label: 'Time',
      };
    }

    return {
      type: 'number',
      label: col,
    };
  });

  return (
    <div className="mb3">
      <div className="w-100 tl flex flex-wrap">
        <div className="w-100">
          {showTitle && (
            <div className="f3 dark-blue mb1">
              Consumption
              <div className="f7 b gray ttu tracked dib pl2">
                <a
                  data-tip="Percentage of listeners still listening during an episode"
                  className="ph1 ba b--gray br-100 tc pointer"
                >
                  &#63;
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt2 bg-white br2">
        <Chart
          chartType="AreaChart"
          options={chartOptions}
          columns={chartColumns}
          rows={rows}
          width="100%"
          height="400px"
        />
      </div>
    </div>
  );
}

ConsumptionChart.propTypes = {
  loading: PropTypes.bool,
  consumptionSeries: PropTypes.array.isRequired,
  showTitle: PropTypes.bool,
};

ConsumptionChart.defaultProps = {
  consumptionSeries: [],
  loading: false,
  showTitle: true,
};
