import React, { useState } from 'react';
import Header from 'shared/Header';
const BuyerProfile = ({ handleSubmit, teamId }) => {
  const [formState, setFormState] = useState({ teamId });

  const handleChange = e => {
    const formData = new FormData(e.currentTarget);
    const entries = formData.entries();
    const current = formState;
    for (const entry of entries) {
      current[entry[0]] = entry[1];
      setFormState(current);
    }
  };

  return (
    <div>
      <div className="bb b--light-gray mb3">
        <Header className="dark-blue mt3">Welcome to Chartable Connect</Header>
        <p className="gray">
          Before we get you connected, tell us a little about your organization so we can customize
          your Connect experience.
        </p>
      </div>
      <form onChange={handleChange}>
        <div className="mb3">
          <label className="mb1 f5" htmlFor="companyName">
            Company Name
          </label>
          <input
            className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
            type="text"
            name="companyName"
          />
        </div>
        <div className="mb3">
          <label className="mb1 f5" htmlFor="websiteUrl">
            Website
          </label>
          <input
            className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
            placeholder="https://"
            type="text"
            name="websiteUrl"
          />
        </div>
        <div className="mb3">
          <label className="mb1 f5" htmlFor="budget">
            Budget
          </label>
          <input
            className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
            placeholder="$"
            type="number"
            step="1000"
            name="budget"
          />
        </div>
        <div
          onClick={() => handleSubmit(formState)}
          style={{ margin: '0 auto 0 0' }}
          className="pv2 bn white bg-blue pointer w4 f5 br2 header-font mt3 tc relative"
        >
          Submit
        </div>
      </form>
    </div>
  );
};

export default BuyerProfile;
