import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'shared/charts/PieChart';
import Header from 'shared/Header';

const Channel = ({ channelDetails, referrerColorMap }) => {
  return (
    <div className="mt4">
      <Header className="dark-blue mb2" useNew>
        By Channel
      </Header>
      <div className="flex flex-wrap">
        {channelDetails.uniqueClicks && (
          <div className="w-50 pr2">
            <Header useNew small>
              Clicks
            </Header>
            <PieChart data={channelDetails.uniqueClicks} />
          </div>
        )}
        {channelDetails.uniqueDownloads && (
          <div className="w-50 pr2">
            <Header useNew small>
              Converted Devices
            </Header>
            <PieChart data={channelDetails.uniqueDownloads} colorMap={referrerColorMap} />
          </div>
        )}
        {channelDetails.newDownloads && (
          <div className="w-50">
            <Header useNew small>
              New Converted Devices
            </Header>
            <PieChart data={channelDetails.newDownloads} colorMap={referrerColorMap} />
          </div>
        )}
      </div>
    </div>
  );
};

Channel.propTypes = {
  channelDetails: PropTypes.object,
  referrerColorMap: PropTypes.any,
};

export default Channel;
