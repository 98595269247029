/* eslint-disable @typescript-eslint/no-shadow */
import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { getAudioAdAggregateBenchmarks } from 'shared/api';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';
import Loading from 'shared/Loading';
import StatCard from 'shared/StatCard';

function BenchmarksView({ match }) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [pivotColumn, setPivotColumn] = useState(null);
  const [dimensions, setDimensions] = useState({});
  const [isInternal, setIsInternal] = useState(null);
  const [notice, setNotice] = useState(null);

  useEffect(() => {
    getBenchmarks({}, 'buyer_podcast_genre_id', null);
  }, []);

  function getBenchmarks(dimensions, pivotColumn, isInternal) {
    setLoading(true);
    getAudioAdAggregateBenchmarks({
      teamId: match.params.teamId,
      dimensions,
      pivotColumn,
      isInternal,
    })
      .then(res => {
        setData(res.data);
        setLoading(false);
        setNotice(null);
        setPivotColumn(pivotColumn);
        setDimensions(dimensions);
        setIsInternal(isInternal);
      })
      .catch(() => {
        setLoading(false);
        setNotice(
          <BannerNotice rounded error className="mv3" message="Failed to load benchmark data" />,
        );
      });
  }

  if (loading) {
    return <Loading fullScreen />;
  }

  const columns = [
    {
      Header: 'Genre',
      accessor: 'name',
    },
    {
      Header: 'Conversions Rate',
      accessor: 'averageUniqueDownloadRate',
      Cell: row => (
        <div className="tc">
          {row.original.averageUniqueDownloadRate
            ? `${(row.original.averageUniqueDownloadRate * 100).toFixed(3)}%`
            : null}
        </div>
      ),
    },
    {
      Header: 'New Conversions Rate',
      accessor: 'averageNewDownloadRate',
      Cell: row => (
        <div className="tc">
          {row.original.averageNewDownloadRate
            ? `${(row.original.averageNewDownloadRate * 100).toFixed(3)}%`
            : null}
        </div>
      ),
    },
    {
      Header: 'Sample Size',
      accessor: 'sampleSize',
    },
  ];

  return (
    <div className="pv3 ph4">
      {notice}
      <div>
        <div className="b4 br2 w-100">
          <div className="flex-wrap flex mb2 justify-end">
            <Button
              type={isInternal === null ? 'primary' : 'secondary'}
              onClick={() => getBenchmarks(dimensions, pivotColumn, null)}
            >
              All Promos
            </Button>
            <Button
              className="ml2"
              type={isInternal === true ? 'primary' : 'secondary'}
              onClick={() => getBenchmarks(dimensions, pivotColumn, true)}
            >
              Internal Promos
            </Button>
            <Button
              className="ml2"
              type={isInternal === false ? 'primary' : 'secondary'}
              onClick={() => getBenchmarks(dimensions, pivotColumn, false)}
            >
              External Promos
            </Button>
          </div>
          <div className="mb3 flex gap flex-wrap justify-between">
            <StatCard
              className="flex-grow"
              title="Conversion Rate"
              tooltip="Number of unique downloads divided by the number of impressions"
              stat={
                data && data.total.averageUniqueDownloadRate
                  ? `${(data.total.averageUniqueDownloadRate * 100).toFixed(3)}%`
                  : '-'
              }
            />
            <StatCard
              className="flex-grow"
              title="New Conversion Rate"
              tooltip="Number of new downloads divided by the number of impressions"
              stat={
                data && data.total.averageNewDownloadRate
                  ? `${(data.total.averageNewDownloadRate * 100).toFixed(3)}%`
                  : '-'
              }
            />
            <StatCard
              className="flex-grow"
              title="Total Sample Size"
              tooltip="Number of samples across which benchmark is measured"
              stat={data ? data.total.sampleSize : '-'}
            />
          </div>
        </div>
        <div className="flex flex-wrap justify-end items-center mb2">
          <Button
            className="ml2"
            type={pivotColumn === 'buyer_podcast_genre_id' ? 'primary' : 'secondary'}
            onClick={() => getBenchmarks(dimensions, 'buyer_podcast_genre_id', isInternal)}
          >
            Promoted Podcast's Genre
          </Button>
          <Button
            className="ml2"
            type={pivotColumn === 'seller_podcast_genre_id' ? 'primary' : 'secondary'}
            onClick={() => getBenchmarks(dimensions, 'seller_podcast_genre_id', isInternal)}
          >
            Podcast Ad's Genre
          </Button>
        </div>
      </div>
      {data && (
        <ReactTable
          defaultSorted={[
            {
              id: 'averageNewDownloadRate',
              desc: true,
            },
          ]}
          data={data.pivot}
          showPagination={false}
          pageSize={data.pivot.length}
          columns={columns}
          className="-highlight w-100 f5"
          NoDataComponent={() => null}
        />
      )}
    </div>
  );
}

export default BenchmarksView;
