"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.focusTransitionType = exports.focusTransitionFade = exports.focusMargin = exports.focusGapBorderStyle = exports.focusGapBorder = exports.focusDimensionsAndPosition = exports.focusBottomBorderStyle = exports.focusBottomBorder = exports.focusBorderWidth = exports.focusBorderColor = exports.focusBorderBaseStyle = exports.browserFocusReset = exports.absoluteBorder = void 0;
exports.getFocusDimensionsAndPosition = getFocusDimensionsAndPosition;
var _styledComponents = require("styled-components");
var _semanticTheme = require("../semantic-theme");
var focusBorderWidth = 3;
exports.focusBorderWidth = focusBorderWidth;
var focusMargin = -(focusBorderWidth * 2);
exports.focusMargin = focusMargin;
var focusBorderColor = (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialBase);
exports.focusBorderColor = focusBorderColor;
var focusTransitionFade = '200ms';
exports.focusTransitionFade = focusTransitionFade;
var focusTransitionType = 'ease-in';

/**
 * Removes default browser focus style.
 * Should be applied regardless of whether or not we are showing visible focus states.
 * */
exports.focusTransitionType = focusTransitionType;
var browserFocusReset = (0, _styledComponents.css)(["&:focus{outline:none;}"]);
exports.browserFocusReset = browserFocusReset;
function getFocusDimensionsAndPosition() {
  var dimension = "".concat(focusBorderWidth * 4, "px");
  var position = "-".concat(focusBorderWidth * 2, "px");
  return {
    dimension: dimension,
    position: position
  };
}
var focusDimensionsAndPosition = function focusDimensionsAndPosition() {
  var _getFocusDimensionsAn = getFocusDimensionsAndPosition(),
    dimension = _getFocusDimensionsAn.dimension,
    position = _getFocusDimensionsAn.position;
  return (0, _styledComponents.css)(["inline-size:", ";block-size:", ";top:", ";left:", ";"], "calc(100% + ".concat(dimension, ")"), "calc(100% + ".concat(dimension, ")"), position, position);
};
exports.focusDimensionsAndPosition = focusDimensionsAndPosition;
var absoluteBorder = function absoluteBorder() {
  var margin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : focusMargin;
  return (0, _styledComponents.css)(["position:absolute;top:", "px;left:", "px;right:", "px;bottom:", "px;"], margin, margin, margin, margin);
};
/** Base styles included in both `focusGapBorderStyle` and  `focusBottomBorderStyle` */
exports.absoluteBorder = absoluteBorder;
var focusBorderBaseStyle = (0, _styledComponents.css)(["display:block;position:absolute;pointer-events:none;transition:border-color ", " ", ";box-sizing:border-box;"], focusTransitionFade, focusTransitionType);

/** Can be added to a child element or pseudoelement to apply an invisible border that doesn't affect box sizing
 *  When focused, add a color to the border to make it appear */
exports.focusBorderBaseStyle = focusBorderBaseStyle;
var focusGapBorderStyle = function focusGapBorderStyle(borderRadius) {
  return (0, _styledComponents.css)(["", " ", " background:transparent;border-radius:", ";border:", "px solid transparent;"], focusBorderBaseStyle, focusDimensionsAndPosition(), borderRadius, focusBorderWidth);
};

/** Can be added to a child element or pseudoelement to apply an invisible border that doesn't affect box sizing
 *  When focused, add a color to the border to make it appear */
exports.focusGapBorderStyle = focusGapBorderStyle;
var focusBottomBorderStyle = function focusBottomBorderStyle(bottomBase) {
  return (0, _styledComponents.css)(["", " bottom:", "px;border-bottom:", "px solid transparent;width:100%;"], focusBorderBaseStyle, bottomBase - focusBorderWidth * 2, focusBorderWidth);
};

/** Mixin that applies a gap-style focus border to a pseudo element */
exports.focusBottomBorderStyle = focusBottomBorderStyle;
var focusGapBorder = function focusGapBorder(borderRadius) {
  var isUsingKeyboard = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var showUsingProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (0, _styledComponents.css)(["position:relative;", " ", ""], browserFocusReset, isUsingKeyboard && (0, _styledComponents.css)(["&::after{", " content:'';}&:focus::after{border-color:", ";}", ""], focusGapBorderStyle(borderRadius), focusBorderColor, showUsingProp && (0, _styledComponents.css)(["&::after{border-color:", ";}"], focusBorderColor)));
};

/** Mixin that applies a bottom-border style focus state to a pseudo element */
exports.focusGapBorder = focusGapBorder;
var focusBottomBorder = function focusBottomBorder() {
  var bottomBase = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var isUsingKeyboard = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  var showUsingProp = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return (0, _styledComponents.css)(["position:relative;", " ", ""], browserFocusReset, isUsingKeyboard && (0, _styledComponents.css)(["&::after{content:'';", "}&:focus::after{border-color:inherit;}", ""], focusBottomBorderStyle(bottomBase), showUsingProp && (0, _styledComponents.css)(["&::after{border-color:inherit;}"])));
};
exports.focusBottomBorder = focusBottomBorder;