import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import TrackingLinkSearchInput from 'shared/TrackingLinkSearchInput';
import PodcastSearchInput from 'shared/PodcastSearchInput';
import Button from 'shared/Button';
import Checkbox from 'shared/Checkbox';
import SectionDivider from 'shared/SectionDivider';
import AudioAdCampaignSearchInput from 'shared/AudioAdCampaignSearchInput';
import DateRangePicker from 'shared/DateRangePicker';
import EpisodeSearchInput from 'shared/EpisodeSearchInput';
import { createReport } from 'shared/api';
import AggregationWindowSelector from './components/AggregationWindowSelector';

import { definitions, InputTypes, MultiValueInputDefaultKeys } from './definitions';

const getInputComponent = (
  teamId,
  state,
  setState,
  type,
  key,
  keys,
  initialValues,
  required,
  dependsOn,
  options,
) => {
  switch (type) {
    case InputTypes.audioAdCampaignId:
      return (
        <AudioAdCampaignSearchInput
          teamId={teamId}
          onSelectAudioAdCampaign={audioAdCampaignId => {
            const _state = { ...state };
            _state[key] = audioAdCampaignId;
            setState(_state);
          }}
          selectedAudioAdCampaignId={state[key]}
          initialAudioAdCampaignId={initialValues[key]}
        />
      );
    case InputTypes.dateRange:
      let _keys;
      // If we are provided keys but they don't match what we need, throw error
      // Else default to startDate / endDate
      if (keys) {
        if (keys.length !== MultiValueInputDefaultKeys.dateRange.length) {
          throw new Error(`DateRange requires 2 keys, ${keys ? keys.length : 0} provided`);
        } else {
          _keys = keys;
        }
      } else {
        _keys = MultiValueInputDefaultKeys.dateRange;
      }

      return (
        <DateRangePicker
          errorOnInvalidRange={true}
          startDate={state[_keys[0]]}
          endDate={state[_keys[1]]}
          onChange={(startDate, endDate) => {
            const _state = { ...state };
            _state[_keys[0]] = startDate;
            _state[_keys[1]] = endDate;
            setState(_state);
          }}
          maxDate={new Date()}
        />
      );
    case InputTypes.aggregationWindow:
      return (
        <AggregationWindowSelector
          aggregationWindow={state[key]}
          onChange={aggregationWindow => {
            const _state = { ...state };
            _state[key] = aggregationWindow;
            setState(_state);
          }}
        />
      );
    case InputTypes.podcastId:
      return (
        <React.Fragment>
          <PodcastSearchInput
            teamId={teamId}
            initialPodcastId={initialValues[key]}
            onSelect={podcast => {
              const _state = { ...state };
              _state[key] = podcast ? podcast.id : null;
              setState(_state);
            }}
            showClearInput={!required}
          />
        </React.Fragment>
      );
    case InputTypes.episodeId:
      if (!dependsOn) {
        throw new Error(
          'Episode Component depends on podcast id state, you must define `dependsOn` in the report definition.',
        );
      }

      return (
        <React.Fragment>
          <EpisodeSearchInput
            podcastId={state[dependsOn]}
            teamId={teamId}
            onSelect={episode => {
              const _state = { ...state };
              _state[key] = episode ? episode.id : null;
              setState(_state);
            }}
            initialEpisodeId={initialValues[key]}
            showClearInput={!required}
          />
        </React.Fragment>
      );
    case InputTypes.trackingLinkId:
      return (
        <TrackingLinkSearchInput
          initialTrackingLinkId={initialValues[key]}
          onSelect={trackingLink => {
            const _state = { ...state };
            _state[key] = trackingLink ? trackingLink.id : null;
            setState(_state);
          }}
        />
      );
    default:
      throw new Error(`Provided input type has no component: ${type}`);
  }
};

// Validate initial values from url params and format/cast them to correct types if needed
const validateInitialValue = (value, type) => {
  switch (type) {
    case InputTypes.dateRange:
      try {
        return moment(value, 'MM/DD/YY').toDate();
      } catch (_) {
        return null;
      }
    default:
      return value;
  }
};

// Creates state dictionary based on definition
const getStateFromDefinition = (definition, initialValues) => {
  const state = {};

  definition.forEach(input => {
    const { key, keys, type, defaultValue } = input;

    const multiValueInputKeys = MultiValueInputDefaultKeys[type];

    // If input takes multiple keys
    if (multiValueInputKeys) {
      let _multiKeys = multiValueInputKeys;
      // Check for custom keys first
      if (keys) {
        // Custom keys must be override all default keys
        if (keys.length !== multiValueInputKeys.length) {
          throw new Error(`Different number of custom keys provided for ${type} than required`);
        }

        _multiKeys = keys;
      }

      // Then we set the initial value based on the following hierarchy:
      // Initial value from url params > default value from definitions > null
      _multiKeys.forEach(k => {
        if (initialValues[k]) {
          const validatedInitialValue = validateInitialValue(initialValues[k], type);
          state[k] = !!validatedInitialValue ? validatedInitialValue : null;
        } else if (!!defaultValue) {
          state[k] = defaultValue[k];
        } else {
          state[k] = null;
        }
      });
    } else {
      // For single values, still follow the same rules as above
      const k = !!key ? key : type;
      state[k] = !!initialValues[k] ? initialValues[k] : !!defaultValue ? defaultValue : null;
    }
  });

  return state;
};

// Validates inputs before sending to createReport
const validateInputs = (inputs, definition) => {
  Object.keys(inputs).forEach(key => {
    if (inputs[key]) return;
    const inputDef = definition.find(d => d.key === key || d.type === key);
    if (inputDef.required === true) {
      // This makes required default to false
      throw Error();
    }
  });
};

const ReportCreator = ({
  reportType,
  match,
  onCreate,
  onSuccess,
  onFailure,
  includeSendEmailOnComplete,
  initialValues,
}) => {
  const definition = definitions[reportType];
  const { teamId } = match.params;
  const stateFromDefinition = useMemo(() => getStateFromDefinition(definition, initialValues), []);

  const [state, setState] = useState(stateFromDefinition);
  const [showErrors, setShowErrors] = useState(false);
  const [sendEmailOnComplete, setSendEmailOnComplete] = useState(includeSendEmailOnComplete);
  const [requestInProgress, setRequestInProgress] = useState(false);

  const handleCreateReport = async () => {
    setRequestInProgress(true);

    onCreate();

    try {
      const res = await createReport({
        ...state,
        sendEmailOnComplete,
        reportType,
        teamId,
      });
      onSuccess();
    } catch (e) {
      onFailure();
      setRequestInProgress(false);
    }
  };

  // Generate all input components from selected definition
  const components = useMemo(() => {
    return definition.map(input => {
      const { type, header, required, errorMessage, key, keys, dependsOn, options } = input;

      // If component depends on another state to be set first, just return empty element
      if (dependsOn && !state[dependsOn]) {
        return null;
      }

      const _key = !!key ? key : type;

      const inputComponent = getInputComponent(
        teamId,
        state,
        setState,
        type,
        _key,
        keys,
        initialValues,
        required,
        dependsOn,
        options,
      );

      let showError = false;
      if (showErrors && required) {
        if (keys || MultiValueInputDefaultKeys[type]) {
          const _keys = !!keys ? keys : MultiValueInputDefaultKeys[type];
          showError = _keys.find(k => state[k] === null);
        } else {
          showError = state[_key] === null;
        }
      }

      return (
        <div key={_key}>
          <div className="header-font mid-gray mb2">{header}</div>
          {showError && (
            <div className="f6 red mb2">
              {!!errorMessage ? errorMessage : 'You must select an input'}
            </div>
          )}
          <div>{inputComponent}</div>
          <SectionDivider />
        </div>
      );
    });
  }, [state, showErrors]);

  return (
    <div className="mw6">
      {components}
      {includeSendEmailOnComplete && (
        <Checkbox
          isChecked={sendEmailOnComplete}
          onClick={() => setSendEmailOnComplete(!sendEmailOnComplete)}
          label="Send me an email when my report is ready"
          className="mb3"
        />
      )}
      <Button
        onClick={() => {
          setShowErrors(false);
          try {
            validateInputs(state, definition);
            handleCreateReport();
          } catch (_) {
            setShowErrors(true);
          }
        }}
        loading={requestInProgress}
        disabled={requestInProgress}
        size="large"
        type="create"
      >
        Generate Report
      </Button>
    </div>
  );
};

ReportCreator.propTypes = {
  reportType: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  onCreate: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  includeSendEmailOnComplete: PropTypes.bool,
  initialValues: PropTypes.object,
};

ReportCreator.defaultProps = {
  includeSendEmailOnComplete: true,
  initialValues: {},
};

export default withRouter(ReportCreator);
