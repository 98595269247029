import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import DownSVG from 'images/icons/caret-down-blue.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import { onClickHandler } from 'shared/a11y/lib';
import { fillPromoEpisode, getAudioRollupReach, getPromo } from 'shared/api';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import DownloadHistoryChart from 'shared/charts/DownloadHistoryChart';
import EpisodeSelector from 'shared/EpisodeSelector';
import Loading from 'shared/Loading';
import NewStatusIndicator from 'shared/NewStatusIndicator';

import LargeStatusWarnings from './LargeStatusWarnings';
import PixelManager from './PixelManager';
import PromoEditor from './PromoEditor';
import RollupTable from './RollupTable';
import StatCardHeader from './StatCardHeader';
import TopBar from './TopBar';

function AudioAdPlacementDetailView({
  initialState,
  history,
  match,
  clearAnalyticsTabState,
  clearManageTabState,
  storeState,
  selectedPodcast,
  lookbackWindow,
  isSeller,
  backUrl,
  canEditPromos,
  showData,
}) {
  const { teamId, audioAdPlacementId, audioAdCampaignId } = match.params;

  const [audioAdPlacement, setAudioAdPlacement] = useState(null);
  const [failedToLoadAudioAdPlacement, setFailedToLoadAudioAdPlacement] = useState(false);
  const [pixelManagerIsOpen, setPixelManagerIsOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [episodeFillInProgress, setEpisodeFillInProgress] = useState(false);
  const [episodeFillError, setEpisodeFillError] = useState(null);

  const [startDate, setStartDate] = useState(showData ? initialState.startDate : null);
  const [endDate, setEndDate] = useState(showData ? initialState.endDate : null);

  const [showDetails, setShowDetails] = useState(!showData);

  const [reach, setReach] = useState(null);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    handleGetAudioAdPlacement();
    if (!isSeller) {
      handleGetAudioRollupReach();
    }
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (!storeState || !showData) return;
    storeState({ startDate, endDate });
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  function handleGetAudioAdPlacement() {
    getPromo({
      promoId: audioAdPlacementId,
      teamId,
      startDate,
      endDate,
    })
      .then(res => setAudioAdPlacement(res.data))
      .catch(() => setFailedToLoadAudioAdPlacement(true));
  }

  function handleFillEpisode(episode) {
    if (episodeFillInProgress) return;
    setEpisodeFillInProgress(true);
    setEpisodeFillError(null);

    fillPromoEpisode({
      audioAdPlacementId,
      teamId,
      episodeId: episode.cid,
    })
      .then(res => {
        window.location.assign(
          `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics/${res.data.audioAdPlacementId}`,
        );
      })
      .catch(() => {
        setEpisodeFillError('Something went wrong trying to fill your episode');
        setEpisodeFillInProgress(false);
      });
  }

  function handleGetAudioRollupReach() {
    getAudioRollupReach({
      audioAdCampaignId: audioAdCampaignId,
      audioAdPlacementId,
      startDate,
      endDate,
    }).then(res => setReach(res.data));
  }

  function handleUpdatePromo(updatedAudioAdPlacement) {
    setAudioAdPlacement(updatedAudioAdPlacement);
    clearAnalyticsTabState();
    clearManageTabState();
    setEditing(false);
  }

  const renderTotalCost = () => {
    if (audioAdPlacement.totalCost) {
      return <div className="f5 dark-gray truncate">{audioAdPlacement.totalCost}</div>;
    }
    if (canEditPromos) {
      return (
        <div {...onClickHandler(() => setEditing(true))} className="blue link pointer i">
          Add total cost
        </div>
      );
    }
    return <div className="f5 dark-gray">-</div>;
  };

  const topBar = (
    <TopBar text={showData ? 'Analytics' : 'Manage'} podcast={selectedPodcast} backUrl={backUrl} />
  );

  if (failedToLoadAudioAdPlacement) {
    return (
      <div>
        {topBar}
        <BannerNotice
          error
          message="Failed to load SmartPromos. Please reload the page to try again."
        />
      </div>
    );
  }

  if (!audioAdPlacement) {
    return (
      <div>
        {topBar}
        <Loading fullScreen />
      </div>
    );
  }

  if (isSeller) {
    const destinationURL = `/teams/${match.params.teamId}/dashboard/adops/manage/${audioAdPlacement.placementMetadataId}`;
    window.location.assign(destinationURL);
    return (
      <div>
        {topBar}
        <div className="tc ph4 pv5">
          <div className="f3 force-inter mb3">This page has moved!</div>
          <div className="f5 force-inter mb1">You are being redirected to the adops page.</div>
          <a href={destinationURL} className="blue link pointer">
            Click here if you are not automatically redirected.
          </a>
        </div>
      </div>
    );
  }

  return (
    <div data-testid="audio-ad-placement-detail">
      {topBar}
      <div className="ph4 mb3 mt3">
        <div className="w-100 items-center flex flex-row justify-between gap mb3">
          <NewStatusIndicator
            className="f5 ttc b br4 ph3 pv1 help"
            status={audioAdPlacement.isArchived ? 'ARCHIVED' : audioAdPlacement.status.value}
            text={
              audioAdPlacement.status ? audioAdPlacement.status.value.toLowerCase() : 'archived'
            }
            tooltip={audioAdPlacement.isArchived ? null : audioAdPlacement.status.description}
          />
          {showData && (
            <div className="flex flex-wrap gap-small items-center">
              <ReactTooltip id="lookbackwindow" />
              <div
                data-for="lookbackwindow"
                data-tip="How many days after each impression we look to find conversions. Change in SmartPromos Settings."
                className="f7 tc br4 ph2 pv1 white bg-blue help"
              >
                {lookbackWindow} day attribution window
              </div>
              <ChartableDatePicker
                disabledDays={{ after: new Date() }}
                inlineStyle={{ width: 'max-content' }}
                startDate={startDate}
                endDate={endDate}
                handleUpdateDateRange={(start, end) => {
                  clearAnalyticsTabState();
                  setStartDate(start);
                  setEndDate(end);
                  setAudioAdPlacement(null);
                }}
              />
            </div>
          )}
        </div>
        <div className="mb2 flex gap justify-between flex-row items-baseline">
          <div className="f4">{audioAdPlacement.name}</div>
          {canEditPromos && (
            <Button type="primary" onClick={() => setEditing(true)} size="small" className="w3 f6">
              Edit
            </Button>
          )}
        </div>
        <div className="card pa3 mb3">
          <div className="flex flex-wrap" style={{ gap: '20px 0' }}>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoting</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {audioAdPlacement.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={audioAdPlacement.buyerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {audioAdPlacement.buyerPodcast
                    ? audioAdPlacement.buyerPodcast.shortTitle
                    : `${audioAdPlacement.buyerTeam} network`}
                </div>
              </div>
            </div>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoted on</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {audioAdPlacement.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={audioAdPlacement.sellerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {audioAdPlacement.sellerPodcast
                    ? audioAdPlacement.sellerPodcast.shortTitle
                    : `${audioAdPlacement.sellerTeam} network`}
                </div>
              </div>
            </div>
            {audioAdPlacement.episodeTitle && (
              <div style={{ flex: '50%', maxWidth: '100%' }}>
                <div className="f6 gray mb1 b">Episode</div>
                <div className="f5 dark-gray truncate">{audioAdPlacement.episodeTitle}</div>
              </div>
            )}
            {showDetails && (
              <React.Fragment>
                {audioAdPlacement.episode && (
                  <div style={{ flex: '50%' }}>
                    <div className="f6 gray mb1 b">Publish Date</div>
                    <div className="f5 dark-gray truncate">
                      {moment(audioAdPlacement.episode.publishedAt).format('MMM D, YYYY')}
                    </div>
                  </div>
                )}
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Publisher</div>
                  <div className="f5 dark-gray truncate">{audioAdPlacement.sellerTeam}</div>
                </div>
                {audioAdPlacement.adType && (
                  <div style={{ flex: '50%' }}>
                    <div className="f6 gray mb1 b">Ad Type</div>
                    <div className="f5 dark-gray truncate">{audioAdPlacement.adType}</div>
                  </div>
                )}
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Placement Type</div>
                  <div className="f5 dark-gray truncate">
                    {audioAdPlacement.campaignType === 'dai' ? 'Dynamic' : 'Baked-in'}
                  </div>
                </div>
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Total Cost</div>
                  {renderTotalCost()}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="flex flex-row mt3">
            <div
              {...onClickHandler(() => setShowDetails(!showDetails))}
              className="pointer f6 blue link"
            >
              See {showDetails ? 'less' : 'more'}
            </div>
            <img
              alt="down icon"
              src={DownSVG}
              style={{
                height: '15px',
                marginTop: '1px',
                transform: `scaleY(${showDetails ? '-1' : '1'})`,
              }}
            />
          </div>
        </div>
        {audioAdPlacement.campaignType === 'dai' && (
          <Button onClick={() => setPixelManagerIsOpen(true)} className="mw5 mb3" type="primary">
            View Tracking Pixel Code
          </Button>
        )}
        <LargeStatusWarnings promo={audioAdPlacement} teamId={teamId} isSeller={false} />
        {showData ? (
          <>
            <StatCardHeader className="mb3" stats={audioAdPlacement} reach={reach} />
            <React.Fragment>
              {audioAdPlacement.podcasts && (
                <RollupTable
                  rollups={audioAdPlacement.podcasts}
                  dimension="Podcast"
                  onRowClick={row => {
                    if (row.original.podcastId) {
                      history.push(`${match.url}/byPodcast/${row.original.podcastId}`);
                    }
                  }}
                />
              )}
              <div className="mt4">
                {audioAdPlacement.uniqueDownloads.history &&
                  audioAdPlacement.uniqueDownloads.history.length > 0 && (
                    <div>
                      <DownloadHistoryChart
                        title="Unique Converted Devices"
                        downloadHistory={audioAdPlacement.uniqueDownloads.history}
                        hideSelectors
                        loading={false}
                        height="480px"
                      />
                    </div>
                  )}
                {audioAdPlacement.newDownloads.history &&
                  audioAdPlacement.uniqueDownloads.history.length > 0 && (
                    <div>
                      <DownloadHistoryChart
                        title="New Unique Converted Devices"
                        downloadHistory={audioAdPlacement.newDownloads.history}
                        hideSelectors
                        loading={false}
                        height="480px"
                      />
                    </div>
                  )}
                {audioAdPlacement.impressions.history &&
                  audioAdPlacement.impressions.history.length > 0 && (
                    <DownloadHistoryChart
                      title="Impressions"
                      downloadHistory={audioAdPlacement.impressions.history}
                      hideSelectors
                      loading={false}
                      height="480px"
                    />
                  )}
              </div>
            </React.Fragment>
          </>
        ) : (
          <>
            {audioAdPlacement.status.value === 'ACTIVE' && (
              <Banner success noImage>
                Data is available on the analytics page.{' '}
                <Link
                  className="link pointer underline"
                  to={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics/${audioAdPlacementId}`}
                >
                  Go to analytics dashboard →
                </Link>
              </Banner>
            )}
          </>
        )}
        {audioAdPlacement.campaignType === 'baked_in' &&
          !!audioAdPlacement.sellerPodcast &&
          !audioAdPlacement.episode && (
            <>
              {audioAdPlacement.isInternal &&
              moment(audioAdPlacement.startedAt).isBefore(new Date()) ? (
                <div className="mw6">
                  {episodeFillError && (
                    <Banner className="mb2" error>
                      {episodeFillError}
                    </Banner>
                  )}
                  <EpisodeSelector
                    teamId={teamId}
                    podcastId={audioAdPlacement.sellerPodcast.slug}
                    scheduledAirDate={audioAdPlacement.startedAt}
                    onSelect={episode => handleFillEpisode(episode)}
                    fillInProgress={episodeFillInProgress}
                    buttonText="Select Episode"
                  />
                </div>
              ) : (
                <Banner className="f5 mb3" noImage>
                  You will be able select the episode the promo aired on after the scheduled air
                  date, {moment(audioAdPlacement.startedAt).format('MM/DD/YYYY')}
                </Banner>
              )}
            </>
          )}
        <BasicModal isOpen={editing} onRequestClose={() => setEditing(false)} ariaHideApp={false}>
          <PromoEditor
            teamId={teamId}
            audioAdPlacement={audioAdPlacement}
            onCancel={() => setEditing(false)}
            onSuccess={handleUpdatePromo}
            includeDetailsAfterUpdate
          />
        </BasicModal>
        <BasicModal
          isOpen={pixelManagerIsOpen}
          onRequestClose={() => setPixelManagerIsOpen(false)}
          ariaHideApp={false}
        >
          <PixelManager
            canInstallPixelCode={audioAdPlacement.isInternal}
            onClose={() => setPixelManagerIsOpen(false)}
            pixel={audioAdPlacement.pixel}
            teamId={teamId}
            promo={audioAdPlacement}
          />
        </BasicModal>
      </div>
    </div>
  );
}

AudioAdPlacementDetailView.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  backUrl: PropTypes.string.isRequired,
  lookbackWindow: PropTypes.number,
  clearAnalyticsTabState: PropTypes.func,
  storeState: PropTypes.func,
  initialState: PropTypes.object,
  selectedPodcast: PropTypes.object,
  isSeller: PropTypes.bool,
  showData: PropTypes.bool,
};

AudioAdPlacementDetailView.defaultProps = {
  initialState: { startDate: moment().subtract(30, 'days').toDate(), endDate: moment().toDate() },
  selectedPodcast: null,
  isSeller: false,
  showData: true,
};

export default AudioAdPlacementDetailView;
