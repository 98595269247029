import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import DownSVG from 'images/icons/caret-down-blue.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import { onClickHandler } from 'shared/a11y/lib';
import {
  archivePromo,
  getAudioAdCampaign,
  getAudioRollupReach,
  getBuyerPromos,
  updateTeamUserPreferences,
} from 'shared/api';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import DownloadHistoryChart from 'shared/charts/DownloadHistoryChart';
import ColumnSelector from 'shared/ColumnSelector';
import useDebounce from 'shared/hooks/useDebounce';
import Loading from 'shared/Loading';
import PopoutSelect from 'shared/PopoutSelect';

import ErrorResolutionExplainer from '../ErrorResolutionExplainer';
import PromoEditor from '../PromoEditor';
import StatCardHeader from '../StatCardHeader';
import PromoTable from './components/PromoTable';
import RollupByPodcastView from './components/RollupByPodcastView';

const tableOptions = [
  { value: 'promotion', label: 'Promotion' },
  { value: 'podcast', label: 'Podcast' },
];

function reducer(state, action) {
  switch (action.type) {
    case 'setAudioAdPlacements':
      return {
        ...state,
        totalPlacements: action.payload.metadata.total,
        audioAdPlacements: action.payload.data,
      };
    case 'updateAudioAdPlacements':
      return { ...state, audioAdPlacements: action.payload };
    case 'setReach':
      return { ...state, reach: action.payload };
    case 'setAudioAdCampaign':
      return { ...state, audioAdCampaign: action.payload };
    case 'setDateRange':
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        audioAdCampaign: null,
        audioAdCampaignData: null,
        audioAdCampaignPlacements: null,
      };
    default:
      throw new Error();
  }
}

function Analytics(props) {
  const {
    initialState,
    match,
    allColumns,
    clearManageTabState,
    storeState,
    history,
    byPodcast,
    lookbackWindow,
    selectedColumns,
  } = props;

  const renderCount = useRef(0);
  renderCount.current += 1;

  const pageSize = 20;

  const [state, dispatch] = useReducer(reducer, initialState);

  const { teamId, audioAdCampaignId } = match.params;

  const [columnSelectorIsOpen, setColumnSelectorIsOpen] = useState(false);
  const [selectedAudioAdPlacement, setSelectedAudioAdPlacement] = useState(null);
  const [promoEditorIsOpen, setPromoEditorIsOpen] = useState(false);
  const [errorResolutionIsOpen, setErrorResolutionIsOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [sortDesc, setSortDesc] = useState(true);
  const [sortBy, setSortBy] = useState('startedAt');
  const [promoTableIsLoading, setPromoTableIsLoading] = useState(false);

  const [promoNameSearchString, setPromoNameSearchString] = useState('');

  const [failedToLoadAudioAdCampaign, setFailedToLoadAudioAdCampaign] = useState(false);
  const [failedToLoadAudioAdPlacements, setFailedToLoadAudioAdPlacements] = useState(false);

  const debouncedNameSearchString = useDebounce(promoNameSearchString, 400);

  const { startDate, endDate, audioAdCampaign, reach, audioAdPlacements, totalPlacements } = state;

  useEffect(() => {
    return;
  }, [debouncedNameSearchString]);

  useEffect(() => {
    if (audioAdCampaign && audioAdCampaign.audioAdCampaignId === audioAdCampaignId) return;
    if (audioAdCampaign) {
      dispatch({ type: 'setAudioAdCampaign', payload: null });
      clearManageTabState();
    }

    handleGetAudioAdCampaign();
    handleGetAudioAdPlacements();
    handleGetAudioRollupReach();
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioAdCampaignId, startDate, endDate]);

  useEffect(() => {
    if (!audioAdPlacements) return;
    handleGetAudioAdPlacements();
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sortBy, sortDesc, debouncedNameSearchString]);

  useEffect(() => {
    storeState({ ...state });
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioAdPlacements, audioAdCampaign, startDate, endDate]);

  const selectedTableOption = useMemo(
    () => (byPodcast ? tableOptions[1] : tableOptions[0]),
    [byPodcast],
  );

  function handleGetAudioAdPlacements() {
    setPromoTableIsLoading(true);

    getBuyerPromos({
      page,
      pageSize,
      teamId,
      audioAdCampaignId,
      startDate,
      endDate,
      includeArchived: false,
      includeConversions: true,
      sortBy: sortBy,
      sortDesc: sortDesc,
      nameQuery: debouncedNameSearchString,
    })
      .then(res => {
        dispatch({ type: 'setAudioAdPlacements', payload: res.data });
      })
      .catch(() => setFailedToLoadAudioAdPlacements(true))
      .finally(() => setPromoTableIsLoading(false));
  }

  function handleGetAudioAdCampaign() {
    getAudioAdCampaign({ teamId, audioAdCampaignId, startDate, endDate, includeTimeseries: true })
      .then(res => dispatch({ type: 'setAudioAdCampaign', payload: res.data }))
      .catch(() => setFailedToLoadAudioAdCampaign(true));
  }

  function handleUpdateSelectedColumns(columns) {
    updateTeamUserPreferences({ teamId, selectedPromoAnalyticsColumns: columns }).then(() =>
      window.location.reload(),
    );
  }

  function handleUpdatePromo(updatedAudioAdPlacement) {
    const _audioAdPlacements = audioAdPlacements.map(s =>
      s.audioAdPlacementId === updatedAudioAdPlacement.audioAdPlacementId
        ? updatedAudioAdPlacement
        : s,
    );
    dispatch({ type: 'updateAudioAdPlacements', payload: _audioAdPlacements });
    clearManageTabState();
    closeModal();
  }

  function handleArchivePromo(audioAdPlacementId) {
    archivePromo({ audioAdPlacementId, teamId, isArchived: true }).then(() => {
      dispatch({ type: 'setAudioAdCampaign', payload: null });
      handleGetAudioAdCampaign();
      handleGetAudioAdPlacements();
      clearManageTabState();
    });
  }

  function closeModal() {
    setSelectedAudioAdPlacement(null);
    setPromoEditorIsOpen(false);
    setErrorResolutionIsOpen(false);
    setColumnSelectorIsOpen(false);
  }

  function handleGetAudioRollupReach() {
    getAudioRollupReach({
      audioAdCampaignId,
      startDate,
      endDate,
    }).then(res => dispatch({ type: 'setReach', payload: res.data }));
  }

  if (failedToLoadAudioAdCampaign) {
    return (
      <BannerNotice
        error
        message="Failed to load SmartPromos. Please reload the page to try again."
      />
    );
  }

  if (!audioAdCampaign) {
    return (
      <div className="w-100 mt4 tc">
        <Loading />
      </div>
    );
  }

  const renderFailedToLoadAudioAdPlacements = failedToLoad => {
    if (!failedToLoad) return null;
    return (
      <Banner
        onClick={() => this.setState({ overlappingCampaignId: null })}
        className="mb3"
        type="error"
      >
        <div className="f6 force-inter">
          <b>Failed to load list of promos</b>
        </div>
      </Banner>
    );
  };

  const renderAudioAdPlacements = placements => {
    if (!placements) {
      return (
        <div className="w-100 mt4 tc">
          <Loading />
        </div>
      );
    }
    return (
      <Switch>
        <Route exact path="/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics/byPodcast">
          <RollupByPodcastView
            match={match}
            history={history}
            campaign={audioAdCampaign}
            teamId={teamId}
            startDate={startDate}
            endDate={endDate}
          />
        </Route>
        <Route exact path="/teams/:teamId/dashboard/promos/:audioAdCampaignId/analytics">
          <React.Fragment>
            <input
              value={promoNameSearchString}
              className="pa2 input-reset ba bg-white br2 b--moon-gray mb2 w-100"
              type="text"
              placeholder="Search By Promo Name"
              onChange={e => setPromoNameSearchString(e.target.value)}
            />
            <PromoTable
              loading={promoTableIsLoading}
              page={page}
              total={totalPlacements}
              promos={audioAdPlacements}
              teamId={teamId}
              campaignId={audioAdCampaignId}
              selectedColumns={selectedColumns}
              setPage={setPage}
              sortDesc={sortDesc}
              setSortDesc={setSortDesc}
              sortBy={sortBy}
              setSortBy={setSortBy}
              editPromo={selectedPlacement => {
                setPromoEditorIsOpen(true);
                setSelectedAudioAdPlacement(selectedPlacement);
              }}
              openErrorExplainer={selectedPlacement => {
                setErrorResolutionIsOpen(true);
                setSelectedAudioAdPlacement(selectedPlacement);
              }}
              archivePromo={handleArchivePromo}
            />
          </React.Fragment>
        </Route>
      </Switch>
    );
  };

  return (
    <div className="mt3 mb5 ph4">
      {failedToLoadAudioAdCampaign ? (
        <div>Error</div>
      ) : (
        <React.Fragment>
          <div className="mb3 w-100">
            <div className="flex flex-wrap gap-small items-center justify-end">
              <ReactTooltip id="lookbackwindow" />
              <div
                data-for="lookbackwindow"
                data-tip="How many days after each impression we look to find conversions. Change in SmartPromos Settings."
                className="f7 tc br4 ph2 pv1 white bg-blue help"
              >
                {lookbackWindow} day attribution window
              </div>
              <ChartableDatePicker
                disabledDays={{ after: new Date() }}
                inlineStyle={{ width: 'max-content' }}
                startDate={startDate}
                endDate={endDate}
                handleUpdateDateRange={(newStart, newEnd) => {
                  dispatch({
                    type: 'setDateRange',
                    payload: { startDate: newStart, endDate: newEnd },
                  });
                }}
              />
            </div>
          </div>
          <StatCardHeader
            className="mb3"
            stats={audioAdCampaign}
            reach={reach}
            showIncrementalReach={false}
          />
        </React.Fragment>
      )}
      <div>
        <div className="flex flex-row mb2 items-baseline">
          <div className="mr1">Stats by</div>
          <PopoutSelect
            innerContent={
              <div className="hover dim pointer blue flex flex-row items-baseline">
                <div className="f5 b">{selectedTableOption.label}</div>
                <img
                  style={{ marginTop: '3px', alignSelf: 'flex-end' }}
                  className="h-auto w1"
                  src={DownSVG}
                  alt=""
                />
              </div>
            }
            value={selectedTableOption}
            options={tableOptions}
            onSelect={selection => {
              const baseUrl = `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics`;
              history.push(selection.value === 'podcast' ? `${baseUrl}/byPodcast` : baseUrl);
            }}
          />
          {!byPodcast && (
            <div
              className="ml-auto f6 link blue pointer"
              {...onClickHandler(() => setColumnSelectorIsOpen(true))}
            >
              edit columns
            </div>
          )}
        </div>
        {renderFailedToLoadAudioAdPlacements(failedToLoadAudioAdPlacements)}
        {!failedToLoadAudioAdPlacements && renderAudioAdPlacements(audioAdPlacements)}
      </div>
      <div className="mt4">
        {audioAdCampaign.uniqueDownloads.history &&
          audioAdCampaign.uniqueDownloads.history.length > 0 && (
            <div>
              <DownloadHistoryChart
                title="Unique Converted Devices"
                downloadHistory={audioAdCampaign.uniqueDownloads.history}
                hideSelectors
                loading={false}
                height="480px"
              />
            </div>
          )}
        {audioAdCampaign.newDownloads.history &&
          audioAdCampaign.uniqueDownloads.history.length > 0 && (
            <div>
              <DownloadHistoryChart
                title="New Unique Converted Devices"
                downloadHistory={audioAdCampaign.newDownloads.history}
                hideSelectors
                loading={false}
                height="480px"
              />
            </div>
          )}
        {audioAdCampaign.impressions.history && audioAdCampaign.impressions.history.length > 0 && (
          <DownloadHistoryChart
            title="Impressions"
            downloadHistory={audioAdCampaign.impressions.history}
            hideSelectors
            loading={false}
            height="480px"
          />
        )}
      </div>
      {!!selectedAudioAdPlacement && (
        <BasicModal isOpen onRequestClose={closeModal} ariaHideApp={false}>
          {promoEditorIsOpen && (
            <PromoEditor
              teamId={teamId}
              audioAdPlacement={selectedAudioAdPlacement}
              onCancel={closeModal}
              onSuccess={handleUpdatePromo}
              includeDetailsAfterUpdate
            />
          )}
          {errorResolutionIsOpen && (
            <React.Fragment>
              <ErrorResolutionExplainer promo={selectedAudioAdPlacement} />
              <Button onClick={closeModal}>Close</Button>
            </React.Fragment>
          )}
        </BasicModal>
      )}
      <BasicModal isOpen={columnSelectorIsOpen} onRequestClose={closeModal} ariaHideApp={false}>
        <React.Fragment>
          <ColumnSelector
            title="Edit Table Columns"
            allColumns={allColumns}
            selectedColumns={selectedColumns}
            onSave={handleUpdateSelectedColumns}
          />
          <Button className="mt2" onClick={() => setColumnSelectorIsOpen(false)}>
            Close
          </Button>
        </React.Fragment>
      </BasicModal>
    </div>
  );
}

Analytics.propTypes = {
  match: PropTypes.object.isRequired,
  allColumns: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  clearManageTabState: PropTypes.func.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  storeState: PropTypes.func.isRequired,
  initialState: PropTypes.object,
  byPodcast: PropTypes.bool,
};

Analytics.defaultProps = {
  initialState: { startDate: moment().subtract(30, 'days').toDate(), endDate: moment().toDate() },
  byPodcast: false,
};

export default Analytics;
