import React, { useState } from 'react';
import moment from 'moment';

import Confirmation from './Confirmation';

const KeyGen = ({ activeKey, createKey, delayEvent, keyCreated }) => {
  const [hidden, setHidden] = useState(true);
  const [confirming, setConfirming] = useState(false);
  const [popUpText, setpopUpText] = useState(null);

  const handleClickCreate = () => {
    if (activeKey) {
      setConfirming(true);
    } else {
      createKey();
    }
  };

  const handleCreate = () => {
    setConfirming(false);
    createKey();
  };

  const createButton = () => {
    return (
      <div
        className="pointer dim br2 tc ba f6 pa3 mw5 bg-blue white b--blue mt3"
        onClick={handleClickCreate}
      >
        Create {!activeKey ? 'an' : 'a new'} API key
      </div>
    );
  };

  const copyKey = () => {
    navigator.clipboard.writeText(activeKey.token);
    setpopUpText('Copied to clipboard');
    delayEvent(1000, setpopUpText, null);
    delayEvent(1000, setHidden, true);
  };

  const keyView = () => {
    if (!activeKey) {
      return (
        <div>
          There are no active API keys for your team. Click "Create an API Key" to generate a new
          key.
        </div>
      );
    }

    const { user } = activeKey;
    const day = moment(activeKey.createdAt).format('MM/DD/YYYY');
    const time = moment(activeKey.createdAt).format('HH:mm');

    return (
      <div>
        <h3 className="mb4">
          Your API Key: <small>(click to copy to your clipboard)</small>
        </h3>
        {keyCreated && (
          <div style={{ height: 0, position: 'relative', top: '-22px' }} className="bold f6 green">
            A new key has been created. Click "Show" to view.
          </div>
        )}
        <input
          onClick={() => copyKey()}
          className="br2 w-90 code dib f6 tc bg-transparent pa3 ba b--blue pointer"
          readOnly
          type={hidden ? 'password' : 'text'}
          value={activeKey.token}
        />
        <div
          className="dib pointer dim br2 tc ba f6 pa3 blue b--blue bg-white ml1"
          onClick={() => setHidden(!hidden)}
        >
          {hidden ? 'Show' : 'Hide'}
        </div>
        <small className="db mt2">
          Created by {user} on {day} at {time}
        </small>
        {popUpText && (
          <div className="tc w-90" style={{ height: 0, position: 'relative', top: '-42px' }}>
            <div className="dib pa2" style={{ background: '#0000009e', color: 'white' }}>
              {popUpText}
            </div>
          </div>
        )}
      </div>
    );
  };
  return (
    <div className="mb5">
      {confirming && <Confirmation setConfirming={setConfirming} createKey={handleCreate} />}
      {keyView()}
      {createButton()}
    </div>
  );
};

export default KeyGen;
