import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Chart } from 'react-google-charts';
import ReactTooltip from 'react-tooltip';

import Loading from 'shared/Loading';
import { addUtcOffset } from 'shared/helpers';

import TitleBar from 'shared/charts/TitleBar';

const ChartContainer = ({ rows, chartType, options, columns, width, height }) => {
  return (
    <Chart
      chartType={chartType}
      options={options}
      columns={columns}
      rows={rows}
      width={width}
      height={height}
    />
  );
};

const dataChanged = (prevProps, nextProps) => {
  return JSON.stringify(prevProps.rows) === JSON.stringify(nextProps.rows);
};

const WrappedChartContainer = React.memo(ChartContainer, dataChanged);

function DownloadHistoryChart({
  title,
  subtitle,
  legendPosition,
  downloadHistory,
  numDays,
  aggregation,
  loading,
  onGetDownloadsHistory,
  height,
  includeHourly,
  trackableUrl,
  hideSelectors,
  dualAxes,
}) {
  if (loading) {
    return (
      <div>
        <TitleBar title={title || 'Downloads'} subtitle={subtitle} />
        <div className="mt2 w-100 tc center h-2">
          <Loading />
        </div>
      </div>
    );
  }

  const numDayOptions = {
    7: { value: 7, label: '7 Days' },
    30: { value: 30, label: '30 Days' },
    60: { value: 60, label: '60 Days' },
    180: { value: 180, label: '180 Days' },
    365: { value: 365, label: '365 Days' },
  };

  const baseAggregationOptions = {};
  if (includeHourly) {
    baseAggregationOptions.hourly = { value: 'hourly', label: 'Hourly' };
  }
  const aggregationOptions = Object.assign(baseAggregationOptions, {
    daily: { value: 'daily', label: 'Daily' },
    weekly: { value: 'weekly', label: 'Weekly' },
    monthly: { value: 'monthly', label: 'Monthly' },
  });

  let isDate = false;
  let chartColumns = [];
  let rows = [];
  let hAxis = {};
  let vAxis = {};

  if (downloadHistory && downloadHistory[0]) {
    chartColumns = downloadHistory[0].map(col => {
      if (col === 'Date') {
        isDate = true;
        return {
          type: aggregation === 'hourly' ? 'datetime' : 'date',
          label: 'Date',
        };
      }

      return {
        type: 'number',
        label: col,
      };
    });
    vAxis = {
      minorGridlines: {
        count: 0,
      },
    };

    rows = JSON.parse(JSON.stringify(downloadHistory)).splice(1);
    if (isDate) {
      rows = rows.map(t => {
        const displayDate = addUtcOffset(new Date(t[0]));
        return [displayDate].concat(t.splice(1));
      });

      hAxis = {
        type: 'date',
        format: 'MMM d',
        maxAlternation: 1,
        slantedText: false,
        gridlines: {
          count: 0,
        },
      };
      if (rows.length === 1) {
        hAxis.minValue = new Date(new Date(rows[0][0].valueOf()).setDate(rows[0][0].getDate() - 1));
        hAxis.maxValue = new Date(new Date(rows[0][0].valueOf()).setDate(rows[0][0].getDate() + 1));
      }
      hAxis.ticks = rows.map(t => t[0]);
    } else {
      hAxis.title = downloadHistory[0][0];
    }
  }

  const chartOptions = {
    displayZoomButtons: false,
    displayRangeSelector: false,
    hAxis,
    vAxis,
    axes: { y: { all: { range: { min: 0 } } } },
    legend: { position: legendPosition },
    chartArea: {
      left: dualAxes ? 60 : 50,
      top: legendPosition === 'top' ? 50 : 20,
      width: legendPosition === 'right' || dualAxes ? '80%' : '90%',
      height: legendPosition === 'top' ? '70%' : '80%',
    },
    interpolateNulls: true,
    backgroundColor: {
      strokeWidth: 0,
      fill: 'transparent',
    },
    thickness: 3,
    pointSize: 5,
    series: {
      0: { color: title == 'Confirmed Plays' ? '#4B9EC1' : '#9BC53D' },
    },
  };

  if (dualAxes && chartColumns.length > 2) {
    chartOptions.axes = { y: { 0: {}, 1: { side: 'right' } } };
    let colors = ['#4B9EC1', '#9BC53D', '#FA7921', '#885A9A'];
    const series = {};
    for (let i = 1; i < chartColumns.length; i++) {
      series[i - 1] = {
        targetAxisIndex: Math.floor((2 * i) / chartColumns.length),
        color: colors[i - 1],
      };
    }
    chartOptions.series = series;
  }

  return (
    <div className="mb4">
      <ReactTooltip />
      <div className="w-100 tl flex flex-wrap">
        <TitleBar title={title || 'Downloads'} subtitle={subtitle} />
        {!hideSelectors && (
          <div className="w-50-l w-100 pa2-s cf">
            <div className="fr-l fl w-50-ns w-third-l w-100">
              <Select
                value={numDayOptions[numDays]}
                onChange={e => onGetDownloadsHistory({ numDays: e.value })}
                options={Object.values(numDayOptions)}
              />
            </div>
            <div className="fr-l fl w-50-ns w-third-l w-100">
              <Select
                value={aggregationOptions[aggregation]}
                onChange={e => onGetDownloadsHistory({ aggregation: e.value })}
                options={Object.values(aggregationOptions)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt2 bg-white br2 relative" style={{ zIndex: 1 }}>
        {downloadHistory && (
          <div>
            <WrappedChartContainer
              chartType="AreaChart"
              options={chartOptions}
              columns={chartColumns}
              rows={rows}
              width="100%"
              height={height || '400px'}
            />
          </div>
        )}
      </div>
    </div>
  );
}

DownloadHistoryChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  legendPosition: PropTypes.string,
  downloadHistory: PropTypes.array,
  aggregation: PropTypes.string,
  numDays: PropTypes.number,
  onGetDownloadsHistory: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  height: PropTypes.string,
  includeHourly: PropTypes.bool,
  trackableUrl: PropTypes.string,
  hideSelectors: PropTypes.bool,
  dualAxes: PropTypes.bool,
};

DownloadHistoryChart.defaultProps = {
  title: null,
  legendPosition: 'none',
  downloadHistory: [],
  numDays: null,
  aggregation: null,
  subtitle: null,
  height: '400px',
  includeHourly: false,
  trackableUrl: null,
  hideSelectors: false,
  onGetDownloadsHistory: null,
  dualAxes: false,
};

export default DownloadHistoryChart;
