import React from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';

const TOUR_STEPS = [
  {
    target: '.tour-step-one',
    content:
      'The Retention Chart shows how new downloaders continue to engage with your podcast over time.\nClick next to learn more about how it works!',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-two',
    content: 'Each row shows a "cohort," which corresponds to a grouping of new users.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-three',
    content:
      'The date on the left side is the first month of the cohort. Your cohort is made up of new downloaders who began listening during this month.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-four',
    content:
      'This number represents the percentage of downloaders in this cohort who listened during this month. The higher this number is, the more users are continuing to engage with your podcast.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.tour-step-five',
    content:
      'Your average download per device, shows the number of times a unique user in your cohort downloaded an episode of your podcast.\nTypically, this number is the same as the number of episodes you release per month.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
];

const RetentionTour = ({ showTour }) => (
  <Joyride
    run={showTour}
    disableScrollParentFix={true}
    continuous={true}
    steps={TOUR_STEPS}
    showProgress={true}
    showSkipButton={true}
    disableScrolling={true}
    styles={{
      options: {
        primaryColor: '#4B9EC1',
      },
    }}
  />
);

RetentionTour.propTypes = {
  showTour: PropTypes.bool,
};

RetentionTour.defaultProp = {
  showTour: false,
};

export default RetentionTour;
