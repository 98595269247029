"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDeprecatedButtonFont = exports.deprecatedTypeVariants = exports.deprecatedButtonPaddingTopBottom = exports.buttonSizesWithDeprecationWarning = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
var _ = require("../");
var buttonSizesWithDeprecationWarning = {
  /**
   * @deprecated
   * Use buttonSize="sm" instead.
   */
  sm: 'sm',
  /**
   * @deprecated
   * Use buttonSize="md" instead.
   */
  md: 'md',
  /**
   * @deprecated
   * Use buttonSize="lg" instead.
   */
  lg: 'lg'
};
exports.buttonSizesWithDeprecationWarning = buttonSizesWithDeprecationWarning;
var deprecatedTypeVariants = {
  sm: 'finaleBold',
  md: 'violaBold',
  lg: 'balladBold'
};
exports.deprecatedTypeVariants = deprecatedTypeVariants;
var getDeprecatedButtonFont = function getDeprecatedButtonFont(buttonSize) {
  switch (buttonSize) {
    case 'sm':
      return (0, _.finaleBold)();
    case 'md':
      return (0, _.violaBold)();
    case 'lg':
      return (0, _.balladBold)();
    default:
      return (0, _.violaBold)();
  }
};
exports.getDeprecatedButtonFont = getDeprecatedButtonFont;
var deprecatedButtonPaddingTopBottom = {
  sm: _encoreFoundation.spacer8,
  md: '14px',
  lg: _encoreFoundation.spacer16
};
exports.deprecatedButtonPaddingTopBottom = deprecatedButtonPaddingTopBottom;