import React from 'react';
import ReactTable from 'react-table';

export default function BasicTable(props) {
  const derivedProps = { ...props };
  if (!derivedProps.className) {
    derivedProps.className = '-highlight w-100';
  }
  if (!derivedProps.showPagination) {
    derivedProps.showPagination = false;
  }
  if (!derivedProps.filterable) {
    derivedProps.filterable = false;
  }
  if (!derivedProps.pageSize && !derivedProps.showPagination && derivedProps.data) {
    derivedProps.pageSize = derivedProps.data.length;
  }

  return <ReactTable {...derivedProps} />;
}
