import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Select from 'react-select';

export default class CountryDemographicsChart extends Component {
  constructor(props) {
    super(props);

    this.handleLocationSwitch = this.handleLocationSwitch.bind(this);

    const { column, countryDemographics } = this.props;

    const selectedColumn = column || Object.keys(countryDemographics)[0];

    this.state = {
      selectedColumn,
    };
  }

  handleLocationSwitch(e) {
    this.setState({
      selectedColumn: e.value,
    });
  }

  render() {
    const { countryDemographics, title, subtitle, handleShowCities } = this.props;

    const { selectedColumn } = this.state;

    const chartOptions = {
      legend: 'none',
      backgroundColor: {
        strokeWidth: 0,
        fill: 'transparent',
      },
    };

    const locationOptions = {};
    Object.keys(countryDemographics).forEach(
      v => (locationOptions[v] = { value: v, label: v.charAt(0).toUpperCase() + v.slice(1) }),
    );
    const demographics = countryDemographics[selectedColumn];

    // A customer complained about this so I'm pushing this fix now,
    // but it is still a problem. Wrapping it in a try/catch for now.
    // The issue is that selectedColumn is not coming up with a valid column in countryDemographics.
    // Existing rollbar issue here -> https://rollbar.com/chartery/chartery/items/12254/?item_page=0&#instances
    // -JS

    var rows;
    try {
      rows = JSON.parse(JSON.stringify(demographics)).splice(1);
    } catch (err) {
      return null;
    }

    const chartColumns = demographics[0].map(col => {
      if (col === 'Country') {
        return {
          type: 'string',
          label: 'Country',
        };
      }

      return {
        type: 'number',
        label: col,
      };
    });

    const chartEvents = [];
    // DZ: removing city functionality for now bc it's too slow
    if (false && selectedColumn === 'trackable' && handleShowCities) {
      chartEvents.push({
        eventName: 'select',
        callback: ({ chartWrapper }) => {
          const chart = chartWrapper.getChart();
          const selection = chart.getSelection();
          if (selection.length === 0) return;
          const region = rows[selection[0].row][0];
          handleShowCities(region);
        },
      });
    }

    return (
      <div className="mb4">
        <div className="w-100 tl flex flex-wrap">
          <div className="w-50-l w-100 pa2-s pt2-ns">
            <div className="f3 dark-blue">
              {title || 'Locations'}
              {subtitle && (
                <div className="f7 b gray ttu tracked dib pl2">
                  <a data-tip={subtitle} className="ph1 ba b--gray br-100 tc pointer">
                    &#63;
                  </a>
                </div>
              )}
              {Object.keys(locationOptions).length === 1 && (
                <span className="pl2 f6 b">({locationOptions[selectedColumn].label})</span>
              )}
            </div>
          </div>
          {Object.keys(locationOptions).length > 1 && (
            <div className="w-50-l w-100 pa2-s cf">
              <div className="fr-l fl w-50-ns w-50-l w-100">
                {Object.entries(locationOptions).length > 1 && (
                  <Select
                    value={locationOptions[selectedColumn]}
                    onChange={this.handleLocationSwitch}
                    options={Object.values(locationOptions)}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt2 bg-white br2">
          <Chart
            chartEvents={chartEvents}
            chartType="GeoChart"
            options={chartOptions}
            columns={chartColumns}
            rows={rows}
            width="100%"
            height="480px"
          />
        </div>
      </div>
    );
  }
}

CountryDemographicsChart.propTypes = {
  countryDemographics: PropTypes.object.isRequired,
  column: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  handleShowCities: PropTypes.func,
};

CountryDemographicsChart.defaultProps = {
  column: null,
  title: null,
  subtitle: null,
  handleShowCities: null,
};
