import React from 'react';
import CheckIcon from 'images/icons/check.svg';
import PropTypes from 'prop-types';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';

const DisconnectConfirmationModal = ({ onConfirm, onClose, isOpen, hasMegaphoneBundle }) => {
  return (
    <BasicModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className="absolute overflow-auto z-9999 left-2 right-2 bg-white center top-2 ba b--light-gray br2 mw6-7"
    >
      <div className="bb ph4 pt3 moon-gray">
        <div className="dark-gray">
          <div className="tl f3 b mb4">Disconnect from Megaphone?</div>
          <div className="tl force-inter lh-copy mb4">Disconnecting from Megaphone will:</div>
          {hasMegaphoneBundle && (
            <div className="flex flex-row justify-left items-center mb4">
              <img alt="check" src={CheckIcon} className="pr3" />
              <div>Change your current plan to the Chartable Indie Plan</div>
            </div>
          )}
          <div className="flex flex-row justify-left items-center mb4">
            {hasMegaphoneBundle && <img alt="check" src={CheckIcon} className="pr3" />}
            Remove download data sourced from Megaphone connection. Trackable data will not be
            removed.
          </div>
        </div>
      </div>
      <div className="flex flex-row mt3 pb3 ph3 items-center justify-end">
        <Button className="mr1 w4" style={{ border: 'none' }} onClick={onConfirm}>
          Disconnect
        </Button>
        <Button
          className="white pv3 w4 mt-auto f5 b bg-spotify-purple"
          style={{ borderRadius: '28px' }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </div>
    </BasicModal>
  );
};

DisconnectConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasMegaphoneBundle: PropTypes.bool.isRequired,
};

export default DisconnectConfirmationModal;
