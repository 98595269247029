/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useReducer, useState } from 'react';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { searchTeamUsers, updateTrackingLink } from 'shared/api/v1';
import BasicModal from 'shared/BasicModal';
import Checkbox from 'shared/Checkbox';
import Loading from 'shared/Loading';
import PaginatedTable from 'shared/PaginatedTable';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import 'react-table/react-table.css';

function formatNumber(number) {
  return number === null ? '-' : number.toLocaleString();
}

function LinkTable({
  trackingLinks,
  handleSort,
  page,
  handlePaging,
  loading,
  teamId,
  hasSmartLinksAutomation,
  total,
}) {
  const readOnlyMode = useReadOnlyMode();

  const [pendingLinkParams, setPendingLinkParams] = useState(false);
  const [togglingAutomation, setTogglingAutomation] = useState(false);
  const [automatedUser, setAutomatedUser] = useState({ email: '', id: '' });

  const baseRowLink = `/teams/${teamId}/dashboard/links`;

  const updateLink = async params => {
    setTogglingAutomation(params.id);
    try {
      await updateTrackingLink(params);
      const index = trackingLinks.findIndex(link => link.id === params.id);
      trackingLinks[index].isAutomated = params.isAutomated;
    } catch (e) {
      return;
    } finally {
      setTogglingAutomation(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleToggleAutomated = trackingLink => {
    const turningOff = trackingLink.isAutomated;
    const params = {
      id: trackingLink.id,
      isAutomated: !trackingLink.isAutomated,
    };
    if (turningOff) return updateLink(params);
    setPendingLinkParams(params);
  };

  const queryTeamUsers = (inputValue, callback) => {
    if (inputValue.length >= 3) {
      searchTeamUsers({ query: inputValue }).then(res => callback(res.data.data));
    }
  };

  const handleSetUser = selection => {
    updateLink({
      ...pendingLinkParams,
      automatedLinkUserId: selection.id,
    });
    setAutomatedUser(selection);
    setPendingLinkParams(false);
  };

  const userModal = () => {
    return (
      <BasicModal
        isOpen={!!pendingLinkParams}
        onRequestClose={() => setPendingLinkParams(false)}
        shouldCloseOnOverlayClick
        ariaHideApp={false}
        style={{
          overlay: {
            zIndex: 999,
          },
          content: {
            outline: 'none',
            maxHeight: '90vh',
            overflow: 'visible',
          },
        }}
      >
        <div>
          <h3 className="mb2">Select the user who should receive the SmartLinks</h3>
          <AsyncSelect
            getOptionLabel={option => option.email}
            className="flex-grow-1"
            cacheOptions
            getOptionValue={({ value }) => value}
            loadOptions={queryTeamUsers}
            onChange={handleSetUser}
            value={{
              email: automatedUser.email,
              value: automatedUser.id,
            }}
          />
        </div>
      </BasicModal>
    );
  };

  const clicksHeader = () => (
    <div>
      {'Clicks '}
      <a
        data-tip="click the link name to get the most up to date info"
        className="ph1 ba b--gray br-100 tc pointer"
      >
        &#63;
      </a>
    </div>
  );
  const uniqueClicksHeader = () => (
    <div>
      {'Unique Clicks '}
      <a
        data-tip="click the link name to get the most up to date info"
        className="ph1 ba b--gray br-100 tc pointer"
      >
        &#63;
      </a>
    </div>
  );
  const columns = [
    {
      Header: '',
      sortable: false,
      width: 40,
      Cell: row => (
        <Link
          to={`${baseRowLink}/${row.original.id}`}
          className="w-50 center no-underline mid-gray"
        >
          <img src={row.original.podcast.displayImageUrl} className="center fl br2" alt="" />
        </Link>
      ),
    },
    {
      id: 'name',
      Header: 'Name',
      accessor: 'name',
      minWidth: 200,
      Cell: row => (
        <Link to={`${baseRowLink}/${row.original.id}`} className="tl no-underline mid-gray">
          {row.original.name}
        </Link>
      ),
    },
    {
      id: 'created_at',
      Header: 'Published',
      accessor: 'createdAt',
      className: 'tc ph4',
      manualSortBy: true,
      Cell: row => (
        <Link to={`${baseRowLink}/${row.original.id}`} className="no-underline mid-gray">
          {moment(row.original.createdAt).format('MM/DD/YYYY')}
        </Link>
      ),
    },
    {
      id: 'clicks',
      Header: clicksHeader(),
      accessor: 'clicks',
      sortable: false,
      className: 'tr ph4',
      Cell: row => (
        <Link to={`${baseRowLink}/${row.original.id}`} className="no-underline mid-gray">
          {formatNumber(row.original.clicks)}
        </Link>
      ),
    },
    {
      id: 'unique_clicks',
      Header: uniqueClicksHeader(),
      accessor: 'uniqueClicks',
      sortable: false,
      className: 'tr ph4',
      Cell: row => (
        <Link to={`${baseRowLink}/${row.original.id}`} className="no-underline mid-gray">
          {formatNumber(row.original.uniqueClicks)}
        </Link>
      ),
    },
    {
      id: 'downloads',
      sortable: false,
      Header: 'Converted Devices',
      accessor: 'devices',
      className: 'tr ph4',
      Cell: row => (
        <Link to={`${baseRowLink}/${row.original.id}`} className="no-underline mid-gray">
          {formatNumber(row.original.devices)}
        </Link>
      ),
    },
    {
      id: 'new_downloads',
      sortable: false,
      Header: 'New Converted Devices',
      accessor: 'newDevices',
      className: 'tr ph4',
      Cell: row => (
        <Link to={`${baseRowLink}/${row.original.id}`} className="no-underline mid-gray">
          {formatNumber(row.original.newDevices)}
        </Link>
      ),
    },
  ];

  if (!readOnlyMode && hasSmartLinksAutomation) {
    const automationColumn = {
      Header: 'Automated?',
      sortable: false,
      accessor: 'isAutomated',
      Cell: row => (
        <div>
          {togglingAutomation === row.original.id ? (
            <div style={{ margin: '0 auto', maxWidth: '20px' }}>
              <Loading small />
            </div>
          ) : (
            <Checkbox
              isChecked={row.original.isAutomated}
              disabled={false}
              className="justify-center"
              onClick={() => handleToggleAutomated(row.original)}
              label=""
            />
          )}
        </div>
      ),
    };

    columns.push(automationColumn);
  }

  return (
    <div className="w-100 relative">
      <ReactTooltip />
      {userModal()}
      <PaginatedTable
        defaultSorted={[
          {
            id: 'created_at',
            desc: true,
          },
        ]}
        columns={columns}
        data={trackingLinks}
        handlePaging={event => handlePaging(event.selected + 1)}
        handleSort={sort => {
          handleSort(sort[0].id, sort[0].desc);
        }}
        loading={loading}
        total={total}
        page={page}
        pageSize={trackingLinks.length}
        rowStyle={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '0.5rem 0',
        }}
        getTrProps={() => ({
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
          },
        })}
        tableClassName="-highlight"
      />
    </div>
  );
}

LinkTable.propTypes = {
  trackingLinks: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  handlePaging: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  hasSmartLinksAutomation: PropTypes.bool,
};

export default LinkTable;
