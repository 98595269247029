import React from 'react';
import PropTypes from 'prop-types';

const EmailPassForm = ({ handleSubmission, renderMegaphoneButton, handleChange, errors }) => {
  return (
    <form onSubmit={handleSubmission} onChange={handleChange}>
      <div className={`mt1`}>
        <div className={`form-group`}>
          <label className={`db lh-copy f5 header-font`} htmlFor="session_email">
            Email
          </label>
          <input
            className={`pa2 input-reset ba w-100 bg-white br2 b--silver string`}
            autoFocus="autofocus"
            required="required"
            aria-required="true"
            type="email"
            name="email"
            id="Email"
          />
        </div>
      </div>
      <div className={`mt3`}>
        <div className={`form-group`}>
          <label className={`db lh-copy f5 header-font`} htmlFor="session_password">
            Password
          </label>
          <input
            className={`pa2 input-reset ba w-100 bg-white br2 b--silver`}
            required="required"
            aria-required="true"
            type="password"
            name="password"
            id="Password"
          />
        </div>
      </div>
      {errors()}
      <div className={`mt3 f6`}>
        <input
          name="commit"
          type="submit"
          value="Log in"
          className={`ph3 pv2 input-reset bn white bg-blue pointer f5 dib dim w-100 br2 header-font`}
        />
      </div>
      {renderMegaphoneButton()}
    </form>
  );
};

EmailPassForm.propTypes = {
  renderMegaphoneButton: PropTypes.func.isRequired,
  handleSubmission: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
};

export default EmailPassForm;
