import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Form, Field } from 'react-final-form';

import Loading from 'shared/Loading';
import { getInvites, createInvite } from 'shared/api';

export default class TeamInvites extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
    this.handleGetInvites = this.handleGetInvites.bind(this);

    this.state = {
      loading: false,
      invites: [],
    };
  }

  handleGetInvites() {
    getInvites({ teamId: this.props.teamId }).then(res => {
      this.setState({
        invites: res.data,
      });
    });
  }

  onSubmit(params, form, callback) {
    createInvite({ teamInvite: params, teamId: this.props.teamId }).then(res => {
      if (res.data.errors) {
        callback(res.data.errors);
        this.setState({ loading: false });
      } else {
        form.reset();
        this.handleGetInvites();
        callback();
      }
    });
  }

  componentDidMount() {
    this.handleGetInvites();
  }

  render() {
    const { loading, invites } = this.state;

    if (loading) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    return (
      <div>
        <Form
          onSubmit={this.onSubmit}
          render={({ handleSubmit, pristine, invalid }) => (
            <form onSubmit={handleSubmit}>
              <Field component="input" type="hidden" name="authenticityToken" />
              <Field component="input" type="email" name="email">
                {({ input, meta }) => (
                  <div>
                    <input
                      {...input}
                      className="pa2 input-reset ba w-100 bg-white br2 b--silver"
                      type="email"
                      autoFocus={true}
                      placeholder="friend@example.com"
                      name="email"
                    />
                    {meta.submitError && meta.touched && (
                      <span className="red">{meta.submitError}</span>
                    )}
                  </div>
                )}
              </Field>
              <button
                type="submit"
                disabled={pristine}
                className="ph3 pv2 input-reset bn white bg-blue pointer f5 dib dim w-100 br2 header-font mt2"
              >
                Send invite
              </button>
            </form>
          )}
        />

        {invites.length > 0 && <div className="mt4 f3 gray mb2">Pending invites</div>}

        <div className="invites silver">
          {invites.map(invite => (
            <div key={invite.id} className="mb2">
              ✅ {invite.email}
            </div>
          ))}
        </div>
      </div>
    );
  }
}
