import React, { useState } from 'react';
import Header from 'shared/Header';
import Sidebar from 'shared/Sidebar';
import { ReadOnlyModeContext } from 'shared/ReadOnlyModeContext';

import TeamInviteManager from '../TeamInviteManager';
import TeamUserManager from '../TeamUserManager';

const TeamInviteUserManager = props => {
  const [selectedTab, setSelectedTab] = useState('users');

  const renderTab = (title, tab) => {
    const selectedClassName = 'no-underline f5 b pointer dark-gray pb3 bb bw2 tc b--blue';
    const unselectedClassName =
      'no-underline f5 hover-blue pointer mid-gray pb2 bb-none bw2 tc b--moon-gray';
    const selected = tab === selectedTab;
    const className = selected ? selectedClassName : unselectedClassName;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className="pointer" onClick={() => setSelectedTab(tab)}>
        {title}
        <div className={className} />
      </div>
    );
  };

  const getContent = () => {
    const userComponent = <TeamUserManager {...props} />;
    const invitesComponent = <TeamInviteManager {...props} />;
    return (
      <div>
        <div className={selectedTab === 'users' ? '' : 'dn'}>{userComponent}</div>
        {!props.readOnlyMode && (
          <div className={selectedTab === 'invites' ? '' : 'dn'}>{invitesComponent}</div>
        )}
      </div>
    );
  };

  return (
    <Sidebar
      readOnlyMode={props.readOnlyMode}
      sidebar={props.sidebar}
      subSidebar={props.subSidebar}
    >
      <ReadOnlyModeContext.Provider value={props.readOnlyMode}>
        <div className="pl3 mb4">
          <div className="bg-white pt4 ph4">
            <div className="mb4">
              <Header useNew>Users</Header>
            </div>
            <div className="flex flex-row gap-large">
              {renderTab('Active Users', 'users')}
              {!props.readOnlyMode &&
                props.userInfo.hasMegaphoneBundle &&
                renderTab('Invites', 'invites')}
            </div>
          </div>
          <div className="pa3">{getContent()}</div>
        </div>
      </ReadOnlyModeContext.Provider>
    </Sidebar>
  );
};

export default TeamInviteUserManager;
