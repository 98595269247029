import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';

const SearchTeamUserForm = ({ setSearchInput }) => {
  const [input, setInput] = useState('');

  const handleTextSearch = useCallback(
    debounce(i => {
      if (i.length >= 3 || !i.length) {
        setSearchInput(i);
      }
    }, 300),
    [],
  );

  return (
    <div>
      <input
        type="text"
        className="input-reset ba b--black-20 pa2 mb3 db w-100"
        placeholder="Search by email"
        value={input}
        onChange={e => {
          setInput(e.target.value);
          handleTextSearch(e.target.value);
        }}
      />
    </div>
  );
};

export default SearchTeamUserForm;
