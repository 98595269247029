import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from 'shared/Button';
import Header from 'shared/Header';

function LandingPage({ match, canCreateSmartPromos }) {
  if (!canCreateSmartPromos) {
    return (
      <div className="ph4 mt3">
        <div className="lh-copy">You do not have permission to create SmartPromos.</div>
      </div>
    );
  }

  return (
    <div className="ph4 mt3">
      <Header className="dark-blue mb3" useNew size="large">
        Measure your Podcast to Podcast advertising
      </Header>
      <Header className="dark-blue" useNew size="small">
        See how effective your Podcast-to-Podcast ads are at converting new listeners
      </Header>
      <ul className="lh-copy">
        <li>Calculate Return On Ad Spend (ROAS) of your podcast ads</li>
        <li>Compare ad campaigns across multiple podcasts</li>
        <li>Verify impression delivery</li>
      </ul>
      <Link
        to={{
          pathname: `/teams/${match.params.teamId}/dashboard/promos/new`,
          state: { backUrl: location.pathname },
        }}
        className="no-underline white"
      >
        <Button className="b mw6" size="large" type="primary">
          Get started with your first SmartPromo →
        </Button>
      </Link>
    </div>
  );
}

LandingPage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default LandingPage;
