import React from 'react';

const PublicInfo = () => {
  return (
    <div>
      <div className="mt3 lh-copy">
        <a
          target="_blank"
          href="https://podcasters.spotify.com/"
          className="link blue"
          rel="noreferrer"
        >
          Spotify for Podcasters
        </a>{' '}
        offers detailed listening and consumption statistics. Chartable allows you to integrate
        these analytics - so you can see all of your podcast stats in one place and easily track
        trends and optimize your podcast&apos;s performance. Note that viewing charts from Spotify
        does not require you to integrate your Spotify for Podcasters account with Chartable.
      </div>
    </div>
  );
};

export default PublicInfo;
