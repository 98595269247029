import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import FormTitle from 'shared/FormTitle';
import Loading from 'shared/Loading';

import { getTeam } from 'shared/api';

export default class FormDivisionSelector extends Component {
  constructor(props) {
    super(props);

    this.handleLoadTeam = this.handleLoadTeam.bind(this);
    this.handleAutosuggest = this.handleAutosuggest.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);

    this.state = {
      team: null,
      titleSearchText: '',
      suggestions: [],
    };
  }

  handleLoadTeam() {
    const { teamSlug, teamId } = this.props;

    getTeam({
      teamId: teamSlug,
      id: teamId,
    }).then(res => {
      this.setState({
        team: res.data,
      });
    });
  }

  handleAutosuggest({ value }) {
    const q = value.trim().toLowerCase();
    const { team } = this.state;

    const suggestions = team.divisions.filter(d => d.name.toLowerCase().includes(q));
    this.setState({ suggestions });
  }

  handleSuggestionSelected(ev, { suggestion }) {
    this.props.onSelectDivision(suggestion);
    this.setState({ titleSearchText: '' });
  }

  renderSuggestion(suggestion) {
    const { name } = suggestion;

    return (
      <div className="relative pointer link pa2 flex flex-wrap items-center">
        <div className="f6 tl">{name}</div>
      </div>
    );
  }

  render() {
    const { title } = this.props;

    const { team, suggestions, titleSearchText } = this.state;

    if (!team) {
      this.handleLoadTeam();
    }

    return (
      <div className="mb4">
        {title && <FormTitle title={title} />}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleAutosuggest}
          onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
          onSuggestionSelected={this.handleSuggestionSelected}
          getSuggestionValue={suggestion => suggestion.trackName}
          shouldRenderSuggestions={value => value.trim().length > 1}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            className: 'pa2 b--moon-gray ba br2 w-100 w5 f5',
            placeholder: 'Search shows by title',
            value: titleSearchText,
            onChange: e => this.setState({ titleSearchText: e.target.value }),
          }}
        />
      </div>
    );
  }
}

FormDivisionSelector.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamSlug: PropTypes.string.isRequired,
  onSelectDivision: PropTypes.func.isRequired,
  onUnselectDivision: PropTypes.func,
  title: PropTypes.string,
};

FormDivisionSelector.defaultProps = {
  onUnselectTeamPodcast: null,
  title: null,
};
