import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { paginatedSearchEpisodes } from 'shared/api';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import Loading from 'shared/Loading';

const EpisodeCard = ({ onSelect, episode }) => {
  const [showDescription, setShowDescription] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const episodeDescription = episode.description || '';

  const description = showDescription
    ? episodeDescription
    : `${episodeDescription.substring(0, 100)}...`;

  const isOutOfMeasurementRange = moment().subtract(6, 'months') > moment(episode.publishedAt);

  return (
    <div className="pa3 bg-white ba b--moon-gray">
      <div className="flex flex-wrap items-end mb1">
        <div className="dark-gray mr2 b">{episode.title}</div>
        <div className="dark-blue b">{moment(episode.publishedAt).format('MM/DD/YYYY')}</div>
      </div>
      <div className="f6 mid-gray lh-copy">
        {description}
        <span
          onClick={() => setShowDescription(!showDescription)}
          className="ml1 blue link f6 pointer"
        >
          Show {showDescription ? 'less' : 'more'}
        </span>
      </div>
      {showConfirm ? (
        <div className="mt3 flex flex-row justify-between gap">
          <Button onClick={() => onSelect(episode)} type="create" className="flex-grow primary">
            Confirm
          </Button>
          <Button onClick={() => setShowConfirm(false)} className="flex-grow primary">
            Cancel
          </Button>
        </div>
      ) : (
        <Button
          disabled={isOutOfMeasurementRange}
          onClick={() => setShowConfirm(true)}
          type="primary"
          className="mt3 ml-auto"
        >
          {isOutOfMeasurementRange
            ? 'Episode must be published in last 60 days'
            : 'Approve Measurement'}
        </Button>
      )}
    </div>
  );
};

EpisodeCard.propTypes = {
  episode: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
};

const EpisodeSelector = ({ teamId, podcastId, scheduledAirDate, onSelect, error }) => {
  const pageSize = 3;

  const [episodes, setEpisodes] = useState(null);
  const [totalEpisodes, setTotalEpisodes] = useState(0);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [localError, setLocalError] = useState(error);

  const handleGetEpisodes = async () => {
    setRequestInProgress(true);
    try {
      const response = await paginatedSearchEpisodes({
        teamId,
        podcastId,
        page,
        pageSize,
        title: searchQuery,
      });
      setEpisodes(response.data.data);
      setTotalEpisodes(response.data.metadata.total);
    } catch (e) {
      setLocalError('Failed to fetch episodes');
    } finally {
      setRequestInProgress(false);
    }
  };

  useEffect(() => {
    handleGetEpisodes();
  }, [page]);

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      handleGetEpisodes();
    }
  }, [searchQuery]);

  const innerContent = episodes ? (
    <div className="flex flex-column br3">
      {episodes.map(episode => (
        <EpisodeCard key={episode.id} episode={episode} onSelect={onSelect} />
      ))}
    </div>
  ) : (
    <div className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 bg-light-gray o-60">
      <Loading />
    </div>
  );

  const paginator =
    totalEpisodes > 0 ? (
      <div className="flex flex-row">
        <div
          onClick={() => (page > 1 ? setPage(page - 1) : null)}
          className={`flex-grow tc pa2 ${
            page > 1
              ? 'blue pointer bg-white ba b--blue hover-bg-light-gray'
              : 'bg-light-gray ba b--light-gray'
          }`}
        >
          ← Prev Page
        </div>
        <div
          onClick={() => (page * pageSize < totalEpisodes ? setPage(page + 1) : null)}
          className={`flex-grow tc pa2 ${
            page * pageSize < totalEpisodes
              ? 'blue pointer bg-white ba b--blue hover-bg-light-gray'
              : 'bg-light-gray ba b--light-gray'
          }`}
        >
          Next Page →
        </div>
      </div>
    ) : null;

  return (
    <div className="mw6">
      <div className="force-inter mb1 dark-gray f4">Select Episode</div>
      <div className="mid-gray lh-copy mb2">Select the episode that contains the promo.</div>
      {scheduledAirDate && (
        <div className="mid-gray b mb2">
          This promo was scheduled for{' '}
          <b className="dark-blue">{moment(scheduledAirDate).format('MM/DD/YYYY')}</b>
        </div>
      )}
      {localError && (
        <Banner className="mb2" error>
          {localError}
        </Banner>
      )}
      <div className="mb2">
        <input
          className="pa2 input-reset ba w-100 bg-white br2 b--silver mt1 border-box"
          placeholder="Search by episode name"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
      </div>
      <div className="relative">
        {innerContent}
        {paginator}
        {requestInProgress && (
          <div className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 bg-light-gray o-60">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

EpisodeSelector.propTypes = {
  onSelect: PropTypes.func.isRequired,
  teamId: PropTypes.string.isRequired,
  podcastId: PropTypes.string.isRequired,
  scheduledAirDate: PropTypes.instanceOf(Date),
  error: PropTypes.string,
};

EpisodeSelector.defaultProps = {
  scheduledAirDate: null,
  error: null,
};

export default EpisodeSelector;
