import React, { useEffect, useMemo, useState } from 'react';
import { approvePromoRequest, denyPromoRequest, getAdOpsRequests } from 'shared/api';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';

import ApprovalForm from './ApprovalForm';
import RequestList from './RequestList';
import Tag from './Tag';

const RequestOverview = props => {
  const { teamId } = props.match.params;
  const { tagTypes, history } = props;

  const urlParams = new URLSearchParams(props.location.search);
  const initialTags = urlParams.get('tags');

  const [tags, setTags] = useState(
    initialTags ? new Set(initialTags.split(',')) : new Set(['smartPromo', 'needsApproval']),
  );

  const [audioAdPlacementRequests, setAudioAdPlacementRequests] = useState(null);

  const [selectedRow, setSelectedRow] = useState(null);
  const [approvalFlow, setApprovalFlow] = useState(false);
  const [approvalRequestInProgress, setApprovalRequestInProgress] = useState(false);
  const [approvalError, setApprovalError] = useState(null);
  const [approvedRequest, setApprovedRequest] = useState(null);

  const assignTagsToRequest = request => {
    const tags = [];
    if (request.status) {
      if (request.status === 'denied') {
        tags.push(tagTypes[request.status].value);
      } else {
        tags.push(tagTypes.needsApproval.value);
      }
    } else {
      tags.push(tagTypes.smartPromo.value);
      tags.push(request.campaignType === 'dai' ? tagTypes.dynamic.value : tagTypes.bakedIn.value);
      if (request.statusCode === 'NEEDS_APPROVAL') {
        tags.push(tagTypes.needsApproval.value);
      } else {
        tags.push(tagTypes[request.statusCode.toLowerCase()].value);
      }
    }

    return tags;
  };

  useEffect(() => {
    handleGetAdOpsRequests();
  }, []);

  const handleGetAdOpsRequests = () => {
    getAdOpsRequests({ teamId }).then(res => {
      setAudioAdPlacementRequests(
        res.data.audioAdPlacementRequests.map(a => ({ ...a, tags: assignTagsToRequest(a) })),
      );
    });
  };

  const handleDenyRequest = async () => {
    if (approvalRequestInProgress) return;
    setApprovalRequestInProgress(true);

    try {
      const response = await denyPromoRequest({
        audioAdPlacementRequestId: selectedRow.id,
        teamId,
      });
      setAudioAdPlacementRequests(
        audioAdPlacementRequests.map(a => {
          if (a.id !== selectedRow.id) return a;
          return { ...selectedRow, tags: ['smartPromo', 'denied'] };
        }),
      );

      setSelectedRow(null);
      setApprovedRequest(null);
    } catch (err) {
      console.log(err);
    }
  };

  const handleApproval = async (
    provider,
    campaignType,
    shouldSendPixelEmail = false,
    episodeId,
  ) => {
    if (approvalRequestInProgress) return;
    setApprovalRequestInProgress(true);

    try {
      const response = await approvePromoRequest({
        teamId,
        audioAdPlacementRequestId: selectedRow.id,
        pixelProvider: provider ? provider.value : null,
        episodeId,
      });
      setAudioAdPlacementRequests(audioAdPlacementRequests.filter(a => a.id !== selectedRow.id));
      setApprovedRequest(response.data.promo);
    } catch (err) {
      try {
        setApprovalError(err.response.data);
      } catch (_) {
        setApprovalError({ unknownError: 'Something went wrong' });
      }
    }

    setApprovalRequestInProgress(false);
  };

  const filteredRequests = useMemo(() => {
    if (!audioAdPlacementRequests) return null;
    let allRequests = audioAdPlacementRequests;

    if (tags.length === 0) return allRequests;

    if (!tags.has('smartPromo')) {
      allRequests = allRequests.filter(t => !t.tags.includes('smartPromo'));
    }

    if (!tags.has('denied')) {
      allRequests = allRequests.filter(t => !t.tags.includes('denied'));
    }

    if (!tags.has('needsApproval')) {
      allRequests = allRequests.filter(t => !t.tags.includes('needsApproval'));
    }

    return allRequests;
  }, [tags, JSON.stringify(audioAdPlacementRequests)]);

  return (
    <div>
      <div className="w-100 ph4 tour-step-three">
        <div className="mt3 f5 dark-gray b mb2">Tag Filters (click to turn on/off)</div>
        <div className="flex flex-wrap gap">
          <div className="card pa2">
            <div className="f6 mid-gray b mb2">Status</div>
            <div className="flex flex-wrap gap">
              <Tag
                label={tagTypes.needsApproval.label}
                color={
                  tags.has(tagTypes.needsApproval.value) ? tagTypes.needsApproval.color : '#999'
                }
                onClick={() => {
                  tags.has(tagTypes.needsApproval.value)
                    ? tags.delete(tagTypes.needsApproval.value)
                    : tags.add(tagTypes.needsApproval.value);
                  setTags(new Set(tags));
                }}
              />
              <Tag
                label={tagTypes.denied.label}
                color={tags.has(tagTypes.denied.value) ? tagTypes.denied.color : '#999'}
                onClick={() => {
                  tags.has(tagTypes.denied.value)
                    ? tags.delete(tagTypes.denied.value)
                    : tags.add(tagTypes.denied.value);
                  setTags(new Set(tags));
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pa4">
        <RequestList
          history={history}
          teamId={teamId}
          requests={filteredRequests}
          tagTypes={tagTypes}
          handleButtonPress={(row, approval) => {
            setSelectedRow(row);
            setApprovalFlow(approval);
          }}
        />
      </div>
      {selectedRow && (
        <BasicModal
          isOpen
          onRequestClose={() => {
            setSelectedRow(null);
            setApprovedRequest(null);
            setApprovalError(null);
          }}
          ariaHideApp={false}
        >
          {approvalFlow ? (
            <ApprovalForm
              teamId={teamId}
              history={history}
              request={selectedRow}
              onApprove={handleApproval}
              onCancel={() => {
                setSelectedRow(null);
                setApprovedRequest(null);
                setApprovalError(null);
              }}
              requestInProgress={approvalRequestInProgress}
              approvedRequest={approvedRequest}
              error={approvalError}
            />
          ) : (
            <div>
              <div className="f4 mb3 dark-gray">Deny request?</div>
              <Button
                onClick={handleDenyRequest}
                type="destructive"
                loading={approvalRequestInProgress}
                disabled={approvalRequestInProgress}
              >
                Confirm
              </Button>
              <Button
                className="mt3"
                onClick={() => {
                  setSelectedRow(null);
                  setApprovedRequest(null);
                  setApprovalError(null);
                }}
                loading={approvalRequestInProgress}
                disabled={approvalRequestInProgress}
              >
                Cancel
              </Button>
            </div>
          )}
        </BasicModal>
      )}
    </div>
  );
};

export default RequestOverview;
