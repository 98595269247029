import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';

import Loading from 'shared/Loading';
import Image from 'images/visual-data.svg';

import { getPublicAudioAdAggregateBenchmarks } from 'shared/api';
import { abbreviateNumber } from 'shared/helpers';

const columns = [
  {
    Header: 'Genre',
    id: 'genre',
    accessor: 'name',
    Cell: row => <div className="near-black mv0 pv2">{row.original.name}</div>,
  },
  {
    Header: 'Average Conversion Rate',
    id: 'uniqueConversionRate',
    accessor: 'averageUniqueDownloadRate',
    Cell: row => (
      <div className="near-black mv0 pv2 tc">
        {row.original.averageUniqueDownloadRate
          ? `${(row.original.averageUniqueDownloadRate * 100).toFixed(2)}%`
          : '-'}
      </div>
    ),
  },
  {
    Header: 'Average New Conversion Rate',
    id: 'newConversionRate',
    accessor: 'averageNewDownloadRate',
    Cell: row => (
      <div className="near-black mv0 pv2 tc">
        {row.original.averageNewDownloadRate
          ? `${(row.original.averageNewDownloadRate * 100).toFixed(2)}%`
          : '-'}
      </div>
    ),
  },
  {
    Header: 'Sample Size',
    id: 'sampleSize',
    accessor: 'sampleSize',
    Cell: row => <div className="near-black mv0 pv2 tc">{row.original.sampleSize}</div>,
  },
];

const pivotColumns = {
  buyerPivot: 'buyerPivot',
  sellerPivot: 'sellerPivot',
};

const Header = props => (
  <div className="flex flex-wrap mb4">
    <div className="mt0 f2 f-subheadline-ns header-font dark-blue lh-title mb4 tc w-100 fw7">
      Podcast-to-Podcast Advertising Benchmarks
    </div>
    <div className="flex flex-row">
      <div className="mb2 mid-gray lh-copy f3 body-font pv3" style={{ flexGrow: '1' }}>
        Podcast-to-podcast promotions are a popular and effective podcast marketing channel. We
        crunched the numbers on over 2,200 podcast-to-podcast promotions using Chartable's{' '}
        <a href="/publishers/smartpromos" className="link blue">
          SmartPromos podcast promo attribution
        </a>{' '}
        to determine which ads convert best.
        <div className="mt3 b">All stats update daily; check back for fresh insights!</div>
      </div>
      <div className="db-ns dn" style={{ flexGrow: '1' }}>
        <img className="h-auto w-100" src={Image} />
      </div>
    </div>
  </div>
);

function DataContent({ benchmarkData }) {
  const [genreFilter, setGenreFilter] = useState('');
  const [pivotColumn, setPivotColumn] = useState(pivotColumns.sellerPivot);

  const pageSize = 100;

  function handleFilterChange(e) {
    e.preventDefault();
    setGenreFilter(e.target.value);
  }

  const pivot = benchmarkData[pivotColumn];

  let filteredRows;
  if (genreFilter === '') {
    filteredRows = pivot;
  } else {
    filteredRows = pivot.filter(r => r.name.toUpperCase().includes(genreFilter.toUpperCase()));
  }

  return (
    <React.Fragment>
      <div className="f2 dark-blue mt0 mb2">Overall</div>
      <div
        className="flex flex-row-ns flex-column mb5 justify-between w-100 w-auto-ns"
        style={{ gap: '10px' }}
      >
        <div
          className="br3 dib pa4 ba b--black-10"
          style={{
            flex: 1,
            boxShadow:
              'rgba(44, 62, 80, 0.1) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.1) 0px 1px 2px',
          }}
        >
          <div className="flex flex-row items-baseline near-black">
            <h1 className="mr1 mv0 header-font" style={{ fontSize: '3em' }}>
              {(benchmarkData.total.averageUniqueDownloadRate * 100).toFixed(2)}
            </h1>
            <h1 className="mv0">%</h1>
          </div>
          <h3 className="mv0 b--dotted b--black-30 bl-0 bt-0 br-0 ba dib">Conversion Rate</h3>
          <div className="mt2">Number of unique downloads divided by the number of impressions</div>
        </div>
        <div
          className="br3 dib pa4 ba b--black-10 w-100 w-auto-ns"
          style={{
            flex: 1,
            boxShadow:
              'rgba(44, 62, 80, 0.1) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.1) 0px 1px 2px',
          }}
        >
          <div className="flex flex-row items-baseline near-black">
            <h1 className="mr1 mv0 header-font" style={{ fontSize: '3rem' }}>
              {(benchmarkData.total.averageNewDownloadRate * 100).toFixed(2)}
            </h1>
            <h1 className="mv0">%</h1>
          </div>
          <h3 className="mv0 b--dotted b--black-30 bl-0 bt-0 br-0 ba dib">New Conversion Rate</h3>
          <div className="mt2">Number of new downloads divided by the number of impressions</div>
        </div>
        <div
          className="br3 dib pa4 ba b--black-10 w-100 w-auto-ns"
          style={{
            flex: 1,
            boxShadow:
              'rgba(44, 62, 80, 0.1) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.1) 0px 1px 2px',
          }}
        >
          <div className="flex flex-row items-baseline near-black">
            <h1 className="mv0 header-font" style={{ fontSize: '3rem' }}>
              {abbreviateNumber(benchmarkData.total.impressions)}
            </h1>
          </div>
          <h3 className="mv0 b--dotted b--black-30 bl-0 bt-0 br-0 ba dib">Impressions</h3>
          <div className="mt2">Total impression sample size</div>
        </div>
      </div>

      <div className="f2 dark-blue mb2">Key Takeaways</div>
      <div className="flex flex-column mb3 w-100 mb5">
        <div className="flex flex-wrap">
          <div className="w-100 w-50-ns pa3 bg-washed-blue f4 body-font lh-copy ba bw1 b--white">
            <div className="f3 dark-blue">Highest conversion rate</div>
            <div>
              Podcasts promoted on Documentary podcasts had the highest average conversion rate.
            </div>
          </div>
          <div className="w-100 w-50-ns pa3 bg-washed-blue f4 body-font lh-copy ba bw1 b--white">
            <div className="f3 dark-blue">Most popular genre</div>
            <div>
              Society & Culture and News were the most promoted genres, as well as the genres most
              promoted on.
            </div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="w-100 w-50-ns pa3 bg-washed-blue f4 body-font lh-copy ba bw1 b--white">
            <div className="f3 dark-blue">Most conversions - external campaigns</div>
            <div>
              Parenting, Golf and Film Reviews offered the most new conversions to podcast marketers
              running promos outside their own networks.
            </div>
          </div>
          <div className="w-100 w-50-ns pa3 bg-washed-blue f4 body-font lh-copy ba bw1 b--white">
            <div className="f3 dark-blue">Most conversions - internal campaigns</div>
            <div>
              Football, Performing Arts and Religion & Spirituality offered the new most new
              conversions per internal campaign.
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap w-100 justify-between mb2 items-baseline justify-between">
        <div className="w-auto-l w-100 mb2 mb0-l">
          <div className="f2 dark-blue mb2">Results by genre</div>
          <input
            style={{
              outline: 'none',
              boxShadow:
                'rgba(44, 62, 80, 0.1) 0px 0.4em 0.8em -2px, rgba(102, 119, 136, 0.1) 0px 1px 2px',
              height: '36px',
            }}
            className="input-reset ba b--black-10 br4 pv2 ph3 mw7 w-100"
            placeholder="Filter by genre name"
            type="text"
            onChange={handleFilterChange}
            value={genreFilter}
          />
        </div>
        <div className="w-auto-l ml-auto w-100 justify-end mb2 mb0-l">
          <h3 className="mb2 mt0">Show genre of:</h3>
          <div onClick={() => setPivotColumn(pivotColumns.buyerPivot)} className="mb2 pointer">
            <input type="radio" checked={pivotColumn === pivotColumns.buyerPivot} readOnly />{' '}
            Podcast Being Promoted (Buyer)
          </div>
          <div onClick={() => setPivotColumn(pivotColumns.sellerPivot)} className="pointer">
            <input type="radio" checked={pivotColumn === pivotColumns.sellerPivot} readOnly />{' '}
            Podcast Containing Promotion (Seller)
          </div>
        </div>
      </div>
      <ReactTable
        data={filteredRows}
        columns={columns}
        pageSize={filteredRows.length > pageSize ? pageSize : filteredRows.length}
        showPageJump={false}
        showPageSizeOptions={false}
        showPagination={filteredRows.length > pageSize}
        defaultSorted={[
          {
            id: 'genre',
            desc: false,
          },
        ]}
      />
    </React.Fragment>
  );
}

function PublicBenchmarks(props) {
  const [benchmarkData, setBenchmarkData] = useState(null);

  useEffect(() => {
    async function getBenchmarkData() {
      const response = await getPublicAudioAdAggregateBenchmarks({
        dimensions: {}, // returns 500 error unless I send empty hash -JS
      });

      setBenchmarkData(response.data);
    }

    getBenchmarkData();
  }, []);

  return (
    <div className="ph2">
      <Header />
      {benchmarkData ? (
        <DataContent benchmarkData={benchmarkData} />
      ) : (
        <div className="w-100 mt5 tc">
          <Loading />
        </div>
      )}
    </div>
  );
}

export default PublicBenchmarks;
