import ArchivedLinkView from './components/ArchivedLinkView';
import LinkDetailView from './components/LinkDetailView';
import Overview from './components/Overview';
import Settings from './components/Settings';
import TrackingLinkForm from './components/TrackingLinkForm';

export const routes = [
  {
    path: '/teams/:teamId/dashboard/links/settings',
    key: 'SETTINGS',
    Component: Settings,
  },
  {
    path: '/teams/:teamId/dashboard/links/new',
    key: 'CREATE',
    Component: TrackingLinkForm,
  },
  {
    path: '/teams/:teamId/dashboard/links/archived',
    key: 'ARCHIVED',
    Component: ArchivedLinkView,
  },
  {
    path: '/teams/:teamId/dashboard/links/:trackingLinkId',
    key: 'DETAIL',
    Component: LinkDetailView,
  },
  {
    path: '/teams/:teamId/dashboard/links',
    key: 'OVERVIEW',
    Component: Overview,
  },
];
