import React, { useState } from 'react';
import up from 'images/icons/sidebar/open.svg';
import down from 'images/icons/sidebar/collapsed.svg';
import check from 'images/icons/sidebar/check.svg';

const TeamSwitcher = ({ teams, currentTeam, setOpen, open }) => {
  const showSwitcher = teams.length > 1;

  const SwitchIcon = () => {
    return (
      <div style={{ position: 'relative', top: '-4px', right: '10px', height: '5px' }}>
        <img className={`db`} src={up} />
        <img style={{ position: 'relative', top: '-16px' }} src={down} />
      </div>
    );
  };

  return (
    <div style={{ margin: '25px 0 5px 15px' }}>
      <div style={{ cursor: showSwitcher ? 'pointer' : 'initial' }} onClick={() => setOpen(!open)}>
        <span
          className={`header-font`}
          style={{ fontSize: '20px', color: '#ffffff', fontWeight: 600 }}
        >
          {currentTeam}
        </span>
        {showSwitcher && (
          <div className={`fr`}>
            <SwitchIcon />
          </div>
        )}
      </div>
      {showSwitcher && open && (
        <div
          className={`team-switcher-menu`}
          style={{
            width: '288px',
            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.07)',
            background: '#ffffff',
            maxHeight: '248px',
            borderRadius: '4px',
            zIndex: 1,
            padding: '8px',
            marginTop: '10px',
            position: 'absolute',
          }}
        >
          <div style={{ margin: '8px' }}>
            <span
              style={{ fontSize: '12px', color: '#30657B', opacity: 0.4, fontWeight: 700 }}
              className={`ttu`}
            >
              Change Team
            </span>
          </div>
          <div style={{ overflowY: 'scroll', maxHeight: '201px' }}>
            {teams.map((team, idx) => {
              return (
                <div key={team.name} style={{ paddingLeft: '8px' }}>
                  <a style={{ textDecoration: 'none' }} href={team.url}>
                    <div
                      className={`team-switcher-tab`}
                      style={{
                        borderRadius: '4px',
                        height: '49px',
                        color: '#000000',
                        fontWeight: team.active ? 700 : 'initial',
                        width: '250px',
                        padding: '16px',
                      }}
                    >
                      {team.name}
                      {team.active && (
                        <img
                          className={`fr`}
                          style={{ width: '13px', position: 'relative', top: '5px' }}
                          src={check}
                        />
                      )}
                    </div>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamSwitcher;
