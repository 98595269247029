import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import Header from 'shared/Header';
import TitleBar from 'shared/charts/TitleBar';
import BasicTable from 'shared/BasicTable';
import Loading from 'shared/Loading';
import { getTeam, deleteTeam } from 'shared/api';

import EditSVG from 'images/icons/pencil.svg';
import TrashSVG from 'images/icons/trash.svg';

export default class DivisionManagerView extends Component {
  constructor(props) {
    super(props);

    this.handleLoadTeam = this.handleLoadTeam.bind(this);
    this.handleDeleteDivision = this.handleDeleteDivision.bind(this);

    this.state = {
      team: null,
    };
  }

  handleLoadTeam() {
    const { teamSlug, teamId } = this.props;

    getTeam({
      teamId: teamSlug,
      id: teamId,
    }).then(res => {
      this.setState({
        team: res.data,
      });
    });
  }

  handleDeleteDivision(divisionId, divisionName) {
    const { teamSlug, onCreateNotification } = this.props;

    deleteTeam({
      teamId: teamSlug,
      id: divisionId,
    }).then(res => {
      this.setState({
        team: null,
      });
      onCreateNotification(`${divisionName} deleted.`);
    });
  }

  render() {
    const { onShowAddDivision, onShowEditDivision } = this.props;

    const { team } = this.state;

    if (!team) {
      this.handleLoadTeam();
      return <Loading />;
    }

    let divisionTableColumns = [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Users',
        accessor: 'numUsers',
      },
      {
        Header: '',
        sortable: false,
        Cell: row => (
          <div className="dt">
            <a
              onClick={() => onShowEditDivision(row.original.id)}
              className="dtc v-mid link underline"
            >
              <img src={EditSVG} width="25px" className="pointer" />
            </a>
            {row.original.canDelete && (
              <a
                onClick={() => {
                  if (window.confirm('Are you sure you want to delete this division?'))
                    this.handleDeleteDivision(row.original.id, row.original.name);
                }}
                className="dtc v-mid link underline"
              >
                <img src={TrashSVG} width="20px" className="pointer ml2" />
              </a>
            )}
          </div>
        ),
      },
    ];

    return (
      <div className="pa3">
        <Header>Manage Divisions</Header>
        <div className="mw6 center">
          <div className="cf mb2">
            <Button className="fr w4" type="primary" onClick={onShowAddDivision}>
              Add Division
            </Button>
          </div>
          <div className="f5">
            {team.divisions.length == 0 ? (
              <div>Get started by adding a division.</div>
            ) : (
              <BasicTable columns={divisionTableColumns} data={team.divisions} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

DivisionManagerView.propTypes = {
  teamSlug: PropTypes.string.isRequired,
  onShowAddDivision: PropTypes.func.isRequired,
  onShowEditDivision: PropTypes.func.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
};
