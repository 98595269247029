import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { debounce } from 'lodash';
import ReactSlider, { SliderTooltip } from 'rc-slider';

import 'rc-tooltip/assets/bootstrap.css';
import './SliderStyles.css';

const { createSliderWithTooltip } = ReactSlider;
const Range = createSliderWithTooltip(ReactSlider.Range);

const { Handle } = ReactSlider;

const Slider = ({
  start,
  end,
  min,
  max,
  showGreater,
  updateState,
  updateKey,
  disabled,
  setUpsellModalIsOpen,
}) => {
  const [range, setRange] = useState([start, end]);
  const [selfSet, setSelfSet] = useState(false);
  useEffect(() => {
    if (!selfSet && (start !== range[0] || end !== range[1])) {
      setRange([start, end]);
    } else {
      setSelfSet(false);
    }
  }, [start, end]);

  const formatTip = value => {
    if (disabled) {
      return 'Upgrade to Pro!';
    }
    if (value === max && showGreater !== false) {
      return <span style={{ whiteSpace: 'nowrap' }}>{abbreviatedValue(value)} +</span>;
    }
    return abbreviatedValue(value);
  };

  const abbreviatedValue = value => {
    let val = value;
    if (value >= 1000000) {
      val = `${value / 1000000}m`;
    } else if (value >= 10000) {
      val = `${value / 1000}k`;
    }
    return val;
  };

  const Scale = ({ min, max }) => {
    const fill = new Array(6).fill(null);
    const unit = max / 5;
    return (
      <div className="flex justify-between">
        {fill.map((el, i) => {
          let val = i * unit;
          val = abbreviatedValue(val);
          if (i === fill.length - 1 && showGreater !== false) {
            val += ' +';
          }
          return (
            <span
              key={val}
              style={{
                color: '#AAA',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                width: '30px',
                textAlign: 'center',
              }}
              className="f7"
            >
              {val}
            </span>
          );
        })}
      </div>
    );
  };

  const sendChange = debounce(posArray => {
    updateState({
      action: 'SET',
      value: {
        start: posArray[0],
        end: posArray[1],
      },
      key: updateKey,
    });
  }, 600);

  const handleChange = posArray => {
    if (!disabled) {
      setRange(posArray);
      sendChange(posArray);
    }
  };

  const handleUpsell = () => {
    if (disabled) {
      setUpsellModalIsOpen(true);
    }
    return;
  };

  const isActive = range[0] !== min || range[1] !== max;

  return (
    <div>
      {disabled && <ReactTooltip id="upsell" />}
      <div
        onClick={() => handleUpsell()}
        data-for="upsell"
        data-tip="Upgrade to Pro for advanced filters!"
        className={`${isActive ? '' : 'inactive'}`}
        style={{
          width: 'calc(100% - 15px)',
          height: '35px',
          paddingLeft: '15px',
          paddingTop: '15px',
        }}
      >
        <Range
          min={min}
          max={max}
          onChange={handleChange}
          value={range}
          tipFormatter={formatTip}
          step={max / 5}
          disabled={disabled}
        />
      </div>
      <div>
        <Scale min={min} max={max} />
      </div>
    </div>
  );
};

export default Slider;
