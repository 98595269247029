import React, { useEffect, useMemo } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { setViewedTour } from 'shared/api';
import Button from 'shared/Button';
import Header from 'shared/Header';
import Sidebar from 'shared/Sidebar';
import TabBarItem from 'shared/TabBarItem';
import UserContext, { withUser } from 'shared/UserContext';
import { ReadOnlyModeContext } from 'shared/ReadOnlyModeContext';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import 'react-table/react-table.css';
import AdOpsTour from './components/AdOpsTour';
import Manage from './components/Manage';
import PlacementDetailView from './components/PlacementDetailView';
import RequestOverview from './components/RequestOverview';
import Settings from './components/Settings';
import AudioAdPlacementRequestDetailView from './components/SmartPromos/AudioAdPlacementRequestDetailView';

const AdOps = props => {
  const { teamId } = props.match.params;
  const {
    showAdOpsTour,
    art19DecryptionKeys,
    attributionStatusNotifications,
    smartpromoApprovalRequests,
    teamHashedId,
    history,
  } = props;

  const requestTagTypes = useMemo(
    () => ({
      needsApproval: {
        value: 'needsApproval',
        label: 'Needs Approval',
        color: '#4B9EC1',
      },
      denied: {
        value: 'denied',
        label: 'Denied',
        color: '#4B9EC1',
      },
      // Commenting this out while we decide if we want to allow approved requests to show up.
      // approved: {
      //   value: 'approved',
      //   label: 'Approved',
      //   color: '#4B9EC1',
      // },
    }),
    [],
  );

  const approvedTagTypes = useMemo(() => ({
    active: {
      value: 'active',
      label: 'Active',
      color: '#4B9EC1',
    },
    ready: {
      value: 'ready',
      label: 'Ready',
      color: '#4B9EC1',
    },
    error: {
      value: 'error',
      label: 'Error',
      color: '#4B9EC1',
    },
    warning: {
      value: 'warning',
      label: 'Warning',
      color: '#4B9EC1',
    },
    internal: {
      value: 'internal',
      label: 'Internal',
      color: '#4B9EC1',
    },
    external: {
      value: 'external',
      label: 'External',
      color: '#4B9EC1',
    },
  }));

  const sharedTagTypes = useMemo(
    () => ({
      smartAd: {
        value: 'smartAd',
        label: 'SmartAd',
        color: '#4B9EC1',
      },
      smartPromo: {
        value: 'smartPromo',
        label: 'SmartPromo',
        color: '#4B9EC1',
      },
      dynamic: {
        value: 'dynamic',
        label: 'Dynamic',
        color: '#4B9EC1',
      },
      bakedIn: {
        value: 'bakedIn',
        label: 'Baked In',
        color: '#4B9EC1',
      },
    }),
    [],
  );

  const handleSetViewedTour = () => {
    setViewedTour({ teamId, flag: 'viewed_adops_tour', value: true });
  };

  useEffect(() => {
    if (!showAdOpsTour) return;
    handleSetViewedTour();
  }, []);

  return (
    <Switch>
      <Route
        path="/teams/:teamId/dashboard/adops/manage/:placementId/:byPodcast?"
        component={PlacementDetailView}
      />
      {!props.readOnlyMode && (
        <Route
          path="/teams/:teamId/dashboard/adops/settings"
          render={renderProps => (
            <Settings
              {...renderProps}
              art19DecryptionKeys={art19DecryptionKeys}
              attributionStatusNotifications={attributionStatusNotifications}
              smartpromoApprovalRequests={smartpromoApprovalRequests}
              teamHashedId={teamHashedId}
            />
          )}
        />
      )}
      <Route
        path="/teams/:teamId/dashboard/adops/requests/:audioAdPlacementRequestId"
        render={renderProps => <AudioAdPlacementRequestDetailView {...renderProps} />}
      />
      <Route path="/teams/:teamId/dashboard/adops">
        <div>
          <div className="bg-white pt4 ph4 mb4">
            <div className="flex flex-row gap-small">
              <Header useNew className="mb2 tour-step-zero">
                AdOps
              </Header>
              {!props.readOnlyMode && (
                <Button
                  onClick={() => history.push(`/teams/${teamId}/dashboard/adops/settings`)}
                  className="ml-auto"
                  type="primary"
                >
                  Settings
                </Button>
              )}
            </div>
            <div className="f5 mb4 mid-gray">Manage sold campaigns and promos</div>
            <div className="flex flex-row gap-large">
              <TabBarItem
                className="tour-step-one"
                title="Requests"
                route={`/teams/${teamId}/dashboard/adops/requests`}
                selected={location.pathname === `/teams/${teamId}/dashboard/adops/requests`}
                disableWhenSelected
              />
              <TabBarItem
                className="tour-step-two"
                title="Manage"
                route={`/teams/${teamId}/dashboard/adops/manage`}
                selected={location.pathname === `/teams/${teamId}/dashboard/adops/manage`}
                disableWhenSelected
              />
            </div>
          </div>
          <Switch>
            <Route
              path="/teams/:teamId/dashboard/adops/manage"
              render={renderProps => (
                <Manage {...renderProps} tagTypes={{ ...sharedTagTypes, ...approvedTagTypes }} />
              )}
            />
            <Route
              path="/teams/:teamId/dashboard/adops/requests"
              render={renderProps => (
                <RequestOverview
                  {...renderProps}
                  tagTypes={{ ...sharedTagTypes, ...requestTagTypes }}
                />
              )}
            />
            <Redirect to="/teams/:teamId/dashboard/adops/requests" />
          </Switch>
          <AdOpsTour showTour={showAdOpsTour} />
        </div>
      </Route>
    </Switch>
  );
};

const WrappedAdOps = withUser(AdOps);

const AdOpsRouter = props => {
  return (
    <ReadOnlyModeContext.Provider value={props.readOnlyMode}>
      <UserContext.Provider value={props.userInfo}>
        <Sidebar readOnlyMode={props.readOnlyMode} sidebar={props.sidebar}>
          <Router>
            <Route
              path="/teams/:teamId/dashboard/adops"
              render={renderProps => <WrappedAdOps {...props} {...renderProps} />}
            />
          </Router>
        </Sidebar>
      </UserContext.Provider>
    </ReadOnlyModeContext.Provider>
  );
};

export default AdOpsRouter;
