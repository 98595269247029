import React from 'react';
import Button from 'shared/Button';
import { redirectToPricingPage } from 'shared/helpers';

const MarketingPage = ({ match }) => (
  <div className="pa4 flex flex-row gap-large">
    <div className="pa3 w-50">
      <div className="header-font f2 mb3">The Easiest Way to Market Your Podcast</div>
      <div className="force-inter f4 lh-copy">
        Instantly translate your audience data into a{' '}
        <b className="blue">compelling story for potential sponsors</b>
      </div>
      <Button
        type="primary"
        className="mt3 mb5"
        onClick={() => {
          redirectToPricingPage('Clicked Upgrade: Sales Sheet', match.params.teamId);
        }}
      >
        Upgrade to get started
      </Button>
      <div>
        <div className="f4 mb3 force-inter b">Marketing material, made simple</div>
        <div className="f5 lh-copy mb2">
          Sales Sheets make the task of finding, aggregating, and presenting a information about
          your podcast simpler than ever—without ever having to run a listener survey.
        </div>
        <div className="f5 lh-copy mb2">
          After creating a Sales Sheet, you can easily share them with the click of a button.
        </div>
        <div className="f5 lh-copy mb2">
          Here are some examples from publishers who have already created their first Sales Sheets:
        </div>
        <div>
          <a
            href="https://chartable.com/sheet/2GLXsXPG"
            referrer="noreferrer"
            target="_blank"
            className="no-underline dark-gray"
          >
            <div className="w-100 pa3 br3 mb2 hover-bg-blue hover-white force-inter pointer bg-white">
              <div className="f4 force-inter">The Jordan Harbinger Show</div>
              <div className="f5">Podcast One</div>
            </div>
          </a>
          <a
            href="https://chartable.com/sheet/vMB5srK0"
            referrer="noreferrer"
            target="_blank"
            className="no-underline dark-gray"
          >
            <div className="w-100 pa3 br3 mb2 hover-bg-blue hover-white force-inter pointer bg-white">
              <div className="f4 force-inter">Crónicas Obscuras</div>
              <div className="f5">Sonoro</div>
            </div>
          </a>
          <a
            href="https://chartable.com/sheet/30OQsD7G"
            referrer="noreferrer"
            target="_blank"
            className="no-underline dark-gray"
          >
            <div className="w-100 pa3 br3 mb4 hover-bg-blue hover-white force-inter pointer bg-white">
              <div className="f4 force-inter">Revisionist History</div>
              <div className="f5">Pushkin</div>
            </div>
          </a>
        </div>
        <Button
          type="create"
          className="mb5"
          onClick={() => {
            redirectToPricingPage('Clicked Upgrade: Sales Sheet', match.params.teamId);
          }}
        >
          Try it for yourself
        </Button>
      </div>
    </div>
    <div className="bg-white br3 pa3">
      <img src="https://cdn.buttercms.com/ZTxVbmOGQjmD2k5Q6Jjo" />
    </div>
  </div>
);

export default MarketingPage;
