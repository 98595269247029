import React from 'react';
import PropTypes from 'prop-types';

export default function FormError({ errorMessage }) {
  if (!errorMessage) {
    return <div />;
  }

  return <div className="red mt1">{errorMessage}</div>;
}

FormError.defaultProps = {
  error: null,
};
