/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useParams } from 'react-router-dom';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import {
  fetchTrackingLinkDetails,
  fetchTrackingLinkTimeseriesData,
  getTrackingLink,
  updateTrackingLink,
} from 'shared/api/v1';
import { LookbackWindowSettings } from 'shared/AttributionSettings';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import ConfirmationModal from 'shared/ConfirmationModal';
import { mapPieChartColors } from 'shared/helpers.js';
import Loading from 'shared/Loading';
import TopBar from 'shared/SmallTopBar';
import StatCard from 'shared/StatCard';
import { withUser } from 'shared/UserContext';

import TrackingLinkForm from '../../components/TrackingLinkForm';
import Channel from './components/Channel';
import Clicks from './components/Clicks';
import DetailHeader from './components/DetailHeader';
import Devices from './components/Devices';
import Episode from './components/Episode';
import LinkInfo from './components/LinkInfo';
import Player from './components/Player';

const aggregations = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly',
};

function LinkDetailView({
  onArchiveTrackingLink,
  onSaveTrackingLink,
  canEditSmartLinks,
  history,
  startDate,
  endDate,
  handleUpdateDateRange,
  canChangeSmartLinksAttributionWindow,
  lookbackWindow,
  teamHashedId,
  handleChangeLookbackWindow,
  availableLookbackWindows,
  location,
  linkableReadOnly,
}) {
  const { trackingLinkId, teamId } = useParams();

  const urlParams = new URLSearchParams(location.search);
  const isNew = urlParams.get('isNew') === 'true'; // ¯\_(ツ)_/¯

  const [sourceId, setSourceId] = useState(null);
  const [trackingLink, setTrackingLink] = useState(null);

  // Timeseries data
  const [clickHistory, setClickHistory] = useState(null);
  const [downloadHistory, setDownloadHistory] = useState(null);

  // Graph data
  const [channelDetails, setChannelDetails] = useState(null);
  const [playerDetails, setPlayerDetails] = useState(null);
  const [episodeDetails, setEpisodeDetails] = useState(null);

  const [banner, setBanner] = useState(null);

  const [aggregation, setAggregation] = useState(aggregations.daily);

  const [failedToLoadTrackingLink, setFailedToLoadTrackingLink] = useState(false);

  const [archiveConfirmationModalIsOpen, setArchiveConfirmationModalIsOpen] = useState(false);
  const [attributionWindowSettingsIsOpen, setAttributionWindowSettingsIsOpen] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  async function handleFetchTrackingLinkTimeseriesData() {
    try {
      const params = {
        startDate,
        endDate,
        aggregation,
        trackingLinkId,
        sourceId,
        metrics: 'clicks,devices',
      };
      const res = await fetchTrackingLinkTimeseriesData(params);
      if (res.data.clicks) {
        setClickHistory(res.data.clicks);
      }
      if (res.data.devices) {
        setDownloadHistory(res.data.devices);
      }
    } catch (e) {
      setBanner(
        <BannerNotice
          error
          message="Failed to fetch SmartLink timeseries data"
          onClose={() => setBanner(null)}
        />,
      );
    }
  }

  async function handleFetchTrackingLinkDetails() {
    try {
      const params = {
        startDate,
        endDate,
        aggregation,
        trackingLinkId,
        sourceId,
        by: 'channels,players,episodes',
      };
      const res = await fetchTrackingLinkDetails(params);
      if (res.data.channels) {
        setChannelDetails(res.data.channels);
      }
      if (res.data.players) {
        setPlayerDetails(res.data.players);
      }
      if (res.data.episodes) {
        setEpisodeDetails(res.data.episodes);
      }
    } catch (e) {
      setBanner(
        <BannerNotice
          error
          message="Failed to fetch SmartLink details"
          onClose={() => setBanner(null)}
        />,
      );
    }
  }

  async function handleLoadTrackingLink() {
    if (!trackingLinkId) return;
    try {
      const params = {
        trackingLinkId,
        sourceId,
        startDate,
        endDate,
      };
      const res = await getTrackingLink(params);
      setTrackingLink(res.data);
    } catch (e) {
      setFailedToLoadTrackingLink(true);
    }
  }

  async function handleArchiveSmartLink() {
    try {
      const params = { id: trackingLinkId, archived: true };
      await updateTrackingLink(params);
      onArchiveTrackingLink();
      history.push(`/teams/${teamId}/dashboard/links`);
    } catch (e) {
      setBanner(
        <BannerNotice
          error
          message={linkableReadOnly ? e.response.data.errors : 'Failed to archive SmartLink'}
          onClose={() => setBanner(null)}
        />,
      );
    }
  }

  useEffect(() => window.scroll(0, 0), []);

  useEffect(() => {
    handleFetchTrackingLinkTimeseriesData();
    handleFetchTrackingLinkDetails();
  }, [startDate, endDate, aggregation, sourceId, lookbackWindow]);

  useEffect(() => {
    setTrackingLink(null);
    handleLoadTrackingLink();
  }, [trackingLinkId, sourceId, startDate, endDate, lookbackWindow]);

  const referrerColorMap = useMemo(
    () =>
      trackingLink && channelDetails ? mapPieChartColors(Object.values(channelDetails)) : null,
    [trackingLink],
  );

  const playerColorMap = useMemo(
    () =>
      trackingLink && trackingLink.players
        ? mapPieChartColors(Object.values(trackingLink.players))
        : null,
    [trackingLink],
  );

  const sourceOptions = useMemo(() => {
    if (!trackingLink) return null;
    const sourceOptions = trackingLink.sourceIds
      .map(option => (option ? { value: option, label: option } : null))
      .filter(o => o);
    sourceOptions.unshift({ value: null, label: 'All Clicks' });
    return sourceOptions;
  }, [trackingLink]);

  if (!trackingLink) {
    return (
      <div>
        <TopBar backText="Overview" backUrl={`/teams/${teamId}/dashboard/links`} />
        {failedToLoadTrackingLink ? (
          <BannerNotice
            message="Failed to load SmartLinks. Please reach out to support if this problem persists."
            error
          />
        ) : (
          <Loading fullScreen />
        )}
      </div>
    );
  }

  return (
    <Switch>
      <Route path="/teams/:teamId/dashboard/links/:trackingLinkId/edit">
        <TrackingLinkForm
          trackingLink={trackingLink}
          onSaveTrackingLink={onSaveTrackingLink}
          TrackingLinkForm={TrackingLinkForm}
          linkableReadOnly={linkableReadOnly}
        />
      </Route>
      <Route path="/teams/:teamId/dashboard/links/:trackingLinkId">
        <div>
          <ReactTooltip multiline id="lastUpdated" />
          <TopBar
            backText="Overview"
            backUrl={`/teams/${teamId}/dashboard/links`}
            podcast={trackingLink.podcast}
          />
          {banner}
          <div className="pa3">
            <DetailHeader
              canChangeSmartLinksAttributionWindow={canChangeSmartLinksAttributionWindow}
              setAttributionWindowSettingsIsOpen={setAttributionWindowSettingsIsOpen}
              lookbackWindow={lookbackWindow}
              startDate={startDate}
              endDate={endDate}
              canEditSmartLinks={canEditSmartLinks}
              handleUpdateDateRange={handleUpdateDateRange}
              trackingLink={trackingLink}
              aggregation={aggregation}
              aggregations={aggregations}
              setAggregation={setAggregation}
            />
            <LinkInfo
              canEditSmartLinks={canEditSmartLinks}
              trackingLink={trackingLink}
              teamId={teamId}
              setShowDropDown={setShowDropDown}
              showDropDown={showDropDown}
              setArchiveConfirmationModalIsOpen={setArchiveConfirmationModalIsOpen}
              isNew={isNew}
            />
            <div className="card pa3 mb3">
              <div className="dark-gray mb2">Filter by Source ID</div>
              <Select
                value={{
                  value: sourceId ? sourceId : null,
                  label: sourceId ? sourceId : 'All clicks',
                }}
                onChange={selection => setSourceId(selection.value)}
                options={sourceOptions}
              />
            </div>
            <div className="inline-flex gap flex-wrap w-100">
              <StatCard
                title="Clicks"
                stat={trackingLink.clicks}
                inlineStyle={{ flex: 1 }}
                tooltip="Total number of clicks on the link"
              />
              <StatCard
                title="Unique Clicks"
                stat={trackingLink.uniqueClicks}
                inlineStyle={{ flex: 1 }}
                tooltip="Total number of unique clicks on the link"
              />
              <StatCard
                title="Converted Devices"
                stat={trackingLink.uniqueDownloads}
                inlineStyle={{ flex: 1 }}
                tooltip="Number of unique devices attributed to link.  Number of downloads performed by these devices shown below."
                byline={`${trackingLink.uniqueDeviceDownloads} downloads`}
              />
              {trackingLink.podcast.trackableEnabled && (
                <StatCard
                  title="New Converted Devices"
                  stat={trackingLink.conversions}
                  inlineStyle={{ flex: 1 }}
                  tooltip="Devices that hadn't downloaded an episode of the show in the past 60 days."
                  byline={`${trackingLink.newDeviceDownloads} downloads`}
                />
              )}
            </div>
            <Clicks
              clickHistory={clickHistory}
              startDate={startDate}
              endDate={endDate}
              aggregation={aggregation}
            />
            <Devices
              downloadHistory={downloadHistory}
              startDate={startDate}
              endDate={endDate}
              aggregation={aggregation}
            />
            {channelDetails && !sourceId && (
              <Channel channelDetails={channelDetails} referrerColorMap={referrerColorMap} />
            )}
            <React.Fragment>
              {playerDetails && (
                <Player playerDetails={playerDetails} playerColorMap={playerColorMap} />
              )}
              {episodeDetails && (
                <Episode episodeDetails={episodeDetails} playerColorMap={playerColorMap} />
              )}
            </React.Fragment>
          </div>
        </div>
        <ConfirmationModal
          title="Archive this SmartLink?"
          onConfirm={handleArchiveSmartLink}
          isOpen={archiveConfirmationModalIsOpen}
          onClose={() => setArchiveConfirmationModalIsOpen(false)}
        />
        <BasicModal
          isOpen={attributionWindowSettingsIsOpen}
          onRequestClose={() => setAttributionWindowSettingsIsOpen(false)}
          ariaHideApp={false}
        >
          <div>
            <LookbackWindowSettings
              teamId={teamId}
              teamHashedId={teamHashedId}
              lookbackWindow={lookbackWindow}
              availableLookbackWindows={availableLookbackWindows}
              onSuccess={team => {
                handleChangeLookbackWindow(team.linkLookbackWindowHours);
                setAttributionWindowSettingsIsOpen(false);
              }}
              onFailure={() => {
                setBanner(
                  <BannerNotice
                    error
                    message="Failed to update attribution window."
                    onClose={() => setBanner(null)}
                  />,
                );
                setAttributionWindowSettingsIsOpen(false);
              }}
              defaultWindow={1}
              useRadioButtons
              product="smartlinks"
            />
            <Button className="mt3 w4" onClick={() => setAttributionWindowSettingsIsOpen(false)}>
              Close
            </Button>
          </div>
        </BasicModal>
      </Route>
    </Switch>
  );
}

LinkDetailView.propTypes = {
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  handleUpdateDateRange: PropTypes.func.isRequired,
  onArchiveTrackingLink: PropTypes.func.isRequired,
  onSaveTrackingLink: PropTypes.func.isRequired,
  handleChangeLookbackWindow: PropTypes.func.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
  canEditSmartLinks: PropTypes.bool.isRequired,
  canChangeSmartLinksAttributionWindow: PropTypes.bool.isRequired,
  canCreateReports: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  availableLookbackWindows: PropTypes.any,
  location: PropTypes.any,
  hasSmartLinksAutomation: PropTypes.bool,
  automatedLinkUserId: PropTypes.any,
};

export default withUser(LinkDetailView);
