import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

import { getEpisodeOptions, refreshEpisodes, getEpisodes, getEpisode } from 'shared/api/v1';

function mapEpisodesToOptions(episodes) {
  return episodes.map(episode => ({
    value: episode.cid,
    label: episode.title,
  }));
}

function EpisodeSearchInput({
  teamId,
  podcastId,
  onSelect,
  className,
  allowRefresh,
  numberOfEpisodesToPrefetch,
  initialEpisodeId,
  initialEpisode,
  showClearInput,
}) {
  const [episode, setEpisode] = useState(initialEpisode ? initialEpisode : null);
  const [loadingEpisodes, setLoadingEpisodes] = useState(numberOfEpisodesToPrefetch > 0);
  const [prefetchedEpisodes, setPrefetchedEpisodes] = useState(null);
  const [loadingInitialEpisode, setLoadingInitialEpisode] = useState(false);

  function handleSelection(selection) {
    setEpisode(selection ? { id: selection.value, title: selection.label } : null);
    onSelect(selection ? { id: selection.value, title: selection.label } : null);
  }

  function loadEpisodeOptions(query, callback) {
    getEpisodeOptions({ query, teamId, podcastId }).then(res => {
      const options = mapEpisodesToOptions(res.data.results);
      callback(options);
    });
  }

  function handleRefreshEpisodes() {
    if (loadingEpisodes) return;
    setLoadingEpisodes(true);
    refreshEpisodes({ teamId, podcastId })
      .then(() => handlePrefetchEpisodes())
      .catch(() => setLoadingEpisodes(false));
  }

  function handlePrefetchEpisodes() {
    if (numberOfEpisodesToPrefetch === 0) {
      setLoadingEpisodes(false);
      return;
    }

    getEpisodes({ teamId, podcastId, pageSize: numberOfEpisodesToPrefetch })
      .then(res => setPrefetchedEpisodes(res.data))
      .finally(() => setLoadingEpisodes(false));
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handlePrefetchEpisodes(), []);

  useEffect(() => {
    const handleGetEpisode = async () => {
      setLoadingInitialEpisode(true);
      try {
        const res = await getEpisode({
          episodeId: initialEpisodeId,
          podcastId: podcastId,
          includeChartPositions: false,
          includeCharts: false,
          teamId,
        });
        handleSelection({ value: res.data.id, label: res.data.title });
        // eslint-disable-next-line no-empty
      } catch (_) {}
      setLoadingInitialEpisode(false);
    };

    if (!initialEpisode && podcastId && initialEpisodeId) {
      handleGetEpisode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultOptions = useMemo(() => {
    if (!prefetchedEpisodes) return [];
    return mapEpisodesToOptions(prefetchedEpisodes);
  }, [prefetchedEpisodes]);

  const value = {
    value: episode ? episode.id : null,
    label: episode ? episode.title : 'Search by episode title...',
  };

  return (
    <div className={`${className} mw-100`}>
      {allowRefresh && (
        <div className="mid-gray f6 mb1">
          Type to search for your episode.{' '}
          <a onClick={handleRefreshEpisodes} className="blue link pointer">
            Refresh Episodes.
          </a>
        </div>
      )}
      <AsyncSelect
        value={value}
        defaultOptions={defaultOptions}
        onChange={handleSelection}
        isDisabled={!podcastId || loadingEpisodes || loadingInitialEpisode}
        isLoading={loadingInitialEpisode}
        loadOptions={(query, callback) => loadEpisodeOptions(query, callback)}
        noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Type to search' : 'No results')}
      />
      {showClearInput && (
        <div className="link blue pointer f6 mt2" onClick={() => handleSelection(null)}>
          Clear Episode
        </div>
      )}
    </div>
  );
}

EpisodeSearchInput.propTypes = {
  teamId: PropTypes.string.isRequired,
  podcastId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  allowRefresh: PropTypes.bool,
  numberOfEpisodesToPrefetch: PropTypes.number,
  initialEpisodeId: PropTypes.string, // fixme
  initialEpisode: PropTypes.object,
  showClearInput: PropTypes.bool,
};

EpisodeSearchInput.defaultProps = {
  className: '',
  allowRefresh: false,
  numberOfEpisodesToPrefetch: 0,
  initialEpisode: null,
  initialEpisodeId: null,
};

export default EpisodeSearchInput;
