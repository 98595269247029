import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

function TabBarItem({ title, selected, route, disableWhenSelected, notification }) {
  const selectedClassName = 'no-underline f6 b dim pointer blue pb2 bb bw2 tc b--blue';
  const unselectedClassName = 'no-underline f6 dim pointer near-black pb2 bb bw2 tc b--moon-gray';

  if (selected && disableWhenSelected) {
    return (
      <div style={{ width: '7rem' }} className={selectedClassName}>
        {notification ? (
          <div className="flex justify-center flex-row">
            <div className="mr1">{title}</div>
            <div style={{ fontSize: '0.5rem' }} className="bg-red white ph1 flex items-center br4">
              {notification}
            </div>
          </div>
        ) : (
          title
        )}
      </div>
    );
  }

  return (
    <Link
      to={route}
      style={{ width: '7rem' }}
      className={selected ? selectedClassName : unselectedClassName}
    >
      {notification ? (
        <div className="flex justify-center flex-row">
          <div className="mr1">{title}</div>
          <div style={{ fontSize: '0.5rem' }} className="bg-red white ph1 flex items-center br4">
            {notification}
          </div>
        </div>
      ) : (
        title
      )}
    </Link>
  );
}

TabBarItem.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  disableWhenSelected: PropTypes.bool,
  notification: PropTypes.any,
};

TabBarItem.defaultProps = {
  disableWhenSelected: true,
  notification: null,
};

function TabBar({ children, className }) {
  return <div className={`flex flex-row items-end ${className}`}>{children}</div>;
}

export { TabBarItem };

export default TabBar;
