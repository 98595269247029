/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { destroyCredential, getSpotifyOauthUrl, scrapeCredential } from 'shared/api';
import Banner from 'shared/Banner';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';
import SmallTopBar from 'shared/SmallTopBar';

import Connections from './Connections';
import PublicInfo from './PublicInfo';
import Success from './Success';

const SpotifyOauth = ({ teamId, sidebar, credentials: serverCredentials, upgradeUrl, isAdmin }) => {
  const checkActive = creds => creds.findIndex(c => c.status === 'active') > -1;
  const [credentials, setCredentials] = useState(serverCredentials);
  const [hasActiveConnection, setHasActiveConnection] = useState(checkActive(serverCredentials));
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleLogin = async () => {
    setError(false);
    try {
      const res = await getSpotifyOauthUrl({
        teamId: teamId,
      });
      window.location = res.data.url;
    } catch (e) {
      setError(true);
    }
  };

  const removeConnection = credentialId => async () => {
    if (confirm('Warning: You will lose access to any data connected to this account!')) {
      setLoading(true);
      setError(false);
      try {
        await destroyCredential({
          id: credentialId,
          teamId: teamId,
        });
        const newCreds = [...credentials.filter(c => c.id !== credentialId)];
        setCredentials(newCreds);
        setHasActiveConnection(checkActive(newCreds));
      } catch (e) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };

  const scrapeConnection = credentialId => async () => {
    if (!isAdmin) {
      return;
    }
    setLoading(true);
    setError(false);
    try {
      await scrapeCredential({
        id: credentialId,
        teamId: teamId,
      });
      alert('scrape initiated. check for demos in a bit.');
    } catch (e) {
      setError(false);
    } finally {
      setLoading(false);
    }
  };

  const Error = () => (
    <div className="mb4">
      <Banner
        type="error"
        onClick={() => setError(false)}
        style={{ cursor: 'pointer' }}
        hoverText="click to dismiss"
      >
        An error has occurred. Please try again or contact support.
      </Banner>
    </div>
  );

  return (
    <Sidebar sidebar={sidebar}>
      <SmallTopBar
        text="Spotify Integration"
        backText="Integrations"
        backUrl={`/teams/${teamId}/dashboard/integrations`}
        hasRouter={false}
      />
      <div className="mw7 pa3 mb2">
        {isSuccess && hasActiveConnection && <Success setIsSuccess={setIsSuccess} />}
        {error && <Error />}
        <div className="f3 dark-blue">Connect to Spotify</div>
        <PublicInfo />
        {loading ? (
          <div className="tc ma5">
            <Loading />
          </div>
        ) : (
          <Connections
            handleLogin={handleLogin}
            credentials={credentials}
            isAdmin={isAdmin}
            removeConnection={removeConnection}
            hasActiveConnection={hasActiveConnection}
            scrapeConnection={scrapeConnection}
          />
        )}
      </div>
    </Sidebar>
  );
};

export default SpotifyOauth;
