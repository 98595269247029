import React, { useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { updateTeam } from 'shared/api';
import Button from 'shared/Button';
import Checkbox from 'shared/Checkbox';
import Header from 'shared/Header';

const teamLookbackWindowAttributes = {
  smartpromos: 'promoLookbackWindowDays',
  smartlinks: 'linkLookbackWindowHours',
};

function LookbackWindowSettings({
  teamId,
  teamHashedId,
  lookbackWindow,
  availableLookbackWindows,
  onSuccess,
  onFailure,
  defaultWindow,
  product,
  useRadioButtons,
}) {
  if (product !== 'smartpromos' && product !== 'smartlinks') {
    throw new Error(
      "You must pass a valid 'product' prop to LookbackWindowSettings. Options: 'smartpromos', 'smartlinks'",
    );
  }

  const [selectedLookbackWindow, setSelectedLookbackWindow] = useState(lookbackWindow);
  const [requestInProgress, setRequestInProgress] = useState(false);

  function handleSave() {
    if (requestInProgress) return;
    setRequestInProgress(true);

    const params = { teamId, id: teamHashedId };

    // Select the correct update param name based on the product (see "enum" above for definitions)
    params[teamLookbackWindowAttributes[product]] = selectedLookbackWindow;

    updateTeam(params)
      .then(res => onSuccess(res.data))
      .catch(() => onFailure())
      .finally(() => setRequestInProgress(false));
  }

  let defaultWindowString;
  switch (product) {
    case 'smartpromos':
      defaultWindowString = '7-day';
      break;
    case 'smartlinks':
      defaultWindowString = '1-hour';
      break;
    default:
      defaultWindowString = '7-day';
      break;
  }

  return (
    <div className="card">
      <Header className="mb2 dark-blue" useNew>
        Attribution Window
      </Header>
      <div className="mid-gray mb3">
        Your attribution window controls how many days after each impression we look to find
        conversions. This can be changed at any time and does not impact how data is collected,
        stored, or saved.
      </div>
      <div className="mid-gray mb3">
        <b>Note:</b> For data collected prior to 10/1/2020, we can only show results on the default{' '}
        {defaultWindowString} window.
      </div>
      {useRadioButtons ? (
        <div>
          {availableLookbackWindows.map(lb => (
            <Checkbox
              key={lb}
              className="mb3"
              radioButton
              isChecked={selectedLookbackWindow === lb}
              onClick={() => setSelectedLookbackWindow(lb)}
              label={
                // eslint-disable-next-line no-nested-ternary
                product === 'smartlinks'
                  ? lb % 24 > 0
                    ? `${lb} hour${lb === 1 ? '' : 's'}`
                    : `${lb / 24} days`
                  : lb
              }
              flavorText={lb === defaultWindow ? '(default)' : null}
            />
          ))}
        </div>
      ) : (
        <Select
          aria-label="lookback-window-selector"
          value={{
            value: selectedLookbackWindow,
            label: `${selectedLookbackWindow} days`,
          }}
          onChange={selection => setSelectedLookbackWindow(selection.value)}
          options={availableLookbackWindows.map(lb => ({
            value: lb,
            label: `${lb} days`,
          }))}
          className="w-100 mb3"
        />
      )}
      <Button className="w4" onClick={handleSave} type="primary" testId="lookback-window-save-btn">
        Save
      </Button>
    </div>
  );
}

LookbackWindowSettings.propTypes = {
  teamId: PropTypes.string.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  product: PropTypes.string.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  availableLookbackWindows: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
  defaultWindow: PropTypes.number,
  useRadioButtons: PropTypes.bool,
};

LookbackWindowSettings.defaultProps = {
  useRadioButtons: false,
  defaultWindow: null,
};

export default LookbackWindowSettings;
