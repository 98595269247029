import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DropzoneS3Uploader from 'react-dropzone-s3-uploader';

import Button from 'shared/Button';
import { fetchSignedImageUrl, deleteSocialMediaUrl } from 'shared/api';

// ***
// react-dropzone-s3-uploader uses componentWillMount and componentWillReceiveProps
// which will be deprecated in React 17.
//
// For now they will throw console warnings. At some point we should either switch to
// another uploader library or use a simpler library (like react-s3-uploader)
// as a base to build our own.
//
// As of January, 2021 for the SmartLinks Creator Refactor project, I'm leaving it as is -JS
// ***

function TrackingLinkImageUploadHandler({
  teamId,
  onUploadImage,
  onRemoveImage,
  initialImageUrl,
  linkableReadOnly,
}) {
  const [imageS3Url, setImageS3Url] = useState(initialImageUrl ? initialImageUrl : null);
  const [uploadBoxIsHidden, setUploadBoxIsHidden] = useState(!!initialImageUrl);
  const [error, setError] = useState(null);
  const [progress, setProgress] = useState(0);
  const [removingImage, setRemovingImage] = useState(false);

  function handleLoadSignedImageUrl(file, callback) {
    setUploadBoxIsHidden(true);

    fetchSignedImageUrl({
      teamId,
      objectName: file.name,
      objectType: file.type,
    })
      .then(res => callback(res.data))
      .catch(() => {
        setUploadBoxIsHidden(true);
        setError('Failed to upload image');
      });
  }

  function handleDeleteImage() {
    if (linkableReadOnly) {
      setError('SmartLinks cannot be changed at this time.');
    }

    if (removingImage) return;
    setRemovingImage(true);

    if (initialImageUrl !== null && initialImageUrl === imageS3Url) {
      setImageS3Url(null);
      setUploadBoxIsHidden(false);
      onRemoveImage();
      setRemovingImage(false);
      return;
    }

    deleteSocialMediaUrl({ teamId, socialMediaImageUrl: imageS3Url })
      .then(() => {
        setUploadBoxIsHidden(false);
        setProgress(0);
        setImageS3Url(null);
        onRemoveImage();
      })
      .catch(() => setError('Failed to delete image'))
      .finally(() => setRemovingImage(false));
  }

  function handleFinishUpload(info) {
    const imageUrl = info.signedUrl.split('?')[0];
    setImageS3Url(imageUrl);
    onUploadImage(imageUrl);
  }

  function handleError() {
    setUploadBoxIsHidden(false);
    setProgress(0);
    setError('Failed to upload image');
  }

  function handleProgress(progress) {
    setError(null);
    setProgress(progress);
  }

  function handleDropRejected() {
    setUploadBoxIsHidden(false);
    setProgress(0);
    setError('Could not upload image. Check that it is not too big. (Can be up to 1280 x 1024)');
  }

  const uploadOptions = {
    getSignedUrl: handleLoadSignedImageUrl,
    uploadRequestHeaders: {},
  };

  return (
    <div className="h5 pv3 ph3 flex flex-column">
      <div className="center mb2 red b">{error}</div>
      {uploadBoxIsHidden && (
        <div className="center mt2">
          {imageS3Url ? (
            <React.Fragment>
              <a href={imageS3Url} target="_blank" rel="noreferrer">
                <img className="h4-5 w-auto" src={imageS3Url} />
              </a>
              <Button
                className="w4 f6 center mt1"
                size="small"
                type="destructive"
                onClick={handleDeleteImage}
              >
                Remove
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="dark-gray f5 mb2">Uploading ...</div>
              <div className="w5 h1 bg-moon-gray">
                <div className="bg-blue h1" style={{ width: `${progress}%` }} />
              </div>
            </React.Fragment>
          )}
        </div>
      )}
      <DropzoneS3Uploader
        upload={uploadOptions}
        onFinish={handleFinishUpload}
        s3Url="" // This does not seem to matter because we are using presigned urls even though it's a required prop -JS
        className={`${
          uploadBoxIsHidden ? 'dn' : 'flex'
        } self-center items-center justify-center center`}
        accept="image/*"
        onProgress={handleProgress}
        onError={handleError}
        onDropRejected={handleDropRejected}
        maxSizeBytes={1024 * 1024 * 25}
        disabled={linkableReadOnly}
      >
        <div className="tc pa3 b f5 center blue">Click or drag an image file to upload</div>
      </DropzoneS3Uploader>
    </div>
  );
}

TrackingLinkImageUploadHandler.propTypes = {
  teamId: PropTypes.string.isRequired,
  onUploadImage: PropTypes.func.isRequired,
  onRemoveImage: PropTypes.func.isRequired,
  initialImageUrl: PropTypes.string,
};

TrackingLinkImageUploadHandler.defaultProps = {
  initialImageUrl: null,
};

export default TrackingLinkImageUploadHandler;
