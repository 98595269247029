import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

function handleClick(onClick, history, backUrl) {
  if (onClick) {
    onClick();
    return;
  } else if (backUrl) {
    history.push(backUrl);
  } else {
    history.goBack();
  }
}

function BackBar({ buttonText, history, backUrl, onClick }) {
  return (
    <div
      onClick={() => handleClick(onClick, history, backUrl)}
      className="w-100 pv2 ph3 white bg-light-blue tl pointer"
    >
      « {buttonText}
    </div>
  );
}

BackBar.propTypes = {
  history: PropTypes.object,
  buttonText: PropTypes.string,
  backUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default withRouter(BackBar);
