import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ReactTable from 'react-table';
import moment from 'moment';
import {
  adminFillPromoEpisode,
  adminGetPromoApprovalBreakdown,
  adminGetPromosToFill,
  adminGetPromosWithStatus,
  adminSendPromoApprovalEmail,
  adminUpdatePromo,
  adminUpdateTeamEmailPreferences,
} from 'shared/api';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import EpisodeSelectorForm from 'shared/EpisodeSelectorForm';
import FormPodcast from 'shared/FormPodcast';
import Loading from 'shared/Loading';
import StatusIndicator from 'shared/StatusIndicator';

import 'react-table/react-table.css';
import PromoDetailView from './components/PromoDetailView';

const STATUSES = {
  NEEDS_APPROVAL: { value: 'Needs approval', label: 'Needs Approval' },
  DENIED: { value: 'Denied', label: 'Denied' },
  APPROVED: { value: 'Approved', label: 'Approved' },
};

class ScheduledPromoEpisodeFiller extends React.Component {
  constructor(props) {
    super(props);

    this.assignEpisode = this.assignEpisode.bind(this);

    this.state = {
      requestInProgress: false,
      promos: null,
      selectedRow: null,
      selectedEpisode: null,
      notice: null,
    };
  }

  componentDidMount() {
    adminGetPromosToFill().then(res => this.setState({ promos: res.data }));
  }

  assignEpisode() {
    if (this.state.requestInProgress) return;
    this.setState({ requestInProgress: true });

    const { selectedRow, selectedEpisode } = this.state;

    adminFillPromoEpisode({ promoId: selectedRow.id, episodeId: selectedEpisode.id })
      .then(res => {
        this.setState({
          promos: this.state.promos.filter(p => p.id !== res.data.removedPromo.audioAdPlacementId),
          notice: (
            <BannerNotice
              success
              message="Assigned episode"
              onClose={() => this.setState({ notice: null })}
            />
          ),
        });
      })
      .catch(err =>
        this.setState({
          notice: (
            <BannerNotice
              error
              message="Failed to send emails"
              onClose={() => this.setState({ notice: null })}
            />
          ),
        }),
      )
      .finally(() => this.setState({ requestInProgress: false }));
  }

  render() {
    const { requestInProgress, promos, selectedRow, selectedEpisode } = this.state;

    const columns = [
      {
        Header: 'Promo',
        Cell: row => {
          const promo = row.original;
          return (
            <a
              className="ph2 pv2"
              href={`/teams/${promo.buyerTeamSlug}/dashboard/promos/${promo.audioAdCampaignId}/analytics/${promo.id}`}
            >
              {promo.name}
            </a>
          );
        },
      },
      {
        Header: 'Scheduled Air Date',
        accessor: 'scheduledAirDate',
        id: 'scheduledAirDate',
        Cell: row => (
          <div className="tc pv2">{moment(row.original.scheduledAirDate).format('MM/DD/YYYY')}</div>
        ),
      },
      {
        Header: 'Promoted Podcast',
        Cell: row => (
          <div>
            {row.original.buyerPodcast ? (
              <div className="flex items-center">
                <div className="mr2" style={{ width: '15%' }}>
                  <img
                    src={row.original.buyerPodcast.displayImageUrl}
                    className="center fl br2"
                    alt=""
                  />
                </div>
                <div className="w-70">{row.original.buyerPodcast.shortTitle}</div>
              </div>
            ) : (
              <div className="tc">-</div>
            )}
          </div>
        ),
      },
      {
        Header: 'Promoted On Podcast',
        Cell: row => (
          <div>
            {row.original.sellerPodcast ? (
              <div className="flex items-center">
                <div className="mr2" style={{ width: '15%' }}>
                  <img
                    src={row.original.sellerPodcast.displayImageUrl}
                    className="center fl br2"
                    alt=""
                  />
                </div>
                <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
              </div>
            ) : (
              <div className="tc">-</div>
            )}
          </div>
        ),
      },
      {
        Header: 'Creator',
        accessor: 'creator',
      },
      {
        Header: '',
        Cell: row => (
          <Button
            onClick={() => {
              this.setState({
                selectedRow: row.original,
                selectedEpisode:
                  row.original.suggestedEpisodes.length === 1
                    ? row.original.suggestedEpisodes[0]
                    : null,
              });
            }}
            type="primary"
            loading={requestInProgress}
          >
            Assign Episode
          </Button>
        ),
      },
    ];

    if (!promos) {
      return <Loading fullScreen />;
    }

    return (
      <div>
        {this.state.notice}
        <Banner error className="mt3 mh3 b">
          This is a deprecated UI that should only be used if we need to fill a promo created before
          the client side episode filler was added (4/15/2022).
        </Banner>
        <div className="pa3 mt3 w-100">
          <ReactTable
            getTrProps={(state, rowInfo) => ({
              style: {
                display: 'flex',
                alignItems: 'center',
              },
            })}
            defaultSorted={[
              {
                id: 'scheduledAirDate',
                desc: true,
              },
            ]}
            data={promos}
            showPagination={false}
            pageSize={promos.length}
            columns={columns}
            className="-striped w-100 f5"
            NoDataComponent={() => null}
          />
          <BasicModal
            isOpen={!!selectedRow}
            onRequestClose={() => this.setState({ selectedRow: null, selectedEpisode: null })}
            ariaHideApp={false}
          >
            {!!selectedRow && (
              <div>
                <div className="f3 header-font near-black mb3 tc">Assign Episode</div>
                <div className="f4 near-black header-font mb2">Podcast</div>
                <div className="mb2">
                  <FormPodcast podcast={selectedRow.sellerPodcast} />
                </div>
                <div className="mb4">
                  <a target="_blank" href={`/podcasts/${selectedRow.sellerPodcast.slug}`}>
                    Go to podcast page
                  </a>
                </div>
                <div className="f4 near-black mb2">Scheduled Air Date</div>
                <div className="f4 gray mb4">
                  {moment(selectedRow.scheduledAirDate).format('MM/DD/YYYY')}
                </div>
                <div className="mb4">
                  <div className="flex flex-row items-baseline justify-between">
                    <div className="f4 near-black header-font mb2">Episode</div>
                    {selectedRow.suggestedEpisodes.length > 1 && (
                      <div>
                        <div className="red mb2 f5">
                          Multiple air dates dropped on the scheduled air date:
                        </div>
                        <div>
                          {selectedRow.suggestedEpisodes.map(episode => (
                            <div key={episode.id} className="b mb2 f5">
                              {episode.title}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {selectedRow.suggestedEpisodes.length === 1 && (
                      <div
                        className="f5 blue underline pointer"
                        onClick={() =>
                          this.setState({ selectedEpisode: selectedRow.suggestedEpisodes[0] })
                        }
                      >
                        Use suggested
                      </div>
                    )}
                  </div>
                  <EpisodeSelectorForm
                    teamId="chartable-radio"
                    podcast={{ ...selectedRow.sellerPodcast, id: selectedRow.sellerPodcast.slug }}
                    selectedEpisode={selectedEpisode}
                    onSelectEpisode={suggestion => this.setState({ selectedEpisode: suggestion })}
                    onUnselectEpisode={() => this.setState({ selectedEpisode: null })}
                    scopeToTeamPodcast={false}
                  />
                </div>
                <Button
                  className="mb2"
                  disabled={!selectedEpisode}
                  onClick={() => {
                    this.assignEpisode();
                    this.setState({ selectedRow: null, selectedEpisode: null });
                  }}
                  type="primary"
                >
                  Assign Episode
                </Button>
                <Button onClick={() => this.setState({ selectedRow: null, selectedEpisode: null })}>
                  Cancel
                </Button>
              </div>
            )}
          </BasicModal>
        </div>
      </div>
    );
  }
}

class EmailSenderView extends React.Component {
  constructor(props) {
    super(props);

    this.sendEmails = this.sendEmails.bind(this);
    this.updateTeamPreferences = this.updateTeamPreferences.bind(this);

    this.state = {
      promoRequests: null,
      selectedRow: null,
      requestInProgress: false,
      needSellerTeamCount: 0,
      notice: null,
      preferenceUpdateSuccess: false,
      preferenceUpdateFailed: false,
      preferenceUpdateInProgress: false,
    };
  }

  componentDidMount() {
    adminGetPromoApprovalBreakdown().then(res =>
      this.setState({
        promoRequests: res.data.promoRequests,
        needSellerTeamCount: res.data.needSellerTeamCount,
      }),
    );
  }

  sendEmails(teamId) {
    if (this.state.requestInProgress) return;
    this.setState({ requestInProgress: true });

    adminSendPromoApprovalEmail({ teamId: this.state.selectedRow.team.id })
      .then(res =>
        this.setState({
          notice: (
            <BannerNotice
              success
              message="Sent emails"
              onClose={() => this.setState({ notice: null })}
            />
          ),
        }),
      )
      .catch(err =>
        this.setState({
          notice: (
            <BannerNotice
              error
              message="Failed to send emails"
              onClose={() => this.setState({ notice: null })}
            />
          ),
        }),
      )
      .finally(() => this.setState({ requestInProgress: false }));
  }

  updateTeamPreferences(teamId, userEmailPreferences) {
    if (this.state.preferenceUpdateInProgress) return;
    this.setState({
      preferenceUpdateInProgress: true,
      preferenceUpdateSuccess: false,
      preferenceUpdateFailed: false,
    });

    adminUpdateTeamEmailPreferences({
      teamId,
      userEmailPreferences,
    })
      .then(res => {
        this.setState({ preferenceUpdateSuccess: true });
      })
      .catch(err => {
        this.setState({ preferenceUpdateFailed: true });
      })
      .finally(() => {
        this.setState({ preferenceUpdateInProgress: false });
      });
  }

  render() {
    if (!this.state.promoRequests) {
      return <Loading fullScreen />;
    }

    const {
      promoRequests,
      selectedRow,
      requestInProgress,
      needSellerTeamCount,
      preferenceUpdateSuccess,
      preferenceUpdateFailed,
      preferenceUpdateInProgress,
    } = this.state;

    const columns = [
      {
        Header: 'Team',
        accessor: 'team.name',
        className: 'tc ttc pv2',
        Cell: row => (
          <a href={`/teams/${row.original.team.slug}/dashboard`} target="_blank">
            {row.original.team.name}
          </a>
        ),
      },
      {
        Header: '# of Approvals needed',
        accessor: 'promoCount',
        className: 'tc',
      },
      {
        Header: '',
        Cell: row => (
          <Button
            loading={requestInProgress}
            onClick={() => this.setState({ selectedRow: row.original })}
            type="primary"
          >
            Send Emails
          </Button>
        ),
      },
    ];

    return (
      <div>
        {needSellerTeamCount > 0 && (
          <BannerNotice
            message={`There are ${needSellerTeamCount} promo(s) that need seller teams assigned`}
            actionText="Assign seller teams"
            action={() => this.props.history.push('/custom_admin/react/promos')}
          />
        )}
        {this.state.notice}
        <div className="pa3 w-100">
          <ReactTable
            getTrProps={(state, rowInfo) => ({
              style: {
                display: 'flex',
                alignItems: 'center',
                background: rowInfo.viewIndex % 2 ? '#F5FAFC' : '#FFFFFF',
              },
            })}
            data={promoRequests}
            showPagination={false}
            pageSize={promoRequests.length}
            columns={columns}
            className="-striped w-100 f5"
            NoDataComponent={() => null}
          />
        </div>
        <BasicModal
          isOpen={!!selectedRow}
          onRequestClose={() => this.setState({ selectedRow: null })}
          ariaHideApp={false}
        >
          {!!selectedRow && (
            <div>
              <div className="f4 header-font near-black mb2">
                Send approval emails to {selectedRow.team.name}
              </div>
              <div className="f5 lh-copy gray mb3">
                All members of this team who have a check below will receive an email to approve{' '}
                {selectedRow.promoCount} promo(s).
              </div>
              <Button
                className="mb2"
                onClick={() => {
                  this.sendEmails(this.state.selectedRow.team.id);
                  this.setState({ selectedRow: null });
                }}
                type="primary"
              >
                Send Emails
              </Button>
              <Button onClick={() => this.setState({ selectedRow: null })}>Cancel</Button>
              <div className="pt4 bt b--light-gray">
                <div className="flex flex-column mb4">
                  <div className="f5 mb1 header-font b">
                    Team Member's SmartPromo Email Preferences
                  </div>
                  <div className="f5 mb3">
                    Change user's promo request email preferences, if unchecked they won't receive
                    an email
                  </div>
                  {selectedRow.teamUsers.map((user, index) => (
                    <div key={user.userId} className="flex flex-row items-baseline f5">
                      <input
                        className="mr2 mb2"
                        type="checkbox"
                        onChange={() => {
                          selectedRow.teamUsers.map(u => {
                            if (u.userId === user.userId) {
                              u.emailPreferences.smartpromoApprovalRequests =
                                !user.emailPreferences.smartpromoApprovalRequests;
                              return u;
                            }
                            return u;
                          });
                          const _promoRequests = promoRequests.map(pr =>
                            pr.team.id === selectedRow.team.id ? selectedRow : pr,
                          );
                          this.setState({ promoRequests: _promoRequests });
                        }}
                        checked={user.emailPreferences.smartpromoApprovalRequests}
                      />
                      <div className="mr2">{user.email}</div>
                    </div>
                  ))}
                </div>
                {preferenceUpdateSuccess && <div className="mb2 f6 green">Update successful</div>}
                {preferenceUpdateFailed && <div className="mb2 f6 red">Update failed</div>}
                <Button
                  onClick={() =>
                    this.updateTeamPreferences(selectedRow.team.slug, selectedRow.teamUsers)
                  }
                  disabled={preferenceUpdateInProgress}
                  loading={preferenceUpdateInProgress}
                >
                  Update Email Preferences
                </Button>
              </div>
            </div>
          )}
        </BasicModal>
      </div>
    );
  }
}

class PromoDashboard extends React.Component {
  state = {
    promos: null,
    currentStatus: null,
    statuses: null,
  };

  componentDidMount() {
    adminGetPromosWithStatus({ status: 'NEEDS_APPROVAL' }).then(res =>
      this.setState({ promos: res.data }),
    );
  }

  render() {
    const { promos } = this.state;

    if (!promos) {
      return <Loading fullScreen />;
    }

    const columns = [
      {
        Header: 'Insertion Type',
        accessor: 'campaignType',
        Cell: row => (
          <div className="tc f5">
            {row.original.campaignType === 'dai' ? 'Dynamic' : 'Baked-in'}
          </div>
        ),
      },
      {
        Header: 'Promo Name',
        accessor: 'name',
      },
      {
        Header: 'Creator',
        accessor: 'creatorEmail',
      },
      {
        Header: 'Buyer Team',
        accessor: 'buyerTeam',
      },
      {
        Header: 'Seller Team',
        accessor: 'sellerTeam',
        width: 300,
        Cell: row => (
          <div
            className={
              row.original.podcastOwner && row.original.podcastOwner === row.original.sellerTeamSlug
                ? 'b green'
                : ''
            }
          >
            {row.original.sellerTeam ? (
              row.original.sellerTeam
            ) : (
              <i className="red">Needs publisher assigned</i>
            )}
          </div>
        ),
      },
      {
        Header: 'User Entered Seller Team',
        accessor: 'userInputPublisherName',
      },
      {
        Header: 'Promoted On',
        accessor: 'sellerPodcast',
        minWidth: 150,
        Cell: row => (
          <div>
            {row.original.sellerPodcast ? (
              <div className="flex items-center">
                <div className="mr2" style={{ width: '15%' }}>
                  <img
                    src={row.original.sellerPodcast.displayImageUrl}
                    className="center fl br2"
                    alt=""
                  />
                </div>
                <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
              </div>
            ) : (
              <div className="tc">-</div>
            )}
          </div>
        ),
      },
      {
        Header: 'Episode',
        accessor: 'sellerTeamEpisode',
        Cell: row =>
          row.original.episode ? <div>{row.original.episode}</div> : <div className="tc">-</div>,
      },
      {
        Header: 'Type',
        accessor: 'isInternal',
        maxWidth: 75,
        Cell: row => (
          <div className="tc f5">{row.original.isInternal ? 'Internal' : 'External'}</div>
        ),
      },
      {
        Header: 'Air Date',
        accessor: 'startedAt',
        id: 'startedAt',
        Cell: row => (
          <div className="tc">
            {row.original.startedAt ? moment(row.original.startedAt).format('MM/DD/YYYY') : null}
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'tc ttc',
        minWidth: 150,
        Cell: row => {
          const promo = row.original;
          if (promo.isArchived) {
            return <StatusIndicator forPromo status="ARCHIVED" text="archived" />;
          } else if (promo.isRequest) {
            return (
              <StatusIndicator
                forPromoRequest
                status={promo.statusCode}
                text={STATUSES[promo.statusCode].value}
                tooltip={STATUSES[promo.statusCode].tooltip}
              />
            );
          }
          return (
            <StatusIndicator
              forPromo
              status={promo.status.value}
              text={promo.status.value.toLowerCase()}
              tooltip={promo.status.description}
            />
          );
        },
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: row => (
          <div className="tc">
            {row.original.createdAt ? moment(row.original.createdAt).format('MM/DD/YYYY') : null}
          </div>
        ),
      },
    ];

    return (
      <div className="pa3 mw8">
        <BannerNotice
          className="mv2 w-100"
          rounded
          message="If Seller Team is green that means they are also the 'owner' of the podcast (they own the trackable prefix)"
        />
        <ReactTable
          defaultSorted={[
            {
              id: 'createdAt',
              desc: true,
            },
          ]}
          getTrProps={(state, rowInfo) => ({
            style: {
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              background: rowInfo.viewIndex % 2 ? '#F5FAFC' : '#FFFFFF',
            },
            onClick: () => {
              this.props.history.push(
                `/custom_admin/react/promos/${rowInfo.original.id}${
                  rowInfo.original.isRequest ? '/request' : ''
                }`,
              );
            },
          })}
          data={promos}
          showPagination={false}
          pageSize={promos.length}
          columns={columns}
          className="-striped -highlight w-100 f5"
          NoDataComponent={() => null}
        />
      </div>
    );
  }
}

function PromoRouter(props) {
  return (
    <Switch>
      <Route
        path="/custom_admin/react/promos/episode_filler"
        component={ScheduledPromoEpisodeFiller}
      />
      <Route path="/custom_admin/react/promos/approval_manager" component={EmailSenderView} />
      <Route
        path="/custom_admin/react/promos/:promoId/:promoRequest?"
        component={PromoDetailView}
      />
      <Route path="/custom_admin/react/promos" component={PromoDashboard} />
    </Switch>
  );
}

export default PromoRouter;
