"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rootStyle = void 0;
var _styledComponents = require("styled-components");
//
// Baseline style for most components
//

var rootStyle = function rootStyle() {
  return (0, _styledComponents.css)(["box-sizing:border-box;-webkit-tap-highlight-color:transparent;"]);
};
exports.rootStyle = rootStyle;