import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { teamSearch } from 'shared/api';

const TeamSearchInput = ({ onSelect }) => {
  const [teams, setTeams] = useState(null);

  const formatOptions = options => {
    // The current endpoint returns teams for a specific usecase (finding teams with lots of campaigns)
    // It's probably worth returning something more generic and passing options to determine how we want to sort
    // Reminder that this is facing admin only
    return options.map(o => ({
      label: `${o.name} - ${o.value}`,
      slug: o.slug,
      value: o.value,
      id: o.id,
    }));
  };

  const handleSearchTeams = (query, callback) => {
    if (query.length < 3) return;

    teamSearch({ term: query }).then(res => {
      callback(formatOptions(res.data));
    });
  };

  return (
    <div>
      <AsyncSelect
        cacheOptions
        loadOptions={handleSearchTeams}
        onChange={onSelect}
        placeholder="Search by team name"
        noOptionsMessage={() => 'No teams found'}
      />
    </div>
  );
};

TeamSearchInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
};

export default TeamSearchInput;
