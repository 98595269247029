import React, { Component } from 'react';
import Header from 'shared/Header';

const SellerProfilePrompt = ({ close, wizardUrl }) => {
  return (
    <div>
      <Header className="dark-blue mt3">Welcome to Chartable Connect</Header>
      <p>
        - a place for podcasters to connect with each other for promos, and with advertisers to sell
        ads. To get started, configure your profile.
      </p>
      <div className="flex justify-between">
        <div
          onClick={close}
          className="pv2 bn white bg-gray pointer w4 f5 br2 header-font mt3 tc relative"
        >
          Not Now
        </div>
        <div
          onClick={() => (window.location = wizardUrl)}
          className="pv2 bn white bg-blue pointer w4 f5 br2 header-font mt3 tc relative"
        >
          Start
        </div>
      </div>
    </div>
  );
};

export default SellerProfilePrompt;
