import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

function BannerNotice({
  message,
  type,
  error,
  success,
  action,
  onClose,
  actionText,
  onClick,
  className,
  rounded,
  slim,
}) {
  var style;
  if (error || type === 'error') {
    style = { color: '#9b1c1c', backgroundColor: '#fde8e8' };
  } else if (success || type === 'success') {
    style = { color: '#03543f', backgroundColor: '#def7ec' };
  } else {
    style = { color: '#6d5518', backgroundColor: '#fbeecd' };
  }

  const actionStyle = slim
    ? 'ml3 w-auto underline pointer dim'
    : 'ml3 w5 pv2 bg-white ba br2 flex items-center justify-center pointer dim';

  return (
    <div
      className={classnames(`w-100 relative ${onClick ? ' pointer dim' : ''}`, className)}
      onClick={onClick}
    >
      <div
        className={`pv3 ph2 items-center justify-center flex flex-wrap f5 ${rounded && 'br2'}`}
        style={style}
      >
        <span dangerouslySetInnerHTML={{ __html: message }} />
        {action && (
          <div
            onClick={action}
            style={{ borderColor: style.color, color: style.color }}
            className={actionStyle}
          >
            {actionText}
          </div>
        )}
      </div>
      {onClose && (
        <div className="pointer top-0 right-0 absolute" onClick={onClose}>
          <div style={{ color: style.color }} className="dim f5 dib pointer tr pt1 pr2">
            &times;
          </div>
        </div>
      )}
    </div>
  );
}

BannerNotice.propTypes = {
  message: PropTypes.string.isRequired,
  error: PropTypes.bool,
  success: PropTypes.bool,
  rounded: PropTypes.bool,
  actionText: PropTypes.string,
  type: PropTypes.string,
  action: PropTypes.func,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  className: PropTypes.string,
  slim: PropTypes.bool,
};

BannerNotice.defaultProps = {
  error: false,
  success: false,
  type: null,
  rounded: false,
};

export default BannerNotice;
