import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import Autosuggest from 'react-autosuggest';

import './autosuggest.css';

export default class PodcastDetails extends Component {
  constructor(props) {
    super(props);

    this.handleAutosuggest = this.handleAutosuggest.bind(this);
    this.handleClearSuggestions = this.handleClearSuggestions.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);

    this.state = {
      loading: false,
      suggestions: [],
      name: '',
      podcastId: props.podcastId || null,
    };
  }

  handleAutosuggest({ value }) {
    const params = {
      q: value.trim(),
    };

    axios.get('/search', { params }).then(res => {
      if (this.state.name.trim() == params.q) {
        this.setState({ suggestions: res.data.results });
      }
    });
  }

  handleClearSuggestions() {
    this.setState({ suggestions: [] });
  }

  handleSuggestionSelected(event, { suggestion }) {
    if (this.props.inputId) {
      this.setState({
        podcastId: suggestion.id,
      });
    } else {
      window.location = `/podcasts/${suggestion.slug}`;
    }

    this.setState({
      name: suggestion.title,
    });
  }

  renderSuggestion(suggestion) {
    const displayTitle =
      suggestion.title.length > 50 ? `${suggestion.title.slice(0, 50)}...` : suggestion.title;
    let { imageClass, titleClass } = this.props;

    imageClass = imageClass || 'w-20 pr2';
    titleClass = titleClass || 'w-80';

    return (
      <div className="relative pointer link pa2 flex flex-wrap items-center">
        <div className={imageClass}>
          {suggestion.display_image_url && (
            <img
              className="tc center"
              title={suggestion.title}
              src={suggestion.display_image_url}
            />
          )}
        </div>
        <div className={titleClass}>
          <div className="f6 tl" title={suggestion.title}>
            {displayTitle}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { suggestions, name, loading } = this.state;

    const { placeholder, autofocus, className } = this.props;

    return (
      <div>
        {this.props.inputId && (
          <input
            type="hidden"
            id={this.props.inputId}
            name={this.props.inputName}
            value={this.state.podcastId}
          />
        )}
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.handleAutosuggest}
          onSuggestionsClearRequested={this.handleClearSuggestions}
          onSuggestionSelected={this.handleSuggestionSelected}
          getSuggestionValue={suggestion => suggestion.trackName}
          shouldRenderSuggestions={value => value.trim().length > 1}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            className: className ? className : 'pa1 b--moon-gray ba br2 silver w-100 w5',
            placeholder: placeholder ? placeholder : 'Search...',
            value: name,
            onChange: e => this.setState({ name: e.target.value }),
            autoFocus: autofocus,
          }}
        />
      </div>
    );
  }
}

PodcastDetails.propTypes = {
  onSelectCallback: PropTypes.func,
  className: PropTypes.string,
};
