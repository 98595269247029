import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({
  startDate,
  endDate,
  maxDate,
  onChange,
  errorOnInvalidRange,
  isClearable,
  className,
}) => {
  if (errorOnInvalidRange && !!startDate && !!endDate) {
    if (startDate > endDate) {
      throw new Error('Start date is greater than end date');
    }
  }

  return (
    <div className="flex flex-row items-center">
      <div>
        <DatePicker
          className={className}
          selected={startDate}
          onChange={startDate => onChange(startDate, endDate)}
          isClearable={isClearable}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate || maxDate}
          placeholderText="Start date"
        />
      </div>
      <div className="mh2">→</div>
      <div>
        <DatePicker
          className={className}
          selected={endDate}
          onChange={endDate => onChange(startDate, endDate)}
          isClearable={isClearable}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={maxDate}
          placeholderText="End date"
        />
      </div>
    </div>
  );
};

DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  errorOnInvalidRange: PropTypes.bool,
  isClearable: true,
  className: '',
};

DateRangePicker.defaultProps = {
  errorOnInvalidRange: false,
};

export default DateRangePicker;
