import React, { useEffect, useState } from 'react';
import qr from 'qrcode';
import MFAInput from './MFAInput';
import PropTypes from 'prop-types';

const EnableMFA = ({ secretKey, qrUri, setMFA, handleSubmission, errors }) => {
  const [qrBase64, setQrBase64] = useState(null);

  const onMount = async () => {
    try {
      const data = await qr.toDataURL(qrUri);
      setQrBase64(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    onMount();
  }, []);

  const formatSecretKey = () => {
    let formatted = '';
    for (let i = 0; i < secretKey.length; i++) {
      if (!(i % 4)) {
        formatted += ' ';
      }
      formatted += secretKey[i];
    }
    return <div className="tc f5 b mt3 mb3">{formatted}</div>;
  };

  return (
    <div>
      <div>
        <ol>
          <li>Download a two-factor authentication app like Google Authenticator or Duo Mobile</li>
          <li>
            In the app, register your secret key by scanning the following QR code or by entering
            the string of characters below:
            <div className="tc mt3" style={{ marginLeft: '-40px' }}>
              <img src={qrBase64} />
              <br></br>
              {formatSecretKey()}
            </div>
          </li>
          <li>
            After setting up your key in the app, you will be provided with a unique passcode. Enter
            the passcode below to complete setup!
          </li>
        </ol>
      </div>
      <div className="tc mt3">
        <form onSubmit={handleSubmission}>
          <div className="dib" style={{ minWidth: '275px' }}>
            <MFAInput setMFA={setMFA} />
          </div>
          {errors()}
          <div className="mt3 f6">
            <input
              name="commit"
              type="submit"
              value="Submit"
              className="ph3 pv2 input-reset bn white bg-blue pointer f5 dib dim w-100 br2 header-font"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

EnableMFA.propTypes = {
  handleSubmission: PropTypes.func.isRequired,
  setMFA: PropTypes.func.isRequired,
  qrUri: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
};

export default EnableMFA;
