import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import moment from 'moment';
import {
  denormalizePodcast,
  getPodcasts,
  getPodcastsNormalizations,
  normalizePodcast,
} from 'shared/api';
import Loading from 'shared/Loading';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import TeamSearchInput from 'shared/TeamSearchInput';

import 'react-table/react-table.css';

const NormalizationForm = ({ id, onSubmit }) => {
  const [url, setUrl] = useState(''); // The Spotify URL of the podcast to normalize.

  return (
    <form>
      <input
        type="text"
        name="url"
        onChange={e => setUrl(e.target.value)}
        value={url}
        placeholder="Spotify URL"
        className="mr2 pa2 input-reset ba bg-white br2 b--gray"
      />
      <a
        onClick={() => onSubmit(id, url)}
        className="no-underline br2 pa2 bg-blue white pointer dim w3 tc"
      >
        Go
      </a>
    </form>
  );
};

export default function SpotifyDashboard() {
  const [loading, setLoading] = useState(false);
  const [normalizing, setNormalizing] = useState(null); // The ID of the podcast that is currently being normalized.
  const [podcasts, setPodcasts] = useState([]);
  const [teamSelection, setTeamSelection] = useState({});
  const [loadPodcastSwitcher, setLoadPodcastSwitcher] = useState(false); // Show this if more than 50 podcasts exist
  const [numPodcasts, setNumPodcasts] = useState(0);

  const handleNormalizePodcast = (podcastId, url) => {
    normalizePodcast({
      podcastId: podcastId,
      teamId: teamSelection.slug,
      url: url,
      provider: 'spotify',
      reason: 'manual_match',
    }).then(res => {
      const newPodcasts = podcasts;
      const podcast = newPodcasts.find(pod => pod.cid === podcastId);
      podcast.normalization = res.data;
      setPodcasts(newPodcasts);
      setNormalizing(null);
    });

    return false;
  };

  const handleDenormalizePodcast = podcastId => {
    if (confirm('Are you sure?')) {
      denormalizePodcast({
        podcastId: podcastId,
        teamId: teamSelection.slug,
        provider: 'spotify',
      }).then(res => {
        const _podcasts = podcasts.map(pod => {
          return {
            ...pod,
            normalization: pod.id === podcastId ? null : pod.normalization,
          };
        });
        setPodcasts(_podcasts);
      });
    }

    return false;
  };

  // The team selection needs to be passed via params because we cannot guarantee the state has been set yet.
  const handleGetPodcastNormalizations = (results, selection) => {
    getPodcastsNormalizations({
      podcastIds: results.map(p => p.cid),
      provider: 'spotify',
      teamId: selection.slug,
    }).then(res => {
      res.data.forEach(data => {
        const podcast = results.find(pod => pod.slug === data.podcastId);
        podcast.normalization = data.normalization;
      });

      setPodcasts(results);
      setNumPodcasts(results.length);
      setLoading(false);
    });
  };

  const fetchTeamPodcasts = selection => {
    setTeamSelection(selection);
    setLoading(true);

    getPodcasts({ teamId: selection.slug }).then(res => {
      const results = res.data;
      if (results.length > 0 && results.length <= 50) {
        setLoadPodcastSwitcher(false);
        handleGetPodcastNormalizations(results, selection);
      } else if (results.length > 50) {
        setLoading(false);
        setLoadPodcastSwitcher(true);
        setNumPodcasts(results.length);
      } else {
        alert('This team does not have any podcasts');
        setPodcasts([]);
        setLoading(false);
      }
    });
  };

  const reasonToString = reason => {
    switch (reason) {
      case 'feed_match':
        return 'Feed Match';
      case 'title_match':
        return 'Title Match';
      case 'title_description_match':
        return 'Title and Description Match';
      case 'title_image_match':
        return 'Title and Image Match';
      case 'itunes_id_match':
        return 'iTunes ID Match';
      case 'group_match':
        return 'Group Match';
      case 'manual_match':
        return 'Manual Match';
      default:
        return 'Unknown Reason';
    }
  };

  const renderNormalizationForm = podcastId => {
    setNormalizing(podcastId);
  };

  const renderPodcastSwitcher = () => {
    if (loadPodcastSwitcher) {
      return (
        <>
          <p>
            {teamSelection.label.split(' - ')[0]} has too many podcasts to display in one table.
            Please search for a podcast to normalize.
          </p>
          <PodcastSwitcher
            teamId={teamSelection.slug}
            onSelect={selection =>
              handleGetPodcastNormalizations(Array(selection.data), teamSelection)
            }
          />
        </>
      );
    }
  };

  const columns = [
    {
      Header: '',
      sortable: false,
      width: 40,
      Cell: row => (
        <img src={row.original.imageUrl} className="center fl br2" alt={row.original.title} />
      ),
    },
    {
      Header: 'Podcast',
      Cell: row => <>{row.original.title}</>,
    },
    {
      Header: 'Normalize',
      sortable: false,
      Cell: row => {
        if (row.original.normalization) {
          // Podcast has already been normalized.
          return (
            <>
              {reasonToString(row.original.normalization.reason)} on{' '}
              {moment(row.original.normalization.createdAt).format('MM/DD/YYYY')}
            </>
          );
        } else if (normalizing === row.original.cid) {
          // Podcast is currently being normalized.
          return <NormalizationForm id={row.original.cid} onSubmit={handleNormalizePodcast} />;
        }
        // Podcast is not normalized
        return (
          <div style={{ textAlign: 'center' }}>
            <a
              onClick={() => renderNormalizationForm(row.original.cid)}
              className="no-underline br2 pa2 bg-blue white pointer dim w3 tc"
            >
              Normalize
            </a>
          </div>
        );
      },
    },
    {
      Header: 'Denormalize',
      sortable: false,
      style: { textAlign: 'center' },
      Cell: row => {
        if (row.original.normalization) {
          // Podcast is normalized.
          return (
            <a
              onClick={() => handleDenormalizePodcast(row.original.id)}
              className="no-underline br2 pa2 bg-blue white pointer dim w3 tc"
            >
              Denormalize
            </a>
          );
        }
        // Podcast is not normalized.
        return <></>;
      },
    },
  ];

  let content;

  if (loading) {
    content = <Loading fullScreen />;
  } else if (podcasts.length > 0) {
    content = (
      <div className="pa3 mt3 w-100">
        {renderPodcastSwitcher()}
        <ReactTable
          getTrProps={(state, rowInfo) => ({
            style: {
              display: 'flex',
              alignItems: 'center',
            },
          })}
          defaultSorted={[
            {
              id: 'podcastId',
              desc: true,
            },
          ]}
          data={podcasts}
          showPagination={false}
          pageSize={podcasts.length}
          columns={columns}
          className="-striped w-100 f5"
          NoDataComponent={() => null}
          resizable={false}
        />
      </div>
    );
  } else if (loadPodcastSwitcher) {
    content = renderPodcastSwitcher();
  }

  return (
    <div className="bg-white pt4 ph4">
      <h1>Spotify Dashboard</h1>
      <h2>Search By Team Name</h2>
      <TeamSearchInput
        onSelect={selection => fetchTeamPodcasts(selection)}
        includeAll={false}
        defaultToAll={false}
      />
      {content}
    </div>
  );
}
