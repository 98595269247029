import React from 'react';
import PropTypes from 'prop-types';

function BoxOption({ label, value, selected, onClick, first, disabled }) {
  const selectedClassName = disabled
    ? 'bg-moon-gray b--moon-gray white'
    : 'white bg-blue b--blue pointer';
  const unselectedClassName = disabled
    ? 'mid-gray b--moon-gray bg-white'
    : 'mid-gray b--moon-gray bg-white pointer';

  return (
    <div
      className={`${first ? 'bl' : ''} bw1 bb bt br ph2 pv2 f5 tc dib w4 ${
        selected ? selectedClassName : unselectedClassName
      }`}
      onClick={onClick}
    >
      {label}
    </div>
  );
}

function BoxOptionSelect({ options, selectedOption, onSelectOption, disabled }) {
  return (
    <div className={disabled ? 'o-80' : ''}>
      {options.map((option, index) => (
        <BoxOption
          key={index}
          value={option.value}
          label={option.label}
          selected={!!selectedOption && option.value === selectedOption.value}
          first={index === 0}
          onClick={() => {
            if (!disabled) {
              onSelectOption(option);
            }
          }}
          disabled={disabled}
        />
      ))}
    </div>
  );
}

BoxOptionSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onSelectOption: PropTypes.func.isRequired,
  selectedOption: PropTypes.object,
  disabled: PropTypes.bool,
};

BoxOptionSelect.defaultProps = {
  selectedOption: null,
  disabled: false,
};

export default BoxOptionSelect;
