import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SmartFeedsForm from './SmartFeedsForm';
import { updateSmartFeed } from 'shared/api';
import { deleteSmartFeed } from 'shared/api/v1';
import Loading from 'shared/Loading';
import ConfirmationModal from 'shared/ConfirmationModal';

const toastOptions = {
  duration: 10000,
  id: 'SMART_FEEDS_EDIT',
};

const aboutUrl = 'https://help.chartable.com/category/223-smartfeeds';

const SmartFeedsEditView = ({ teamId, podcasts, feeds, match, toast, updateFeed }) => {
  const { feedId } = match.params;
  const feed = feeds.find(feed => feed.id == feedId);
  const [loading, setLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  const handleSubmit = async formData => {
    setLoading(true);
    const payload = { teamId, ...formData, feedId };
    try {
      const res = await updateSmartFeed(payload);
      toast.success('Your feed has been updated.', toastOptions);
      const { feed: newFeed } = res.data;
      updateFeed();
    } catch (e) {
      const message = e.response && e.response.data.error ? e.response.data.error : e;
      toast.error(message, toastOptions);
    } finally {
      setLoading(false);
    }
  };

  const renderBack = () => {
    return (
      <div className="bb b--light-gray w-100 bg-white pv2 ph4 items-center f5">
        <div className="flex items-center justify-between" style={{ height: '1.5rem' }}>
          <Link to={`/smart_feeds/${teamId}`} className="link blue pointer">
            « Back
          </Link>
        </div>
      </div>
    );
  };

  const renderSuccess = () => {
    return (
      <div>
        <div className={`pa3 bg-washed-blue br2 mb3`}>
          <div className={`f4 dark-blue mb3`}>Your SmartFeed is live!</div>
          <p className={`lh-copy`}>
            You can now use this URL for your podcast in Apple Podcasts Connect and anywhere else
            you need to register your feed. Learn more from these{' '}
            <a className={`link blue`} href={aboutUrl} target={`_blank`}>
              helpful articles
            </a>
            .
          </p>
          <pre className={`pre`}>{feed.url}</pre>
        </div>
      </div>
    );
  };

  const handleDeleteSmartfeed = async () => {
    const res = await deleteSmartFeed(feedId, teamId);
    if (!res.data.error) {
      window.location = `/smart_feeds/${teamId}`;
    } else {
      setConfirmingDelete(false);
      setIsDeleting(false);
      toast.error('Error deleting SmartFeed. Please contact support.');
    }
  };

  const renderDeleteModal = () => {
    return (
      <ConfirmationModal
        title="Confirm SmartFeed Deletion"
        message={
          isDeleting
            ? 'Deleting SmartFeed, please do not close the browser or navigate away from this page.'
            : `Are you sure you want to remove this SmartFeed? This will permanently redirect ${feed.url} to ${feed.sourceUrl}.`
        }
        onClose={() => setConfirmingDelete(false)}
        isOpen={true}
        onConfirm={handleDeleteSmartfeed}
      />
    );
  };

  const renderForm = () => {
    if (!feed) {
      return (
        <div>
          <h2>The SmartFeed you are trying to edit does not exist.</h2>
          <div className={`mt4`}>
            <Link
              to={`/smart_feeds/${teamId}/new`}
              className="link no-underline bg-green br2 ph3 pv2 white"
            >
              Create a new SmartFeed
            </Link>
          </div>
        </div>
      );
    }
    return (
      <div>
        {loading && <Loading />}
        <div style={{ visibility: loading ? 'hidden' : 'visible' }}>
          {renderSuccess()}
          <div className={`f3 dark-blue mb3`}>Edit your SmartFeed</div>
          <SmartFeedsForm
            teamId={teamId}
            podcasts={podcasts}
            selectedPodcast={feed.podcast}
            isEditing={true}
            feed={feed}
            feeds={feeds}
            onSubmit={handleSubmit}
          />
          <div
            onClick={() => setConfirmingDelete(true)}
            className={`pa2 bg-red white br2 bn dim mw6 tc mt2`}
          >
            Delete this SmartFeed
          </div>
        </div>
      </div>
    );
  };
  return (
    <div>
      {renderBack()}
      {confirmingDelete && renderDeleteModal()}
      <div className={`mw8 pa3`}>{renderForm()}</div>
    </div>
  );
};

export default SmartFeedsEditView;
