import React, { useEffect, useState } from 'react';
import checked from 'images/icons/checked.png';
import hourglass from 'images/icons/hourglass.png';
import lockedIcon from 'images/icons/locked.png';
import unchecked from 'images/icons/unchecked.png';
import PropTypes from 'prop-types';

import styles from './HouseholdDemos.module.css';

const Locked = ({ reqs }) => {
  const icon = lockedIcon;

  return (
    <div className={styles.lockedBox}>
      <div style={{ textAlign: 'center' }}>
        <img src={icon} className={styles.padlock} />
      </div>
      <div>
        {reqs.map(({ checked: isChecked, onClick, name }) => {
          return (
            <div key={name} className={styles.req}>
              <img className={styles.lockIcon} src={isChecked ? checked : unchecked} />
              <span className={`${styles.lockBullet} ${styles.upgradeLink}`} onClick={onClick}>
                {name}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

Locked.propTypes = {
  reqs: PropTypes.array.isRequired,
};

export default Locked;
