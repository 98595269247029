import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getSmartFeeds } from 'shared/api';
import ReactTable from 'react-table';

const aboutUrl = 'https://help.chartable.com/article/198-what-is-the-smartfeeds-integration';

const SmartFeedsListView = ({ feeds, teamId }) => {
  const renderBack = () => {
    return (
      <div className="bb b--light-gray w-100 bg-white pv2 ph4 items-center f5">
        <div className="flex items-center justify-between" style={{ height: '1.5rem' }}>
          <a href={`/teams/${teamId}/dashboard/integrations`} className="link blue pointer">
            « Back to Integrations
          </a>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (!(feeds && feeds.length)) {
      return null;
    }
    const columns = [
      {
        Header: 'Podcast',
        accessor: 'podcast.title',
        maxWidth: 200,
      },
      {
        Header: 'SmartFeeds URL',
        accessor: 'url',
        Cell: row => {
          return (
            <a className={`link blue`} href={row.original.url}>
              {row.original.url}
            </a>
          );
        },
      },
      {
        Header: 'Original URL',
        accessor: 'sourceUrl',
        Cell: row => {
          return (
            <a className={`link blue`} href={row.original.sourceUrl}>
              {row.original.sourceUrl}
            </a>
          );
        },
      },
      {
        Header: '',
        maxWidth: 70,
        Cell: row => {
          return (
            <Link to={`/smart_feeds/${teamId}/edit/${row.original.id}`} className="link blue">
              Edit
            </Link>
          );
        },
      },
    ];
    return (
      <div>
        <ReactTable
          getTrProps={() => ({
            style: {
              display: 'flex',
              alignItems: 'center',
              height: '40px',
            },
          })}
          data={feeds}
          columns={columns}
          pageSize={Math.min(20, feeds.length)}
          showPagination={feeds.length > 20}
          className="-striped -highlight w-100 f5 pointer"
        />
      </div>
    );
  };

  const reload = () => {
    window.location.reload();
  };

  return (
    <div>
      {renderBack()}
      <div className={`mw8 pa3`}>
        <div className={`f4 dark-blue mb3`}>
          Your SmartFeeds{' '}
          <a className={`italic f7 link blue dim`} target={`_blank`} href={aboutUrl}>
            What is this?
          </a>
        </div>
        {!(feeds && feeds.length) && <span>You have not created any SmartFeeds.</span>}
        {feeds && !!feeds.length && renderTable()}
        <div className={`mt4`}>
          <Link
            to={`/smart_feeds/${teamId}/new`}
            className="link no-underline bg-green br2 ph3 pv2 white"
          >
            Create a new SmartFeed
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SmartFeedsListView;
