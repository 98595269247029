import React from 'react';

const BuyerForm = ({ profile }) => {
  return (
    <div>
      <div className="mb3">
        <label className="mb1 f5" htmlFor="companyName">
          Company Name
        </label>
        <input
          defaultValue={profile.companyName}
          className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
          type="text"
          name="companyName"
        />
      </div>
      <div className="mb3">
        <label className="mb1 f5" htmlFor="websiteUrl">
          Website
        </label>
        <input
          defaultValue={profile.websiteUrl}
          className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
          placeholder="https://"
          type="text"
          name="websiteUrl"
        />
      </div>
    </div>
  );
};

export default BuyerForm;
