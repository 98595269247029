import React from 'react';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import PieChart from 'shared/charts/PieChart';
import Loading from 'shared/Loading';

import DemographicsChartHeader from './DemographicsChartHeader';

const PlayersView = ({ data, loading, error }) => {
  let content;

  if (loading) {
    content = <Loading />;
  } else if (error) {
    content = <Banner error>Could not load player data</Banner>;
  } else if (!data) {
    content = <Banner noImage>Not enough player data. Check back later!</Banner>;
  } else {
    content = (
      <div className="w-100 flex flex-wrap">
        <div className="w-100-ns w-100 pr2-ns">
          <PieChart data={{ trackable: data }} showOptions={false} />
        </div>
      </div>
    );
  }

  return (
    <div className="mw8">
      <div className="b f4 force-inter dark-blue mt4 mb2">Players</div>
      {content}
    </div>
  );
};

PlayersView.propTypes = {
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  data: PropTypes.array,
};

PlayersView.defaultProps = {
  data: null,
};

export default PlayersView;
