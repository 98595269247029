import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'shared/Button';
import BasicModal from 'shared/BasicModal';
import Loading from 'shared/Loading';
import FormError from 'shared/FormError';
import { getTeam, updateTeam } from 'shared/api';

export default class EditDivisionForm extends Component {
  constructor(props) {
    super(props);

    this.handleLoadDivision = this.handleLoadDivision.bind(this);
    this.handleUpdateDivision = this.handleUpdateDivision.bind(this);

    this.state = {
      name: '',
      errors: {},
      loading: false,
    };
  }

  handleLoadDivision() {
    const { divisionId, teamSlug } = this.props;

    getTeam({
      teamId: teamSlug,
      id: divisionId,
    }).then(res => {
      this.setState({
        division: res.data,
        name: res.data.name,
      });
    });
  }

  handleUpdateDivision() {
    const { divisionId, teamSlug, onCreateNotification, onCloseForm } = this.props;
    const { name } = this.state;

    this.setState({ loading: true });

    updateTeam({
      teamId: teamSlug,
      id: divisionId,
      name,
    }).then(res => {
      if (res.data.errors) {
        this.setState({
          loading: false,
          errors: res.data.errors,
        });
      } else {
        onCreateNotification(`${res.data.name} updated.`);
        onCloseForm();
      }
    });
  }

  render() {
    const { name, errors, loading, division } = this.state;

    if (!division) {
      this.handleLoadDivision();
      return <Loading />;
    }

    const { onCloseForm } = this.props;

    const disabled = name.length < 3;

    return (
      <div>
        <BasicModal isOpen onRequestClose={() => onCloseForm()}>
          <div className="f4 mb4 tc b">Update {division.name}</div>
          <div className="mb4">
            <div className="f5 header-font mb2">Name</div>
            <input
              type="text"
              className="pa2 input-reset ba w-100 bg-white br2 b--silver string optional"
              value={name}
              onChange={e => this.setState({ name: e.target.value })}
            />
            <FormError error={errors.name} />
          </div>
          <Button
            className="w-100"
            type="primary"
            disabled={disabled}
            onClick={() => this.handleUpdateDivision()}
            loading={loading}
          >
            Update
          </Button>
        </BasicModal>
      </div>
    );
  }
}

EditDivisionForm.propTypes = {
  teamSlug: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  onCloseForm: PropTypes.func.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
};
