import React from 'react';
import report from 'images/report.svg';

import styles from '../styles.module.css';
import OnboardingButton from './OnboardingButton';

const Welcome = ({ teamName, nextStep }) => {
  return (
    <div data-testid="welcome-container">
      <div className={styles.welcomeBody}>
        <div className={styles.welcomeSteps}>
          <h1>Welcome, {teamName}!</h1>
          <p>Here are the steps we’ll take you through to get started:</p>
          <ol>
            <li>Add your podcast catalogue to Chartable to set up your podcast directory</li>
            <li>
              Set up Trackable, our tracking prefix, to get advanced analytics and attribution with
              SmartLinks
            </li>
            <li>
              Start tracking your podcasts by viewing chart ranks and reviews on Apple Podcasts,
              Spotify, and more
            </li>
          </ol>
        </div>
        <div className={styles.welcomeImage}>
          <img src={report} alt="Report" />
        </div>
      </div>
      <OnboardingButton nextStep={nextStep}>Get started</OnboardingButton>
    </div>
  );
};

export default Welcome;
