import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getTeamUser, updateTeamUser } from 'shared/api';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import FormCloseBox from 'shared/FormCloseBox';
import FormDivisionSelector from 'shared/FormDivisionSelector';
import FormInputCheckbox from 'shared/FormInputCheckbox';
import FormPodcast from 'shared/FormPodcast';
import FormPodcastSearchInput from 'shared/FormPodcastSearchInput';
import FormSelect from 'shared/FormSelect';
import FormTitle from 'shared/FormTitle';
import Loading from 'shared/Loading';

const ROLES = [
  { value: 'owner', label: 'Owner' },
  { value: 'admin', label: 'Admin' },
  { value: 'member', label: 'Member' },
  { value: 'viewer', label: 'Viewer' },
];

function isEqual(a, b) {
  if (Array.isArray(a)) {
    return a.length === b.length && [...a].every(value => b.includes(value));
  }
  return a === b;
}

export default class EditTeamUserForm extends Component {
  constructor(props) {
    super(props);

    this.handleLoadTeamUser = this.handleLoadTeamUser.bind(this);
    this.handleUpdateTeamUser = this.handleUpdateTeamUser.bind(this);
    this.handleAddTeamPodcast = this.handleAddTeamPodcast.bind(this);
    this.handleRemoveTeamPodcast = this.handleRemoveTeamPodcast.bind(this);
    this.handleAddTeamUser = this.handleAddTeamUser.bind(this);
    this.handleRemoveTeamUser = this.handleRemoveTeamUser.bind(this);
    this.toggleAllDivisions = this.toggleAllDivisions.bind(this);

    this.state = {
      name: '',
      errors: {},
      loading: false,
      user: null,
      isAllDivisionsUser: null,
      selectedDivision: null,
    };
  }

  handleLoadTeamUser() {
    const { userId, teamId } = this.props;

    getTeamUser({
      teamId,
      id: userId,
    }).then(res => {
      this.setState({
        user: res.data,
        newUser: JSON.parse(JSON.stringify(res.data)),
        isAllDivisionsUser: res.data.teamUser !== null,
      });
    });
  }

  handleUpdateTeamUser() {
    const { userId, teamId, onCreateNotification, onCloseForm } = this.props;
    const { newUser } = this.state;

    if (newUser.teamUser === null && newUser.teamUsers.length === 0) {
      // eslint-disable-next-line no-alert
      alert('Add a division to this user to save.');
      return;
    }

    if (newUser.teamUser && newUser.teamUser.role === null) {
      // eslint-disable-next-line no-alert
      alert('Select a role for this user to save.');
      return;
    }

    this.setState({ loading: true });

    updateTeamUser({
      teamId,
      id: userId,
      ...this.formatParameters(newUser),
    }).then(res => {
      if (res.data.errors) {
        this.setState({
          loading: false,
          errors: res.data.errors,
        });
      } else {
        onCreateNotification(`${res.data.email} updated.`);
        onCloseForm();
      }
    });
  }

  handleAddTeamPodcast(teamPodcast) {
    // this only works when there is a teamUser entry on the user
    const { newUser } = this.state;

    if (
      newUser.teamUser.allowedTeamPodcasts.filter(
        tp => tp.teamPodcastId === teamPodcast.teamPodcastId,
      ).length === 0
    ) {
      newUser.teamUser.allowedTeamPodcasts.push(teamPodcast);
    }

    this.setState({ newUser });
  }

  handleRemoveTeamPodcast(teamPodcast) {
    const { newUser } = this.state;

    const { allowedTeamPodcasts } = newUser.teamUser;

    newUser.teamUser.allowedTeamPodcasts = allowedTeamPodcasts.filter(
      tp => tp.teamPodcastId !== teamPodcast.teamPodcastId,
    );

    this.setState({ newUser });
  }

  handleAddTeamUser(division, role) {
    const { newUser } = this.state;

    if (newUser.teamUsers.filter(tu => tu.teamId === division.id).length === 0) {
      newUser.teamUsers.push({ teamId: division.id, teamName: division.name, role: role });
    }

    this.setState({ newUser, selectedDivision: null });
  }

  handleRemoveTeamUser(divisionId) {
    const { newUser } = this.state;

    newUser.teamUsers = newUser.teamUsers.filter(tu => tu.teamId !== divisionId);

    this.setState({ newUser });
  }

  updateNewTeamUser(newParams) {
    const { newUser } = this.state;
    let { teamUser } = newUser;

    teamUser = { ...teamUser, ...newParams };
    newUser.teamUser = teamUser;

    this.setState({ newUser });
  }

  toggleAllDivisions() {
    const { isAllDivisionsUser } = this.state;
    const { newUser } = this.state;

    newUser.teamUsers = [];

    if (isAllDivisionsUser) {
      newUser.teamUser = null;
    } else {
      newUser.teamUser = {
        role: null,
        isRestrictedPodcastUser: false,
        allowedTeamPodcasts: [],
      };
    }

    this.setState({
      isAllDivisionsUser: !isAllDivisionsUser,
      newUser,
    });
  }

  formatParameters(user) {
    const { teamUser, teamUsers } = user;

    if (teamUser) {
      return {
        role: teamUser.role,
        isRestrictedPodcastUser: teamUser.isRestrictedPodcastUser,
        allowedTeamPodcastIds: teamUser.allowedTeamPodcasts.map(tp => tp.teamPodcastId),
      };
    }
    return {
      teamUsers: teamUsers.map(tu => ({ teamId: tu.teamId, role: tu.role })),
    };
  }

  render() {
    const { loading, user, newUser, isAllDivisionsUser, selectedDivision } = this.state;

    if (!user) {
      this.handleLoadTeamUser();
      return <Loading />;
    }

    const { onCloseForm, hasRestrictedUsers, teamId, allowedRoles, hasDivisions, teamHashedId } =
      this.props;

    const userParameters = this.formatParameters(user);
    const newUserParameters = this.formatParameters(newUser);
    const disabled = Object.keys(userParameters).every(key =>
      isEqual(newUserParameters[key], userParameters[key]),
    );
    const allowedRoleOptions = ROLES.filter(r => allowedRoles.includes(r.value));
    const newTeamUser = newUser.teamUser;
    const newTeamUsers = newUser.teamUsers;

    return (
      <div>
        <BasicModal isOpen onRequestClose={() => onCloseForm()}>
          <div className="f4 mb4 tc b">Update {user.email}</div>
          {hasDivisions && (
            <FormInputCheckbox
              value={isAllDivisionsUser}
              onChange={this.toggleAllDivisions}
              title="Access"
              name="All Divisions"
            />
          )}
          {newTeamUser ? (
            <div>
              <FormSelect
                title="Role"
                value={newTeamUser.role}
                options={allowedRoleOptions}
                onChange={value => this.updateNewTeamUser({ role: value })}
                tooltip="Admins cannot manage billing, Members cannot manage other users."
              />
              {hasRestrictedUsers && (
                <div>
                  <FormInputCheckbox
                    value={!newTeamUser.isRestrictedPodcastUser}
                    onChange={() =>
                      this.updateNewTeamUser({
                        isRestrictedPodcastUser: !newTeamUser.isRestrictedPodcastUser,
                      })
                    }
                    title="Access"
                    name="All Podcasts"
                  />
                  {newTeamUser.isRestrictedPodcastUser && (
                    <div>
                      <div className="mb3">
                        <FormTitle title="Allowed Podcasts" />
                        {newTeamUser.allowedTeamPodcasts.map(teamPodcast => (
                          <FormPodcast
                            podcast={teamPodcast}
                            onUnselectPodcast={this.handleRemoveTeamPodcast}
                            key={teamPodcast.id}
                          />
                        ))}
                      </div>
                      <div className="mb4">
                        <FormPodcastSearchInput
                          title="Add an allowed podcast"
                          teamId={teamId}
                          onSelectPodcast={this.handleAddTeamPodcast}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : (
            <div>
              <FormTitle title="Allowed Divisions" />
              {newTeamUsers.map(teamUser => (
                <div className="mb4">
                  <FormCloseBox onClose={() => this.handleRemoveTeamUser(teamUser.teamId)}>
                    <div className="pa3 f4 fw4 dt w-100">
                      <div className="w-50-ns w-100 dtc">{teamUser.teamName}</div>
                      <div className="w-50-ns w-100 dtc">
                        Role:{' '}
                        {teamUser.role.charAt(0).toUpperCase() +
                          teamUser.role.slice(1).toLowerCase()}
                      </div>
                    </div>
                  </FormCloseBox>
                </div>
              ))}
              {selectedDivision ? (
                <div className="mb4">
                  <FormCloseBox onClose={() => this.setState({ selectedDivision: null })}>
                    <div className="tc f4 fw4 pa3">{selectedDivision.name}</div>
                  </FormCloseBox>
                  <FormSelect
                    options={allowedRoleOptions}
                    onChange={value => this.handleAddTeamUser(selectedDivision, value)}
                    tooltip="Admins cannot manage billing, Members cannot manage other users."
                    placeholder="Select a role to add the division..."
                    className="mb0"
                  />
                </div>
              ) : (
                <FormDivisionSelector
                  title="Add a division"
                  teamId={teamHashedId}
                  teamSlug={teamId}
                  onSelectDivision={division => this.setState({ selectedDivision: division })}
                />
              )}
            </div>
          )}
          <Button
            className="w-100"
            type="primary"
            disabled={disabled}
            onClick={() => this.handleUpdateTeamUser()}
            loading={loading}
          >
            Update
          </Button>
        </BasicModal>
      </div>
    );
  }
}

EditTeamUserForm.propTypes = {
  teamId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  onCloseForm: PropTypes.func.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
  hasRestrictedUsers: PropTypes.bool.isRequired,
  allowedRoles: PropTypes.array.isRequired,
  teamHashedId: PropTypes.string.isRequired,
};
