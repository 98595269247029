import React from 'react';
import ReactTooltip from 'react-tooltip';
import lock from 'images/icons/locked.png';
import { abbreviateNumberTwo } from 'shared/helpers';

import DemoCard from './DemoCard';
import DemoViz from './DemoViz';

function truncateDescription(description) {
  if (description.length > 300) {
    let truncateIndex = 290;
    while (truncateIndex < description.length) {
      if (description[truncateIndex] === ' ') {
        return `${description.substr(0, truncateIndex)}...`;
      }
      truncateIndex += 1;
    }
  }
  return description;
}

function getStarFill(index, rating_average) {
  if (rating_average >= index) {
    return 'full';
  }
  if (rating_average >= index - 0.5) {
    return 'half';
  }
  return 'empty';
}

const htmlStripRegEx = /(<([^>]+)>)/gi;

const ResultItem = ({
  podcast,
  handleContact,
  isConnecting,
  setRelatedPodcast,
  sent,
  isPro,
  setUpsellModalIsOpen,
}) => {
  const {
    title,
    slug,
    description,
    display_image_url,
    rating_count,
    rating_average,
    genres,
    publisher,
    id,
    has_seller_profile,
  } = podcast;

  let strippedDescription = document.createElement('textarea');
  strippedDescription.innerHTML = description ? description.replaceAll(htmlStripRegEx, '') : '';
  strippedDescription = strippedDescription.value;

  const getDefaultSrc = e => {
    e.target.src = `https://via.placeholder.com/90x90.png?text=${publisher}`;
  };

  const handleRequest = podcast => {
    if (isPro) {
      handleContact(podcast);
    } else {
      setUpsellModalIsOpen(true);
    }
  };

  const ConnectButton = () => {
    const disabled = !isPro && !sent;
    return (
      <div className="v-base tc white relative fr connect-button w4">
        {disabled && <ReactTooltip id={`${podcast.id}`} />}
        <div
          data-for={`${podcast.id}`}
          data-tip="Upgrade to Pro to request an intro!"
          style={{ cursor: sent ? 'not-allowed' : 'pointer' }}
          onClick={() => handleRequest(podcast)}
          className={`ba ${sent || 'dim'} pa1 ${
            sent || disabled ? 'bg-light-gray' : 'bg-blue'
          } b--light-gray br2 mt2`}
        >
          <div style={{ top: 0, left: 0 }} className="di fw5 header-font relative">
            {sent ? 'Pending' : 'Request Intro'}
          </div>
        </div>
      </div>
    );
  };

  const Verified = () => {
    if (!has_seller_profile) {
      return null;
    }
    return (
      <div style={{ display: 'inline' }}>
        <img
          style={{
            width: '26px',
            marginRight: '6px',
            position: 'relative',
            top: '5px',
          }}
          src="https://cdn3.iconfinder.com/data/icons/essentials-volume-i/128/verified-blue-512.png"
        />
      </div>
    );
  };

  const smartRound = num => {
    let place;
    switch (true) {
      case num > 1000000:
        place = 50000;
        break;
      case num > 200000:
        place = 10000;
        break;
      case num > 100000:
        place = 5000;
        break;
      case num > 20000:
        place = 1000;
        break;
      default:
        place = 100;
        break;
    }
    return abbreviateNumberTwo(Math.round(num / place) * place);
  };

  return (
    <div className="mb3 bb b--light-gray card pa3">
      <div className="dt w-100">
        <div className="dtc v-top w4">
          <a target="_blank" href={`/podcasts/${slug}`}>
            <img
              className="br2 w-auto h4 ba b--near-white"
              src={display_image_url}
              onError={getDefaultSrc}
            />
          </a>
          <ConnectButton />
        </div>
        <div className="dtc v-top pl3">
          <div className="f3 w-100">
            <div className="dt w-100">
              <div className="dtc tl w-60 v-mid">
                <a className="link dark-blue" target="_blank" href={`/podcasts/${slug}`}>
                  <Verified />
                  {title}
                </a>
              </div>
              <div className="dtc tr w-40 v-mid">
                {rating_average ? (
                  <div className="stars f4">
                    <span className="stars f7 mr2 relative" style={{ top: '-3px' }}>
                      {Math.round(rating_average * 10) / 10}
                    </span>
                    {[1, 2, 3, 4, 5].map(index => {
                      return (
                        <div
                          key={index}
                          className={`dib icon-star ${getStarFill(index, rating_average)}`}
                        >
                          ☆
                        </div>
                      );
                    })}
                    <div className="gray f6">{`${rating_count} ratings`}</div>
                  </div>
                ) : (
                  <div className="gray f6">Rating unavailable</div>
                )}
              </div>
            </div>
          </div>
          <div className="mt1 f5 lh-copy gray">
            <div className="mb2 flex justify-start">
              <ReactTooltip id="estimated" />
              {!!podcast.estimated_min_thirty_day_downloads &&
                !!podcast.estimated_max_thirty_day_downloads && (
                  <DemoCard>
                    {!isPro ? (
                      <div
                        data-for="lock"
                        data-tip="Upgrade to Pro for advanced signals!"
                        className="tc"
                      >
                        <img style={{ width: '33px', opacity: '.5' }} src={lock} />
                      </div>
                    ) : (
                      <span
                        data-for="estimated"
                        data-tip="Estimates are calculated from public data using our proprietary methodology."
                        className="tc db dark-blue"
                        style={{ fontSize: '25px' }}
                      >
                        {smartRound(podcast.estimated_min_thirty_day_downloads).toLocaleString()} -{' '}
                        {smartRound(podcast.estimated_max_thirty_day_downloads).toLocaleString()}
                      </span>
                    )}
                    <span className="tc db" style={{ fontSize: '10px' }}>
                      30d Est. Downloads
                    </span>
                  </DemoCard>
                )}
              {!!podcast.estimated_min_thirty_day_reach &&
                !!podcast.estimated_max_thirty_day_reach && (
                  <DemoCard>
                    {!isPro ? (
                      <div
                        data-for="lock"
                        data-tip="Upgrade to Pro for advanced signals!"
                        className="tc"
                      >
                        <img style={{ width: '33px', opacity: '.5' }} src={lock} />
                      </div>
                    ) : (
                      <span
                        data-for="estimated"
                        data-tip="Estimates are calculated from public data using our proprietary methodology."
                        className="tc db dark-blue"
                        style={{ fontSize: '25px' }}
                      >
                        {smartRound(podcast.estimated_min_thirty_day_reach).toLocaleString()} -{' '}
                        {smartRound(podcast.estimated_max_thirty_day_reach).toLocaleString()}
                      </span>
                    )}
                    <span className="tc db" style={{ fontSize: '10px' }}>
                      30d Est. Reach
                    </span>
                  </DemoCard>
                )}
            </div>
            {(podcast.average_thirty_day_downloads || podcast.average_thirty_day_reach) && (
              <div className="mb2 flex justify-start">
                <ReactTooltip id="lock" />
                {podcast.average_thirty_day_reach && (
                  <DemoCard>
                    {!isPro ? (
                      <div
                        data-for="lock"
                        data-tip="Upgrade to Pro for advanced signals!"
                        className="tc"
                      >
                        <img style={{ width: '33px', opacity: '.5' }} src={lock} />
                      </div>
                    ) : (
                      <span className="tc db dark-blue" style={{ fontSize: '25px' }}>
                        {smartRound(podcast.average_thirty_day_reach).toLocaleString()}
                      </span>
                    )}
                    <span className="tc db" style={{ fontSize: '10px' }}>
                      30d Reach
                    </span>
                  </DemoCard>
                )}
                {podcast.average_thirty_day_downloads && (
                  <DemoCard>
                    {!isPro ? (
                      <div
                        data-for="lock"
                        data-tip="Upgrade to Pro for advanced signals!"
                        className="tc"
                      >
                        <img style={{ width: '33px', opacity: '.5' }} src={lock} />
                      </div>
                    ) : (
                      <span className="tc db dark-blue" style={{ fontSize: '25px' }}>
                        {smartRound(podcast.average_thirty_day_downloads).toLocaleString()}
                      </span>
                    )}
                    <span className="tc db" style={{ fontSize: '10px' }}>
                      30d Downloads
                    </span>
                  </DemoCard>
                )}
                {podcast.percentage_male && (
                  <DemoViz locked={!isPro} podcast={podcast} demoType="gender" />
                )}
                {podcast.percentage_0_17 && (
                  <DemoViz locked={!isPro} podcast={podcast} demoType="age" />
                )}
              </div>
            )}
            {strippedDescription
              ? isConnecting
                ? strippedDescription
                : truncateDescription(strippedDescription)
              : null}
          </div>
          <div className="mt3 f6 silver">
            {publisher ? (
              <span>
                <div className="dib">
                  <span className="silver">{publisher}</span>
                </div>
                {genres && genres.length > 0 ? <span className="pl1 pr1">·</span> : null}
              </span>
            ) : null}
            {genres.map((name, index) => (
              <span key={index}>
                <div className="dib">
                  <span className="silver">{name}</span>
                </div>
                {index === genres.length - 1 ? null : <span className="pl1 pr1">·</span>}
              </span>
            ))}
          </div>
        </div>
      </div>
      <div
        onClick={() => setRelatedPodcast(podcast)}
        name={id}
        style={{ bottom: '13px', cursor: 'pointer' }}
        className="fr dim relative i dark-blue related"
      >
        View similar podcasts
      </div>
    </div>
  );
};

export default ResultItem;
