"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.zIndexSkipLink = exports.zIndexPreviewBackdrop = exports.zIndexPopover = exports.zIndexFixed = exports.zIndexDialogBackdrop = exports.zIndexDialog = exports.zIndexBannerDecorator = exports.transitionFade = exports.sidebarSlimBaseWidth = exports.sidebarPadding = exports.sidebarBaseWidth = exports.opacityDisabled = exports.opacityActive = exports.fontWeightBook = exports.fontWeightBold = exports.fontWeightBlack = exports.cursorDisabled = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
var _layoutTheme = require("./layout-theme");
// Typography
var fontWeightBook = 400;
exports.fontWeightBook = fontWeightBook;
var fontWeightBold = 700;
exports.fontWeightBold = fontWeightBold;
var fontWeightBlack = 900;

// z-index master list
exports.fontWeightBlack = fontWeightBlack;
var zIndexPreviewBackdrop = 0;
exports.zIndexPreviewBackdrop = zIndexPreviewBackdrop;
var zIndexBannerDecorator = 1;
exports.zIndexBannerDecorator = zIndexBannerDecorator;
var zIndexFixed = 1030;
exports.zIndexFixed = zIndexFixed;
var zIndexDialogBackdrop = 1040;
exports.zIndexDialogBackdrop = zIndexDialogBackdrop;
var zIndexDialog = 1050;
exports.zIndexDialog = zIndexDialog;
var zIndexPopover = 1060;
exports.zIndexPopover = zIndexPopover;
var zIndexSkipLink = 9999;

// Sidebar
exports.zIndexSkipLink = zIndexSkipLink;
var sidebarBaseWidth = '200px';
exports.sidebarBaseWidth = sidebarBaseWidth;
var sidebarSlimBaseWidth = _encoreFoundation.spacer64;
exports.sidebarSlimBaseWidth = sidebarSlimBaseWidth;
var sidebarPadding = (0, _layoutTheme.cssLayoutMargin)('compact', _encoreFoundation.spacer24);

// Transitions + Animations
exports.sidebarPadding = sidebarPadding;
var transitionFade = '0.1s';

// Opacities
exports.transitionFade = transitionFade;
var opacityDisabled = _encoreFoundation.opacity30;
exports.opacityDisabled = opacityDisabled;
var opacityActive = _encoreFoundation.opacity70;

// Misc
exports.opacityActive = opacityActive;
var cursorDisabled = 'not-allowed'; // Disabled cursor for form controls and buttons.
exports.cursorDisabled = cursorDisabled;