/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { updateTeam } from 'shared/api';
import Button from 'shared/Button';

class Art19DecryptionKeyInput extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSave = this.onSave.bind(this);

    this.state = {
      showKeys: false,
      newDecryptionKey: '',
    };
  }

  onChange(e) {
    this.setState({ newDecryptionKey: e.target.value });
  }

  onSave() {
    if (this.state.requestInProgress || this.state.newDecryptionKey === '') return;
    this.setState({ requestInProgress: true });

    const { teamHashedId, teamId, art19DecryptionKeys, onFailure, onSuccess } = this.props;

    updateTeam({
      art19DecryptionKeys: art19DecryptionKeys.concat(this.state.newDecryptionKey),
      id: teamHashedId,
      teamId,
    })
      .then(res => {
        this.setState({
          newDecryptionKey: '',
        });
        onSuccess(res.data.art19DecryptionKeys);
      })
      .catch(() => onFailure())
      .finally(() => this.setState({ requestInProgress: false }));
  }

  renderDecryptionKeys() {
    return this.props.decryptionKeys.map((key, i) => (
      <div className="ph2 pv3 ba bg-white b--moon-gray br2 mb2" key={i}>
        <div className="header-font mid-gray f6 mb2 mr2">Key {i + 1}</div>
        <div className="ph1 pv2 f5 w-100 br2 b--moon-gray ba nowrap overflow-x-scroll">{key}</div>
      </div>
    ));
  }

  render() {
    const { showKeys, newDecryptionKey, requestInProgress } = this.state;

    const { decryptionKeys } = this.props;

    return (
      <div>
        <div className="f3 dark-blue mb3 header-font mr2">Art19 Decryption Keys</div>
        <div style={{ fontSize: '18px' }} className="mid-gray lh-copy mb2">
          Art19 encrypts data needed to measure downloads. We need your decryption key to measure
          campaigns.
        </div>
        <div style={{ fontSize: '18px' }} className="mid-gray lh-copy mb3">
          To get your key contact your Art19 rep and ask for your tracking pixel decryption key.
          Once you have it, paste the key below and press save.
        </div>
        <div style={{ fontSize: '18px' }} className="mid-gray lh-copy mb3">
          Questions?{' '}
          <a
            target="_blank"
            href="mailto:adops@chartable.com"
            className="blue link pointer"
            rel="noopener noreferrer"
          >
            Get in touch!
          </a>
        </div>
        <input
          type="text"
          className="ph2 mt3 mb1 f5 w-100 br2 b--moon-gray ba pv3"
          placeholder="Enter Art19 Decryption Key"
          value={newDecryptionKey}
          onChange={this.onChange}
        />
        <Button
          loading={requestInProgress}
          onClick={this.onSave}
          type="primary"
          className="w-100 mt3"
          testId="art19-decryption-key-save-btn"
        >
          Save key
        </Button>
        {decryptionKeys.length > 0 && (
          <div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="link blue pointer w-auto mt3"
              style={{ fontSize: '19px' }}
              onClick={() => this.setState({ showKeys: !showKeys })}
            >
              {showKeys ? 'Hide saved keys' : `Show saved keys (${decryptionKeys.length})`}
            </div>
            {showKeys && this.renderDecryptionKeys()}
          </div>
        )}
      </div>
    );
  }
}

Art19DecryptionKeyInput.propTypes = {
  decryptionKeys: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func.isRequired,
};

export default Art19DecryptionKeyInput;
