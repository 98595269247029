export const demographicsSegments = {
  householdIncome: 'householdIncome',
  education: 'education',
  ethnicity: 'ethnicity',
  gender: 'gender',
  age: 'age',
};

// Spotify data is formatted differently
export function formatSpotifyDemographicDataForBarChart(data) {
  const formattedData = [];
  let total = 0;

  // Get total #
  data.forEach((item, index) => {
    if (index === 0) {
      return;
    }
    total += item[1];
  });

  // Show % of total on bar chart
  data.forEach((item, index) => {
    if (index === 0) return;
    const percentage = Number(((item[1] / total) * 100).toFixed(2));
    formattedData.push([item[0], percentage, '#4b9ec1', `${percentage}%`]);
  });

  formattedData.unshift(['', '', { role: 'style' }, { role: 'tooltip' }]);

  return formattedData;
}

export function formatDemographicDataForBarChart(segment, data, multi = false) {
  const formattedData = [];

  data.forEach(item =>
    formattedData.push(
      // household_income :(
      [
        item[segment === demographicsSegments.householdIncome ? 'householdIncome' : segment],
        item.reachPercentage,
        '#4b9ec1',
        `${item.reachPercentage}%`,
        item.benchmarkReachPercentage,
        '#CDECFF',
        `${item.benchmarkReachPercentage}% (benchmark)`,
      ],
    ),
  );

  formattedData.sort((a, b) => {
    if (segment === demographicsSegments.householdIncome) {
      try {
        // This is a super hacky way to grab the first number of the income range
        return parseInt(a[0].split(' - ')[0].split('$')[1]) <
          parseInt(b[0].split(' - ')[0].split('$')[1])
          ? 1
          : -1;
      } catch (e) {
        // Catch "Unknown" income
        return -1;
      }
    } else {
      if (a[1] == b[1]) return 0;
      return a[1] < b[1] ? 1 : -1;
    }
  });

  if (multi) {
    formattedData.unshift([
      '',
      '',
      { role: 'style' },
      { role: 'tooltip' },
      '',
      { role: 'style' },
      { role: 'tooltip' },
    ]);
  } else {
    formattedData.unshift(['', '', { role: 'style' }, { role: 'tooltip' }]);
  }

  return formattedData;
}
