import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SmartFeedsForm from './SmartFeedsForm';
import { createSmartFeed } from 'shared/api';
import Loading from 'shared/Loading';
import ConfirmationModal from 'shared/ConfirmationModal';
const toastOptions = {
  duration: 10000,
  id: 'SMART_FEEDS_CREATE',
};

const aboutUrl = 'https://help.chartable.com/category/223-smartfeeds';

const SmartFeedsCreateView = ({ teamId, podcasts, feeds, toast }) => {
  const [loading, setLoading] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const handleSubmit = async formData => {
    const payload = { teamId, ...formData };
    try {
      const res = await createSmartFeed(payload);
      const { feed } = res.data;
      setConfirming(feed);
    } catch (e) {
      const message = e.response.data && e.response.data.error ? e.response.data.error : e;
      toast.error(message, toastOptions);
    }
  };

  const onConfirm = () => {
    window.location = `/smart_feeds/${teamId}/edit/${confirming.id}`;
  };

  const renderBack = () => {
    return (
      <div className="bb b--light-gray w-100 bg-white pv2 ph4 items-center f5">
        <div className="flex items-center justify-between" style={{ height: '1.5rem' }}>
          <Link to={`/smart_feeds/${teamId}`} className="link blue pointer">
            « Back
          </Link>
        </div>
      </div>
    );
  };

  const confirmationMessage = () => {
    return (
      <div>
        <h3>Next Steps</h3>
        <p>
          {' '}
          - Hooray! Your SmartFeed has been created, but you{' '}
          <span className={`fw9 dark-red`}>must</span> submit your SmartFeed to all podcast
          directories.
        </p>
        <p>
          {' '}
          - Check out these <a href={aboutUrl}>helpful articles</a> about SmartFeeds to learn more.
        </p>
      </div>
    );
  };

  const renderConfirmation = () => {
    return (
      <ConfirmationModal
        title={``}
        message={confirmationMessage()}
        onClose={onConfirm}
        isOpen={!!confirming}
        confirmTitle={'OK'}
        onConfirm={onConfirm}
        showCancel={false}
      />
    );
  };

  return (
    <div>
      {renderConfirmation()}
      {renderBack()}
      <div className={`mw8 pa3`}>
        {loading ? (
          <Loading />
        ) : (
          <div>
            <div className={`f3 dark-blue mb3`}>Create a SmartFeed</div>
            <SmartFeedsForm
              teamId={teamId}
              podcasts={podcasts}
              onSubmit={handleSubmit}
              feeds={feeds}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SmartFeedsCreateView;
