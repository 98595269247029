import React, { Component } from 'react';

const ChartRanking = ({ data }) => {
  const displayDelta = markup => {
    let inner;
    let deltaClass;
    if (markup) {
      const htmlObj = {
        __html: markup,
      };
      inner = <div dangerouslySetInnerHTML={htmlObj} />;
      deltaClass = `pb2 w3 ${!data.mostRecent ? 'silver' : ''}`;
    } else {
      inner = 'NEW';
      deltaClass = 'pr2 pb2 ttu f7 v-mid w3 green';
    }
    return <td className={deltaClass}>{inner}</td>;
  };
  return (
    <tr className="mb2 w-100">
      <td className={`pr2 pb2 w2 ${!data.mostRecent ? 'silver tc f7 ttu' : ''}`}>
        {data.mostRecent ? `#${data.rank}` : 'OUT'}
      </td>
      {data.mostRecent ? displayDelta(data.delta) : <td className="pr2 pb2 v-mid w3">&mdash;</td>}
      <td className="pr2 pb2">
        <a className="link blue" href={data.specUrl}>
          {data.displayName}
        </a>
      </td>
      <td className="w4 pr2 pb2">
        <a className="link silver" href={data.chartUrl}>
          Chart &raquo;
        </a>
      </td>
    </tr>
  );
};

export default ChartRanking;
