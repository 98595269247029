import React, { useEffect, useState } from 'react';
import EpisodicRetentionChart from 'shared/charts/EpisodicRetentionChart';
import RetentionChart from 'shared/charts/RetentionChart';

import LockedRetention from './LockedRetention';

const MONTHLY = 'monthly';
const EPISODIC = 'episodic';
const LOCKED_MONTHLY = 'locked_monthly';

const RetentionSwitcher = ({
  viewingPodcastDetail,
  canViewRetentionChart,
  hasEnoughDataForEpisodicRetention,
  hasEnoughDataForRetentionChart,
  trackableEnabled,
  teamId,
  teamPodcastId,
  forceLoading,
  showRetentionTour,
  hasRetentionChart,
}) => {
  const episodicOk =
    viewingPodcastDetail && canViewRetentionChart && hasEnoughDataForEpisodicRetention;
  const monthlyOk = viewingPodcastDetail && hasEnoughDataForRetentionChart && trackableEnabled;
  const monthlyLocked = !canViewRetentionChart;
  const switcherAvailable = episodicOk && monthlyOk;
  const [active, setActive] = useState(null);

  const handleSwitch = targetActive => {
    if (monthlyLocked && targetActive === MONTHLY) {
      setActive(LOCKED_MONTHLY);
      return;
    }
    setActive(targetActive);
  };

  useEffect(() => {
    if (episodicOk) {
      handleSwitch(EPISODIC);
    } else if (monthlyOk) {
      handleSwitch(MONTHLY);
    }
  }, []);

  const Switcher = () => {
    return (
      <div className="ml2 flex flex-row items-center">
        <div
          style={{
            border: '1px solid #ccc',
            borderRight: 'none',
            borderRadius: '4px 0 0 4px',
            color: [MONTHLY, LOCKED_MONTHLY].includes(active) ? 'white' : 'initial',
            background: [MONTHLY, LOCKED_MONTHLY].includes(active) ? '#4B9EC1' : 'initial',
          }}
          className="f6 pv1 ph2 dark-gray pointer"
          onClick={() => handleSwitch(MONTHLY)}
        >
          Monthly
        </div>
        <div
          style={{
            border: '1px solid #ccc',
            borderLeft: 'none',
            borderRadius: '0 4px 4px 0',
            color: [EPISODIC].includes(active) ? 'white' : 'initial',
            background: [EPISODIC].includes(active) ? '#4B9EC1' : 'initial',
          }}
          className="f6 pv1 ph2 dark-gray pointer"
          onClick={() => handleSwitch(EPISODIC)}
        >
          Episodic
        </div>
      </div>
    );
  };

  const getRetentionComponent = () => {
    switch (active) {
      case EPISODIC:
        return (
          <EpisodicRetentionChart
            className="mb4"
            teamId={teamId}
            teamPodcastId={teamPodcastId}
            forceLoading={forceLoading}
            switcher={switcherAvailable && <Switcher />}
            handleSwitch={handleSwitch}
          />
        );
      case MONTHLY:
        return (
          <RetentionChart
            className="mb4"
            teamId={teamId}
            teamPodcastId={teamPodcastId}
            showTour={showRetentionTour}
            forceLoading={forceLoading}
            switcher={switcherAvailable && <Switcher />}
            handleSwitch={handleSwitch}
          />
        );
      case LOCKED_MONTHLY:
        return (
          <LockedRetention
            teamId={teamId}
            trackableEnabled={trackableEnabled}
            subscribed={hasRetentionChart}
          />
        );
      default:
        return null;
    }
  };

  return <div>{getRetentionComponent()}</div>;
};

export default RetentionSwitcher;
