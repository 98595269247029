/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import SmallLoading from 'shared/SmallLoading';
import { abbreviateNumber } from 'shared/helpers';

const StatCardContent = ({
  contentClassName,
  title,
  stat,
  shouldAbbreviateNumber,
  percentage,
  tooltip,
  text,
  byline,
  showLoadingIfNull,
}) => (
  <div className={`h-100 ${contentClassName}`} style={{ fontFamily: 'Inter' }}>
    <div className="flex flex-column justify-between h-100">
      <ReactTooltip id={title} />
      <div className="flex flex-row">
        <div style={{ fontWeight: 400 }} className={`mt0 mb2 dark-blue dib ${title ? '' : 'o-0'}`}>
          {title ? title : 'pl'}
        </div>
        {tooltip && (
          <div className="f7 b gray ttu tracked tc ml2" style={{ marginTop: '3px' }}>
            <a
              data-tip={tooltip}
              data-for={title}
              className="ph1 ba b--gray br-100 tc"
              style={{ cursor: 'help' }}
            >
              ?
            </a>
          </div>
        )}
      </div>
      <div className="mt-auto flex flex-wrap items-baseline gap-small">
        <div
          className="dark-gray fw-600 f3"
          style={{
            fontFamily: 'Inter',
            color: '#33334f',
            letterSpacing: '-.019em',
          }}
        >
          {text ? (
            stat
          ) : stat !== null ? (
            shouldAbbreviateNumber ? (
              abbreviateNumber(stat)
            ) : (
              stat.toLocaleString()
            )
          ) : showLoadingIfNull ? (
            <SmallLoading />
          ) : (
            '-'
          )}
        </div>
        {stat !== null && percentage && <h3 className="mv0 dark-gray">%</h3>}
        {byline && <div className="f6 mid-gray">({byline})</div>}
      </div>
    </div>
  </div>
);

const StatCard = props => (
  <div
    style={{
      ...props.inlineStyle,
      boxShadow: '0 2px 10px 0 rgb(51 51 79 / 2%)',
    }}
    className={`pa3 ba b--light-gray bg-white br3 flex flex-column ${props.className}`}
  >
    <StatCardContent {...props} />
  </div>
);

StatCard.propsTypes = {
  title: PropTypes.string.isRequired,
  stat: PropTypes.number,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  tooltip: PropTypes.string,
  increase: PropTypes.number,
  percentage: PropTypes.bool,
  text: PropTypes.bool,
  shouldAbbreviateNumber: PropTypes.bool,
  showLoadingIfNull: PropTypes.bool,
  inlineStyle: PropTypes.object,
};

StatCard.defaultProps = {
  showLoadingIfNull: true,
  percentage: false,
  text: false,
  shouldAbbreviateNumber: false,
  className: '',
  contentClassName: '',
  inlineStyle: null,
  tooltip: null,
  stat: null,
};

export { StatCardContent };

export default StatCard;
