import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const green = ['complete', 'active', 'ok', 'approved'];

const yellow = ['warning', 'pending', 'processing', 'sent', 'needs_approval', 'ready'];

const red = ['error', 'failure', 'denied', 'failed'];

function colorForStatus(status) {
  if (green.includes(status)) {
    return { color: '#03543f', backgroundColor: '#def7ec' };
  }

  if (yellow.includes(status)) {
    return { color: '#6d5518', backgroundColor: '#fbeecd' };
  }

  if (red.includes(status)) {
    return { color: '#9b1c1c', backgroundColor: '#fde8e8' };
  }

  return { color: '#fff', backgroundColor: '#252f3fb3' };
}

const NewStatusIndicator = props => {
  let { colors } = props;
  const status = props.status.toLowerCase();

  if (!props.colors) {
    colors = colorForStatus(status);
  }

  // react tooltip "id" and the tag that triggers it need to match here otherwise we get
  // a double render.
  // More detail here: https://github.com/wwayne/react-tooltip/issues/346
  const tooltipId = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

  return (
    <div onClick={props.onClick}>
      <ReactTooltip id={tooltipId} />
      <span
        className={props.className || 'b br4 f6 ttc pointer'}
        data-tip={props.tooltip}
        data-for={tooltipId}
        style={{
          ...colors,
          padding: props.className ? '' : '0.075rem 0.75rem',
        }}
      >
        {props.text || status}
      </span>
    </div>
  );
};

NewStatusIndicator.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  colors: PropTypes.object,
  onClick: PropTypes.func,
};

NewStatusIndicator.defaultProps = {
  colors: null,
};

export default NewStatusIndicator;
