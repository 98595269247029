import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Toggle from 'react-toggle';
import arrow from 'images/icons/up_arrow.svg';

const SortControl = ({
  sortOptions,
  sortOption,
  handleSortBy,
  setMyPods,
  myPods,
  actor,
  verified,
  setVerified,
  quality,
  setQuality,
}) => {
  const [viewState, setViewState] = useState({
    verified,
    myPods,
    quality,
  });

  useEffect(() => {
    setViewState({
      verified,
      myPods,
      quality,
    });
  }, [verified, myPods, quality]);

  return (
    <div className="w-100 dib fr" style={{ position: 'relative', top: '-13px' }}>
      <Select
        defaultValue={sortOption}
        value={sortOption}
        className="dib v-mid w-25 fr bn sort-menu"
        onChange={handleSortBy}
        options={sortOptions}
        isOptionDisabled={option => option.disabled}
        styles={{
          control: (provided, state) => ({
            ...provided,
            border: 'none',
            background: '#FFFFFF',
            borderRadius: '.5rem',
            boxShadow: '0 2px 10px 0 rgb(51 51 79 / 2%)',
          }),
          singleValue: (provided, state) => ({
            ...provided,
            color: '#777',
          }),
          option: (provided, state) => {
            const val = state.data.value;
            return {
              ...provided,
              display: val === null ? 'none' : 'block',
            };
          },
        }}
      />
      <div className="fr">
        <div className="mr3 quality">
          <Toggle
            checked={viewState.quality}
            icons={false}
            className="mt2 mr1 outline-0"
            onChange={() => {
              setViewState({
                ...viewState,
                quality: !quality,
              });
              setQuality(!quality);
            }}
          />
          <span style={{ top: '-8px' }} className="ml1 relative">
            Active
          </span>
        </div>
      </div>
      <div className="fr">
        <div className="mr3 verified">
          <Toggle
            checked={viewState.verified}
            icons={false}
            className="mt2 mr1 outline-0"
            onChange={() => {
              setViewState({
                ...viewState,
                verified: !verified,
              });
              setVerified(!verified);
            }}
          />
          <span style={{ top: '-8px' }} className="ml1 relative">
            Verified
          </span>
        </div>
      </div>
      {actor === 'publisher' && (
        <div className="fr">
          <div className="mr3">
            <Toggle
              checked={viewState.myPods}
              icons={false}
              className="mt2 mr1 outline-0"
              onChange={() => {
                setViewState({
                  ...viewState,
                  myPods: !myPods,
                });
                setMyPods(!myPods);
              }}
            />
            <span style={{ top: '-8px' }} className="ml1 relative">
              My Podcasts
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortControl;
