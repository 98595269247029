"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _baseline = require("./baseline");
Object.keys(_baseline).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _baseline[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _baseline[key];
    }
  });
});
var _browsers = require("./browsers");
Object.keys(_browsers).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _browsers[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _browsers[key];
    }
  });
});
var _buttons = require("./buttons");
Object.keys(_buttons).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _buttons[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _buttons[key];
    }
  });
});
var _buttonsDeprecated = require("./buttons-deprecated");
Object.keys(_buttonsDeprecated).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _buttonsDeprecated[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _buttonsDeprecated[key];
    }
  });
});
var _clearFix = require("./clearFix");
Object.keys(_clearFix).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _clearFix[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _clearFix[key];
    }
  });
});
var _controls = require("./controls");
Object.keys(_controls).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _controls[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _controls[key];
    }
  });
});
var _focusBorders = require("./focusBorders");
Object.keys(_focusBorders).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _focusBorders[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _focusBorders[key];
    }
  });
});
var _forms = require("./forms");
Object.keys(_forms).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _forms[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _forms[key];
    }
  });
});
var _images = require("./images");
Object.keys(_images).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _images[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _images[key];
    }
  });
});
var _localization = require("./localization");
Object.keys(_localization).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _localization[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _localization[key];
    }
  });
});
var _overlays = require("./overlays");
Object.keys(_overlays).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _overlays[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _overlays[key];
    }
  });
});
var _overlaysDeprecated = require("./overlays-deprecated");
Object.keys(_overlaysDeprecated).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _overlaysDeprecated[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _overlaysDeprecated[key];
    }
  });
});
var _textOverflow = require("./textOverflow");
Object.keys(_textOverflow).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _textOverflow[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _textOverflow[key];
    }
  });
});
var _triangle = require("./triangle");
Object.keys(_triangle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _triangle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _triangle[key];
    }
  });
});
var _type = require("./type");
Object.keys(_type).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _type[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _type[key];
    }
  });
});
var _visuallyHidden = require("./visuallyHidden");
Object.keys(_visuallyHidden).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _visuallyHidden[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _visuallyHidden[key];
    }
  });
});