import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

export default function PodcastChart({ data, annotationsWidth }) {
  const ranks = data.map(arr => {
    return arr[1];
  });

  const formattedData = data.map(t => {
    return [new Date(t[0]), t[1], t[2], t[3]];
  });

  const minRank = Math.min(...ranks);
  const maxRank = Math.max(...ranks);
  const gridlinesCount = maxRank - minRank > 5 ? 5 : maxRank - minRank + 1;

  const options = {
    title: '',
    displayZoomButtons: false,
    displayRangeSelector: false,
    chart: {
      vAxis: {
        direction: -1,
        minValue: 1,
        maxValue: maxRank > 400 ? 1500 : 400,
      },
      hAxis: {
        type: 'date',
        format: 'MMM d, y',
        maxAlternation: 1,
      },
    },
    //annotations: {'Episode': {style: 'line'}, 'Featured': {style: 'line'}},
    legend: { position: 'none' },
    interpolateNulls: true,
    series: {
      0: { color: '#4B9EC1', lineWidth: 3 },
    },
    backgroundColor: {
      strokeWidth: 0,
    },
    displayAnnotations: true,
    thickness: 3,
    annotationsWidth: annotationsWidth,
    colors: ['#4B9EC1'],
  };

  const columns = [
    { type: 'date', label: 'Date' },
    { type: 'number', label: 'Rank' },
    { type: 'string', label: 'Featured Type' },
    { type: 'string', label: 'Episode Title' },
  ];

  return (
    <Chart
      chartType="AnnotationChart"
      options={options}
      columns={columns}
      rows={formattedData}
      width="100%"
      height="400px"
    />
  );
}

PodcastChart.propTypes = {
  data: PropTypes.array.isRequired,
};
