import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Select from 'react-select';

export default class ColumnChart extends Component {
  constructor(props) {
    super(props);

    this.handleColumnSwitch = this.handleColumnSwitch.bind(this);

    this.state = {
      selectedColumn: this.props.column || Object.keys(this.props.data)[0],
    };
  }

  handleColumnSwitch(e) {
    this.setState({
      selectedColumn: e.value,
    });
  }

  render() {
    const { data, title, subtitle, calculatePercentage } = this.props;

    const { selectedColumn } = this.state;

    const chartOptions = {
      chart: { title: '' },
      legend: { position: 'top', maxLines: 3 },
      chartArea: {
        left: 40,
        top: 40,
        width: '80%',
        height: '70%',
      },
      interpolateNulls: false,
      vAxis: {
        format: "#'%'",
      },
    };

    let ind = 0;
    const chartColumns = data[selectedColumn][0].map(col => {
      ind += 1;
      if (ind === 1) {
        return {
          type: 'string',
          label: null,
        };
      }

      return {
        type: 'number',
        label: col,
      };
    });

    let rows = [];
    if (calculatePercentage) {
      const sums = new Array(data[selectedColumn][0].length);
      let j = 1;
      for (; j < data[selectedColumn].length; j++) {
        const row = data[selectedColumn][j];
        let i = 1;
        for (; i < row.length; i++) {
          sums[i] = sums[i] ? sums[i] + row[i] : row[i];
        }
      }
      rows = JSON.parse(JSON.stringify(data[selectedColumn]))
        .splice(1)
        .map(row => {
          const normalizedRow = [row[0]];
          let i = 1;
          for (; i < row.length; i++) {
            normalizedRow.push((100 * row[i]) / sums[i]);
          }
          return normalizedRow;
        });
    } else {
      rows = JSON.parse(JSON.stringify(data[selectedColumn])).splice(1);
    }

    const options = {};
    Object.keys(data).forEach(
      v => (options[v] = { value: v, label: v.charAt(0).toUpperCase() + v.slice(1) }),
    );

    return (
      <div className="mb4">
        <div className="w-100 tl flex flex-wrap">
          <div className="w-50-l w-100 pa2-s pt2-ns">
            <div className="f3 dark-blue mb2">
              {title}
              {subtitle && (
                <div className="f7 b gray ttu tracked dib pl2">
                  <a data-tip={subtitle} className="ph1 ba b--gray br-100 tc pointer">
                    &#63;
                  </a>
                </div>
              )}
              {Object.keys(options).length === 1 && (
                <span className="pl2 f6 b">({options[selectedColumn].label})</span>
              )}
            </div>
          </div>
          {Object.keys(options).length > 1 && (
            <div className="w-50-l w-100 pa2-s cf">
              <div className="fr-l fl w-100">
                <Select
                  value={options[selectedColumn]}
                  onChange={this.handleLocationSwitch}
                  options={Object.values(options)}
                />
              </div>
            </div>
          )}
        </div>
        <div className="mt2 bg-white br2">
          <Chart
            chartType="ColumnChart"
            options={chartOptions}
            columns={chartColumns}
            rows={rows}
            width="100%"
            height="300px"
          />
        </div>
      </div>
    );
  }
}

ColumnChart.propTypes = {
  data: PropTypes.object.isRequired,
  column: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  calculatePercentage: PropTypes.bool,
};

ColumnChart.defaultProps = {
  column: null,
  title: null,
  subtitle: null,
  calculatePercentage: false,
};
