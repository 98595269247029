import React from 'react';
import PropTypes from 'prop-types';

import WarningRedSVG from 'images/icons/warning-red.svg';
import WarningSVG from 'images/icons/warning-yellow.svg';

function Banner({
  style,
  className,
  image,
  children,
  type,
  error,
  success,
  innerClassName,
  noImage,
  onClick,
  backgroundColor, // must be valid CSS properties for backgroundColor
  textColor, // must be valid CSS properties for color
  hoverText,
}) {
  let imageSrc = image;
  let colorStyle;

  if (!!backgroundColor && !!textColor) {
    colorStyle = { color: textColor, backgroundColor: backgroundColor };
  } else {
    if (error || type === 'error') {
      if (!image) {
        imageSrc = WarningRedSVG;
      }
      colorStyle = { color: '#9b1c1c', backgroundColor: '#fde8e8' };
    } else if (success || type === 'success' || type == 'active') {
      colorStyle = { color: '#03543f', backgroundColor: '#def7ec' };
    } else {
      if (!image) {
        imageSrc = WarningSVG;
      }
      colorStyle = { color: '#6d5518', backgroundColor: '#fbeecd' };
    }
  }

  return (
    <div title={hoverText} className={className} style={style}>
      <div
        onClick={onClick}
        className={`${onClick ? 'dim pointer' : ''} ${innerClassName ? innerClassName : 'pa3 br3'}`}
        style={colorStyle}
      >
        <div className="flex flex-row gap-large items-center">
          {!noImage && <img src={imageSrc} className="w-auto h1" />}
          <div>{children}</div>
        </div>
      </div>
    </div>
  );
}

Banner.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  image: PropTypes.any,
  children: PropTypes.any,
  type: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  noImage: PropTypes.bool,
  onClick: PropTypes.func,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
};

Banner.defaultProps = {
  style: null,
  className: '',
  innerClassName: '',
  image: null,
  children: null,
  type: null,
  error: false,
  success: false,
  noImage: false,
  onClick: null,
  backgroundColor: '',
  textColor: '',
  hoverText: '',
};

export default Banner;
