import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';
import Select from 'react-select';

export default class PieChart extends Component {
  constructor(props) {
    super(props);

    this.handleColumnSwitch = this.handleColumnSwitch.bind(this);

    this.state = {
      selectedColumn: this.props.column || Object.keys(this.props.data)[0],
    };
  }

  handleColumnSwitch(e) {
    this.setState({
      selectedColumn: e.value,
    });
  }

  render() {
    const { data, title, subtitle, hideAbsolute, colorMap, hidePercentage, showOptions } =
      this.props;

    const { selectedColumn } = this.state;

    const chartOptions = {
      title: '',
      pieSliceText: 'percentage',
      backgroundColor: {
        strokeWidth: 0,
        fill: 'transparent',
      },
      hAxis: {
        title: '',
      },
      vAxis: {
        title: '',
      },
      chartArea: { left: 20, top: 20, width: '90%', height: '90%' },
      colors: [
        '#30657B',
        '#885A9A',
        '#4B9EC1',
        '#7FA232',
        '#FDE74C',
        '#FA7921',
        '#E55934',
        '#D6A5A0',
        '#9BC53D',
        '#DC6082',
        '#8CC1D7',
      ],
    };

    if (hideAbsolute) {
      chartOptions['tooltip'] = { text: 'percentage' };
    }

    if (hidePercentage) {
      chartOptions['pieSliceText'] = 'value';
    }

    const options = {};
    Object.keys(data).forEach(
      v => (options[v] = { value: v, label: v.charAt(0).toUpperCase() + v.slice(1) }),
    );
    if (colorMap) {
      const slices = data[selectedColumn].slice(1).map(row => {
        return { color: colorMap[row[0]] };
      });
      chartOptions['slices'] = slices;
    }

    return (
      <div className="mb4">
        {showOptions && (
          <div className="w-100 tl flex flex-wrap">
            <div className="w-50-l w-100 pa2-s pt2-ns">
              <div className="f3 dark-blue mb2">
                {title}
                {subtitle && (
                  <div className="f7 b gray ttu tracked dib pl2">
                    <a data-tip={subtitle} className="ph1 ba b--gray br-100 tc pointer">
                      &#63;
                    </a>
                  </div>
                )}
                {Object.keys(options).length === 1 && options[selectedColumn].label.length > 0 && (
                  <span className="pl2 f6 b">({options[selectedColumn].label})</span>
                )}
              </div>
            </div>
            {Object.keys(options).length > 1 && (
              <div className="w-50-l w-100 pa2-s cf">
                <div className="fr-l fl w-100">
                  <Select
                    value={options[selectedColumn]}
                    onChange={this.handleLocationSwitch}
                    options={Object.values(options)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div className="mt2 bg-white br2">
          <Chart
            chartType="PieChart"
            options={chartOptions}
            data={JSON.parse(JSON.stringify(data[selectedColumn]))}
            width="100%"
            height="300px"
          />
        </div>
      </div>
    );
  }
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  column: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  hideAbsolute: PropTypes.bool,
  colorMap: PropTypes.object,
  hidePercentage: PropTypes.bool,
  showOptions: PropTypes.bool,
};

PieChart.defaultProps = {
  column: null,
  title: null,
  subtitle: null,
  hideAbsolute: false,
  hidePercentage: false,
  colorMap: null,
  showOptions: true,
};
