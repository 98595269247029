import React, { useState } from 'react';
import { adminAddUserToTeam, adminConfirmInput } from 'shared/api';
import Button from 'shared/Button';
import FormSelect from 'shared/FormSelect';

const InputForm = ({
  teamSlugInput,
  userEmailInput,
  selectedRole,
  setTeamSlugInput,
  setUserEmailInput,
  setSelectedRole,
  onConfirm,
  requestInProgress,
  error,
}) => (
  <>
    <div className="f5 mb2 blue">Enter a team slug (Megaphone connected only)</div>
    <input
      disabled={requestInProgress}
      value={teamSlugInput}
      className="pa2 input-reset ba bg-white br2 b--silver w-100 mb3"
      type="text"
      placeholder="chartable-radio"
      onChange={e => setTeamSlugInput(e.target.value)}
    />
    <div className="f5 mb2 blue">Enter the users email</div>
    <input
      disabled={requestInProgress}
      value={userEmailInput}
      className="pa2 input-reset ba bg-white br2 b--silver w-100 mb3"
      type="email"
      placeholder="jshelley@spotify.com"
      onChange={e => setUserEmailInput(e.target.value)}
    />
    <div className="f5 mb2 blue">Select the users role</div>
    <FormSelect
      value={selectedRole}
      options={[
        { value: 'admin', label: 'admin' },
        { value: 'viewer', label: 'viewer' },
        { value: 'member', label: 'member' },
      ]}
      onChange={setSelectedRole}
      placeholder="Select a role"
      className="mb3"
      disabled={requestInProgress}
    />
    {error && <div className="red mb2">{error}</div>}
    <Button
      disabled={!teamSlugInput || !userEmailInput || !selectedRole}
      loading={requestInProgress}
      type="create"
      onClick={() => onConfirm(teamSlugInput, userEmailInput, selectedRole)}
    >
      Confirm
    </Button>
  </>
);

const ConfirmedInputs = ({
  userIsNew,
  userEmailInput,
  teamSlugInput,
  selectedRole,
  handleAddUserToTeam,
  addRequestInProgress,
}) => (
  <>
    <div className="f5 blue">What will happen next:</div>
    <div className="f5 lh-copy mb4">
      <ol className="mb0 mt2">
        {userIsNew && (
          <>
            <li>
              User will be created with email: <b>{userEmailInput}</b>
            </li>
            <li>The user will receive a Password Reset email.</li>
          </>
        )}
        <li>
          User ({userEmailInput}) will be added to{' '}
          <a href={`/teams/${teamSlugInput}/dashboard`}>this team</a> with the role of{' '}
          {selectedRole} and <b>have access to all the team's podcasts</b>
        </li>
      </ol>
    </div>
    <Button loading={addRequestInProgress} type="create" onClick={handleAddUserToTeam}>
      This is what I want to happen
    </Button>
  </>
);

const UserDashboard = () => {
  const [confirmedInputs, setConfirmedInputs] = useState(false);
  const [error, setError] = useState(null);
  const [userIsNew, setUserIsNew] = useState(false);
  const [teamSlugInput, setTeamSlugInput] = useState('');
  const [userEmailInput, setUserEmailInput] = useState('');
  const [selectedRole, setSelectedRole] = useState(null);
  const [confirmRequestInProgress, setConfirmRequestInProgress] = useState(false);
  const [addRequestInProgress, setAddRequestInProgress] = useState(false);
  const [finished, setFinished] = useState(false);

  const handleReset = () => {
    setConfirmedInputs(false);
    setUserIsNew(false);
    setTeamSlugInput('');
    setUserEmailInput('');
    setSelectedRole(null);
    setFinished(false);
  };

  const handleAddUserToTeam = () => {
    setAddRequestInProgress(true);

    adminAddUserToTeam({
      slug: teamSlugInput,
      email: userEmailInput,
      role: selectedRole,
    })
      .then(() => setFinished(true))
      .catch(() => {
        setError('Failed to add user to team');
        setConfirmedInputs(false);
      })
      .finally(() => setAddRequestInProgress(false));
  };

  const confirmInputs = (slug, email, role) => {
    setConfirmRequestInProgress(true);
    setError(null);

    adminConfirmInput({ slug, email, role })
      .then(res => {
        if (res.data.error) {
          setError(res.data.error);
          return;
        }

        setUserIsNew(res.data.userIsNew);
        setConfirmedInputs(true);
      })
      .catch(() => setError('Unknown error occurred'))
      .finally(() => setConfirmRequestInProgress(false));
  };

  const setContent = () => {
    if (finished) {
      return (
        <>
          <div className="f5 blue mb2">Success</div>
          <div className="f5 lh-copy mb2">
            The user will now have access to the team's dashboard. You can confirm they were added{' '}
            <a href={`/teams/${teamSlugInput}/dashboard/users`}>here</a>.
          </div>
          {userIsNew && (
            <div className="f5 lh-copy mb2">
              The user has been sent instructions to reset their password at {userEmailInput}.
            </div>
          )}
          <Button onClick={handleReset}>Reset</Button>
        </>
      );
    } else if (confirmedInputs) {
      return (
        <ConfirmedInputs
          userIsNew={userIsNew}
          userEmailInput={userEmailInput}
          teamSlugInput={teamSlugInput}
          selectedRole={selectedRole}
          handleAddUserToTeam={handleAddUserToTeam}
          addRequestInProgress={addRequestInProgress}
        />
      );
    }

    return (
      <InputForm
        teamSlugInput={teamSlugInput}
        userEmailInput={userEmailInput}
        selectedRole={selectedRole}
        setTeamSlugInput={setTeamSlugInput}
        setUserEmailInput={setUserEmailInput}
        setSelectedRole={setSelectedRole}
        error={error}
        requestInProgress={confirmRequestInProgress}
        onConfirm={confirmInputs}
      />
    );
  };

  return (
    <div className="mw6 pa4">
      <div className="f4 blue">How this works</div>
      <div className="f5 lh-copy mb4">
        <ol className="mb0 mt2">
          <li>Pick a team (must be Megaphone team)</li>
          <li>Input the user's email and select a role</li>
          <li>Click the create button</li>
          <li>
            <b>If the user does not exist yet</b> a new user will be created
          </li>
          <li>The user will be added to the team you selected</li>
        </ol>
      </div>
      {setContent()}
    </div>
  );
};

export default UserDashboard;
