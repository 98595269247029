import React from 'react';
import Modal from 'react-modal';

export default function BasicModal(props) {
  const derivedProps = { ...props };
  if (!derivedProps.style) {
    derivedProps.style = {
      overlay: { zIndex: 999 },
      content: { outline: 'none', maxHeight: '90vh' },
    };
  }
  if (!derivedProps.className) {
    derivedProps.className =
      'absolute overflow-auto z-9999 pa4 left-2 right-2 bg-white mw6 center top-2 ba br2';
  }

  return <Modal {...derivedProps} />;
}
