import React, { useEffect, useState } from 'react';
import burger from 'images/icons/sidebar/burger.svg';
import close from 'images/icons/sidebar/close.svg';
import left from 'images/icons/sidebar/left_arrow.svg';
import { localStorageHelper as ls } from 'shared/helpers';
import useWindowSize from 'shared/hooks/useWindowSize';

import ReadOnlyModeBanner from '../ReadOnlyModeBanner';
import SidebarCategory from './components/SidebarCategory';
import TeamSwitcher from './components/TeamSwitcher';
import UserSettings from './components/UserSettings';

const SidebarContents = ({ sidebar, collapsed, setCollapsed, isMobile, subSidebar, offset }) => {
  const store = new ls(sidebar.context);
  const [collapsedState, setCollapsedState] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [teamSwitcherIsOpen, setTeamSwitcherIsOpen] = useState(false);

  useEffect(() => {
    if (!store.get('collapsedState')) {
      const emptyArray = new Array(Object.keys(sidebar.menu).length).fill(0);
      setCollapsedState(emptyArray);
      store.set('collapsedState', emptyArray);
    } else {
      setCollapsedState(store.get('collapsedState'));
    }
  }, []);

  const SubSidebar = () => {
    return (
      <div
        className={`dib v-top`}
        style={{
          background: '#35617b',
          color: '#fff',
          width: '150px',
          height: '100vh',
          top: 0,
          left: collapsed ? '-150px' : '230px',
        }}
      >
        <div style={{ fontSize: '20px', marginTop: '28px', marginLeft: '16px' }}>
          {`Team${sidebar.current_user?.is_admin ? ` #${sidebar.current_team.id}` : ''}`}
        </div>
        <div style={{ marginLeft: '16px', marginTop: '30px' }}>
          {subSidebar.map((t, index) => {
            return (
              <a
                key={index}
                style={{
                  display: 'block',
                  fontSize: '14px',
                  textDecoration: 'none',
                  color: '#f7f7f7',
                  marginBottom: '10px',
                }}
                href={t[1]}
              >
                <div style={{ height: '18px' }}>{t[0]}</div>
              </a>
            );
          })}
        </div>
      </div>
    );
  };

  const renderClose = () => {
    return (
      <div style={{ margin: '30px 0 30px 20px' }}>
        <img
          onClick={() => setCollapsed(true)}
          src={close}
          style={{ cursor: 'pointer', width: '24px' }}
        />
      </div>
    );
  };

  const renderSidebarTab = () => {
    return (
      <div
        className={`transition-left`}
        onClick={() => setCollapsed(!collapsed)}
        style={{ position: 'fixed', height: '0px', left: collapsed ? 0 : `${offset}px`, top: 0 }}
      >
        <div
          className={`bg-washed-blue br dim b--blue`}
          style={{ cursor: 'pointer', height: '100vh', width: '12px' }}
        >
          <div style={{ padding: '0 2px', position: 'relative', top: 'calc(50% - 48px)' }}>
            {new Array(4).fill('').map((_, index) => (
              <img
                key={index}
                style={{ transform: collapsed ? 'scale(-1, 1)' : '' }}
                className={'db mb3'}
                src={left}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  if (!collapsedState) {
    return null;
  }

  return (
    <div>
      <div
        style={{
          width: `${offset}px`,
          height: '100vh',
          background: '#073C52',
          zIndex: isMobile || teamSwitcherIsOpen ? 999 : 'auto',
          left: collapsed ? `-${offset}px` : 0,
          top: 0,
          bottom: 0,
        }}
        className={`fixed sidebar-container transition-left`}
      >
        <div style={{ width: '210px', height: '100vh' }} className={`dib`}>
          {!isMobile && renderSidebarTab()}
          {isMobile && renderClose()}
          <TeamSwitcher
            open={teamSwitcherIsOpen}
            setOpen={setTeamSwitcherIsOpen}
            teams={sidebar.teams}
            currentTeam={sidebar.current_team.name}
          />
          <div
            className={`sidebar-tab-menu`}
            style={{
              width: '100%',
              maxHeight: `calc(100vh - 120px)`,
              overflowY: 'auto',
              padding: '0 16px 0 16px',
            }}
          >
            {Object.keys(sidebar.menu).map((cat, idx) => {
              return (
                <SidebarCategory
                  context={sidebar.context}
                  key={cat}
                  index={idx}
                  name={cat}
                  collapsed={collapsedState[idx]}
                  category={sidebar.menu[cat]}
                />
              );
            })}
          </div>
          {!!sidebar.current_user && (
            <UserSettings
              sidebar={sidebar}
              settingsOpen={settingsOpen}
              setSettingsOpen={setSettingsOpen}
            />
          )}
        </div>
        {!!subSidebar && <SubSidebar />}
      </div>
    </div>
  );
};

const Sidebar = props => {
  const [isMobile, setIsMobile] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  let offset = !!props.subSidebar ? 360 : 222;

  if (collapsed) {
    offset = 12;
  }

  if (isMobile) {
    offset = 0;
  }

  let baseOffset = !!props.subSidebar ? 360 : 210;
  const size = useWindowSize();
  useEffect(() => {
    const current = size.width < 1195;
    setIsMobile(current);
    setCollapsed(current);
  }, [size.width]);

  useEffect(() => {
    if (props.collapsed) {
      setCollapsed(props.collapsed);
    }
  }, [props.collapsed]);

  const getInner = html => {
    return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  };

  if (isMobile === null) {
    return null;
  }

  return (
    <div className={``} data-testid="shared-sidebar">
      <SidebarContents
        {...props}
        offset={baseOffset}
        isMobile={isMobile}
        setCollapsed={setCollapsed}
        collapsed={collapsed}
      />
      <div
        className={`v-top transition-body`}
        style={{ marginLeft: `${offset}px`, width: `calc(100% - ${offset}px)` }}
      >
        {isMobile && (
          <div style={{ margin: '0 0 0 20px' }}>
            <img
              onClick={() => setCollapsed(false)}
              src={burger}
              style={{ marginTop: '30px', cursor: 'pointer', width: '32px' }}
            />
          </div>
        )}
        {props.readOnlyMode && <ReadOnlyModeBanner />}
        {props.message && getInner(props.message)}
        {!!props.__html && getInner(props.__html)}
        {!!props.children && props.children}
      </div>
    </div>
  );
};

export default Sidebar;
