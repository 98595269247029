import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  createReport,
  getConsumptionSeries,
  getDownloadsHistory,
  getPacingHistory,
  getPlaysHistory,
  getPodcast,
  getTeam,
} from 'shared/api';
import {
  getPodcasts,
  getTeamDownloadHistory,
  getTeamPlayHistory,
  getTeamStatistics,
} from 'shared/api/v1';
import { getFlooredUtcDateTime } from 'shared/helpers';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';
import Button from 'shared/Button';
import UserContext from 'shared/UserContext';

import 'react-table/react-table.css';
import DashboardOverviewView from './components/DashboardOverviewView';

const DeprecationWelcomePage = props => {
  return (
    <UserContext.Provider value={props.userInfo}>
      <Sidebar message={props.message} sidebar={props.sidebar}>
        <div className="flex align-center justify-center">
          <div className="pa3 ma3 mt5 w6 card">
            <div className="mb4 f4 tc blue">Welcome to Chartable</div>
            {props.userInfo.hasMegaphoneBundle ? (
              <div className="flex w-100 gap-large">
                <a
                  className="flex-grow no-underline"
                  href={`/teams/${props.teamId}/dashboard/promos`}
                >
                  <Button type="primary">
                    <div className="pa4">SmartPromos</div>
                  </Button>
                </a>
                <a
                  className="flex-grow no-underline"
                  href={`/teams/${props.teamId}/dashboard/links`}
                >
                  <Button type="primary">
                    <div className="pa4">SmartLinks</div>
                  </Button>
                </a>
              </div>
            ) : (
              <a className="flex-grow no-underline" href={`/teams/${props.teamId}/dashboard/adops`}>
                <div className="f black o-90 mb3">
                  This team has AdOps-only access. With this access, you can approve or deny
                  SmartPromo requests from Megaphone-hosted partners in the AdOps tab.
                </div>
                <Button type="primary">
                  <div className="pa4">AdOps</div>
                </Button>
              </a>
            )}
          </div>
        </div>
      </Sidebar>
    </UserContext.Provider>
  );
};

export default DeprecationWelcomePage;

// Below is the legacy DashboardOverview code deprecated 12/12/2024.
// To re-instate it you can switch the default export, however, many of the
// routes it uses are now deprecated and restricted as well.

class DashboardOverview extends Component {
  constructor(props) {
    super(props);

    this.handleGetTeam = this.handleGetTeam.bind(this);
    this.handleGetPodcast = this.handleGetPodcast.bind(this);
    this.handleGetPodcasts = this.handleGetPodcasts.bind(this);
    this.handleGetDownloadsHistory = this.handleGetDownloadsHistory.bind(this);
    this.handleGetConsumptionSeries = this.handleGetConsumptionSeries.bind(this);
    this.handleGetPlaysHistory = this.handleGetPlaysHistory.bind(this);
    this.handleChangeDownloadsEndDate = this.handleChangeDownloadsEndDate.bind(this);
    this.handleChangeDownloadsStartDate = this.handleChangeDownloadsStartDate.bind(this);
    this.handleChangePlaysEndDate = this.handleChangePlaysEndDate.bind(this);
    this.handleChangePlaysStartDate = this.handleChangePlaysStartDate.bind(this);
    this.updatePodcastTablePage = this.updatePodcastTablePage.bind(this);
    this.updatePodcastTableSort = this.updatePodcastTableSort.bind(this);
    this.handleCSVExport = this.handleCSVExport.bind(this);

    const initialStartDate = moment().subtract(3, 'months').toDate();
    const initialEndDate = new Date();
    this.state = {
      team: null,
      podcast: null,
      podcasts: null,
      loadingDownloadsHistory: false,
      downloadsHistory: null,
      downloadsHistoryAggregation: null,
      consumptionSeries: null,
      loadingConsumptionSeries: false,
      loadingPlaysHistory: false,
      playsHistory: null,
      playsHistoryAggregation: null,
      loadingDemographics: null,
      pacingHistory: null,
      downloadsStartDate: initialStartDate,
      downloadsEndDate: initialEndDate,
      playsStartDate: initialStartDate,
      playsEndDate: initialEndDate,
      podcastTablePage: 1,
      totalPodcasts: 0,
      podcastTableSortBy: 'downloads',
      sortDesc: true,
      tableSortLoadInProgress: false,
      trailingStats: null,
      pacingHistoryLoadStatus: 'notStarted',
    };
  }

  componentDidMount() {
    const { podcastId } = this.props;

    this.handleGetTeam();

    if (podcastId) {
      this.handleGetPodcast();
    } else {
      this.handleGetPodcasts();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { podcast, team } = this.state;

    const { podcastId, userInfo } = this.props;

    let statistics = null;
    if (podcastId && !prevState.podcast && podcast) {
      statistics = podcast.statistics;
    } else if (this.props.teamId && !prevState.team && team) {
      statistics = team.statistics;
    }

    if (
      this.state.podcastTablePage !== prevState.podcastTablePage ||
      this.state.sortDesc !== prevState.sortDesc ||
      this.state.podcastTableSortBy !== prevState.podcastTableSortBy
    ) {
      this.handleGetPodcasts();
    }

    if (statistics === null) return;

    if (statistics.trailing.downloads['60']) {
      this.handleGetDownloadsHistory({ numDays: 60, aggregation: 'daily' });
      if (podcastId && this.state.pacingHistoryLoadStatus === 'notStarted') {
        this.handleGetPacingHistory();
      }
    }
    if (userInfo.showSpotify) {
      this.handleGetPlaysHistory({ numDays: 60, aggregation: 'daily' });
      this.handleGetConsumptionSeries();
    }
  }

  async handleGetTeam() {
    try {
      const { reportingWindow } = this.props.userInfo;
      const res = await getTeamStatistics();
      this.setState({ trailingStats: res.data[reportingWindow] });
    } catch (_) {}

    getTeam({ id: this.props.teamHashedId, teamId: this.props.teamId }).then(res => {
      this.setState({
        team: res.data,
      });
    });
  }

  handleGetPodcast() {
    getPodcast({ ...this.props, include_last_episode: true, include_chart_positions: true }).then(
      res => {
        this.setState({
          podcast: res.data,
        });
      },
    );
  }

  async handleGetPodcasts() {
    if (this.state.tableSortLoadInProgress) return;
    this.setState({ tableSortLoadInProgress: true });

    const { reportingWindow } = this.props;

    const { podcastTablePage, podcastTableSortBy, sortDesc } = this.state;

    try {
      const res = await getPodcasts({
        pageSize: 10,
        sortBy: podcastTableSortBy,
        page: podcastTablePage,
        sortDesc,
        reportingWindow,
      });

      this.setState({
        podcasts: res.data.data,
        totalPodcasts: res.data.metadata.total,
      });
    } catch (_) {}

    this.setState({ tableSortLoadInProgress: false });
  }

  handleChangeDownloadsStartDate(date) {
    this.handleGetDownloadsHistory({ startDate: date });
  }

  handleChangeDownloadsEndDate(date) {
    this.handleGetDownloadsHistory({ endDate: date });
  }

  handleChangePlaysEndDate(date) {
    this.handleGetPlaysHistory({ endDate: date });
  }

  handleChangePlaysStartDate(date) {
    this.handleGetPlaysHistory({ startDate: date });
  }

  async handleGetPlaysHistory({ startDate, endDate, aggregation }) {
    const selectedStartDate = startDate || this.state.playsStartDate;
    const selectedEndDate = endDate || this.state.playsEndDate;

    const { loadingPlaysHistory, playsHistoryAggregation } = this.state;
    if (loadingPlaysHistory) return;
    this.setState({ loadingPlaysHistory: true });

    const { teamId, podcastId, episodeId } = this.props;

    const params = {
      startDate: selectedStartDate ? getFlooredUtcDateTime(selectedStartDate) : null,
      endDate: selectedEndDate ? getFlooredUtcDateTime(selectedEndDate) : null,
      aggregation: aggregation || playsHistoryAggregation,
    };

    try {
      let res;
      if (podcastId) {
        res = await getPlaysHistory({
          ...params,
          podcastId,
          episodeId,
          teamId,
        });
      } else {
        res = await getTeamPlayHistory({ ...params });
      }

      this.setState({
        playsHistory: res.data,
        loadingPlaysHistory: false,
        playsStartDate: selectedStartDate,
        playsEndDate: selectedEndDate,
        playsHistoryAggregation: aggregation || playsHistoryAggregation,
      });
    } catch (_) {}
  }

  handleGetConsumptionSeries() {
    const { loadingConsumptionSeries } = this.state;
    if (loadingConsumptionSeries) return;

    this.setState({ loadingConsumptionSeries: true });
    getConsumptionSeries({ teamId: this.props.teamId, podcastId: this.props.podcastId }).then(
      res => {
        this.setState({
          loadingConsumptionSeries: false,
          consumptionSeries: res.data,
        });
      },
    );
  }

  async handleGetDownloadsHistory({ startDate, endDate, aggregation }) {
    const selectedStartDate = startDate || this.state.downloadsStartDate;
    const selectedEndDate = endDate || this.state.downloadsEndDate;

    const { loadingDownloadsHistory, downloadsHistoryAggregation } = this.state;

    const { podcastId, teamId } = this.props;

    if (loadingDownloadsHistory) return;
    this.setState({ loadingDownloadsHistory: true });

    const params = {
      startDate: selectedStartDate ? getFlooredUtcDateTime(selectedStartDate) : null,
      endDate: selectedEndDate ? getFlooredUtcDateTime(selectedEndDate) : null,
      aggregation: aggregation || downloadsHistoryAggregation,
    };

    try {
      let res;

      if (podcastId) {
        res = await getDownloadsHistory({ ...params, podcastId, teamId });
      } else {
        res = await getTeamDownloadHistory({ ...params });
      }

      this.setState({
        downloadsHistory: res.data,
        loadingDownloadsHistory: false,
        downloadsHistoryAggregation: aggregation || downloadsHistoryAggregation,
        downloadsStartDate: selectedStartDate,
        downloadsEndDate: selectedEndDate,
      });
    } catch (_) {}
  }

  handleGetPacingHistory() {
    this.setState({ pacingHistoryLoadStatus: 'loading' });
    getPacingHistory({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
    }).then(res => {
      this.setState({ pacingHistory: res.data, pacingHistoryLoadStatus: 'finished' });
    });
  }

  updatePodcastTablePage(page) {
    this.setState({ podcastTablePage: page });
  }

  updatePodcastTableSort(column, desc) {
    this.setState({ podcastTableSortBy: column, sortDesc: desc });
  }

  handleCSVExport() {
    const { teamId } = this.props;
    const podcastId = this.state.podcast ? this.state.podcast.id : null;

    // TODO - Move this to report center
    createReport({
      teamId: teamId,
      sendEmailOnComplete: true,
      podcastId: podcastId,
      reportType: 'PODCAST_OVERVIEW_REPORT',
    });
  }

  render() {
    const {
      team,
      podcast,
      podcasts,
      totalPodcasts,
      downloadsHistory,
      downloadsHistoryNumDays,
      downloadsHistoryAggregation,
      loadingDownloadsHistory,
      consumptionSeries,
      loadingPlaysHistory,
      playsHistory,
      playsHistoryNumDays,
      playsHistoryAggregation,
      loadingDemographics,
      downloadsStartDate,
      downloadsEndDate,
      playsStartDate,
      playsEndDate,
      pacingHistory,
      tableSortLoadInProgress,
      podcastTablePage,
    } = this.state;

    const { podcastsCount } = this.props;

    return (
      <UserContext.Provider value={this.props.userInfo}>
        <Sidebar message={this.props.message} sidebar={this.props.sidebar}>
          {!team && <Loading fullScreen />}
          {team && (
            <DashboardOverviewView
              team={team}
              podcast={podcast}
              podcasts={podcasts}
              podcastsCount={podcastsCount}
              totalPodcasts={totalPodcasts}
              podcastTablePage={podcastTablePage}
              tableSortLoadInProgress={tableSortLoadInProgress}
              updatePodcastTablePage={this.updatePodcastTablePage}
              updatePodcastTableSort={this.updatePodcastTableSort}
              downloadsHistory={downloadsHistory}
              downloadsHistoryAggregation={downloadsHistoryAggregation}
              downloadsHistoryNumDays={downloadsHistoryNumDays}
              onGetDownloadsHistory={this.handleGetDownloadsHistory}
              loadingDownloadsHistory={loadingDownloadsHistory}
              playsHistory={playsHistory}
              onChangeDownloadsEndDate={this.handleChangeDownloadsEndDate}
              onChangeDownloadsStartDate={this.handleChangeDownloadsStartDate}
              onChangePlaysEndDate={this.handleChangePlaysEndDate}
              onChangePlaysStartDate={this.handleChangePlaysStartDate}
              loadingPlaysHistory={loadingPlaysHistory}
              playsHistoryAggregation={playsHistoryAggregation}
              playsHistoryNumDays={playsHistoryNumDays}
              onGetPlaysHistory={this.handleGetPlaysHistory}
              consumptionSeries={consumptionSeries}
              loadingDemographics={loadingDemographics}
              playsStartDate={playsStartDate}
              playsEndDate={playsEndDate}
              downloadsStartDate={downloadsStartDate}
              downloadsEndDate={downloadsEndDate}
              pacingHistory={pacingHistory}
              planId={this.props.planId}
              showDownloadWarning={this.props.showDownloadWarning}
              handleCSVExport={this.handleCSVExport}
              trailingStats={this.state.trailingStats}
              reportingWindow={this.props.userInfo.reportingWindow}
            />
          )}
        </Sidebar>
      </UserContext.Provider>
    );
  }
}

DashboardOverview.propTypes = {
  teamHashedId: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  podcastId: PropTypes.string,
  showDownloadWarning: PropTypes.bool,
  planId: PropTypes.string,
};

DashboardOverview.defaultProps = {
  podcastId: null,
  planId: 'hobby_monthly',
  showDownloadWarning: false,
};
