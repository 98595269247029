import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import qs from 'qs';
import moment from 'moment';

import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';

import {
  getTrackingLinks,
  getTrackingLink,
  getLinksSummary,
  getCompareClicksHistory,
  getCompareDownloadsHistory,
} from 'shared/api';

import DashboardLinksCompareView from './components/DashboardLinksCompareView';

export default class DashboardLinksCompare extends Component {
  constructor(props) {
    super(props);

    this.handleChangeLink = this.handleChangeLink.bind(this);
    this.handleGetDownloadsHistory = this.handleGetDownloadsHistory.bind(this);
    this.handleChangeClicksEndDate = this.handleChangeClicksEndDate.bind(this);
    this.handleChangeClicksStartDate = this.handleChangeClicksStartDate.bind(this);
    this.handleChangeDownloadsEndDate = this.handleChangeDownloadsEndDate.bind(this);
    this.handleChangeDownloadsStartDate = this.handleChangeDownloadsStartDate.bind(this);
    this.handleGetClicksHistory = this.handleGetClicksHistory.bind(this);
    this.handleGetDownloadsHistory = this.handleGetDownloadsHistory.bind(this);

    this.state = {
      linkOne: null,
      linkTwo: null,
      loading: false,
      loadingData: false,
      loadingClicksHistory: false,
      clicksHistory: null,
      downloadsHistory: null,
      playersData: null,
      clicksHistoryAggregation: 'Daily',
      clicksStartDate: moment().subtract(3, 'months').toDate(),
      clicksEndDate: new Date(),
      downloadsHistoryAggregation: 'Daily',
      downloadsStartDate: moment().subtract(3, 'months').toDate(),
      downloadsEndDate: new Date(),
    };
  }

  componentDidMount() {
    const { links, link } = this.props;

    if (link) {
      this.setState({
        linkOne: link,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      linkOne,
      linkTwo,
      loadingData,
      clicksStartDate,
      clicksEndDate,
      clicksHistoryAggregation,
      downloadsStartDate,
      downloadsEndDate,
      downloadsHistoryAggregation,
      loadingClicksHistory,
    } = this.state;

    if (prevState.linkOne !== linkOne || prevState.linkTwo !== linkTwo) {
      this.handleGetClicksHistory(clicksStartDate, clicksEndDate, clicksHistoryAggregation);
      this.handleGetDownloadsHistory(
        downloadsStartDate,
        downloadsEndDate,
        downloadsHistoryAggregation,
      );
    }

    if (loadingClicksHistory && !loadingData) {
      this.setState({
        loadingData: true,
      });
    }
    if (!loadingClicksHistory && loadingData) {
      this.setState({
        loadingData: false,
      });
    }
  }

  handleChangeLink(linkOne, linkTwo) {
    if (this.state.loadingData) return;

    if (linkOne) {
      this.setState({
        linkOne,
      });
    }
    if (linkTwo) {
      this.setState({
        linkTwo,
      });
    }
  }

  handleGetDownloadsHistory({ startDate, endDate, aggregation }) {
    const selectedStartDate = startDate == null ? this.state.downloadsStartDate : startDate;
    const selectedEndDate = endDate == null ? this.state.downloadsEndDate : endDate;
    const { loadingDownloadsHistory, downloadsHistoryAggregation } = this.state;

    const linkData = {
      teamId: this.props.teamId,
      links: [this.state.linkOne.id],
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      aggregation: aggregation || downloadsHistoryAggregation,
    };

    if (this.state.linkTwo) {
      linkData['links'].push(this.state.linkTwo.id);
    }

    if (loadingDownloadsHistory) return;
    this.setState({ loadingDownloadsHistory: true, downloadsHistory: null });

    getCompareDownloadsHistory(linkData).then(res => {
      this.setState({
        downloadsHistory: res.data,
        downloadsStartDate: selectedStartDate,
        downloadsEndDate: selectedEndDate,
        loadingDownloadsHistory: false,
        downloadsHistoryAggregation: aggregation || downloadsHistoryAggregation,
      });
    });
  }

  handleGetClicksHistory({ startDate, endDate, aggregation }) {
    const selectedStartDate = startDate == null ? this.state.clicksStartDate : startDate;
    const selectedEndDate = endDate == null ? this.state.clicksEndDate : endDate;
    const { loadingClicksHistory, clicksHistoryAggregation } = this.state;

    const linkData = {
      teamId: this.props.teamId,
      links: [this.state.linkOne.id],
      startDate: selectedStartDate,
      endDate: selectedEndDate,
      aggregation: aggregation || clicksHistoryAggregation,
    };

    if (this.state.linkTwo) {
      linkData['links'].push(this.state.linkTwo.id);
    }

    if (loadingClicksHistory) return;
    this.setState({ loadingClicksHistory: true, clicksHistory: null });

    getCompareClicksHistory(linkData).then(res => {
      this.setState({
        clicksHistory: res.data,
        clicksStartDate: selectedStartDate,
        clicksEndDate: selectedEndDate,
        loadingClicksHistory: false,
        clicksHistoryAggregation: aggregation || clicksHistoryAggregation,
      });
    });
  }

  handleChangeClicksStartDate(date) {
    this.handleGetClicksHistory({ startDate: date });
  }

  handleChangeClicksEndDate(date) {
    this.handleGetClicksHistory({ endDate: date });
  }

  handleChangeDownloadsStartDate(date) {
    this.handleGetDownloadsHistory({ startDate: date });
  }

  handleChangeDownloadsEndDate(date) {
    this.handleGetDownloadsHistory({ endDate: date });
  }

  pushHistory() {
    const queryString = qs.stringify({
      link_ids: this.linkIds() || undefined,
    });
    this.props.history.push(`${this.props.match.url}?${queryString}`);
  }

  linksIds() {
    const { linkOne, linkTwo } = this.state;
    const ret = [];
    if (linkOne) ret.push(linkOne.id);
    if (linkTwo) ret.push(linkTwo.id);
    return ret;
  }

  render() {
    const {
      loading,
      linkOne,
      linkTwo,
      clicksHistory,
      downloadsHistory,
      playersData,
      clicksHistoryAggregation,
      clicksStartDate,
      clicksEndDate,
      downloadsHistoryAggregation,
      downloadsStartDate,
      downloadsEndDate,
    } = this.state;

    const { teamId, links, sidebar } = this.props;

    if (loading) {
      return <Loading fullScreen />;
    }

    return (
      <Sidebar sidebar={sidebar}>
        <DashboardLinksCompareView
          teamId={teamId}
          links={links}
          linkOne={linkOne}
          linkTwo={linkTwo}
          onChangeLink={this.handleChangeLink}
          clicksHistory={clicksHistory}
          downloadsHistory={downloadsHistory}
          playersData={playersData}
          clicksHistoryAggregation={clicksHistoryAggregation}
          clicksStartDate={clicksStartDate}
          clicksEndDate={clicksEndDate}
          onChangeClicksEndDate={this.handleChangeClicksEndDate}
          onChangeClicksStartDate={this.handleChangeClicksStartDate}
          downloadsStartDate={downloadsStartDate}
          downloadsEndDate={downloadsEndDate}
          downloadsHistoryAggregation={downloadsHistoryAggregation}
          onChangeDownloadsEndDate={this.handleChangeDownloadsEndDate}
          onChangeDownloadsStartDate={this.handleChangeDownloadsStartDate}
          onGetClicksHistory={this.handleGetClicksHistory}
          onGetDownloadsHistory={this.handleGetDownloadsHistory}
        />
      </Sidebar>
    );
  }
}

DashboardLinksCompare.propTypes = {
  teamId: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  link: PropTypes.object,
};

DashboardLinksCompare.defaultProps = {
  link: null,
};
