import React, { useState } from 'react';
import { adminCreatePartner } from 'shared/api';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import PodcastSearchInput from 'shared/PodcastSearchInput';

const CreatePartnershipForm = props => {
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [publisherSlug, setPublisherSlug] = useState('');
  const [partnerSlug, setPartnerSlug] = useState('');
  const [podcast, setPodcast] = useState(null);

  const [errors, setErrors] = useState(null);

  const handleCreatePartnership = () => {
    setRequestInProgress(true);
    adminCreatePartner({ teamSlug: publisherSlug, podcastSlug: podcast.slug, partnerSlug })
      .then(res => window.location.assign('/custom_admin/react/partners'))
      .catch(errors => {
        setErrors(
          errors.response.data.errors
            ? errors.response.data.errors
            : { strange: 'An unknown error has occurred' },
        );
        setRequestInProgress(false);
      });
  };

  return (
    <div className="mw6 pa4">
      {!!errors &&
        Object.keys(errors).map(error => (
          <Banner key={error} type="error" className="mv2">
            <b className="ttc">{error}</b>: {errors[error]}
          </Banner>
        ))}
      <div className="f5 mb2 mt4 dark-gray">Team (Publisher) Slug</div>
      <div className="f6 mid-gray mb2">The slug of the publisher team who owns the podcast</div>
      <input
        value={publisherSlug}
        className="pa2 input-reset ba bg-white br2 b--silver w-100"
        type="text"
        placeholder="chartable-radio"
        onChange={e => setPublisherSlug(e.target.value)}
      />
      <div className="f5 mb2 mt4 dark-gray">Partner (Agency) Slug</div>
      <div className="f6 mid-gray mb2">The slug of the partner team who can access the podcast</div>
      <input
        value={partnerSlug}
        className="pa2 input-reset ba bg-white br2 b--silver w-100"
        type="text"
        placeholder="the-agency"
        onChange={e => setPartnerSlug(e.target.value)}
      />
      <div className="f5 mb2 mt4 dark-gray">Podcast</div>
      <div className="f6 mid-gray mb2">
        The podcast that the PUBLISHER TEAM OWNS that they want the PARTNER TEAM to have access to
      </div>
      <PodcastSearchInput
        overrideTeamId="chartable-radio"
        onSelect={setPodcast}
        selectedPodcast={podcast}
        scopeToTeamPodcasts={false}
        maxPodcastsToFetch={20}
      />
      <Button
        className="mt4"
        type="primary"
        disabled={!partnerSlug || !podcast || !publisherSlug || requestInProgress}
        loading={requestInProgress}
        onClick={handleCreatePartnership}
      >
        Create Partnership
      </Button>
    </div>
  );
};

export default CreatePartnershipForm;
