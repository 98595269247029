import React, { Component } from 'react';
import Modal from 'react-modal';

const Confirmation = ({ setConfirming, createKey }) => {
  const renderContents = () => {
    return (
      <div style={{ marginBottom: '-6px' }}>
        <p className="mb3">
          Creating a new key will deactivate your current key! Are you sure you want to do this?
        </p>
        <div className="tc">
          <div
            className="pointer dim br2 dib tc ba f6 pa2 w3 bg-red white b--red mt1"
            onClick={() => setConfirming(false)}
          >
            Cancel
          </div>
          <div
            className="pointer dim br2 dib tc ba f6 pa2 w3 bg-blue white b--blue mt1 ml1"
            onClick={createKey}
          >
            Create
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="">
      <Modal
        shouldCloseOnOverlayClick
        isOpen
        onRequestClose={() => {
          setConfirming(false);
        }}
        ariaHideApp={false}
        style={{
          overlay: { zIndex: 1 },
          content: {
            outline: 'none',
            maxWidth: '410px',
            height: '190px',
            paddingTop: '0px',
            margin: '100px auto 0 auto',
          },
        }}
      >
        <h2 className="tc">Warning!</h2>
        {renderContents()}
      </Modal>
    </div>
  );
};

export default Confirmation;
