import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { useParams } from 'react-router-dom';

import { getTrackingLink, searchTrackingLinks } from 'shared/api/v1';

const TrackingLinkSearchInput = ({ onSelect, initialTrackingLinkId, className }) => {
  const { teamId } = useParams();
  const [trackingLink, setTrackingLink] = useState(null);
  const [loadingInitialTrackingLink, setLoadingInitialTrackingLink] = useState(false);

  const formatOptions = options => {
    return options.map(option => ({
      ...option,
      value: option.id,
      label: option.name,
    }));
  };

  const loadOptions = (query, callback) => {
    if (query.length < 3) return;
    searchTrackingLinks({ query })
      .then(res => {
        const options = formatOptions(res.data);
        callback(options);
      })
      .catch(() => callback([]));
  };

  const handleSelection = selection => {
    onSelect(selection);
    setTrackingLink(selection);
  };

  useEffect(() => {
    if (initialTrackingLinkId) {
      setLoadingInitialTrackingLink(true);
      getTrackingLink({
        trackingLinkId: initialTrackingLinkId,
        sourceId: null,
        // We don't actually need any data so we pass in a small date range
        startDate: new Date(),
        endDate: new Date(),
      })
        .then(res => {
          const trackingLink = formatOptions([res.data])[0];
          handleSelection(trackingLink);
        })
        .catch(() => {})
        .finally(() => setLoadingInitialTrackingLink(false));
    }
  }, []);

  return (
    <div className={`${className} mw-100`}>
      <AsyncSelect
        value={trackingLink}
        onChange={handleSelection}
        isDisabled={loadingInitialTrackingLink}
        isLoading={loadingInitialTrackingLink}
        loadOptions={(query, callback) => loadOptions(query, callback)}
        noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Type to search' : 'No results')}
      />
    </div>
  );
};

TrackingLinkSearchInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
  initialTrackingLinkId: PropTypes.string,
  className: PropTypes.string,
};

TrackingLinkSearchInput.dfeaultProps = {
  initialTrackingLinkId: null,
  className: '',
};

export default TrackingLinkSearchInput;
