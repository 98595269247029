import React, { Component } from 'react';
import { Field, Form } from 'react-final-form';
import Modal from 'react-modal';
import { BarLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { createCredential, updateCredential } from 'shared/api';
import Sidebar from 'shared/Sidebar';

export default class CredentialsForm extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onReceived = this.onReceived.bind(this);

    this.state = {
      loading: false,
      socketHashId: this.props.socketHashId,
      credential: this.props.credential,
      initialValues: {
        ...this.props.credential,
        authenticityToken: document.head.querySelector('meta[name="csrf-token"]').content,
      },
    };
  }

  handleSubmit(params, form, callback) {
    const f = this.state.credential.id ? updateCredential : createCredential;
    const hashId = this.state.socketHashId || new Date().getTime();
    this.setState({ loading: true, socketHashId: hashId });
    f({
      id: this.state.credential.id,
      credential: params,
      teamId: this.props.teamId,
      hashId: hashId,
    }).then(res => {
      if (res.data.errors) {
        callback(res.data.errors);
        this.setState({ loading: false });
      } else {
        if (res.data.status === 'active') {
          window.location = this.props.successUrl;
        } else if (res.data.status === 'pending_invite') {
          window.location = `${this.props.successUrl}?credential_id=${res.data.id}`;
        }
        this.setState({ loading: false, credential: res.data });
        callback();
      }
    });
  }

  onReceived(message) {
    if (message.hash_id && message.hash_id === this.state.socketHashId) {
      if (message.credential) {
        this.setState({
          loading: false,
          credential: message.credential,
        });
      }
    }
  }

  render() {
    const {
      teamId,
      trackableUrl,
      accountTypes,
      blubrryRedirectURL,
      actionCableUrl,
      sidebar,
      inOnboarding,
      __html,
      showForm,
    } = this.props;

    const { credential, initialValues, loading } = this.state;

    const labelClass = 'db lh-copy f5 header-font select required';
    const inputClass = 'pa2 input-reset ba w-100 bg-white br2 b--silver string optional';

    // DZ: I am sure there is a more react-y way of doing this
    let usernameLabel = 'Username';
    if (credential.accountType == 'podcasts_connect_email') {
      usernameLabel = 'Account ID';
    }

    if (!showForm) {
      return <Sidebar sidebar={sidebar} __html={__html} />;
    }

    return (
      <Sidebar sidebar={sidebar} __html={__html}>
        <div className="mw7 ph3">
          {loading && (
            <div className="overlay">
              <div className="vh-100 dt w-100">
                <div className="dtc v-mid h-100 tc center">
                  <div className="w-20 center flex items-center flex-column">
                    <div className="pa4 b f4">Connecting...</div>
                    <BarLoader sizeUnit="px" size={150} color="#123abc" loading />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="f5 body-font lh-copy gray mb2">
            {credential.status === 'authentication_error' && (
              <div className="bg-washed-red br2 pa3">
                We were unable to verify these credentials, please check your username and password
                and try again.
                <br />
                To remove this credential, click the red "Delete" button at the bottom of the form.
              </div>
            )}
            {credential.status === 'active' && (
              <div>Connected&mdash;check back for your stats shortly.</div>
            )}
          </div>
          <div className="ba br2 pa2 w-100 b--primary bg-white b--moon-gray">
            <Form
              initialValues={initialValues}
              onSubmit={this.handleSubmit}
              render={({ handleSubmit, values, pristine }) => (
                <form onSubmit={handleSubmit}>
                  <Field component="input" type="hidden" name="authenticityToken" />
                  <div>
                    <Field name="username">
                      {({ input, meta }) => (
                        <div>
                          <label className={labelClass}>{usernameLabel}</label>
                          <input {...input} type="text" className={inputClass} autoFocus />
                          {meta.submitError && meta.touched && (
                            <span className="red">{meta.submitError}</span>
                          )}
                        </div>
                      )}
                    </Field>
                    {values.accountType != 'podcasts_connect_email' && (
                      <Field name="password">
                        {({ input, meta }) => (
                          <div className="mt3">
                            <label className={labelClass}>Password</label>
                            <input {...input} type="password" className={inputClass} />
                            {meta.submitError && meta.touched && (
                              <span className="red">{meta.submitError}</span>
                            )}
                          </div>
                        )}
                      </Field>
                    )}
                  </div>
                  <div className="mt3">
                    <button
                      type="submit"
                      className="ph3 pv2 input-reset bn white bg-blue pointer f5 dib dim w-100 br2 header-font"
                    >
                      {values.id ? 'Update' : 'Connect'}
                    </button>
                  </div>
                </form>
              )}
            />
            {credential.id && (
              <a href={`/credentials/${credential.id}`} data-method="delete" className="mt2">
                <button className="mt2 ph3 pv2 input-reset bn white bg-red pointer f5 dib dim w-100 br2 header-font">
                  Delete
                </button>
              </a>
            )}
          </div>
        </div>
      </Sidebar>
    );
  }
}

CredentialsForm.propTypes = {
  credential: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  accountType: PropTypes.string,
  accountTypes: PropTypes.object,
  trackableUrl: PropTypes.string,
  blubrryRedirectURL: PropTypes.string,
  actionCableUrl: PropTypes.string,
  inOnboarding: PropTypes.bool,
  socketHashId: PropTypes.string,
  showForm: PropTypes.bool,
};

CredentialsForm.defaultProps = {
  accountType: null,
  accountTypes: null,
  trackableUrl: null,
  blubrryRedirectURL: null,
  actionCableUrl: null,
  inOnboarding: false,
  showForm: true,
};
