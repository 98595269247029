/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import Select from 'react-select';
import { remarketingPixelPlatformKeys, remarketingPixelPlatforms } from 'shared/helpers';
import Loading from 'shared/Loading';

const RemarketingPixel = ({ platform, pixel, id, update, pixelOptions }) => {
  pixelOptions.unshift({ value: null, label: 'None' });
  return (
    <div className="pv3 bb b--light-gray">
      <div className="flex flex-row gap-small items-center mb3">
        <img
          alt="pixel-icon"
          src={remarketingPixelPlatforms[platform].icon}
          className="w-auto"
          style={{ height: '20px' }}
        />
        {remarketingPixelPlatforms[platform].label}
      </div>
      <Select
        value={pixel ? { label: pixel, value: pixel } : { value: null, label: 'None' }}
        onChange={({ value }) => update({ platform, pixel: value, id })}
        options={pixelOptions}
      />
    </div>
  );
};

const RemarketingPixels = props => {
  const { allRemarketingPixels, remarketingPixels, addRemarketingPixel } = props;

  const integrations = {
    facebook: null,
    google_tag_manager: null,
    tiktok: null,
  };

  remarketingPixels.forEach(rp => (integrations[rp.platform] = rp.pixel));

  const pixelsByPlatform = useMemo(() => {
    if (!allRemarketingPixels) return {};

    const pixels = {};
    remarketingPixelPlatformKeys.forEach(k => (pixels[k] = []));

    allRemarketingPixels.forEach(rp => pixels[rp.platform].push(rp.pixel));
    return pixels;
  }, [allRemarketingPixels]);

  const handleUpdate = integration => {
    integrations[integration.platform] = integration.pixel;
    addRemarketingPixel(
      Object.keys(integrations).map(k => ({ platform: k, pixel: integrations[k] })),
    );
  };

  if (!allRemarketingPixels) {
    return <Loading />;
  }

  return (
    <div>
      {Object.keys(integrations).map(key => (
        <RemarketingPixel
          platform={key}
          pixel={integrations[key]}
          key={key}
          pixelOptions={key ? pixelsByPlatform[key].map(p => ({ value: p, label: p })) : []}
          update={handleUpdate}
        />
      ))}
    </div>
  );
};

export default RemarketingPixels;
