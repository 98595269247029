import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import moment from 'moment';
import { getArchivedPromos, unarchivePromo } from 'shared/api';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import Header from 'shared/Header';
import PaginatedTable from 'shared/PaginatedTable';
import TopBar from 'shared/SmallTopBar';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

const ArchiveView = ({ clearManageTabState, selectedPodcast, match }) => {
  const readOnlyMode = useReadOnlyMode();

  const { audioAdCampaignId, teamId } = match.params;
  const pageSize = 20;

  const [promos, setPromos] = useState([]);

  const [nameQueryStringInputValue, setNameQueryStringInputValue] = useState('');
  const [nameQuery, setNameQuery] = useState('');
  const [sortDesc, setSortDesc] = useState(true);
  const [sortBy, setSortBy] = useState('archivedAt');
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [, setFailedToUnarchivePromo] = useState(false);

  const handleGetArchivedPromos = async () => {
    setLoading(true);

    try {
      const res = await getArchivedPromos({
        teamId,
        page,
        sortBy,
        sortDesc,
        pageSize,
        audioAdCampaignId,
        nameQuery,
      });

      const { data, metadata } = res.data;
      setTotal(metadata.total);
      setPromos(data);
    } catch (_) {
      setFailedToLoad(true);
    }

    setLoading(false);
  };

  const handleUnarchivePromo = async audioAdPlacementId => {
    setLoading(true);
    setFailedToUnarchivePromo(false);

    try {
      await unarchivePromo({ audioAdPlacementId, teamId });
      clearManageTabState();
      handleGetArchivedPromos();
    } catch (_) {
      setFailedToUnarchivePromo(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetArchivedPromos();
    // ignoring exhaustive deps until tests are written
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy, sortDesc, page, nameQuery]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        sortable: false,
        maxWidth: 80,
        Cell: row => (
          <Button
            type="primary"
            className="f7 w-100"
            size="small"
            disabled={readOnlyMode}
            onClick={() => handleUnarchivePromo(row.original.audioAdPlacementId)}
          >
            Unarchive
          </Button>
        ),
      },
      {
        Header: 'Promo Name',
        accessor: 'name',
      },
      {
        Header: 'Publisher',
        accessor: 'sellerTeam',
        Cell: row => <div>{row.original.sellerTeam ? row.original.sellerTeam : '-'}</div>,
      },
      {
        Header: 'Promoted On',
        id: 'sellerPodcast',
        accessor: 'sellerPodcast.shortTitle',
        sortable: false,
        minWidth: 150,
        Cell: row => (
          <div className="pointer flex items-center h-100 w-100">
            {row.original.sellerPodcast ? (
              <div className="flex items-center">
                {row.original.sellerPodcast.displayImageUrl && (
                  <div className="mr2 h2 w2">
                    <img
                      src={row.original.sellerPodcast.displayImageUrl}
                      className="center fl br2"
                      alt=""
                    />
                  </div>
                )}
                <div className="w-70">{row.original.sellerPodcast.shortTitle}</div>
              </div>
            ) : (
              <div className="i tc">Run of Network or Multiple</div>
            )}
          </div>
        ),
      },
      {
        Header: 'Archived At',
        id: 'archivedAt',
        accessor: 'archivedAt',
        Cell: row => (
          <div className="tc">{moment(row.original.archivedAt).format('MM/DD/YYYY')}</div>
        ),
        // ignoring exhaustive deps until tests are written
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
    ],
    [],
  );

  // ignoring exhaustive deps until tests are written
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTextSearch = useCallback(
    debounce(text => {
      setNameQuery(text);
    }, 300),
    [],
  );

  return (
    <div>
      <TopBar
        backText="Manage"
        backUrl={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/manage`}
        podcast={selectedPodcast}
      />
      <div className="pa4">
        <Header size="small" useNew className="mb2">
          Promo Archive
        </Header>
        <div className="pa2 card mv2">
          <div className="f6 dark-gray b mb1">Filter by name</div>
          <input
            className="input-reset ba b--black-20 pa2 db w-90"
            placeholder="Search by name"
            value={nameQueryStringInputValue}
            onChange={e => {
              setNameQueryStringInputValue(e.target.value);
              handleTextSearch(e.target.value);
            }}
          />
        </div>
        <PaginatedTable
          defaultSorted={[
            {
              id: 'archivedAt',
              desc: true,
            },
          ]}
          columns={columns}
          handlePaging={event => setPage(event.selected + 1)}
          handleSort={sort => {
            setSortDesc(sort[0].desc);
            setSortBy(sort[0].id);
          }}
          data={promos}
          page={page}
          total={total}
          pageSize={pageSize}
          loading={loading}
          showLoadingWhileDataIsNull
          failedToLoad={failedToLoad}
          failedToLoadMessage={
            <Banner error>
              <div className="f6">Failed to load archived promos</div>
            </Banner>
          }
          rowStyle={{
            display: 'flex',
            alignItems: 'center',
            minHeight: '2rem',
          }}
          tableClassName="w-100 f5"
        />
      </div>
    </div>
  );
};

export default ArchiveView;
