import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  createReport,
  getEpisodes,
  getEpisodesSummary,
  getEpisodesSync,
  updateTeamUserPreferences,
} from 'shared/api';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import ColumnSelector from 'shared/ColumnSelector';
import Loading from 'shared/Loading';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import Sidebar from 'shared/Sidebar';
import { withUser } from 'shared/UserContext';

import DashboardEpisodesBrowserView from './components/DashboardEpisodesBrowserView';

class DashboardEpisodesBrowser extends Component {
  constructor(props) {
    super(props);

    this.fetchEpisodesSummary = this.fetchEpisodesSummary.bind(this);
    this.handleLoadEpisodes = this.handleLoadEpisodes.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleUpdateSelectedColumns = this.handleUpdateSelectedColumns.bind(this);

    this.state = {
      summary: null,
      sort: null,
      episodes: null,
      page: null,
      pageSize: null,
      showColumnSelector: false,
      selectedColumns: this.props.selectedColumns,
      notificationBanner: null,
      tableSortLoadInProgress: false,
    };
  }

  componentDidMount() {
    this.fetchEpisodesSummary();
  }

  componentDidUpdate(_, prevState) {
    const { summary, pageSize } = this.state;

    if (summary && !prevState.summary) {
      this.setState({ pageSize: 30 });
    }

    if (pageSize && !prevState.pageSize) {
      this.handleLoadEpisodes(1, { publishedAt: 'desc' });
    }
  }

  handleLoadEpisodes(page, sort) {
    if (this.state.tableSortLoadInProgress) return;
    this.setState({ tableSortLoadInProgress: true });

    getEpisodes({
      sort: JSON.stringify(sort),
      page,
      pageSize: this.state.pageSize,
      ...this.props,
    })
      .then(res => {
        this.setState({
          episodes: res.data,
          page,
          sort,
        });
      })
      .finally(() => this.setState({ tableSortLoadInProgress: false }));
  }

  handleSort(sort) {
    this.handleLoadEpisodes(1, { [`${sort[0].id}`]: sort[0].desc ? 'desc' : 'asc' });
  }

  handleRefresh() {
    getEpisodesSync({
      teamId: this.props.teamId,
    }).then(res => {
      const message =
        res.data.status === 200
          ? "👍 We're fetching new artwork, RSS feeds, reviews, and episodes. This can take an hour or more. Please check back shortly!"
          : 'Please give us some time to sync all your data before refreshing 😉';
      this.setState({
        notificationBanner: <BannerNotice success message={message} />,
      });
    });
  }

  handleExport() {
    const { teamId, podcastId } = this.props;
    createReport({
      teamId: teamId,
      sendEmailOnComplete: true,
      podcastId: podcastId,
      reportType: 'EPISODES_OVERVIEW_REPORT',
    })
      .then(() => {
        this.setState({
          notificationBanner: (
            <BannerNotice
              success
              message={`Your report is being generated. We will send an email when its ready to be downloaded. `}
              onClose={() => this.setState({ notificationBanner: null })}
              actionText="Check report status"
              action={() => (window.location = `/teams/${teamId}/dashboard/reports`)}
            />
          ),
        });
      })
      .catch(err => {
        let message = 'Failed to create report. Please try again later or reach out to support.';
        if (err.response.status === '409') {
          message =
            'Your team already has a report of this type running. You must wait until that report completes before creating a new one';
        }

        this.setState({
          notificationBanner: (
            <BannerNotice
              error
              message={message}
              onClose={() => this.setState({ notificationBanner: null })}
              actionText="Check report status"
              action={() => (window.location = `/teams/${teamId}/dashboard/reports`)}
            />
          ),
        });
      })
      .finally(() => {
        this.setState({ requestInProgress: false });
      });
  }

  handleUpdateSelectedColumns(selectedColumns) {
    this.setState({
      selectedColumns: selectedColumns,
    });
    updateTeamUserPreferences({
      teamId: this.props.teamId,
      selectedEpisodeColumns: selectedColumns,
    }).then(() => {
      window.location.reload();
    });
  }

  fetchEpisodesSummary() {
    getEpisodesSummary({ ...this.props }).then(res => {
      this.setState({
        summary: res.data,
      });
    });
  }

  setPodcast = ({ type, data }) => {
    const urlBase = `/teams/${this.props.teamId}/dashboard/episodes`;

    if (type === 'all') {
      window.location = urlBase;
      return;
    }
    window.location = `${urlBase}?podcast_id=${data.id}`;
  };

  render() {
    const {
      summary,
      episodes,
      page,
      sort,
      pageSize,
      showColumnSelector,
      notificationBanner,
      tableSortLoadInProgress,
    } = this.state;

    const {
      podcastId,
      teamId,
      podcast,
      selectedColumns,
      allColumns,
      hasCustomizableColumns,
      podcastsCount,
      sidebar,
      userInfo,
    } = this.props;

    const manyPodcasts = podcastsCount > 1;

    return (
      <Sidebar sidebar={sidebar}>
        {!summary && <Loading fullScreen />}
        {summary && (
          <div>
            {notificationBanner}

            <div className="flex flex-wrap items-center justify-between ph3">
              <div style={{ minHeight: '30px' }} className="flex self-center justify-start mt3">
                {manyPodcasts && (
                  <PodcastSwitcher
                    teamId={teamId}
                    onSelect={this.setPodcast}
                    loading={false}
                    selectedPodcast={podcast}
                    defaultToNone={false}
                    defaultToAll={false}
                    includeNone={false}
                    includeAll
                    numberOfDefaultOptionsToFetch={podcastsCount}
                    className="w6 mb3"
                  />
                )}
              </div>
              <div className="flex flex-grow flex-wrap-m justify-end-ns tr">
                {hasCustomizableColumns && (
                  <Button
                    onClick={() => this.setState({ showColumnSelector: true })}
                    type="primary"
                    size="small"
                    className="ml3 w-29-m w-22 mr2"
                  >
                    Customize
                  </Button>
                )}
                <a
                  className="no-underline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/teams/${teamId}/dashboard/reports/create?product=Analytics&reportType=EPISODES_OVERVIEW_REPORT${
                    podcastId ? `&podcastId=${podcastId}` : ''
                  }`}
                >
                  <Button type="primary" size="small" className="w-29-m w-22 mr2">
                    Export All
                  </Button>
                </a>
                <Button
                  onClick={() => this.handleRefresh()}
                  type="primary"
                  size="small"
                  className="w-29-m w-22 mr2"
                >
                  Refresh
                </Button>
              </div>
            </div>
            <div className="pt3-m">
              <DashboardEpisodesBrowserView
                teamId={teamId}
                podcastId={podcastId}
                summary={summary}
                episodes={episodes}
                page={page}
                sort={sort}
                onLoadEpisodes={this.handleLoadEpisodes}
                pageSize={pageSize}
                onSort={this.handleSort}
                selectedColumns={selectedColumns}
                tableSortLoadInProgress={tableSortLoadInProgress}
                userInfo={userInfo}
              />
            </div>
            <BasicModal
              isOpen={showColumnSelector}
              onRequestClose={() => this.setState({ showColumnSelector: false })}
            >
              <ColumnSelector
                allColumns={allColumns}
                selectedColumns={selectedColumns}
                onSave={this.handleUpdateSelectedColumns}
                title="Customize episode columns"
              />
            </BasicModal>
          </div>
        )}
      </Sidebar>
    );
  }
}

DashboardEpisodesBrowser.propTypes = {
  podcastId: PropTypes.string,
  teamId: PropTypes.string.isRequired,
  allColumns: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  hasCustomizableColumns: PropTypes.bool.isRequired,
};

DashboardEpisodesBrowser.defaultProps = {
  podcastId: null,
};

export default withUser(DashboardEpisodesBrowser);
