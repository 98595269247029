import React, { useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';

import BasicModal from 'shared/BasicModal';
import SectionDivider from 'shared/SectionDivider';
import ReportCreator from 'shared/ReportCreator';
import ErrorBoundary from 'shared/ErrorBoundary';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import { publisherReports as reports } from 'shared/ReportCreator/definitions';

const PublisherReportCreator = ({ location, match }) => {
  const { teamId } = match.params;

  let initialValues = {};
  const urlParams = new URLSearchParams(location.search);

  urlParams.forEach((v, k) => {
    initialValues[k] = v;
  });

  const [product, setProduct] = useState(() => {
    if (!initialValues.product) return null;
    return Object.keys(reports).includes(initialValues.product) ? initialValues.product : null;
  });

  const [reportType, setReportType] = useState(() => {
    if (!initialValues.reportType || !product) return null;
    return !!reports[product].find(r => r.type === initialValues.reportType)
      ? initialValues.reportType
      : null;
  });

  const [showReportDescriptions, setShowReportDescriptions] = useState(false);
  const [showFailedBanner, setShowFailedBanner] = useState(false);

  const errorComponent = <Banner error>Could not get configuration for this report type.</Banner>;

  const productOptions = useMemo(
    () =>
      Object.keys(reports).map((k, idx) => (
        <div
          key={k}
          onClick={() => {
            if (product === k) return;
            setProduct(k);
            setReportType(null);
          }}
          className={`b--moon-gray dim pointer bg-white ba f5 near-black tc w4-5 pa3 ${
            product === k && 'white bg-blue b--blue'
          } ${idx === 0 && 'br2 br--left'} ${
            idx === Object.keys(reports).length - 1 && 'br2 br--right'
          }`}
        >
          {k}
        </div>
      )),
    [product],
  );

  const reportTypeOptions = useMemo(() => {
    if (!product) return null;
    return reports[product].map(({ label, type }, idx) => (
      <div
        key={type}
        onClick={() => setReportType(type)}
        className={`b--moon-gray dim pointer bg-white ba f5 near-black br2 tc w4-5 pa3 ${
          reportType === type && 'white bg-blue b--blue'
        } ${idx === 0 && 'br--left'} ${idx === reports[product].length - 1 && 'br--right'}`}
      >
        {label}
      </div>
    ));
  }, [product, reportType]);

  return (
    <div className="flex flex-column mw7 pb5 mt4">
      {showFailedBanner && (
        <Banner error className="mb4" onClick={() => setShowFailedBanner(false)}>
          <div>Failed to create report</div>
        </Banner>
      )}
      <div className="header-font mid-gray mb2">Select a Product</div>
      <div className="flex flex-row">{productOptions}</div>
      <SectionDivider />
      {product && (
        <React.Fragment>
          <div className="header-font mid-gray mb2">
            Select a Report Type{' '}
            <span
              onClick={() => setShowReportDescriptions(true)}
              className="pointer blue f6 link ml2"
            >
              Learn more
            </span>
          </div>
          <div className="flex flex-row">{reportTypeOptions}</div>
          <SectionDivider />
        </React.Fragment>
      )}
      {reportType && (
        <ErrorBoundary errorComponent={errorComponent}>
          <ReportCreator
            key={reportType}
            reportType={reportType}
            initialValues={initialValues}
            onCreate={() => setShowFailedBanner(false)}
            onSuccess={() => window.location.assign(`/teams/${teamId}/dashboard/reports`)}
            onFailure={() => setShowFailedBanner(true)}
          />
        </ErrorBoundary>
      )}
      {showReportDescriptions && (
        <BasicModal
          isOpen={showReportDescriptions}
          onRequestClose={() => setShowReportDescriptions(false)}
          ariaHideApp={false}
        >
          <div className="flex flex-column gap-large">
            {reports[product].map(({ label, description }) => (
              <div key={label}>
                <div className="f4 dark-gray b mb1">{label}</div>
                <div className="mid-gray lh-copy f5 mb3">{description}</div>
              </div>
            ))}
            <Button className="mt2" onClick={() => setShowReportDescriptions(false)}>
              Close
            </Button>
          </div>
        </BasicModal>
      )}
    </div>
  );
};

export default withRouter(PublisherReportCreator);
