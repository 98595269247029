import React from 'react';
import PropTypes from 'prop-types';

const Tag = ({ label, color, onClick }) => {
  return (
    <div
      onClick={onClick}
      className="b br4 f7 ttc pointer dim white"
      style={{ backgroundColor: color, padding: '0.075rem 0.45rem' }}
    >
      {label}
    </div>
  );
};

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Tag;
