import React from 'react';

import styles from '../styles.module.css';

const OnboardingStep = ({ children }) => {
  return (
    <div className={styles.onboardingStep}>
      <div>{children}</div>
    </div>
  );
};

export default OnboardingStep;
