import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

export default function TitleBar({ title, subtitle }) {
  return (
    <div className="w-50-l w-100 pa2-s pt2-ns">
      <ReactTooltip />
      <div className="f3 dark-blue mb2">
        {title || 'Downloads'}
        {subtitle && (
          <div className="f7 b gray ttu tracked dib pl2">
            <a data-tip={subtitle} className="ph1 ba b--gray br-100 tc pointer">
              &#63;
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
};

TitleBar.defaultProps = {
  subtitle: null,
};
