import React from 'react';
import PropTypes from 'prop-types';

const EmailPassForm = ({ renderMegaphoneButton, errors }) => {
  return (
    <div className="mb7">
      <div style={{ maxWidth: '400px' }}>{renderMegaphoneButton()}</div>
    </div>
  );
};

EmailPassForm.propTypes = {
  renderMegaphoneButton: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
};

export default EmailPassForm;
