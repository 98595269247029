/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
// FIXME - dangerous linting maneuvers postponed
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { onClickHandler } from 'shared/a11y/lib';
import {
  archivePromo,
  deletePromoRequest,
  fillPromoEpisode,
  getBuyerPromoRequests,
  getBuyerPromos,
  putPromo,
} from 'shared/api';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import EpisodeSelector from 'shared/EpisodeSelector';
import PaginatedTable from 'shared/PaginatedTable';
import TabBar, { TabBarItem } from 'shared/TabBar';
import TableErrorView from 'shared/TableErrorView';
import { withUser } from 'shared/UserContext';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import PixelManager from '../components/PixelManager';
import ErrorResolutionExplainer from './ErrorResolutionExplainer';
import PromoEditor from './PromoEditor';
import { getPromoRequestTableColumns, getPromoTableColumns } from './tableColumns.jsx';

function Manage({
  match,
  storeState,
  canEditPromos,
  newPromo,
  nullifyNewPromo,
  viewingPromoTable,
  initialState,
  clearAnalyticsTabState,
}) {
  const readOnlyMode = useReadOnlyMode();
  const { teamId, audioAdCampaignId } = match.params;
  const pageSize = 20;

  const [failedToLoadPromos, setFailedToLoadPromos] = useState(false);
  const [failedToLoadPromoRequests, setFailedToLoadPromoRequests] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Promo table state
  const [promos, setPromos] = useState(initialState ? initialState.promos : null);
  const [promoTablePage, setPromoTablePage] = useState(
    initialState ? initialState.promoTablePage : 1,
  );
  const [promoTableSortBy, setPromoTableSortBy] = useState(
    initialState ? initialState.promoTableSortBy : 'startedAt',
  );
  const [promoTableSortDesc, setPromoTableSortDesc] = useState(
    initialState ? initialState.promoTableSortDesc : true,
  );
  const [totalPromos, setTotalPromos] = useState(initialState ? initialState.totalPromos : 0);
  const [promoTableLoading, setPromoTableLoading] = useState(false);
  const [promoSearchString, setPromoSearchString] = useState(
    initialState ? initialState.promoSearchString : '',
  );
  const [searchInputValue, setSearchInputValue] = useState(
    initialState ? initialState.promoSearchString : '',
  );

  // Promo request table state
  const [promoRequests, setPromoRequests] = useState(
    initialState ? initialState.promoRequests : null,
  );
  const [promoRequestTablePage, setPromoRequestTablePage] = useState(
    initialState ? initialState.promoRequestTablePage : 1,
  );
  const [promoRequestTableSortBy, setPromoRequestTableSortBy] = useState(
    initialState ? initialState.promoRequestTableSortBy : 'startedAt',
  );
  const [promoRequestTableSortDesc, setPromoRequestTableSortDesc] = useState(
    initialState ? initialState.promoRequestTableSortDesc : true,
  );
  const [totalPromoRequests, setTotalPromoRequests] = useState(
    initialState ? initialState.totalPromoRequests : 0,
  );
  const [promoRequestTableLoading, setPromoRequestTableLoading] = useState(
    initialState ? initialState.promoRequestTableLoading : false,
  );

  const [selectedPromo, setSelectedPromo] = useState(null);
  const [pixelManagerIsOpen, setPixelManagerIsOpen] = useState(false);
  const [editorIsOpen, setEditorIsOpen] = useState(false);
  const [resolutionExplainerIsOpen, setResolutionExplainerIsOpen] = useState(false);
  const [episodeSelectFormIsOpen, setEpisodeSelectFormIsOpen] = useState(false);
  const [episodeFillInProgress, setEpisodeFillInProgress] = useState(false);
  const [episodeFillError, setEpisodeFillError] = useState(null);
  const [newlyFilledPromo, setNewlyFilledPromo] = useState(null);

  const promoTableColumns = useMemo(() => {
    return getPromoTableColumns(
      !readOnlyMode && canEditPromos,
      handleInstallPixelClick,
      handleEditorClick,
      handleArchiveClick,
      handleResolveClick,
      handleFillEpisodeClick,
      audioAdCampaignId,
      teamId,
    );
  }, [audioAdCampaignId]);

  const promoRequestTableColumns = useMemo(() => {
    return getPromoRequestTableColumns(!readOnlyMode && canEditPromos, handleEditorClick);
  }, []);

  const handleLoadPromos = () => {
    getBuyerPromos({
      teamId,
      pageSize,
      audioAdCampaignId,
      nameQuery: promoSearchString,
      page: promoTablePage,
      sortBy: promoTableSortBy,
      sortDesc: promoTableSortDesc,
    })
      .then(({ data }) => {
        setPromos(data.data);
        setTotalPromos(data.metadata.total);
        setPromoTableLoading(false);
      })
      .catch(() => {
        setFailedToLoadPromos(true);
        setPromoTableLoading(false);
      });
  };

  useEffect(() => {
    if (initialState) return;
    setPromoTableLoading(true);
    handleLoadPromos();
  }, [audioAdCampaignId, promoTablePage, promoTableSortBy, promoTableSortDesc, promoSearchString]);

  useEffect(() => {
    if (initialState) return;
    setPromoRequestTableLoading(true);
    handleLoadPromoRequests();
  }, [
    audioAdCampaignId,
    promoRequestTablePage,
    promoRequestTableSortBy,
    promoRequestTableSortDesc,
  ]);

  useEffect(() => {
    storeState(null);
    return () => {
      storeState({
        promos,
        promoTablePage,
        promoTableSortDesc,
        promoTableSortBy,
        promoSearchString,
        totalPromos,
        promoRequestTablePage,
        promoRequestTableSortDesc,
        promoRequestTableSortBy,
        totalPromoRequests,
        promoRequests,
      });
    };
  }, [
    audioAdCampaignId,
    promoTablePage,
    promoTableSortDesc,
    promoTableSortBy,
    totalPromos,
    promos,
    promoRequestTablePage,
    promoRequestTableSortDesc,
    promoRequestTableSortBy,
    totalPromoRequests,
    promoRequests,
  ]);

  useEffect(() => () => nullifyNewPromo(), []);

  const handleTextSearch = useCallback(
    debounce(text => {
      setPromoSearchString(text);
    }, 300),
    [],
  );

  function closeModal() {
    setSelectedPromo(null);
    setPixelManagerIsOpen(false);
    setEditorIsOpen(false);
    setResolutionExplainerIsOpen(false);
    setEpisodeFillInProgress(false);
    setEpisodeSelectFormIsOpen(false);
  }

  function handleResolveClick(selectedPromo) {
    setSelectedPromo(selectedPromo);
    setResolutionExplainerIsOpen(true);
  }

  function handleFillEpisodeClick(selectedPromo) {
    setSelectedPromo(selectedPromo);
    setEpisodeSelectFormIsOpen(true);
  }

  function handleFillEpisode(episode) {
    setEpisodeFillError(null);
    if (episodeFillInProgress) return;
    setEpisodeFillInProgress(true);

    fillPromoEpisode({
      audioAdPlacementId: selectedPromo.audioAdPlacementId,
      teamId,
      episodeId: episode.cid,
    })
      .then(res => {
        setPromoTableLoading(true);
        handleLoadPromos();
        clearAnalyticsTabState();
        closeModal();
        setNewlyFilledPromo(res.data);
      })
      .catch(() => {
        setEpisodeFillError('Something went wrong trying to fill your episode');
      })
      .finally(() => {
        setEpisodeFillInProgress(false);
      });
  }

  function handleUpdatePromo({ adType, targetImpressions, totalCost, name, isInternal }) {
    putPromo({
      teamId,
      adType,
      targetImpressions,
      totalCost,
      name,
      isInternal,
      audioAdPlacementId: selectedPromo.audioAdPlacementId,
    })
      .then(() => {
        // Just reload everything since a change could impact sortBy
        setPromoTableLoading(true);
        handleLoadPromos();
        clearAnalyticsTabState();
        closeModal();
      })
      .catch(() => setErrorMessage('Failed to update promo'));
  }

  function handleInstallPixelClick(selectedPromo) {
    setSelectedPromo(selectedPromo);
    setPixelManagerIsOpen(true);
  }

  function handleEditorClick(selectedPromo) {
    setSelectedPromo(selectedPromo);
    setEditorIsOpen(true);
  }

  function handleArchiveClick(audioAdPlacementId) {
    setPromoTableLoading(true);
    archivePromo({ audioAdPlacementId, teamId })
      .then(() => {
        handleLoadPromos();
        clearAnalyticsTabState();
      })
      .catch(() => setErrorMessage('Failed to archive promo.'))
      .finally(() => setPromoTableLoading(false));
  }

  function handleDeletePromoRequest() {
    deletePromoRequest({
      teamId,
      audioAdPlacementRequestId: selectedPromo.id,
    })
      .then(() => {
        // Just reload everything since a change could impact sortBy
        setPromoRequestTableLoading(true);
        handleLoadPromoRequests();
        closeModal();
      })
      .catch(() => setErrorMessage('Failed to delete promo request.'));
  }

  function handleLoadPromoRequests() {
    getBuyerPromoRequests({
      teamId,
      pageSize,
      audioAdCampaignId,
      page: promoRequestTablePage,
      sortBy: promoRequestTableSortBy,
      sortDesc: promoRequestTableSortDesc,
    })
      .then(({ data }) => {
        setPromoRequests(data.data);
        setTotalPromoRequests(data.metadata.total);
        setPromoRequestTableLoading(false);
      })
      .catch(() => {
        setFailedToLoadPromoRequests(true);
        setPromoRequestTableLoading(false);
      });
  }

  const tableConfig = {
    page: viewingPromoTable ? promoTablePage : promoRequestTablePage,
    sortBy: viewingPromoTable ? promoTableSortBy : promoRequestTableSortBy,
    sortDesc: viewingPromoTable ? promoTableSortDesc : promoRequestTableSortDesc,
    data: viewingPromoTable ? promos : promoRequests,
    columns: viewingPromoTable ? promoTableColumns : promoRequestTableColumns,
    failedToLoad: viewingPromoTable ? failedToLoadPromos : failedToLoadPromoRequests,
    loading: viewingPromoTable ? promoTableLoading : promoRequestTableLoading,
    total: viewingPromoTable ? totalPromos : totalPromoRequests,
    setTablePage: viewingPromoTable ? setPromoTablePage : setPromoRequestTablePage,
    setTableSortDesc: viewingPromoTable ? setPromoTableSortDesc : setPromoRequestTableSortDesc,
    setTableSortBy: viewingPromoTable ? setPromoTableSortBy : setPromoRequestTableSortBy,
  };

  const renderPixelUI = () => {
    if (newPromo.isInternal) {
      return newPromo.campaignType === 'dai' ? (
        <React.Fragment>
          <div className="f5 lh-copy mb3">
            Here's a pixel for your new promo, <b>{newPromo.name}</b>:
          </div>
          <Button
            onClick={() => {
              setSelectedPromo(newPromo);
              setPixelManagerIsOpen(true);
            }}
            type="primary"
            className="w5"
          >
            View Pixel
          </Button>
        </React.Fragment>
      ) : (
        <div className="f5 lh-copy">
          {newPromo.episode
            ? 'Please allow up to 2 hours for data to populate on your promo.'
            : 'After the air date for this promotion, your team will be able to select the episode for attribution on the SmartPromos > Manage > Promos page.'}
        </div>
      );
    }
    return (
      <div className="f5 lh-copy">
        Your SmartPromo {newPromo.name} has been sent to {newPromo.publisherName}.{' '}
        {newPromo.campaignType === 'dai'
          ? 'They will be able to approve and select a tracking pixel on their Adops dashboard.'
          : `On the selected date, ${moment(newPromo.scheduledAirDate).format(
              'MM/DD/YYYY',
            )}, they will be able to approve and select the episode containing the promo on the Adops page of their dashboard.`}{' '}
        <a
          href="https://help.chartable.com/article/166-smartpromo-request-learn-more"
          target="_blank"
        >
          More info here
        </a>
        .
      </div>
    );
  };

  return (
    <div className="ph4 mb5">
      {errorMessage && (
        <BannerNotice
          className="mb3"
          onClose={() => setErrorMessage(null)}
          onClick={() => setErrorMessage(null)}
          message={errorMessage}
          error
          rounded
        />
      )}
      {newPromo && (
        <div
          style={{ color: '#03543f', backgroundColor: '#def7ec' }}
          className="relative mb2 mt3 br3 pa3"
        >
          <div data-testid="promoSuccess" className="mb1 b">
            Created Promo{newPromo.isRequest ? ' Request' : ''}!
          </div>
          <div
            role="presentation"
            className="pointer top-0 right-0 absolute"
            {...onClickHandler(nullifyNewPromo)}
          >
            <div className="dim f5 dib pointer tr pt1 pr2">&times;</div>
          </div>
          {renderPixelUI()}
        </div>
      )}
      <div className="flex flex-wrap justify-between items-end mv3">
        <TabBar>
          <TabBarItem
            title="Promos"
            route={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/manage`}
            selected={viewingPromoTable}
          />
          <TabBarItem
            title="Requests"
            route={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/manage/requests`}
            selected={!viewingPromoTable}
          />
        </TabBar>
        <Link
          className="ml-auto blue link"
          to={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/manage/archive`}
        >
          See archive →
        </Link>
      </div>
      <div className="f5 mid-gray mb2">
        {viewingPromoTable
          ? 'These are all of your promos. Both active and inactive promos appear here.'
          : "These are your outgoing promo requests. Once they've been approved by the publisher they will appear in the 'Promos' section"}
      </div>
      {viewingPromoTable && (
        <div className="mw6">
          <input
            value={searchInputValue}
            className="pa2 input-reset ba bg-white br2 b--silver mb3 w-100"
            type="text"
            placeholder="Search By Promo Name"
            onChange={({ target: { value } }) => {
              setSearchInputValue(value);
              handleTextSearch(value);
            }}
          />
        </div>
      )}
      {newlyFilledPromo && (
        <Banner className="mb2" success noImage>
          Your promo has been matched with an episode. Data will begin to populate automatically.{' '}
          <Link
            className="link pointer underline"
            to={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics/${newlyFilledPromo.audioAdPlacementId}`}
          >
            Details on your promo can be found here →
          </Link>
        </Banner>
      )}
      <PaginatedTable
        defaultSorted={[
          {
            id: tableConfig.sortBy,
            desc: tableConfig.sortDesc,
          },
        ]}
        columns={tableConfig.columns}
        handlePaging={event => tableConfig.setTablePage(event.selected + 1)}
        handleSort={sort => {
          tableConfig.setTableSortDesc(sort[0].desc);
          tableConfig.setTableSortBy(sort[0].id);
        }}
        data={tableConfig.data}
        page={tableConfig.page}
        total={tableConfig.total}
        pageSize={pageSize}
        loading={tableConfig.loading}
        showLoadingWhileDataIsNull
        tableClassName="-highlight pointer"
        getTrProps={() => ({
          style: { display: 'flex', alignItems: 'center' },
        })}
        failedToLoad={tableConfig.failedToLoad}
        failedToLoadMessage={
          <TableErrorView
            header={`Failed to load promo${viewingPromoTable ? 's' : ' requests'}!`}
            subheader="Please reach out to support if this problem persists."
          />
        }
      />
      {selectedPromo && (
        <BasicModal isOpen onRequestClose={closeModal} ariaHideApp={false}>
          {episodeSelectFormIsOpen && (
            <>
              {selectedPromo.sellerPodcast ? (
                <>
                  {episodeFillError && (
                    <Banner className="mb2" error noImage>
                      {episodeFillError}
                    </Banner>
                  )}
                  <EpisodeSelector
                    teamId={teamId}
                    podcastId={selectedPromo.sellerPodcast.slug}
                    scheduledAirDate={selectedPromo.startedAt}
                    onSelect={episode => handleFillEpisode(episode)}
                    fillInProgress={episodeFillInProgress}
                    buttonText="Select Episode"
                  />
                </>
              ) : (
                <Banner className="f6 mb3" error>
                  <b>This promo has no promoting podcast assigned to it.</b>
                  <div className="mt1 lh-copy">
                    The podcast that will promote <i>{selectedPromo.buyerPodcast.title}</i> is not
                    assigned to this promo. Please get in touch with support to resolve this issue.
                  </div>
                </Banner>
              )}
              <Button className="mt3" onClick={closeModal}>
                Close
              </Button>
            </>
          )}
          {pixelManagerIsOpen && (
            <PixelManager
              canInstallPixelCode={selectedPromo.isInternal}
              onClose={closeModal}
              pixel={selectedPromo.pixel}
              promo={selectedPromo}
            />
          )}
          {editorIsOpen &&
            (selectedPromo.isRequest ? (
              <div>
                <h2 className="mt0">Edit Promo</h2>
                <div className="mv4">
                  <div className="f5 mb2 header-font">Name</div>
                  <div className="f4 near-black">{selectedPromo.name}</div>
                </div>
                <Button className="mb2" onClick={handleDeletePromoRequest} type="destructive">
                  Cancel Promo Request
                </Button>
                <Button onClick={closeModal}>Close</Button>
              </div>
            ) : (
              <PromoEditor
                teamId={teamId}
                audioAdPlacement={selectedPromo}
                onCancel={closeModal}
                onSuccess={handleUpdatePromo}
              />
            ))}
          {resolutionExplainerIsOpen && (
            <div>
              <ErrorResolutionExplainer promo={selectedPromo} />
              <Button onClick={closeModal}>Close</Button>
            </div>
          )}
        </BasicModal>
      )}
    </div>
  );
}

Manage.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  storeState: PropTypes.func.isRequired,
  clearAnalyticsTabState: PropTypes.func.isRequired,
  nullifyNewPromo: PropTypes.func.isRequired,
  canEditPromos: PropTypes.bool.isRequired,
  viewingPromoTable: PropTypes.bool.isRequired,
  newPromo: PropTypes.object,
  initialState: PropTypes.object,
};

Manage.defaultProps = {
  newPromo: null,
};

export default withUser(Manage);
