import React from 'react';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ChartHistoryChart from 'shared/charts/ChartHistoryChart';
import ColumnChart from 'shared/charts/ColumnChart';
import ConsumptionChart from 'shared/charts/ConsumptionChart';
import CountryDemographicsChart from 'shared/charts/CountryDemographicsChart';
import Loading from 'shared/Loading';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import UpgradeModal from 'shared/UpgradeModal';

export default function DashboardCompareView({
  teamId,
  podcastOne,
  podcastTwo,
  episodeOne,
  episodeTwo,
  podcastOneEpisodes,
  podcastTwoEpisodes,
  podcastsCount,
  podcasts,
  onChangePodcast,
  onChangeEpisode,
  demographics,
  playerDemographics,
  downloadsHistory,
  downloadsHistoryAggregation,
  onGetDownloadsHistory,
  playsHistory,
  playsStartDate,
  playsEndDate,
  downloadsStartDate,
  downloadsEndDate,
  playsHistoryAggregation,
  onGetPlaysHistory,
  consumptionSeries,
  onChangePlaysEndDate,
  onChangePlaysStartDate,
  onChangeDownloadsEndDate,
  onChangeDownloadsStartDate,
  loadingData,
  loadingPodcasts,
  onGenerateAudienceOverlap,
  loadingAudienceOverlap,
  generatedAudienceOverlap,
}) {
  let episodeOneOptions = [];
  if (podcastOneEpisodes) {
    episodeOneOptions = podcastOneEpisodes.map(episode => ({
      value: episode,
      label: episode.shortTitle,
    }));
  }
  let episodeTwoOptions = [];
  if (podcastTwoEpisodes) {
    episodeTwoOptions = podcastTwoEpisodes.map(episode => ({
      value: episode,
      label: episode.shortTitle,
    }));
  }

  const episodes = (episodeOne || episodeTwo) !== null;

  const { countryDemographics, genderDemographics, ageDemographics } = demographics || {};

  const availableData =
    countryDemographics ||
    genderDemographics ||
    ageDemographics ||
    playerDemographics ||
    downloadsHistory ||
    playsHistory ||
    consumptionSeries;
  let audienceOverlapAvailable =
    podcastOne && podcastTwo && podcastOne.trackableEnabled && podcastTwo.trackableEnabled;
  if (audienceOverlapAvailable) {
    if (episodeOne || episodeTwo) {
      if (
        (episodeOne && !episodeTwo) ||
        (episodeTwo && !episodeOne) ||
        episodeOne.id === episodeTwo.id
      ) {
        audienceOverlapAvailable = false;
      }
    } else {
      if (podcastOne.id === podcastTwo.id) {
        audienceOverlapAvailable = false;
      }
    }
  }

  return (
    <div>
      <div className="mw8 pa3">
        <ReactTooltip />
        <div className="w-100 flex-ns flex-wrap-ns mb4">
          <div className="w-50-ns w-100 pa2">
            {podcastsCount > 1 && (
              <div className="mb4">
                <div className="f4 mb2">Choose a podcast</div>
                <PodcastSwitcher
                  fetchDefaultOptions={false}
                  defaultOptions={podcasts}
                  teamId={teamId}
                  onSelect={({ data }) => onChangePodcast(data)}
                  loading={loadingPodcasts}
                  selectedPodcast={podcastOne || null}
                  defaultToNone
                  defaultToAll={false}
                  includeNone
                  includeAll={false}
                  numberOfDefaultOptionsToFetch={podcastsCount}
                />
              </div>
            )}
            {podcastOne && (
              <div>
                <div className="f4 mb2">Choose an episode</div>
                <Select
                  value={episodeOne ? { value: episodeOne, label: episodeOne.title } : null}
                  onChange={e => onChangeEpisode(e.value)}
                  options={episodeOneOptions}
                />
              </div>
            )}
          </div>
          <div className="w-50-ns w-100 pa2">
            {podcastsCount > 1 && (
              <div className="mb4">
                <div className="f4 mb2">Choose a podcast</div>
                <PodcastSwitcher
                  fetchDefaultOptions={false}
                  defaultOptions={podcasts}
                  teamId={teamId}
                  onSelect={({ data }) => onChangePodcast(null, data)}
                  loading={loadingPodcasts}
                  selectedPodcast={podcastTwo || null}
                  defaultToNone
                  defaultToAll={false}
                  includeNone
                  includeAll={false}
                  numberOfDefaultOptionsToFetch={podcastsCount}
                />
              </div>
            )}
            {podcastTwo && (
              <div>
                <div className="f4 mb2">Choose an episode</div>
                <Select
                  value={episodeTwo ? { value: episodeTwo, label: episodeTwo.title } : null}
                  onChange={e => onChangeEpisode(null, e.value)}
                  options={episodeTwoOptions}
                />
              </div>
            )}
          </div>
        </div>

        {loadingData ? (
          <Loading />
        ) : (
          <div>
            {!availableData && (podcastOne || podcastTwo) && (
              <div className="f5 tc b">
                Add integrations to compare data from two shows or episodes.
              </div>
            )}
            {availableData && podcastOne && podcastTwo && (
              <div className="mb4">
                <div className="w-100 tl flex flex-wrap">
                  <div className="w-100 pa2-s pt2-ns">
                    <div className="f3 dark-blue mb2">
                      Audience Overlap
                      <div className="f5 mt2">
                        Statistics on the intersection between the audience of two shows or
                        episodes.
                      </div>
                    </div>
                  </div>
                  {audienceOverlapAvailable ? (
                    <div className="bg-white ba b--dark-green pa2 h-25 br2 mv2">
                      {loadingAudienceOverlap ? (
                        <Loading />
                      ) : (
                        <div
                          className={classNames('link dark-green w-100 pointer')}
                          onClick={e => onGenerateAudienceOverlap()}
                        >
                          {generatedAudienceOverlap ? (
                            <span>CSV Sent</span>
                          ) : (
                            <span>Export CSV</span>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="f5 b">
                      Choose two shows or episodes with Trackable integrated to see Audience Overlap
                    </div>
                  )}
                </div>
              </div>
            )}

            {downloadsHistory && (
              <ChartHistoryChart
                legendPosition="top"
                history={downloadsHistory}
                aggregation={downloadsHistoryAggregation}
                startDate={downloadsStartDate}
                endDate={downloadsEndDate}
                onGetHistory={onGetDownloadsHistory}
                handleChangeEndDate={onChangeDownloadsEndDate}
                handleChangeStartDate={onChangeDownloadsStartDate}
                loading={false}
                dualAxes
                height="480px"
                teamId={teamId}
              />
            )}
            {playsHistory && (
              <ChartHistoryChart
                title="Confirmed Plays"
                subtitle="Listens longer than 60 seconds"
                legendPosition="top"
                history={playsHistory}
                aggregation={playsHistoryAggregation}
                startDate={playsStartDate}
                endDate={playsEndDate}
                handleChangeEndDate={onChangePlaysEndDate}
                handleChangeStartDate={onChangePlaysStartDate}
                onGetHistory={onGetPlaysHistory}
                dualAxes
                loading={false}
                teamId={teamId}
              />
            )}
            {consumptionSeries && <ConsumptionChart consumptionSeries={consumptionSeries} />}
            {countryDemographics && (
              <CountryDemographicsChart
                title="Locations"
                countryDemographics={countryDemographics}
                loading={false}
              />
            )}
            {genderDemographics && (
              <ColumnChart data={genderDemographics} title="Gender" calculatePercentage />
            )}
            {ageDemographics && (
              <ColumnChart data={ageDemographics} title="Age" calculatePercentage />
            )}
            {playerDemographics && (
              <ColumnChart data={playerDemographics} title="Players" calculatePercentage />
            )}
            <div className="tc f7">All data in UTC</div>
          </div>
        )}
      </div>
    </div>
  );
}

DashboardCompareView.propTypes = {
  teamId: PropTypes.string.isRequired,
  podcastOne: PropTypes.object,
  podcastOneEpisodes: PropTypes.array,
  episodeOne: PropTypes.object,
  podcastTwo: PropTypes.object,
  podcastTwoEpisodes: PropTypes.array,
  episodeTwo: PropTypes.object,
  onChangePodcast: PropTypes.func.isRequired,
  onChangeEpisode: PropTypes.func.isRequired,
  demographics: PropTypes.object,
  playsHistory: PropTypes.array,
  playsHistoryAggregation: PropTypes.string,
  playsHistoryNumDays: PropTypes.number,
  onGetPlaysHistory: PropTypes.func.isRequired,
  downloadsHistory: PropTypes.array,
  downloadsHistoryAggregation: PropTypes.string,
  downloadsHistoryNumDays: PropTypes.number,
  onGetDownloadsHistory: PropTypes.func.isRequired,
  consumptionSeries: PropTypes.array,
  loadingAudienceOverlap: PropTypes.bool,
  audienceOverlap: PropTypes.object,
  onChangeDownloadsEndDate: PropTypes.func.isRequired,
  onChangeDownloadsStartDate: PropTypes.func.isRequired,
  onChangePlaysEndDate: PropTypes.func.isRequired,
  onChangePlaysStartDate: PropTypes.func.isRequired,
  downloadsStartDate: PropTypes.instanceOf(Date).isRequired,
  downloadsEndDate: PropTypes.instanceOf(Date).isRequired,
  playsStartDate: PropTypes.instanceOf(Date).isRequired,
  playsEndDate: PropTypes.instanceOf(Date).isRequired,
  loadingData: PropTypes.bool,
  onGenerateAudienceOverlap: PropTypes.func.isRequired,
  generatedAudienceOverlap: PropTypes.bool,
};

DashboardCompareView.defaultProps = {
  podcastOne: null,
  podcastOneEpisodes: null,
  episodeOne: null,
  podcastTwo: null,
  podcastTwoEpisodes: null,
  episodeTwo: null,
  demographics: {},
  playsHistory: null,
  playsHistoryAggregation: null,
  downloadsHistory: null,
  downloadsHistoryAggregation: null,
  consumptionSeries: null,
  loadingData: false,
  generatedAudienceOverlap: false,
  loadingAudienceOverlap: false,
};
