import React from 'react';
import PropTypes from 'prop-types';

import Loading from 'shared/Loading';

function FormPodcast({ podcast, onUnselectPodcast, showEmpty, showTrackableStatus }) {
  return (
    <div>
      <div className="ba br2 mt2 b--dotted">
        <div className="relative flex flex-wrap items-center bg-white">
          <span className="w-15 h3 mr2">
            {showEmpty ? (
              <div className="bg-light-gray h3 w3"></div>
            ) : (
              podcast.imageUrl && (
                <img className="h-100 w-auto" title={podcast.title} src={podcast.imageUrl} />
              )
            )}
          </span>
          <span className="w-auto">
            <div className="f6 tl">{showEmpty ? 'No podcast selected' : podcast.title}</div>
          </span>
          {onUnselectPodcast && (
            <div className="pointer pr2 pt1 top-0 right-0 absolute f7 lh-copy b">
              <div
                className="underline-hover f5 dib black pointer tr"
                onClick={() => onUnselectPodcast(podcast)}
              >
                &times;
              </div>
            </div>
          )}
          {showTrackableStatus && (
            <div
              className={`pr2 pb1 bottom-0 right-0 absolute f7 lh-copy b ${
                podcast.trackableStatus ? 'green' : 'red'
              }`}
            >
              {podcast.trackableStatus ? 'Has prefix' : 'No prefix'}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

FormPodcast.propTypes = {
  podcast: PropTypes.object,
  onUnselectPodcast: PropTypes.func,
  showEmpty: PropTypes.bool,
  showTrackableStatus: PropTypes.bool,
};

FormPodcast.defaultProps = {
  showEmpty: false,
  showTrackableStatus: false,
};

export default FormPodcast;
