import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import FormTitle from 'shared/FormTitle';
import FormPodcast from 'shared/FormPodcast';
import SmallLoading from 'shared/SmallLoading';

import { searchTeamPodcasts, buyerPodcastSearch } from 'shared/api';

class FormPodcastSearchInput extends Component {
  constructor(props) {
    super(props);

    this.handleAutosuggest = this.handleAutosuggest.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);
    this.textInput = React.createRef();

    this.state = {
      titleSearchText: '',
      suggestions: [],
      requestInProgress: false,
    };
  }

  handleAutosuggest({ value }) {
    const {
      teamId,
      scopeToTeamPodcast,
      filterByTrackable,
      showIsInternal,
      includeAllTeamPodcasts,
    } = this.props;

    this.setState({ requestInProgress: true });

    if (scopeToTeamPodcast) {
      searchTeamPodcasts({
        q: value ? value.trim() : value,
        teamId,
        includeAllTeamPodcasts,
      }).then(res => {
        this.setState({ suggestions: res.data.results || [], requestInProgress: false });
      });
    } else {
      buyerPodcastSearch({
        q: value ? value.trim() : value,
        teamId,
        showIsInternal,
        filterByTrackable: filterByTrackable || false,
      }).then(res => {
        this.setState({ suggestions: res.data.results, requestInProgress: false });
      });
    }
  }

  handleSuggestionSelected(ev, { suggestion }) {
    this.props.onSelectPodcast(suggestion);
    this.setState({ titleSearchText: '', suggestions: [] });
  }

  renderSuggestion(suggestion) {
    const { imageUrl, title, trackableStatus } = suggestion;

    const displayTitle = title && title.length > 50 ? `${title.slice(0, 50)}...` : title;

    return (
      <div className="relative pointer link pa2 flex flex-wrap items-center">
        <div className="w-10 pr2">
          {imageUrl && <img className="tc center" title={title} src={imageUrl} alt="" />}
        </div>
        <div className="w-85">
          <div className="f6 tl" title={title}>
            {displayTitle}
          </div>
        </div>
        {this.props.showTrackableStatus && (
          <div
            className={`pr2 pt1 top-0 right-0 absolute f7 lh-copy b ${
              trackableStatus ? 'green' : 'red'
            }`}
          >
            {trackableStatus ? 'Trackable' : 'Needs prefix'}
          </div>
        )}
      </div>
    );
  }

  storeInputReference = autosuggest => {
    if (autosuggest !== null) {
      this.input = autosuggest.input;
    }
  };

  render() {
    const { onUnselectPodcast, selectedPodcast, title, showTrackableStatus } = this.props;

    const { suggestions, titleSearchText, requestInProgress } = this.state;

    return (
      <div>
        {title && <FormTitle title={title} />}
        {selectedPodcast ? (
          <FormPodcast
            podcast={selectedPodcast}
            onUnselectPodcast={onUnselectPodcast}
            showTrackableStatus={showTrackableStatus}
          />
        ) : (
          <div>
            <div className="relative">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.handleAutosuggest}
                onSuggestionSelected={this.handleSuggestionSelected}
                getSuggestionValue={suggestion => suggestion.trackName}
                shouldRenderSuggestions={value => {
                  const ok = value.length > 1 && titleSearchText !== '';
                  return ok;
                }}
                renderSuggestion={this.renderSuggestion}
                alwaysRenderSuggestions={false}
                ref={this.storeInputReference}
                inputProps={{
                  className: 'pt2 pb2 pl2 pr3 b--gray ba br2 w-100 w5 f5',
                  placeholder: 'Search shows by title',
                  value: titleSearchText,
                  onChange: e => this.setState({ titleSearchText: e.target.value }),
                }}
              />
              {(requestInProgress || (suggestions.length == 0 && titleSearchText.length >= 2)) && (
                <SmallLoading className="absolute right-0 top-0 h-100 flex items-center mr2" />
              )}
            </div>
            {this.props.showEmptyWhenNoneSelected && (
              <div className="mt2 dim pointer" onClick={() => this.input.focus()}>
                <FormPodcast showEmpty />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

FormPodcastSearchInput.propTypes = {
  teamId: PropTypes.string.isRequired,
  onSelectPodcast: PropTypes.func.isRequired,
  selectedPodcast: PropTypes.object,
  onUnselectPodcast: PropTypes.func,
  title: PropTypes.string,
  scopeToTeamPodcast: PropTypes.bool,
  showEmptyWhenNoneSelected: PropTypes.bool,
  filterByTrackable: PropTypes.bool,
  showIsInternal: PropTypes.bool,
  includeAllTeamPodcasts: PropTypes.bool,
};

FormPodcastSearchInput.defaultProps = {
  onUnselectPodcast: null,
  title: null,
  scopeToTeamPodcast: true,
  selectedPodcast: null,
  showEmptyWhenNoneSelected: false,
  filterByTrackable: null,
  showTrackableStatus: false,
  showIsInternal: false,
  includeAllTeamPodcasts: false,
};

export default FormPodcastSearchInput;
