import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { onClickHandler } from './a11y/lib';
import Loading from './SmallLoading';

const SIZE = {
  huge: 'f4 pa3',
  large: 'f5 pa3',
  medium: 'f5 pa2',
  small: 'f5 pa1',
};

const TYPE = {
  primary: 'bg-blue white b--blue',
  spotify: 'bg-mid-blue white b--blue br-pill',
  default: 'bg-white near-black b--moon-gray',
  create: 'bg-green white b--green',
  destructive: 'bg-red white b--red',
};

const INVERTED = {
  primary: 'blue b--blue bg-white',
  default: 'bg-white near-black b--moon-gray', // no inversion for default
  create: 'green b--green bg-white',
  destructive: 'red b--red bg-white',
};

function Button({
  onClick,
  children,
  type,
  className,
  loading,
  disabled,
  size,
  inverted,
  style,
  hideBorder,
  testId = 'shared-btn',
}) {
  let buttonClass = classnames(`br2 tc ${hideBorder ? '' : 'ba'}`, SIZE[size]);

  if (!disabled && !loading) {
    buttonClass = classnames('pointer dim', buttonClass, inverted ? INVERTED[type] : TYPE[type]);
  } else if (disabled) {
    buttonClass = classnames(
      type === 'spotify' && TYPE[type],
      'bg-moon-gray b--moon-gray white',
      buttonClass,
    );
  }

  const handleClick = loading || disabled ? null : onClick;

  return (
    <div
      {...onClickHandler(() => handleClick())}
      className={classnames(buttonClass, className)}
      style={style}
      data-testid={testId}
    >
      {loading ? <Loading /> : children}
    </div>
  );
}

Button.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  inverted: PropTypes.bool,
  hideBorder: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Button.defaultProps = {
  type: 'default',
  size: 'medium',
  loading: false,
  disabled: false,
  inverted: false,
  style: null,
  hideBorder: false,
};

export default Button;
