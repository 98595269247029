import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { adminDeletePartner, adminGetPartners } from 'shared/api';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import ConfirmationModal from 'shared/ConfirmationModal';
import Loading from 'shared/Loading';
import SmallTopBar from 'shared/SmallTopBar';

const PartnerDetailView = () => {
  const { teamId } = useParams();

  const [requestInProgress, setRequestInProgress] = useState(false);
  const [partners, setPartners] = useState(null);
  const [selectedPartnership, setSelectedPartnership] = useState(null);
  const [banner, setBanner] = useState(null);

  const handleDeletePartner = (partnerSlug, podcastSlug) => {
    setRequestInProgress(true);
    setSelectedPartnership(null);
    adminDeletePartner({ teamSlug: teamId, partnerSlug, podcastSlug })
      .then(() => window.location.reload())
      .catch(err => {
        setRequestInProgress(false);
        setBanner(
          <Banner type="error" onClick={() => setBanner(null)}>
            Failed to delete partnership
          </Banner>,
        );
      });
  };

  const columns = useMemo(() => [
    {
      Header: 'Team (Publisher)',
      accessor: 'team.name',
      Cell: row => <a href={`/teams/${row.original.team.slug}`}>{row.original.team.name}</a>,
    },
    {
      Header: 'Partner (Agency)',
      accessor: 'partner.name',
      Cell: row => <a href={`/teams/${row.original.partner.slug}`}>{row.original.partner.name}</a>,
    },
    {
      Header: 'Podcast',
      accessor: 'podcast.title',
    },
    {
      Header: '',
      Cell: row => (
        <Button
          type="destructive"
          size="small"
          onClick={() => setSelectedPartnership(row.original)}
        >
          Delete
        </Button>
      ),
    },
  ]);

  useEffect(() => {
    adminGetPartners({ teamSlug: teamId })
      .then(res => setPartners(res.data))
      .catch(err => console.log(err));
  }, []);

  return (
    <div>
      <SmallTopBar backUrl="/custom_admin/react/partners" backText="Back" text={teamId} />
      <div className="pa4">
        {banner}
        {!!partners && !requestInProgress ? (
          <ReactTable
            data={partners}
            columns={columns}
            showPagination={false}
            pageSize={partners.length}
            className="w-100 f5 -striped"
            NoDataComponent={() => null}
            getTrProps={(state, row) => ({
              style: { display: 'flex', alignItems: 'center', textAlign: 'center' },
            })}
          />
        ) : (
          <Loading fullScreen />
        )}
      </div>
      <ConfirmationModal
        title="Delete partnership?"
        onClose={() => setSelectedPartnership(null)}
        isOpen={!!selectedPartnership}
        onConfirm={() =>
          handleDeletePartner(selectedPartnership.partner.slug, selectedPartnership.podcast.slug)
        }
      />
    </div>
  );
};

export default PartnerDetailView;
