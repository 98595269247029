import React, { useState } from 'react';
import CaretDownSVG from 'images/icons/caret-down-blue.svg';
import FacebookSVG from 'images/icons/facebook.svg';
import TwitterSVG from 'images/icons/twitter.svg';
import PropTypes from 'prop-types';

const useCopy = (text, callback) => {
  // Hack alert! (I think DZ wrote this -JS)
  const dummy = document.createElement('input');
  document.body.appendChild(dummy);
  dummy.setAttribute('value', text);
  dummy.select();
  document.execCommand('copy');
  document.body.removeChild(dummy);
  if (callback) {
    callback();
  }
};

function ShareView({ trackingLink, initialShowShareOptions }) {
  const url = trackingLink.url;

  const [showShareOptions, setShowShareOptions] = useState(initialShowShareOptions);
  const [parameters, setParameters] = useState({
    // Listing all keys is not required, but handles controlled input error
    at: '',
    sid: '',
    utm_source: '',
    utm_campaign: '',
    utm_medium: '',
    utm_content: '',
    utm_term: '',
  });

  function getDisplayUrl() {
    const params = Object.entries(parameters).map(([key, val]) =>
      val ? `${key}=${encodeURIComponent(val)}` : null,
    );

    const str = params.filter(Boolean).join('&');

    let displayUrl = url;
    if (str && str.length > 1) {
      displayUrl = `${url}?${str}`;
    }

    return displayUrl;
  }

  function onChangeInput(event) {
    parameters[event.target.name] = event.target.value;
    setParameters({ ...parameters });
  }

  const displayUrl = getDisplayUrl();

  return (
    <div>
      <div className={`flex flex-row gap ${showShareOptions ? 'mt3' : ''}`}>
        <div
          className={`flex br2 flex-row bg-near-white gap-small ba ${
            showShareOptions ? 'b--blue w-100' : 'b--near-white w-60'
          }`}
        >
          <div
            className={`pv2 ph1 dark-gray ${showShareOptions ? 'f5' : 'f6'}`}
            style={{ overflowWrap: 'anywhere' }}
          >
            {displayUrl}
          </div>
          {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
          <div
            // eslint-disable-next-line react-hooks/rules-of-hooks
            onClick={() => useCopy(displayUrl, null)}
            className="ml-auto pv2 f6 ph3 bg-blue white b pointer dim flex justify-center items-center"
          >
            Copy
          </div>
        </div>
        <a
          target="_blank"
          href={`https://twitter.com/intent/tweet?text=Check%20out%20this%20${
            trackingLink.episode ? 'Episode' : 'Podcast'
          }&url=${displayUrl}`}
          className="bg-near-white pa1 br2 pointer dim flex justify-center items-center"
          style={{ height: '2rem', width: '2rem' }}
          rel="noreferrer"
        >
          <img src={TwitterSVG} className="w-auto" style={{ height: '21px' }} />
        </a>
        <a
          target="_blank"
          href={`https://www.facebook.com/sharer/sharer.php?u=${displayUrl}`}
          className="bg-near-white pa1 br2 pointer dim flex justify-center items-center"
          style={{ height: '2rem', width: '2rem' }}
          rel="noreferrer"
        >
          <img src={FacebookSVG} className="w-auto" style={{ height: '21px' }} />
        </a>
      </div>
      {showShareOptions && (
        <div className="mt3">
          <div className="dark-blue mb1">Source ID (sid)</div>
          <div className="mb2 mid-gray f6">
            Set a Source ID (sid) to help the effectiveness of your marketing channels. You can
            pivot a link&apos;s stats using the sid. The sid is only used by Chartable, not other
            services.
          </div>
          <input
            name="sid"
            className="mb4 pa2 input-reset ba w-100 bg-white br2 b--gray"
            type="text"
            placeholder="e.g. twitter.ad, newsletter.march"
            value={parameters.sid}
            onChange={onChangeInput}
          />
          {!!trackingLink.defaultUrl && (
            <React.Fragment>
              <div className="dark-blue mb1">
                Google Analytics
                <a
                  href="https://en.wikipedia.org/wiki/UTM_parameters"
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  className="link blue body-font f5 pl2"
                >
                  Learn more &raquo;
                </a>
              </div>
              <div className="f6 mid-gray mb2">
                Any parameters specified here will be sent to your Default URL when your listeners
                are redirected there.
              </div>
              <div className="flex flex-wrap mb4">
                <div className="w-50-ns w-100 pr2-ns mb2">
                  <div className="f6 dark-gray mb1">Source</div>
                  <input
                    name="utm_source"
                    className="pa2 input-reset ba w-100 bg-white br2 b--gray"
                    type="text"
                    placeholder="e.g. newsletter"
                    onChange={onChangeInput}
                  />
                </div>
                <div className="w-50-ns w-100 pr2-ns mb2">
                  <div className="f6 dark-gray mb1">Campaign</div>
                  <input
                    name="utm_campaign"
                    className="pa2 input-reset ba w-100 bg-white br2 b--gray"
                    type="text"
                    placeholder="e.g. show-launch"
                    onChange={onChangeInput}
                  />
                </div>
                <div className="w-50-ns w-100 pr2-ns mb2">
                  <div className="f6 dark-gray mb1">Medium</div>
                  <input
                    name="utm_medium"
                    className="pa2 input-reset ba w-100 bg-white br2 b--gray"
                    type="text"
                    placeholder="e.g. email"
                    onChange={onChangeInput}
                  />
                </div>
                <div className="w-50-ns w-100 pr2-ns mb2">
                  <div className="f6 dark-gray mb1">Content</div>
                  <input
                    name="utm_content"
                    className="pa2 input-reset ba w-100 bg-white br2 b--gray"
                    type="text"
                    placeholder="e.g. subscribe-link"
                    onChange={onChangeInput}
                  />
                </div>
                <div className="w-50-ns w-100 pr2-ns mb2">
                  <div className="f6 dark-gray mb1">Term</div>
                  <input
                    name="utm_term"
                    className="pa2 input-reset ba w-100 bg-white br2 b--gray"
                    type="text"
                    placeholder="e.g. Subscribe Now"
                    onChange={onChangeInput}
                  />
                </div>
              </div>
            </React.Fragment>
          )}
          <div className="dark-blue mb1">
            Apple Affiliate
            <a
              href="https://affiliate.itunes.apple.com/resources/"
              target="_blank"
              rel="noopener noreferrer nofollow"
              className="f5 body-font pl2 link blue"
            >
              Learn more &raquo;
            </a>
          </div>
          <div className="mid-gray f6 mb2">
            Your parameters will be added anytime a listener is redirected to Apple Podcasts.
          </div>
          <input
            name="at"
            className="pa2 input-reset ba w-100 bg-white br2 b--gray"
            type="text"
            placeholder="Apple affiliate ID"
            value={parameters.at}
            onChange={onChangeInput}
          />
        </div>
      )}
      <div
        className={`${showShareOptions ? 'mt3 f5' : 'f6 mt2'} link blue pointer`}
        onClick={() => setShowShareOptions(!showShareOptions)}
      >
        {showShareOptions ? 'Less' : 'More'} share options
        <img
          src={CaretDownSVG}
          className="h1"
          style={{
            marginBottom: '-3px',
            transform: `scaleY(${showShareOptions ? '-1' : '1'})`,
          }}
        />
      </div>
    </div>
  );
}

ShareView.propTypes = {
  trackingLink: PropTypes.object.isRequired,
  initialShowShareOptions: PropTypes.bool,
};

ShareView.defaultProps = {
  initialShowShareOptions: false,
};

export default ShareView;
