import React, { useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getSmartPromosGeographicData } from 'shared/api';
import { getPodcastGeography, getTeamGeography } from 'shared/api/v1';
import { constructReactSelectOptionsFromStringArray } from 'shared/helpers';
import Loading from 'shared/Loading';
import PopoutSelect from 'shared/PopoutSelect';

import MapView from './components/MapView';

function GeoListItem({ geo, totals, onClick, metric, countryCode }) {
  const name = countryCode ? geo.cityGeonameDisplayname : geo.countryCodeDisplayname;
  const total = totals[metric];
  let percentage = '';
  if (geo[metric]) {
    const quotient = geo[metric] / totals[metric];
    percentage = Math.round(quotient * 10000) / 100;
  }
  if (!name) {
    return null;
  }
  return (
    <div
      onClick={onClick}
      className="pv3 bb b--light-gray w-100 flex flex-row items-center justify-between pointer hover-white hover-bg-blue"
    >
      <h4 className="ma0 f5 pl2">{name}</h4>
      <h4 className="ma0 f5 pr2">
        {percentage}%{' '}
        {geo[metric] && <small style={{ fontWeight: 300 }}>({geo[metric].toLocaleString()})</small>}
      </h4>
    </div>
  );
}

// This will tell component which query to pivot off of
const pivotTypes = {
  smartpromos: 'smartpromos',
  podcastOverview: 'podcastOverview',
};

const regionTypeOptions = [
  { value: 'city_geoname_id', label: 'Place' },
  { value: 'zip_code', label: 'Zip Code' },
  { value: 'postal_code', label: 'Postal Code' },
];

function objectsAreEqual(first, second) {
  return JSON.stringify(first) === JSON.stringify(second);
}

function GeoView({
  pivotType,
  selectedPodcast,
  audioAdCampaignId,
  audioAdPlacementId,
  teamId,
  shouldIgnoreUpdates,
  startDate,
  endDate,
  showDateRange, // Should remove once we have a permanent page wide date range selector for the podcast overview -JS
  showSource,
}) {
  const [page, setPage] = useState(null);
  const [pageSize, setPageSize] = useState(1000);
  const [countryCode, setCountryCode] = useState('US');
  const [regionType, setRegionType] = useState('city_geoname_id');
  const [geos, setGeos] = useState(null);
  const [regions, setRegions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [tooltipContent, setTooltipContent] = useState('');
  const [sortBy, setSortBy] = useState(
    pivotType === 'podcastOverview' ? 'downloads' : 'impressions',
  );
  const [sortDirection, setSortDirection] = useState('desc');
  const [geoJson, setGeoJson] = useState(null);
  const [jumpData, setJumpData] = useState(null);
  const [previousPivotModels, setPreviousPivotModels] = useState(null);
  const leftRef = useRef();
  const [availableMetrics, setAvailableMetrics] = useState([]);
  const [totals, setTotals] = useState(null);
  const [geoSource, setGeoSource] = useState('');

  // Use this to make sure we don't refetch data when user navigates between pages.
  // usePrevious was unreliable because this page would rerender whenever user clicked
  // _any_ tab, which causes it to get reset. -JS
  const createPivotModelsObject = () => {
    return {
      selectedPodcast,
      audioAdCampaignId,
      audioAdPlacementId,
      countryCode,
      sortBy,
      startDate,
      endDate,
    };
  };

  async function fetchGeoData() {
    const params = {
      page,
      pageSize,
      countryCode,
      regionType,
      teamId,
      sortBy,
      sortDirection,
      startDate,
      endDate,
      dataType: sortBy,
    };

    let response;

    switch (pivotType) {
      case pivotTypes.podcastOverview:
        if (!!selectedPodcast) {
          response = await getPodcastGeography({
            podcastId: selectedPodcast.id,
            sortDesc: sortDirection === 'desc',
            ...params,
          });
        } else {
          response = await getTeamGeography({
            sortDesc: sortDirection === 'desc',
            ...params,
          });
        }
        break;
      case pivotTypes.smartpromos:
        try {
          response = await getSmartPromosGeographicData({
            audioAdCampaignId,
            audioAdPlacementId,
            ...params,
          });
        } catch (e) {
          setLoading(false);
          return;
        }
        break;
      default:
        return;
    }

    const countriesGeoJson = await import('./components/GeoJson/customgeo.json');
    const jumpLookup = await import('./components/GeoJson/data.json');

    if (countryCode) {
      setRegions(response.data.data);
    }

    setJumpData(jumpLookup);
    setGeoJson(countriesGeoJson);
    const geoTotals = {
      downloads: 0,
      reach: 0,
      impressions: 0,
      uniqueHouseholds: 0,
      conversions: 0,
      revenue: 0,
      uniqueDownloads: 0,
    };
    const newGeos = response.data.data;

    if (pivotType !== 'podcastOverview') {
      for (let i = 0; i < newGeos.length; i++) {
        geoTotals.impressions += newGeos[i].impressions;
      }
    }
    for (let j = 0; j < newGeos.length; j++) {
      geoTotals.downloads += newGeos[j].downloads;
      geoTotals.reach += newGeos[j].reach;
      geoTotals.uniqueHouseholds += newGeos[j].uniqueHouseholds;
      geoTotals.conversions += newGeos[j].conversions;
      geoTotals.revenue += newGeos[j].revenue;
      geoTotals.uniqueDownloads += newGeos[j].uniqueDownloads;
    }
    setTotals(geoTotals);
    setGeos(newGeos);

    if (response.data.metadata.source) {
      setGeoSource(response.data.metadata.source);
    }

    if (availableMetrics && availableMetrics.length === 0) {
      if (!!response.data.metadata.availableMetrics) {
        const res = constructReactSelectOptionsFromStringArray(
          response.data.metadata.availableMetrics,
        );
        setAvailableMetrics(res);
      } else {
        setAvailableMetrics([
          { value: 'downloads', label: 'Downloads' },
          { value: 'reach', label: 'Reach' },
        ]);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const currPivotModels = createPivotModelsObject();
    if (!shouldIgnoreUpdates && !objectsAreEqual(currPivotModels, previousPivotModels)) {
      setPreviousPivotModels(currPivotModels);
      setLoading(true);
      fetchGeoData();
    }
  }, [
    selectedPodcast,
    audioAdPlacementId,
    audioAdCampaignId,
    shouldIgnoreUpdates,
    countryCode,
    sortBy,
    startDate,
    endDate,
  ]);

  const handleSetCountryCode = code => {
    if (countryCode === null) {
      setRegionType('city_geoname_id');
    } else if (code === null) {
      setRegionType(null);
    }
    setCountryCode(code);
  };

  const setTooltip = tip => {
    setTooltipContent(tip);
  };

  return (
    <div>
      <ReactTooltip />
      <div
        className="flex flex-row"
        style={{ maxHeight: 'calc(38vw + 140px)', minHeight: '510px' }}
      >
        <div
          ref={leftRef}
          style={{ flex: 2, borderRight: 'none' }}
          className="ba b--light-silver br2 br--left"
        >
          {!loading && !geos && (
            <div className="ma3" data-testid="geography-fetch-error">
              Failed to load Geography Data. Please reload the page to try again.
            </div>
          )}
          {geos && (
            <React.Fragment>
              <MapView
                countries={geos}
                regions={regions}
                countryCode={countryCode}
                setCountryCode={handleSetCountryCode}
                setTooltipContent={setTooltip}
                dateRange={{ start: startDate, end: endDate }}
                countriesGeoJson={geoJson}
                jumpData={jumpData}
                metric={sortBy}
                showSource={showSource}
                geoSource={geoSource}
              />
              <ReactTooltip id="mapViewTip" html={true}>
                {tooltipContent}
              </ReactTooltip>
            </React.Fragment>
          )}
        </div>
        <div style={{ flex: 1 }} className="ba b--light-silver br2 br--right flex flex-column">
          {!!geos && !loading ? (
            <React.Fragment>
              <div className="pv2 f5 flex flex-row ph2 bb b--moon-gray">
                <PopoutSelect
                  innerClassName="mr2 w4-5 pv1 ph2"
                  defaultOption={availableMetrics.find(opt => opt.value === sortBy)}
                  options={availableMetrics}
                  onSelect={selection => setSortBy(selection.value)}
                />
                {showDateRange && (
                  <span className={`fr relative i pr2 f7`} style={{ paddingTop: '7px' }}>{`${moment(
                    startDate,
                  ).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`}</span>
                )}
              </div>
              <div className="overflow-y-scroll">
                {geos.map((geo, index) => (
                  <GeoListItem
                    onClick={() => {
                      // If the previous countryCode is null, we want to set regionType to city_geoname_id by defaul
                      if (countryCode === null) {
                        setRegionType('city_geoname_id');
                      }
                      setCountryCode(geo.countryCode);
                    }}
                    geo={geo}
                    totals={totals}
                    key={index}
                    metric={sortBy}
                    countryCode={countryCode}
                  />
                ))}
                {geos.length === 0 && (
                  <span className={`ph2 pv3`}>City Breakdown Not Available</span>
                )}
              </div>
              <div
                className="w-100 dim pointer blue tc mt-auto"
                style={{ boxShadow: '0px -2px 2px 0px rgba(0, 0, 0, .2)', padding: '12px 0' }}
              >
                Show more
              </div>
            </React.Fragment>
          ) : (
            <>{loading && <Loading title="Loading geo list" fullScreen isChild />}</>
          )}
        </div>
      </div>
    </div>
  );
}

GeoView.propTypes = {
  pivotType: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  selectedPodcast: PropTypes.object,
  audioAdCampaignId: PropTypes.string,
  shouldIgnoreUpdates: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  showSource: PropTypes.bool,
};

GeoView.defaultProps = {
  selectedPodcast: null,
  audioAdCampaignId: null,
  shouldIgnoreUpdates: false,
  startDate: moment.utc().subtract(45, 'days').toDate(),
  endDate: moment.utc().toDate(),
  showDateRange: true,
  showSource: true,
};

export default GeoView;
