import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/Button';

export default class ColumnSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedColumns: new Set(props.selectedColumns),
    };
  }

  onChange(e) {
    const { selectedColumns } = this.state;
    e.target.checked ? selectedColumns.add(e.target.name) : selectedColumns.delete(e.target.name);
    this.setState({ selectedColumns });
  }

  render() {
    const { allColumns, title, onSave } = this.props;

    const { selectedColumns } = this.state;

    return (
      <div>
        <div className="tc f4 mb3 dark-blue">{title}</div>
        <div className="f5 pa2">
          {Object.keys(allColumns).map(key => {
            return (
              <div key={key} className="ma2">
                <input
                  type="checkbox"
                  key={key}
                  checked={selectedColumns.has(key)}
                  name={key}
                  onChange={e => this.onChange(e)}
                  data-testid={`${key}-checkbox`}
                />
                <label className="ml2">{allColumns[key]}</label>
              </div>
            );
          })}
        </div>
        <Button onClick={() => onSave([...selectedColumns])} type="primary" className="mt3">
          Apply changes
        </Button>
      </div>
    );
  }
}

ColumnSelector.defaultProps = {
  allColumns: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
