import React, { useEffect, useMemo, useState } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import ReactTable from 'react-table';
import { adminGetPartners } from 'shared/api';
import Header from 'shared/Header';
import Loading from 'shared/Loading';
import TabBarItem from 'shared/TabBarItem';

import CreatePartnershipsForm from './CreatePartnershipsForm';
import PartnerDetailView from './PartnerDetailView';

const PartnerDashboard = ({ history, match }) => {
  const location = useLocation();

  const [teams, setTeams] = useState(null);

  useEffect(() => {
    adminGetPartners()
      .then(res => setTeams(res.data))
      .catch(err => console.log(err));
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Publisher (Owner) Team',
        accessor: 'team.name',
      },
      {
        Header: '# of Partners',
        accessor: 'count',
      },
    ],
    [],
  );

  const navigation = (
    <div className="bg-white pt4 ph4">
      <Header useNew className="mb4">
        Manage Partnerships (Agencies)
      </Header>
      <div className="flex flex-row gap-large">
        <TabBarItem
          title="Manage"
          route="/custom_admin/react/partners"
          selected={location.pathname === '/custom_admin/react/partners'}
        />
        <TabBarItem
          title="Create"
          route="/custom_admin/react/partners/create"
          selected={location.pathname === '/custom_admin/react/partners/create'}
        />
      </div>
    </div>
  );

  return (
    <div className="bg-near-white vh-100">
      <Switch>
        <Route
          path="/custom_admin/react/partners/create"
          render={renderProps => (
            <React.Fragment>
              {navigation}
              <CreatePartnershipsForm {...renderProps} />
            </React.Fragment>
          )}
        />
        <Route path="/custom_admin/react/partners/:teamId" component={PartnerDetailView} />
        <Route path="/custom_admin/react/partners">
          <React.Fragment>
            {navigation}
            <div className="pa4">
              {teams ? (
                <ReactTable
                  data={teams}
                  columns={columns}
                  showPagination={false}
                  pageSize={teams.length}
                  className="w-100 f5 -striped -highlight pointer"
                  NoDataComponent={() => null}
                  getTrProps={(state, row) => ({
                    style: { display: 'flex', alignItems: 'center', textAlign: 'center' },
                    onClick: () =>
                      history.push(`/custom_admin/react/partners/${row.original.team.slug}`),
                  })}
                />
              ) : (
                <Loading fullScreen />
              )}
            </div>
          </React.Fragment>
        </Route>
      </Switch>
    </div>
  );
};

export default PartnerDashboard;
