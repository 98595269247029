import React from 'react';

const SubscriptionSettings = () => {
  return (
    <>
      <div className="f4 dark-blue mb3">Billing</div>
      <div className="f6 lh-copy">
        Chartable will no longer be billing any clients for use of the platform, regardless of
        platform tier. If you have already prepaid for Chartable’s services for this time, Chartable
        will provide you a refund through the end of your contract period. If you have any questions
        or have any issues with billing, please submit a ticket through the support portal for
        assistance.
      </div>
    </>
  );
};

export default SubscriptionSettings;
