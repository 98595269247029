import React from 'react';
import PropTypes from 'prop-types';
import PieChart from 'shared/charts/PieChart';
import Header from 'shared/Header';

const Episode = ({ episodeDetails, playerColorMap }) => {
  return (
    <div className="mt3">
      <Header className="mb2 dark-blue" useNew>
        By Episode
      </Header>
      <div className="flex flex-wrap">
        {episodeDetails.uniqueDownloads && (
          <div className="w-50 pr2">
            <Header useNew small>
              Converted Devices
            </Header>
            <PieChart data={episodeDetails.uniqueDownloads} colorMap={playerColorMap} />
          </div>
        )}
        {episodeDetails.newDownloads && (
          <div className="w-50">
            <Header useNew small>
              New Converted Devices
            </Header>
            <PieChart data={episodeDetails.newDownloads} colorMap={playerColorMap} />
          </div>
        )}
      </div>
    </div>
  );
};

Episode.propTypes = {
  episodeDetails: PropTypes.object,
  playerColorMap: PropTypes.any,
};

export default Episode;
