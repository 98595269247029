import React from 'react';
import moment from 'moment';

export const EIGHTEEN_MONTHS_AGO = moment().subtract(18, 'months').unix();

export const SORT_OPTIONS = isPro => {
  return [
    {
      value: 'trending_rank',
      label: 'Trending',
      disabled: false,
    },
    {
      value: 'downloads',
      label: isPro ? (
        'Downloads'
      ) : (
        <div>
          Downloads{' '}
          <div
            style={{
              fontSize: '8px',
              position: 'relative',
              top: '-2px',
              padding: '2px',
              borderRadius: '2px',
            }}
            className="ba b--moon-gray di"
          >
            PRO
          </div>
        </div>
      ),
      disabled: !isPro,
    },
    {
      value: 'reach',
      label: isPro ? (
        'Reach'
      ) : (
        <div>
          Reach{' '}
          <div
            style={{
              fontSize: '8px',
              position: 'relative',
              top: '-2px',
              padding: '2px',
              borderRadius: '2px',
            }}
            className="ba b--moon-gray di"
          >
            PRO
          </div>
        </div>
      ),
      disabled: !isPro,
    },
    {
      value: 'rating_average',
      label: 'Average Rating',
      disabled: false,
    },
    {
      value: 'rating_count',
      label: '# of Ratings',
      disabled: false,
    },
    {
      value: 'review_count',
      label: '# of Reviews',
      disabled: false,
    },
    {
      value: null,
      label: 'Sort By:',
      disabled: false,
    },
  ];
};

export const TOUR_ONE_STEPS = [
  {
    target: '.connect-container',
    content: (
      <div>
        <h2>Welcome to Connect!</h2>
        <p className="tc">
          Connect is a place to find podcasters to connect with for buying or trading ads and
          promos. Connect is in beta right now, and we’d love to hear your thoughts!
        </p>
      </div>
    ),
    disableBeacon: true,
    placement: 'center',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.connect-search',
    content: 'Search podcasts by title, description, genre, or publisher.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.sidebar',
    content: 'Filter podcasts to narrow your search.',
    disableBeacon: true,
    placement: 'right',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.sort-menu',
    content: 'Sort podcasts by average rating, trending, and more.',
    disableBeacon: true,
    placement: 'left',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.related',
    content: 'Click to see podcasts enjoyed by listeners of this show.',
    disableBeacon: true,
    placement: 'left',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.connect-button',
    content: 'Reach out! Click here to get connected with the creators of this podcast.',
    disableBeacon: true,
    placement: 'right',
    floaterProps: {
      disableAnimation: true,
    },
  },
];

export const TOUR_TWO_STEPS = [
  {
    target: '.verified',
    content: 'Filter your search to podcasts with verified Chartable profiles.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.quality',
    content: 'Filter your search to podcasts with episodes published in the last 18 months.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.downloads_reach',
    content: 'Filter results by thirty day downloads or reach.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
  {
    target: '.settings_button',
    content: 'Click here to edit your Connect profile.',
    disableBeacon: true,
    placement: 'bottom',
    floaterProps: {
      disableAnimation: true,
    },
  },
];
