import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Tooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getAudioAdCampaign, getBuyerPromos, getSmartPromosPlayerData } from 'shared/api';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import GeoView from 'shared/GeoView';
import PlayerChart from 'shared/PlayerChart';
import TabBar, { TabBarItem } from 'shared/TabBar';

function AudienceView({
  initialState,
  location,
  match,
  clearManageTabState,
  clearAnalyticsTabState,
  storeState,
}) {
  const { teamId, audioAdCampaignId } = match.params;

  const [audioAdCampaign, setAudioAdCampaign] = useState(initialState.audioAdCampaign);
  const [startDate, setStartDate] = useState(initialState.startDate);
  const [endDate, setEndDate] = useState(initialState.endDate);

  const [audioAdPlacement, setAudioAdPlacement] = useState(null);
  const [isFetchingCampaign, setIsFetchingCampaign] = useState(false);

  const [playerData, setPlayerData] = useState(null);
  const [playerMetadata, setPlayerMetadata] = useState(null);
  const [selectedPlayerMetric, setSelectedPlayerMetric] = useState({
    value: 'impressions',
    label: 'Impressions',
  });
  const [playerDataRequestInProgress, setPlayerDataRequestInProgress] = useState(false);
  const [promos, setPromos] = useState([]);

  useEffect(() => {
    async function handleGetPlayerData() {
      try {
        const response = await getSmartPromosPlayerData({
          audioAdCampaignId,
          audioAdPlacementId: audioAdPlacement ? audioAdPlacement.audioAdPlacementId : null,
          dataType: selectedPlayerMetric.value,
          sortBy: selectedPlayerMetric.value,
          sortDirection: 'desc',
          startDate,
          endDate,
        });
        setPlayerData(response.data.data);
        setPlayerMetadata(response.data.metadata);
        setPlayerDataRequestInProgress(false);
      } catch {
        setPlayerDataRequestInProgress(false);
      }
    }
    setPlayerDataRequestInProgress(true);
    handleGetPlayerData();
  }, [audioAdCampaignId, audioAdPlacement, selectedPlayerMetric, startDate, endDate]);

  useEffect(() => {
    storeState({ startDate, endDate });
  }, [startDate, endDate]);

  async function handleGetAudioAdCampaign() {
    setIsFetchingCampaign(true);
    const response = await getAudioAdCampaign({
      teamId,
      audioAdCampaignId,
      startDate: null,
      endDate: null,
      includeArchived: false,
    });
    setAudioAdCampaign(response.data);
    setIsFetchingCampaign(false);
  }

  const handleGetAudioAdPlacements = async () => {
    try {
      const data = await getBuyerPromos({
        page: 1,
        pageSize: 1000,
        teamId,
        audioAdCampaignId,
        startDate,
        endDate,
        includeArchived: false,
        includeConversions: true,
      });
      setPromos(data.data.data.reverse());
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (!(audioAdCampaign && audioAdCampaign.audioAdCampaignId === audioAdCampaignId)) {
      clearManageTabState();
      clearAnalyticsTabState();
      handleGetAudioAdCampaign();
      handleGetAudioAdPlacements();
    }
    // ignoring exhaustive deps until tests are written
    // potential danger in rewriting logic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioAdCampaignId, startDate, endDate]);

  return (
    <div className="mt3 mb5 ph4">
      <div className="flex w-100 justify-end mb2 mb0-l">
        <ChartableDatePicker
          disabledDays={{ after: new Date() }}
          inlineStyle={{ width: 'max-content' }}
          startDate={startDate}
          endDate={endDate}
          handleUpdateDateRange={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </div>
      <div className="flex flex-row items-baseline">
        <Tooltip />
        <div className="mid-gray mb2 f5 b">Promotion</div>
        <span
          data-tip="Filter data to selected promotion"
          className="ph1 ba b--gray br-100 tc help ml1 h1 w1 f7 dib"
        >
          ?
        </span>
      </div>
      <Select
        aria-label="promotion-dropdown"
        isLoading={isFetchingCampaign}
        value={{
          value: audioAdPlacement ? audioAdPlacement.audioAdPlacementId : null,
          label: audioAdPlacement ? audioAdPlacement.name : 'All',
        }}
        onChange={e =>
          setAudioAdPlacement(e.value ? { audioAdPlacementId: e.value, name: e.label } : null)
        }
        options={
          promos
            ? [
                { value: null, label: 'All' },
                ...promos.map(promo => ({ value: promo.audioAdPlacementId, label: promo.name })),
              ]
            : []
        }
        className="w-100 mb3"
      />
      <div className="flex flex-row mb3">
        <TabBar className="mv2">
          <TabBarItem
            title="Geography"
            route={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience`}
            selected={
              location.pathname ===
              `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience`
            }
          />
          <TabBarItem
            title="Players"
            route={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience/players`}
            selected={
              location.pathname ===
              `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience/players`
            }
          />
        </TabBar>
      </div>
      <div
        data-testid="geoview-container"
        className={
          location.pathname === `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience`
            ? ''
            : 'dn'
        }
      >
        <GeoView
          pivotType="smartpromos"
          teamId={teamId}
          audioAdCampaignId={audioAdCampaignId}
          audioAdPlacementId={audioAdPlacement ? audioAdPlacement.audioAdPlacementId : null}
          startDate={startDate}
          endDate={endDate}
          showDateRange={false}
          shouldIgnoreUpdates={
            location.pathname !== `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience`
          }
          showSource={false}
        />
      </div>
      <div
        data-testid="playerchart-container"
        className={
          location.pathname ===
          `/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/audience/players`
            ? ''
            : 'dn'
        }
      >
        <PlayerChart
          className="w-100"
          data={playerData}
          metadata={playerMetadata}
          selectedMetric={selectedPlayerMetric}
          handleSelectMetric={setSelectedPlayerMetric}
          isLoading={playerDataRequestInProgress}
        />
      </div>
    </div>
  );
}

AudienceView.propTypes = {
  clearManageTabState: PropTypes.func.isRequired,
  clearAnalyticsTabState: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  storeState: PropTypes.func.isRequired,
  initialState: PropTypes.object,
};

AudienceView.defaultProps = {
  initialState: {
    startDate: moment().subtract(30, 'days').toDate(),
    endDate: moment().toDate(),
  },
};

export default AudienceView;
