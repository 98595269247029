import React, { useEffect, useState } from 'react';
import { getAdOpsPlacement } from 'shared/api';
import Loading from 'shared/Loading';
import TopBar from 'shared/SmallTopBar';

import AudioAdPlacementDetailView from './SmartPromos/AudioAdPlacementDetailView';

const PlacementDetailView = ({ history, match, location }) => {
  const { teamId, placementId } = match.params;

  const [placement, setPlacement] = useState(null);

  useEffect(() => {
    getAdOpsPlacement({ teamId, id: placementId }).then(res => setPlacement(res.data));
  }, []);

  if (!placement) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <TopBar
        backUrl={`/teams/${teamId}/dashboard/adops/manage`}
        backText="All Placements"
        locationState={location.state}
      />
      <div className="pv3 ph4">
        <AudioAdPlacementDetailView
          placement={placement}
          location={location}
          history={history}
          teamId={teamId}
          match={match}
          isSeller={placement.buyerTeamSlug !== teamId}
        />
      </div>
    </div>
  );
};

export default PlacementDetailView;
