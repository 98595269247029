import React, { Component } from 'react';
import styles from './MFAInput.module.css';
import PropTypes from 'prop-types';

const MFAInput = ({ setMFA }) => {
  // instantiate an empty array to map inputs
  const ITERATOR = new Array(6).fill('');
  const refs = [];

  const handleDigit = event => {
    const currentIndex = parseInt(event.target.name);
    const char = event.target.value;
    const nextInput = refs[`input_${currentIndex + 1}`];
    const prevInput = refs[`input_${currentIndex - 1}`];
    setMFA(getMFA());
    if (currentIndex < ITERATOR.length - 1 && char !== '') {
      nextInput.select();
    } else if (char === '' && currentIndex > 0) {
      prevInput.select();
    }
  };

  const getMFA = () => {
    return ITERATOR.map((_, i) => {
      return refs[`input_${i}`].value;
    }).join('');
  };

  const applyRef = (name, ref) => {
    refs[`input_${name}`] = ref;
  };

  return (
    <div className={`tc flex justify-between mw5`}>
      {ITERATOR.map((_, i) => {
        return (
          <input
            key={`input_${i}`}
            maxLength={1}
            size={1}
            inputMode="numeric"
            className={styles.mfaInput}
            autoFocus={i === 0 ? 'autofocus' : null}
            ref={ref => applyRef(i, ref)}
            type="text"
            name={i}
            onChange={handleDigit}
          />
        );
      })}
    </div>
  );
};

MFAInput.propTypes = {
  setMFA: PropTypes.func.isRequires,
};

export default MFAInput;
