import React, { useEffect, useState } from 'react';
import { getAudioRollups } from 'shared/api';
import Loading from 'shared/Loading';

import RollupTable from './RollupTable';

const RollupTables = ({ podcastRollups, match, teamId, placement }) => {
  const [episodeData, setEpisodeData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleGetEpisodeRollup = podcastId => {
    setLoading(true);

    getAudioRollups({
      teamId,
      podcastId,
      audioAdCampaignId: placement.audioAdCampaignId,
      audioAdPlacementId: placement.audioAdPlacementId,
      startDate: null,
      endDate: null,
      pivotColumn: 'podcast_id',
      includeDetails: true,
    })
      .then(res => setEpisodeData(res.data[0].episodes))
      .finally(() => setLoading(false));
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div>
      <div className="mb2 b mid-gray">
        {episodeData ? (
          <div>
            Episodes
            <span className="ml2 f6 pointer link blue" onClick={() => setEpisodeData(null)}>
              Back to podcasts
            </span>
          </div>
        ) : (
          'Podcasts'
        )}
      </div>
      {episodeData ? (
        <RollupTable rollups={episodeData} dimension="Episode" />
      ) : (
        <RollupTable
          rollups={podcastRollups}
          dimension="Podcast"
          onRowClick={row => {
            handleGetEpisodeRollup(row.original.podcastId);
          }}
        />
      )}
    </div>
  );
};

export default RollupTables;
