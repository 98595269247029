import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Banner from 'shared/Banner';
import Button from 'shared/Button';
import SmallLoading from 'shared/SmallLoading';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import PodcastSearchInput from 'shared/PodcastSearchInput';
import { createSalesSheet } from 'shared/api';

const statsToInclude = [
  'Downloads Per Episode',
  'Gender Breakdown',
  'Most Active DMAs',
  'Consumption (when available)',
  'Audience Education, Age, & Net Worth',
];

const NewSalesSheetForm = ({ history, teamId, initialPodcast }) => {
  const [podcast, setPodcast] = useState(initialPodcast);
  const [date, setDate] = useState(new Date());
  const [createInProgress, setCreateInProgress] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateSalesSheet = async () => {
    setCreateInProgress(true);

    createSalesSheet({ podcastId: podcast.id, date, teamId })
      .then(res =>
        window.location.assign(`/teams/${teamId}/dashboard/sales_sheet/manage/${res.data.id}`),
      )
      .catch(err => {
        if (err.response.data.errors.failedToLoadDemographics) {
          setError(
            "This podcast doesn't have demographics, yet! We need more data before we can generate a demographics report.",
          );
        } else if (err.response.data.errors.noDownloads) {
          setError("We don't have any downloads for this podcast, yet!");
        } else {
          setError('Please reach out to support if this problem persists.');
        }
        setCreateInProgress(false);
      })
      .catch(err => {
        setError('Please reach out to support if this problem persists.');
        setCreateInProgress(false);
      });
  };

  return (
    <React.Fragment>
      {error && (
        <Banner type="error" className="mv3" onClick={() => setError(null)}>
          <div className="b f6 mb1">Failed to create sales sheet</div>
          <div className="f6">{error}</div>
        </Banner>
      )}
      <div className="mb4">
        <div className="f5 mb2 dark-gray">Select a podcast</div>
        <PodcastSearchInput onSelect={setPodcast} initialPodcast={podcast} className="f5 mw7" />
      </div>
      {/*
        We are not supporting date changes at launch so commenting this out for now -JS
        ===============================================================================
        <div className="mb4">
          <div className="f5 mb1 mt4 dark-gray">Date</div>
          <div className="f6 mid-gray mb2">We will generate your sales sheet data only up to this date</div>
          <ReactTooltip />
          <div data-tip="We currently only support generating a sales sheet for the current date">
            <ChartableDatePicker
              disabled
              day={date}
              disabledDays={{after: new Date()}}
              handleUpdateDay={setDate}
              singleDaySelect
            />
          </div>
        </div>
      */}
      <div>
        <div className="f5 mb3 dark-gray">Report will include:</div>
        {statsToInclude.map((item, index) => (
          <div key={item} className="items-center flex flex-row gap mb3">
            <div className="br-100 bg-mid-gray" style={{ height: '8px', width: '8px' }}></div>
            <div>{item}</div>
          </div>
        ))}
      </div>
      <Button
        disabled={!podcast || createInProgress}
        loading={createInProgress}
        type="create"
        onClick={handleCreateSalesSheet}
      >
        Generate Sales Sheet
      </Button>
    </React.Fragment>
  );
};

NewSalesSheetForm.propTypes = {
  teamId: PropTypes.string.isRequired,
  initialPodcast: PropTypes.object,
};

NewSalesSheetForm.defaultProps = {
  initialPodcast: null,
};

export default NewSalesSheetForm;
