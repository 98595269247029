import React from 'react';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Loading from 'shared/Loading';

import EpisodeTable from './EpisodeTable';

export default function DashboardEpisodesBrowserView({
  summary,
  teamId,
  episodes,
  sort,
  tableSortLoadInProgress,
  page,
  pageSize,
  onLoadEpisodes,
  onSort,
  selectedColumns,
  userInfo,
}) {
  const pageStyle = 'dib mr2 b--gray br2 ba bg-white';
  const linkStyle = 'dib pv2 ph3 pointer';
  const pageCount = Math.ceil(summary.numEpisodes / pageSize);

  return (
    <div className="mw9 ph3">
      {episodes ? (
        <div className="relative">
          <EpisodeTable
            episodes={episodes}
            baseUrl={`/teams/${teamId}/dashboard/episodes/?podcast_id=`}
            onSort={onSort}
            hasDownloads={summary.hasDownloadsData}
            hasViews={summary.hasViewsData}
            hasListens={summary.hasListensData && userInfo.showSpotify}
            reportingWindow={summary.reportingWindow}
            selectedColumns={selectedColumns}
            tableSortLoadInProgress={tableSortLoadInProgress}
          />
          <ReactPaginate
            className={pageCount === 1 && 'dn'}
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            breakClassName="dib mr2"
            pageClassName={pageStyle}
            previousClassName={page > 1 ? pageStyle : 'dn'}
            nextClassName={page < pageCount ? pageStyle : 'dn'}
            pageLinkClassName={linkStyle}
            previousLinkClassName={linkStyle}
            nextLinkClassName={linkStyle}
            activeClassName="bg-blue white"
            disabledClassName="dn"
            containerClassName={classNames(pageCount === 1 && 'dn', 'pa2 list pl0 w-100 center tc')}
            onPageChange={data => {
              onLoadEpisodes(data.selected + 1, sort);
            }}
            initialPage={page - 1}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            disableInitialCallback
          />
          {tableSortLoadInProgress && (
            <div className="absolute top-0 w-100 h-100">
              <div className="absolute top-0 w-100 h-100 bg-light-gray o-30" />
              <Loading className="mb5" fullScreen />
            </div>
          )}
        </div>
      ) : (
        <div className="mt2 w-100 tc center h-2">
          <Loading />
        </div>
      )}
    </div>
  );
}

DashboardEpisodesBrowserView.propTypes = {
  teamId: PropTypes.string.isRequired,
  podcastId: PropTypes.string,
  tableSortLoadInProgress: PropTypes.bool.isRequired,
  summary: PropTypes.object.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  onLoadEpisodes: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sort: PropTypes.object,
  episodes: PropTypes.array,
};

DashboardEpisodesBrowserView.defaultProps = {
  episodes: [],
};
