import React from 'react';
import memoize from 'memoize-one';
import moment from 'moment';
import PropTypes from 'prop-types';
import RankHistoryChart from 'shared/charts/RankHistoryChart';
import Loading from 'shared/Loading';
import UpsellDatePicker from 'shared/UpsellDatePicker';

import 'react-datepicker/dist/react-datepicker.css';

class DashboardChartsView extends React.Component {
  state = {
    startDate: null,
    endDate: null,
  };

  filterRankHistory = memoize((rankHistory, startDate, endDate) =>
    rankHistory.filter(t => moment(t[0]) >= startDate && moment(t[0]) <= endDate),
  );

  getPeakChartPosition = memoize(
    rankHistory =>
      Math.min.apply(
        null,
        rankHistory.filter(e => e[1] !== null).map(e => e[1]),
      ) || '-',
  );

  getLastCharted = memoize(rankHistory => moment(rankHistory[rankHistory.length - 1][0]));

  getFirstCharted = memoize(rankHistory => moment(rankHistory[0][0]));

  render() {
    const { rankHistory } = this.props;
    if (!rankHistory) {
      return (
        <div className="mw8">
          <div className="w-100 flex items-center justify-center h5 flex-wrap">
            <Loading />
          </div>
        </div>
      );
    }

    if (rankHistory.length <= 0) {
      return (
        <div className="mw8">
          <div className="w-100 flex items-center justify-center h5 flex-wrap">
            <div>Rank History is not available</div>
          </div>
        </div>
      );
    }

    const lastCharted = this.getLastCharted(rankHistory);
    const firstCharted = this.getFirstCharted(rankHistory);

    const startDate =
      this.state.startDate || this.props.minStartDate || moment().subtract(3, 'months').toDate();
    const minStartDate =
      this.props.minStartDate && new Date(this.props.minStartDate) > firstCharted.toDate()
        ? new Date(this.props.minStartDate)
        : firstCharted.toDate();
    const endDate = this.state.endDate ? this.state.endDate : new Date();

    const filteredRankHistory = this.filterRankHistory(rankHistory, startDate, endDate);
    const peakChartPosition = this.getPeakChartPosition(rankHistory);

    return (
      <div className="mw8">
        <div className="w-100 flex justify-between flex-wrap">
          <div className="w-third-l tl-ns tc mb2">
            <div className="f4-ns f5 b dark-blue tc">{lastCharted.format('MM-DD-YYYY') || '-'}</div>
            <div className="f6 gray ttu tracked tc">last charted</div>
          </div>
          <div className="w-third-l tl-ns tc mb2">
            <div className="f4-ns f5 b dark-blue tc">{peakChartPosition}</div>
            <div className="f6 gray ttu tracked tc">peak position</div>
          </div>
          <div className="w-third-l flex flex-row tr">
            <div className="w-100 w-50-ns mr2">
              <UpsellDatePicker
                selected={moment(startDate).toDate()}
                onChange={startDate => {
                  if (startDate > endDate) return;
                  this.setState({ startDate });
                }}
                isClearable
                selectsStart
                startDate={moment(startDate).toDate()}
                endDate={moment(endDate).toDate()}
                minDate={this.props.minStartDate ? moment(this.props.minStartDate).toDate() : null}
                placeholderText="Start date"
                teamId={this.props.teamId}
              />
            </div>
            <div className="w-100 w-50-ns">
              <UpsellDatePicker
                selected={moment(endDate).toDate()}
                onChange={endDate => {
                  if (startDate > endDate) return;
                  this.setState({ endDate });
                }}
                isClearable
                selectsEnd
                startDate={moment(startDate).toDate()}
                endDate={moment(endDate).toDate()}
                minDate={this.props.minStartDate ? moment(this.props.minStartDate).toDate() : null}
                placeholderText="End date"
                teamId={this.props.teamId}
              />
            </div>
          </div>
        </div>
        <div className="w-auto tr mt1">
          <div
            className="br2 dim pointer dib bg-blue ttu tracked ph2 pv1 white mr3 f6"
            onClick={() => {
              this.setState({
                startDate: moment().subtract(1, 'weeks').toDate(),
                endDate: new Date(),
              });
            }}
          >
            last week
          </div>
          <div
            onClick={() => {
              this.setState({
                startDate: moment().subtract(1, 'months').toDate(),
                endDate: new Date(),
              });
            }}
            className="br2 dim pointer dib bg-blue ttu tracked ph2 pv1 white mr3 f6"
          >
            last month
          </div>
          {!minStartDate && (
            <div
              onClick={() => {
                this.setState({
                  startDate: minStartDate,
                  endDate: minStartDate > lastCharted.toDate() ? new Date() : lastCharted.toDate(),
                });
              }}
              className="br2 dim pointer dib bg-blue ttu tracked ph2 pv1 white f6"
            >
              all time
            </div>
          )}
        </div>
        {filteredRankHistory.length > 0 ? (
          <RankHistoryChart rankHistory={filteredRankHistory} />
        ) : (
          <div className="mw8">
            <div className="w-100 flex items-center justify-center h5">
              <div className="f4 header-font">
                No Chart Placement during this interval. Try more dates!
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

DashboardChartsView.propTypes = {
  rankHistory: PropTypes.array,
  minStartDate: PropTypes.instanceOf(Date),
  teamId: PropTypes.string.isRequired,
};

DashboardChartsView.defaultProps = {
  rankHistory: null,
  minStartDate: null,
};

export default DashboardChartsView;
