import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import refreshIcon from 'images/icons/refresh.png';
import { deleteTeamPodcast, getTeamPodcasts, searchTeamPodcasts } from 'shared/api';
import { refreshTrackableStatus } from 'shared/api/v1';
import BannerNotice from 'shared/BannerNotice';
import ConfirmationModal from 'shared/ConfirmationModal';
import Loading from 'shared/Loading';
import PodcastTracker from 'shared/PodcastTracker';
import Sidebar from 'shared/Sidebar';

import 'react-table/react-table.css';
import { readJsonConfigFile } from 'typescript';

const toastOptions = {
  duration: 10000,
};

const URL_ADD = 'url_add';
const SEARCH_ADD = 'search_add';
const FEED_ADD = 'feed_add';

const DashboardPodcasts = props => {
  const { teamId, removePodcast, sidebar, subSidebar, readOnlyMode } = props;
  const [loading, setLoading] = useState(true);
  const [podcasts, setPodcasts] = useState(null);
  const [podcastCount, setPodcastCount] = useState(null);
  const [pageCount, setPageCount] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addOpen, setAddOpen] = useState(false);
  const [banner, setBanner] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [showSearch, setShowSearch] = useState(null);
  const [addStep, setAddStep] = useState(0);
  const [addMethod, setAddMethod] = useState(SEARCH_ADD);
  const [addResult, setAddResult] = useState(null);
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [refreshing, setRefreshing] = useState({});

  const fetchPods = async () => {
    let podcastArray;
    let podcastTotal;
    let pages;
    setLoading(true);
    if (searchText) {
      try {
        const res = await searchTeamPodcasts({
          teamId,
          q: searchText,
          excludeStatistics: true,
          page: page,
          pageSize,
        });
        podcastArray = (res.data && res.data.results) || [];
        podcastTotal = res.data.total;
        pages = Math.ceil(podcastTotal / pageSize);
      } catch (error) {
        toast.error(
          'Error fetching podcasts. Please reload the page or contact support.',
          toastOptions,
        );
      }
    } else {
      try {
        const res = await getTeamPodcasts({ teamId, page: page, pageSize });
        podcastArray = res.data.results;
        podcastTotal = res.data.total;
        pages = Math.ceil(podcastTotal / pageSize);
        setShowSearch(pages > 1);
      } catch (error) {
        toast.error(
          'Error fetching podcasts. Please reload the page or contact support.',
          toastOptions,
        );
      }
    }
    setPageCount(pages);
    setPodcastCount(podcastTotal);
    setPodcasts(podcastArray);
    setLoading(false);
  };

  const refreshStatus = async podcastId => {
    setRefreshing(state => {
      return { ...state, [podcastId]: 1 };
    });

    try {
      const res = await refreshTrackableStatus(teamId, { podcastId });
      const { trackableEnabled } = res.data;
      setPodcasts(pods => {
        return pods.map(pod =>
          pod.id === podcastId ? { ...pod, trackableEnabled: trackableEnabled } : pod,
        );
      });
      setRefreshing(state => {
        return { ...state, [podcastId]: 0 };
      });
    } catch (e) {
      toast.error(e.message, toastOptions);
    }
  };

  useEffect(() => {
    if (!!searchText && searchText.length < 3) {
      return;
    }
    fetchPods();
  }, [page, searchText]);

  const renderList = () => {
    const columns = [
      {
        Header: 'Podcast',
        accessor: 'title',
        Cell: row => {
          return (
            <div>
              <span className="f7 dib mr3">{row.original.title}</span>
              <span className="f7 silver dib">{row.original.feedUrl}</span>
            </div>
          );
        },
      },
      {
        Header: 'Trackable Status',
        accessor: 'trackableEnabled',
        maxWidth: 175,
        className: 'tc',
        Cell: row => {
          if (refreshing[row.original.id] == 1) {
            return (
              <div style={{ margin: '0 auto' }} className="mw2">
                <Loading small />
              </div>
            );
          }
          return (
            <div>
              <div className="tc dib">
                {row.original.trackableEnabled ? (
                  <span className="code f7">✔️</span>
                ) : (
                  <span className="code f7 dib" style={{ marginTop: '-5px' }}>
                    <span
                      className="relative"
                      style={{ top: '2px', right: '2px', fontSize: '26px' }}
                    >
                      -
                    </span>
                  </span>
                )}
              </div>
              {!readOnlyMode && (
                <div
                  onClick={() => refreshStatus(row.original.id)}
                  className="f7 tc dib link blue"
                  data-tip="Refresh Trackable Status"
                  data-for={row.original.id}
                >
                  <ReactTooltip place="bottom" id={row.original.id} />
                  <img
                    className="relative ml2 mw1"
                    style={{ opacity: 0.4, top: '3px' }}
                    src={refreshIcon}
                  />
                </div>
              )}
            </div>
          );
        },
      },
      {
        Header: '',
        getHeaderProps: () => ({
          className: 'mw5',
        }),
        className: 'tc',
        maxWidth: 100,
        Cell: row => renderRemove(row.original),
      },
    ];
    return (
      <div className="mb4">
        {showSearch && (
          <input
            type="text"
            className="pa2 input-reset ba bg-white br2 b--silver mb3 w-100"
            onChange={handleFilter}
            placeholder="Search for a podcast"
          />
        )}
        {loading ? (
          <div style={{ height: '861px' }}>
            <Loading fullScreen isChild />
          </div>
        ) : (
          <ReactTable
            getTrProps={() => ({
              style: {
                display: 'flex',
                alignItems: 'center',
                height: '40px',
              },
            })}
            data={podcasts}
            columns={columns}
            pageSize={Math.min(20, podcasts.length)}
            showPagination={false}
            className="-striped -highlight w-100 f5 pointer"
          />
        )}
      </div>
    );
  };

  const handleFilter = ev => {
    const q = ev.currentTarget.value;
    setPage(1);
    setSearchText(q);
  };

  const handleRemove = async (podcast, confirm) => {
    if (confirm) {
      setConfirmingDelete(podcast);
      return;
    }
    doRemovePodcast(podcast);
  };

  const doRemovePodcast = async podcast => {
    setLoading(true);
    const teamPodcastId = podcast.teamPodcastId;
    try {
      const res = await deleteTeamPodcast({ teamId, teamPodcastId });
      toast.success(
        <span>
          You have removed <span className="i">{podcast.title}</span>
        </span>,
        toastOptions,
      );
      fetchPods();
      setConfirmingDelete(false);
      setPodcasts(podcasts => {
        return podcasts.filter(p => p.teamPodcastId !== teamPodcastId);
      });
    } catch (e) {
      const message = e.response.data.error || e;
      alert(message);
    }
    setConfirmingDelete(false);
    setLoading(false);
  };

  const renderRemove = podcast => {
    if (readOnlyMode) {
      return null;
    }

    if (!removePodcast) {
      return '-';
    }
    return (
      <div
        onClick={() => handleRemove(podcast, podcast.hasExternalData)}
        className="pointer bg-light-red pa1 dib f6 br2"
      >
        <span className="white nowrap">Remove</span>
      </div>
    );
  };

  const renderHeader = () => {
    if (readOnlyMode) {
      return null;
    }

    return (
      <div className="mb3 flex items-center justify-between">
        <div className="f3 dark-blue">Podcasts</div>
        {props.userInfo.hasMegaphoneBundle && (
          <div
            onClick={() => setAddOpen(true)}
            className="f6 tr bg-green dim white pointer nowrap pa2 br2"
          >
            Add a podcast
          </div>
        )}
      </div>
    );
  };

  const handleAddedPodcast = result => {
    if (result.success) {
      toast.success('Your podcast has been added.', toastOptions);
      setAddOpen(false);
      setAddStep(0);
      fetchPods();
    } else {
      toast.error(result.error, toastOptions);
      setAddOpen(false);
      setAddStep(0);
    }
  };

  const renderModalStep = () => {
    switch (addStep) {
      case 0:
        return (
          <div className="tc">
            <span className="dib mb3">How would you like to find your show?</span>
            <div
              style={{ margin: '10px auto' }}
              className="bg-dark-blue dim white pointer nowrap pa2 mw5 tc br2"
              onClick={() => {
                setAddMethod(SEARCH_ADD);
                setAddStep(addStep + 1);
              }}
            >
              Search by Title
            </div>
            <div
              style={{ margin: '10px auto' }}
              className="bg-dark-blue dim white pointer nowrap pa2 mw5 tc br2"
              onClick={() => {
                setAddMethod(URL_ADD);
                setAddStep(addStep + 1);
              }}
            >
              Add by Apple Podcasts URL
            </div>
            <div
              style={{ margin: '0 auto' }}
              className="bg-dark-blue dim white pointer nowrap pa2 mw5 tc br2"
              onClick={() => {
                setAddMethod(FEED_ADD);
                setAddStep(addStep + 1);
              }}
            >
              Add Private Podcast
            </div>
          </div>
        );
      case 1:
        return (
          <div className="tc">
            <PodcastTracker method={addMethod} callback={handleAddedPodcast} autoFocus {...props} />
          </div>
        );
      case 2:
        return (
          <div>
            {addResult === 'success' ? (
              <h2 className="tc green">Your podcast has been added.</h2>
            ) : (
              <h2 className="tc red">{addResult}</h2>
            )}
          </div>
        );
    }
  };

  const renderAddModal = () => {
    return (
      <div className="">
        <Modal
          shouldCloseOnOverlayClick
          isOpen
          onRequestClose={() => {
            setAddStep(0);
            setAddResult(null);
            setAddOpen(false);
          }}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 1 },
            content: {
              outline: 'none',
              maxWidth: '410px',
              height: '260px',
              paddingTop: '0px',
              margin: 'auto',
            },
          }}
        >
          <h2 className="tc">Add a Podcast</h2>
          {renderModalStep()}
        </Modal>
      </div>
    );
  };

  const renderDeleteModal = () => {
    const pod = confirmingDelete;
    return (
      <ConfirmationModal
        title="Confirm Podcast Removal"
        message={
          loading
            ? 'Deleting Podcast...'
            : 'This podcast has associated external data. Are you sure you want to remove it from your team profile?'
        }
        onClose={() => setConfirmingDelete(false)}
        isOpen
        onConfirm={() => {
          doRemovePodcast(pod);
        }}
      />
    );
  };

  return (
    <Sidebar message={props.message} sidebar={sidebar} subSidebar={subSidebar}>
      <Toaster />
      {addOpen && renderAddModal()}
      {confirmingDelete && renderDeleteModal()}
      <div>
        <div style={{ margin: '0 auto' }} className="mw8 pa3">
          {renderHeader()}
          {renderList()}
          <ReactPaginate
            className={pageCount === 1 && 'dn'}
            previousLabel="&laquo;"
            nextLabel="&raquo;"
            breakClassName="dib mr2"
            pageClassName="dib mr2 b--gray br2 ba bg-white"
            previousClassName={page > 1 ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
            nextClassName={page < pageCount ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
            pageLinkClassName="dib pv2 ph3 pointer"
            previousLinkClassName="dib pv2 ph3 pointer"
            nextLinkClassName="dib pv2 ph3 pointer"
            activeClassName="bg-blue white"
            disabledClassName="dn"
            containerClassName={`${pageCount === 1 && 'dn'} pa2 list pl0 w-100 center tc`}
            onPageChange={event => setPage(event.selected + 1)}
            initialPage={page - 1}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            disableInitialCallback
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default DashboardPodcasts;
