import React, { useState } from 'react';
import { signIn as apiSignIn, peekUser, enableMFA } from 'shared/api';
import MFAForm from './components/MFAForm';
import EmailPassForm from './components/EmailPassForm';
import MegaphoneSignup from './components/MegaphoneSignup';
import MegaphoneOnboardingSignup from './components/MegaphoneOnboardingSignup';
import EnableMFA from 'shared/EnableMFA';
import Button from 'shared/Button';
import { popupCenter } from 'shared/helpers';

const STANDARD_AUTH = 'STANDARD_SIGN_IN';
const MFA_AUTH = 'MFA_AUTH';
const ENABLE_MFA = 'ENABLE_MFA';
const MEGAPHONE_SIGNUP = 'MEGAPHONE_SIGNUP';
const MEGAPHONE_ONBOARDING = 'MEGAPHONE_ONBOARDING';


const SignIn = ({ isMegaphoneSignup, isOnboarding }) => {
  const [state, doSetState] = useState({
    viewing: isMegaphoneSignup ? (isOnboarding ? MEGAPHONE_ONBOARDING : MEGAPHONE_SIGNUP) : STANDARD_AUTH,
    email: '',
    password: '',
    mfa: null,
    errors: false,
    secretKey: null,
    qrUri: null,
  });

  const setState = setter => {
    doSetState(prevState => ({
      ...prevState,
      ...setter,
    }));
  };

  const handleChange = event => {
    setState({
      [event.target.name]: event.target.value,
    });
  };

  const submitMFA = async ev => {
    ev.preventDefault();
    const { email, password, mfa } = state;
    try {
      const { data } = await enableMFA({ email, password, mfa });
      if (data.success) await signIn();
    } catch ({
      response: {
        data: { errors },
      },
    }) {
      setState({ errors });
    }
  };

  const signIn = async () => {
    const { email, password, mfa } = state;
    try {
      const { data } = await apiSignIn({ email, password, mfa });
      if (data.success) window.location = data.redirect;
    } catch ({
      response: {
        data: { errors },
      },
    }) {
      setState({ errors });
    }
  };

  const setMFA = mfa => {
    setState({ mfa });
  };

  const submitForm = async () => {
    const { email, password, viewing } = state;
    // if we're looking at MFA auth, we've already gotten
    // all we need from peekUser() - go ahead and attempt sign in
    if (viewing === MFA_AUTH) {
      return await signIn();
    }
    try {
      const { data } = await peekUser({ email, password });
      switch (data.status) {
        case 'enable_mfa':
          setState({
            errors: '',
            viewing: ENABLE_MFA,
            qrUri: data.qrUri,
            secretKey: data.secretKey,
          });
          break;
        case 'auth_mfa':
          setState({
            errors: '',
            viewing: MFA_AUTH,
          });
          break;
        case 'signed_in':
        default:
          if (data.success) {
            window.location = data.redirect;
          } else {
            setState({ errors: data.errors });
          }
      }
    } catch ({
      response: {
        data: { errors },
      },
    }) {
      setState({ errors });
    }
  };

  const handleSubmission = event => {
    event.preventDefault();
    submitForm();
  };

  const renderMegaphoneButton = () => (
    <Button
      style={{ backgroundColor: '#7474ec' }}
      size="large"
      className="ph3 mt2 pv2 input-reset bn white pointer f5 dib dim w-100 br2 header-font"
      onClick={() => {
        popupCenter(
          '/oauth/authorize_megaphone?redirect_path=oauth/megaphone_oauth_callback',
          'megaphoneOauthWindow',
          735,
          675,
        );
      }}
    >
      Log in with Megaphone
    </Button>
  );

  const renderErrors = () => {
    const { errors } = state;
    if (!errors) {
      return null;
    }
    return <div style={{ color: 'red' }}>{errors}</div>;
  };

  const renderTitle = () => {
    const { viewing } = state;
    if (viewing === MEGAPHONE_ONBOARDING) {
      return null;
    }
    let title;
    switch (viewing) {
      case ENABLE_MFA:
        title = 'Enable two-factor authentication';
        break;
      default:
        title = 'Log in to your account';
        break;
    }
    return <div className={`f2 gray mb3`}>{title}</div>

  };

  const renderForm = () => {
    const { viewing, qrUri, secretKey } = state;
    let form;
    switch (viewing) {
      case STANDARD_AUTH:
        form = (
          <EmailPassForm
            errors={renderErrors}
            handleChange={handleChange}
            handleSubmission={handleSubmission}
            renderMegaphoneButton={renderMegaphoneButton}
          />
        );
        break;
      case MFA_AUTH:
        form = (
          <MFAForm
            errors={renderErrors}
            handleChange={handleChange}
            handleSubmission={handleSubmission}
            setMFA={setMFA}
          />
        );
        break;
      case ENABLE_MFA:
        form = (
          <EnableMFA
            errors={renderErrors}
            handleSubmission={submitMFA}
            setMFA={setMFA}
            qrUri={qrUri}
            secretKey={secretKey}
          />
        );
        break;
      case MEGAPHONE_SIGNUP:
        form = (
          <MegaphoneSignup renderMegaphoneButton={renderMegaphoneButton} errors={renderErrors} />
        );
        break;
      case MEGAPHONE_ONBOARDING:
        form = (
          <MegaphoneOnboardingSignup renderMegaphoneButton={renderMegaphoneButton}/>
        )
    }
    return (
      <div className={`${!isMegaphoneSignup && 'ba br2'} pa2 center w-100 b--primary bg-white`}>
        {form}
      </div>
    );
  };

  const renderForgot = () => {
    if (state.viewing === MEGAPHONE_ONBOARDING) {
      return null;
    }
    return (
      <div className="mt3 cf">
        <a className={`link blue`} href="/passwords/new">
          Forgot your password?
        </a>
      </div>
    );
  };

  return (
    <div className={`mw8 center pv3 ph2 ph0-ns`}>
      <div className={`${state.viewing === MEGAPHONE_ONBOARDING ? '' : 'mt3 center w-75 mw6'}`}>
        {renderTitle()}
        {renderForm()}
        {renderForgot()}
      </div>
    </div>
  );
};

export default SignIn;
