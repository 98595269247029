"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getButtonPaddingValues = exports.getButtonPadding = exports.getButtonFont = exports.defaultTypeVariants = exports.defaultButtonPaddingLeftRight = exports.buttonWithIconSidePadding = exports.buttonWithIconMargin = exports.buttonTransitionFade = exports.buttonTextTransform = exports.buttonStylesHover = exports.buttonStylesDisabled = exports.buttonStylesBase = exports.buttonStylesActive = exports.buttonLinkReset = exports.buttonHoverScale = exports.buttonBorderRadius = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
var _styledComponents = require("styled-components");
var _EncoreContext = require("../../contexts/EncoreContext");
var _typeHelpers = require("../../typeHelpers");
var _ = require("../");
//
// Buttons
// --------------------------------------------------

var buttonBorderRadius = '500px';
exports.buttonBorderRadius = buttonBorderRadius;
var buttonTransitionFade = '33ms';
exports.buttonTransitionFade = buttonTransitionFade;
var buttonTextTransform = 'none';
exports.buttonTextTransform = buttonTextTransform;
var buttonHoverScale = '1.04';

/**
 * Left and right padding for buttons without icons.
 * For buttons with `iconLeading` or `iconTrailing`, see `getButtonWithIconLeftRightPadding()`.
 */
exports.buttonHoverScale = buttonHoverScale;
var defaultButtonPaddingLeftRight = {
  sm: _encoreFoundation.spacer16,
  md: _encoreFoundation.spacer32,
  lg: _encoreFoundation.spacer48
};

/**
 * Observed left &/or right padding for buttons with `iconLeading` or `iconTrailing`.
 * Note: "Observed" because the true padding is calculated, see `getButtonWithIconLeftRightPadding()`.
 */
exports.defaultButtonPaddingLeftRight = defaultButtonPaddingLeftRight;
var buttonWithIconSidePadding = {
  sm: _encoreFoundation.spacer12,
  md: _encoreFoundation.spacer20,
  lg: _encoreFoundation.spacer24
};

/** Observed margin between icons and text (absolute positioned, not real margin) */
exports.buttonWithIconSidePadding = buttonWithIconSidePadding;
var buttonWithIconMargin = {
  sm: '5px',
  md: _encoreFoundation.spacer12,
  lg: '10px'
};
exports.buttonWithIconMargin = buttonWithIconMargin;
var defaultTypeVariants = {
  sm: 'mestoBold',
  md: 'balladBold',
  lg: 'cello'
};
exports.defaultTypeVariants = defaultTypeVariants;
var getButtonFont = function getButtonFont(buttonSize, status) {
  if (status === _EncoreContext.encoreContextStatus.deprecated) {
    return (0, _.getDeprecatedButtonFont)(buttonSize);
  }
  switch (buttonSize) {
    case 'sm':
      return (0, _.mestoBold)();
    case 'md':
      return (0, _.balladBold)();
    case 'lg':
      return (0, _.cello)();
    default:
      return (0, _.balladBold)();
  }
};

/**
 * Calculates side padding for buttons with `iconLeading` &/or `iconTrailing`.
 * Accounts for the icon's size, padding on the button edge, and margin between icons and text.
 * Used in ButtonPrimary and ButtonSecondary.
 */
exports.getButtonFont = getButtonFont;
var getButtonWithIconLeftRightPadding = function getButtonWithIconLeftRightPadding(buttonSize) {
  var padding = buttonWithIconSidePadding[buttonSize];
  var margin = buttonWithIconMargin[buttonSize];
  var iconSize = _.controlIconSize[buttonSize];
  return (0, _typeHelpers.pxToInt)(padding) + iconSize + (0, _typeHelpers.pxToInt)(margin);
};
var getButtonPaddingValues = function getButtonPaddingValues(buttonSize) {
  var borderWidth = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var status = arguments.length > 2 ? arguments[2] : undefined;
  var paddingTopBottom;
  if (status === 'deprecated') {
    paddingTopBottom = "".concat((0, _typeHelpers.pxToInt)(_.deprecatedButtonPaddingTopBottom[buttonSize]) - borderWidth, "px");
  } else {
    paddingTopBottom = "".concat((0, _typeHelpers.pxToInt)(_.controlPaddingBlock[buttonSize]) - borderWidth, "px");
  }
  var paddingLeftRight = "".concat((0, _typeHelpers.pxToInt)(defaultButtonPaddingLeftRight[buttonSize]) - borderWidth, "px");
  var withIconPaddingLeftRight = "".concat(getButtonWithIconLeftRightPadding(buttonSize) - borderWidth, "px");
  return {
    paddingTopBottom: paddingTopBottom,
    paddingLeftRight: paddingLeftRight,
    withIconPaddingLeftRight: withIconPaddingLeftRight
  };
};
exports.getButtonPaddingValues = getButtonPaddingValues;
var getButtonPadding = function getButtonPadding(buttonSize, iconLeading, iconTrailing, iconOnly, borderWidth, status) {
  var _getButtonPaddingValu = getButtonPaddingValues(buttonSize, borderWidth, status),
    paddingTopBottom = _getButtonPaddingValu.paddingTopBottom,
    paddingLeftRight = _getButtonPaddingValu.paddingLeftRight,
    withIconPaddingLeftRight = _getButtonPaddingValu.withIconPaddingLeftRight;

  /** iconOnly buttons used fixed width & height instead of padding */
  var iconOnlyDimensions = (0, _styledComponents.css)(["inline-size:", ";block-size:", ";"], _.controlMinBlockSize[buttonSize], _.controlMinBlockSize[buttonSize]);
  var noIconPadding = (0, _styledComponents.css)(["padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";"], paddingTopBottom, paddingTopBottom, paddingLeftRight, paddingLeftRight);
  var symmetricalIconPadding = (0, _styledComponents.css)(["padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";"], paddingTopBottom, paddingTopBottom, withIconPaddingLeftRight, withIconPaddingLeftRight);
  var asymmetricalIconPadding = (0, _styledComponents.css)(["padding-block-start:", ";padding-block-end:", ";padding-inline-start:", ";padding-inline-end:", ";"], paddingTopBottom, paddingTopBottom, iconLeading ? withIconPaddingLeftRight : paddingLeftRight, iconTrailing ? withIconPaddingLeftRight : paddingLeftRight);
  if (iconOnly) {
    return iconOnlyDimensions;
  }
  if (!iconLeading && !iconTrailing) {
    return noIconPadding;
  }
  if (iconLeading && iconTrailing) {
    return symmetricalIconPadding;
  }
  return asymmetricalIconPadding;
};

/** Base styles shared by most buttons, including root style, focus reset and font sizes */
exports.getButtonPadding = getButtonPadding;
var buttonStylesBase = function buttonStylesBase(buttonSize, useBrowserDefaultFocusStyle, status) {
  return (0, _styledComponents.css)(["", ";", " ", ";background-color:transparent;border:0;border-radius:", ";display:inline-block;position:relative;text-align:center;text-decoration:none;text-transform:", ";touch-action:manipulation;transition-duration:", ";transition-property:background-color,border-color,color,box-shadow,filter,transform;user-select:none;vertical-align:middle;transform:translate3d(0,0,0);"], (0, _.rootStyle)(), !useBrowserDefaultFocusStyle && _.browserFocusReset, getButtonFont(buttonSize, status), buttonBorderRadius, buttonTextTransform, buttonTransitionFade);
};

/** Disabled styles shared by most buttons */
exports.buttonStylesBase = buttonStylesBase;
var buttonStylesDisabled = (0, _styledComponents.css)(["cursor:", ";opacity:", ";transform:scale(1);"], _.cursorDisabled, _.opacityDisabled);

/** Active styles shares by most buttons */
exports.buttonStylesDisabled = buttonStylesDisabled;
var buttonStylesActive = (0, _styledComponents.css)(["opacity:", ";outline:none;transform:scale(1);"], _.opacityActive);

/** Hover styles shares by most buttons */
exports.buttonStylesActive = buttonStylesActive;
var buttonStylesHover = (0, _styledComponents.css)(["transform:scale(", ");"], buttonHoverScale);

// Reset a button to a link looking thing
exports.buttonStylesHover = buttonStylesHover;
var buttonLinkReset = function buttonLinkReset() {
  var useBrowserDefaultFocusStyle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return (0, _styledComponents.css)(["background-color:transparent;border:0;color:inherit;line-height:1;letter-spacing:inherit;padding:0;", ""], !useBrowserDefaultFocusStyle && _.browserFocusReset);
};
exports.buttonLinkReset = buttonLinkReset;