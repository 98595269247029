import React, { useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import axios from 'axios';
import ExclaimIcon from 'images/icons/encore-exclaim.png';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';
import ChartHistoryChart from 'shared/charts/ChartHistoryChart';
import ConsumptionChart from 'shared/charts/ConsumptionChart';
import PacingChartView from 'shared/charts/PacingChartView';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import Stat from 'shared/Stat/index';
import { withUser } from 'shared/UserContext';

import PodcastTable from './PodcastTable';
import RetentionSwitcher from './RetentionSwitcher';

// This takes the legacy statistics data that is returned
// from Podcasts and allows reformats it to the new json format.
// Can be removed once we switch to public endpoint.
const formatLegacyStatistics = statistics => {
  const iabListeners =
    statistics.trailing.iabListeners &&
    statistics.trailing.iabListeners[statistics.reportingWindow] > 0;
  const showReviews = !iabListeners && !statistics.subscribers;
  const showReach =
    statistics.trailing.iabListeners &&
    statistics.trailing.iabListeners[statistics.reportingWindow] &&
    statistics.trailing.iabListeners[statistics.reportingWindow] > 0;

  return {
    iabListeners: showReach ? statistics.trailing.iabListeners[statistics.reportingWindow] : null,
    iabListenersGrowth: showReach
      ? statistics.trailing.iabListeners[`${statistics.reportingWindow}Growth`]
      : null,
    downloads: statistics.trailing.downloads[statistics.reportingWindow],
    downloadsGrowth: statistics.trailing.downloads[`${statistics.reportingWindow}Growth`],
    reviews: showReviews ? statistics.trailing.reviews[statistics.reportingWindow] : null,
    rating: showReviews ? statistics.rating : null,
  };
};

function DashboardOverviewView({
  trackableEnabled,
  canViewRetentionChart,
  showRetentionTour,
  team,
  podcast,
  podcasts,
  totalPodcasts,
  podcastTablePage,
  updatePodcastTableSort,
  loadingDownloadsHistory,
  downloadsHistory,
  downloadsHistoryAggregation,
  onGetDownloadsHistory,
  loadingPlaysHistory,
  pacingHistory,
  playsHistory,
  playsHistoryAggregation,
  onGetPlaysHistory,
  loadingConsumptionsSeries,
  consumptionSeries,
  onChangeDownloadsEndDate,
  onChangeDownloadsStartDate,
  onChangePlaysEndDate,
  onChangePlaysStartDate,
  downloadsStartDate,
  downloadsEndDate,
  playsStartDate,
  playsEndDate,
  podcastsCount,
  updatePodcastTablePage,
  tableSortLoadInProgress,
  hasEnoughDataForRetentionChart,
  hasRetentionChart,
  hasEnoughDataForEpisodicRetention,
  allowedOnAllPodcasts,
  trailingStats,
  reportingWindow,
  userInfo,
}) {
  const statistics = podcast ? formatLegacyStatistics(podcast.statistics) : trailingStats;

  const {
    downloads,
    downloadsGrowth,
    iabListeners,
    iabListenersGrowth,
    rating,
    reviews,
    reviewsGrowth,
  } = statistics;

  const urlBase = `/teams/${team.slug}/dashboard`;
  const trackableUrl = `${urlBase}/integrations/trackable`;
  const chartsUrl = `${urlBase}/charts${podcast ? `?podcast_id=${podcast.id}` : ''}`;
  const vipRef = useRef();
  const [showCXEmailBanner, setShowCXEmailBanner] = useState(false);

  const setPodcast = ({ type, data }) => {
    if (type === 'all') {
      window.location = urlBase;
      return;
    }
    window.location = `${urlBase}?podcast_id=${data.id}`;
  };

  const isLoading = !podcast && window.location.href.indexOf('podcast_id') >= 0;
  const manyPodcasts = podcastsCount > 1;

  // This is a hack to determine if the user is only looking at one podcast vs. "All" podcasts.
  // Right now, the query for podcast also returns a bunch of data, which makes it very slow.
  // If we could split the query so that we get "basic" info on the podcast we have a full page load
  // until it gets back, and then load each chart individually with that data.
  // ...oh also all of dashboard overview isn't tied to react router.
  // -JS
  const location = window.location;
  const viewingPodcastDetail = !!new URLSearchParams(location.search).get('podcast_id');

  const showReviews = !!rating;
  const showReach = !!iabListeners;
  const cardCount = [showReviews, showReach, showReviews].reduce((a, b) => (a += b ? 1 : 0), 1);

  const sendVIPEmail = () => {
    vipRef.current.remove();
    setShowCXEmailBanner(true);
    axios.get(`/teams/${userInfo.teamId}/send_team_data_to_cx`);
  };

  const megaphoneOnboardingBanner = () => {
    if (document.location.search.includes('megaphone_onboarding')) {
      return (
        <Banner
          className="mv2 f5 lh-solid"
          image={ExclaimIcon}
          backgroundColor="#FFA42B"
          textColor="#000"
        >
          Get the most out of Chartable by&nbsp;
          <a className="black" href={`/teams/${userInfo.teamId}/dashboard/integrations/trackable`}>
            installing your Trackable prefix
          </a>
          &nbsp;on your RSS feed.
          <span ref={vipRef}>
            {vipRef.current && (
              <>
                <br />
                <br />
                Need help?{' '}
                <a className="black underline pointer" onClick={() => sendVIPEmail()}>
                  Click here
                </a>{' '}
                and our support team will reach out to you.
              </>
            )}
          </span>
        </Banner>
      );
    }
    return <></>;
  };

  return (
    <div>
      <div className="mw8 pa3">
        {showCXEmailBanner && (
          <BannerNotice message="A member of our customer service team will reach out to you shortly!" />
        )}
        {megaphoneOnboardingBanner()}
        <ReactTooltip />
        <div className="flex flex-wrap w-100 mb4 items-center">
          {manyPodcasts && (
            <PodcastSwitcher
              teamId={team.slug}
              onSelect={setPodcast}
              loading={isLoading}
              selectedPodcast={podcast || null}
              defaultToNone={false}
              defaultToAll={false}
              includeNone={false}
              includeSelect={!allowedOnAllPodcasts}
              defaultToSelect={!allowedOnAllPodcasts}
              includeAll={allowedOnAllPodcasts}
              numberOfDefaultOptionsToFetch={podcasts && podcasts.length}
              className="w-80 mr2"
            />
          )}
          <a
            className="link no-underline white"
            style={{ flex: 1 }}
            target="_blank"
            href={`/teams/${
              team.slug
            }/dashboard/reports/create?product=Analytics&reportType=PODCAST_OVERVIEW_REPORT${
              podcast ? `&podcastId=${podcast.id}` : ''
            }`}
          >
            <Button type="primary" className={!manyPodcasts && 'mw4 ml-auto'}>
              Export
            </Button>
          </a>
        </div>
        <div className="mb4 flex justify-between">
          <Stat
            cardCount={cardCount}
            label={`downloads—${reportingWindow}d`}
            stat={isLoading ? false : downloads || 'Unknown'}
            percentGrowth={isLoading ? false : downloadsGrowth}
          />
          {!!iabListeners && (
            <Stat
              cardCount={cardCount}
              label={`reach-${reportingWindow}d`}
              stat={isLoading ? false : iabListeners}
              percentGrowth={isLoading ? false : iabListenersGrowth}
            />
          )}
          {showReviews && (
            <Stat
              cardCount={cardCount}
              label={`reviews—${reportingWindow}d`}
              stat={isLoading ? false : reviews}
              percentGrowth={isLoading ? false : reviewsGrowth}
            />
          )}
          {showReviews && (
            <Stat
              stars
              cardCount={cardCount}
              label="average rating"
              stat={isLoading ? false : rating}
            />
          )}
        </div>
        {podcast && pacingHistory && pacingHistory.length !== 0 && (
          <PacingChartView episodes={pacingHistory} />
        )}
        {((downloadsHistory && downloadsHistory.length > 0) ||
          loadingDownloadsHistory ||
          downloadsHistoryAggregation === 'hourly') && (
          <ChartHistoryChart
            history={downloadsHistory}
            aggregation={downloadsHistoryAggregation}
            startDate={downloadsStartDate}
            endDate={downloadsEndDate}
            handleChangeEndDate={onChangeDownloadsEndDate}
            handleChangeStartDate={onChangeDownloadsStartDate}
            onGetHistory={onGetDownloadsHistory}
            loading={loadingDownloadsHistory}
            trackableUrl={trackableUrl}
            height="480px"
            teamId={team.slug}
          />
        )}
        {hasRetentionChart && (
          <RetentionSwitcher
            teamId={team.slug}
            hasRetentionChart={hasRetentionChart}
            teamPodcastId={podcast ? podcast.teamPodcastId : null}
            showTour={showRetentionTour}
            forceLoading={!podcast}
            viewingPodcastDetail={viewingPodcastDetail}
            canViewRetentionChart={canViewRetentionChart}
            trackableEnabled={trackableEnabled}
            hasEnoughDataForEpisodicRetention={hasEnoughDataForEpisodicRetention}
            hasEnoughDataForRetentionChart={hasEnoughDataForRetentionChart}
          />
        )}
        {!loadingConsumptionsSeries && consumptionSeries && consumptionSeries.length > 1 && (
          <ConsumptionChart
            consumptionSeries={consumptionSeries}
            consumptionStatistics={statistics.consumption}
          />
        )}
        {((playsHistory && playsHistory.length > 0) || loadingPlaysHistory) && (
          <ChartHistoryChart
            title="Confirmed Plays"
            subtitle="Listens longer than 60 seconds"
            legendPosition="top"
            history={playsHistory}
            startDate={playsStartDate}
            endDate={playsEndDate}
            onGetHistory={onGetPlaysHistory}
            handleChangeEndDate={onChangePlaysEndDate}
            handleChangeStartDate={onChangePlaysStartDate}
            aggregation={playsHistoryAggregation}
            loading={loadingPlaysHistory}
            teamId={team.slug}
          />
        )}
        {podcast && podcast.chartPositions && Object.keys(podcast.chartPositions).length > 0 && (
          <div className="w-100 mb3">
            <div className="f3 dark-blue mb2 w-100">
              <a href={chartsUrl} className="link dark-blue">
                Charts at a glance
              </a>
            </div>
            <table className="w-100 f5">
              <tr>
                <th className="tl f7 ttu silver w3">Rank</th>
                <th className="tl f7 ttu silver w3">Change</th>
                <th />
              </tr>
            </table>
            <div className="b4 br2 pa3 bg-white h-100">
              {Object.entries(podcast.chartPositions).map(([provider, positions]) => (
                <table className="w-100 f5" key={provider}>
                  <tbody>
                    {positions.map(position => (
                      <tr className="mb2 w-100" key={position.chart.id + position.chart.chartType}>
                        <td className="pr2 pb2 w3">
                          {position.position ? (
                            <span>#{position.position}</span>
                          ) : (
                            <span>&mdash;</span>
                          )}
                        </td>
                        <td className="pr2 pb2 w3">
                          {position.delta ? (
                            <span className={position.delta <= 0 ? 'green' : 'red'}>
                              {position.delta > 0 ? <span>&#9660;</span> : <span>&#9650;</span>}
                              {Math.abs(position.delta)}
                            </span>
                          ) : (
                            <span>&mdash;</span>
                          )}
                        </td>
                        <td className="pr2 pb2">
                          <a
                            href={`${chartsUrl}&chart_id=${position.chart.id}&chart_type=${position.chart.chartType}`}
                            className="link blue"
                          >
                            {position.chart.displayName}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ))}
            </div>
          </div>
        )}
        {podcasts && (
          <div>
            <div className="f3 dark-blue mb3 w-100">Podcasts</div>
            <div className="mt2 flex-row mb3">
              <PodcastTable
                updatePodcastTableSort={updatePodcastTableSort}
                updatePodcastTablePage={updatePodcastTablePage}
                podcasts={podcasts}
                totalPodcasts={totalPodcasts}
                reportingWindow={reportingWindow}
                urlBase={urlBase}
                tableSortLoadInProgress={tableSortLoadInProgress}
                page={podcastTablePage}
              />
            </div>
          </div>
        )}
        <div className="tc f7">All data in UTC</div>
      </div>
    </div>
  );
}

DashboardOverviewView.propTypes = {
  team: PropTypes.object.isRequired,
  podcast: PropTypes.object,
  podcasts: PropTypes.array,
  loadingDownloadsHistory: PropTypes.bool.isRequired,
  downloadsHistory: PropTypes.array,
  downloadsHistoryAggregation: PropTypes.string,
  onGetDownloadsHistory: PropTypes.func.isRequired,
  loadingPlaysHistory: PropTypes.bool.isRequired,
  playsHistory: PropTypes.array,
  playsHistoryAggregation: PropTypes.string,
  playsHistoryNumDays: PropTypes.number,
  onGetPlaysHistory: PropTypes.func.isRequired,
  loadingConsumptionsSeries: PropTypes.bool,
  consumptionSeries: PropTypes.array,
  loadingDemographics: PropTypes.bool.isRequired,
  onChangeDownloadsEndDate: PropTypes.func.isRequired,
  onChangeDownloadsStartDate: PropTypes.func.isRequired,
  onChangePlaysEndDate: PropTypes.func.isRequired,
  onChangePlaysStartDate: PropTypes.func.isRequired,
  downloadsStartDate: PropTypes.instanceOf(Date).isRequired,
  downloadsEndDate: PropTypes.instanceOf(Date).isRequired,
  playsStartDate: PropTypes.instanceOf(Date).isRequired,
  playsEndDate: PropTypes.instanceOf(Date).isRequired,
  showDownloadWarning: PropTypes.bool,
  handleCSVExport: PropTypes.func.isRequired,
  canViewRetentionChart: PropTypes.bool,
  trackableEnabled: PropTypes.bool,
};

DashboardOverviewView.defaultProps = {
  podcast: null,
  podcasts: null,
  downloadsHistory: null,
  downloadsHistoryAggregation: null,
  consumptionSeries: null,
  playsHistory: null,
  playsHistoryAggregation: null,
  showDownloadWarning: false,
  canViewRetentionChart: false,
  trackableEnabled: false,
};

export default withUser(DashboardOverviewView);
