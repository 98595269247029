"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deprecatedOverlayDirections = void 0;
exports.mapPhysicalToLogicalDirections = mapPhysicalToLogicalDirections;
var _overlays = require("./overlays");
/**
 * As we localized our components, we began using CSS logical properties.
 *
 * ie: left --> start, right --> end
 *
 * These directions do not reflect Encore Web's maturity from physical
 * to logical properties and thus, will be deprecated and archived. #Web3
 *
 * TODO: Archive these in a future release.
 */

var deprecatedOverlayDirections = {
  /**
   * @deprecated
   * Use the string "top" instead of the helper object.
   */
  top: 'top',
  /**
   * @deprecated
   * Use the string "topEnd" instead of the helper object.
   */
  topRight: 'topRight',
  /**
   * @deprecated
   * Use the string "topStart" instead of the helper object.
   */
  topLeft: 'topLeft',
  /**
   * @deprecated
   * Use the string "start" instead of the helper object.
   */
  left: 'left',
  /**
   * @deprecated
   * Use the string "startTop" instead of the helper object.
   */
  leftTop: 'leftTop',
  /**
   * @deprecated
   * Use the string "startBottom" instead of the helper object.
   */
  leftBottom: 'leftBottom',
  /**
   * @deprecated
   * Use the string "bottom" instead of the helper object.
   */
  bottom: 'bottom',
  /**
   * @deprecated
   * Use the string "bottomStart" instead of the helper object.
   */
  bottomLeft: 'bottomLeft',
  /**
   * @deprecated
   * Use the string "bottomEnd" instead of the helper object.
   */
  bottomRight: 'bottomRight',
  /**
   * @deprecated
   * Use the string "end" instead of the helper object.
   */
  right: 'right',
  /**
   * @deprecated
   * Use the string "endTop" instead of the helper object.
   */
  rightTop: 'rightTop',
  /**
   * @deprecated
   * Use the string "endBottom" instead of the helper object.
   */
  rightBottom: 'rightBottom'
};
exports.deprecatedOverlayDirections = deprecatedOverlayDirections;
var physicalToLogicalMap = {
  top: 'top',
  topRight: 'topEnd',
  topLeft: 'topStart',
  left: 'start',
  leftTop: 'startTop',
  leftBottom: 'startBottom',
  bottom: 'bottom',
  bottomLeft: 'bottomStart',
  bottomRight: 'bottomEnd',
  right: 'end',
  rightTop: 'endTop',
  rightBottom: 'endBottom'
};

/**
 * type guard to tell TypeScript that if a key is in an object,
 * the key is a valid lookup
 * https://stackoverflow.com/questions/58960077/how-to-check-if-a-strongly-typed-object-contains-a-given-key-in-typescript-witho
 */
function isObjKey(key, obj) {
  return key in obj;
}
function mapPhysicalToLogicalDirections(direction) {
  if (!direction) return undefined;
  if (isObjKey(direction, _overlays.overlayDirections)) {
    return direction;
  }
  return physicalToLogicalMap[direction];
}