import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getCharts, getEpisodes, getPodcast, getPodcasts, getRankHistory } from 'shared/api';
import Loading from 'shared/Loading';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import Sidebar from 'shared/Sidebar';

import DashboardChartsSelectorView from './components/DashboardChartsSelectorView';
import DashboardChartsView from './components/DashboardChartsView';

function chartsFromArray(chartsArray) {
  const charts = {};
  chartsArray.forEach(chart => {
    Object.keys(chart.path).forEach(entry => {
      const elem = chart.path[entry].fullPath.trim();
      if (!charts[elem]) {
        charts[elem] = { charts: [], children: [] };
      }
      charts[elem].charts.push(chart);
      if (chart.path[entry].child) {
        charts[elem].children.push(chart.path[entry].child);
      }
    });
  });

  return charts;
}

export default class DashboardCharts extends Component {
  constructor(props) {
    super(props);

    this.fetchCharts = this.fetchCharts.bind(this);
    this.fetchTeamPodcasts = this.fetchTeamPodcasts.bind(this);
    this.fetchRankHistory = this.fetchRankHistory.bind(this);
    this.fetchEpisodes = this.fetchEpisodes.bind(this);

    this.handleChangePodcast = this.handleChangePodcast.bind(this);
    this.handleChangeEpisode = this.handleChangeEpisode.bind(this);
    this.handleChangeChart = this.handleChangeChart.bind(this);

    this.state = {
      loading: true,
      charts: null,
      podcasts: null,
      episodes: null,
      selectedChart: null,
      selectedPodcast: null,
      rankHistory: null,
      preloadChart: this.props.loadChart,
      preloadEpisode: this.props.episodeId,
    };
  }

  componentDidMount() {
    this.fetchTeamPodcasts();
    if (this.props.podcastId) {
      this.fetchEpisodes();
    }
  }

  componentDidUpdate() {
    const {
      selectedPodcast,
      charts,
      rankHistory,
      episodes,
      loading,
      selectedEpisode,
      selectedChart,
      preloadEpisode,
    } = this.state;

    if (loading && preloadEpisode) {
      return;
    }

    if (loading && selectedPodcast && charts === null) {
      this.fetchCharts();
      return;
    }

    if (loading && charts !== null && (!this.props.podcastId || episodes) && rankHistory === null) {
      if (Object.keys(charts).length > 0) {
        const provider = Object.keys(charts)[0];
        if (this.state.preloadChart) {
          this.fetchRankHistory(this.state.preloadChart);
        } else {
          this.fetchRankHistory(charts[provider].charts[0]);
        }
      } else {
        this.setState({ loading: false, rankHistory: [] });
      }
      return;
    }

    if (loading && rankHistory !== null) {
      this.setState({ loading: false });
    }
  }

  fetchCharts() {
    getCharts({
      teamId: this.props.teamId,
      podcastId: this.state.selectedPodcast.id,
      episodeId: this.state.selectedEpisode ? this.state.selectedEpisode.id : undefined,
    }).then(res => {
      this.setState({
        charts: chartsFromArray(res.data),
      });
    });
  }

  fetchTeamPodcasts() {
    const { teamId, podcastId } = this.props;
    if (podcastId) {
      getPodcast({ teamId, podcastId }).then(res => {
        this.setState({
          podcasts: [res.data],
          selectedPodcast: res.data,
        });
      });
    } else {
      getPodcasts({ teamId }).then(res => {
        this.setState({
          podcasts: res.data,
          selectedPodcast: res.data[0],
        });
      });
    }
  }

  fetchRankHistory(chart) {
    getRankHistory({
      teamId: this.props.teamId,
      podcastId: this.state.selectedPodcast.id,
      episodeId: this.state.selectedEpisode ? this.state.selectedEpisode.id : undefined,
      chart_type: chart.chartType,
      chart_id: chart.id,
    }).then(res => {
      this.setState({
        selectedChart: chart,
        rankHistory: res.data,
        preloadChart: null,
      });
    });
  }

  fetchEpisodes() {
    getEpisodes({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      hasChartAppearance: true,
    }).then(res => {
      let selectedEpisode = null;
      if (this.state.preloadEpisode) {
        selectedEpisode = res.data.find(pos => pos.id === this.state.preloadEpisode);
      }
      this.setState({
        episodes: res.data,
        selectedEpisode: selectedEpisode,
        preloadEpisode: null,
      });
    });
  }

  handleChangeChart(path) {
    const { charts } = this.state;
    try {
      this.fetchRankHistory(charts[path].charts[0]);
    } catch (e) {
      // Paths ending with '- All Time' are actually ' - All Time' (with whitespace) in charts
      // so this is a hack to get it to work sinec I don't know how to mess with the chart paths. -JS
      if (path.length > 12) {
        path = path.substring(0, path.length - 10);
        this.fetchRankHistory(charts[`${path} - All-Time`].charts[0]);
      }
    }
  }

  handleChangePodcast(podcastId) {
    const { podcasts } = this.state;

    const selectedPodcast = podcasts.find(pos => pos.id === podcastId);

    this.setState({
      loading: true,
      charts: null,
      rankHistory: null,
      selectedEpisode: null,
      selectedPodcast,
    });
  }

  handleChangeEpisode(episodeId) {
    const { episodes } = this.state;

    const selectedEpisode = episodes.find(pos => pos.id === episodeId);
    this.setState({
      loading: true,
      charts: null,
      rankHistory: null,
      selectedEpisode,
    });
  }

  setPodcast = ({ type, data }) => {
    const { teamId } = this.props;
    const urlBase = `/teams/${teamId}/dashboard/charts`;
    if (type === 'all') {
      window.location = `${urlBase}/overview`;
      return;
    }
    window.location = `${urlBase}?podcast_id=${data.id}`;
  };

  render() {
    const { podcastsCount, podcast, teamId, sidebar } = this.props;

    const manyPodcasts = podcastsCount > 1;

    return (
      <Sidebar sidebar={sidebar}>
        {this.state.loading ? (
          <Loading fullScreen />
        ) : (
          <div className="pa3">
            {manyPodcasts && (
              <PodcastSwitcher
                teamId={teamId}
                onSelect={this.setPodcast}
                loading={false}
                selectedPodcast={podcast || null}
                defaultToNone={false}
                defaultToAll={false}
                includeNone={false}
                includeAll
                numberOfDefaultOptionsToFetch={podcastsCount}
                className="w8 mb3"
              />
            )}
            <div className="f3 dark-blue mb3">Charts</div>
            <DashboardChartsSelectorView
              {...this.state}
              onChangeChart={this.handleChangeChart}
              onChangeEpisode={this.handleChangeEpisode}
              onChangePodcast={this.handleChangePodcast}
              minStartDate={this.props.minStartDate}
            />
            <DashboardChartsView
              {...this.state}
              minStartDate={this.props.minStartDate}
              teamId={this.props.teamId}
            />
          </div>
        )}
      </Sidebar>
    );
  }
}

DashboardCharts.propTypes = {
  podcastId: PropTypes.string,
  teamId: PropTypes.string.isRequired,
  minStartDate: PropTypes.instanceOf(Date),
};

DashboardCharts.defaultProps = {
  podcastId: null,
  minStartDate: null,
};
