import React from 'react';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import DataChartBase from 'shared/DataChartBase';
import Loading from 'shared/Loading';

function DemographicsBarChart({ data, subtitle, isLoading, inlineStyle }) {
  if (!data) {
    return (
      <DataChartBase inlineStyle={inlineStyle} title={title} subtitle={subtitle}>
        <div style={{ height: '500px' }} className="flex justify-center items-center">
          <Loading />
        </div>
      </DataChartBase>
    );
  }

  return (
    <DataChartBase inlineStyle={inlineStyle} subtitle={subtitle} isLoading={isLoading}>
      <Chart
        height="100%"
        chartType="BarChart"
        loader={<div>One moment please...</div>}
        data={data}
        options={{
          colors: ['#4b9ec1', '#CDECFF'],
          legend: { display: false, position: 'none' },
          vAxis: {
            textPosition: 'left',
            textStyle: { color: '#111111', auraColor: 'none' },
          },
          hAxis: {
            format: "#'%'",
            minorGridlines: { count: 0 },
            viewWindowMode: 'maximized',
          },
          chartArea: { width: '100%', height: '90%', top: '-5', bottom: 20, right: 30, left: 150 },
          focusTarget: 'category',
        }}
      />
    </DataChartBase>
  );
}

DemographicsBarChart.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  isLoading: PropTypes.bool,
  data: PropTypes.array,
};

DemographicsBarChart.defaultProps = {
  title: null,
  data: null,
  subtitle: null,
  isLoading: false,
};

export default DemographicsBarChart;
