import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Art19DecryptionKeyInput, EmailPreferences } from 'shared/AttributionSettings';
import BannerNotice from 'shared/BannerNotice';
import TopBar from 'shared/SmallTopBar';

function Settings(props) {
  const urlParams = new URLSearchParams(props.location.search);
  const success = urlParams.get('success') === 'true';

  const {
    teamHashedId,
    attributionStatusNotifications,
    smartpromoApprovalRequests,
    art19DecryptionKeys,
  } = props;

  const { teamId } = props.match.params;

  const [bannerNotice, setBannerNotice] = useState(
    success ? (
      <BannerNotice
        rounded
        message="Updated Settings!"
        type="success"
        onClose={() => setBannerNotice(null)}
      />
    ) : null,
  );

  function createBannerNotice(message, type) {
    setBannerNotice(
      <BannerNotice rounded message={message} type={type} onClose={() => setBannerNotice(null)} />,
    );
  }

  return (
    <div>
      <TopBar backUrl={`/teams/${teamId}/dashboard/adops/manage`} backText="All Placements" />
      <div className="ph4 mt3 pb5">
        {bannerNotice && <div className="mb3">{bannerNotice}</div>}
        <div className="mw7">
          <div className="f3 near-black mt0 header-font">Attribution Settings</div>
          <div className="pa4 mt3 card ba b--moon-gray">
            <EmailPreferences
              teamId={teamId}
              attributionStatusNotifications={attributionStatusNotifications}
              smartpromoApprovalRequests={smartpromoApprovalRequests}
              onSuccess={preferences => {
                location.assign(`/teams/${teamId}/dashboard/adops/settings?success=true`);
              }}
              onFailure={() => {
                createBannerNotice(
                  'Failed to update email alert settings. Please reach out to support if this problem continues.',
                  'error',
                );
              }}
            />
          </div>
          <div className="pa4 mt3 card ba b--moon-gray">
            <Art19DecryptionKeyInput
              teamId={teamId}
              art19DecryptionKeys={art19DecryptionKeys}
              teamHashedId={teamHashedId}
              decryptionKeys={art19DecryptionKeys}
              onSuccess={keys => {
                window.location.assign(`/teams/${teamId}/dashboard/adops/settings?success=true`);
              }}
              onFailure={e => {
                console.log(e);
                createBannerNotice(
                  'Failed to add key. Please reach out to support if this problem continues.',
                  'error',
                );
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Settings.propTypes = {
  match: PropTypes.object.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  attributionStatusNotifications: PropTypes.bool.isRequired,
  smartpromoApprovalRequests: PropTypes.bool.isRequired,
  art19DecryptionKeys: PropTypes.array.isRequired,
};

export default Settings;
