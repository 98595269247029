/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import Tooltip from 'react-tooltip';
import ImageBlueSVG from 'images/icons/image-blue.svg';
import ImageGraySVG from 'images/icons/image-gray.svg';
import IntegrationBlueSVG from 'images/icons/integration-blue.svg';
import IntegrationGraySVG from 'images/icons/integration-gray.svg';
import LockSVG from 'images/icons/lock.svg';
import PencilBlueSVG from 'images/icons/pencil-blue.svg';
import PencilGraySVG from 'images/icons/pencil-gray.svg';
import PlayBlueSVG from 'images/icons/play-blue.svg';
import PlayGraySVG from 'images/icons/play-gray.svg';
import RedirectBlueSVG from 'images/icons/redirect-blue.svg';
import RedirectGraySVG from 'images/icons/redirect-gray.svg';
import WarningRedSVG from 'images/icons/warning-red.svg';
import WebsiteBlueSVG from 'images/icons/website-blue.svg';
import WebsiteGraySVG from 'images/icons/website-gray.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  createTrackingLink,
  getPodcast,
  getTrackingLinkEpisodeDeeplinks,
  getTrackingLinkPodcastDeeplinks,
  getTrackingLinkRemarketingPixels,
  updateTrackingLink,
} from 'shared/api/v1';
import Banner from 'shared/Banner';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import Checkbox from 'shared/Checkbox';
import Header from 'shared/Header';
import { allPlayerKeys } from 'shared/helpers';
import Loading from 'shared/Loading';
import SmallTopBar from 'shared/SmallTopBar';
import TrackingLinkImageUploadHandler from 'shared/TrackingLinkImageUploadHandler';
import { withUser } from 'shared/UserContext';

import RemarketingPixelForm from '../RemarketingPixelForm';
import ColorPicker from './components/ColorPicker';
import DeeplinkManager from './components/DeeplinkManager';
import LinkNameForm from './components/LinkNameForm';
import LinkPodcastEpisodeForm from './components/LinkPodcastEpisodeForm';
import LinkRedirectSetupView from './components/LinkRedirectSetupView';
import PlayerOrderSelect from './components/PlayerOrderSelect';
import RemarketingPixels from './components/RemarketingPixels';

const steps = {
  basicInfo: 0,
  linkDestination: 1,
  landingPage: 2,
  socialMediaImage: 3,
  conditionalRedirects: 4,
  pixels: 5,
};

const stepHeader = [
  {
    header: 'Basic Information',
    subheader: null,
  },
  {
    header: 'Deeplinks',
    subheader:
      'Our system will try to auto-generate default links for your podcast and episodes. Please edit, add, or remove links as necessary. You can control which links will appear in the next step.',
  },
  {
    header: 'Customize Landing Page',
    subheader: 'Users who click on your link will land on this page by default.',
  },
  {
    header: 'Social Media Image',
    subheader: (
      <React.Fragment>
        <div>Add an image people will see when you share this link on social media.</div>
        <div>
          For a list of recommended sizes for social media platforms,{' '}
          <a
            href="https://blog.hootsuite.com/social-media-image-sizes-guide/"
            target="_blank"
            rel="noreferrer"
          >
            please click here.
          </a>
        </div>
      </React.Fragment>
    ),
  },
  {
    header: 'Conditional Redirects',
    subheader: "Setup automatic redirects based on the listener's device.",
  },
  {
    header: 'Remarketing Pixels',
    subheader: 'Add third party service remarketing pixel to your link page.',
  },
];

const Step = ({
  className,
  onClick,
  locked,
  selected,
  title,
  selectedIcon,
  unselectedIcon,
  iconSize,
}) => {
  let iconSrc;

  if (locked) {
    iconSrc = LockSVG;
  } else {
    iconSrc = selected ? selectedIcon : unselectedIcon;
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={`${selected ? 'blue' : 'gray'} ${
        locked ? 'o-70' : 'hover-blue pointer'
      } f6 flex gap-small items-center flex-row ${className}`}
      onClick={locked ? null : onClick}
    >
      <div
        style={{
          height: `${iconSize}px`,
          width: `${iconSize}px`,
          marginTop: iconSize ? `${19 - iconSize}px` : '',
        }}
      >
        <img alt="icon" src={iconSrc} style={{ height: iconSize ? iconSize : '19px' }} />
      </div>
      {title}
    </div>
  );
};

function reducer(state, action) {
  switch (action.type) {
    case 'setStep':
      return { ...state, step: action.payload };
    case 'setPodcast':
      return {
        ...state,
        podcast: action.payload,
        name: `${action.payload.title} - ${moment().format('MM/DD/YYYY')}`,
        episode: null,
        isAutomated: false,
        automatedLinkUserId: null,
      };
    case 'setShowEpisodePlayer':
      return { ...state, showEpisodePlayer: !state.showEpisodePlayer };
    case 'setEpisode':
      return { ...state, episode: action.payload };
    case 'setName':
      return { ...state, name: action.payload };
    case 'setCustomShortLink':
      return { ...state, customShortLink: action.payload };
    case 'setCustomShortLinkDomainId':
      return { ...state, customShortLinkDomainId: action.payload };
    case 'setPlayerPageOrder':
      return { ...state, playerPageOrder: action.payload };
    case 'setPlayerPageCustomTitle':
      return { ...state, playerPageCustomTitle: action.payload };
    case 'setPlayerPageCustomDescription':
      return { ...state, playerPageCustomDescription: action.payload };
    case 'setSocialMediaImageUrl':
      return { ...state, socialMediaImageUrl: action.payload };
    case 'setRedirects':
      return { ...state, redirects: action.payload };
    case 'setRedirectDefaultUrl':
      return { ...state, redirectDefaultUrl: action.payload };
    case 'setBackgroundColor':
      return { ...state, backgroundColor: action.payload };
    case 'addRemarketingPixel':
      return { ...state, remarketingPixels: action.payload };
    case 'setRemarketingPixels':
      return { ...state, remarketingPixels: action.payload };
    case 'setIsAutomated':
      return { ...state, isAutomated: action.payload };
    case 'setAutomatedLinkUserId':
      return { ...state, automatedLinkUserId: action.payload };
    case 'setDeeplinks':
      return {
        ...state,
        deeplinks: action.payload,
        ...(action.resetPlayerPageOrder && {
          playerPageOrder: allPlayerKeys.filter(p =>
            action.payload.map(d => d.platformKey).includes(p),
          ),
        }),
      };
    default:
      throw new Error();
  }
}

function TrackingLinkForm({
  trackingLink,
  onSaveTrackingLink,
  onlyAllowedPodcastCid,
  canUseSmartLinksCustomDefaultUrl,
  canCreateSmartLinks,
  canUseSmartLinksAdvancedCustomization,
  bl,
  history,
  hasSmartLinksAutomation,
  linkableReadOnly,
}) {
  const { teamId } = useParams();

  const isEditing = !!trackingLink;

  const [loadingInitialState, setLoadingInitialState] = useState(true);
  const [errors, setErrors] = useState(null);
  const [savingTrackingLink, setSavingTrackingLink] = useState(false);
  const [failedToFetchDeeplinks, setFailedToFetchDeeplinks] = useState(false);
  const [failedToFetchRemarketingPixels, setFailedToFetchRemarketingPixels] = useState(false);

  const [cachedPalette, setCachedPalette] = useState(null);

  const [allRemarketingPixels, setAllRemarketingPixels] = useState(null);
  const [remarketingPixelFormIsOpen, setRemarketingPixelFormIsOpen] = useState(false);

  // When quick linking to the deeplink manager page, I want to have the correct platform key pre selected
  const [preSelectedNewDeeplinkPlatformKey, setPreSelectedNewDeeplinkPlatformKey] = useState(null);

  const [state, dispatch] = useReducer(reducer, {
    step: steps.basicInfo,
    name: isEditing ? trackingLink.name : '',
    customShortLink: isEditing ? trackingLink.slug : '',
    customShortLinkDomainId: isEditing && trackingLink.domain ? trackingLink.domain.id : null,
    podcast: isEditing ? trackingLink.podcast : null,
    episode: isEditing ? trackingLink.episode : null,
    playerPageOrder: null,
    deeplinks: null,
    isAutomated: isEditing ? !!trackingLink.isAutomated : false,
    playerPageCustomTitle: isEditing ? trackingLink.title : '',
    playerPageCustomDescription: isEditing ? trackingLink.description : '',
    socialMediaImageUrl: isEditing ? trackingLink.socialMediaImageUrl : null,
    redirects: isEditing ? trackingLink.platforms : {},
    redirectDefaultUrl: isEditing ? trackingLink.defaultUrl : '',
    backgroundColor: isEditing ? trackingLink.backgroundColor : '#FFFFFF',
    remarketingPixels: isEditing
      ? trackingLink.remarketingPixels.map(rp => ({ platform: rp.platform, pixel: rp.pixel }))
      : [],
    showEpisodePlayer: isEditing ? trackingLink.showEpisodePlayer : true,
    automatedLinkUserId:
      isEditing && trackingLink.automatedTrackingLinkMetadata
        ? trackingLink.automatedTrackingLinkMetadata.metadata.userId
        : null,
  });

  const {
    step,
    name,
    customShortLink,
    customShortLinkDomainId,
    podcast,
    episode,
    playerPageOrder,
    deeplinks,
    playerPageCustomTitle,
    playerPageCustomDescription,
    socialMediaImageUrl,
    redirects,
    redirectDefaultUrl,
    backgroundColor,
    remarketingPixels,
    showEpisodePlayer,
    isAutomated,
    automatedLinkUserId,
  } = state;

  const handleCreateTrackingLink = async () => {
    if (savingTrackingLink) return;
    setSavingTrackingLink(true);

    const data = {
      podcastId: podcast.id,
      episodeId: episode ? episode.id : null,
      remarketingPixels: remarketingPixels.filter(rp => !!rp.pixel),
      slug: customShortLink,
      teamId,
      smartLinkDomainId: customShortLinkDomainId,
      socialMediaImageUrl,
      defaultUrl: redirectDefaultUrl,
      name,
      playerPageOrder,
      redirects,
      title: playerPageCustomTitle,
      description: playerPageCustomDescription,
      backgroundColor,
      showEpisodePlayer,
      isAutomated,
      automatedLinkUserId,
    };

    if (isEditing) {
      data.id = trackingLink.id;
      data.forceUpload = true;
      try {
        const res = await updateTrackingLink(data);
        onSaveTrackingLink(res.data);
      } catch (e) {
        let _errors;

        if (linkableReadOnly) {
          _errors = { unknownError: e.response.data.errors };
        } else {
          _errors = {
            unknownError:
              'Failed to create SmartLink. Reach out to support if this problem continues.',
          };
          // Backend sends these errors back in an array, although it seems like there's only ever one of each type
          if (e && e.response && e.response.data && e.response.data.errors) {
            Object.keys(e.response.data.errors).map(key => {
              _errors[key] = e.response.data.errors[key];
            });
          }
        }

        setErrors(_errors);
        setSavingTrackingLink(false);
      }
    } else {
      try {
        const res = await createTrackingLink(data);
        onSaveTrackingLink(res.data);
      } catch (e) {
        let _errors;

        if (linkableReadOnly) {
          _errors = { unknownError: e.response.data.errors };
        } else {
          _errors = {
            unknownError:
              'Failed to create SmartLink. Reach out to support if this problem continues.',
          };
          if (e && e.response && e.response.data && e.response.data.errors) {
            _errors = e.response.data.errors;
          }
        }

        setErrors(_errors);
        setSavingTrackingLink(false);
      }
    }
  };

  const goToDeeplinkManager = platformKey => {
    setPreSelectedNewDeeplinkPlatformKey(platformKey);
    dispatch({ type: 'setStep', payload: steps.linkDestination });
  };

  const handleGetDeeplinks = async () => {
    setCachedPalette(null);
    let resetPlayerPageOrder = true;
    setFailedToFetchDeeplinks(false);

    // Skip first playe page order reset on edit so that we don't override info from trackingLink
    if (isEditing && !playerPageOrder && trackingLink.players && trackingLink.players.all) {
      dispatch({ type: 'setPlayerPageOrder', payload: trackingLink.players.all });
      resetPlayerPageOrder = false;
    }

    try {
      let response;

      if (episode) {
        response = await getTrackingLinkEpisodeDeeplinks({
          teamId,
          podcastId: podcast.id,
          rawEpisodeId: episode.id,
        });
      } else {
        response = await getTrackingLinkPodcastDeeplinks({
          teamId,
          podcastId: podcast.id,
        });
      }

      dispatch({
        type: 'setDeeplinks',
        payload: response.data.data,
        resetPlayerPageOrder,
      });
    } catch (_) {
      setFailedToFetchDeeplinks(true);
    }
  };

  const fetchPodcast = async podcastId => {
    if (podcastId) {
      try {
        const res = await getPodcast({ podcastId, teamId });
        dispatch({ type: 'setPodcast', payload: res.data.data });
      } catch (_) {
        // This does not need to be handled.
        // UI will render empty podcast selection form.
      }
    }

    setLoadingInitialState(false);
  };

  useEffect(() => {
    if (isEditing) {
      setLoadingInitialState(false);
    } else {
      fetchPodcast(onlyAllowedPodcastCid);
    }
  }, []);

  useEffect(() => {
    if (!podcast) return;
    handleGetDeeplinks();
  }, [JSON.stringify(episode), JSON.stringify(podcast)]);

  const handleGetAllRemarketingPixels = async () => {
    setFailedToFetchRemarketingPixels(false);
    try {
      const res = await getTrackingLinkRemarketingPixels({ teamId });
      setAllRemarketingPixels(res.data);
      if (!isEditing) {
        const defaults = res.data
          .map(p => (p.defaultForPlatform ? { pixel: p.pixel, platform: p.platform } : null))
          .filter(p => p);
        dispatch({ type: 'setRemarketingPixels', payload: defaults });
      }
    } catch (_) {
      setFailedToFetchRemarketingPixels(true);
    }
  };

  useEffect(() => {
    handleGetAllRemarketingPixels();
  }, []);

  if (loadingInitialState) {
    return (
      <div>
        <SmallTopBar
          backUrl={`/teams/${teamId}/dashboard/links${isEditing ? `/${trackingLink.id}` : ''}`}
          backText={isEditing ? 'Back' : 'Overview'}
          text={`${isEditing ? 'Editing' : 'Creating'} SmartLink`}
        />
        <Loading fullScreen />
      </div>
    );
  }

  const notAllowedOverlay = canCreateSmartLinks ? null : (
    <div className="absolute w-100 h-100 o-70 bg-near-white" style={{ zIndex: 999 }} />
  );

  return (
    <div>
      <SmallTopBar
        backUrl={`/teams/${teamId}/dashboard/links${isEditing ? `/${trackingLink.id}` : ''}`}
        backText={isEditing ? 'Back' : 'Overview'}
        text={`${isEditing ? 'Editing' : 'Creating'} SmartLink`}
      />
      {errors && errors.unknownError && (
        <BannerNotice
          className="ph5 mt3"
          rounded
          error
          message={errors.unknownError}
          onClick={() => setErrors(null)}
        />
      )}
      {!canCreateSmartLinks && (
        <Banner className="ph5 mt3">
          <div className="b mb2 f6">
            You don&apos;t have permission to create or edit SmartLinks
          </div>
          <div className="f6 mb1">
            You&apos;re role is <b>Viewer</b> on your current team. You must be a <b>member</b>,{' '}
            <b>admin</b>, or <b>owner</b> to access this feature.
          </div>
          <div className="f6">
            Contact an admin or the owner of your team to change your role in Team Settings.
          </div>
        </Banner>
      )}
      <div className="relative ph5 mt4 flex" style={{ marginBottom: '400px' }}>
        {notAllowedOverlay}
        <div className="w5">
          <div className="mw5 flex-grow">
            <Tooltip />
            <div className="gray b mb3">Setup</div>
            <Step
              onClick={() => dispatch({ type: 'setStep', payload: steps.basicInfo })}
              selected={step === steps.basicInfo}
              selectedIcon={errors && errors.slug ? WarningRedSVG : PencilBlueSVG}
              unselectedIcon={errors && errors.slug ? WarningRedSVG : PencilGraySVG}
              className={errors && errors.slug ? 'red mb3' : 'mb3'}
              title="Basic Info"
            />
            <Step
              onClick={() => dispatch({ type: 'setStep', payload: steps.linkDestination })}
              selected={step === steps.linkDestination}
              selectedIcon={PlayBlueSVG}
              unselectedIcon={PlayGraySVG}
              locked={!podcast}
              className="mb4"
              title="Deeplinks"
            />
            <div className="gray b mb3">Customize</div>
            <Step
              onClick={() => dispatch({ type: 'setStep', payload: steps.landingPage })}
              selected={step === steps.landingPage}
              selectedIcon={WebsiteBlueSVG}
              unselectedIcon={WebsiteGraySVG}
              className="mb3"
              title="Landing Page"
              iconSize={17}
              locked={!podcast}
            />
            {canUseSmartLinksAdvancedCustomization && (
              <Step
                onClick={() => dispatch({ type: 'setStep', payload: steps.socialMediaImage })}
                selected={step === steps.socialMediaImage}
                selectedIcon={ImageBlueSVG}
                unselectedIcon={ImageGraySVG}
                className="mb4"
                title="Social Media Image"
                locked={!podcast}
              />
            )}
            <div className="gray mb3 mt3">
              <b>Advanced</b>
            </div>
            <Step
              onClick={() => dispatch({ type: 'setStep', payload: steps.conditionalRedirects })}
              selected={step === steps.conditionalRedirects}
              selectedIcon={RedirectBlueSVG}
              unselectedIcon={RedirectGraySVG}
              className="mb3"
              title="Conditional Redirects"
              locked={!podcast}
            />
            {canUseSmartLinksAdvancedCustomization && (
              <Step
                onClick={() => dispatch({ type: 'setStep', payload: steps.pixels })}
                selected={step === steps.pixels}
                selectedIcon={IntegrationBlueSVG}
                unselectedIcon={IntegrationGraySVG}
                className="mb3"
                title="Remarketing Pixels"
                locked={!podcast}
              />
            )}
            <div className="mv4 bb b--light-gray mr5" />
            <Button
              loading={savingTrackingLink}
              className="mr5"
              type={isEditing ? 'primary' : 'create'}
              disabled={!podcast || savingTrackingLink}
              onClick={handleCreateTrackingLink}
            >
              {isEditing ? 'Save' : 'Create'}
            </Button>
          </div>
        </div>
        <div className="w-70">
          <div className="card pa3" style={{ height: 'fit-content' }}>
            <div className="pb3 bb b--light-gray">
              <div className="items-center flex flex-wrap justify-between w-100">
                <Header className="dark-blue" size="small" useNew>
                  {stepHeader[step].header}
                </Header>
                <a
                  className="no-underline"
                  target="_blank"
                  href="https://help.chartable.com/article/28-how-do-i-create-a-smartlink"
                  rel="noreferrer"
                >
                  <Button inverted type="primary">
                    Get Help!
                  </Button>
                </a>
              </div>
              {stepHeader[step].subheader && (
                <div className="f6 mv2 mid-gray lh-copy">{stepHeader[step].subheader}</div>
              )}
            </div>
            {step === steps.basicInfo && (
              <React.Fragment>
                <LinkPodcastEpisodeForm
                  podcast={podcast}
                  errors={errors}
                  episode={episode}
                  dispatch={dispatch}
                  isEditing={isEditing}
                  teamId={teamId}
                  isAutomated={isAutomated}
                  automatedLinkUserId={automatedLinkUserId}
                  hasSmartLinksAutomation={hasSmartLinksAutomation}
                />
                {podcast && (
                  <LinkNameForm
                    errors={errors}
                    name={name}
                    history={history}
                    trackingLink={trackingLink}
                    customShortLink={customShortLink}
                    customShortLinkDomainId={customShortLinkDomainId}
                    canUseSmartLinksAdvancedCustomization={canUseSmartLinksAdvancedCustomization}
                    canEditSlug={trackingLink && trackingLink.canEditSlug}
                    podcast={podcast}
                    isEditing={isEditing}
                    dispatch={dispatch}
                    teamId={teamId}
                  />
                )}
              </React.Fragment>
            )}
            {step === steps.linkDestination && (
              <>
                {failedToFetchDeeplinks && (
                  <Banner className="mv2 mw7" error>
                    Failed to load deeplinks
                  </Banner>
                )}
                <DeeplinkManager
                  teamId={teamId}
                  podcastId={podcast.id}
                  rawEpisodeId={episode ? episode.id : null}
                  deeplinks={deeplinks}
                  setDeeplinks={newDeeplinks =>
                    dispatch({ type: 'setDeeplinks', payload: newDeeplinks })
                  }
                  className="mt3"
                  preSelectedNewDeeplinkPlatformKey={preSelectedNewDeeplinkPlatformKey}
                  nullifyPreSelectedKey={() => setPreSelectedNewDeeplinkPlatformKey(null)}
                  linkableReadOnly={linkableReadOnly}
                />
              </>
            )}
            {step === steps.landingPage && (
              <div>
                {canUseSmartLinksAdvancedCustomization && (
                  <React.Fragment>
                    <div className="dark-gray mv2">Title</div>
                    <input
                      type="text"
                      placeholder="Leave blank to use your podcast's title"
                      className="pa2 input-reset ba w-100 bg-white br2 b--silver"
                      value={playerPageCustomTitle}
                      onChange={e =>
                        dispatch({ type: 'setPlayerPageCustomTitle', payload: e.target.value })
                      }
                    />
                    <div className="dark-gray mt3 mb2">Description</div>
                    <textarea
                      placeholder="Leave blank to use your podcast's description"
                      type="text"
                      className="pa2 input-reset ba w-100-ns bg-white br2 b--silver"
                      value={playerPageCustomDescription}
                      onChange={e =>
                        dispatch({
                          type: 'setPlayerPageCustomDescription',
                          payload: e.target.value,
                        })
                      }
                    />
                    <div className="mt3">
                      <Checkbox
                        isChecked={showEpisodePlayer}
                        onClick={() => dispatch({ type: 'setShowEpisodePlayer' })}
                        label="Show Podcast Player"
                        description="When checked, a player for your podcast will appear on the landing page."
                      />
                    </div>
                    <div className="dark-gray mt4">Custom Background Color</div>
                    <div className="mb2 lh-copy mid-gray f6">
                      Choose a background color for your link page, or set a custom{' '}
                      <span>
                        <a
                          className="link blue pointer"
                          target="_blank"
                          rel="noreferrer"
                          href="https://www.w3schools.com/colors/colors_hexadecimal.asp"
                        >
                          hex code color
                        </a>
                      </span>
                      .
                    </div>
                    <ColorPicker
                      color={backgroundColor}
                      onSelectColor={color =>
                        dispatch({ type: 'setBackgroundColor', payload: color })
                      }
                      imageUrl={podcast.displayImageUrl}
                      podcastId={podcast.id}
                      teamId={teamId}
                      cachedPalette={cachedPalette}
                      cachePalette={setCachedPalette}
                    />
                  </React.Fragment>
                )}
                <div className="dark-gray mt3">Player Order</div>
                <div className="lh-copy mid-gray f6">
                  Drag and drop players to change the order they are displayed. Hidden players will
                  not appear.
                </div>
                {deeplinks ? (
                  <PlayerOrderSelect
                    initialPlayerOrder={playerPageOrder}
                    selectedPodcastId={podcast.id}
                    storePlatformOrder={playerOrder =>
                      dispatch({ type: 'setPlayerPageOrder', payload: playerOrder })
                    }
                    goToDeeplinkManager={goToDeeplinkManager}
                    validPlayers={[...deeplinks.map(deeplink => deeplink.platformKey), 'rss']} // Assume RSS is always valid
                  />
                ) : (
                  <Loading fullScreen />
                )}
              </div>
            )}
            {step === steps.socialMediaImage && (
              <TrackingLinkImageUploadHandler
                initialImageUrl={socialMediaImageUrl}
                teamId={teamId}
                onUploadImage={newSocialMediaImageUrl =>
                  dispatch({ type: 'setSocialMediaImageUrl', payload: newSocialMediaImageUrl })
                }
                onRemoveImage={() => dispatch({ type: 'setSocialMediaImageUrl', payload: null })}
                linkableReadOnly={linkableReadOnly}
              />
            )}
            {step === steps.conditionalRedirects &&
              (deeplinks ? (
                <React.Fragment>
                  <LinkRedirectSetupView
                    redirects={redirects}
                    saveRedirects={newRedirects =>
                      dispatch({ type: 'setRedirects', payload: newRedirects })
                    }
                    goToDeeplinkManager={goToDeeplinkManager}
                    validPlayers={deeplinks.map(deeplink => deeplink.platformKey)}
                  />
                  {!bl && (
                    <div className="mt4">
                      <div className="f5 mb2 mt4 dark-gray">Default URL</div>
                      <div className="f6 lh-copy mid-gray mt1">
                        When listeners click your SmartLink they will be redirected to here if we
                        are unable to match a redirect rule instead of the{' '}
                        <a
                          target="_blank"
                          className="link blue"
                          href="https://link.chtbl.com/demo"
                          rel="noreferrer"
                        >
                          landing page
                        </a>
                        .
                      </div>
                      {canUseSmartLinksCustomDefaultUrl ? (
                        <input
                          value={redirectDefaultUrl}
                          className="mt2 pa2 input-reset ba bg-white br2 b--silver w-100"
                          type="text"
                          placeholder="https:// ..."
                          onChange={({ target: { value } }) =>
                            dispatch({ type: 'setRedirectDefaultUrl', payload: value })
                          }
                        />
                      ) : (
                        <div className="mt2">
                          <a
                            target="_blank"
                            className="f6 link blue b"
                            href="mailto:support@chartable.com?subject=Requesting Access to SmartLinks Custom Default URLs"
                            rel="noreferrer"
                          >
                            Apply for access to set Custom Default URLs!
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <Loading fullScreen />
              ))}
            {step === steps.pixels && (
              <div>
                {failedToFetchRemarketingPixels && (
                  <Banner error>Failed to load Remarketing Pixels</Banner>
                )}
                <RemarketingPixels
                  addRemarketingPixel={rp => dispatch({ type: 'addRemarketingPixel', payload: rp })}
                  remarketingPixels={remarketingPixels}
                  allRemarketingPixels={allRemarketingPixels}
                  teamId={teamId}
                />
                <Button
                  type="primary"
                  size="small"
                  className="f6 dib mt3"
                  onClick={() => setRemarketingPixelFormIsOpen(true)}
                >
                  + Add New Remarketing Pixel
                </Button>
              </div>
            )}
            {step === steps.pixels ||
            (!canUseSmartLinksAdvancedCustomization && step === steps.conditionalRedirects) ? (
              <Button
                disabled={savingTrackingLink}
                loading={savingTrackingLink}
                className="mt4"
                type={isEditing ? 'primary' : 'create'}
                onClick={handleCreateTrackingLink}
              >
                {isEditing ? 'Save' : 'Create'}
              </Button>
            ) : (
              <Button
                className="mt4"
                type="primary"
                disabled={!podcast}
                onClick={() => {
                  let nextStep = step + 1;
                  // Skip social media step if user is not paid
                  if (!canUseSmartLinksAdvancedCustomization && step === steps.landingPage) {
                    nextStep = steps.conditionalRedirects;
                  }

                  dispatch({ type: 'setStep', payload: nextStep });
                }}
              >
                Next →
              </Button>
            )}
          </div>
        </div>
      </div>
      {remarketingPixelFormIsOpen && (
        <BasicModal
          isOpen
          onRequestClose={() => setRemarketingPixelFormIsOpen(false)}
          ariaHideApp={false}
        >
          <div style={{ minHeight: '300px' }}>
            <Header className="mb3" useNew size="small">
              Add new Marketing Pixel
            </Header>
            <RemarketingPixelForm
              teamId={teamId}
              onSave={() => {
                setRemarketingPixelFormIsOpen(false);
                setAllRemarketingPixels(null);
                handleGetAllRemarketingPixels();
              }}
              linkableReadOnly={linkableReadOnly}
            />
            <Button className="mt3" onClick={() => setRemarketingPixelFormIsOpen(false)}>
              Close
            </Button>
          </div>
        </BasicModal>
      )}
    </div>
  );
}

TrackingLinkForm.propTypes = {
  onSaveTrackingLink: PropTypes.func.isRequired,
  canUseSmartLinksCustomDefaultUrl: PropTypes.bool.isRequired,
  canUseSmartLinksAdvancedCustomization: PropTypes.bool.isRequired,
  bl: PropTypes.bool.isRequired,
  trackingLink: PropTypes.object,
  onlyAllowedPodcastCid: PropTypes.string,
  canCreateSmartLinks: PropTypes.bool,
};

TrackingLinkForm.defaultProps = {
  trackingLink: null,
};

export default withUser(TrackingLinkForm);
