import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import ReactTable from 'react-table';
import moment from 'moment';
import Loading from 'shared/Loading';

const KeyList = ({ keys, pageCount, page, pageSize, setPage, loading }) => {
  const renderList = () => {
    const columns = [
      {
        Header: 'Creator',
        accessor: 'user',
        getHeaderProps: () => ({
          className: 'mw4 tl',
        }),
        className: 'mw4 tc',
      },
      {
        Header: 'API Key',
        accessor: 'token',
        className: 'code f7',
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        getHeaderProps: () => ({
          className: 'mw5',
        }),
        className: 'mw5',
        Cell: row => (
          <div className="tc">{moment(row.original.createdAt).format('MM/DD/YYYY HH:mm')}</div>
        ),
      },
      {
        Header: 'Deactivated',
        accessor: 'deactivatedAt',
        getHeaderProps: () => ({
          className: 'mw5',
        }),
        className: 'mw5',
        Cell: row => (
          <div className="tc">{moment(row.original.deactivatedAt).format('MM/DD/YYYY HH:mm')}</div>
        ),
      },
    ];

    return (
      <div className="mb4">
        {loading ? (
          <Loading fullScreen />
        ) : (
          <ReactTable
            getTrProps={() => ({
              style: {
                display: 'flex',
                alignItems: 'center',
                height: '40px',
              },
            })}
            data={keys}
            columns={columns}
            pageSize={Math.min(pageSize, keys.length)}
            showPagination={false}
            className="-striped w-100 f5"
          />
        )}
      </div>
    );
  };

  const renderPagination = () => {
    return (
      <ReactPaginate
        className={pageCount === 1 && 'dn'}
        previousLabel="&laquo;"
        nextLabel="&raquo;"
        breakClassName="dib mr2"
        pageClassName="dib mr2 b--gray br2 ba bg-white"
        previousClassName={page > 1 ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
        nextClassName={page < pageCount ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
        pageLinkClassName="dib pv2 ph3 pointer"
        previousLinkClassName="dib pv2 ph3 pointer"
        nextLinkClassName="dib pv2 ph3 pointer"
        activeClassName="bg-blue white"
        disabledClassName="dn"
        containerClassName={`${pageCount === 1 && 'dn'} pa2 list pl0 w-100 center tc`}
        onPageChange={event => setPage(event.selected + 1)}
        initialPage={page - 1}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        disableInitialCallback
      />
    );
  };
  if (!(keys && keys.length)) {
    return null;
  }
  return (
    <div>
      <h3>Inactive Keys</h3>
      {renderList()}
      {renderPagination()}
    </div>
  );
};

export default KeyList;
