import React from 'react';
import PropTypes from 'prop-types';

import SmallLoading from 'shared/SmallLoading';
import PopoutSelect from 'shared/PopoutSelect';

function DataChartBase({
  children,
  title,
  subtitle,
  className,
  isLoading,
  inlineStyle,
  tooltip,
  metricOptions,
  selectedMetric,
  handleSelectMetric,
}) {
  return (
    <div style={inlineStyle} className={`bg-white br3 flex flex-column ${className}`}>
      <div className="flex mb2 flex-row items-center">
        {title && (
          <div className="flex flex-row">
            <h2 className="mv0 black-90 dib mr2">{title}</h2>
            <h4 className="mv0 dib black-70">{subtitle}</h4>
          </div>
        )}
        {metricOptions && (
          <div className="ml3">
            <PopoutSelect
              innerClassName="w4 pv1 ph2"
              defaultOption={selectedMetric}
              options={metricOptions}
              onSelect={handleSelectMetric}
            />
          </div>
        )}
        {isLoading && (
          <div className="ml-auto self-start">
            <SmallLoading />
          </div>
        )}
      </div>
      {tooltip && (
        <div className="f7 b gray ttu tracked tc ml2" style={{ marginTop: '3px' }}>
          <a
            data-tip={tooltip}
            data-for={title}
            className="ph1 ba b--gray br-100 tc"
            style={{ cursor: 'help' }}
          >
            ?
          </a>
        </div>
      )}
      {children}
    </div>
  );
}

DataChartBase.propsTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  isLoading: PropTypes.bool,
  inlineStyle: PropTypes.object,
  metricOptions: PropTypes.array,
  selectedMetric: PropTypes.object,
  handleSelectMetric: PropTypes.func,
};

DataChartBase.defaultProps = {
  isLoading: false,
  className: '',
  tooltip: null,
  metricOptions: null,
  selectedMetric: null,
  handleSelectMetric: null,
  title: null,
};

export default DataChartBase;
