import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Upgrade from 'images/upgrade.svg';
import Button from 'shared/Button';
import { redirectToPricingPage } from './helpers';

export default function UpgradeModal({ className, message, teamId, redirectUrl, event }) {
  return (
    <div className="fixed bg-washed-green w-70 center h-75 z-9999 br3 ba b--light-silver bw1 tc">
      <img src={Upgrade} style={{ width: '400px', height: '400px' }} className="center tc v-mid" />
      <p className="f4"> {message} </p>
      <div className="flex flex-wrap flex-grow justify-center center w-90 center">
        <Button
          onClick={e => redirectToPricingPage(event, teamId)}
          className="mt2 w5 mh1"
          size="huge"
          type="primary"
        >
          Learn more!
        </Button>
        <Button onClick={e => (window.location = redirectUrl)} className="mt2 w5 mh1" size="huge">
          Not now
        </Button>
      </div>
    </div>
  );
}

UpgradeModal.propTypes = {
  message: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  className: PropTypes.string,
  redirectUrl: PropTypes.string.isRequired,
};

UpgradeModal.defaultProps = {
  className: '',
};
