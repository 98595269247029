/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { getTrackingLinks } from 'shared/api/v1';
import BannerNotice from 'shared/BannerNotice';
import useJWTManager from 'shared/hooks/useJWTManager';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';
import UserContext, { withUser } from 'shared/UserContext';
import { ReadOnlyModeContext } from 'shared/ReadOnlyModeContext';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import { routes } from './routes';

const DashboardLinks = props => {
  const [state, doSetState] = useState({
    selectedPodcast: null,
    selectedEpisode: null,
    trackingLinks: null,
    loadingTrackingLinks: false,
    linkTablePageSize: 20,
    linkTablePage: 1,
    linkTableSortBy: 'created_at',
    linkTableSortDesc: true,
    includeArchived: false,
    totalTrackingLinks: 0,
    failedToLoadTrackingLinks: false,
    startDate: moment('2017-12-31').toDate(),
    endDate: moment().toDate(),
    lookbackWindow: props.lookbackWindow,
    showAutomatedOnly: false,
    trackingLink: null,
    preventRedirect: false,
  });

  const readOnlyMode = useReadOnlyMode();

  const setState = setter => {
    doSetState(prevState => ({
      ...prevState,
      ...setter,
    }));
  };

  const handleChangeLookbackWindow = lookbackWindow => {
    setState({ lookbackWindow });
  };

  const resetLinkTable = () => {
    setState({
      linkTablePage: 1,
      linkTableSortBy: 'created_at',
      linkTableSortDesc: true,
    });
  };

  const handleLinkTablePaging = page => {
    setState({ linkTablePage: page });
  };

  const handleLinkTableSort = (column, desc) => {
    setState({
      linkTablePage: 1,
      linkTableSortBy: column,
      linkTableSortDesc: desc,
    });
  };

  const handleLoadTrackingLinks = async () => {
    if (state.loadingTrackingLinks) return;
    setState({ loadingTrackingLinks: true });
    try {
      const params = {
        page: state.linkTablePage,
        pageSize: state.linkTablePageSize,
        archived: state.includeArchived,
        sortBy: state.linkTableSortBy,
        sortDesc: state.linkTableSortDesc,
        podcastId: state.selectedPodcast ? state.selectedPodcast.id : null,
        episodeId: state.selectedEpisode ? state.selectedEpisode.id : null,
        startDate: state.startDate,
        endDate: state.endDate,
        automated: state.showAutomatedOnly,
      };

      const res = await getTrackingLinks(params);

      setState({
        trackingLinks: res.data.data,
        totalTrackingLinks: res.data.metadata.total,
        loadingTrackingLinks: false,
        preventRedirect: res.data.metadata.total > 0 || state.preventRedirect,
      });
      if (res.data.metadata.total === 0 && !state.preventRedirect && !state.showAutomatedOnly) {
        props.history.push(`/teams/${props.match.params.teamId}/dashboard/links/new`);
      }
    } catch (e) {
      setState({ failedToLoadTrackingLinks: true });
    }
  };

  useEffect(() => {
    handleLoadTrackingLinks();
  }, [
    state.linkTablePage,
    state.linkTableSortBy,
    state.linkTableSortDesc,
    state.lookbackWindow,
    state.showAutomatedOnly,
    state.startDate,
    state.endDate,
    state.selectedEpisode,
    state.selectedPodcast,
  ]);

  const handleUpdateDateRange = (startDate, endDate) => {
    setState({ startDate, endDate });
  };

  const onSaveTrackingLink = trackingLink =>
    window.location.assign(`/teams/${props.teamId}/dashboard/links/${trackingLink.id}?isNew=true`);

  const onSelectPodcast = selectedPodcast => {
    setState({ selectedPodcast, selectedEpisode: null });
    resetLinkTable();
  };

  const onSelectEpisode = selectedEpisode => {
    setState({ selectedEpisode });
    resetLinkTable();
  };

  const onAutomationFilterClick = () => setState({ showAutomatedOnly: !state.showAutomatedOnly });

  const componentProps = {
    CREATE: {
      onSaveTrackingLink,
    },
    ARCHIVED: {
      onUnarchiveTrackingLink: () => setState({ trackingLinks: null }),
    },
    DETAIL: {
      onArchiveTrackingLink: () => setState({ trackingLinks: null }),
      handleUpdateDateRange,
      handleChangeLookbackWindow,
      onSaveTrackingLink,
    },
    OVERVIEW: {
      onSelectPodcast,
      onSelectEpisode,
      onAutomationFilterClick,
      handleSort: handleLinkTableSort,
      handleLoadTrackingLinks,
      handlePaging: handleLinkTablePaging,
      handleChangeLookbackWindow,
      handleUpdateDateRange,
      linkTableSortBy: state.linkTableSortBy,
      linkTableSortDesc: state.linkTableSortDesc,
    },
  };

  return (
    <Switch>
      {routes.map(({ path, key, Component }) => (
        <Route
          path={path}
          key={key}
          render={renderProps => (
            <Component {...renderProps} {...props} {...state} {...componentProps[key]} />
          )}
        />
      ))}
      <Route component={() => <h1>Not Found!</h1>} />
    </Switch>
  );
};

DashboardLinks.propTypes = {
  lookbackWindow: PropTypes.number.isRequired,
  availableLookbackWindows: PropTypes.array.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hasSmartLinksAutomation: PropTypes.bool,
  teamId: PropTypes.string,
};

const WrappedDashboardLinks = compose(withRouter, withUser)(DashboardLinks);

const DashboardLinksRouter = props => {
  const { initialLoad } = useJWTManager(props.userInfo.id, props.userInfo.teamId);

  if (initialLoad) {
    return <Loading fullScreen />;
  }

  return (
    <Sidebar readOnlyMode={props.readOnlyMode} sidebar={props.sidebar}>
      <ReadOnlyModeContext.Provider value={props.readOnlyMode}>
        <UserContext.Provider value={props.userInfo}>
          <Router>
            <Route path="/teams/:teamId/dashboard/links">
              {props.linkableReadOnly && (
                <BannerNotice message="SmartLinks are read-only while we undergo some routine maintenance." />
              )}
              <WrappedDashboardLinks {...props} />
            </Route>
          </Router>
        </UserContext.Provider>
      </ReadOnlyModeContext.Provider>
    </Sidebar>
  );
};

DashboardLinksRouter.propTypes = {
  sidebar: PropTypes.any,
  userInfo: PropTypes.object,
};

export default DashboardLinksRouter;
