import React from 'react';

const SellerForm = ({ profile, isBoth }) => {
  return (
    <div>
      <span className="f7 db i mb3">
        The contact named below will receive correspondence from Connect.
      </span>
      <div className="mb3">
        <label className="mb1 f5" htmlFor="contactName">
          Contact Name
        </label>
        <input
          defaultValue={profile.contactName}
          name="contactName"
          type="text"
          className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
        />
      </div>
      <div className="mb3">
        <label className="mb1 f5" htmlFor="contactEmail">
          Contact Email
        </label>
        <input
          defaultValue={profile.contactEmail}
          name="contactEmail"
          type="text"
          className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
        />
      </div>
      <div className="mb3">
        <label className="mb1 f5" htmlFor="contactTitle">
          Contact Role
        </label>
        <input
          defaultValue={profile.contactTitle}
          name="contactTitle"
          type="text"
          className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
        />
      </div>
      {!isBoth && (
        <div className="mb3">
          <label className="mb1 f5" htmlFor="contactCompany">
            Company
          </label>
          <input
            defaultValue={profile.contactCompany}
            name="contactCompany"
            type="text"
            className="mt1 pa2 input-reset ba w-100-ns bg-white br2 b--silver"
          />
        </div>
      )}
    </div>
  );
};

export default SellerForm;
