import React, { useCallback, useEffect, useState } from 'react';
import { AnimatedList } from 'react-animated-list';
import Joyride from 'react-joyride';
import Modal from 'react-modal';
import {
  DataSearch,
  ReactiveBase,
  ReactiveList,
  SingleList,
  SingleRange,
} from '@appbaseio/reactivesearch';
import user from 'images/icons/sidebar/user_blue.svg';
import { debounce, isEmpty } from 'lodash';
import {
  getElasticsearchKey,
  getTeam,
  sendConnectMessage,
  setViewedConnectTour,
  setViewedSellerPrompt,
  upsertBuyerProfile,
  upsertSellerProfile,
} from 'shared/api';
import ErrorBoundary from 'shared/ErrorBoundary';
import { serialize } from 'shared/helpers';
import Loading from 'shared/Loading';

import BuyerProfile from './components/BuyerProfile';
import ConnectForm from './components/ConnectForm';
import ConnectSearch from './components/ConnectSearch';
import ConnectUpsell from './components/ConnectUpsell';
import ResultItem from './components/ResultItem';
import SellerProfilePrompt from './components/SellerProfilePrompt';
import Slider from './components/Slider';
import SortControl from './components/SortControl';
import UserSettings from './components/UserSettings';
import { EIGHTEEN_MONTHS_AGO, SORT_OPTIONS, TOUR_ONE_STEPS, TOUR_TWO_STEPS } from './constants';
import styles from './styles/styles.module.css';

const actor = 'publisher';
const querystring = require('querystring');
const ConnectView = ({
  teamId,
  url,
  history,
  teamHashedId,
  query,
  fetchTeam,
  filterOptions,
  buyerProfile,
  apiKey,
  shouldAutoFetchKey,
  fetchNewKey,
  fetchingNewKey,
  resultListHasLoaded,
  relatedPodcast,
  setRelatedPodcast,
  setBuyerProfile,
  updateState,
  sentPodcastIds,
  setSentPodcastIds,
  setResultListHasLoaded,
  userType,
  sellerProfile,
  viewedSellerPrompt,
  completedTours,
  wizardUrl,
  hasConnectProFeatures: isPro,
  profileConfigIsOpen,
}) => {
  const [podcastToContact, setPodcastToContact] = useState(null);
  const [formState, setFormState] = useState({});
  const [sendingState, setSendingState] = useState(false);
  const [buyerProfileIsOpen, setBuyerProfileIsOpen] = useState(false);
  const [genre, setGenre] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(!!profileConfigIsOpen);
  const [sellerProfileIsOpen, setSellerProfileIsOpen] = useState(false);
  const [tourState, setTourState] = useState(completedTours);
  const [skippedSellerProfile, setSkipperSellerProfile] = useState(false);
  const [upsellModalIsOpen, setUpsellModalIsOpen] = useState(false);

  useEffect(() => {
    if (podcastToContact === null) {
      handleModalClose();
    }
  }, [podcastToContact]);

  const handleSortBy = sortOption => {
    updateState({
      action: 'SET',
      key: 'sortOption',
      value: sortOption,
    });
  };

  const handleSortDirection = () => {
    const opposite = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(opposite);
  };

  const transformHeader = (request, theKey) => {
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `ApiKey ${theKey}`,
      },
    };
  };

  const emitSearchChange = useCallback(
    debounce((val, triggerQuery) => {
      updateState({
        action: 'SET',
        value: val,
        key: 'textSearch',
      });
    }, 400),
    [],
  );

  const onChange = (value, triggerQuery, event) => {
    if (!event) return;
    const val = event.target.value;
    setDebounceSearch(val);
    emitSearchChange(val, triggerQuery);
  };

  const handleContact = podcast => {
    if (sentPodcastIds.includes(podcast.id)) return;
    if (!buyerProfile.id || !buyerProfile.companyName) {
      setBuyerProfileIsOpen(true);
    }
    setPodcastToContact(podcast);
  };

  const handleFormChange = interest => {
    const opp = !formState[interest];
    setFormState({
      ...formState,
      [interest]: opp,
    });
  };

  const sendMessage = async () => {
    setSendingState('sending');
    try {
      const response = await sendConnectMessage({
        buyerProfileId: buyerProfile.id,
        messageSubject: `${
          buyerProfile.ownerName || buyerProfile.companyName || buyerProfile.company_name
        } <> ${podcastToContact.title} (via Chartable Connect)`,
        interestedInCrossPromo: !!formState.interestedInCrossPromo,
        interestedInAds: !!formState.interestedInAds,
        interestedInGuestSpot: !!formState.interestedInGuestSpot,
        podcastId: podcastToContact.id,
        teamId: teamId,
      });
    } catch (error) {
      return setSendingState('error');
    }
    setSentPodcastIds([podcastToContact.id, ...sentPodcastIds]);
    setSendingState('sent');
  };

  const handleModalClose = () => {
    setPodcastToContact(null);
    setSendingState(false);
    setFormState({});
  };

  const handleSetRelatedPodcast = podcast => {
    updateState({
      action: 'SET',
      key: 'relatedPodcast',
      value: podcast,
    });
  };

  const resetFilters = () => {
    updateState({
      action: 'CLEAR',
      key: 'ALL',
      value: null,
    });
  };

  const handleSettingsSubmit = data => {
    handleBuyerProfile(data);
  };

  const notAuthorizedBanner = shouldAutoFetchKey ? null : (
    <div
      onClick={() => fetchNewKey(teamId)}
      className="pointer dim w-100 bb bg-red bt white tc db pa3 mb3 br2 f5"
    >
      {fetchingNewKey ? (
        <Loading fullScreen />
      ) : (
        "We're having some trouble connecting. Click on this banner to retry or refresh the page!"
      )}
    </div>
  );

  const getModalContents = () => {
    switch (sendingState) {
      case 'sending':
        return <Loading fullScreen isChild />;
      case 'sent':
        return (
          <div className="w-100 dark-blue f2 pt5 lh-copy-l tc">
            We've forwarded your intro request to the publisher - you’ll receive an email back when
            the publisher replies.
          </div>
        );
      case 'error':
        return (
          <div className="w-100 dark-blue f2 pt5 lh-copy-l tc">
            Your request could not be sent. Please try again or contact support.
          </div>
        );
      default:
        return (
          <ConnectForm
            handleChange={handleFormChange}
            buyerProfile={buyerProfile}
            handleSend={sendMessage}
            formState={formState}
            podcast={podcastToContact}
          />
        );
    }
  };

  const renderTextSearchNote = () => {
    if (filterOptions.relatedPodcast && filterOptions.relatedPodcast.title) {
      return (
        <div className="dib ml1">
          titles related to <span className="i">"{filterOptions.relatedPodcast.title}"</span>
          <div
            onClick={() => {
              resetFilters();
            }}
            style={{ cursor: 'pointer' }}
            className="red f7 dim ml2 dib"
          >
            &times; remove
          </div>
        </div>
      );
    }
    if (
      filterOptions.textSearch &&
      !(filterOptions.relatedPodcast && filterOptions.relatedPodcast.title)
    ) {
      return (
        <div className="dib ml1">
          results for <span className="i">"{filterOptions.textSearch}"</span>
          <div
            onClick={() => {
              resetFilters();
            }}
            style={{ cursor: 'pointer' }}
            className="red f7 dim ml2 dib"
          >
            &times; remove
          </div>
        </div>
      );
    }
  };

  const onSetMyPods = val => {
    if (val) {
      setSortOption(SORT_OPTIONS.find(o => o.value === null));
    } else {
      setSortOption(SORT_OPTIONS.find(o => o.value === 'top200_rank'));
    }
    setMyPods(val);
  };

  const handleBuyerProfile = data => {
    upsertBuyerProfile(data).then(res => {
      setBuyerProfile(data);
      setBuyerProfileIsOpen(false);
      setViewedConnectTour({ teamId, id: teamHashedId, tour: 1 });
      fetchTeam();
    }, console.error);
  };

  const handleSellerProfile = data => {
    upsertSellerProfile(data).then(() => true, console.error);
  };

  const renderBuyerProfileModal = () => {
    return (
      <div className="">
        <Modal
          shouldCloseOnOverlayClick={false}
          isOpen={buyerProfileIsOpen}
          onRequestClose={() => {
            return false;
          }}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 1 },
            content: {
              outline: 'none',
              maxWidth: '410px',
              height: '420px',
              paddingTop: '0px',
              margin: '50px auto',
            },
          }}
        >
          <BuyerProfile handleSubmit={handleBuyerProfile} teamId={teamId} />
        </Modal>
      </div>
    );
  };

  const skipSellerProfile = () => {
    setSellerProfileIsOpen(false);
    setSkipperSellerProfile(true);
  };

  const renderSellerProfileModal = () => {
    return (
      <div className="">
        <Modal
          shouldCloseOnOverlayClick
          isOpen={sellerProfileIsOpen}
          onRequestClose={() => skipSellerProfile()}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 1 },
            content: {
              outline: 'none',
              maxWidth: '410px',
              height: '210px',
              paddingTop: '0px',
              margin: 'auto',
            },
          }}
        >
          <SellerProfilePrompt
            close={() => skipSellerProfile()}
            teamId={teamId}
            wizardUrl={wizardUrl}
          />
        </Modal>
      </div>
    );
  };

  const renderUpsellModal = () => {
    return (
      <div className="">
        <Modal
          shouldCloseOnOverlayClick
          isOpen={upsellModalIsOpen}
          onRequestClose={() => setUpsellModalIsOpen(false)}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 1 },
            content: {
              outline: 'none',
              maxWidth: '480px',
              height: '315px',
              paddingTop: '0px',
              margin: 'auto',
            },
          }}
        >
          <ConnectUpsell close={() => skipSellerProfile()} teamId={teamId} />
        </Modal>
      </div>
    );
  };

  if (query === null || apiKey == null || buyerProfile === null || sellerProfile === null) {
    return <Loading fullScreen />;
  }

  return (
    <ErrorBoundary>
      <UserSettings
        buyerProfile={buyerProfile}
        sellerProfile={sellerProfile}
        handleSubmit={handleSettingsSubmit}
        setIsOpen={setSettingsOpen}
        teamId={teamId}
        isOpen={settingsOpen}
        userType={userType}
      />
      <div className="mh4 connect-container">
        {notAuthorizedBanner}
        <Joyride
          run={!tourState.tourOne && !sellerProfileIsOpen}
          continuous
          steps={TOUR_ONE_STEPS}
          showProgress
          showSkipButton
          disableScrolling
          callback={({ action }) => {
            if (action === 'reset') {
              setTourState({
                ...tourState,
                tourOne: true,
              });
            }
          }}
          styles={{
            options: {
              primaryColor: '#4B9EC1',
            },
          }}
        />

        {renderSellerProfileModal()}
        {renderBuyerProfileModal()}
        {renderUpsellModal()}
        <Modal
          shouldCloseOnOverlayClick
          isOpen={!!podcastToContact && !buyerProfileIsOpen}
          ariaHideApp={false}
          style={{
            overlay: { zIndex: 1 },
            content: {
              outline: 'none',
              maxWidth: '700px',
              height: '330px',
              paddingTop: '0px',
              margin: '50px auto',
            },
          }}
        >
          {podcastToContact && (
            <div>
              <div
                onClick={() => setPodcastToContact(null)}
                className={`dim fr fw1 dib ${styles.closeContactModal}`}
              >
                &times;
              </div>
              {getModalContents()}
            </div>
          )}
        </Modal>
        <ReactiveBase
          className="mb3"
          app="podcasts"
          url={url}
          theme={{
            typography: {
              fontFamily: 'Source Sans Pro,sans-serif',
            },
            colors: {
              primaryColor: '#4B9EC1',
            },
          }}
          // Passing headers by prop results in the header being urlencoded 'transformRequest'
          // allows me to pass in the auth header without it being encoded -JS
          // (Details: https://github.com/appbaseio/reactivesearch/issues/958)
          transformRequest={req => transformHeader(req, apiKey)}
        >
          <div>
            <div className="flex justify-between">
              <div className={styles.connectSearchContainer}>
                <ConnectSearch onChange={emitSearchChange} searchValue={filterOptions.textSearch} />
              </div>
              <div className="flex justify-between mt3" style={{}}>
                <img
                  className={`settings_button ${styles.settingsButton}`}
                  onClick={() => setSettingsOpen(!settingsOpen)}
                  src={user}
                />
              </div>
            </div>
            <div style={{ paddingTop: '45px' }} className="w-20 dib v-top">
              <div className="f5 ba br2 pa3 b--light-gray br3 mr2 sidebar card">
                {resultListHasLoaded ? (
                  <SingleList
                    className="pb3"
                    componentId="genres"
                    dataField="genres.keyword"
                    title="GENRE"
                    queryFormat="and"
                    size={200}
                    value={genre}
                    onChange={setGenre}
                    onValueChange={val => {
                      updateState({
                        action: 'SET',
                        value: val,
                        key: 'genres',
                      });
                    }}
                    sortBy="asc"
                    innerClass={{
                      title: 'dark-gray',
                      count: 'dn',
                      input: 'br3 genre-search bg-white-important',
                      label: 'dark-gray f6',
                    }}
                  />
                ) : null}
                <h4 className="header-font">AVERAGE RATING</h4>
                <Slider
                  min={0}
                  max={5}
                  start={filterOptions.ratingAverage.start}
                  end={filterOptions.ratingAverage.end}
                  showGreater={false}
                  updateState={updateState}
                  updateKey="ratingAverage"
                />
                <div className="downloads_reach">
                  <h4 className="header-font">DOWNLOADS</h4>
                  <Slider
                    min={0}
                    max={200000}
                    disabled={!isPro}
                    setUpsellModalIsOpen={setUpsellModalIsOpen}
                    start={filterOptions.downloads.start}
                    end={filterOptions.downloads.end}
                    updateState={updateState}
                    updateKey="downloads"
                  />
                  <h4 className="header-font">REACH</h4>
                  <Slider
                    min={0}
                    max={50000}
                    disabled={!isPro}
                    setUpsellModalIsOpen={setUpsellModalIsOpen}
                    start={filterOptions.reach.start}
                    end={filterOptions.reach.end}
                    updateState={updateState}
                    updateKey="reach"
                  />
                </div>
                <h4 className="header-font"># OF REVIEWS</h4>
                <Slider
                  min={0}
                  max={1000}
                  start={filterOptions.reviewCount.start}
                  end={filterOptions.reviewCount.end}
                  updateState={updateState}
                  updateKey="reviewCount"
                />
                <h4 className="header-font"># OF RATINGS</h4>
                <Slider
                  min={0}
                  max={5000}
                  start={filterOptions.ratingCount.start}
                  end={filterOptions.ratingCount.end}
                  updateState={updateState}
                  updateKey="ratingCount"
                />
              </div>
            </div>
            <div style={{ marginTop: '-10px' }} className="w-80 dib v-top">
              <div className="ml8 pl2">
                <ReactiveList
                  loader={<Loading fullScreen />}
                  showLoader={false}
                  componentId="SearchResult"
                  pagination
                  react={{
                    and: [],
                  }}
                  size={10}
                  dataField={() => {
                    return filterOptions.sortOption && filterOptions.sortOption.value;
                  }}
                  paginationAt="bottom"
                  autosuggest
                  onPageChange={pg => {
                    if (pg !== filterOptions.SearchResult) {
                      updateState({ action: 'SET', key: 'SearchResult', value: pg });
                    }
                  }}
                  render={({ loading, error, data }) => {
                    if (loading) return null;
                    return (
                      <AnimatedList animation="fade" initialAnimationDuration={1000}>
                        {data.map(podcast => (
                          <ResultItem
                            key={podcast.slug}
                            setUpsellModalIsOpen={setUpsellModalIsOpen}
                            handleContact={handleContact}
                            podcast={podcast}
                            isPro={isPro}
                            sent={sentPodcastIds.includes(podcast.id)}
                            setRelatedPodcast={handleSetRelatedPodcast}
                          />
                        ))}
                      </AnimatedList>
                    );
                  }}
                  onData={() => {
                    // This is a hack to get the initial load working correctly.
                    // When this and the Genre filter component rendered together, one
                    // of their get requests to ES would fail -JS
                    if (resultListHasLoaded) return;
                    setResultListHasLoaded(true);
                  }}
                  renderError={console.error}
                  onError={error => {
                    if (error.status === 401 && shouldAutoFetchKey) {
                      fetchNewKey(teamId);
                    }
                  }}
                  renderNoResults={() => {
                    return (
                      <div className="mt5">
                        <div>Sorry, no results found.</div>
                        <div>Try removing some filters and try again.</div>
                        <button
                          className="bg-blue white br2 ba b--light-gray dim pa2 mt2"
                          onClick={resetFilters}
                        >
                          Clear All Filters
                        </button>
                      </div>
                    );
                  }}
                  defaultQuery={() => {
                    return query;
                  }}
                  renderResultStats={stats => {
                    return (
                      <div style={{ height: '55px' }} className="gray w-100 f6 pb3 pl1">
                        <div
                          className="dib"
                          style={{ top: '12px', position: 'relative', zIndex: 1 }}
                        >
                          {`Showing ${stats.displayedResults * stats.currentPage + 1} - ${
                            stats.displayedResults * (stats.currentPage + 1)
                          } of ${stats.numberOfResults}`}
                          {renderTextSearchNote()}
                        </div>
                        <Joyride
                          run={!!tourState.tourOne && !sellerProfileIsOpen && !tourState.tourTwo}
                          continuous
                          steps={TOUR_TWO_STEPS}
                          showProgress
                          showSkipButton
                          disableScrolling
                          callback={({ action }) => {
                            if (action === 'reset') {
                              setViewedConnectTour({ teamId, id: teamHashedId, tour: 2 });
                            }
                          }}
                          styles={{
                            options: {
                              primaryColor: '#4B9EC1',
                            },
                          }}
                        />
                        <SortControl
                          sortDirection={filterOptions.sortDirection}
                          sortOption={filterOptions.sortOption}
                          sortOptions={SORT_OPTIONS(isPro)}
                          handleSortDirection={handleSortDirection}
                          handleSortBy={handleSortBy}
                          actor={actor}
                          setMyPods={status =>
                            updateState({ action: 'SET', key: 'myPodcasts', value: status })
                          }
                          setVerified={status =>
                            updateState({ action: 'SET', key: 'verified', value: status })
                          }
                          setQuality={status =>
                            updateState({ action: 'SET', key: 'quality', value: status })
                          }
                          verified={filterOptions.verified}
                          quality={filterOptions.quality}
                          myPods={filterOptions.myPodcasts}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </ReactiveBase>
      </div>
    </ErrorBoundary>
  );
};

export default ConnectView;
