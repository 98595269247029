import React from 'react';
import PropTypes from 'prop-types';

function TableErrorView({ header, subheader, className }) {
  return (
    <div className={`ba b--moon-gray justify-center items-center flex flex-column h5 ${className}`}>
      <h2 className="mt0 mb2">{header}</h2>
      <h4 className="mv0">{subheader}</h4>
    </div>
  );
}

TableErrorView.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string,
  className: PropTypes.string,
};

export default TableErrorView;
