import React from 'react';
import Select from 'react-select';
import AcastLogo from 'images/acast.png';
import AdSwizzLogo from 'images/adswizz.png';
import Art19Logo from 'images/art19.png';
import AudacyLogo from 'images/audacy.png';
import KnitLogo from 'images/knit.png';
import MegaphoneLogo from 'images/megaphone.png';
import TritonLogo from 'images/triton.png';
import PropTypes from 'prop-types';
import { createTrackingPixel } from 'shared/api';
import Button from 'shared/Button';
import { providerDisplayNames, providerOptions } from 'shared/helpers';

const InstallationInstructions = {
  megaphone: (
    <div>
      Follow the{' '}
      <a
        target="_blank"
        href="http://support.megaphone.fm/ad-ops/third-party-tracking"
        rel="noopener noreferrer"
      >
        Megaphone third party tracking instructions
      </a>{' '}
      using the tracking pixel code below.
    </div>
  ),
  mtm: (
    <div>
      Follow the{' '}
      <a
        target="_blank"
        href="http://support.megaphone.fm/ad-ops/third-party-tracking"
        rel="noopener noreferrer"
      >
        Spotify Audience Network third party tracking instructions
      </a>{' '}
      using the tracking pixel code below.
    </div>
  ),
  art19: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Art19
      account.
    </div>
  ),
  adswizz: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your
      AdsWizz account.
    </div>
  ),
  triton: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Omny
      or Triton account.
    </div>
  ),
  omny_tap_new: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Omny
      or Triton account.
    </div>
  ),
  audacy: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your
      Audacy account.
    </div>
  ),
  acast: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Acast
      account.
    </div>
  ),
  knit: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Knit
      account.
    </div>
  ),
  art19_marketplace: (
    <div>
      Copy and paste the tracking pixel code below into the ad order you want to track in your Art19
      Marketplace account.
    </div>
  ),
};

const Providers = [
  {
    name: 'art19',
    image: Art19Logo,
    displayText: 'Art19',
  },
  {
    name: 'megaphone',
    image: MegaphoneLogo,
    displayText: 'Megaphone',
  },
  {
    name: 'adswizz',
    image: AdSwizzLogo,
    displayText: 'AdsWizz',
  },
  {
    name: 'triton',
    image: TritonLogo,
    displayText: 'Omny / Triton',
  },
  {
    name: 'audacy',
    image: AudacyLogo,
    displayText: 'Audacy',
  },
  {
    name: 'mtm',
    displayText: 'Spotify Audience Network',
  },
  {
    name: 'art19_marketplace',
    displayText: 'Art19 Marketplace',
  },
  {
    name: 'knit',
    image: KnitLogo,
    displayText: 'Knit',
  },
  {
    name: 'acast',
    image: AcastLogo,
    displayText: 'Acast',
  },
  {
    name: 'simplecast',
    displayText: 'Simplecast',
  },
];

const SelectProvider = ({ selectedProvider, selectProvider }) => (
  <div>
    <h2 className="mt0 mb1">Select your hosting provider</h2>
    <div className="mv4 h5 w-100">
      <Select
        value={selectedProvider}
        onChange={provider => selectProvider(provider)}
        options={providerOptions}
        className="f5 w-100"
      />
    </div>
  </div>
);

class PixelManager extends React.Component {
  constructor(props) {
    super(props);

    this.createPixel = this.createPixel.bind(this);

    this.state = {
      pixel: props.pixel,
      requestInProgress: false,
      selectedProvider: null,
    };
  }

  createPixel() {
    createTrackingPixel({
      promoId: this.props.promo.audioAdPlacementId,
      teamId: this.props.teamId,
      provider: this.state.selectedProvider ? this.state.selectedProvider.value : null,
      pixelName: `${this.props.promo.name} pixel`,
    })
      .then(res => {
        this.props.onCreatePixelSuccess(res.data);
        this.setState({ pixel: res.data.pixel });
      })
      .catch(err => {
        this.props.onCreatePixelFailure(err);
        this.props.onClose();
      });
  }

  render() {
    const { pixel, selectedProvider, requestInProgress } = this.state;

    if (!pixel) {
      return (
        <div>
          <SelectProvider
            selectedProvider={selectedProvider}
            selectProvider={provider => this.setState({ selectedProvider: provider })}
          />
          <Button
            className="w-100"
            type="primary"
            disabled={!selectedProvider}
            loading={requestInProgress}
            onClick={this.createPixel}
          >
            Confirm & Create Pixel
          </Button>
          {this.props.onClose && (
            <Button size="large" className="w-100 mv3" onClick={this.props.onClose}>
              Close
            </Button>
          )}
        </div>
      );
    }

    return (
      <div>
        <div>
          <h2 className="mt0 mb1 dark-gray">Provider</h2>
          <div>
            <div className="pv1 f4 w-100 ttc">{providerDisplayNames[pixel.provider]}</div>
          </div>
        </div>
        <div className="bg-near-white w-90 center mv3" style={{ height: '2px' }} />
        <div className="mb4 mt0">
          <h2 className="mb2 dark-gray">Installation</h2>
          <div className="bg-near-white pa3">
            {this.props.canInstallPixelCode ? (
              <div className="lh-copy f5 body-font dark-gray">
                {InstallationInstructions[pixel.provider]}
              </div>
            ) : (
              <div className="lh-copy f5 body-font dark-gray mb3">
                The{' '}
                <b className="red bold">
                  publisher team running the promomotion ({this.props.promo.sellerTeam})
                </b>{' '}
                must add this tracking pixel URL to the campaign via their dynamic ad insertion
                platform before the promo starts.
              </div>
            )}
            <div className="red f6 mt2 lh-copy">
              Note: this tag contains macros, or dynamic values, which must be filled in by the ad
              serving platform. <strong>It will not load in a web browser</strong>.
            </div>
            <div className="bg-light-gray w-95 center mv3" style={{ height: '2px' }} />
            <div className="flex flex-column">
              <div
                className="ba bg-white br2 b--light-silver pa3 mb2"
                style={{ wordBreak: 'break-all' }}
              >
                <code className="f6">{pixel.url}</code>
              </div>
            </div>
          </div>
        </div>
        <Button size="large" className="w-100" onClick={this.props.onClose}>
          Close
        </Button>
      </div>
    );
  }
}

PixelManager.propTypes = {
  promo: PropTypes.object.isRequired,
  teamId: PropTypes.string.isRequired,
  pixel: PropTypes.object,
  onPixelCreateSuccess: PropTypes.func,
  onPixelCreateFailure: PropTypes.func,
  canInstallPixelCode: PropTypes.bool,
};

PixelManager.defaultProps = {
  pixel: null,
  canInstallPixelCode: true,
};

export default PixelManager;
