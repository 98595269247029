import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import InvertedPlusIcon from 'images/icons/inverted-plus.svg';
import PropTypes from 'prop-types';
import { createMegaphoneOrganization, getMegaphoneOrganizations } from 'shared/api/v1';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import useUserInfo from 'shared/hooks/useUserInfo';

import BundleConfirmationModal from './BundleConfirmationModal';
import ConnectionConfirmationModal from './ConnectionConfirmationModal';

const TeamConnector = ({ onSuccess }) => {
  const { teamName, canManageMegaphoneConnection, plan } = useUserInfo();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [upgradedTier, setUpgradedTier] = useState(null);
  const [bundleConfirmationIsOpen, setBundleConfirmationIsOpen] = useState(false);

  const [connectOrganizationRequestInProgress, setConnectOrganizationRequestInProgress] =
    useState(false);

  const handleConnectMegaphoneOrganization = async () => {
    setConnectOrganizationRequestInProgress(true);
    try {
      const response = await createMegaphoneOrganization({
        id: selectedOrganization.id,
        name: selectedOrganization.name,
      });

      onSuccess(response.data);
    } catch (e) {
      try {
        setError(e.response.data.errors.base[0]);
      } catch (_) {
        setError('Failed to link Megaphone organization.');
      }

      setConfirmationModalIsOpen(false);
      setConnectOrganizationRequestInProgress(false);
    }
  };

  const handleGetMegaphoneOrganizations = async () => {
    if (!canManageMegaphoneConnection) return;
    setLoading(true);

    try {
      const response = await getMegaphoneOrganizations();
      if (response.data.error) {
        throw new Error(response.data.error);
      }

      const organizations = response.data
        .filter(org => ['super_admin', 'organization_admin'].includes(org.role))
        .map(org => ({
          label: org.name,
          value: org.id,
          name: org.name,
          id: org.id,
          megaphonePlan: org.type,
          bundleEligible: org.chartable_bundle_eligible,
        }));
      setOrganizationOptions(organizations);
    } catch (e) {
      try {
        setError(e.message);
      } catch (_) {
        setError('Failed to get Megaphone organizations.');
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetMegaphoneOrganizations();
  }, []);

  const checkBundleEligible = () => {
    let bundle = null;
    if (selectedOrganization.bundleEligible) {
      if (selectedOrganization.megaphonePlan === 'enterprise') {
        bundle = 'Enterprise';
      } else {
        bundle =
          ['pro', 'professional'].includes(selectedOrganization.megaphonePlan) &&
          plan !== 'enterprise'
            ? 'Pro'
            : null;
      }
    }
    setUpgradedTier(bundle);
    return bundle;
  };

  const handleLink = () => {
    const availableBundle = checkBundleEligible();
    if (availableBundle) {
      setBundleConfirmationIsOpen(true);
    } else {
      setConfirmationModalIsOpen(true);
    }
  };

  return (
    <>
      {!canManageMegaphoneConnection && (
        <Banner className="mb2 b">
          Only team Admins and Owners can connect a Megaphone Organization
        </Banner>
      )}
      {error && (
        <Banner className="mb2" error onClick={() => setError(null)}>
          {error}
        </Banner>
      )}
      <div className="card pb5 pa3 flex flex-column">
        <div className="flex items-center flex-row mb4">
          <div
            style={{
              background: 'linear-gradient(135deg, #2D46B9 0%, #F573A0 100%)',
              width: '40px',
              height: '40px',
            }}
          />
          <div style={{ marginLeft: '10px' }}>
            <div className="force-inter fw-500 dark-gray f4">{teamName}</div>
            <div className="f6 gray">Chartable Team</div>
          </div>
        </div>
        <div>
          <div className="mid-gray f6 fw5 mb2" style={{ marginLeft: '50px' }}>
            Link your Megaphone Organization
          </div>
          <div className="flex flex-row w-100 items-center">
            <img src={InvertedPlusIcon} alt="" className="mr3 o-50 h2 w2" />
            <div style={{ width: '23rem' }}>
              <Select
                placeholder="Select Megaphone Organization"
                options={organizationOptions}
                onChange={setSelectedOrganization}
                isDisabled={loading || !canManageMegaphoneConnection}
              />
            </div>
          </div>
        </div>
        <Button
          className={`white pv3 w5 mt5 fw7 center ${
            !selectedOrganization ? 'bg-moon-gray' : 'bg-spotify-purple'
          }`}
          style={{ borderRadius: '40px', marginLeft: '50px' }}
          disabled={!selectedOrganization}
          onClick={handleLink}
        >
          Link Teams
        </Button>
        <BundleConfirmationModal
          isOpen={bundleConfirmationIsOpen}
          onClose={() => setBundleConfirmationIsOpen(false)}
          onConfirm={() => {
            setBundleConfirmationIsOpen(false);
            setConfirmationModalIsOpen(true);
          }}
          upgradedTier={upgradedTier}
        />
        <ConnectionConfirmationModal
          isLoading={connectOrganizationRequestInProgress}
          isOpen={confirmationModalIsOpen}
          onClose={() => setConfirmationModalIsOpen(false)}
          onConfirm={handleConnectMegaphoneOrganization}
          selectedOrganization={selectedOrganization}
        />
      </div>
    </>
  );
};

TeamConnector.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default TeamConnector;
