import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import Button from 'shared/Button';
import Loading from 'shared/Loading';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode'

import Tag from './Tag';

const RequestList = ({ tagTypes, requests, handleButtonPress, teamId }) => {
  const readOnlyMode = useReadOnlyMode();

  const TableLink = ({ children, className, row }) => (
    <Link
      to={
        readOnlyMode ? '#' : `/teams/${teamId}/dashboard/adops/requests/${row.original.id}`
      }
      className={`color-inherit no-underline ${className}`}
    >
      {children}
    </Link>
  );

  const columns = useMemo(
    () => [
      {
        Header: '',
        sortable: false,
        width: 180,
        Cell: row => {
          const mustWaitForPublishDate =
            row.original.tags.includes('smartPromo') &&
            row.original.campaignType === 'baked_in' &&
            !row.original.episode &&
            !!row.original.scheduledAirDate &&
            moment(row.original.scheduledAirDate).isAfter(new Date());

          return (
            <div className="gap flex flex-row items-center h-100">
              {mustWaitForPublishDate && <ReactTooltip id={row.original.id} />}
              <div
                data-for={mustWaitForPublishDate ? row.original.id : null}
                data-tip={
                  mustWaitForPublishDate
                    ? `You will be able to approve this promo the day after its scheduled air date, ${moment(
                        row.original.scheduledAirDate,
                      ).format('MM/DD/YYYY')}`
                    : null
                }
                className="w-50"
              >
                <Button
                  onClick={() => handleButtonPress(row.original, true)}
                  className="w-100"
                  size="small"
                  type="create"
                  disabled={
                    readOnlyMode || row.original.tags.includes(tagTypes.denied.value) || mustWaitForPublishDate
                  }
                >
                  Approve
                </Button>
              </div>
              <Button
                onClick={() => handleButtonPress(row.original, false)}
                inverted
                className="w-50"
                size="small"
                type="destructive"
                disabled={readOnlyMode || row.original.tags.includes(tagTypes.denied.value)}
              >
                Deny
              </Button>
            </div>
          );
        },
      },
      {
        Header: 'Tags',
        sortable: false,
        Cell: row => {
          return (
            <TableLink row={row} className="no-underline flex flex-wrap gap-small">
              {row.original.tags.map(tag => {
                if (tagTypes[tag].value === 'smartPromo') {
                  return <></>;
                }
                return (
                  <Tag
                    key={tagTypes[tag].value}
                    label={tagTypes[tag].label}
                    color={tagTypes[tag].color}
                  />
                );
              })}
            </TableLink>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        className: 'flex items-center pl2',
        Cell: row => <TableLink row={row}>{row.original.name}</TableLink>,
      },
      {
        Header: 'Buyer',
        id: 'buyer',
        className: 'flex items-center pl2',
        accessor: row => (row.buyerTeam ? row.buyerTeam : row.buyer),
        Cell: row => (
          <TableLink row={row}>
            {row.original.buyerTeam ? row.original.buyerTeam : row.original.buyer}
          </TableLink>
        ),
      },
      {
        Header: 'Sold Show',
        id: 'soldPodcast',
        className: 'flex items-center',
        accessor: row =>
          // eslint-disable-next-line no-nested-ternary
          row.podcast ? row.podcast.title : row.sellerPodcast ? row.sellerPodcast.title : null,
        Cell: row => {
          let podcast;
          if (row.original.podcast) {
            podcast = row.original.podcast;
          } else if (row.original.sellerPodcast) {
            podcast = row.original.sellerPodcast;
          } else {
            return (
              <TableLink row={row}>
                <div className="tc i">Multiple or Run of Network</div>
              </TableLink>
            );
          }

          return (
            <TableLink row={row} className="flex items-center">
              <div className="mr2 h2 w2">
                <img src={podcast.displayImageUrl} className="center fl br2" alt="" />
              </div>
              <div className="w-70">{podcast.shortTitle}</div>
            </TableLink>
          );
        },
      },
      {
        Header: 'Promoting',
        id: 'promoting',
        className: 'flex items-center',
        accessor: row => (row.buyer ? row.buyer : row.buyerPodcast.title),
        Cell: row => {
          if (row.original.buyer) {
            return <div>{row.original.buyer}</div>;
          } else if (!row.original.buyerPodcast) {
            return (
              <TableLink row={row}>
                <div className="tc i">Multiple or Run of Network</div>
              </TableLink>
            );
          }

          return (
            <TableLink row={row} className="flex items-center">
              <div className="mr2 h2 w2">
                <img
                  src={row.original.buyerPodcast.displayImageUrl}
                  className="center fl br2"
                  alt=""
                />
              </div>
              <div className="w-70">{row.original.buyerPodcast.shortTitle}</div>
            </TableLink>
          );
        },
      },
      {
        Header: 'Air Date',
        id: 'startDate',
        className: 'flex items-center f6',
        accessor: row =>
          // eslint-disable-next-line no-nested-ternary
          row.scheduledAirDate
            ? row.scheduledAirDate
            : row.plannedStartDate
            ? row.plannedStartDate
            : null,
        Cell: row => {
          if (row.original.scheduledAirDate) {
            return (
              <TableLink row={row}>
                {moment(row.original.scheduledAirDate).format('MM/DD/YYYY')}
              </TableLink>
            );
          } else if (row.original.plannedStartDate) {
            return (
              <TableLink row={row} className="flex flex-row items-center">
                <div>{moment(row.original.plannedStartDate).format('MM/DD/YYYY')}</div>
                {row.original.plannedEndDate && (
                  <React.Fragment>
                    <div className="mh2">-</div>
                    <div>{moment(row.original.plannedEndDate).format('MM/DD/YYYY')}</div>
                  </React.Fragment>
                )}
              </TableLink>
            );
          }
          return (
            <TableLink row={row} className="tc w-100">
              -
            </TableLink>
          );
        },
      },
    ],
    [],
  );

  if (!requests) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <ReactTable
        columns={columns}
        data={requests}
        pageSize={requests.length >= 10 ? 10 : requests.length}
        showPageSizeOptions={false}
        className="-highlight pointer"
      />
    </div>
  );
};

export default RequestList;
