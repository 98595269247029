import React from 'react';
import ReactPaginate from 'react-paginate';
import ReactTable from 'react-table';
import classnames from 'classnames';
import Loading from 'shared/Loading';

function PodcastTable({
  reportingWindow,
  page,
  urlBase,
  podcasts,
  totalPodcasts,
  updatePodcastTablePage,
  updatePodcastTableSort,
  tableSortLoadInProgress,
}) {
  const columns = [
    {
      Header: 'Podcast',
      id: 'title',
      Cell: row => (
        <div className="flex items-center">
          <div className="w2 h2">
            <img src={row.original.imageUrl} className="center fl br2" alt="" />
          </div>
          <div className="w-75 pa2" style={{ fontSize: '20px' }}>
            {row.original.title}
          </div>
        </div>
      ),
    },
    {
      Header: `Downloads ${reportingWindow}d`,
      id: 'downloads',
      accessor: `statistics[${reportingWindow}].downloads`,
      Cell: row => (
        <div className="tc">
          {row.original.statistics[reportingWindow].downloads
            ? row.original.statistics[reportingWindow].downloads.toLocaleString()
            : 'N/A'}
        </div>
      ),
    },
    {
      Header: `Downloads ${reportingWindow}d growth`,
      id: 'statistics[${reportingWindow}].downloadsGrowth',
      sortable: false,
      Cell: row => (
        <div className="tc">
          {row.original.statistics[reportingWindow].downloadsGrowth
            ? `${row.original.statistics[reportingWindow].downloadsGrowth}%`
            : 'N/A'}
        </div>
      ),
    },
  ];

  const pageSize = 10;
  const pageCount = Math.ceil(totalPodcasts / pageSize);

  return (
    <div className="relative">
      <ReactTable
        getTrProps={(state, rowInfo) => ({
          style: { cursor: 'pointer', display: 'flex', alignItems: 'center' },
          onClick: () => (location = `${urlBase}?podcast_id=${rowInfo.original.id}`),
        })}
        defaultSorted={[
          {
            id: 'downloads',
            desc: true,
          },
        ]}
        data={podcasts}
        collapseOnDataChange
        pageSize={totalPodcasts >= pageSize ? pageSize : totalPodcasts}
        showPagination={false}
        columns={columns}
        className="-highlight w-100"
        onSortedChange={sort => updatePodcastTableSort(sort[0].id, sort[0].desc)}
      />
      <ReactPaginate
        className={pageCount === 1 && 'dn'}
        previousLabel="&laquo;"
        nextLabel="&raquo;"
        breakClassName="dib mr2"
        pageClassName="dib mr2 b--gray br2 ba bg-white"
        previousClassName={page > 1 ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
        nextClassName={page < pageCount ? 'dib mr2 b--gray br2 ba bg-white' : 'dn'}
        pageLinkClassName="dib pv2 ph3 pointer"
        previousLinkClassName="dib pv2 ph3 pointer"
        nextLinkClassName="dib pv2 ph3 pointer"
        activeClassName="bg-blue white"
        disabledClassName="dn"
        containerClassName={classnames(pageCount === 1 && 'dn', 'pa2 list pl0 w-100 center tc')}
        onPageChange={event => updatePodcastTablePage(event.selected + 1)}
        initialPage={page - 1}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        disableInitialCallback
      />
      {tableSortLoadInProgress && (
        <div className="absolute top-0 w-100 h-100">
          <div className="absolute top-0 w-100 h-100 bg-light-gray o-30" />
          <Loading className="mb5" fullScreen />
        </div>
      )}
    </div>
  );
}

export default PodcastTable;
