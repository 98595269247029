import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from 'react-google-charts';

export default function RankHistoryChart({ rankHistory }) {
  const maxRank = Math.max(...rankHistory.map(t => t[1]));
  const maxChartRank = maxRank;
  const chartOptions = {
    displayZoomButtons: false,
    displayRangeSelector: false,
    vAxis: {
      direction: -1,
      viewWindow: {
        min: 1,
        max: maxChartRank,
      },
    },
    hAxis: {
      type: 'date',
      format: 'MMM d, y',
      maxAlternation: 1,
    },
    legend: { position: 'none' },
    chartArea: {
      left: 80,
      top: 20,
      width: '80%',
      height: '80%',
    },
    interpolateNulls: false,
    backgroundColor: {
      strokeWidth: 0,
      fill: 'transparent',
    },
    displayAnnotations: true,
    thickness: 3,
    pointSize: 3,
  };

  const chartColumns = [
    {
      type: 'date',
      label: 'Date',
    },
    {
      type: 'number',
      label: 'Rank',
    },
  ];

  return (
    <div className="mt2 bg-white br2">
      <Chart
        chartType="LineChart"
        options={chartOptions}
        columns={chartColumns}
        rows={JSON.parse(JSON.stringify(rankHistory)).map(t =>
          [new Date(t[0])].concat(t.splice(1)),
        )}
        width="100%"
        height="400px"
      />
    </div>
  );
}

RankHistoryChart.propTypes = {
  rankHistory: PropTypes.array.isRequired,
};
