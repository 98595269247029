import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';

function getTrackableEmailString(promo) {
  const buyerTeamName = promo.buyerTeam.name || promo.buyerTeam;
  const subject = `${buyerTeamName} needs you to install Trackable for an upcoming promo`;
  const body = encodeURI(`Hello,

${buyerTeamName} is using Chartable to measure an upcoming podcast promo on your show, ${promo.sellerPodcast.title}.

Chartable indicates that you don't have a Trackable Prefix installed on your show. The prefix allows Chartable to measure downloads and conversions for this promotion.

In order to install Trackable, sign up for a free Chartable account on www.chartable.com, then follow the instructions to get it installed with your provider.

Please feel free to email adops@chartable.com for assistance on setting up your account or installing Trackable.

Best Regards,
${buyerTeamName}`);
  return `mailto:?subject=${subject}&body=${body}`;
}

const UnknownErrorExplainer = () => (
  <div>
    <div className="f5 b mb2 b">Promo Error</div>
    <div className="f6 lh-copy">
      There has been an error with your promo. The Chartable engineering team has been alerted to
      this error and is working to fix it.
    </div>
    <div className="f6 lh-copy">Please reach out to support if you have any questions.</div>
  </div>
);

const NeedArt19DecryptionKeyExplainer = ({ teamId }) => (
  <div>
    <div className="mb4">
      <div className="f5 mb2 b">Need Art19 Decryption Key</div>
      <div className="f6 lh-copy">
        Art19 encrypts data needed to measure downloads. We need your decryption key to measure
        campaigns.
      </div>
    </div>
    <div>
      <div className="i mb2 b">How to resolve</div>
      <div className="f6 lh-copy mb2">
        To get your key contact your Art19 rep and ask for your tracking pixel decryption key.
      </div>
      <div className="f6 lh-copy">
        Once you have your key, go to{' '}
        <a target="_blank" href={`/teams/${teamId}/dashboard/adops/settings`}>
          your attribution settings page
        </a>{' '}
        and follow the instructions on the page to enter it.
      </div>
    </div>
  </div>
);

const TrackableNotEnabledExplainer = ({ promo, isSeller }) => {
  const { buyerTeamPodcastHasTrackable, sellerTeamPodcastHasTrackable } = promo.trackableErrors;

  return (
    <div>
      <div className="mb3">
        <div className="f5 mb2 b">Podcast Missing Trackable Prefix</div>
        <div className="f6 lh-copy">
          {!buyerTeamPodcastHasTrackable && !sellerTeamPodcastHasTrackable
            ? 'Both Podcasts must have a prefix installed on their feeds in order to perform attribution.'
            : `${
                buyerTeamPodcastHasTrackable ? promo.sellerPodcast.title : promo.buyerPodcast.title
              } must install their trackable pixel in order to perform attribution.`}
        </div>
      </div>
      <div>
        <div className="i b">How to resolve</div>
        {!buyerTeamPodcastHasTrackable && (
          <div className="mt3">
            <div className="b f6 mb2">Fix {promo.buyerPodcast.title} trackable status</div>
            {!isSeller ? (
              <ol className="mb0 mt2">
                <li className="lh-copy f6 mb2">
                  Make sure the Trackable Prefix is installed on {promo.buyerPodcast.title}'s feed.
                </li>
                <li className="lh-copy f6 mb2">
                  Once you're sure it's installed, click the "Refresh Trackable Status" button on
                  your{' '}
                  <a
                    target="_blank"
                    href={`/teams/${
                      promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                    }/dashboard/integrations/trackable`}
                  >
                    integrations page
                  </a>{' '}
                  under settings.
                </li>
                <li className="lh-copy f6">
                  Once you no longer see {promo.buyerPodcast.title} under "These podcasts do not
                  have the Trackable prefix," your promo's error status will return to normal
                  (status updates every 10 minutes).
                </li>
              </ol>
            ) : (
              <div className="f6">
                The publisher of {promo.buyerPodcast.title} must install their team's Trackable
                Prefix on their rss.
              </div>
            )}
          </div>
        )}
        {!sellerTeamPodcastHasTrackable && (
          <div className="mt3">
            <div className="b f6 mb2">Fix {promo.sellerPodcast.title} trackable status</div>
            {isSeller ? (
              <ol className="mb0 mt2">
                <li className="f6 mb2">
                  Make sure the Trackable Prefix is installed on {promo.sellerPodcast.title}'s feed
                  (
                  <a
                    target="_blank"
                    href={`/teams/${
                      promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                    }/dashboard/integrations/trackable`}
                  >
                    Instructions
                  </a>
                  ).
                </li>
                <li className="f6 mb2">
                  Once you're sure it's installed, click the "Refresh Trackable Status" button on
                  your{' '}
                  <a
                    target="_blank"
                    href={`/teams/${
                      promo.buyerTeamSlug ? promo.buyerTeamSlug : promo.buyerTeam.slug
                    }/dashboard/integrations/trackable`}
                  >
                    integrations page
                  </a>{' '}
                  under settings.
                </li>
                <li className="f6">
                  Once you no longer see {promo.sellerPodcast.title} under "These podcasts do not
                  have the Trackable prefix," your promo's error status will return to normal
                  (status updates every 10 minutes).
                </li>
              </ol>
            ) : (
              <div>
                <div>
                  The publisher of {promo.sellerPodcast.title} must install their team's Trackable
                  Prefix on their rss.
                </div>
                <a
                  href={getTrackableEmailString(promo)}
                  target="_blank"
                  className="blue pointer dim"
                >
                  Contact the seller.
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const NoPixelExplainer = () => (
  <div>
    <div className="mb4">
      <div className="f5 mb2 b">No pixel has been created for this dynamic promo</div>
      <div className="f6">
        Once you select a hosting provider, Chartable will generate a tracking pixel for this promo
        for you to install.
      </div>
    </div>
    <div>
      <div className="f5 mb2 b i">How to resolve</div>
      <ol className="mb0 mt2">
        <li className="mb2 f6">
          In the left-most column of this table click the "Install Pixel" button for the promo you
          have selected
        </li>
        <li className="f6">
          Follow the instructions to select a hosting provider and a pixel will automatically be
          generated for you
        </li>
      </ol>
    </div>
  </div>
);

const NoEpisodeOnDropDateExplainer = ({ promo }) =>
  promo.isInternal ? (
    <div>
      <div className="f5 mb2 b">No Episode assigned on publish date</div>
      <div className="f5">
        <a
          href={`/teams/${promo.buyerTeamSlug}/dashboard/promos/${promo.audioAdCampaignId}/manage/${promo.audioAdPlacementId}`}
          rel="noreferrer"
        >
          Click here to select the episode containing the promotion
        </a>
      </div>
    </div>
  ) : (
    <div>
      <div className="mb4">
        <div className="f5 mb2 b">No Episode assigned on publish date</div>
        <div className="f6">
          An episode has not be assigned for the given publish date,{' '}
          <b className="b">{moment(promo.startedAt).format('MM/DD/YYYY')}</b>.
        </div>
      </div>
      <div>
        <div className="f5 mb2 i b">How to resolve</div>
        <div className="header-font f5 mb2 b">
          The publisher team can select the episode containing the promotion on their AdOps
          dashboard
        </div>
      </div>
    </div>
  );

const WaitingForFirstEpisodeDropExplainer = ({ promo }) =>
  promo.isInternal ? (
    <>
      <div className="f5 mb2 b">Waiting for episode to be published</div>
      <div className="f6">
        On given publish date, <b className="b">{moment(promo.startedAt).format('MM/DD/YYYY')}</b>,{' '}
        you can select the episode containing the promo from the{' '}
        <a
          href={`/teams/${promo.buyerTeamSlug}/dashboard/promos/${promo.audioAdCampaignId}/manage/${promo.audioAdPlacementId}`}
          rel="noreferrer"
        >
          SmartPromos Manage tab.
        </a>
      </div>
    </>
  ) : (
    <SimpleExplanation
      title="Waiting for episode to be published"
      subtitle="The publisher team will be able to select the episode containing the promotion once it airs"
    />
  );

const SimpleExplanation = ({ title, subtitle }) => (
  <React.Fragment>
    <div className="f5 b">{title}</div>
    {subtitle && <div className="f6 mt2">{subtitle}</div>}
  </React.Fragment>
);

const AudioAdPlacementStatusExplanation = ({ promo, isSeller, teamId }) => {
  let explanation;

  switch (promo.status.code) {
    case 'NO_EPISODE_ON_DROP_DATE':
      explanation = <NoEpisodeOnDropDateExplainer promo={promo} />;
      break;
    case 'NO_PIXEL':
    case 'NO_PROVIDER_FOR_PIXEL':
      explanation = <NoPixelExplainer />;
      break;
    case 'TRACKABLE_NOT_ENABLED':
      explanation = <TrackableNotEnabledExplainer promo={promo} isSeller={isSeller} />;
      break;
    case 'NEED_ART19_DECRYPTION_KEY':
      explanation = <NeedArt19DecryptionKeyExplainer teamId={teamId} />;
      break;
    case 'WAITING_ON_FIRST_PIXEL_EVENT':
      explanation = (
        <SimpleExplanation
          title="Waiting on first pixel event"
          subtitle="Make sure to install your pixel according to your provider's instructions"
        />
      );
      break;
    case 'WAITING_FOR_FIRST_EPISODE_DROP':
      explanation = <WaitingForFirstEpisodeDropExplainer promo={promo} />;
      break;
    case 'PIXEL_ACTIVE':
      explanation = <SimpleExplanation title="Receiving events from pixel" />;
      break;
    case 'TRACKABLE_ENABLED':
      explanation = (
        <SimpleExplanation title="Trackable prefix is installed. Data will populate automatically." />
      );
      break;
    default:
      explanation = <UnknownErrorExplainer />;
  }

  return (
    <Banner noImage type={promo.status.value.toLowerCase()}>
      {explanation}
    </Banner>
  );
};

AudioAdPlacementStatusExplanation.propTypes = {
  promo: PropTypes.object.isRequired,
  isSeller: PropTypes.bool.isRequired,
  teamId: PropTypes.string.isRequired,
};

export default AudioAdPlacementStatusExplanation;
