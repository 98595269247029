import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import { LookbackWindowSettings } from 'shared/AttributionSettings';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import Checkbox from 'shared/Checkbox';
import EpisodeSearchInput from 'shared/EpisodeSearchInput';
import Header from 'shared/Header';
import { capitalizeFirstLetter } from 'shared/helpers';
import Loading from 'shared/Loading';
import PodcastSwitcher from 'shared/PodcastSwitcher';
import ReportLink from 'shared/ReportLink';
import { withUser } from 'shared/UserContext';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import LinkTable from './LinkTable';

function Overview({
  onSelectPodcast,
  selectedPodcast,
  linkTablePage,
  trackingLinks,
  handleLoadTrackingLinks,
  loadingTrackingLinks,
  match,
  handlePaging,
  handleSort,
  totalTrackingLinks,
  selectedEpisode,
  onSelectEpisode,
  canEditSmartLinks,
  canCreateReports,
  userInfo,
  failedToLoadTrackingLinks,
  teamHashedId,
  availableLookbackWindows,
  lookbackWindow,
  handleChangeLookbackWindow,
  canChangeSmartLinksAttributionWindow,
  startDate,
  endDate,
  handleUpdateDateRange,
  showAutomatedOnly,
  onAutomationFilterClick,
  hasSmartLinksAutomation,
}) {
  const { teamId } = useParams();

  const readOnlyMode = useReadOnlyMode();

  const [banner, setBanner] = useState(null);
  const [attributionWindowSettingsIsOpen, setAttributionWindowSettingsIsOpen] = useState(false);

  useEffect(() => {
    if (!failedToLoadTrackingLinks && !trackingLinks) {
      handleLoadTrackingLinks();
    }
  }, []);

  if (failedToLoadTrackingLinks) {
    return (
      <BannerNotice
        error
        message="Failed to load SmartLinks. Please reach out to support if this problem persists."
      />
    );
  }

  if (!trackingLinks) {
    return <Loading fullScreen />;
  }

  const AutomationFilter = () => {
    return (
      <div>
        <span>
          <Checkbox
            isChecked={showAutomatedOnly}
            className="justify-end mb2"
            onClick={onAutomationFilterClick}
            label="Show only automated SmartLinks"
          />
        </span>
      </div>
    );
  };

  return (
    <div>
      {banner}
      <div className="pa3 mb5">
        <div className="justify-between items-center mb3 flex flex-wrap">
          <div className="flex flex-row gap-small">
            {readOnlyMode ? (
              <>
                <Button disabled type="create" className="w4">
                  New Link
                </Button>
                <Button disabled className="w4">Settings</Button>
              </>
            ) : (
              <>
                <Link className="no-underline white" to={`/teams/${teamId}/dashboard/links/new`}>
                  <Button type="create" className="w4">
                    New Link
                  </Button>
                </Link>
                <Link
                  to={`/teams/${teamId}/dashboard/links/settings/deeplinks`}
                  className="no-underline"
                >
                  <Button className="w4">Settings</Button>
                </Link>
              </>
            )}
          </div>
          <div className="flex-row gap-small items-center flex">
            {canChangeSmartLinksAttributionWindow && !readOnlyMode && (
              <React.Fragment>
                <ReactTooltip id="lookbackwindow" />
                <div
                  data-for="lookbackwindow"
                  data-tip="How many days after each impression we look to find conversions. Click to change."
                  className="mr1 dim f7 tc br4 ph2 pv1 white bg-blue pointer"
                  onClick={() => setAttributionWindowSettingsIsOpen(true)}
                >
                  {lookbackWindow % 24 > 0
                    ? `${lookbackWindow} hour`
                    : `${lookbackWindow / 24} days`}{' '}
                  attribution window
                </div>
              </React.Fragment>
            )}
            <ChartableDatePicker
              showAllTime
              startDate={startDate}
              endDate={endDate}
              handleUpdateDateRange={handleUpdateDateRange}
            />
          </div>
        </div>
        <div className="flex flex-wrap gap mb3">
          <PodcastSwitcher
            teamId={teamId}
            onSelect={selection =>
              onSelectPodcast(selection.type === 'some' ? selection.data : null)
            }
            selectedPodcast={selectedPodcast}
            className="f5 flex-grow"
            fetchDefaultOptions={false}
            loading={false}
          />
          {!readOnlyMode && canEditSmartLinks &&
            (canCreateReports ? (
              <ReportLink
                params={{
                  product: 'SmartLinks',
                  reportType: 'SMARTLINKS_OVERVIEW_REPORT',
                  podcastId: selectedPodcast ? selectedPodcast.id : null,
                  episodeId: selectedEpisode ? selectedEpisode.id : null,
                }}
              >
                <Button className="w4" type="primary" inverted>
                  Export CSV
                </Button>
              </ReportLink>
            ) : (
              <Button
                className="w4"
                type="primary"
                inverted
                onClick={() => {
                  setBanner(
                    <BannerNotice
                      warning
                      message={`Your team's ${capitalizeFirstLetter(
                        userInfo.plan,
                      )} plan can't create reports`}
                    />,
                  );
                }}
              >
                Export CSV
              </Button>
            ))}
        </div>
        {!!selectedPodcast && (
          <div className="card mb3 w-100 pa3">
            <div className="mb2 items-baseline flex flex-row gap-small">
              <Header useNew small>
                Filter by Episode
              </Header>
              {!!selectedEpisode && (
                <div
                  onClick={() => onSelectEpisode(null)}
                  className="f6 blue underline pointer dim"
                >
                  clear
                </div>
              )}
            </div>
            <EpisodeSearchInput
              podcastId={selectedPodcast.id}
              teamId={teamId}
              onSelect={onSelectEpisode}
              selectedEpisode={selectedEpisode}
              className="f5 mb3"
            />
          </div>
        )}
        <div className="flex justify-between items-center mb2">
          <Header className="mr2" useNew>
            SmartLinks
          </Header>
          <Link className="ml-auto blue link" to={`${match.url}/archived`}>
            See archive →
          </Link>
        </div>
        {hasSmartLinksAutomation && <AutomationFilter />}
        <LinkTable
          trackingLinks={trackingLinks}
          handleSort={handleSort}
          handlePaging={handlePaging}
          page={linkTablePage}
          total={totalTrackingLinks}
          loading={loadingTrackingLinks}
          handleLoadTrackingLinks={handleLoadTrackingLinks}
          teamId={teamId}
          hasSmartLinksAutomation={hasSmartLinksAutomation}
        />
      </div>
      <BasicModal
        isOpen={attributionWindowSettingsIsOpen}
        onRequestClose={() => setAttributionWindowSettingsIsOpen(false)}
        ariaHideApp={false}
      >
        <div>
          <LookbackWindowSettings
            teamId={teamId}
            teamHashedId={teamHashedId}
            lookbackWindow={lookbackWindow}
            availableLookbackWindows={availableLookbackWindows}
            onSuccess={team => {
              handleChangeLookbackWindow(team.linkLookbackWindowHours);
              setAttributionWindowSettingsIsOpen(false);
            }}
            onFailure={() => {
              setBanner(
                <BannerNotice
                  error
                  message="Failed to update attribution window."
                  onClose={() => setBanner(null)}
                />,
              );
              setAttributionWindowSettingsIsOpen(false);
            }}
            defaultWindow={1}
            useRadioButtons
            product="smartlinks"
          />
          <Button className="mt3 w4" onClick={() => setAttributionWindowSettingsIsOpen(false)}>
            Close
          </Button>
        </div>
      </BasicModal>
    </div>
  );
}

Overview.propTypes = {
  userInfo: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleChangeLookbackWindow: PropTypes.func.isRequired,
  handlePaging: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  onSelectEpisode: PropTypes.func.isRequired,
  onSelectPodcast: PropTypes.func.isRequired,
  handleLoadTrackingLinks: PropTypes.func.isRequired,
  linkTablePage: PropTypes.number.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  loadingTrackingLinks: PropTypes.bool.isRequired,
  failedToLoadTrackingLinks: PropTypes.bool.isRequired,
  canEditSmartLinks: PropTypes.bool.isRequired,
  canCreateReports: PropTypes.bool.isRequired,
  canChangeSmartLinksAttributionWindow: PropTypes.bool.isRequired,
  availableLookbackWindows: PropTypes.array.isRequired,
  teamHashedId: PropTypes.string.isRequired,
  selectedPodcast: PropTypes.object,
  selectedEpisode: PropTypes.object,
  trackingLinks: PropTypes.array,
  totalTrackingLinks: PropTypes.number,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  handleUpdateDateRange: PropTypes.func,
  showAutomatedOnly: PropTypes.bool,
  onAutomationFilterClick: PropTypes.func,
  hasSmartLinksAutomation: PropTypes.bool,
};

Overview.defaultProps = {
  selectedPodcast: null,
  selectedEpisode: null,
  trackingLinks: null,
  totalTrackingLinks: 0,
  canChangeSmartLinksAttributionWindow: false,
};

export default withUser(Overview);
