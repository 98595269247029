/* eslint-disable react/prop-types */
import React from 'react';
import { components } from 'react-select';

const { Option, Placeholder } = components;

const OptionWithIcon = props => (
  <Option {...props}>
    <div className="input-select__single-value flex flex-row items-center">
      <img
        src={props.data.icon}
        className="input-select__icon br2 mr2"
        style={{ height: '28px' }}
      />
      <div>{props.data.label}</div>
    </div>
  </Option>
);

const SingleValueWithIcon = ({ data }) => (
  <div className="input-select">
    <div className="input-select__single-value flex flex-row items-center">
      {data.icon ? (
        <img src={data.icon} className="input-select__icon mr2 br2" style={{ height: '28px' }} />
      ) : (
        <div
          className="input-select__icon mr2 br2 bg-light-gray"
          style={{ height: '28px', width: '28px' }}
        />
      )}
      <div>{data.label}</div>
    </div>
  </div>
);

const PlaceholderWithEmptyIcon = props => (
  <Placeholder {...props}>
    <div className="flex flex-row items-center">
      <div
        className="input-select__icon mr2 br2 bg-light-gray"
        style={{ height: '28px', width: '28px' }}
      />
      {props.children}
    </div>
  </Placeholder>
);

export { OptionWithIcon, SingleValueWithIcon, PlaceholderWithEmptyIcon };
