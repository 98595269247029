import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Chart } from 'react-google-charts';
import UpsellDatePicker from 'shared/UpsellDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from 'shared/Loading';
import moment from 'moment';
import TitleBar from 'shared/charts/TitleBar';
import { addUtcOffset } from 'shared/helpers';

export default function ChartHistoryChart({
  noTitle,
  title,
  subtitle,
  legendPosition,
  history,
  startDate,
  endDate,
  aggregation,
  loading,
  onGetHistory,
  height,
  includeHourly,
  trackableUrl,
  hideSelectors,
  dualAxes,
  handleChangeEndDate,
  handleChangeStartDate,
  minStartDate,
  teamId,
  hideAggregationOptions,
}) {
  if (loading) {
    return (
      <div>
        {!noTitle && <TitleBar title={title || 'Stats'} subtitle={subtitle} />}
        <div className="mt2 w-100 tc center h-2">
          <Loading />
        </div>
      </div>
    );
  }

  const baseAggregationOptions = {};
  if (includeHourly) {
    baseAggregationOptions.hourly = { value: 'hourly', label: 'Hourly' };
  }
  const aggregationOptions = Object.assign(baseAggregationOptions, {
    daily: { value: 'daily', label: 'Daily' },
    weekly: { value: 'weekly', label: 'Weekly' },
    monthly: { value: 'monthly', label: 'Monthly' },
  });

  let isDate = false;
  let chartColumns = [];
  let rows = [];
  let hAxis = {};
  if (history) {
    chartColumns = history[0].map(col => {
      if (col === 'Date') {
        isDate = true;
        return {
          type: aggregation === 'hourly' ? 'datetime' : 'date',
          label: 'Date',
        };
      }

      return {
        type: 'number',
        label: col,
      };
    });

    rows = JSON.parse(JSON.stringify(history)).splice(1);
    if (isDate) {
      // I tried working with the 'formats' prop on the area chart.
      // It would fix the X axis dates but the hover over dates were off
      // so I'm just changing the utc offset manually for each row. - JS
      rows = rows.map(t => [addUtcOffset(new Date(t[0]))].concat(t.splice(1)));
      hAxis = {
        type: 'date',
        format: 'MMM d, y',
        maxAlternation: 1,
        slantedText: true,
      };
    } else {
      hAxis.title = history[0][0];
    }
  }

  const chartOptions = {
    displayZoomButtons: false,
    displayRangeSelector: false,
    hAxis,
    axes: { y: { all: { range: { min: 0 } } } },
    legend: { position: legendPosition },
    chartArea: {
      left: dualAxes ? 60 : 50,
      top: legendPosition === 'top' ? 50 : 20,
      width: legendPosition === 'right' || dualAxes ? '80%' : '90%',
      height: legendPosition === 'top' ? '70%' : '80%',
    },
    interpolateNulls: true,
    backgroundColor: {
      strokeWidth: 0,
      fill: 'transparent',
    },
    thickness: 3,
    pointSize: 5,
    series: {
      0: { color: title == 'Confirmed Plays' ? '#4B9EC1' : '#9BC53D' },
    },
  };

  if (dualAxes && chartColumns.length > 2) {
    chartOptions.axes = { y: { 0: {}, 1: { side: 'right' } } };
    let colors = ['#4B9EC1', '#9BC53D', '#FA7921', '#885A9A'];
    const series = {};
    for (let i = 1; i < chartColumns.length; i++) {
      series[i - 1] = {
        targetAxisIndex: Math.floor((2 * i) / chartColumns.length),
        color: colors[i - 1],
      };
    }
    chartOptions.series = series;
  }

  return (
    <div className="mb4">
      <div className="w-100 tl flex flex-wrap">
        {!noTitle && <TitleBar title={title || 'Downloads'} subtitle={subtitle} />}
        {/*
          While we move DatePickers to be page-wide, I need a way to
          remove them while leaving the aggregation options in -JS
        */}
        {!hideAggregationOptions && hideSelectors && (
          <div className="w-50-l w-100 pa2-s cf">
            <Select
              value={aggregationOptions[aggregation]}
              onChange={e => onGetHistory({ aggregation: e.value })}
              options={Object.values(aggregationOptions)}
            />
          </div>
        )}
        {!hideSelectors && (
          <div className="w-50-l w-100 pa2-s cf">
            <div className="fr-l fl w-50-ns w-100">
              <div className="fl w-100 w-50-ns">
                <UpsellDatePicker
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  isClearable={true}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Start date"
                  minDate={minStartDate ? moment(minStartDate).toDate() : null}
                  teamId={teamId}
                />
              </div>
              <div className="fl w-100 w-50-ns">
                <UpsellDatePicker
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  isClearable={true}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="End date"
                  minDate={minStartDate ? moment(minStartDate).toDate() : null}
                  teamId={teamId}
                />
              </div>
            </div>
            <div className="fr-l fl w-50-ns w-third-l w-100">
              <Select
                value={aggregationOptions[aggregation]}
                onChange={e => onGetHistory({ aggregation: e.value })}
                options={Object.values(aggregationOptions)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt2 bg-white br2">
        {history && (
          <Chart
            chartType="AreaChart"
            options={chartOptions}
            columns={chartColumns}
            rows={rows}
            width="100%"
            height={height || '400px'}
          />
        )}
        {!history && aggregation === 'hourly' && (
          <div className="pa4 f4 b gray h-100 center tc v-mid">
            <a href={trackableUrl} className="link gray">
              To see hourly stats, integrate with Trackable.
            </a>
          </div>
        )}
      </div>
    </div>
  );
}

ChartHistoryChart.propTypes = {
  noTitle: PropTypes.bool, // This is a big hack so I can use my own title. This whole component needs to be reworked in the semi near future. -JS
  title: PropTypes.string,
  subtitle: PropTypes.string,
  legendPosition: PropTypes.string,
  history: PropTypes.array,
  aggregation: PropTypes.string,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  handleChangeEndDate: PropTypes.func,
  handleChangeStartDate: PropTypes.func,
  onGetHistory: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  height: PropTypes.string,
  includeHourly: PropTypes.bool,
  trackableUrl: PropTypes.string,
  hideSelectors: PropTypes.bool,
  dualAxes: PropTypes.bool,
  minStartDate: PropTypes.instanceOf(Date),
  hideAggregationOptions: PropTypes.bool,
};

ChartHistoryChart.defaultProps = {
  title: null,
  legendPosition: 'none',
  history: [],
  aggregation: null,
  subtitle: null,
  height: '400px',
  includeHourly: false,
  trackableUrl: null,
  hideSelectors: false,
  onGetHistory: null,
  dualAxes: false,
  minStartDate: null,
  hideAggregationOptions: true,
  noTitle: false,
};
