import React from 'react';
import moment from 'moment';
import { Chart } from 'react-google-charts';
import { localeFormattedTime } from 'shared/helpers';

import Loading from 'shared/Loading';

const LINE_COLORS = ['#3366cc', '#f90', '#909', '#df5238', '#299618'];

class PacingChartView extends React.PureComponent {
  renderEpisodeRows() {
    const { episodes } = this.props;

    return episodes.map((episode, index) => {
      if (episode.title == null || episode.title === undefined) {
        episode.title = 'Untitled Episode';
      }
      const sevenDayDownloads = this.getSevenDayDownloads(episode.downloads);
      return (
        <div className="flex flex-row gray bb b--near-white pv2" key={index}>
          <div className="flex items-center flex-row" style={{ flexBasis: '60%' }}>
            <div
              className="br-100 mr2"
              style={{ backgroundColor: LINE_COLORS[index], height: '10px', width: '10px' }}
            ></div>
            {episode.title.substring(0, 80)}
            {episode.title.length > 80 ? '...' : ''}
          </div>
          <div style={{ flexBasis: '20%' }}>
            {moment(episode.publishedAt).format('MMM DD, YYYY')}
          </div>
          <div style={{ flexBasis: '20%' }}>
            {sevenDayDownloads ? sevenDayDownloads.toLocaleString() : ''}
          </div>
        </div>
      );
    });
  }

  getSevenDayDownloads(downloads) {
    return (
      downloads[7] ||
      downloads[6] ||
      downloads[5] ||
      downloads[4] ||
      downloads[3] ||
      downloads[2] ||
      downloads[1]
    );
  }

  formatData(data) {
    const titles = [data.flatMap(d => d.title)];
    const datapoints = [1, 2, 3, 4, 5, 6, 7].map(i =>
      [i].concat(data.map(d => (d.downloads[i] ? d.downloads[i] : null))),
    );
    titles[0].unshift('');
    return titles.concat(datapoints);
  }

  renderChart() {
    let data = this.formatData(this.props.episodes);

    // The following code removes from data podcasts that have all null values
    // This is because google charts expects the values to be numbers.
    // https://stackoverflow.com/questions/13216377/google-chart-api-error-all-series-on-a-given-axis-must-be-of-the-same-data-type
    // I've also tried explicitly telling the <Chart> that the column should be nulls, but that did not appear to work.
    [7, 6, 5, 4, 3, 2, 1].forEach(index => {
      let foundValue = false;
      data.forEach(row => {
        if (typeof row[index] === 'string') return;
        if (row[index] !== null) {
          foundValue = true;
        }
      });

      if (!foundValue) {
        data.forEach(row => row.splice(index, 1));
      }
    });

    const chartOptions = {
      displayZoomButtons: false,
      displayRangeSelector: false,
      legend: { position: 'none' },
      chartArea: {
        left: 75,
        top: 40,
        width: '90%',
        height: '80%',
      },
      interpolateNulls: true,
      backgroundColor: {
        strokeWidth: 0,
        fill: 'transparent',
      },
      hAxis: {
        title: 'Days since launch',
        format: '#',
        gridlines: {
          count: 7,
          color: '#ccc',
        },
        minorGridlines: {
          count: 0,
        },
      },
      vAxes: {
        ticks: [0, 1, 2, 3, 4, 5, 6],
        1: {
          title: 'Losses',
          textStyle: { color: 'red' },
        },
      },
      focusTarget: 'category',
      seriesthickness: 3,
      pointSize: 4,
      displayAnnotations: false,
      tooltip: { isHtml: true },
      series: {
        0: { color: LINE_COLORS[0], lineWidth: 5, pointSize: 8 },
        1: { color: LINE_COLORS[1] },
        2: { color: LINE_COLORS[2] },
        3: { color: LINE_COLORS[3] },
        4: { color: LINE_COLORS[4] },
      },
    };
    return (
      <div>
        <Chart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={data}
          options={chartOptions}
        />
      </div>
    );
  }

  render() {
    return (
      <div className="mb4">
        <div className="dib f3 dark-blue">Episode Pacing</div>
        <div className="dib f7 b gray ttu tracked pl2">
          <a
            data-tip="Last 5 episodes 7 day download pacing"
            className="ph1 ba b--gray br-100 tc pointer"
            currentitem="false"
          >
            ?
          </a>
        </div>
        {this.props.episodes ? (
          <div className="mt2 bg-white br2">
            {this.renderChart()}
            <div className="mt3 ph3 flex flex-column">
              <div className="flex flex-row bb b--black pb1">
                <div style={{ flexBasis: '60%' }} className="f6 b black ttu">
                  Title
                </div>
                <div style={{ flexBasis: '20%' }} className="f6 b black ttu">
                  Publish Date
                </div>
                <div style={{ flexBasis: '20%' }} className="f6 b black ttu">
                  7 Day Downloads
                </div>
              </div>
              {this.renderEpisodeRows()}
            </div>
          </div>
        ) : (
          <Loading className="mt2 w-100 tc center h-2" />
        )}
      </div>
    );
  }
}

export default PacingChartView;
