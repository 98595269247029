import React, { useEffect, useState } from 'react';
import user from 'images/icons/sidebar/user.svg';
import AnnounceKit from 'announcekit-react';
import whatsNew from 'images/icons/sidebar/whats_new.svg';

const UserSettings = ({ sidebar, settingsOpen, setSettingsOpen }) => {
  const {
    current_user,
    context,
    sign_out_path,
    switch_context_path,
    notification_count,
    notification_path,
    can_switch_context,
    switch_context_url,
  } = sidebar;
  const displayName = current_user.name || current_user.email;
  const itemCount =
    3 +
    [notification_count, can_switch_context].reduce((a, b) => {
      return (a += b ? 1 : 0);
    }, 0);
  const [unreadAnnouncements, setUnreadAnnouncements] = useState(0);
  const ref = React.createRef();

  useEffect(() => {
    ref.current.unread().then(setUnreadAnnouncements);
  }, [ref]);

  let totalNotifications = notification_count + unreadAnnouncements;
  totalNotifications = totalNotifications > 9 ? '9+' : totalNotifications;

  return (
    <div style={{ position: 'absolute', bottom: 10, width: '210px', padding: '0 17px' }}>
      <div
        className={`sidebar-user-menu-container`}
        style={{ background: '#1B5066', borderRadius: '4px', padding: '6px' }}
      >
        <div
          onClick={() => setSettingsOpen(!settingsOpen)}
          style={{
            background: '#073C52',
            cursor: 'pointer',
            color: '#ffffff',
            borderRadius: '4px',
            height: '100%',
            padding: '8px 0 4px 16px',
          }}
        >
          <img style={{ width: '24px' }} src={user} />
          <div
            style={{
              fontSize: '14px',
              display: 'inline-block',
              overflow: 'hidden',
              textOverflow: 'ellipses',
              width: !!totalNotifications ? '70px' : '105px',
              verticalAlign: 'top',
              position: 'relative',
              top: '4px',
              marginLeft: '10px',
              fontWeight: 600,
              opacity: 0.85,
            }}
          >
            {displayName}
          </div>
          {!!totalNotifications && (
            <div
              style={{
                background: '#FF564B',
                height: '8px',
                width: '8px',
                borderRadius: '4px',
                position: 'relative',
                float: 'right',
                top: '8px',
                marginRight: '16px',
              }}
            ></div>
          )}
        </div>
        <div
          className={`sidebar-user-menu`}
          style={{ overflow: 'hidden', height: settingsOpen ? `${itemCount * 49}px` : 0 }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              fontSize: '14px',
              height: '49px',
              color: 'white',
              marginBottom: '8px',
              padding: '16px 0 16px 16px',
            }}
          >
            <AnnounceKit
              ref={ref}
              widget="https://changelog.chartable.com/widgets/v2/1Xx49W"
              className=""
              data={{ is_sponsor: true }}
            >
              <img style={{ marginRight: '10px' }} src={whatsNew} />
              <span style={{ position: 'relative', top: '-7px' }}>What's New</span>
            </AnnounceKit>
          </div>
          <div>
            <a href="/settings" style={{ textDecoration: 'none' }}>
              <div
                style={{
                  fontSize: '14px',
                  color: '#FFFFFF',
                  height: '49px',
                  padding: '8px 0 8px 16px',
                }}
              >
                <span>Your Profile</span>
              </div>
            </a>
          </div>
          <div>
            {!!notification_count && (
              <a href={notification_path} style={{ textDecoration: 'none' }}>
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    height: '49px',
                    padding: '8px 0 8px 16px',
                  }}
                >
                  <span>Notifications</span>
                  <span style={{ marginRight: '16px' }} className={`fr`}>
                    {notification_count}
                  </span>
                </div>
              </a>
            )}
          </div>
          <div>
            {can_switch_context && (
              <a href={switch_context_path} style={{ textDecoration: 'none' }}>
                <div
                  style={{
                    fontSize: '14px',
                    color: '#FFFFFF',
                    height: '49px',
                    padding: '8px 0 8px 16px',
                  }}
                >
                  <span style={{}}>For {context === 'sponsor' ? 'Publishers' : 'Advertisers'}</span>
                </div>
              </a>
            )}
          </div>
          <div>
            <a href={sign_out_path} style={{ textDecoration: 'none' }}>
              <div
                style={{
                  fontSize: '14px',
                  color: '#FF685F',
                  marginBottom: '8px',
                  height: '49px',
                  padding: '8px 0 8px 16px',
                }}
              >
                <span style={{ fontWeight: 700 }}>Log Out</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
