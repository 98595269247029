import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

import Banner from 'shared/Banner';
import ConsumptionChart from 'shared/charts/ConsumptionChart';
import Loading from 'shared/Loading';
import { publicGetSalesSheet } from 'shared/api';
import { abbreviateNumberTwo } from 'shared/helpers';

import BarChart from './BarChart';
import IndexedValueChart from './IndexedValueChart';

const demographicsSegments = {
  householdIncome: 'householdIncome',
  education: 'education',
};

const errorTypes = {
  unknown: 'unknown',
  notFound: 'notFound',
};

function formatDemographicDataForBarChart(segment, data) {
  const formattedData = [];

  data.forEach(item =>
    formattedData.push(
      // household_income :(
      [
        item[segment === demographicsSegments.householdIncome ? 'householdIncome' : segment],
        item.reachPercentage,
        '#CDECFF',
        `${item.reachPercentage}%`,
      ],
    ),
  );

  formattedData.sort((a, b) => {
    if (segment === demographicsSegments.householdIncome) {
      try {
        // This is a super hacky way to grab the first number of the income range
        return parseInt(a[0].split(' - ')[0].split('$')[1]) <
          parseInt(b[0].split(' - ')[0].split('$')[1])
          ? 1
          : -1;
      } catch (e) {
        // Catch "Unknown" income
        return -1;
      }
    } else {
      if (a[1] == b[1]) return 0;
      return a[1] < b[1] ? 1 : -1;
    }
  });

  formattedData.unshift(['', '', { role: 'style' }, { role: 'tooltip' }]);

  return formattedData;
}

const SalesSheet = ({ id }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const handleGetSalesSheet = () => {
    if (loading) return;
    setLoading(true);

    publicGetSalesSheet({ id })
      .then(res => setData(res.data))
      .catch(err => {
        if (err.response && err.response.status && err.response.status === 404) {
          setError(errorTypes.notFound);
        } else {
          setError(errorTypes.unknown);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    handleGetSalesSheet();
  }, []);

  // I couldn't help myself and wanted the header color to match the index color.
  // This is unnecessary if you use the colorMap prop in IndexedValueChart. -JS
  const genderColor = useMemo(() => {
    if (!data) return 'white';
    const postiveGender = data.reportData.gender.find(
      item => item.reachPercentageAgainstBenchmark >= 0.0,
    );
    return postiveGender.gender === 'Female' ? 'red' : 'dark-blue';
  }, [JSON.stringify(data)]);

  if (error) {
    return (
      <Banner type="error" className="mv3">
        <div className="b f6">
          {error === errorTypes.notFound
            ? 'This sales sheet no longer exists!'
            : 'Failed to load sales sheet data'}
        </div>
        {error === errorTypes.unknown && (
          <div className="f6 mt1">Please reach out to support if this problem persists</div>
        )}
      </Banner>
    );
  }

  if (loading || !data) {
    return (
      <div className="tc w-100 mt4">
        <Loading />
      </div>
    );
  }

  // TODO - truncate description
  // Leaving this snippet here in until I can confirm that it works:
  //
  // let strippedDescription = document.createElement("textarea")
  // strippedDescription.innerHTML = description ? description.replaceAll(htmlStripRegEx, '') : '';
  // strippedDescription = strippedDescription.value;

  const { podcast, reportData } = data;

  return (
    <div className="pa3">
      <div className="flex flex-row w-100 h3 items-center mb5">
        <img
          className="br3"
          src={podcast.imageUrl}
          style={{
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            width: '6.5rem',
            height: '6.5rem',
          }}
        />
        <div className="ml3">
          <div className="f2 dark-gray mb1 force-inter">{podcast.title}</div>
          {/*
            <div className="mid-gray">
              {data.podcast.description}
            </div>
          */}
        </div>
      </div>

      <div className="flex flex-wrap mt4">
        <div className="w-100 flex items-start flex-wrap justify-between">
          <div className="w-50-l w-100 mb4 mb0-l">
            <div className="b mb1 f4 force-inter dark-blue">Downloads per episode</div>
            <div className="b near-black" style={{ fontSize: '4rem' }}>
              {!!reportData.averageEpisodeDownloads
                ? abbreviateNumberTwo(reportData.averageEpisodeDownloads)
                : 'Hidden'}
            </div>
          </div>
          {reportData.topCities.length > 0 && (
            <div className="w-50-l w-100">
              <div className="b mb1 f4 force-inter dark-blue">Top Cities</div>
              <div className="flex flex-wrap gap mw5">
                {reportData.topCities.map((item, index) => (
                  <div key={index} className="dark-gray f4 b">
                    {item}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="mt4 b f4 force-inter dark-blue mb2">Household Income</div>
        <BarChart
          isLoading={false}
          data={formatDemographicDataForBarChart(
            demographicsSegments.householdIncome,
            reportData.householdIncome,
          )}
          inlineStyle={{ height: '500px', width: '100%' }}
        />
        <div className="b f4 force-inter dark-blue mt4 mb2">Education</div>
        <BarChart
          isLoading={false}
          data={formatDemographicDataForBarChart(
            demographicsSegments.education,
            reportData.education,
          )}
          inlineStyle={{ height: '350px', width: '100%' }}
        />
        <div className="w-100 mt4">
          <div className={`b mb3 f4 force-inter ${genderColor}`}>Gender</div>
          <IndexedValueChart
            indexedData={reportData.gender}
            labelKey="gender"
            color={genderColor}
          />
          <div className="b mb3 f4 force-inter mt4 purple">Age</div>
          <IndexedValueChart indexedData={reportData.age} labelKey="age" color="purple" />
          {!!reportData.consumption && (
            <React.Fragment>
              <div className="mt4">
                <span className="b f4 force-inter dark-blue">Episode Consumption</span>
                <ReactTooltip />
                <span
                  className="f6 help blue dim ml1"
                  data-tip="Percentage of listeners still listening at a given point during an episode"
                >
                  what is this?
                </span>
              </div>
              <ConsumptionChart showTitle={false} consumptionSeries={reportData.consumption} />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default SalesSheet;
