/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { getTrackingLinkTeamDomains } from 'shared/api/v1';

const CREATE_CUSTOM_DOMAIN = 'createCustomDomain';
function LinkNameForm({
  name,
  customShortLink,
  canEditSlug,
  isEditing,
  errors,
  dispatch,
  teamId,
  history,
  podcast,
  customShortLinkDomainId,
  canUseSmartLinksAdvancedCustomization,
}) {
  const disabled = isEditing && !canEditSlug;
  const defaultDomain = {
    label: 'link.chtbl.com/',
    value: null,
    disabled: disabled,
  };

  const createDomain = {
    label: canUseSmartLinksAdvancedCustomization ? (
      'Create a Custom Domain'
    ) : (
      <div className="">
        Create a Custom Domain{' '}
        <div
          style={{
            fontSize: '8px',
            position: 'relative',
            top: '-2px',
            padding: '2px',
            borderRadius: '2px',
          }}
          className="ba b--moon-gray di"
        >
          PRO
        </div>
      </div>
    ),
    value: CREATE_CUSTOM_DOMAIN,
    disabled: !canUseSmartLinksAdvancedCustomization || disabled,
  };

  const [domain, setDomain] = useState(null);
  const [domains, setDomains] = useState([defaultDomain]);

  const formatDomains = customDomains => {
    return customDomains.map(cd => ({
      label: `${cd.name}/`,
      value: cd.id,
      disabled: disabled,
    }));
  };

  const fetchCustomDomains = async () => {
    const params = { teamId };
    const res = await getTrackingLinkTeamDomains(params);
    const { data: domains } = res;
    const activeDomains = domains.filter(d => d.status === 'Active');
    const activeOptions = formatDomains(activeDomains);
    const podcastDomain =
      activeOptions.map(d => d.value).includes(podcast.trackingLinkTeamDomainId) &&
      podcast.trackingLinkTeamDomainId;
    const customDomain = customShortLinkDomainId || podcastDomain;
    const selectedDomain = customDomain
      ? activeOptions.find(d => d.value === customDomain)
      : defaultDomain;
    dispatch({ type: 'setCustomShortLinkDomainId', payload: selectedDomain.value });
    setDomain(selectedDomain);
    setDomains([defaultDomain, ...activeOptions, createDomain]);
  };

  useEffect(() => {
    fetchCustomDomains();
  }, [podcast]);

  if (!domain) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="f5 mb2 mt4 dark-gray">SmartLink Name</div>
      <div className="f6 mid-gray mb2">For your records only, will not be shown to users</div>
      <input
        value={name}
        className="pa2 input-reset ba bg-white br2 b--silver w-100"
        type="text"
        placeholder="My SmartLink ... (Leave blank to autogenerate)"
        onChange={e => dispatch({ type: 'setName', payload: e.target.value })}
      />
      <div className="f5 mb2 mt4 dark-gray">
        Custom Short Link <span className="i">(optional)</span>
      </div>
      {isEditing && !canEditSlug && (
        <div style={{ color: '#6d5518' }} className="f6 mb1 b">
          This link already has clicks attributed to it, so it can&rsquo;t be changed.
        </div>
      )}
      {errors && errors.slug && <div className="f6 red b mb1">{`The slug ${errors.slug}.`}</div>}
      <div className="flex flex-row items-center">
        <Select
          defaultValue={domain}
          value={domain}
          disabled={disabled}
          className="dib v-mid w-40 fr bn sort-menu"
          onChange={selected => {
            if (disabled) {
              return;
            }
            if (selected.value === CREATE_CUSTOM_DOMAIN) {
              if (canUseSmartLinksAdvancedCustomization) {
                history.push(`/teams/${teamId}/dashboard/links/settings/custom_domains`);
              }
            }
            setDomain(selected);
            return dispatch({ type: 'setCustomShortLinkDomainId', payload: selected.value });
          }}
          options={domains}
          components={{ IndicatorSeparator: () => null }}
          styles={{
            control: provided => ({
              ...provided,
              border: 'none',
              background: 'transparent',
              outline: 'none',
            }),
            valueContainer: provided => ({
              ...provided,
              justifyContent: 'flex-end',
              padding: 'none',
            }),
            option: (provided, state) => {
              return {
                ...provided,
                textAlign: 'right',
                color: state.data.disabled ? '#aaa' : 'initial',
              };
            },
          }}
        />

        <input
          disabled={disabled}
          value={customShortLink}
          className={`pa1 ${
            errors && errors.slug ? 'input-error' : ''
          } input-reset ba br2 b--silver w-100 ${
            isEditing && !canEditSlug ? 'bg-light-gray gray o-70' : 'bg-white'
          }`}
          type="text"
          placeholder="iLovePodcasts ... (Leave blank to autogenerate)"
          onChange={e => dispatch({ type: 'setCustomShortLink', payload: e.target.value })}
        />
      </div>
    </React.Fragment>
  );
}

LinkNameForm.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customShortLink: PropTypes.string,
  canEditSlug: PropTypes.bool,
  isEditing: PropTypes.bool,
  name: PropTypes.string,
  errors: PropTypes.object,
  teamId: PropTypes.string,
  history: PropTypes.object,
  podcast: PropTypes.object,
  customShortLinkDomainId: PropTypes.string,
  canUseSmartLinksAdvancedCustomization: PropTypes.bool,
};

LinkNameForm.defaultProps = {
  canEditSlug: false,
  isEditing: false,
  errors: null,
  customShortLink: '',
  name: '',
};

export default LinkNameForm;
