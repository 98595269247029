"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.violaBold = exports.viola = exports.minuetBold = exports.minuet = exports.metronome = exports.mestoBold = exports.mesto = exports.forte = exports.finaleBold = exports.finale = exports.celloCanon = exports.cello = exports.canon = exports.brio = exports.bass = exports.balladBold = exports.ballad = exports.altoBrio = exports.alto = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
var _styledComponents = require("styled-components");
var responsiveVariant = function responsiveVariant(mobileToken, desktopToken) {
  var isSizeDifferent = mobileToken.fontSize !== desktopToken.fontSize;
  var isWeightDifferent = mobileToken.fontWeight !== desktopToken.fontWeight;
  var isTransformDifferent = mobileToken.textTransform !== desktopToken.textTransform;
  var isLetterSpacingDifferent = mobileToken.letterSpacing !== desktopToken.letterSpacing;
  var isFamilyDifferent = mobileToken.fontFamily !== desktopToken.fontFamily;
  var isDesktopDifferent = isSizeDifferent || isWeightDifferent || isFamilyDifferent || isTransformDifferent || isLetterSpacingDifferent;
  return (0, _styledComponents.css)(["font-size:", ";font-weight:", ";text-transform:", ";letter-spacing:", ";font-family:var(--font-family,", ");", ";"], mobileToken.fontSize, mobileToken.fontWeight, mobileToken.textTransform, mobileToken.letterSpacing, mobileToken.fontFamily, /* only apply desktop styles if they differ from mobile ones */
  isDesktopDifferent && (0, _styledComponents.css)(["@media (min-width:", "){font-size:", ";font-weight:", ";font-family:", ";text-transform:", ";letter-spacing:", ";}"], _encoreFoundation.breakpoint.screenSmMin, isSizeDifferent && desktopToken.fontSize, isWeightDifferent && desktopToken.fontWeight, isFamilyDifferent && "var(--font-family, ".concat(desktopToken.fontFamily, ")"), isTransformDifferent && desktopToken.textTransform, isLetterSpacingDifferent && desktopToken.letterSpacing));
};
var bass = function bass() {
  return responsiveVariant(_encoreFoundation.mobileBass, _encoreFoundation.desktopBass);
};
exports.bass = bass;
var forte = function forte() {
  return responsiveVariant(_encoreFoundation.mobileForte, _encoreFoundation.desktopForte);
};
exports.forte = forte;
var brio = function brio() {
  return responsiveVariant(_encoreFoundation.mobileBrio, _encoreFoundation.desktopBrio);
};
exports.brio = brio;
var alto = function alto() {
  return responsiveVariant(_encoreFoundation.mobileAlto, _encoreFoundation.desktopAlto);
};
exports.alto = alto;
var altoBrio = function altoBrio() {
  return responsiveVariant(_encoreFoundation.mobileAltoBrio, _encoreFoundation.desktopAltoBrio);
};
exports.altoBrio = altoBrio;
var canon = function canon() {
  return responsiveVariant(_encoreFoundation.mobileCanon, _encoreFoundation.desktopCanon);
};
exports.canon = canon;
var cello = function cello() {
  return responsiveVariant(_encoreFoundation.mobileCello, _encoreFoundation.desktopCello);
};
exports.cello = cello;
var celloCanon = function celloCanon() {
  return responsiveVariant(_encoreFoundation.mobileCelloCanon, _encoreFoundation.desktopCelloCanon);
};
exports.celloCanon = celloCanon;
var ballad = function ballad() {
  return responsiveVariant(_encoreFoundation.mobileBallad, _encoreFoundation.desktopBallad);
};
exports.ballad = ballad;
var balladBold = function balladBold() {
  return responsiveVariant(_encoreFoundation.mobileBalladBold, _encoreFoundation.desktopBalladBold);
};
exports.balladBold = balladBold;
var viola = function viola() {
  return responsiveVariant(_encoreFoundation.mobileViola, _encoreFoundation.desktopViola);
};
exports.viola = viola;
var violaBold = function violaBold() {
  return responsiveVariant(_encoreFoundation.mobileViolaBold, _encoreFoundation.desktopViolaBold);
};
exports.violaBold = violaBold;
var mesto = function mesto() {
  return responsiveVariant(_encoreFoundation.mobileMesto, _encoreFoundation.desktopMesto);
};
exports.mesto = mesto;
var mestoBold = function mestoBold() {
  return responsiveVariant(_encoreFoundation.mobileMestoBold, _encoreFoundation.desktopMestoBold);
};
exports.mestoBold = mestoBold;
var metronome = function metronome() {
  return responsiveVariant(_encoreFoundation.mobileMetronome, _encoreFoundation.desktopMetronome);
};
exports.metronome = metronome;
var finale = function finale() {
  return responsiveVariant(_encoreFoundation.mobileFinale, _encoreFoundation.desktopFinale);
};
exports.finale = finale;
var finaleBold = function finaleBold() {
  return responsiveVariant(_encoreFoundation.mobileFinaleBold, _encoreFoundation.desktopFinaleBold);
};
exports.finaleBold = finaleBold;
var minuet = function minuet() {
  return responsiveVariant(_encoreFoundation.mobileMinuet, _encoreFoundation.desktopMinuet);
};
exports.minuet = minuet;
var minuetBold = function minuetBold() {
  return responsiveVariant(_encoreFoundation.mobileMinuetBold, _encoreFoundation.desktopMinuetBold);
};
exports.minuetBold = minuetBold;