import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Sidebar from 'shared/Sidebar';
import Loading from 'shared/Loading';
import { getReviewsSummary, getReviews, getReviewsHistory } from 'shared/api';

import DashboardReviewsView from './components/DashboardReviewsView';

export default class DashboardReviews extends Component {
  constructor(props) {
    super(props);

    this.fetchReviewsSummary = this.fetchReviewsSummary.bind(this);
    this.fetchReviews = this.fetchReviews.bind(this);
    this.fetchReviewsHistory = this.fetchReviewsHistory.bind(this);
    this.handleChooseProvider = this.handleChooseProvider.bind(this);
    this.handleChooseCountry = this.handleChooseCountry.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleLoadPage = this.handleLoadPage.bind(this);

    this.state = {
      loading: true,
      reviewStats: null,
      reviews: null,
      reviewHistory: null,
      selectedProvider: null,
      selectedCountry: null,
      startDate:
        this.props.minStartDate &&
        new Date(this.props.minStartDate) > moment().subtract(3, 'months').toDate()
          ? moment().subtract(1, 'months').toDate()
          : moment().subtract(3, 'months').toDate(),
      endDate: new Date(),
      page: 1,
    };
  }

  componentDidMount() {
    this.fetchReviewsSummary(null, null, this.state.startDate, this.state.endDate);
  }

  fetchReviewsSummary(provider, country, startDate, endDate) {
    getReviewsSummary({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      startDate,
      endDate,
      provider,
      country,
    }).then(res => {
      this.setState({
        reviewStats: res.data,
      });
      this.fetchReviews(provider, country, startDate, endDate, 1);
      this.fetchReviewsHistory(provider, country, startDate, endDate);
    });
  }

  fetchReviewsHistory(provider, country, startDate, endDate) {
    getReviewsHistory({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      startDate,
      endDate,
      provider,
      country,
    }).then(res => {
      this.setState({
        reviewHistory: res.data,
      });
    });
  }

  fetchReviews(provider, country, startDate, endDate, page) {
    getReviews({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      startDate: startDate,
      endDate: endDate,
      page,
      provider,
      country,
    }).then(res => {
      this.setState({
        selectedCountry: country,
        selectedProvider: provider,
        page,
        startDate: startDate,
        endDate: endDate,
        reviews: res.data,
        loading: false,
        pageLoading: false,
      });
    });
  }

  handleChangeFilters({ selectedProvider, selectedCountry, startDate, endDate }) {
    const provider = selectedCountry !== undefined ? 'Apple Podcasts' : selectedProvider;
    const country = provider !== 'Apple Podcasts' ? null : selectedCountry;
    const selectedStartDate = startDate === undefined ? this.state.startDate : startDate;
    const selectedEndDate = endDate === undefined ? this.state.endDate : endDate;
    this.setState({ loading: true });
    this.fetchReviewsSummary(provider, country, selectedStartDate, selectedEndDate);
  }

  handleChooseProvider(value) {
    this.handleChangeFilters({ selectedProvider: value === '' ? null : value });
  }

  handleChooseCountry(value) {
    this.handleChangeFilters({ selectedCountry: value === '' ? null : value });
  }

  handleChangeStartDate(date) {
    this.handleChangeFilters({ startDate: date });
  }

  handleChangeEndDate(date) {
    this.handleChangeFilters({ endDate: date });
  }

  handleLoadPage(page) {
    if (this.state.pageLoading) {
      return;
    }

    this.setState({ pageLoading: true });
    this.fetchReviews(
      this.state.selectedProvider,
      this.state.selectedCountry,
      this.state.startDate,
      this.state.endDate,
      page,
    );
  }

  render() {
    const {
      loading,
      reviewStats,
      reviewHistory,
      reviews,
      selectedProvider,
      selectedCountry,
      page,
      startDate,
      endDate,
    } = this.state;

    const { teamId, minStartDate, podcastId, podcast, podcastsCount, sidebar } = this.props;

    return (
      <Sidebar sidebar={sidebar}>
        {loading ? (
          <Loading fullScreen />
        ) : (
          <DashboardReviewsView
            reviewHistory={reviewHistory}
            reviewStats={reviewStats}
            reviews={reviews}
            onChooseProvider={this.handleChooseProvider}
            onChooseCountry={this.handleChooseCountry}
            selectedProvider={selectedProvider}
            selectedCountry={selectedCountry}
            onLoadPage={this.handleLoadPage}
            startDate={startDate}
            endDate={endDate}
            handleChangeStartDate={this.handleChangeStartDate}
            handleChangeEndDate={this.handleChangeEndDate}
            page={page}
            pageSize={10}
            podcastId={podcastId}
            podcast={podcast}
            podcastsCount={podcastsCount}
            minStartDate={minStartDate}
            teamId={teamId}
          />
        )}
      </Sidebar>
    );
  }
}

DashboardReviews.propTypes = {
  podcastId: PropTypes.string,
  teamId: PropTypes.string.isRequired,
  minStartDate: PropTypes.instanceOf(Date),
};

DashboardReviews.defaultProps = {
  podcastId: null,
  minStartDate: null,
};
