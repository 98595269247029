/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import EditSVG from 'images/icons/pencil.svg';
import TrashSVG from 'images/icons/trash.svg';
import PropTypes from 'prop-types';
import { getTeamUsers, searchTeamUsers } from 'shared/api/v1';
import Header from 'shared/Header';
import Loading from 'shared/Loading';
import PaginatedTable from 'shared/PaginatedTable';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';
import 'react-table/react-table.css';
import SearchTeamUserForm from './SearchTeamUserForm';

const toastOptions = {
  duration: 5000,
};

const TeamUserManagerView = ({
  teamId,
  onShowEditTeamUser,
  hasRestrictedUsers,
  onDestroyTeamUser,
  hasDivisions,
}) => {
  const readOnlyMode = useReadOnlyMode();

  const [teamUsers, setTeamUsers] = useState(null);
  const [cachedUsers, setCachedUsers] = useState([]);
  const [cachedTotal, setCachedTotal] = useState(0);
  const [sortBy, setSortBy] = useState('email');
  const [page, setPage] = useState(1);
  const [sortDesc, setSortDesc] = useState(false);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const pageSize = 10;

  const handleLoadTeamUsers = async () => {
    setLoading(true);
    try {
      const res = await getTeamUsers({
        teamId,
        sortBy,
        page,
        sortDesc,
        pageSize,
      });
      setTeamUsers(res.data.data);
      setCachedUsers(res.data.data); // Default results when search box length is < 3.
      setCachedTotal(res.data.metadata.total);
      setTotal(res.data.metadata.total);
    } catch (e) {
      const message =
        e.response && e.response.data.error ? e.response.data.error : JSON.stringify(e);
      toast.error(message, toastOptions);
    }
    setLoading(false);
  };

  const handleExecuteTextSearch = async () => {
    setLoading(true);
    try {
      searchTeamUsers({ query: searchInput, includePermissions: true, page }).then(res => {
        setPage(page);
        setTotal(res.data.metadata.total);
        setTeamUsers(res.data.data);
        setLoading(false);
      });
    } catch (e) {
      const message =
        e.response && e.response.data.error ? e.response.data.error : JSON.stringify(e);
      toast.error(message, toastOptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    // handle search term update
    setPage(1);
    if (searchInput.length >= 3) {
      handleExecuteTextSearch().then();
    } else {
      // use cached users
      if (cachedTotal) {
        setTeamUsers(cachedUsers);
        setTotal(cachedTotal);
      } else {
        handleLoadTeamUsers().then();
      }
    }
  }, [sortBy, sortDesc, searchInput]);

  useEffect(() => {
    // handle page update
    if (searchInput.length >= 3) {
      handleExecuteTextSearch().then();
    } else {
      handleLoadTeamUsers().then();
    }
  }, [sortBy, page, sortDesc]);

  const teamUserTableColumns = [
    {
      Header: 'Email',
      accessor: 'email',
      className: 'flex items-center pv2',
    },
    {
      Header: 'Role',
      accessor: 'role',
      manualSortBy: true,
      className: 'flex items-center justify-center',
      Cell: row => row.original.role,
    },
  ];

  if (hasRestrictedUsers) {
    teamUserTableColumns.push({
      Header: 'Access',
      className: 'flex items-center',
      sortable: false,
      Cell: row => {
        const user = row.original;
        if (user.isRestrictedPodcastUser) {
          return user.allowedTeamPodcasts.length === 0
            ? 'No Podcasts'
            : user.allowedTeamPodcasts.join(', ');
        }

        return 'All Podcasts';
      },
    });
  }

  if (hasDivisions) {
    teamUserTableColumns.push({
      Header: 'Divisions',
      sortable: false,
      Cell: row => row.original.divisionRoles.map(item => `${item.name} (${item.role})`).join(', '),
    });
  }

  if (!readOnlyMode) {
    teamUserTableColumns.push({
      Header: 'Actions',
      sortable: false,
      Cell: row => (
        <div className="dt">
          {row.original.canEdit && (
            <a
              onClick={() => onShowEditTeamUser(row.original.id)}
              className="dtc v-mid link underline"
            >
              <img src={EditSVG} alt="Edit" width="25px" className="pointer v-mid" />
            </a>
          )}
          {row.original.canDestroy && (
            <a
              onClick={() => {
                // eslint-disable-next-line no-alert
                if (window.confirm('Are you sure you want to remove this user?'))
                  onDestroyTeamUser(row.original.id);
              }}
              className="dtc v-mid link underline"
            >
              <img src={TrashSVG} alt="Delete" width="25px" className="pointer v-mid" />
            </a>
          )}
        </div>
      ),
    });
  }

  if (!teamUsers) {
    return <Loading />;
  }

  return (
    <div>
      <Toaster />
      <Header>Manage Team</Header>
      <div className="mt2">
        <div className="f5">
          <SearchTeamUserForm setSearchInput={setSearchInput} />
          {teamUsers.length >= 0 && (
            <PaginatedTable
              defaultSorted={[
                {
                  id: sortBy,
                  desc: sortDesc,
                },
              ]}
              columns={teamUserTableColumns}
              data={teamUsers}
              handlePaging={event => setPage(event.selected + 1)}
              handleSort={sort => {
                setPage(1);
                setSortDesc(sort[0].desc);
                setSortBy(sort[0].id);
              }}
              manual
              loading={loading}
              total={total}
              pageSize={pageSize}
              page={page}
              rowStyle={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                minHeight: '2rem',
              }}
              tableClassName="w-100 f5 -highlight"
            />
          )}
        </div>
      </div>
    </div>
  );
};

TeamUserManagerView.propTypes = {
  teamId: PropTypes.string.isRequired,
  onShowEditTeamUser: PropTypes.func.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
  hasRestrictedUsers: PropTypes.bool.isRequired,
  onDestroyTeamUser: PropTypes.func.isRequired,
  hasDivisions: PropTypes.bool.isRequired,
};

export default TeamUserManagerView;
