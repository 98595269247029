import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import StatusIndicator from 'shared/StatusIndicator';
import EditSVG from 'images/icons/pencil.svg';
import TrashSVG from 'images/icons/trash.svg';
import 'react-table/react-table.css';

export default function TrackingLinkTeamPodcastPlatformUrlTable({
  trackingLinkTeamPodcastPlatformUrls,
  teamId,
  onDestroyPlatformUrl,
  onShowEditPlatformUrl,
}) {
  const columns = [
    {
      Header: 'Status',
      width: 140,
      className: 'tc ttc',
      Cell: row => {
        const platformUrl = row.original;
        return (
          <StatusIndicator
            forSmartLinksPlatformUrl
            status={platformUrl.pageValidated}
            text={platformUrl.pageValidated.toLowerCase()}
          />
        );
      },
    },
    {
      Header: 'Podcast',
      sortable: false,
      accessor: 'name',
      className: 'tc ttc',
    },
    {
      Header: 'Platform',
      sortable: false,
      accessor: 'platform',
      className: 'tc ttc',
    },
    {
      Header: 'Custom URL',
      sortable: false,
      accessor: 'url',
      width: 300,
    },
    {
      Header: '',
      sortable: false,
      className: 'tc ttc',
      width: 100,
      Cell: row => (
        <div>
          {row.original.canEdit && (
            <a
              onClick={() => onShowEditPlatformUrl(row.original.id)}
              className="dtc v-mid link underline"
            >
              <img src={EditSVG} width="25px" className="pointer" />
            </a>
          )}
          {row.original.canDestroy && (
            <a
              onClick={() => {
                if (window.confirm('Are you sure you want to remove this custom URL?'))
                  onDestroyPlatformUrl(row.original.id);
              }}
              className="dtc v-mid link underline"
            >
              <img src={TrashSVG} width="25px" className="pointer" />
            </a>
          )}
        </div>
      ),
    },
  ];

  return (
    <ReactTable
      getTrProps={(state, row) => ({
        style: { display: 'flex', alignItems: 'center' },
      })}
      data={trackingLinkTeamPodcastPlatformUrls}
      pageSize={trackingLinkTeamPodcastPlatformUrls.length}
      columns={columns}
      showPagination={false}
      filterable={false}
      className="-highlight w-100"
    />
  );
}

TrackingLinkTeamPodcastPlatformUrlTable.propTypes = {
  trackingLinkTeamPodcastPlatformUrls: PropTypes.array.isRequired,
  onShowEditPlatformUrl: PropTypes.func.isRequired,
  onDestroyPlatformUrl: PropTypes.func.isRequired,
};
