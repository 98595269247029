import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import qs from 'qs';

import Loading from 'shared/Loading';

import withNotifications from 'shared/NotificationManager';
import DivisionManagerView from './components/DivisionManagerView';
import NewDivisionForm from './components/NewDivisionForm';
import EditDivisionForm from './components/EditDivisionForm';
import Sidebar from 'shared/Sidebar';

class DivisionManager extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { teamSlug, teamId, baseRoute, onCreateNotification, sidebar, subSidebar } = this.props;

    return (
      <Sidebar sidebar={sidebar} subSidebar={subSidebar}>
        <Router>
          <Switch>
            <Route
              exact
              path={baseRoute}
              render={props => (
                <div>
                  <DivisionManagerView
                    teamId={teamId}
                    teamSlug={teamSlug}
                    onShowAddDivision={() => props.history.push(`${baseRoute}/new`)}
                    onShowEditDivision={divisionId =>
                      props.history.push(`${baseRoute}/${divisionId}/edit`)
                    }
                    onCreateNotification={onCreateNotification}
                  />
                </div>
              )}
            />
            <Route
              exact
              path={`${baseRoute}/new`}
              render={props => (
                <div>
                  <NewDivisionForm
                    teamSlug={teamSlug}
                    onCloseForm={() => props.history.push(`${baseRoute}`)}
                    onCreateNotification={onCreateNotification}
                  />
                </div>
              )}
            />
            <Route
              exact
              path={`${baseRoute}/:divisionId/edit`}
              render={props => (
                <div>
                  <EditDivisionForm
                    teamSlug={teamSlug}
                    divisionId={props.match.params.divisionId}
                    onCloseForm={() => props.history.push(`${baseRoute}`)}
                    onCreateNotification={onCreateNotification}
                  />
                </div>
              )}
            />
          </Switch>
        </Router>
      </Sidebar>
    );
  }
}

DivisionManager.propTypes = {
  teamSlug: PropTypes.string.isRequired,
  teamId: PropTypes.string.isRequired,
  baseRoute: PropTypes.string.isRequired,
  onCreateNotification: PropTypes.func.isRequired,
};

const DivisionManagerWithNotfications = withNotifications(DivisionManager);
export default DivisionManagerWithNotfications;
