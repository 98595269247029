import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import ReactChartkick, { LineChart } from 'react-chartkick';
import Chart from 'chart.js';
import Select from 'react-select';
import UpsellDatePicker from 'shared/UpsellDatePicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../datepicker.css';
import PodcastSwitcher from 'shared/PodcastSwitcher';

ReactChartkick.addAdapter(Chart);

import StarRatings from 'shared/StarRatings';

export default function DashboardReviewsView({
  reviewStats,
  reviews,
  reviewHistory,
  onChooseProvider,
  selectedProvider,
  onLoadPage,
  onChooseCountry,
  selectedCountry,
  page,
  pageSize,
  startDate,
  endDate,
  podcastId,
  podcast,
  podcastsCount,
  handleChangeEndDate,
  handleChangeStartDate,
  minStartDate,
  teamId,
}) {
  const urlBase = `/teams/${teamId}/dashboard/reviews`;
  const pageStyle = 'dib mr2 b--gray br2 ba bg-white';
  const linkStyle = 'dib pv2 ph3 pointer';
  const pageCount = Math.ceil(reviewStats.totalFilteredReviews / pageSize);

  const setPodcast = ({ type, data }) => {
    if (type === 'all') {
      window.location = urlBase;
      return;
    }
    window.location = `${urlBase}?podcast_id=${data.id}`;
  };

  const manyPodcasts = podcastsCount > 1;

  const podcastSwitcher = () => {
    if (!manyPodcasts) {
      return;
    }
    return (
      <PodcastSwitcher
        teamId={teamId}
        onSelect={setPodcast}
        loading={false}
        selectedPodcast={podcast || null}
        defaultToNone={false}
        defaultToAll={false}
        includeNone={false}
        includeAll={true}
        numberOfDefaultOptionsToFetch={podcastsCount}
      />
    );
  };

  if (reviewStats.totalReviews === 0) {
    return (
      <div className="pa3">
        {podcastSwitcher()}
        <div className="f3 dark-blue mb3">Reviews</div>
        <div className="mw8 pa3 bg-white br2 mb3 tc f4">No Reviews, Yet</div>
      </div>
    );
  }

  const providerOptions = [
    { value: '', label: 'All Providers' },
    ...reviewStats.uniqueProviders.map(provider => ({ value: provider, label: provider })),
  ];
  const countryOptions = [
    { value: '', label: 'All Countries' },
    ...reviewStats.uniqueCountries.map(country => ({ value: country, label: country })),
  ];

  return (
    <div>
      <div className="mw8 pa3">
        {podcastSwitcher()}
        <div className="f3 dark-blue mb3 w-100">Reviews at a glance</div>
        <div className="flex flex-wrap mt2 mb4 bg-almost-white br2 pa3">
          <div className="w-third-ns w-100 tl-ns">
            <div className="f3-ns f4 b dark-blue tl-ns tc">
              {reviewStats.totalFilteredReviews.toLocaleString()}
            </div>
            <div className="f6 gray ttu tracked tl-ns tc">reviews</div>
          </div>
          <div className="w-third-ns w-100 tl-ns tc">
            <div className="f3-ns f4 b dark-blue tc">{reviewStats.monthlyGrowth * 100}%</div>
            <div className="f6 gray ttu tracked tc">monthly growth</div>
          </div>
          <div className="w-third-ns w-100 center">
            <StarRatings rating={reviewStats.rating} className="tr-ns tc" />
            <div className="f6 gray ttu tracked mt1 tc tr-ns">
              {reviewStats.numRatings.toLocaleString()} ratings
            </div>
          </div>
        </div>
        <div className="mb2 cf">
          <div className="dark-blue w-100 cf">
            <div className="f3 fl">Trends</div>
            <div className="fr-l fl w-third-ns w-25-l w-100">
              <Select
                value={
                  selectedProvider
                    ? { value: selectedProvider, label: selectedProvider }
                    : { value: '', label: 'All Providers' }
                }
                onChange={e => onChooseProvider(e.value)}
                options={providerOptions}
              />
            </div>
            <div className="fr-l fl w-third-ns w-25-l w-100">
              <Select
                value={
                  selectedCountry
                    ? { value: selectedCountry, label: selectedCountry }
                    : { value: '', label: 'All Countries' }
                }
                onChange={e => onChooseCountry(e.value)}
                options={countryOptions}
              />
            </div>
            <div className="fr-l fl w-third-ns w-25-l w-100">
              <div className="fl w-100 w-50-ns">
                <UpsellDatePicker
                  selected={startDate}
                  onChange={handleChangeStartDate}
                  isClearable={true}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minStartDate ? moment(minStartDate).toDate() : null}
                  placeholderText="Start date"
                  teamId={teamId}
                />
              </div>
              <div className="fl w-100 w-50-ns">
                <UpsellDatePicker
                  selected={endDate}
                  onChange={handleChangeEndDate}
                  isClearable={true}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={minStartDate ? moment(minStartDate).toDate() : null}
                  placeholderText="End date"
                  teamId={teamId}
                />
              </div>
            </div>
          </div>
        </div>
        {reviewHistory && (
          <div className="bg-white br2 mt2 mb4 pa2">
            <LineChart
              data={reviewHistory}
              colors={['#4B9EC1']}
              label="Reviews Count"
              min={0}
              height="200px"
            />
          </div>
        )}
        {reviews.length > 0 && <div className="f3 mb3 dark-blue">Reviews</div>}
        {reviews.map(review => (
          <div key={review.postedAt} className="mb4 br2 pa3 bg-almost-white">
            {podcastId ? (
              <div className="f4 body-font dark-blue">
                {review.title}
                {review.deleted && (
                  <span className="fr f6 dib bg-red white pa1 ml2 br2 no-underline">Deleted</span>
                )}
              </div>
            ) : (
              <div>
                <div className="f4 body-font dark-blue w-50-ns w-100 fl-ns">
                  {review.title}
                  {review.deleted && (
                    <span className="fr f6 dib bg-red white pa1 ml2 br2 no-underline">Deleted</span>
                  )}
                </div>
                <div className="f5 body-font w-50-ns w-100 fr-ns tr">
                  {review.podcastEmailTitle}
                </div>
              </div>
            )}
            <StarRatings rating={review.rating} />
            <div className="mt1">{review.review}</div>
            <div className="f6 silver mt1">
              {review.authorName} via {review.provider} &middot;
              {review.country && <span>{review.country} &middot;</span>}
              {moment(review.postedAt).format('MM/DD/YYYY')}
            </div>
          </div>
        ))}
        <ReactPaginate
          className={pageCount === 1 && 'dn'}
          previousLabel="&laquo;"
          nextLabel="&raquo;"
          breakClassName="dib mr2"
          pageClassName={pageStyle}
          previousClassName={page > 1 ? pageStyle : 'dn'}
          nextClassName={page < pageCount ? pageStyle : 'dn'}
          pageLinkClassName={linkStyle}
          previousLinkClassName={linkStyle}
          nextLinkClassName={linkStyle}
          activeClassName="bg-blue white"
          disabledClassName="dn"
          containerClassName={classNames(pageCount === 1 && 'dn', 'pa2 list pl0 w-100 center tc')}
          onPageChange={data => {
            onLoadPage(data.selected + 1);
          }}
          initialPage={page - 1}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
        />
      </div>
    </div>
  );
}

DashboardReviewsView.propTypes = {
  reviewStats: PropTypes.object.isRequired,
  reviews: PropTypes.array,
  reviewHistory: PropTypes.array,
  onChooseProvider: PropTypes.func.isRequired,
  onChooseCountry: PropTypes.func.isRequired,
  selectedProvider: PropTypes.string,
  selectedCountry: PropTypes.string,
  onLoadPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  podcastId: PropTypes.string,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  handleChangeEndDate: PropTypes.func.isRequired,
  handleChangeStartDate: PropTypes.func.isRequired,
  resetDatePicker: PropTypes.func.isRequired,
  minStartDate: PropTypes.instanceOf(Date),
  teamId: PropTypes.string.isRequired,
};

DashboardReviewsView.defaultProps = {
  reviews: [],
  reviewHistory: null,
  selectedProvider: '',
  selectedCountry: '',
  podcastId: null,
  minStartDate: null,
};
