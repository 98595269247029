import React from 'react';
import MFAInput from 'shared/MFAInput';
import PropTypes from 'prop-types';

const MFAForm = ({ handleChange, errors, handleSubmission, setMFA }) => {
  return (
    <form onSubmit={handleSubmission} onChange={handleChange}>
      <div className={`mt1`}>
        <div className={`form-group mfa required`}>
          <label className={`db lh-copy f5 header-font`} htmlFor="session_mfa">
            Two-factor authentication code
          </label>
          <MFAInput setMFA={setMFA} />
        </div>
      </div>
      {errors()}
      <div className={`mt3 f6`}>
        <input
          name="commit"
          type="submit"
          value="Submit"
          className={`ph3 pv2 input-reset bn white bg-blue pointer f5 dib dim w-100 br2 header-font`}
        />
      </div>
    </form>
  );
};

MFAForm.propTypes = {
  handleSubmission: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  errors: PropTypes.func.isRequired,
  setMFA: PropTypes.func.isRequired,
};

export default MFAForm;
