import React, { useState, useEffect } from 'react';

const SidebarTab = ({ tab }) => {
  const baseStyle = {
    borderRadius: '4px',
    padding: '7px 10px',
    color: 'white',
    fontSize: '14px',
    height: '30px',
    fontWeight: 400,
  };
  const selectedStyle = {
    ...baseStyle,
    background: '#30657B',
    fontWeight: 700,
  };
  const baseTitleStyle = {
    top: '-3px',
    position: 'relative',
    opacity: 0.8,
  };

  const selectedTitleStyle = {
    ...baseTitleStyle,
    opacity: 1,
  };

  const selected = tab.active;
  const showChildren = !!tab.children && (tab.active || tab.children.find(tab => tab.active));
  return (
    <div>
      <div style={{ marginBottom: '8px' }}>
        <div className={`sidebar-tab`} style={selected ? selectedStyle : baseStyle}>
          <div style={{ height: '18px' }}>
            {!!tab.icon && (
              <img
                style={{ width: '16px', marginRight: '10px' }}
                src={require(`images/icons/sidebar/${tab.icon}`)}
              />
            )}
            {!tab.icon && <div className={`dib`} style={{ marginRight: '20px' }}></div>}
            <span style={selected ? selectedTitleStyle : baseTitleStyle}>{tab.title}</span>
          </div>
        </div>
      </div>
      {showChildren && (
        <div style={{ padding: '0 8px 0 8px' }}>
          {tab.children.map(tab => {
            return (
              <a key={tab.title} style={{ textDecoration: 'none' }} href={tab.url}>
                <SidebarTab tab={tab} />
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SidebarTab;
