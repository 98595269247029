/* eslint-disable react/display-name */
import React from 'react';

const UserContext = React.createContext(false);

// UserInfo hash is defined in `team_dashboard_controller.rb > def set_user_info`

const withUser = Component => props => {
  return (
    <UserContext.Consumer>
      {userInfo =>
        userInfo ? (
          <Component
            {...userInfo}
            userInfo={userInfo}
            userIsSuperAdmin={userInfo.isAdmin}
            {...props}
          />
        ) : (
          <Component {...props} />
        )
      }
    </UserContext.Consumer>
  );
};

export { withUser };

export default UserContext;
