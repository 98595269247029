import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';

import UserContext from 'shared/UserContext';
import Loading from 'shared/Loading';
import BannerNotice from 'shared/BannerNotice';
import Sidebar from 'shared/Sidebar';
import moment from 'moment';
import { ReadOnlyModeContext } from 'shared/ReadOnlyModeContext';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

import ReportsTable from './components/ReportsTable';
import ReportsHeader from './components/ReportsHeader';
import ReportDetailModal from './components/ReportDetailModal';
import PublisherReportCreator from './components/PublisherReportCreator';
import { createReport, getReports, downloadReport, deleteReport, updateReport } from 'shared/api';
import { SelectedFilters } from '@appbaseio/reactivesearch';

const publisherReportTypes = [
  'SMARTPROMOS_SNAPSHOT_REPORT',
  'SMARTPROMOS_TIMESERIES_REPORT',
  'SMARTLINKS_REPORT',
  'SMARTLINKS_OVERVIEW_REPORT',
];

const statuses = [
  {
    value: 'PROCESSING',
    label: 'Processing',
  },
  {
    value: 'COMPLETE',
    label: 'Complete',
  },
  {
    value: 'ARCHIVED',
    label: 'Archived',
  },
  {
    value: 'FAILED',
    label: 'Failed',
  },
];

const ALL = { value: 'ALL', label: 'All' };

const Reports = props => {
  const readOnlyMode = useReadOnlyMode();

  const { history, teamId, userInfo } = props;
  const [reports, setReports] = useState(null);
  const [selectedReport, setSelectedReport] = useState(null);
  const [notification, setNotification] = useState(null);
  const [downloadingReports, setDownloadingReports] = useState(new Set([]));
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportsTotal, setReportTotal] = useState(15);
  const [pagination, setPagination] = useState({
    sortBy: 'createdAt',
    sortDesc: true,
    page: 1,
    pageSize: 15,
  });
  const [filters, setFilters] = useState({
    reportTypes: false,
    status: ALL,
    dateRange: { endDate: moment(), startDate: moment().subtract(6, 'months') },
  });

  let reportTypes;

  reportTypes = publisherReportTypes;

  const [activeReportTypes, setActiveReportTypes] = useState(reportTypes);

  const handleGetReports = async () => {
    setLoading(true);
    const payload = {
      teamId,
      ...pagination,
      reportTypes: filters.reportTypes,
      status: filters.status,
      startDate: filters.dateRange.startDate,
      endDate: filters.dateRange.endDate,
    };
    if (!payload.reportTypes) {
      payload.reportTypes = activeReportTypes;
    }
    if (payload.status.value === 'ALL') {
      payload.status = null;
    }
    try {
      const reports = await getReports(payload);
      setReports(reports.data.reports);
      setReportTotal(reports.data.total);
    } catch (e) {
      setNotification(
        <BannerNotice
          error
          message="Failed to fetch reports"
          onClose={() => setNotification(null)}
        />,
      );
    } finally {
      setLoading(false);
    }
  };

  const handleCreateReport = async params => {
    if (requestInProgress) return;
    setRequestInProgress(true);
    try {
      const res = await createReport({ ...params, teamId });
      setReports(r => {
        return [res.data, ...r];
      });
      setNotification(
        <BannerNotice
          success
          message={`Your report is being generated. ${
            res.data.sendEmailOnComplete || false
              ? 'We will send a the report to your email when it completes'
              : 'You can download the report from this page when it completes.'
          }`}
          onClose={() => setNotification(null)}
        />,
      );
      history.push(`/teams/${teamId}/dashboard/reports`);
      window.scrollTo(0, 0);
    } catch (e) {
      setNotification(
        <BannerNotice
          error
          message={
            e.response && e.response.status == '409'
              ? 'Your team already has a report of this type running.'
              : 'Failed to create report.'
          }
          onClose={() => setNotification(null)}
        />,
      );
      window.scrollTo(0, 0);
    } finally {
      setRequestInProgress(false);
    }
  };

  const handleUpdateTypes = shownReportTypes => {
    handleGetReports(shownReportTypes);
    setActiveReportTypes(shownReportTypes);
  };

  const handleArchiveReport = async report => {
    if (requestInProgress) return;
    setRequestInProgress(true);
    const reportId = report.id;
    const prevStatus = report.status;
    try {
      const res = await updateReport({ teamId, reportId });
      handleGetReports();
    } catch (e) {
      setNotification(
        <BannerNotice
          error
          message="Failed to archive report."
          onClose={() => setNotification(null)}
        />,
      );
    } finally {
      setRequestInProgress(false);
      setSelectedReport(null);
    }
  };

  const handleDeleteReport = async report => {
    if (requestInProgress) return;
    setRequestInProgress(true);
    const reportId = report.id;
    try {
      const res = await deleteReport({ teamId, reportId });
      setReports(r => {
        return r.filter(rpt => rpt.id !== reportId);
      });
    } catch (e) {
      setNotification(
        <BannerNotice
          error
          message="Failed to delete report."
          onClose={() => setNotification(null)}
        />,
      );
    } finally {
      setRequestInProgress(false);
      setSelectedReport(null);
    }
  };

  const handleDownloadReport = async report => {
    const reportId = report.id;
    if (downloadingReports.has(reportId)) return;
    setDownloadingReports(r => {
      const setter = new Set(r);
      return setter.add(reportId);
    });
    try {
      const report = await downloadReport({ teamId, reportId });
      window.open(report.data.downloadUrl);
    } catch (e) {
      alert(e);
    } finally {
      setDownloadingReports(r => {
        const setter = new Set(r);
        setter.delete(reportId);
        return setter;
      });
    }
  };

  const requestFetch = data => {
    setFilters(data);
    setPagination(p => ({
      ...p,
      page: 1,
    }));
  };

  useEffect(() => {
    handleGetReports();
  }, [
    pagination.page,
    pagination.sortBy,
    pagination.sortDesc,
    filters.reportTypes,
    filters.status,
    filters.dateRange,
  ]);

  if (!reports) {
    return <Loading fullScreen />;
  }

  const state = {
    reports,
    selectedReport,
    notification,
    downloadingReports,
    requestInProgress,
    setPagination,
    loading,
    reportsTotal,
    handleUpdateTypes,
    requestFetch,
  };

  return (
    <UserContext.Provider value={userInfo}>
      {notification}
      <div className="pv3 ph4">
        <Switch>
          {!readOnlyMode && (
            <Route
              path="/teams/:teamId/dashboard/reports/create"
              render={renderProps => (
                <div>
                  <ReportsHeader className="mb3 mt2" {...renderProps} />
                  <PublisherReportCreator {...props} {...state} onConfirm={handleCreateReport} />
                </div>
              )}
            />
          )}
          <Route
            path="/teams/:teamId/dashboard/reports"
            render={renderProps => (
              <div>
                <ReportsHeader className="mb3 mt2" {...renderProps} />
                <ReportsTable
                  {...pagination}
                  {...props}
                  {...state}
                  handleDownloadReport={handleDownloadReport}
                  showDetails={setSelectedReport}
                />
              </div>
            )}
          />
        </Switch>
      </div>
      {selectedReport && (
        <ReportDetailModal
          report={selectedReport}
          handleArchiveReport={handleArchiveReport}
          handleDeleteReport={handleDeleteReport}
          onClose={() => setSelectedReport(null)}
        />
      )}
    </UserContext.Provider>
  );
};

const WrappedReports = withRouter(Reports);

const ReportsRouter = props => (
  <ReadOnlyModeContext.Provider value={props.readOnlyMode}>
    <Sidebar readOnlyMode={props.readOnlyMode} sidebar={props.sidebar}>
      <Router>
        <WrappedReports {...props} />
      </Router>
    </Sidebar>
  </ReadOnlyModeContext.Provider>
);

export default ReportsRouter;
