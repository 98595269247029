import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getTrackingLinks, updateTrackingLink } from 'shared/api/v1';
import BackBar from 'shared/BackBar';
import BannerNotice from 'shared/BannerNotice';
import Button from 'shared/Button';
import Header from 'shared/Header';
import Loading from 'shared/Loading';
import PaginatedTable from 'shared/PaginatedTable';
import TableErrorView from 'shared/TableErrorView';
import { useReadOnlyMode } from 'shared/hooks/useReadOnlyMode';

function ArchivedLinkTable({
  trackingLinks,
  page,
  total,
  loading,
  handlePaging,
  handleUnarchiveTrackingLink,
}) {
  const readOnlyMode = useReadOnlyMode();

  const columns = [
    {
      id: 'name',
      Header: 'SmartLink',
      accessor: 'name',
      minWidth: 200,
      sortable: false,
      Cell: row => (
        <div className="flex flex-row items-center">
          <img src={row.original.podcast.displayImageUrl} className="br2 w-auto h2 mr2" alt="" />
          <div>{row.original.name}</div>
        </div>
      ),
    },
    {
      id: 'created_at',
      Header: 'Published',
      accessor: 'createdAt',
      minWidth: 100,
      sortable: false,
      className: 'flex items-center justify-center',
      Cell: row => <div>{moment(row.original.createdAt).format('MM/DD/YYYY')}</div>,
    },
    {
      Header: '',
      className: 'flex items-center justify-center',
      maxWidth: 100,
      Cell: row => (
        <Button
          className="f6 mw4"
          size="small"
          type="primary"
          disabled={readOnlyMode}
          onClick={() => handleUnarchiveTrackingLink(row.original.id)}
        >
          Unarchive
        </Button>
      ),
    },
  ];

  return (
    <div className="w-100 relative">
      <ReactTooltip />
      <PaginatedTable
        defaultSorted={[
          {
            id: 'created_at',
            desc: true,
          },
        ]}
        columns={columns}
        data={trackingLinks}
        handlePaging={event => handlePaging(event.selected + 1)}
        loading={loading}
        total={total}
        page={page}
        pageSize={trackingLinks.length}
        rowStyle={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          padding: '0.5rem 0',
        }}
        getTrProps={() => ({
          style: {
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
          },
        })}
        tableClassName="-highlight"
      />
    </div>
  );
}

ArchivedLinkTable.propTypes = {
  trackingLinks: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  handlePaging: PropTypes.func.isRequired,
  handleUnarchiveTrackingLink: PropTypes.func.isRequired,
};

function ArchivedLinkView({ onUnarchiveTrackingLink }) {
  const pageSize = 20;
  const { teamId } = useParams();

  const [page, setPage] = useState(1);
  const [trackingLinks, setTrackingLinks] = useState(null);
  const [totalTrackingLinks, setTotalTrackingLinks] = useState(0);
  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState(null);

  async function handleUnarchiveTrackingLink(trackingLinkId) {
    setLoading(true);
    try {
      const params = { id: trackingLinkId, archived: false };
      await updateTrackingLink(params);
      onUnarchiveTrackingLink();
      setBanner(
        <BannerNotice
          success
          message="Unarchived SmartLink! It may take a while to repopulate your data."
          onClick={() => setBanner(null)}
        />,
      );
      handleLoadTrackingLinks();
    } catch (e) {
      window.scrollTo(0, 0);
      const errorBanner =
        e.response.status === 401 && !!e.response.data.message ? (
          <BannerNotice warning message="You have used all of your SmartLinks for this month" />
        ) : (
          <BannerNotice
            error
            message="Failed to unarchive SmartLink"
            onClick={() => setBanner(null)}
          />
        );
      setBanner(errorBanner);
      setLoading(false);
    }
  }

  async function handleLoadTrackingLinks() {
    try {
      const params = {
        teamId,
        page,
        pageSize,
        archived: true,
        sortBy: 'created_at',
        sortDesc: true,
        podcastId: null,
        episodeId: null,
      };
      const response = await getTrackingLinks(params);
      setTrackingLinks(response.data.data);
      setTotalTrackingLinks(response.data.metadata.total);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    handleLoadTrackingLinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  if (!trackingLinks) {
    return <Loading fullScreen />;
  }

  return (
    <div>
      <BackBar buttonText="Overview" backUrl={`/teams/${teamId}/dashboard/links`} />
      {banner}
      <div className="pa3">
        <Header useNew className="mb2">
          Archived SmartLinks
        </Header>
        {trackingLinks.length > 0 ? (
          <ArchivedLinkTable
            trackingLinks={trackingLinks}
            handlePaging={newPage => setPage(newPage)}
            page={page}
            total={totalTrackingLinks}
            loading={loading}
            handleUnarchiveTrackingLink={handleUnarchiveTrackingLink}
          />
        ) : (
          <TableErrorView header="No archived links!" />
        )}
      </div>
    </div>
  );
}

ArchivedLinkView.propTypes = {
  onUnarchiveTrackingLink: PropTypes.func.isRequired,
};

export default ArchivedLinkView;
