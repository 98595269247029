import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DownSVG from 'images/icons/caret-down-blue.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getAudioRollupReach, getPromo } from 'shared/api';
import BannerNotice from 'shared/BannerNotice';
import BasicModal from 'shared/BasicModal';
import Button from 'shared/Button';
import DownloadHistoryChart from 'shared/charts/DownloadHistoryChart';
import Loading from 'shared/Loading';
import NewStatusIndicator from 'shared/NewStatusIndicator';
import { withUser } from 'shared/UserContext';

import AudioAdPlacementStatusExplanation from './AudioAdPlacementStatusExplanation';
import PixelManager from './PixelManager';
import RollupTables from './RollupTables';
import StatCardHeader from './StatCardHeader';

function AudioAdPlacementDetailView({ history, match, isSeller, placement, teamId }) {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const [audioAdPlacement, setAudioAdPlacement] = useState(null);
  const [failedToLoadAudioAdPlacement, setFailedToLoadAudioAdPlacement] = useState(false);
  const [pixelManagerIsOpen, setPixelManagerIsOpen] = useState(urlParams.get('new'));
  const [showDetails, setShowDetails] = useState(false);
  const [reach, setReach] = useState(null);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    handleGetAudioAdPlacement();
  }, []);

  useEffect(() => {
    if (audioAdPlacement) {
      handleGetAudioRollupReach();
    }
  }, [audioAdPlacement]);

  function handleGetAudioAdPlacement() {
    getPromo({
      promoId: placement.audioAdPlacementId,
      teamId,
    })
      .then(res => setAudioAdPlacement(res.data))
      .catch(() => setFailedToLoadAudioAdPlacement(true));
  }

  function handleGetAudioRollupReach() {
    getAudioRollupReach({
      audioAdCampaignId: audioAdPlacement.audioAdCampaignId,
      audioAdPlacementId: audioAdPlacement.audioAdPlacementId,
    }).then(res => setReach(res.data));
  }

  if (failedToLoadAudioAdPlacement) {
    return (
      <BannerNotice
        error
        message="Failed to load SmartPromos. Please reload the page to try again."
      />
    );
  }

  if (!audioAdPlacement) {
    return <Loading fullScreen />;
  }

  return (
    <div>
      <div className="mb3">
        <div className="w-100 items-center flex flex-row justify-between gap mb3">
          <NewStatusIndicator
            className="f5 ttc b br4 ph3 pv1 help"
            status={audioAdPlacement.isArchived ? 'ARCHIVED' : audioAdPlacement.status.value}
            text={
              audioAdPlacement.status ? audioAdPlacement.status.value.toLowerCase() : 'archived'
            }
            tooltip={audioAdPlacement.isArchived ? null : audioAdPlacement.status.description}
          />
        </div>
        <div className="f4 mb3">{audioAdPlacement.name}</div>
        <div className="card pa3 mb3">
          <div className="flex flex-wrap" style={{ gap: '20px 0' }}>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoting</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {audioAdPlacement.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={audioAdPlacement.buyerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {audioAdPlacement.buyerPodcast
                    ? audioAdPlacement.buyerPodcast.shortTitle
                    : `${audioAdPlacement.buyerTeam} network`}
                </div>
              </div>
            </div>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoted on</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {audioAdPlacement.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={audioAdPlacement.sellerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {audioAdPlacement.sellerPodcast
                    ? audioAdPlacement.sellerPodcast.shortTitle
                    : `${audioAdPlacement.sellerTeam} network`}
                </div>
              </div>
            </div>
            {audioAdPlacement.episodeTitle && (
              <div style={{ flex: '50%', maxWidth: '100%' }}>
                <div className="f6 gray mb1 b">Episode</div>
                <div className="f5 dark-gray truncate">{audioAdPlacement.episodeTitle}</div>
              </div>
            )}
            {showDetails && (
              <React.Fragment>
                {audioAdPlacement.episode && (
                  <div style={{ flex: '50%' }}>
                    <div className="f6 gray mb1 b">Publish Date</div>
                    <div className="f5 dark-gray truncate">
                      {moment(audioAdPlacement.episode.publishedAt).format('MMM D, YYYY')}
                    </div>
                  </div>
                )}
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Publisher</div>
                  <div className="f5 dark-gray truncate">{audioAdPlacement.sellerTeam}</div>
                </div>
                {audioAdPlacement.adType && (
                  <div style={{ flex: '50%' }}>
                    <div className="f6 gray mb1 b">Ad Type</div>
                    <div className="f5 dark-gray truncate">{audioAdPlacement.adType}</div>
                  </div>
                )}
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Placement Type</div>
                  <div className="f5 dark-gray truncate">
                    {audioAdPlacement.campaignType === 'dai' ? 'Dynamic' : 'Baked-in'}
                  </div>
                </div>
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Total Cost</div>
                  {audioAdPlacement.totalCost ? (
                    <div className="f5 dark-gray truncate">{audioAdPlacement.totalCost}</div>
                  ) : (
                    <div className="f5 dark-gray">-</div>
                  )}
                </div>
              </React.Fragment>
            )}
          </div>
          <div className="flex flex-row mt3">
            <div onClick={() => setShowDetails(!showDetails)} className="pointer f6 blue link">
              See {showDetails ? 'less' : 'more'}
            </div>
            <img
              src={DownSVG}
              style={{
                height: '15px',
                marginTop: '1px',
                transform: `scaleY(${showDetails ? '-1' : '1'})`,
              }}
            />
          </div>
        </div>
        {audioAdPlacement.campaignType === 'dai' && (
          <Button onClick={() => setPixelManagerIsOpen(true)} className="mw5 mb3" type="primary">
            View Tracking Pixel Code
          </Button>
        )}
        <div className="mb3">
          <AudioAdPlacementStatusExplanation
            promo={audioAdPlacement}
            teamId={teamId}
            isSeller={isSeller}
          />
        </div>
        <StatCardHeader className="mb3" stats={audioAdPlacement} reach={reach} />
        {!isSeller && (
          <React.Fragment>
            {audioAdPlacement.podcasts && (
              <RollupTables
                podcastRollups={audioAdPlacement.podcasts}
                match={match}
                teamId={teamId}
                placement={placement}
                history={history}
              />
            )}
            <div className="mt4">
              {audioAdPlacement.uniqueDownloads.history &&
                audioAdPlacement.uniqueDownloads.history.length > 0 && (
                  <div>
                    <DownloadHistoryChart
                      title="Unique Converted Devices"
                      downloadHistory={audioAdPlacement.uniqueDownloads.history}
                      hideSelectors
                      loading={false}
                      height="480px"
                    />
                  </div>
                )}
              {audioAdPlacement.newDownloads.history &&
                audioAdPlacement.uniqueDownloads.history.length > 0 && (
                  <div>
                    <DownloadHistoryChart
                      title="New Unique Converted Devices"
                      downloadHistory={audioAdPlacement.newDownloads.history}
                      hideSelectors
                      loading={false}
                      height="480px"
                    />
                  </div>
                )}
              {audioAdPlacement.impressions.history &&
                audioAdPlacement.impressions.history.length > 0 && (
                  <DownloadHistoryChart
                    title="Impressions"
                    downloadHistory={audioAdPlacement.impressions.history}
                    hideSelectors
                    loading={false}
                    height="480px"
                  />
                )}
            </div>
          </React.Fragment>
        )}
        {audioAdPlacement.campaignType === 'dai' && (
          <BasicModal
            isOpen={pixelManagerIsOpen}
            onRequestClose={() => setPixelManagerIsOpen(false)}
            ariaHideApp={false}
          >
            <PixelManager
              canInstallPixelCode={audioAdPlacement.isInternal}
              onClose={() => setPixelManagerIsOpen(false)}
              pixel={audioAdPlacement.pixel}
              teamId={teamId}
              promo={audioAdPlacement}
            />
          </BasicModal>
        )}
      </div>
    </div>
  );
}

AudioAdPlacementDetailView.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  isSeller: PropTypes.bool,
};

AudioAdPlacementDetailView.defaultProps = {
  isSeller: false,
};

export default withUser(AudioAdPlacementDetailView);
