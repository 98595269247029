import React, { useEffect, useState } from 'react';
import { onClickHandler } from 'shared/a11y/lib';
import { getPodcastByItunesUrl } from 'shared/api';
import Banner from 'shared/Banner';
import BoxOptionSelect from 'shared/BoxOptionSelect';
import Button from 'shared/Button';
import PodcastSearchInput from 'shared/PodcastSearchInput';

const campaignTypeOptions = [
  { label: 'Dynamic', value: 'dai' },
  { label: 'Baked-in', value: 'baked_in' },
];

const PromotingPodcastSearchInput = ({
  teamId,
  campaignType,
  setShowItunesUrlInput,
  setPromotingPodcast,
}) => {
  if (campaignType && campaignType.value === 'dai') {
    return (
      <React.Fragment>
        <div className="mb2 lh-copy dark-gray f6">
          Type to search by Podcast title or{' '}
          <span
            className="link blue b pointer"
            {...onClickHandler(() => setShowItunesUrlInput(true))}
          >
            click here to find by apple podcasts url
          </span>
        </div>
        <PodcastSearchInput
          defaultOption={{
            id: 'run-of-network-promotion', // If there is a podcast with slug it will break ¯\_(ツ)_/¯
            label: 'Run of Network or Multi-show promotion',
            title: 'Run of Network or Multi-show promotion',
          }}
          teamId={teamId}
          onSelect={setPromotingPodcast}
          maxPodcastsToFetch={30}
          scopeToTeamPodcasts={false}
          key="dai"
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="mb2 lh-copy dark-gray f6">Type to search by Podcast title</div>
      <PodcastSearchInput
        defaultOption={null}
        teamId={teamId}
        onSelect={setPromotingPodcast}
        maxPodcastsToFetch={30}
        scopeToTeamPodcasts
        key="baked_in"
      />
      <Banner noImage className="mt2">
        <div className="f6 b lh-copy">
          As of 12/12/2024, baked-in SmartPromos can only be run on podcasts you've added to your
          Chartable Team
        </div>
      </Banner>
    </React.Fragment>
  );
};

function BasicInfoForm({
  teamId,
  promotedPodcast,
  promotingPodcast,
  setPromotingPodcast,
  setPromotedPodcast,
  campaignType,
  setCampaignType,
  setCampaignTypeExplainerIsOpen,
  setApprovalExplainerIsOpen,
  isInternal,
  canIgnoreTrackableErrorOnSmartPromoCreation,
}) {
  const [showItunesUrlInput, setShowItunesUrlInput] = useState(false);
  const [searchingByItunesUrl, setSearchingByItunesUrl] = useState(false);
  const [itunesUrl, setItunesUrl] = useState('');
  const [itunesUrlError, setItunesUrlError] = useState(false);

  const handleSearchByItunesUrl = () => {
    if (searchingByItunesUrl) return;
    setSearchingByItunesUrl(true);
    setItunesUrlError(false);

    getPodcastByItunesUrl({ itunesUrl, teamId })
      .then(res => setPromotingPodcast(res.data))
      .catch(() => setItunesUrlError(true))
      .finally(() => setSearchingByItunesUrl(false));
  };

  useEffect(() => {
    if (campaignType && campaignType.value === 'dai') {
      setPromotingPodcast({
        id: 'run-of-network-promotion', // If there is a podcast with slug it will break ¯\_(ツ)_/¯
        label: 'Run of Network or Multi-show promotion',
        title: 'Run of Network or Multi-show promotion',
      });
    } else {
      setPromotingPodcast(null);
    }

    setShowItunesUrlInput(false);
  }, [campaignType]);

  return (
    <React.Fragment>
      <div className="mb2 lh-copy dark-gray f4-5">
        What is the name of the show you're promoting?
      </div>
      <PodcastSearchInput
        teamId={teamId}
        onSelect={setPromotedPodcast}
        initialPodcast={promotedPodcast}
      />
      {promotedPodcast &&
        (promotedPodcast.trackableEnabled ||
        teamId === 'apm' ||
        canIgnoreTrackableErrorOnSmartPromoCreation ? (
          <React.Fragment>
            {!promotedPodcast.trackableEnabled && (
              <div className="red f6 lh-copy mv2">
                Trackable is not installed on this podcast. You must install the trackable prefix on
                this show in order to measure data.
              </div>
            )}
            <div className="w3 bb b--light-gray mv4" />
            <div className="lh-copy mb1 dark-gray f4-5">
              Will the ad be dynamically inserted or baked in to the episode?
            </div>
            <div
              className="link blue mb2 f6 b pointer"
              {...onClickHandler(() => setCampaignTypeExplainerIsOpen(true))}
            >
              What does this mean?
            </div>
            <BoxOptionSelect
              options={campaignTypeOptions}
              onSelectOption={newCampaignType => {
                setCampaignType(newCampaignType);
                if (
                  newCampaignType.value === 'baked_in' &&
                  promotingPodcast &&
                  promotingPodcast.id === 'run-of-network-promotion'
                ) {
                  setPromotingPodcast(null);
                }
              }}
              selectedOption={campaignType}
            />
            {campaignType && (
              <React.Fragment>
                <div className="w3 bb b--light-gray mv4" />
                <div className="lh-copy dark-gray f4-5">
                  What is the show the promotion is running on?
                </div>
                {showItunesUrlInput ? (
                  <React.Fragment>
                    <div className="mb2 lh-copy dark-gray f6">
                      Enter a show's apple podcasts url then hit the search button or{' '}
                      <span
                        className="link blue b pointer"
                        {...onClickHandler(() => {
                          setShowItunesUrlInput(false);
                          setItunesUrlError(false);
                          setItunesUrl('');
                        })}
                      >
                        click here to search by title
                      </span>
                    </div>
                    <div className="flex flex-row gap-small">
                      {promotingPodcast && promotingPodcast.id !== 'run-of-network-promotion' ? (
                        <div className="flex br1 flex-row items-center pr1 h2 bg-white ba b--moon-gray br1 flex-grow">
                          {promotingPodcast && (
                            <img
                              className="mr1 w-auto h-100 br2"
                              src={promotingPodcast.imageUrl}
                              alt=""
                            />
                          )}
                          <div className="f5 dark-gray truncate">{promotingPodcast.title}</div>
                        </div>
                      ) : (
                        <React.Fragment>
                          <input
                            className="pa2 input-reset flex-grow br2 ba b--moon-gray"
                            type="text"
                            value={itunesUrl}
                            onChange={e => setItunesUrl(e.target.value)}
                            disabled={searchingByItunesUrl}
                            placeholder="https://podcasts.apple.com/us/podcast/id1442696988"
                          />
                          <Button
                            onClick={handleSearchByItunesUrl}
                            type="primary"
                            className="h-100 w3 f6"
                            disabled={searchingByItunesUrl}
                            loading={searchingByItunesUrl}
                          >
                            Search
                          </Button>
                        </React.Fragment>
                      )}
                    </div>
                    {itunesUrlError &&
                      (!promotingPodcast || promotingPodcast.id === 'run-of-network-promotion') && (
                        <div className="f6 red mt1">
                          Could not find podcast. Check that the url is a valid apple podcasts url.
                        </div>
                      )}
                    {promotingPodcast && promotingPodcast.id !== 'run-of-network-promotion' && (
                      <div
                        className="blue link f6 pointer mt1"
                        {...onClickHandler(() => {
                          setItunesUrlError(false);
                          setPromotingPodcast(null);
                          setItunesUrl('');
                        })}
                      >
                        clear
                      </div>
                    )}
                  </React.Fragment>
                ) : (
                  <PromotingPodcastSearchInput
                    teamId={teamId}
                    campaignType={campaignType}
                    setShowItunesUrlInput={setShowItunesUrlInput}
                    promotingPodcast={promotingPodcast}
                    setPromotingPodcast={setPromotingPodcast}
                  />
                )}
                {campaignType &&
                  promotingPodcast &&
                  promotingPodcast.id !== 'run-of-network-promotion' && (
                    <React.Fragment>
                      {campaignType.value === 'dai' && (
                        <div className="mt2 f6 dark-gray">
                          This podcast is on {isInternal ? 'your' : 'an'}{' '}
                          <b>{isInternal ? 'internal' : 'external'} network</b>.{' '}
                          <span
                            className="link blue pointer"
                            {...onClickHandler(() => setApprovalExplainerIsOpen(true))}
                          >
                            Learn more
                          </span>
                        </div>
                      )}
                      {/* Special case apm to ignore trackable status gate */}
                      {teamId !== 'apm' &&
                        campaignType.value === 'baked_in' &&
                        !promotingPodcast.trackableEnabled && (
                          <div className="red lh-copy mt2">
                            You must install trackable on this show in order to measure your{' '}
                            <b>Baked-in</b> promo. Team admins can do this in the Team Settings
                            page.
                          </div>
                        )}
                    </React.Fragment>
                  )}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <div>
            <div className="red lh-copy mt2">
              You must{' '}
              <a
                target="_blank"
                className="link pointer blue b"
                href={`/teams/${teamId}/dashboard/integrations/trackable`}
              >
                install trackable
              </a>{' '}
              on this show in order to measure promos.
            </div>
            <div className="red lh-copy">
              Once you install trackable you will be able to create promos for this podcast.
            </div>
          </div>
        ))}
    </React.Fragment>
  );
}

export default BasicInfoForm;
