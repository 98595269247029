import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTrackingLinkEpisodeDeeplinks, getTrackingLinkPodcastDeeplinks } from 'shared/api/v1';
import Banner from 'shared/Banner';
import EpisodeSearchInput from 'shared/EpisodeSearchInput';
import PodcastSearchInput from 'shared/PodcastSearchInput';

import DeeplinkManager from '../../TrackingLinkForm/components/DeeplinkManager';

const SettingsDeeplinkManager = ({ linkableReadOnly }) => {
  const { teamId } = useParams();

  const [selectedPodcast, setSelectedPodcast] = useState(null);
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [deeplinks, setDeeplinks] = useState(null);
  const [failedToFetchDeeplinks, setFailedToFetchDeeplinks] = useState(false);

  const handleGetDeeplinks = async () => {
    if (!selectedPodcast) return;
    setDeeplinks(null);
    setFailedToFetchDeeplinks(false);

    try {
      let response;

      if (selectedEpisode) {
        response = await getTrackingLinkEpisodeDeeplinks({
          teamId,
          podcastId: selectedPodcast.id,
          rawEpisodeId: selectedEpisode.id,
        });
      } else {
        response = await getTrackingLinkPodcastDeeplinks({
          teamId,
          podcastId: selectedPodcast.id,
        });
      }

      setDeeplinks(response.data.data);
    } catch (_) {
      setFailedToFetchDeeplinks(true);
    }
  };

  useEffect(() => {
    handleGetDeeplinks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedEpisode), JSON.stringify(selectedPodcast)]);

  return (
    <>
      <div className="f5 mb2 lh-copy mt3 dark-gray">
        Manage deeplinks to your podcasts and episodes. <b>Select a Podcast to get started.</b>
      </div>
      <div className="bb b--light-gray w-90 tc" />
      <div className="f5 mb2 mt3 dark-gray">Podcast</div>
      <PodcastSearchInput
        teamId={teamId}
        onSelect={podcast => {
          setSelectedPodcast(podcast);
          setSelectedEpisode(null);
        }}
        selectedPodcast={selectedPodcast}
        className="f5 mw7 mb2"
      />
      {selectedPodcast && (
        <React.Fragment>
          <div className="f5 mb2 mt3 dark-gray">Episode (Leave blank to set Podcast deeplinks)</div>
          <EpisodeSearchInput
            podcastId={selectedPodcast.id}
            teamId={teamId}
            onSelect={setSelectedEpisode}
            selectedEpisode={selectedEpisode}
            className="f5 mw7"
            allowRefresh
            numberOfEpisodesToPrefetch={10}
          />
          {failedToFetchDeeplinks && (
            <Banner className="mt3 mw7" error>
              Failed to load deeplinks
            </Banner>
          )}
          <div className="pa3 mt3 bg-white mw7">
            <div className="f5 mid-gray lh-copy">
              Managing deeplinks for:{' '}
              <b className="dark-gray">
                {selectedPodcast.title} {selectedEpisode && ` - ${selectedEpisode.title}`}
              </b>
            </div>
            <DeeplinkManager
              teamId={teamId}
              podcastId={selectedPodcast ? selectedPodcast.id : null}
              rawEpisodeId={selectedEpisode ? selectedEpisode.id : null}
              deeplinks={deeplinks}
              setDeeplinks={setDeeplinks}
              linkableReadOnly={linkableReadOnly}
            />
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default SettingsDeeplinkManager;
