"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formControlTheme = exports.formControlBaseSelect = exports.formControlBase = exports.formChevronDown = exports.formCheckIndicator = exports.formCheck = void 0;
var _encoreFoundation = require("@spotify-internal/encore-foundation");
var _styledComponents = require("styled-components");
var _ = require("../");
var _semanticTheme = require("../semantic-theme");
var _variables = require("../variables");
var _baseline = require("./baseline");
var _type = require("./type");
/* stylelint-disable no-descending-specificity */

//
// Forms
// --------------------------------------------------

// Shared styles for inputs, textareas, selects
var formControlBase = function formControlBase(useBrowserDefaultFocusStyle) {
  return (0, _styledComponents.css)(["-webkit-appearance:none;background-image:none;border:0;display:block;", ";transition:box-shadow ease-in-out 0.1s,color ease-in-out 0.1s;inline-size:100%;&::placeholder{opacity:1;}", " &:disabled{cursor:not-allowed;opacity:1;}"], (0, _type.ballad)(), !useBrowserDefaultFocusStyle && (0, _styledComponents.css)(["&:focus,&:hover:focus{outline:0;}"]));
};

// Styles for selects only
exports.formControlBase = formControlBase;
var formControlBaseSelect = function formControlBaseSelect() {
  return (0, _styledComponents.css)(["", ";margin-block-start:0;margin-block-end:0;appearance:none;box-shadow:none;text-indent:0.01px;text-overflow:'';&::-ms-expand{display:none;}border-radius:", ";padding-block-start:", ";padding-block-end:", ";padding-inline-start:14px;padding-inline-end:44px;"], (0, _baseline.rootStyle)(), _encoreFoundation.spacer4, _.controlPaddingBlock.md, _.controlPaddingBlock.md);
};

// Styles the down arrow icon on FormSelect and Dropdown
exports.formControlBaseSelect = formControlBaseSelect;
var formChevronDown = function formChevronDown() {
  return (0, _styledComponents.css)(["color:", ";pointer-events:none;position:absolute;right:14px;[dir='rtl'] &{left:14px;right:auto;}"], (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialSubdued));
};

// Shared styles for radios + checkboxes
exports.formChevronDown = formChevronDown;
var formCheck = function formCheck() {
  return (0, _styledComponents.css)(["", ";align-items:center;display:flex;padding-block-end:", ";padding-block-start:", ";position:relative;min-block-size:", ";"], (0, _baseline.rootStyle)(), _encoreFoundation.spacer4, _encoreFoundation.spacer4, _encoreFoundation.spacer32);
};
exports.formCheck = formCheck;
var formCheckIndicator = function formCheckIndicator(radius) {
  return (0, _styledComponents.css)(["", ";background:transparent;border-radius:", ";display:inline-block;block-size:", ";position:relative;user-select:none;inline-size:", ";flex-shrink:0;align-self:flex-start;top:0;"], (0, _baseline.rootStyle)(), radius, _encoreFoundation.spacer16, _encoreFoundation.spacer16);
};
exports.formCheckIndicator = formCheckIndicator;
var formControlTheme = function formControlTheme(_ref) {
  var _ref$isUsingKeyboard = _ref.isUsingKeyboard,
    isUsingKeyboard = _ref$isUsingKeyboard === void 0 ? true : _ref$isUsingKeyboard,
    isHover = _ref.isHover,
    isFocus = _ref.isFocus,
    useBrowserDefaultFocusStyle = _ref.useBrowserDefaultFocusStyle,
    error = _ref.error;
  var hoverStyles = (0, _styledComponents.css)(["box-shadow:inset 0 0 0 1px ", ";"], (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialBase));
  var focusStyles = (0, _styledComponents.css)(["box-shadow:inset 0 0 0 ", "px ", ";"], isUsingKeyboard ? 3 : 1.5, (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialBase));
  var errorStyles = (0, _styledComponents.css)(["&,&:hover{box-shadow:inset 0 0 0 1px ", ";}&:focus{box-shadow:inset 0 0 0 ", "px ", ";}"], (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialNegative), isUsingKeyboard ? 3 : 1.5, (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialNegative));
  return (0, _styledComponents.css)(["background-color:", ";box-shadow:inset 0 0 0 1px ", ";color:", ";&:hover,&[readonly]:hover{", "}", " &:focus,&[readonly]:focus{", "}&&{", ";}&::placeholder{color:", ";}&:disabled{opacity:", ";box-shadow:inset 0 0 0 1px ", ";}&[readonly]{box-shadow:inset 0 0 0 1px ", ";}&:invalid{", ";}", ""], (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.backgroundBase), (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialSubdued), (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.textBase), hoverStyles, isHover && hoverStyles, !useBrowserDefaultFocusStyle && focusStyles, isFocus && !useBrowserDefaultFocusStyle && focusStyles, (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.textSubdued), _variables.opacityDisabled, (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.essentialSubdued), (0, _semanticTheme.cssColorValue)(_semanticTheme.semanticColors.decorativeSubdued), error === undefined && errorStyles, error && errorStyles);
};
exports.formControlTheme = formControlTheme;