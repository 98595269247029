import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  getConsumptionSeries,
  getDownloadsHistory,
  getEpisode,
  getPlaysHistory,
  getPodcast,
} from 'shared/api';
import { getEpisodeDemographics } from 'shared/api/v1';
import { getFlooredUtcDateTime } from 'shared/helpers';
import Loading from 'shared/Loading';
import Sidebar from 'shared/Sidebar';
import { withUser } from 'shared/UserContext';

import DashboardEpisodeOverviewView from './components/DashboardEpisodeOverviewView';

class DashboardEpisodeOverview extends Component {
  constructor(props) {
    super(props);

    this.handleGetPodcast = this.handleGetPodcast.bind(this);
    this.handleGetEpisode = this.handleGetEpisode.bind(this);
    this.handleGetEpisodeDemographics = this.handleGetEpisodeDemographics.bind(this);
    this.handleGetDownloadsHistory = this.handleGetDownloadsHistory.bind(this);
    this.handleGetConsumptionSeries = this.handleGetConsumptionSeries.bind(this);
    this.handleGetPlaysHistory = this.handleGetPlaysHistory.bind(this);
    this.handleChangeDownloadsEndDate = this.handleChangeDownloadsEndDate.bind(this);
    this.handleChangeDownloadsStartDate = this.handleChangeDownloadsStartDate.bind(this);
    this.handleChangePlaysEndDate = this.handleChangePlaysEndDate.bind(this);
    this.handleChangePlaysStartDate = this.handleChangePlaysStartDate.bind(this);

    const initialStartDate = moment().subtract(3, 'months').toDate();
    const initialEndDate = new Date();

    this.state = {
      podcast: null,
      episode: null,
      loadingDownloadsHistory: false,
      downloadsHistory: null,
      downloadsHistoryAggregation: null,
      loadingConsumptionSeries: false,
      consumptionSeries: null,
      loadingPlaysHistory: false,
      playsHistory: null,
      playsHistoryAggregation: null,
      loadingDemographics: null,
      downloadsStartDate: initialStartDate,
      downloadsEndDate: initialEndDate,
      playsStartDate: initialStartDate,
      playsEndDate: initialEndDate,
      episodeDemographics: null,
    };
  }

  componentDidMount() {
    this.handleGetPodcast();
    this.handleGetEpisode();
    this.handleGetEpisodeDemographics();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.episode && this.state.episode) {
      if (this.state.episode.totalDownloads) {
        this.handleGetDownloadsHistory({ numDays: 30, aggregation: 'daily' });
      }
      if (this.state.episode.plays) {
        this.handleGetConsumptionSeries();
        this.handleGetPlaysHistory({ numDays: 30, aggregation: 'daily' });
      }
    }
  }

  handleGetPodcast() {
    getPodcast({ ...this.props, include_last_episode: true }).then(res => {
      this.setState({
        podcast: res.data,
      });
    });
  }

  async handleGetEpisodeDemographics() {
    const urlParams = new URLSearchParams(window.location.search);
    const episodeId = urlParams.get('episode_id');

    try {
      const res = await getEpisodeDemographics({ episodeId });
      this.setState({ episodeDemographics: res.data });
    } catch (_) {}
  }

  handleGetEpisode() {
    getEpisode({ ...this.props, include_chart_positions: true }).then(res => {
      const startDate = new Date(res.data.publishedAt);
      this.setState({
        episode: res.data,
        playsEndDate: new Date(),
        downloadsEndDate: new Date(),
        playsStartDate: startDate,
        downloadsStartDate: startDate,
      });
    });
  }

  handleChangeDownloadsStartDate(date) {
    this.handleGetDownloadsHistory({ startDate: date });
  }

  handleChangeDownloadsEndDate(date) {
    this.handleGetDownloadsHistory({ endDate: date });
  }

  handleChangePlaysEndDate(date) {
    this.handleGetPlaysHistory({ endDate: date });
  }

  handleChangePlaysStartDate(date) {
    this.handleGetPlaysHistory({ startDate: date });
  }

  handleGetDownloadsHistory({ startDate, endDate, aggregation }) {
    const selectedStartDate = startDate === undefined ? this.state.downloadsStartDate : startDate;
    const selectedEndDate = endDate === undefined ? this.state.downloadsEndDate : endDate;
    const { loadingDownloadsHistory, downloadsHistoryAggregation } = this.state;
    if (loadingDownloadsHistory) return;

    this.setState({ loadingDownloadsHistory: true });
    getDownloadsHistory({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      episodeId: this.props.episodeId,
      startDate: selectedStartDate ? getFlooredUtcDateTime(selectedStartDate) : null,
      endDate: selectedEndDate ? getFlooredUtcDateTime(selectedEndDate) : null,
      aggregation: aggregation || downloadsHistoryAggregation,
    }).then(res => {
      this.setState({
        downloadsHistory: res.data,
        loadingDownloadsHistory: false,
        downloadsStartDate: selectedStartDate,
        downloadsEndDate: selectedEndDate,
        downloadsHistoryAggregation: aggregation || downloadsHistoryAggregation,
      });
    });
  }

  handleGetPlaysHistory({ startDate, endDate, aggregation }) {
    const { userInfo } = this.props;
    if (!userInfo.showSpotify) return;

    const selectedStartDate = startDate === undefined ? this.state.playsStartDate : startDate;
    const selectedEndDate = endDate === undefined ? this.state.playsEndDate : endDate;
    const { loadingPlaysHistory, playsHistoryAggregation } = this.state;
    if (loadingPlaysHistory) return;

    this.setState({ loadingPlaysHistory: true });
    getPlaysHistory({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      episodeId: this.props.episodeId,
      startDate: selectedStartDate ? getFlooredUtcDateTime(selectedStartDate) : null,
      endDate: selectedEndDate ? getFlooredUtcDateTime(selectedEndDate) : null,
      aggregation: aggregation || playsHistoryAggregation,
    }).then(res => {
      this.setState({
        playsHistory: res.data,
        loadingPlaysHistory: false,
        playsStartDate: selectedStartDate,
        playsEndDate: selectedEndDate,
        playsHistoryAggregation: aggregation || playsHistoryAggregation,
      });
    });
  }

  handleGetConsumptionSeries() {
    const { userInfo } = this.props;
    if (!userInfo.showSpotify) return;

    const { loadingConsumptionSeries } = this.state;
    if (loadingConsumptionSeries) return;

    this.setState({ loadingConsumptionSeries: true });
    getConsumptionSeries({
      teamId: this.props.teamId,
      podcastId: this.props.podcastId,
      episodeId: this.props.episodeId,
      timeseries: true,
    }).then(res => {
      this.setState({
        loadingConsumptionSeries: false,
        consumptionSeries: res.data,
      });
    });
  }

  render() {
    const {
      podcast,
      episode,
      downloadsHistory,
      downloadsHistoryAggregation,
      loadingDownloadsHistory,
      loadingConsumptionSeries,
      consumptionSeries,
      loadingPlaysHistory,
      playsHistory,
      playsHistoryAggregation,
      loadingDemographics,
      downloadsStartDate,
      downloadsEndDate,
      playsStartDate,
      playsEndDate,
      episodeDemographics,
    } = this.state;

    const { teamId, reportingWindow, podcastsCount, sidebar, userInfo } = this.props;

    return (
      <Sidebar sidebar={sidebar}>
        {!podcast || !episode ? (
          <Loading fullScreen />
        ) : (
          <DashboardEpisodeOverviewView
            teamId={teamId}
            podcast={podcast}
            podcastsCount={podcastsCount}
            episode={episode}
            downloadsHistory={downloadsHistory}
            downloadsHistoryAggregation={downloadsHistoryAggregation}
            downloadsStartDate={downloadsStartDate}
            downloadsEndDate={downloadsEndDate}
            playsStartDate={playsStartDate}
            playsEndDate={playsEndDate}
            onChangeDownloadsEndDate={this.handleChangeDownloadsEndDate}
            onChangeDownloadsStartDate={this.handleChangeDownloadsStartDate}
            onChangePlaysEndDate={this.handleChangePlaysEndDate}
            onChangePlaysStartDate={this.handleChangePlaysStartDate}
            onGetDownloadsHistory={this.handleGetDownloadsHistory}
            loadingDownloadsHistory={loadingDownloadsHistory}
            playsHistory={playsHistory}
            loadingPlaysHistory={loadingPlaysHistory}
            playsHistoryAggregation={playsHistoryAggregation}
            onGetPlaysHistory={this.handleGetPlaysHistory}
            loadingConsumptionSeries={loadingConsumptionSeries}
            consumptionSeries={consumptionSeries}
            loadingDemographics={loadingDemographics}
            reportingWindow={reportingWindow}
            userInfo={userInfo}
            episodeDemographics={episodeDemographics}
          />
        )}
      </Sidebar>
    );
  }
}

DashboardEpisodeOverview.propTypes = {
  teamId: PropTypes.string.isRequired,
  podcastId: PropTypes.string.isRequired,
  episodeId: PropTypes.number.isRequired,
  reportingWindow: PropTypes.string.isRequired,
};

export default withUser(DashboardEpisodeOverview);
