import React, { useState } from 'react';
import Select from 'react-select';
import moment from 'moment';
import PropTypes from 'prop-types';
import Banner from 'shared/Banner';
import Button from 'shared/Button';
import Header from 'shared/Header';
import { providerOptions } from 'shared/helpers';
import Loading from 'shared/Loading';

import EpisodeSelector from './EpisodeSelector';
import AudioAdPlacementStatusExplanation from './SmartPromos/AudioAdPlacementStatusExplanation';

const PromoRequestForm = ({
  request,
  provider,
  setProvider,
  onApprove,
  requestInProgress,
  onCancel,
  teamId,
}) => {
  let content;

  if (request.campaignType === 'dai') {
    content = (
      <>
        <div className="f6 b mb1 mid-gray header-font">Provider</div>
        <Select
          value={provider}
          onChange={e => setProvider(e)}
          options={providerOptions}
          className="f5 near-black mb4"
        />
        <Button
          onClick={() => onApprove(provider, request.campaignType, false, null)}
          type="create"
          disabled={(!provider && request.campaignType === 'dai') || requestInProgress}
          loading={requestInProgress}
        >
          Approve
        </Button>
      </>
    );
  } else if (request.episode) {
    content = (
      <Button
        onClick={() => onApprove(null, request.campaignType, false, null)}
        type="create"
        disabled={(!provider && request.campaignType === 'dai') || requestInProgress}
        loading={requestInProgress}
      >
        Approve
      </Button>
    );
  } else if (!request.sellerPodcast) {
    content = (
      <Banner className="f6 mb3" error>
        <b>This promo has no promoting podcast assigned to it.</b>
        <div className="mt1 lh-copy">
          The podcast that will promote <i>{request.buyerPodcast.title}</i> is not assigned to this
          promo. Please get in touch with support to resolve this issue.
        </div>
      </Banner>
    );
  } else if (moment(request.scheduledAirDate).isBefore(moment(new Date()))) {
    content = (
      <div className="relative">
        <EpisodeSelector
          teamId={teamId}
          podcastId={request.sellerPodcast.slug}
          scheduledAirDate={request.scheduledAirDate}
          onSelect={episode => onApprove(null, request.campaignType, false, episode.cid)}
        />
        {requestInProgress && (
          <div className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0">
            <div className="card pa3 ba bw1 b--light-gray shadow flex justify-center flex-column items-center">
              <Loading />
              <div className="f4 b tc">Filling Episode</div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    content = (
      <Banner className="f5 mb3" noImage>
        You will be able to select an episode for this promo on the scheduled air date,{' '}
        {moment(request.scheduledAirDate).format('MM/DD/YYYY')}
      </Banner>
    );
  }

  return (
    <div>
      <Header useNew size="small" className="mb3">
        Approve Promo Request
      </Header>
      <div className="f6 b mb1 mid-gray header-font">Name</div>
      <div className="mb3 near-black">{request.name}</div>
      <div className="f6 b mb1 mid-gray header-font">Advertiser</div>
      <div className="mb3 near-black">{request.buyerTeam}</div>
      <div className="f6 b mb1 mid-gray header-font">Promoting</div>
      <div className="mb3 near-black">{request.buyerPodcast.title}</div>
      <div className="f6 b mb1 mid-gray header-font">Promoting on</div>
      {request.sellerPodcast ? (
        <div className="mb3 near-black">{request.sellerPodcast.title}</div>
      ) : request.campaignType == 'dai' ? (
        <div className="mb3 near-black i">Multiple or Run of Network</div>
      ) : (
        <div className="mb3 red i">No promoting podcast assigned</div>
      )}
      {request.sellerRawEpisode && (
        <React.Fragment>
          <div className="f6 b mb1 mid-gray header-font">Episode</div>
          <div className="mb3 near-black">{request.sellerRawEpisode.title}</div>
        </React.Fragment>
      )}
      {request.scheduledAirDate && (
        <React.Fragment>
          <div className="f6 b mb1 mid-gray header-font">Air Date</div>
          <div className="mb3 near-black">
            {moment(request.scheduledAirDate).format('MM/DD/YYYY')}
          </div>
        </React.Fragment>
      )}
      <div className="f6 b mb1 mid-gray header-font">Campaign Type</div>
      <div className="near-black mb3">
        {request.campaignType === 'dai' ? 'Dynamic' : 'Baked in'}
      </div>
      {!!request.ownerTeamName && request.campaignType == 'baked_in' && (
        <Banner className="f6 mb3" noImage>
          Your team has permissions to approve SmartPromos on behalf of{' '}
          <b>{request.ownerTeamName}</b>. Once approved, this promo & its data will populate on the{' '}
          <b>{request.ownerTeamName}</b> publisher dashboard.
        </Banner>
      )}
      {content}
      <Button disabled={requestInProgress} className="mt3" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
};

const ErrorBanner = () => (
  <Banner error>
    <b className="f6">
      Something went wrong approving this campaign. Please reach out to support if this problem
      continues
    </b>
  </Banner>
);

const PixelDisplay = ({ pixel }) => (
  <div>
    <h2 className="mb2 dark-gray">Installation</h2>
    <div className="bg-near-white pa3">
      <div className="lh-copy f5 body-font dark-gray">
        Install this pixel according to your provider's instructions
      </div>
      <div className="red f6 mt2 lh-copy">
        Note: this tag contains macros, or dynamic values, which must be filled in by the ad serving
        platform. <strong>It will not load in a web browser</strong>.
      </div>
      <div className="bg-light-gray w-95 center mv3" style={{ height: '2px' }} />
      <div className="flex flex-column">
        <div className="ba bg-white br2 b--light-silver pa3 mb2" style={{ wordBreak: 'break-all' }}>
          <code className="f6">{pixel.url}</code>
        </div>
      </div>
    </div>
  </div>
);

const ApprovalForm = ({
  request,
  onApprove,
  onCancel,
  requestInProgress,
  approvedRequest,
  error,
  teamId,
  history,
}) => {
  const [provider, setProvider] = useState(null);

  if (approvedRequest) {
    return (
      <div>
        <div>
          <div className="f4 mb3">{approvedRequest.name}</div>
          <div className="flex flex-wrap" style={{ gap: '20px 0' }}>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoting</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {approvedRequest.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={approvedRequest.buyerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {approvedRequest.buyerPodcast
                    ? approvedRequest.buyerPodcast.shortTitle
                    : `${approvedRequest.buyerTeam} network`}
                </div>
              </div>
            </div>
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Promoted on</div>
              <div className="flex br1 flex-row items-center pr1 mw6 bg-white w4-5">
                {approvedRequest.sellerPodcast && (
                  <img
                    className="mr1 w-auto"
                    style={{ height: '1.25rem' }}
                    src={approvedRequest.sellerPodcast.displayImageUrl}
                    alt=""
                  />
                )}
                <div className="f5 dark-gray truncate">
                  {approvedRequest.sellerPodcast
                    ? approvedRequest.sellerPodcast.shortTitle
                    : `${approvedRequest.sellerTeam} network`}
                </div>
              </div>
            </div>
            {approvedRequest.episodeTitle && (
              <div style={{ flex: '50%', maxWidth: '100%' }}>
                <div className="f6 gray mb1 b">Episode</div>
                <div className="f5 dark-gray truncate">{approvedRequest.episodeTitle}</div>
              </div>
            )}
            {approvedRequest.campaignType === 'baked_in' &&
              (approvedRequest.episode ? (
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Publish Date</div>
                  <div className="f5 dark-gray truncate">
                    {moment(approvedRequest.episode.publishedAt).format('MMM D, YYYY')}
                  </div>
                </div>
              ) : (
                <div style={{ flex: '50%' }}>
                  <div className="f6 gray mb1 b">Air Date</div>
                  <div className="f5 dark-gray truncate">
                    {moment(approvedRequest.startedAt).format('MMM D, YYYY')}
                  </div>
                </div>
              ))}
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Publisher</div>
              <div className="f5 dark-gray truncate">{approvedRequest.sellerTeam}</div>
            </div>
            {approvedRequest.adType && (
              <div style={{ flex: '50%' }}>
                <div className="f6 gray mb1 b">Ad Type</div>
                <div className="f5 dark-gray truncate">{approvedRequest.adType}</div>
              </div>
            )}
            <div style={{ flex: '50%' }}>
              <div className="f6 gray mb1 b">Placement Type</div>
              <div className="f5 dark-gray truncate">
                {approvedRequest.campaignType === 'dai' ? 'Dynamic' : 'Baked-in'}
              </div>
            </div>
            {!!approvedRequest.pixel && <PixelDisplay pixel={approvedRequest.pixel} />}
          </div>
          <div className="mt3">
            <AudioAdPlacementStatusExplanation promo={approvedRequest} teamId={teamId} isSeller />
          </div>
        </div>
        <Button className="mt3" type="primary" onClick={onCancel}>
          Done
        </Button>
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="mb2">
          <ErrorBanner error={error} />
        </div>
      )}
      <PromoRequestForm
        request={request}
        provider={provider}
        setProvider={setProvider}
        onApprove={onApprove}
        onCancel={onCancel}
        requestInProgress={requestInProgress}
        teamId={teamId}
      />
    </div>
  );
};

ApprovalForm.propTypes = {
  request: PropTypes.object.isRequired,
  onApprove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  requestInProgress: PropTypes.bool.isRequired,
  approvedRequest: PropTypes.object,
  error: PropTypes.any,
};

ApprovalForm.defaultProps = {
  approvedRequest: null,
  error: null,
};

export default ApprovalForm;
