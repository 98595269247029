import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import moment from 'moment';

import { searchEpisodes, buyerSearchEpisodes } from 'shared/api';

class EpisodeSelectorForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleAutosuggest = this.handleAutosuggest.bind(this);
    this.renderSuggestion = this.renderSuggestion.bind(this);
    this.handleSuggestionSelected = this.handleSuggestionSelected.bind(this);

    this.state = {
      titleSearchText: '',
      suggestions: [],
    };
  }

  handleSuggestionSelected(suggestion) {
    this.props.onSelectEpisode(suggestion);
    this.setState({ titleSearchText: '' });
  }

  handleAutosuggest({ value }) {
    const { teamId, podcast } = this.props;

    // ~~Commenting this out for now~~
    // It adds a level of security where a user wouldn't be able to access a team podcast
    // they don't have permission to, but in the case where the user wants to create a
    // promo on a podcast they don't have permission to (something we allow) it blocks them.
    //
    // We only use this selector to buy promos. If we ever want to use it for something
    // else we can revisit.
    // -JS
    //
    // if (this.props.scopeToTeamPodcast) {
    // searchEpisodes({
    //   query: value.trim(),
    //   teamId,
    //   podcastId: podcast.id,
    // }).then(res => {
    //   this.setState({ suggestions: res.data.results });
    // });
    // } else {
    // return

    buyerSearchEpisodes({
      query: value.trim(),
      teamId,
      podcastId: podcast.id,
    }).then(res => {
      this.setState({ suggestions: res.data.results });
    });
  }

  renderSuggestion(suggestion) {
    const displayTitle =
      suggestion.title.length > 40 ? `${suggestion.title.slice(0, 40)}...` : suggestion.title;

    const { publishedAt, title } = suggestion;

    return (
      <div className="relative pointer link pa2 flex flex-wrap items-center">
        <div className="w-85">
          <div className="f6 b tl" title={title}>
            {displayTitle}
          </div>
        </div>
        <div className="w-10 f6 pl2">{moment(publishedAt).format('MM/DD/YYYY')}</div>
      </div>
    );
  }

  render() {
    const { selectedEpisode, onUnselectEpisode } = this.props;

    const { suggestions, titleSearchText } = this.state;

    return (
      <div>
        {selectedEpisode ? (
          <div>
            <div className="ba br2 mt2 b--dotted">
              <div className="relative h3 flex flex-wrap justify-between bg-white pa2">
                <span className="w-auto flex flex-column justify-center">
                  <div className="f6 b tl">{selectedEpisode.title}</div>
                </span>
                <span className="w-15 f6 tr flex flex-column justify-center">
                  {moment(selectedEpisode.publishedAt).format('MM/DD/YYYY')}
                </span>
                <div className="pointer pr2 pt1 top-0 right-0 absolute f7 lh-copy b">
                  <div
                    className="underline-hover f5 dib black pointer tr"
                    onClick={onUnselectEpisode}
                  >
                    &times;
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={this.handleAutosuggest}
            onSuggestionsClearRequested={() => this.setState({ suggestions: [] })}
            onSuggestionSelected={(ev, { suggestion }) => this.handleSuggestionSelected(suggestion)}
            getSuggestionValue={suggestion => suggestion.trackName}
            shouldRenderSuggestions={value => value.trim().length > 1}
            renderSuggestion={this.renderSuggestion}
            inputProps={{
              className: 'pa2 b--moon-gray ba br2 w-100 w5 f5',
              placeholder: 'Search episodes by title',
              value: titleSearchText,
              onChange: e => this.setState({ titleSearchText: e.target.value }),
            }}
          />
        )}
      </div>
    );
  }
}

EpisodeSelectorForm.propTypes = {
  scopeToTeamPodcast: PropTypes.bool,
};

EpisodeSelectorForm.defaultProps = {
  scopeToTeamPodcast: true,
};

export default EpisodeSelectorForm;
