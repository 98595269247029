import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';

import Loading from 'shared/Loading';

import ConsumptionChart from 'shared/charts/ConsumptionChart';
import ChartHistoryChart from 'shared/charts/ChartHistoryChart';
import CountryDemographicsChart from 'shared/charts/CountryDemographicsChart';
import ColumnChart from 'shared/charts/ColumnChart';

function camelize(str) {
  return str.replace(/\W+(.)/g, function (match, chr) {
    return chr.toUpperCase();
  });
}

export default function DashboardLinksCompareView({
  teamId,
  links,
  linkOne,
  linkTwo,
  onChangeLink,
  clicksHistory,
  downloadsHistory,
  playersData,
  clicksHistoryAggregation,
  clicksStartDate,
  clicksEndDate,
  downloadsHistoryAggregation,
  downloadsStartDate,
  downloadsEndDate,
  onChangeClicksEndDate,
  onChangeClicksStartDate,
  onChangeDownloadsEndDate,
  onChangeDownloadsStartDate,
  onGetClicksHistory,
  onGetDownloadsHistory,
}) {
  const linkOptions = links.map(link => ({ value: link, label: link.name }));
  return (
    <div>
      <div className="w-100 ph3 pv2 mb2 white bg-light-blue tl pointer">
        <a href={`/teams/${teamId}/dashboard/links`} className="link white">
          &laquo; All SmartLinks
        </a>
      </div>
      <div className="mw8 pa3">
        <ReactTooltip />
        <div className="w-100 flex-ns flex-wrap-ns mb4">
          <div className="w-50-ns w-100 pa2">
            {links.length > 1 && (
              <div className="mb4">
                <div className="f4 mb2">Choose a SmartLink</div>
                <Select
                  value={linkOne ? { value: linkOne, label: linkOne.name } : null}
                  onChange={e => onChangeLink(e.value)}
                  options={linkOptions}
                />
              </div>
            )}
          </div>
          <div className="w-50-ns w-100 pa2">
            {links.length > 1 && (
              <div className="mb4">
                <div className="f4 mb2">Choose a SmartLink</div>
                <Select
                  value={linkTwo ? { value: linkTwo, label: linkTwo.name } : null}
                  onChange={e => onChangeLink(null, e.value)}
                  options={linkOptions}
                />
              </div>
            )}
          </div>
        </div>

        {clicksHistory && (
          <ChartHistoryChart
            legendPosition="top"
            history={clicksHistory}
            aggregation={'daily'}
            loading={false}
            height="480px"
            title="Clicks"
            legendPosition="top"
            aggregation={clicksHistoryAggregation}
            startDate={clicksStartDate}
            endDate={clicksEndDate}
            handleChangeEndDate={onChangeClicksEndDate}
            handleChangeStartDate={onChangeClicksStartDate}
            onGetHistory={onGetClicksHistory}
          />
        )}

        {downloadsHistory && (
          <ChartHistoryChart
            legendPosition="top"
            history={downloadsHistory}
            aggregation={'daily'}
            loading={false}
            height="480px"
            title="Devices"
            legendPosition="top"
            aggregation={downloadsHistoryAggregation}
            startDate={downloadsStartDate}
            endDate={downloadsEndDate}
            handleChangeEndDate={onChangeDownloadsEndDate}
            handleChangeStartDate={onChangeDownloadsStartDate}
            onGetHistory={onGetDownloadsHistory}
          />
        )}
      </div>
    </div>
  );
}

DashboardLinksCompareView.propTypes = {
  teamId: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  linkOne: PropTypes.object,
  linkTwo: PropTypes.object,
  onChangeLink: PropTypes.func.isRequired,
  clicksHistory: PropTypes.array,
  downloadsHistory: PropTypes.array,
  playersData: PropTypes.array,
  clicksHistoryAggregation: PropTypes.string,
  clicksStartDate: PropTypes.instanceOf(Date),
  clicksEndDate: PropTypes.instanceOf(Date),
  downloadsHistoryAggregation: PropTypes.string,
  downloadsStartDate: PropTypes.instanceOf(Date),
  downloadsEndDate: PropTypes.instanceOf(Date),
  onChangeClicksEndDate: PropTypes.func,
  onChangeClicksStartDate: PropTypes.func,
  onChangeDownloadsEndDate: PropTypes.func,
  onChangeDownloadsStartDate: PropTypes.func,
  onGetClicksHistory: PropTypes.func,
  onGetDownloadsHistory: PropTypes.func,
};

DashboardLinksCompareView.defaultProps = {
  linkOne: null,
  linkTwo: null,
  clicksHistory: [],
  downloadsHistory: [],
  playersData: [],
};
