import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getAudioRollupReach, getAudioRollups } from 'shared/api';
import BannerNotice from 'shared/BannerNotice';
import ChartableDatePicker from 'shared/ChartableDatePicker';
import DownloadHistoryChart from 'shared/charts/DownloadHistoryChart';
import Loading from 'shared/Loading';

import RollupTable from './RollupTable';
import StatCardHeader from './StatCardHeader';
import TopBar from './TopBar';

function PodcastRollupDetailView(props) {
  const { match, selectedPodcast, initialState, storeState, lookbackWindow } = props;

  const { teamId, audioAdCampaignId, audioAdPlacementId, podcastId } = match.params;

  const [podcastRollup, setPodcastRollup] = useState(null);
  const [failedToLoadPodcastRollup, setFailedToLoadPodcastRollup] = useState(false);
  const [startDate, setStartDate] = useState(initialState.startDate);
  const [endDate, setEndDate] = useState(initialState.endDate);

  const [reach, setReach] = useState(null);

  useEffect(() => {
    handleGetAudioRollupReach();
    handleGetAudioAdRollup();
    // ignoring exhaustive deps until tests are written
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => window.scrollTo(0, 0), []);
  // ignoring exhaustive deps until tests are written
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => storeState({ startDate, endDate }), [startDate, endDate]);

  function handleGetAudioAdRollup() {
    getAudioRollups({
      teamId,
      audioAdCampaignId,
      audioAdPlacementId,
      podcastId,
      startDate,
      endDate,
      pivotColumn: 'podcast_id',
      includeDetails: true,
    })
      .then(res => setPodcastRollup(res.data[0]))
      .catch(() => setFailedToLoadPodcastRollup(true));
  }

  function handleGetAudioRollupReach() {
    getAudioRollupReach({
      audioAdCampaignId,
      audioAdPlacementId,
      podcastId,
      startDate,
      endDate,
    }).then(res => setReach(res.data));
  }

  const topBar = (
    <TopBar
      text={audioAdPlacementId ? 'Promotion' : 'Analytics'}
      podcast={selectedPodcast}
      backUrl={`/teams/${teamId}/dashboard/promos/${audioAdCampaignId}/analytics/${
        audioAdPlacementId ? audioAdPlacementId : 'byPodcast'
      }`}
    />
  );

  if (failedToLoadPodcastRollup) {
    return (
      <div>
        {topBar}
        <BannerNotice
          error
          message="Failed to load SmartPromos. Please reload the page to try again."
        />
      </div>
    );
  }

  if (!podcastRollup) {
    return (
      <div>
        {topBar}
        <Loading fullScreen />
      </div>
    );
  }

  const { sellerPodcast, startedAt } = podcastRollup;
  const minEndDate = moment(startedAt).toDate();

  return (
    <div data-testid="podcast-rollup-detail">
      {topBar}
      <div className="ph4 mv3">
        <div className="mb3 items-end gap-small flex flex-wrap w-100 justify-between">
          {audioAdPlacementId && <div className="f4">{podcastRollup.name}</div>}
          <div className="flex flex-wrap gap-small items-center justify-end ml-auto">
            <ReactTooltip id="lookbackwindow" />
            <div
              data-for="lookbackwindow"
              data-tip="How many days after each impression we look to find conversions. Change in SmartPromos Settings."
              className="f7 tc br4 ph2 pv1 white bg-blue help"
            >
              {lookbackWindow} day attribution window
            </div>
            <ChartableDatePicker
              disabledDays={{ after: new Date() }}
              inlineStyle={{ width: 'max-content' }}
              startDate={startDate}
              endDate={endDate}
              handleUpdateDateRange={(start, end) => {
                setStartDate(start);
                setEndDate(end.getTime() < minEndDate.getTime() ? minEndDate : end);
                setPodcastRollup(null);
              }}
            />
          </div>
        </div>
        <div className="pa3 card b--light-gray mb3">
          <div className="br2 flex flex-row items-center bg-white w5">
            <div className="w3 h3">
              {sellerPodcast.displayImageUrl ? (
                <img alt="podcast" src={sellerPodcast.displayImageUrl} />
              ) : (
                <div className="w3 h3 bg-light-gray" />
              )}
            </div>
            <div className="ml2 w-70 f5 tl truncate">{sellerPodcast.shortTitle}</div>
          </div>
        </div>
        <StatCardHeader className="mb3" stats={podcastRollup} reach={reach} />
        <div className="mb2 b mid-gray">Stats by Episode</div>
        <RollupTable rollups={podcastRollup.episodes} teamId={teamId} dimension="Episode" />
        <div className="mt4">
          {podcastRollup.uniqueDownloads.history &&
            podcastRollup.uniqueDownloads.history.length > 0 && (
              <div>
                <DownloadHistoryChart
                  title="Unique Converted Devices"
                  downloadHistory={podcastRollup.uniqueDownloads.history}
                  hideSelectors
                  loading={false}
                  height="480px"
                />
              </div>
            )}
          {podcastRollup.newDownloads.history &&
            podcastRollup.uniqueDownloads.history.length > 0 && (
              <div>
                <DownloadHistoryChart
                  title="New Unique Converted Devices"
                  downloadHistory={podcastRollup.newDownloads.history}
                  hideSelectors
                  loading={false}
                  height="480px"
                />
              </div>
            )}
          {podcastRollup.impressions.history && podcastRollup.impressions.history.length > 0 && (
            <DownloadHistoryChart
              title="Impressions"
              downloadHistory={podcastRollup.impressions.history}
              hideSelectors
              loading={false}
              height="480px"
            />
          )}
        </div>
      </div>
    </div>
  );
}

PodcastRollupDetailView.propTypers = {
  match: PropTypes.object.isRequired,
  selectedPodcast: PropTypes.object.isRequired,
  storeState: PropTypes.func.isRequired,
  lookbackWindow: PropTypes.number.isRequired,
  initialState: PropTypes.object,
};

PodcastRollupDetailView.defaultProps = {
  initialState: { startDate: moment().subtract(30, 'days').toDate(), endDate: moment().toDate() },
};

export default PodcastRollupDetailView;
